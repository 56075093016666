import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
// import Select from "../../../Components/Input/Select";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

import CloseModal from "../../../Components/CloseModal";
import {
  formatIndianCurrency,
  replaceComma,
} from "../../../Utils/helperFunctions";
import {
  updateIncomeMultiplierById,
  savepincomemultipliermaster,
} from "../../../Utils/Services/incomeMultiplier";

import "./style.css";
import { cloneDeep } from "lodash";
toast.configure();

let initForm = {
  id: "",
  types: "",
  multiplier: "",
  errors: {
    multiplier: null,
  },
};
class EditIncomeMultipler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      showInner: false,
      form: cloneDeep(initForm),
    };
  }

  //   componentDidMount = () => {
  //     alert("hello");
  //     console.log("this.props.editData", this.props);
  //     if (this.props.editData && this.props.action == "edit") {
  //       let { form } = this.state;
  //       let obj = { ...form, ...this.props.editData };
  //       this.setState({ form: obj });
  //     }
  //     this.setState({ showModal: true });
  //   };

  componentDidMount() {
    this.check();
  }

  check = () => {
    console.log("this.props.editData", this.props);
    if (this.props.editData && this.props.action == "edit") {
      let { form } = this.state;
      let obj = { ...form, ...this.props.editData };
      this.setState({ form: obj });
    }
    this.setState({ showModal: true });
  };

  //   componentDidUpdate(prevProps, prevState) {
  //     alert("hello");
  //     console.log("prevProps", prevProps);
  //     let { form } = this.state;
  //     if (prevProps.editData !== this.state.form) {
  //       let obj = { ...form, ...this.props.editData };
  //       this.setState({ form: obj });
  //     }
  //   }

  handleModalClose = () => {
    this.setState({ showInner: !this.state.showInner });
    // this.this.props.addRole();
  };

  innerModalClose = (modclose) => {
    if (!modclose) {
      this.handleModalClose();
    }
    this.setState({ showModal: false });
    this.props.handleEditIncomeMultiplier();
    // if (this.props.action == "edit") {
    //   this.props.handleEditIncomeMultiplier();
    // } else {
    //   this.props.handleAddFrankingFee();
    // }
  };

  handleInputChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    form[name] = e.target.value;
    this.setState({ form });
  };

  onValidate = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    if (form[name] == "" || form[name] == null || form[name] == undefined) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      form.errors[name] = null;
      this.setState({ form });
    }
  };

  handleSubmit = () => {
    let { form } = this.state;
    let obj = form;
    // delete obj.errors;
    this.setState({ isLoading: true });
    savepincomemultipliermaster(obj).then((response) => {
      this.setState({ isLoading: false });
      if (response.error) {
        return;
      } else if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        this.props.getIncomeMultiplier();
        this.props.handleEditIncomeMultiplier();
        this.setState({ showModal: false });
      }
    });
  };

  render() {
    let { form, showModal, showInner, isLoading } = this.state;
    let { errors } = form;
    let diableSave = false;
    let isRequiredFeildFilled = false;

    Object.keys(form).map((name) => {
      console.log("form", form[name]);
      if (form[name] == "" || form[name] == null || form[name] == undefined) {
        isRequiredFeildFilled = true;
        return true;
      }
    });

    Object.keys(errors).map((error) => {
      console.log("error", errors[error]);
      if (errors[error] == true || errors[error] != null) {
        diableSave = true;
        return true;
      }
    });

    console.log("form.state", this.props);
    console.log("multiplier", form);
    return (
      <>
        <div>
          <Modal
            className="franking-fee-container"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            size="lg"
          >
            <Modal.Header>
              <Modal.Title>
                {/* {this.props.action == "edit"
                  ? "Edit Income Multiplier"
                  : "Add Income Multiplier"} */}
                Edit Income Multiplier
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
              {isLoading ? (
                <h4 className="text-center">LOADING...</h4>
              ) : (
                <div className="row">
                  <div className="form-group col-md-4 ">
                    <label>{/* <i className="text-danger">*</i> */} Type</label>
                    <div class="inner-addon right-addon">
                      <input
                        type="text"
                        // pattern="\d*"
                        maxLength="9"
                        name="type"
                        value={form.types}
                        placeholder="Enter Type"
                        className={`form-input `}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-4 ">
                    <label>
                      <i className="text-danger">*</i> Multiplier (%)
                    </label>
                    <div class="inner-addon right-addon">
                      <input
                        type="text"
                        // pattern="\d*"
                        maxLength="9"
                        name="multiplier"
                        value={form && form.multiplier}
                        onChange={(e) => {
                          if (
                            e.target.value == "" ||
                            (Math.ceil(e.target.value) <= 100 &&
                              parseInt(e.target.value) >= 0)
                          ) {
                            e.target.value = e.target.value
                              .toString()
                              .split(".")
                              .map((el, i) =>
                                i ? el.split("").slice(0, 2).join("") : el
                              )
                              .join(".");
                            this.handleInputChange(e, "multiplier");
                          }
                        }}
                        // onBlur={(e) => this.onValidate(e, "multiplier")}
                        placeholder="Enter Multiplier (%)"
                        // className={`form-input ${errors.multiplier ? "error-input-border"
                        //     : ""
                        //     } `}
                        className="form-input"
                        disabled={false}
                      />
                    </div>
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-danger" onClick={this.handleModalClose}>
                Cancel
              </Button>
              <Button
                className="btn-success"
                disabled={diableSave || isRequiredFeildFilled || isLoading}
                onClick={this.handleSubmit}
              >
                {this.props.action == "edit" ? "Update" : "Create"}
              </Button>
            </Modal.Footer>
          </Modal>
          <CloseModal
            show={showInner}
            button2={this.handleModalClose}
            button1={this.innerModalClose}
            title="Do you want to close ?"
          />
        </div>
      </>
    );
  }
}

export default EditIncomeMultipler;
