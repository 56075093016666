import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import "./styles.css"

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddEditBranch from "./AddEditBranch.js";
import {
  findallbranches,
} from "../../../Utils/Services/BranchMaster";
toast.configure();

const BranchMaster = (props) => {
  let [BranchData, setBranchData] = useState([]);

  let [productDropdownOptions, setproductDropdownOptions] = useState([]);
  let [showAddProductNormModal, setshowAddProductNormModal] = useState(false);
  let [showEditNormModal, setshowEditNormModal] = useState(false);
  let [editData, setEditData] = useState({});
  let [showBulUploadModal, setshowBulUploadModal] = useState(false);

  useEffect(() => {
    BranchMaster();
    window.scrollTo(0, 0);
  }, []);

  //get all list village
  const BranchMaster = () => {
    findallbranches().then((response) => {
      if (response.error) {
        return;
      }
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        setBranchData(response&&response.data);
      }
    });
  };


  const columns = [
    {
      Header: "Branch Id",
      // width: 100,
      Cell: ({ original }) => {
        return original && original.id ? original.id : null;
      },
    },


    {
      Header: "Branch",
      // width: 100,
      Cell: ({ original }) => {
        return original && original.branchName ? original.branchName : null;
      },
    },

    {
      Header: "Pincode",
      // width: 100,
      Cell: ({ original }) => {
        return original && original.pincode ? original.pincode : null;
      },
    },
    {
      Header: "Tehsil",
      // width: 100,
      Cell: ({ original }) => {
      return original && original.tehsil ? original.tehsil : null;
      },
    },
    {
      Header: "District",
      // width: 100,
      Cell: ({ original }) => {
        return original && original.district ? original.district : null;
      },
    },
    {
      Header: "State",
      // width: 100,
      Cell: ({ original }) => {
        return original && original.state ? original.state : null;
      },
    },
    {
      Header: "Status",
       //width: 180,
      Cell: ({ original }) => {
        return original && original.status === true ? 
        "ACTIVE" : "INACTIVE";
      },
    },
    {
      Header: "Action",
      // width: 80,
      disabled: false,
      accessor: "delete",
      accessor: "edit",

      Cell: ({ original }) => {
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              onClick={() => handleEditBranchMaster(original)}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const handleAddBranch = (id) => {
    setshowAddProductNormModal(!showAddProductNormModal);
  };
        
  const handleEditBranchMaster = (obj) => {
    setshowEditNormModal(!showEditNormModal);
    setEditData(obj);
  };

  const handleBulkUpload = () => {
    console.log("HAPPENED");
    setshowBulUploadModal(!showBulUploadModal);
  }

  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box">
              Branch Master
           </h5>
          <div className="d-flex align-items-center mt-5">
            <button
              disabled={false}
              onClick={handleAddBranch}
              className="add-btn ml-auto btn btn-info btn-lg"
            >
              {" "}
              Add{" "}
            </button>
            {/* <button
              disabled={false}
              onClick={handleBulkUpload}
              className="bulk-upload-button btn btn-info btn-lg"
            >
              {" "}
              Bulk Upload Villages{" "}
            </button> */}
            {showAddProductNormModal && (
              <AddEditBranch
                action={"add"}
                BranchMaster={BranchMaster}
                handleAddBranch={handleAddBranch}
                productDropdownOptions={productDropdownOptions}
              />
            )}
            {showEditNormModal && (
              <AddEditBranch
                action={"edit"}
                BranchMaster={BranchMaster}
                editData={editData}
                handleEditBranchMaster={handleEditBranchMaster}
                productDropdownOptions={productDropdownOptions}
              />
            )}
            {/* {showBulUploadModal && (
              <BulkUploadVillage handleBulkUpload={handleBulkUpload} />
              )} */}
          </div>

          <div className={`mt-4 react-table`}>
            <ReactTable
              data={BranchData}
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default BranchMaster;
