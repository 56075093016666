import { get, post } from "../httpInterceptor";

// API for delete  campaign medium by id
export const deleteCampaignMediumById = (campaignMediumId) => {
  return post(
    `/masters/campaign/medium/delete/${campaignMediumId}`,
    null,
    false
  ).then((res) => {
    console.log("deletecampaignmediumbyId", res);
    return res;
  });
};

// API for get  campaign medium
export const getAllCampaignMedium = () => {
  return get(`/masters/campaign/medium`).then((res) => {
    console.log("get campaign medium", res);
    return res;
  });
};

// API for getting product type
export const getAllProductType = () => {
  return get(`/mapping/products`).then((res) => {
    console.log("RESPONSE :: getAllProduct ::: ", res);
    return res;
  });
};

// API for save and update  campaign medium
export const saveUpdateCampaignMedium = (obj) => {
  return post(`/masters/campaign/medium`, obj, false).then((res) => {
    console.log("save or update  campaign medium", res);
    return res;
  });
};
