import { get, post } from "../httpInterceptor";

// API for delete product campaign

export const deleteProductCampaignById = (obj) => {
  return post(`/productcampaign/deleteproductcampaignbyid`, obj, false).then(
    (res) => {
      console.log("deleteproductcampaignbyId", res);
      return res;
    }
  );
};

// API for get product campaign

export const getAllProductCampaign = () => {
  return get(`/productcampaign/findallproductcampaignlist`).then((res) => {
    console.log("get product campaign", res);
    return res;
  });
};

// // API for save product campaign

// export const addProductCampaign = (obj) => {
// 	return post(`/productcampaign/saveorupdateproductcampaign`, obj, false).then((res) => {
// 		console.log("addFrankingFee", res);
// 		return res;
// 	});
// };

// API for save and update product campaign

export const saveUpdateProductCampaign = (obj) => {
  return post(`/productcampaign/saveorupdateproductcampaign`, obj, false).then(
    (res) => {
      console.log("save or update product campaign", res);
      return res;
    }
  );
};
