import React, { Component } from "react";
import LeftMenu from "../../Components/LeftMenu";
import Header from "../../Components/Header";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export class DashBoard extends Component {
  state = {};
  render() {
    return (
      <div className="dash_grid">
        <ToastContainer />
        <LeftMenu role={this.props.role} />
        <main>
          <Header {...this.props} />
          <section className="container-fluid dash_space admin-dashboard">
            <div className="row mt-2">
              <div className="col-md-4">
                <div className="card2 bg-white">
                  <div className="card-body">
                    <div className="text-center">
                      <div className="d-flex justify-content-center">
                        <i className="fas fa-users bg1 fa-3x m-0"></i>
                      </div>
                      <p className="font-weight-normal mt-3">Role</p>
                      <p className="font-weight-normal fs-24">
                        {/* <Link className="title-orange" to="/role-management"> */}
                          Role Management
                        {/* </Link> */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card2 bg-white">
                  <div className="card-body">
                    <div className="text-center">
                      <div className="d-flex justify-content-center">
                        <i className="fas fa-user-plus fa-3x bg2 m-0"></i>
                      </div>
                      <p className="font-weight-normal mt-3">Users</p>
                      <p className="font-weight-normal fs-24">
                        {/* <Link className="title-orange" to="/user-management"> */}
                          Users
                        {/* </Link> */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card2 bg-white">
                  <div className="card-body">
                    <div className="text-center">
                      <div className="d-flex justify-content-center">
                        <i className="fas fa-money-check-alt fa-3x bg3 m-0"></i>
                      </div>
                      <p className="font-weight-normal mt-3">Franchise</p>
                      <p className="font-weight-normal fs-24">
                        {/* <Link className="title-orange" to="/dsa"> */}
                          Franchise
                        {/* </Link> */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default DashBoard;
