import axios from "axios";
import { get, post, awspost } from "../httpInterceptor";
// const CORS_URL = "http://182.71.191.91:5010/";

// API for get all segment

export const getAllSegmentList = () => {
  return get(`/sarvagram-credit/master/findallsegmentdropdown`).then((res) => {
    console.log("RESPONSE :: getAllSegmentList ::: ", res);
    return res;
  });
};

// API for get all levels values

export const getAllLevelsDropdown = () => {
  return get(`/sarvagram-credit/master/findalllevelsdropdown`).then((res) => {
    console.log("RESPONSE :: getAllLevelsDropdown ::: ", res);
    return res;
  });
};

// API for get all state values

export const getAllStates = () => {
  return get(`/sarvagram-credit/master/findallstates`).then((res) => {
    console.log("RESPONSE :: getAllStates ::: ", res);
    return res;
  });
};

// API for get all products values

export const getAllProducts = () => {
  return get(`/sarvagram-credit/master/findallproductdropdown`).then((res) => {
    console.log("RESPONSE :: getAllProducts ::: ", res);
    return res;
  });
};

// API for get type of company

export const getTypeCompany = () => {
  return get(`/franchise/gettypecompany`).then((res) => {
    console.log("RESPONSE :: getTypeCompany ::: ", res);
    return res;
  });
};

// API for get all branch values

export const getAllBranch = () => {
  return get(`/goldappraiser/findallbranchdropdown`).then((res) => {
    console.log("RESPONSE :: getAllBranch ::: ", res);
    return res;
  });
};

// API for verify pan

// API for verify bank account

export const verifyBankAccountDetails = (objBody) => {
  return axios
    .post("https://live.sarvagram.com/service/utils/bank/verify", objBody, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((res) => {
      return {
        data: res.data,
      };
    });
};

// API for get address by pincode

export const getAddressByPincode = (obj) => {
  return post(`/goldappraiser/findaddressbypincode`, obj, false).then((res) => {
    console.log("getAddressByPincode", res);
    return res;
  });
};

export const pan = (obj) => {
  return post(`/ekyc/pan/verify`, obj, false).then((res) => {
    console.log("pan", res);
    return res;
  });
};

//driving license number authentication

export const verifyDrivingLicense = (objBody) => {
  return awspost(
    `https://7bt254zc57.execute-api.ap-south-1.amazonaws.com/test/driving-license-authentication`,
    objBody,
    false,
    false,
    true
  ).then((res) => {
    console.log("verifyDrivingLicense", res);
    return res;
  });
};

// API for verify voter ID

export const verifyVoterID = (objBody) => {
  return awspost(
    `https://7bt254zc57.execute-api.ap-south-1.amazonaws.com/test/voter-id-authentication`,
    objBody,
    false,
    false,
    true
  ).then((res) => {
    console.log("verifyVoterID", res);
    return res;
  });
};

// API for verify passport

export const verifyPassport = (objBody) => {
  return awspost(
    `https://7bt254zc57.execute-api.ap-south-1.amazonaws.com/test/passport-authentication`,
    objBody,
    false,
    false,
    true
  ).then((res) => {
    console.log("verifyPassport", res);
    return res;
  });
};

// API for verify GST

export const verifyGst = (objBody) => {
  return awspost(
    `https://7bt254zc57.execute-api.ap-south-1.amazonaws.com/test/gst-authentication`,
    objBody,
    false,
    false,
    true
  ).then((res) => {
    console.log("gstVerify", res);
    return res;
  });
};
