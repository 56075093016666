import { get, post } from "../httpInterceptor";

export const uploadImageDoc = (obj) => {
  return post(`/admin-notification/upload`, obj, false, true).then((res) => {
    console.log("uploadImageDoc", res);
    return res;
  });
};

export const postdata = (obj) => {
  return post(`/admin-notification/saveorupdatenotification`, obj, false, {
    headers: {
      "content-type": "multipart/form-data",
    },
  }).then((res) => {
    console.log("adduser", res);
    return res;
  });
};

export const getnotification = () => {
  return get(`/admin-notification/findallnotificationlist`).then((res) => {
    console.log("RESPONSE :: getallnotification ::: ", res);
    return res;
  });
};
export const senddata = () => {
  return post(`/admin-notification/notification`).then((res) => {
    console.log("RESPONSE :: getallnotification ::: ", res);
    return res;
  });
};

export const getallcategory = (obj) => {
  return post(`/admin-notification/getallcategory`, obj, false).then((res) => {
    console.log("RESPONSE :: getallcategory ::: ", res);
    return res;
  });
};

export const deleteCampaign = (obj) => {
  return post(`/productcampaign/deleteproductcampaignbyid`, obj, false).then(
    (res) => {
      console.log("deleteproductcampaignbyId", res);
      return res;
    }
  );
};

export const SendNotification = (obj) => {
  return post(`/admin-notification/notification`, obj, false).then((res) => {
    console.log(`/sendpushnotification`, res);
  });
};

export const deleteNotification = (obj) => {
  return post(`/admin-notification/notification`, obj, false).then((res) => {
    console.log(`/sendpushnotification`, res);
  });
};
