import { get, post } from "../httpInterceptor";
import { API_URL } from "../config";
import axios from "axios";

export const emailRegx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const getAllGoldAudits = () => {
  return get("/audit/gold/audits").then((res) => {
    console.log("RESPONSE :: getAllGoldAudits ::: ", res);
    return res;
  });
};

export const getAllBranches = (obj) => {
  return get(`/mapping/getAllBranches?isAllRequired=false`, obj, false).then(
    (res) => {
      console.log("RESPONSE :: getallbranches ::: ", res);
      return res;
    }
  );
};

export const getGoldAuditLotByBranch = (branch, auditId) => {
  let url = `/audit/gold/lot/generate/${branch}`;
  if (auditId) {
    url = `/audit/gold/lot/generate/${branch}/${auditId}`;
  }

  return get(url).then((res) => {
    console.log("RESPONSE :: GoldAuditLotByBranch ::: ", res);
    return res;
  });
};

// export const saveGoldAuditLot = (formData) => {
// 	return post("/audit/gold", formData, false, true).then((res) => {
// 		console.log("RESPONSE :: saveGoldAuditLot ::: ", res);
// 		return res;
// 	});
// };

export const saveGoldAuditLot = (formData) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  console.log({ userData });
  return axios
    .post(API_URL + "/audit/gold", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.adminAccessToken}`,
        "Access-Control-Allow-Origin": "*",
        Accept: "*/*",
      },
    })
    .then((res) => {
      console.log("RESPONSE :: saveGoldAuditLot ::: ", res);
      return res;
    });
};

// export const getAllGoldAudits = () => {
// 	return get("/audit/gold/audits").then((res) => {
// 		console.log("RESPONSE :: getAllGoldAudits ::: ", res);
// 		return res;
// 	});
// };

export const viewDocument = (docpath) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  return axios
    .get(`${API_URL}/document?fileName=${docpath}`, {
      responseType: "blob",
      headers: {
        Accept: "application/octet-stream",
        Authorization: `Bearer ${userData.adminAccessToken}`,
      },
    })
    .then((res) => {
      console.log("viewDocument", res);
      const { data, headers } = res;
      const fileName = docpath.split("/").pop();
      const blob = new Blob([data], { type: "application/octet-stream" });
      let dom = document.createElement("a");
      let url = window.URL.createObjectURL(blob);
      dom.href = url;
      dom.download = decodeURI(fileName);
      dom.style.display = "none";
      document.body.appendChild(dom);
      dom.click();
      dom.parentNode.removeChild(dom);
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const changeAuditStatus = (id, status) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  return axios
    .post(`${API_URL}/audit/gold/status?status=${status}&auditId=${id}`, null, {
      headers: {
        Authorization: `Bearer ${userData.adminAccessToken}`,
      },
    })
    .then((res) => {
      console.log("changeAuditStatus", res);
      return res;
    });
};

export const getRemainingPouches = (auditId) => {
  return get(`/audit/gold/${auditId}`).then((res) => {
    console.log({ res });
    return res;
  });
};
