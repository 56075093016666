import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import EditIncomeMultipler from "./EditIncomeMultipler";
import { formatIndianCurrency } from "../../../Utils/helperFunctions";
import {
  getAllIncomeMultiplier,
  findallincomemultipliermaster,
} from "../../../Utils/Services/incomeMultiplier";

toast.configure();

const IncomeMultiplier = (props) => {
  let [search, setSearch] = useState("");
  let [IncomeMultiplierData, setIncomeMultiplierData] = useState([]);
  let [
    showEditIncomeMultiplierModal,
    setShowEditIncomeMultiplierModal,
  ] = useState(false);
  let [editData, setEditData] = useState({});

  useEffect(() => {
    getIncomeMultiplier();
  }, []);

  const getIncomeMultiplier = () => {
    findallincomemultipliermaster().then((res) => {
      console.log("findallincomemultipliermaster", res);
      if (res.error) {
        return;
      }
      if (res.data && res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        toast.success(res.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        setIncomeMultiplierData(res.data.data);
      }
    });
  };

  const columns = [
    {
      Header: "SR. No",
      width: 70,
      Cell: (data) => {
        // console.log("dat/a...", data)
        return data.index + 1;
      },
    },
    {
      Header: "Type",
      width: 200,
      Cell: ({ original }) => {
        return original && original.types ? original.types : null;
      },
    },
    {
      Header: "Income Multiplier (%)",
      Cell: ({ original }) => {
        return original.multiplier && original.multiplier
          ? original.multiplier
          : null;
      },
    },
    {
      Header: "Action",
      width: 125,
      disabled: false,
      accessor: "delete",
      accessor: "edit",

      Cell: ({ original }) => {
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              onClick={() => handleEditIncomeMultiplier(original)}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const handleEditIncomeMultiplier = (obj) => {
    setShowEditIncomeMultiplierModal(!showEditIncomeMultiplierModal);
    setEditData(obj);
  };

  const handleSearch = (e) => {
    /* let { roleContainer } = this.state;
        let array = [];
        roleContainer.map(res => {
            let roleName = res.roleName;
            if (roleName)
                if (res.roleName.match(e.target.value.toUpperCase())) {
                    array.push(res);
                }
        });
        this.setState({
            search: e.target.value,
            roleData: e.target.value ? array : roleContainer
        }); */
  };

  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box">
            {" "}
            Income Multiplier
          </h5>
          <div className="d-flex align-items-center mt-5">
            {showEditIncomeMultiplierModal && (
              <EditIncomeMultipler
                action={"edit"}
                getIncomeMultiplier={getIncomeMultiplier}
                editData={editData}
                handleEditIncomeMultiplier={handleEditIncomeMultiplier}
              />
            )}
          </div>

          <div className={`mt-4 react-table`}>
            <ReactTable
              data={IncomeMultiplierData}
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default IncomeMultiplier;
