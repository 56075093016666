import { API_URL } from "../config";
import axios from "axios";

export const viewDocument = async (docpath) => {
  try {
    let userData = JSON.parse(localStorage.getItem("userData"));
    const res = await axios.get(
      `${API_URL}/franchise/getDocument?fileName=${docpath}`,
      {
        responseType: "blob",
        headers: {
          Accept: "application/octet-stream",
          Authorization: `Bearer ${userData.adminAccessToken}`,
        },
      }
    );
    console.log("viewDocument", res);
    const { data, headers } = res;
    const fileName = docpath.split("/").pop();
    const blob = new Blob([data], { type: "application/octet-stream" });
    let dom = document.createElement("a");
    let url_2 = window.URL.createObjectURL(blob);
    dom.href = url_2;
    dom.download = decodeURI(fileName);
    dom.style.display = "none";
    document.body.appendChild(dom);
    dom.click();
    dom.parentNode.removeChild(dom);
    window.URL.revokeObjectURL(url_2);
  } catch (err) {
    console.log("err", err);
  }
};
