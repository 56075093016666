import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from "lodash";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import {
  formatIndianCurrency,
  replaceComma,
  allowNumberGreaterThanZero,
} from "../../../Utils/helperFunctions";
import { insuranceDeductionTable } from "./data";
import {
  getInsuranceDeductionBelow25,
  addOrUpdateInsuranceDeductionBelow25,
  deleteInsuranceDeductionByTenureBelow25,
  getInsuranceDeductionAbove25,
  addOrUpdateInsuranceDeductionAbove25,
  deleteInsuranceDeductionByTenure,
} from "../../../Utils/Services/insuranceDeduction";
import "./style.css";

class InsuranceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: [],
      isNotEditable: true,
      tenureLength: "",
      tenureCount: "",
    };
  }

  componentDidMount = () => {
    // this.setState({ loading: true });
    this.getInsuranceDeduction();
  };

  getInsuranceDeduction = () => {
    let { form } = this.state;
    let { action } = this.props;
    this.setState({ loading: true });
    if (action == "above25") {
      getInsuranceDeductionAbove25().then((response) => {
        this.setState({ loading: false });
        if (response.error) {
          return;
        } else if (response.data && response.data.error) {
          toast.error(response.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
          return;
        } else {
          console.log(
            "response",
            response.data &&
              response.data.insuraceList &&
              response.data.insuraceList.length > 0
          );
          /* toast.success(response.data.message, {
                        type: toast.TYPE.SUCCESS,
                        autoClose: 2000
                    }); */
          if (
            response.data &&
            response.data.insuraceList &&
            response.data.insuraceList.length > 0
          ) {
            this.setState({ form: response.data.insuraceList }, () => {
              let output = response.data.insuraceList.map(
                (outerData, outerIndex) => {
                  Object.keys(outerData).map((each, index) => {
                    if (outerIndex == 0 && index == 0) {
                      console.log(
                        "qqqqqq",
                        outerIndex,
                        outerData[each].length,
                        outerData[each]
                      );
                      this.setState({
                        tenureLength: outerData[each].length + 1,
                        tenureCount: outerData[each].length + 2,
                      });
                    }
                  });
                }
              );
            });
          }
        }
      });
    } else {
      getInsuranceDeductionBelow25().then((response) => {
        this.setState({ loading: false });
        if (response.error) {
          return;
        } else if (response.data && response.data.error) {
          toast.error(response.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
          return;
        } else {
          console.log(
            "response",
            response.data &&
              response.data.insuraceList &&
              response.data.insuraceList.length > 0
          );
          /* toast.success(response.data.message, {
                        type: toast.TYPE.SUCCESS,
                        autoClose: 2000
                    }); */
          if (
            response.data &&
            response.data.insuraceList &&
            response.data.insuraceList.length > 0
          ) {
            this.setState({ form: response.data.insuraceList }, () => {
              let output = response.data.insuraceList.map(
                (outerData, outerIndex) => {
                  Object.keys(outerData).map((each, index) => {
                    if (outerIndex == 0 && index == 0) {
                      console.log(
                        "qqqqqq",
                        outerIndex,
                        outerData[each].length,
                        outerData[each]
                      );
                      this.setState({
                        tenureLength: outerData[each].length + 1,
                        tenureCount: outerData[each].length + 2,
                      });
                    }
                  });
                }
              );
            });
          }
        }
      });
    }
  };

  handleChnages = (e, outerIndex, name, extremeIndex) => {
    let { form } = this.state;
    console.log("wwww", e.target, outerIndex, name, extremeIndex);
    form[outerIndex][name][extremeIndex].rate = e.target.value;

    this.setState({ form });
  };

  addTenure = () => {
    let { form, tenureCount } = this.state;
    let output = form.map((outerData, outerIndex) => {
      Object.keys(outerData).map((each, index) => {
        // console.log("each.....", outerData[each])
        outerData[each].push({
          id: 0,
          rate: "",
          tenure: tenureCount,
          age: outerData[each] && outerData[each][0] && outerData[each][0].age,
        });
        return outerData[each];
      });
    });
    this.setState({ form, tenureCount: tenureCount + 1 });
  };

  deleteTenure = () => {
    let { form, tenureLength, tenureCount } = this.state;
    let that = this;
    let output = form.map((outerData, outerIndex) => {
      Object.keys(outerData).map((each, index) => {
        confirmAlert({
          title: "Confirm to Delete",
          message: "Are you sure to do this?",
          buttons: [
            {
              label: "Yes, Delete It!",
              onClick: () => {
                // console.log("outerData[each].length.....", tenureLength, form[0]["eightTeen"])
                let obj = {};
                if (tenureCount > tenureLength + 1) {
                  obj = form[0]["eightTeen"].find(
                    (data) => data.tenure == tenureLength + 1 && data.id == 0
                  );
                } else {
                  obj = form[0]["eightTeen"].find(
                    (data) => data.tenure == tenureLength && data.id != 0
                  );
                }

                console.log(
                  "outerData[each].length.....",
                  obj,
                  tenureCount,
                  tenureLength + 1
                );
                // return
                if (obj && obj.id == 0) {
                  form.map((outerData, outerIndex) => {
                    Object.keys(outerData).map((each, index) => {
                      // console.log("sadasdas", outerData[each])
                      outerData[each].splice(-1, form[0]["eightTeen"].length);
                      return outerData[each];
                    });
                  });
                  that.setState({ form, tenureCount: tenureCount - 1 });
                } else {
                  let { action } = this.props;
                  if (action == "above25") {
                    deleteInsuranceDeductionByTenure(obj.tenure).then(
                      (response) => {
                        // console.log("deleteFrankingData", response, response.data && response.data.error == "true")
                        this.setState({ isNotEditable: true });
                        if (response.error) {
                          return;
                        }
                        if (response.data && response.data.error) {
                          toast.error(response.data.message, {
                            type: toast.TYPE.ERROR,
                            autoClose: 2000,
                          });
                        } else {
                          toast.success(response.data.message, {
                            type: toast.TYPE.SUCCESS,
                            autoClose: 2000,
                          });
                          this.getInsuranceDeduction();
                        }
                      }
                    );
                  } else {
                    deleteInsuranceDeductionByTenureBelow25(obj.tenure).then(
                      (response) => {
                        // console.log("deleteFrankingData", response, response.data && response.data.error == "true")
                        this.setState({ isNotEditable: true });
                        if (response.error) {
                          return;
                        }
                        if (response.data && response.data.error) {
                          toast.error(response.data.message, {
                            type: toast.TYPE.ERROR,
                            autoClose: 2000,
                          });
                        } else {
                          toast.success(response.data.message, {
                            type: toast.TYPE.SUCCESS,
                            autoClose: 2000,
                          });
                          this.getInsuranceDeduction();
                        }
                      }
                    );
                  }
                }
              },
            },
            {
              label: "No",
              onClick: () => {
                // that.setState({ isNotEditable: true });
              },
            },
          ],
        });
      });
    });
  };

  handleEditableStatus = () => {
    this.setState({ isNotEditable: false });
  };

  handleClose = () => {
    this.setState({ isNotEditable: true });
    this.getInsuranceDeduction();
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    let { form } = this.state;
    let { action } = this.props;
    let obj = {
      insuraceList: form,
    };
    if (action == "above25") {
      addOrUpdateInsuranceDeductionAbove25(obj).then((response) => {
        this.setState({ isNotEditable: true });
        if (response.error) {
          this.setState({ loading: false });
          return;
        } else if (response.data && response.data.error) {
          this.setState({ loading: false });
          toast.error(response.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
          return;
        } else {
          toast.success(response.data.message, {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
          this.getInsuranceDeduction();
        }
      });
    } else {
      addOrUpdateInsuranceDeductionBelow25(obj).then((response) => {
        this.setState({ isNotEditable: true });
        if (response.error) {
          this.setState({ loading: false });
          return;
        } else if (response.data && response.data.error) {
          this.setState({ loading: false });
          toast.error(response.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
          return;
        } else {
          toast.success(response.data.message, {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
          this.getInsuranceDeduction();
        }
      });
    }
  };

  render() {
    let { form, isNotEditable, loading } = this.state;
    let disabledSave = false;
    form.map((outerData, outerIndex) => {
      Object.keys(outerData).map((each, index) => {
        outerData[each].map((extremeInner, extremeIndex) => {
          console.log(
            "extremeInner",
            extremeInner,
            extremeInner.rate,
            extremeInner.rate == "",
            parseFloat(extremeInner.rate),
            isNaN(parseFloat(extremeInner.rate))
          );
          if (
            (extremeInner &&
              extremeInner.rate &&
              parseFloat(extremeInner.rate) < 0) ||
            isNaN(parseFloat(extremeInner.rate))
          ) {
            disabledSave = true;
          }
          return true;
        });
      });
    });
    console.log("this.props.title", form);
    return (
      <>
        {loading || (form && form.length == 0) ? (
          <div className="d-flex flex-column mt-5">
            <div className="d-flex justify-content-center">
              <div class="-loading -active">
                <div class="-loading-inner">Loading...</div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="d-flex flex-column mt-5" style={{ maxHeight: 500 }}>
              <div className="d-flex justify-content-end">
                <h4 className="mr-auto">{this.props.title}</h4>
                <button
                  disabled={false}
                  onClick={this.handleEditableStatus}
                  className="add-btn-insurance ml-1 btn btn-success btn-lg"
                >
                  <span>
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </span>
                </button>
                <button
                  disabled={isNotEditable}
                  onClick={this.addTenure}
                  className=" ml-1 btn btn-success btn-lg"
                >
                  +
                </button>
                <button
                  disabled={isNotEditable}
                  onClick={this.deleteTenure}
                  className=" ml-1 btn btn-danger btn-lg"
                >
                  -
                </button>
              </div>
              <div className="mt-2 " style={{ overflow: "scroll" }}>
                <table
                  // id="dtHorizontalExample"
                  //   class="table table-striped table-bordered table-sm"
                  className="table table-bordered table-sm text-nowrap text-center"
                  cellSpacing="0"
                  width="100%"
                >
                  <thead className="bg_d-primary">
                    <tr>
                      <th style={{ whiteSpace: "break-spaces" }}>
                        {" "}
                        Age | Tenure (Years)
                      </th>
                      {form.map((outerData, outerIndex) => {
                        return Object.keys(outerData).map((each, index) => {
                          // console.log("aaaa header", outerIndex, each, index);
                          return index == 0 ? (
                            <>
                              {outerData[each].map(
                                (extremeInner, extremeIndex) => {
                                  // console.log("extremeInner", extremeInner)
                                  return <th>{extremeIndex + 2}</th>;
                                }
                              )}
                            </>
                          ) : (
                            <></>
                          );
                        });
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr> */}
                    {form.map((outerData, outerIndex) => {
                      return Object.keys(outerData).map((each, index) => {
                        // console.log("aaaa", outerData[each], each, index);
                        return (
                          <>
                            <tr>
                              <td>{index + 18}</td>
                              {outerData[each].map(
                                (extremeInner, extremeIndex) => {
                                  // console.log("extremeInner", extremeInner)
                                  return (
                                    <td>
                                      <input
                                        type="text"
                                        maxLength="9"
                                        value={
                                          extremeInner.rate &&
                                          formatIndianCurrency(
                                            extremeInner.rate
                                              .toString()
                                              .split(".")
                                              .map((el, i) =>
                                                i
                                                  ? el
                                                      .split("")
                                                      .slice(0, 2)
                                                      .join("")
                                                  : el
                                              )
                                              .join(".")
                                          )
                                        }
                                        onChange={(e) => {
                                          e.target.value = replaceComma(
                                            e.target.value
                                          );
                                          if (
                                            parseFloat(e.target.value) >= 0 ||
                                            e.target.value == ""
                                          ) {
                                            this.handleChnages(
                                              e,
                                              outerIndex,
                                              each,
                                              extremeIndex
                                            );
                                          }
                                        }}
                                        placeholder=""
                                        className={`form-input insurance-input text-center`}
                                        disabled={isNotEditable}
                                      />
                                    </td>
                                  );
                                }
                              )}
                            </tr>
                          </>
                        );
                      });
                    })}
                    {/* </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
            {!isNotEditable && (
              <div className="d-flex justify-content-end mt-2">
                <Button className="btn-danger ml-1" onClick={this.handleClose}>
                  Cancel
                </Button>
                <Button
                  className="btn-success ml-1"
                  disabled={disabledSave}
                  onClick={this.handleSubmit}
                >
                  Save
                </Button>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

export default withRouter(InsuranceTable);
