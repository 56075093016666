import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Header } from "./Header";
// import Logo from "../../public/Assets/Images/admin.png";

class LeftMenu extends Component {
  state = {
    hideDashboard: false,
    hideFrankingFee: false,
    hideProcessingFee: false,
    hideLegalValutionFee: false,
    hideRoi: false,
    hideCollateral: false,
    hideDfpMaster: false,
    hideSegmentMaster: false,
    hideProductMaster: false,
    hideLevelMaster: false,
    hideFundingGridMaster: false,
    hideCancelMaster: false,
    hideServiceMaster: false,
    hideVillageMaster: false,
	hideBranchMaster:false,
	hideEmployeeCreation:false,
    hideApproval: false,
    hidePayment: false,
    hideSpokeMaster: false,
    hideWebNotificationMaster: false,
    hideProductCampaign: false,
  };

	componentDidMount() { }

  render() {
    const {
      hideDashboard,
      hideFrankingFee,
      hideProcessingFee,
      hideLegalValutionFee,
      hideRoi,
      hideCollateral,
      hideDfpMaster,
      hideSegmentMaster,
      hideProductMaster,
      hideLevelMaster,
      hideFundingGridMaster,
      hideCancelMaster,
      hideServiceMaster,
      hideVillageMaster,
	  hideBranchMaster,
	  hideEmployeeCreation,
      hideApproval,
      hidePayment,
      hideSpokeMaster,
      hideWebNotificationMaster,
      hideProductCampaign,
    } = this.state;

		let isMaster = [
			"/master-gold-audit",
			"/master-franking-fee",
			"/master-product-funding-norms",
			"/master-processing-fee",
			"/master-legal-valuation-fee",
			"/master-roi",
			"/master-collateral-ltv",
			"/master-dfp-master",
			"/master-insurance-deduction",
			"/master-income-multiplier",
			"/master-funding",
			"/master-MFGEMI",
			"/Cancel-master",
			"/Service-master",
			"/Village-master",
			"/Cancellation-per-master",
			"/VillageSpoke-master",
			"/Web-Notification-master",
		].includes(this.props.location.pathname);

		let on_boarding_master = ["/gold-onboarding"].includes(this.props.location.pathname);
		let approval_master = ["/gold-approval-master", "/Franchise-approval-master"].includes(this.props.location.pathname);
		let appraiser_listing_master = ["/gold-appraiser-master-listing", "/Franchise-appraiser-master-listing"].includes(
			this.props.location.pathname
		);

		
		const onlyEmployeeMasterAccess=["BM", "ZBH", "ZCM", "RBH", "RCM"];
		const onlyUserManagementAccess = ["BM", "ZBH", "ZCM", "RBH", "RCM"];
		const onlyLeaderboardCampaignAccess = ["SH", "PM", "AM", "MISE"];

		return (
			<aside className="LeftMenu">
				<div className="logo-image"></div>
				<ul className="dash_ul">
					<div style={{ background: "#e88753" }} className="row px-3 d-flex justify-content-center">
						<div>
							<img src="./assets/images/sarvagramLogo.png" style={{ height: "auto", width: "auto", padding: 10 }} />
						</div>
					</div>
					<div className="text-center p-2 mt-4">
						<h6 style={{ color: "#e88753" }}>Welcome To Sarvagram </h6>
						<h6 style={{ overflowWrap: "break-word" }} className="text-white">
							{/* {/ {admin ? admin.employeeName.toUpperCase() : "User"} /} */}
						</h6>
					</div>
					<div className="custom-hr">
						<hr />
					</div>
					{this.props.role !== "notFetched" && !onlyUserManagementAccess.includes(this.props.role) && !onlyLeaderboardCampaignAccess.includes(this.props.role) ? (
						<li style={hideDashboard ? { display: "none" } : null}>
							<NavLink to="/dashboard" activeClassName="active">
								<i className="fa fa-tachometer fa-lg"></i>
								<span>Dashboard</span>
							</NavLink>
						</li>
					) : null}
					{/* {this.props.role !== "notFetched" && !onlyLeaderboardCampaignAccess.includes(this.props.role) ? (
						<li style={hideDashboard ? { display: "none" } : null}>
							<NavLink to="/user-management" activeClassName="active">
								<i className="fas fa-user-tag"></i>
								<span>User management</span>
							</NavLink>
						</li>
					) : null} */}
					{this.props.role !== "notFetched" && !onlyUserManagementAccess.includes(this.props.role) ? (
						<>
							{!onlyLeaderboardCampaignAccess.includes(this.props.role) ? (
								<>
									{/* role management */}
									<li style={hideDashboard ? { display: "none" } : null}>
										<NavLink to="/role-management" activeClassName="active">
											<i className="fas fa-user-tag"></i>
											<span>Role management</span>
										</NavLink>
									</li>
									{/* /user-management */}

									{/* access management */}
									<li style={hideDashboard ? { display: "none" } : null}>
										<NavLink to="/access-management" activeClassName="active">
											<i className="fab fa-keycdn fa-lg"></i>
											<span>Rolewise Access management</span>
										</NavLink>
									</li>
									{/* <li>
										<NavLink
											to=""
											activeClassName="active"
											data-toggle="collapse"
											data-target="#collapseOne"
											aria-expanded="true"
											aria-controls="collapseOne"
											id="menu-toggle"
										>
											<i className="fas fa-sitemap"></i>
											<span>Master</span> &nbsp; &nbsp;
											<i className="fa fa-chevron-down"></i>
										</NavLink>
										<div
											id="collapseOne"
											// class={isMaster ? "collapse-show" : "collapse"}
											className={isMaster ? "collapse" : "collapse"}
											aria-labelledby="headingOne"
											data-parent="#accordion"
										>
											<div class="card-body">
												<li style={hideProductCampaign ? { display: "none" } : null}>
													<NavLink to="/product-campaign" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Product Campaign</span>
													</NavLink>
												</li>
												{<li style={hideProductCampaign ? { display: "none" } : null}>
													<NavLink to="/master-gold-audit" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Gold Audit Master</span>
													</NavLink>
												</li>}
												<li style={hideFrankingFee ? { display: "none" } : null}>
													<NavLink to="/master-franking-fee" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Franking Fee</span>
													</NavLink>
												</li>
												<li style={hideFrankingFee ? { display: "none" } : null}>
													<NavLink to="/master-product-funding-norms" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Product funding Norms</span>
													</NavLink>
												</li>
												<li style={hideProcessingFee ? { display: "none" } : null}>
													<NavLink to="/master-processing-fee" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Processing Fee (%)</span>
													</NavLink>
												</li>
												<li style={hideLegalValutionFee ? { display: "none" } : null}>
													<NavLink to="/master-legal-valuation-fee" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Legal & Valuation Fee</span>
													</NavLink>
												</li>
												<li style={hideRoi ? { display: "none" } : null}>
													<NavLink to="/master-roi" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>ROI(%)</span>
													</NavLink>
												</li>
												<li style={hideCollateral ? { display: "none" } : null}>
													<NavLink to="/master-collateral-ltv" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Collateral</span>
													</NavLink>
												</li>
												<li style={hideDfpMaster ? { display: "none" } : null}>
													<NavLink to="/master-dfp-master" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>DFP Master</span>
													</NavLink>
												</li>

												<li style={hideDfpMaster ? { display: "none" } : null}>
													<NavLink to="/master-income-multiplier" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Income Multiplier</span>
													</NavLink>
												</li>

												<li style={hideDfpMaster ? { display: "none" } : null}>
													<NavLink to="/master-family-member" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Family Member Expense</span>
													</NavLink>
												</li>

												<li style={hideDfpMaster ? { display: "none" } : null}>
													<NavLink to="/master-insurance-deduction" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Insurance Deduction</span>
													</NavLink>
												</li>

												<li style={hideSegmentMaster ? { display: "none" } : null}>
													<NavLink to="/master-segment" replace activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Segment Master</span>
													</NavLink>
												</li>
												<li style={hideProductMaster ? { display: "none" } : null}>
													<NavLink to="/master-product" replace activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Product Master</span>
													</NavLink>
												</li>

												<li style={hideLevelMaster ? { display: "none" } : null}>
													<NavLink to="/master-level" replace activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Level Master</span>
													</NavLink>
												</li>

												<li style={hideFundingGridMaster ? { display: "none" } : null}>
													<NavLink to="/master-funding" replace activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Funding Grid Master</span>
													</NavLink>
												</li>
												<li style={hideFundingGridMaster ? { display: "none" } : null}>
													<NavLink to="/master-MFGEMI" replace activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>MFG EMI Master</span>
													</NavLink>
												</li>
												<li style={hideCancelMaster ? { display: "none" } : null}>
													<NavLink to="/Cancel-master" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Cancellation Master</span>
													</NavLink>
												</li>
												<li style={hideServiceMaster ? { display: "none" } : null}>
													<NavLink to="/Service-master" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Implement Inventory Master</span>
													</NavLink>
												</li>

												<li style={hideVillageMaster ? { display: "none" } : null}>
													<NavLink to="/Village-master" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Village Master</span>
													</NavLink>
												</li>

												<li style={hideVillageMaster ? { display: "none" } : null}>
													<NavLink to="/Cancellation-per-master" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Cancellation Percentage Master</span>
													</NavLink>
												</li>
												{/* Payment */}
												{/* <li style={hidePayment ? { display: "none" } : null}>
													<NavLink to="/Payment-master" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Payout Master </span>
													</NavLink>
												</li> */}
												{/* <li style={hideSpokeMaster ? { display: "none" } : null}>
													<NavLink to="/VillageSpoke-master" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Zone-Region-Branch-Spoke-Mapping Master</span>
													</NavLink>
												</li> */}
												{/* <li style={hideWebNotificationMaster ? { display: "none" } : null}>
													<NavLink to="/Web-Notification-master" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Website Notification Master</span>
													</NavLink>
												</li> */}
											{/* </div> */}
										{/* </div> */}
									{/* </li>  */}
									<li>
										<NavLink
											to=""
											activeClassName="active"
											data-toggle="collapse"
											data-target="#collapseMarketingMasters"
											aria-expanded="true"
											aria-controls="collapseMarketingMasters"
											id="menu-toggle"
										>
											<i className="fas fa-sitemap"></i>
											<span>Marketing Master</span> &nbsp; &nbsp;
											<i className="fa fa-chevron-down"></i>
										</NavLink>
										<div
											id="collapseMarketingMasters"
											class={approval_master ? "collapse-show" : "collapse"}
											aria-labelledby="headingOne"
											data-parent="#accordion"
										>
											<div class="card-body">
												<li>
													<NavLink
														to="/campaign-medium"
														activeClassName="active"
													>
														<i class="fa fa-address-card-o"></i>
														<span>Campaign Medium Master</span>
													</NavLink>
												</li>
												<li>
													<NavLink
														to="/campaign-name"
														activeClassName="active"
													>
														<i class="fa fa-address-card-o"></i>
														<span>Campaign Name Master</span>
													</NavLink>
												</li>
											</div>
										</div>
									</li>
									{/* <li>
										<NavLink
											to=""
											activeClassName="active"
											data-toggle="collapse"
											data-target="#collapseOnBoardingMasters"
											aria-expanded="true"
											aria-controls="collapseOnBoardingMasters"
											id="menu-toggle"
										>
											<i className="fa fa-users"></i>
											<span>Onboarding Master</span> &nbsp; &nbsp;
											<i className="fa fa-chevron-down"></i>
										</NavLink>
										<div
											id="collapseOnBoardingMasters"
											class={on_boarding_master ? "collapse-show" : "collapse"}
											aria-labelledby="headingOne"
											data-parent="#accordion"
										>
											<div class="card-body">
												<li>
													<NavLink to="/gold-onboarding" activeClassName="active">
														<i class="fa fa-address-card-o"></i>
														<span>Gold Appraiser Onboarding</span>
													</NavLink>
												</li>

												<li>
													<NavLink to="/franchisee-onboarding" activeClassName="active">
														<i class="fa fa-address-card-o"></i>
														<span>Franchisee Onboarding</span>
													</NavLink>
												</li>
											</div>
										</div>
									</li> */}
									{/* <li>
										<NavLink
											to=""
											activeClassName="active"
											data-toggle="collapse"
											data-target="#collapseApprovalMasters"
											aria-expanded="true"
											aria-controls="collapseApprovalMasters"
											id="menu-toggle"
										>
											<i className="fa fa-check-square"></i>
											<span>Approval Master</span> &nbsp; &nbsp;
											<i className="fa fa-chevron-down"></i>
										</NavLink>
										<div
											id="collapseApprovalMasters"
											class={approval_master ? "collapse-show" : "collapse"}
											aria-labelledby="headingOne"
											data-parent="#accordion"
										>
											<div class="card-body">
												<li>
													<NavLink to="/gold-approval-master" activeClassName="active pb-2 pl-0 pr-0" style={{ whiteSpace: "pre" }}>
														<i class="fa fa-address-card-o"></i>
														<span>Gold Appraiser Approval Master</span>
													</NavLink>
												</li>
												<li>
													<NavLink to="/Franchise-approval-master" activeClassName="active">
														<i class="fa fa-address-card-o"></i>
														<span>Franchise Approval Master</span>
													</NavLink>
												</li>
											</div>
										</div>
									</li> */}


									{/* <li>
										<NavLink
											to=""
											activeClassName="active"
											data-toggle="collapse"
											data-target="#collapseOnAppraiserListingMasters"
											aria-expanded="true"
											aria-controls="collapseOnAppraiserListingMasters"
											id="menu-toggle"
										>
											<i className="fa fa-list-alt"></i>
											<span>Appraiser Listing Master</span> &nbsp; &nbsp;
											<i className="fa fa-chevron-down"></i>
										</NavLink>
										<div
											id="collapseOnAppraiserListingMasters"
											class={appraiser_listing_master ? "collapse-show" : "collapse"}
											aria-labelledby="headingOne"
											data-parent="#accordion"
										>
											<div class="card-body">
												<li>
													<NavLink to="/gold-appraiser-master-listing" activeClassName="active">
														<i class="fa fa-address-card-o"></i>
														<span>Gold Appraiser Listing</span>
													</NavLink>
												</li>
												<li>
													<NavLink to="/Franchise-appraiser-master-listing" activeClassName="active">
														<i class="fa fa-address-card-o"></i>
														<span>Franchise Appraiser Listing</span>
													</NavLink>
												</li>
											</div>
										</div>
									</li> */}
									{/* <li>
										<NavLink to="/change-password" activeClassName="active">
											<i className="fas fa-unlock-alt fa-lg"></i>
											<span>Change Password</span>
										</NavLink>
									</li> */}
								</>
							) : null
							}
							<li>
								<NavLink
									to=""
									activeClassName="active"
									data-toggle="collapse"
									data-target="#collapseOne"
									aria-expanded="true"
									aria-controls="collapseOne"
									id="menu-toggle"
								>
									<i className="fas fa-sitemap"></i>
									<span>Master</span> &nbsp; &nbsp;
									<i className="fa fa-chevron-down"></i>
								</NavLink>
								<div
									id="collapseOne"
									// class={isMaster ? "collapse-show" : "collapse"}
									className={isMaster ? "collapse" : "collapse"}
									aria-labelledby="headingOne"
									data-parent="#accordion"
								>
									<div class="card-body">
										<li style={hideProductCampaign ? { display: "none" } : null}>
											<NavLink to="/product-campaign" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Product Campaign</span>
											</NavLink>
										</li>
										{<li style={hideProductCampaign ? { display: "none" } : null}>
													<NavLink to="/master-gold-audit" activeClassName="active">
														<i class="fa fa-arrows-h"></i>
														<span>Gold Audit Master</span>
													</NavLink>
												</li>}
										<li style={hideFrankingFee ? { display: "none" } : null}>
											<NavLink to="/master-franking-fee" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Franking Fee</span>
											</NavLink>
										</li>
										<li style={hideFrankingFee ? { display: "none" } : null}>
											<NavLink to="/master-product-funding-norms" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Product funding Norms</span>
											</NavLink>
										</li>
										<li style={hideProcessingFee ? { display: "none" } : null}>
											<NavLink to="/master-processing-fee" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Processing Fee (%)</span>
											</NavLink>
										</li>
										<li style={hideLegalValutionFee ? { display: "none" } : null}>
											<NavLink to="/master-legal-valuation-fee" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Legal & Valuation Fee</span>
											</NavLink>
										</li>
										<li style={hideRoi ? { display: "none" } : null}>
											<NavLink to="/master-roi" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>ROI(%)</span>
											</NavLink>
										</li>
										<li style={hideCollateral ? { display: "none" } : null}>
											<NavLink to="/master-collateral-ltv" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Collateral</span>
											</NavLink>
										</li>
										<li style={hideDfpMaster ? { display: "none" } : null}>
											<NavLink to="/master-dfp-master" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>DFP Master</span>
											</NavLink>
										</li>

										<li style={hideDfpMaster ? { display: "none" } : null}>
											<NavLink to="/master-income-multiplier" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Income Multiplier</span>
											</NavLink>
										</li>

										<li style={hideDfpMaster ? { display: "none" } : null}>
											<NavLink to="/master-family-member" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Family Member Expense</span>
											</NavLink>
										</li>

										<li style={hideDfpMaster ? { display: "none" } : null}>
											<NavLink to="/master-insurance-deduction" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Insurance Deduction</span>
											</NavLink>
										</li>

										<li style={hideSegmentMaster ? { display: "none" } : null}>
											<NavLink to="/master-segment" replace activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Segment Master</span>
											</NavLink>
										</li>
										<li style={hideProductMaster ? { display: "none" } : null}>
											<NavLink to="/master-product" replace activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Product Master</span>
											</NavLink>
										</li>

										<li style={hideLevelMaster ? { display: "none" } : null}>
											<NavLink to="/master-level" replace activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Level Master</span>
											</NavLink>
										</li>

										<li style={hideFundingGridMaster ? { display: "none" } : null}>
											<NavLink to="/master-funding" replace activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Funding Grid Master</span>
											</NavLink>
										</li>
										<li style={hideFundingGridMaster ? { display: "none" } : null}>
											<NavLink to="/master-MFGEMI" replace activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>MFG EMI Master</span>
											</NavLink>
										</li>
										<li style={hideCancelMaster ? { display: "none" } : null}>
											<NavLink to="/Cancel-master" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Cancellation Master</span>
											</NavLink>
										</li>
										<li style={hideServiceMaster ? { display: "none" } : null}>
											<NavLink to="/Service-master" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Implement Inventory Master</span>
											</NavLink>
										</li>

										{/* <li style={hideVillageMaster ? { display: "none" } : null}>
											<NavLink to="/Village-master" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Village Master</span>
											</NavLink>
										</li> */}

										<li style={hideVillageMaster ? { display: "none" } : null}>
											<NavLink to="/Cancellation-per-master" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Cancellation Percentage Master</span>
											</NavLink>
										</li>
										{/* Payment */}
										<li style={hidePayment ? { display: "none" } : null}>
											<NavLink to="/Payment-master" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Payout Master </span>
											</NavLink>
										</li>
										{/* <li style={hideSpokeMaster ? { display: "none" } : null}>
											<NavLink to="/VillageSpoke-master" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Zone-Region-Branch-Spoke-Mapping Master</span>
											</NavLink>
										</li> */}
										<li style={hideWebNotificationMaster ? { display: "none" } : null}>
											<NavLink to="/Web-Notification-master" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Website Notification Master</span>
											</NavLink>
										</li>
									</div>
								</div>
							</li>



							<li>
								<NavLink
									to=""
									activeClassName="active"
									data-toggle="collapse"
									data-target="#collapseOneEmployeeMaster"
									aria-expanded="true"
									aria-controls="collapseOneEmployeeMaster"
									id="menu-toggle"
								>
									<i className="fas fa-sitemap"></i>
									<span>Employee Master</span> &nbsp; &nbsp;
									<i className="fa fa-chevron-down"></i>
								</NavLink>
								<div
									id="collapseOneEmployeeMaster"
									// class={isMaster ? "collapse-show" : "collapse"}
									className={isMaster ? "collapse" : "collapse"}
									aria-labelledby="headingOne"
									data-parent="#accordion"
								>
									<div class="card-body">
										<li style={hideEmployeeCreation ? { display: "none" } : null}>
											<NavLink to="/employee-creation" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Employee Creation</span>
											</NavLink>
										</li>
										</div>
								</div>
							</li>


							<li>
								<NavLink
									to=""
									activeClassName="active"
									data-toggle="collapse"
									data-target="#collapseOneGeographyMaster"
									aria-expanded="true"
									aria-controls="collapseOneGeographyMaster"
									id="menu-toggle"
								>
									<i className="fas fa-sitemap"></i>
									<span>Geography Master</span> &nbsp; &nbsp;
									<i className="fa fa-chevron-down"></i>
								</NavLink>
								<div
									id="collapseOneGeographyMaster"
									// class={isMaster ? "collapse-show" : "collapse"}
									className={isMaster ? "collapse" : "collapse"}
									aria-labelledby="headingOne"
									data-parent="#accordion"
								>
									<div class="card-body">
									<li style={hideBranchMaster ? { display: "none" } : null}>
											<NavLink to="/Branch-master" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Branch Master</span>
											</NavLink>
										</li>
										<li style={hideSpokeMaster ? { display: "none" } : null}>
											<NavLink to="/spoke-master" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Spoke Master</span>
											</NavLink>
										</li>
										</div>
								</div>
							</li>


							{/* <li>
								<NavLink
									to=""
									activeClassName="active"
									data-toggle="collapse"
									data-target="#collapseGeographyMasters"
									aria-expanded="true"
									aria-controls="collapseGeographyMasters"
									id="menu-toggle"
								>
									<i className="fa fa-users"></i>
									<span>Geography Master</span> &nbsp; &nbsp;
									<i className="fa fa-chevron-down"></i>
								</NavLink>
								<div
									id="collapseGeographyMasters"
									class={isMaster ? "collapse-show" : "collapse"}
									aria-labelledby="headingOne"
									data-parent="#accordion"
								>
									<div class="card-body">
									<li style={hideBranchMaster ? { display: "none" } : null}>
											<NavLink to="/Branch-master" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Branch Master</span>
											</NavLink>
										</li>
										<li style={hideSpokeMaster ? { display: "none" } : null}>
											<NavLink to="/spoke-master" activeClassName="active">
												<i class="fa fa-arrows-h"></i>
												<span>Spoke Master</span>
											</NavLink>
										</li>
									</div>
								</div>
							</li> */}
							
							
							<li>
								<NavLink
									to=""
									activeClassName="active"
									data-toggle="collapse"
									data-target="#collapseOnBoardingMasters"
									aria-expanded="true"
									aria-controls="collapseOnBoardingMasters"
									id="menu-toggle"
								>
									<i className="fa fa-users"></i>
									<span>Onboarding Master</span> &nbsp; &nbsp;
									<i className="fa fa-chevron-down"></i>
								</NavLink>
								<div
									id="collapseOnBoardingMasters"
									class={on_boarding_master ? "collapse-show" : "collapse"}
									aria-labelledby="headingOne"
									data-parent="#accordion"
								>
									<div class="card-body">
										<li>
											<NavLink to="/gold-onboarding" activeClassName="active">
												<i class="fa fa-address-card-o"></i>
												<span>Gold Appraiser Onboarding</span>
											</NavLink>
										</li>

										<li>
											<NavLink to="/franchisee-onboarding" activeClassName="active">
												<i class="fa fa-address-card-o"></i>
												<span>Franchisee Onboarding</span>
											</NavLink>
										</li>
									</div>
								</div>
							</li>
							<li>
								<NavLink
									to=""
									activeClassName="active"
									data-toggle="collapse"
									data-target="#collapseApprovalMasters"
									aria-expanded="true"
									aria-controls="collapseApprovalMasters"
									id="menu-toggle"
								>
									<i className="fa fa-check-square"></i>
									<span>Approval Master</span> &nbsp; &nbsp;
									<i className="fa fa-chevron-down"></i>
								</NavLink>
								<div
									id="collapseApprovalMasters"
									class={approval_master ? "collapse-show" : "collapse"}
									aria-labelledby="headingOne"
									data-parent="#accordion"
								>
									<div class="card-body">
										<li>
											<NavLink to="/gold-approval-master" activeClassName="active pb-2 pl-0 pr-0" style={{ whiteSpace: "pre" }}>
												<i class="fa fa-address-card-o"></i>
												<span>Gold Appraiser Approval Master</span>
											</NavLink>
										</li>
										<li>
											<NavLink to="/Franchise-approval-master" activeClassName="active">
												<i class="fa fa-address-card-o"></i>
												<span>Franchise Approval Master</span>
											</NavLink>
										</li>
									</div>
								</div>
							</li>
							<li>
								<NavLink
									to=""
									activeClassName="active"
									data-toggle="collapse"
									data-target="#collapseOnAppraiserListingMasters"
									aria-expanded="true"
									aria-controls="collapseOnAppraiserListingMasters"
									id="menu-toggle"
								>
									<i className="fa fa-list-alt"></i>
									<span>Appraiser Listing Master</span> &nbsp; &nbsp;
									<i className="fa fa-chevron-down"></i>
								</NavLink>
								<div
									id="collapseOnAppraiserListingMasters"
									class={appraiser_listing_master ? "collapse-show" : "collapse"}
									aria-labelledby="headingOne"
									data-parent="#accordion"
								>
									<div class="card-body">
										<li>
											<NavLink to="/gold-appraiser-master-listing" activeClassName="active">
												<i class="fa fa-address-card-o"></i>
												<span>Gold Appraiser Listing</span>
											</NavLink>
										</li>
										<li>
											<NavLink to="/Franchise-appraiser-master-listing" activeClassName="active">
												<i class="fa fa-address-card-o"></i>
												<span>Franchise Appraiser Listing</span>
											</NavLink>
										</li>
									</div>
								</div>
							</li>
							<li>
								<NavLink to="/change-password" activeClassName="active">
									<i className="fas fa-unlock-alt fa-lg"></i>
									<span>Change Password</span>
								</NavLink>
							</li>
							<li>
								<NavLink to="/CreateCampaign" activeClassName="active">
									<i className="fa fa-bell"></i>
									<span>Leaderboard Campaign Master</span>
								</NavLink >
							</li >
						</>
					) : (
						<></>
					)
					}
				</ul>
			</aside >
		);
	}
}

export default withRouter(LeftMenu);
