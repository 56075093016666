import { get, post } from "../httpInterceptor";

//list all Zones
export const getAllZones = () => {
  return get(`/mapping/getAllZones`).then((res) => {
    console.log("getAllZones", res);
    return res;
  });
};

//list all Regions
export const getAllRegions = () => {
  return get(`/mapping/getAllRegions`).then((res) => {
    console.log("getAllRegions", res);
    return res;
  });
};

//list all Branches
export const getAllBranches = () => {
  return get(`/mapping/getAllBranches`).then((res) => {
    console.log("getAllBranches", res);
    return res;
  });
};

//list all Pincodes
// export const getAllPincodes = () => {
//   return get(`/mapping/getAllPincodes`).then((res) => {
//     console.log("getAllPincodes", res);
//     return res;
//   });
// };

export const getAllPincodes = () => {
  return get(`/mapping/getUniquePincodesFromVillageMaster`).then((res) => {
    console.log("getAllPincodes", res);
    return res;
  });
};

//list all Mapping
export const findAllMapping = () => {
  return get(`/mapping/findAllMapping`).then((res) => {
    console.log("findAllMapping", res);
    return res;
  });
};

//save or update master

export const saveOrUpdateMappingMaster = (obj) => {
  return post(`/mapping/saveOrUpdateMappingMaster`, obj, false).then((res) => {
    console.log("saveOrUpdateMappingMaster", res);
    return res;
  });
};

//find village by pincode
export const findVillagesByPincode = (pincode) => {
  return post(
    `/mapping/findVillagesByPincode?pincode=${pincode}`,
    {},
    false
  ).then((res) => {
    console.log("findVillagesByPincode", res);
    return res;
  });
};

//Active or Inactive

export const setStatusActiveOrInactive = (obj) => {
  return post(`/mapping/setStatusActiveOrInactive`, obj, false).then((res) => {
    console.log("setStatusActiveOrInactive", res);
    return res;
  });
};
