import React, { useState, useEffect, Component } from 'react';
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from 'lodash';

import CloseModal from "../../../Components/CloseModal";
import { formatIndianCurrency, replaceComma } from "../../../Utils/helperFunctions";
import { addOrUpdateRot, getRoiById } from "../../../Utils/Services/roi";
import { getAllProducts, getAllSegmentList } from "../../../Utils/Services/common";
import { LoanTypeOption } from "../../../Utils/constant";

import "./style.css"
toast.configure();

const segmentOptions = [
    {
        "segmentdescription": "Farmer",
        "segmentcode": "Farmer",
        "id": 2104
    },
    {
        "segmentdescription": "Medium Small Enterprise",
        "segmentcode": "MSE",
        "id": 2105
    },
    {
        "segmentdescription": "Salaried",
        "segmentcode": "Salaried",
        "id": 2106
    }
];

let initForm = {
    product: "",
    segment: "",
    roi: "",
    type: "",
    level: "",
    id: null,
}
class AddEditRoi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            isLoading: false,
            showInner: false,
            form: cloneDeep(initForm),
            productDropdownValue: [],
            segmentDropdownValue: cloneDeep(segmentOptions),
            errors: {
                product: null,
                segment: null,
                roi: null,
                type: null,
                level: null,
            }
        }
    }


    componentDidMount = () => {
        let { form } = this.state;
        this.setState({ showModal: true });
        console.log("props.editData", this.props.editData)
        if (this.props.editData && this.props.action == "edit") {
            // this.setState({ ...form, ...this.props.editData });
            this.getRoiFromId(this.props.editData);
        }
        this.getProducts();
        // this.getSegments();
    }

    getRoiFromId = (roiData) => {
        let { form } = this.state;
        getRoiById({ id: roiData.id }).then((result) => {
            if (result.error) {
                return;
            } else if (result.data && result.data.error) {
                toast.error(result.data.message, {
                    type: toast.TYPE.ERROR,
                    autoClose: 2000
                });
                return;
            } else {
                console.log("result.data.data", result.data.data)

                if (result && result.data && result.data.data) {
                    this.setState({ form: { ...result.data.data } });
                }
            }
        })
    }

    getProducts = () => {
        getAllProducts().then((result) => {
            if (result.error) {
                return;
            } else if (result.data && result.data.error) {
                toast.error(result.data.message, {
                    type: toast.TYPE.ERROR,
                    autoClose: 2000
                });
                return;
            } else {
                if (result && result.data && result.data.data) {
                    this.setState({ productDropdownValue: result.data.data })

                }
            }
        })
    }

    getSegments = () => {
        getAllSegmentList().then((result) => {
            if (result.error) {
                return;
            } else if (result.data && result.data.error) {
                toast.error(result.data.message, {
                    type: toast.TYPE.ERROR,
                    autoClose: 2000
                });
                return;
            } else {
                if (result && result.data && result.data.data) {
                    this.setState({ segmentDropdownValue: result.data.data })

                }
            }
        })
    }

    handleModalClose = () => {
        this.setState({ showInner: !this.state.showInner });
        // this.this.props.addRole();
    };

    innerModalClose = modclose => {
        if (!modclose) {
            this.handleModalClose()
        };
        this.setState({ showModal: false })
        if (this.props.action == "edit") {
            this.props.handleEditRoi()
        } else {
            this.props.handleAddROi()
        }
    };

    handleSubmit = () => {
        let { form } = this.state;
        let obj = form;
        this.setState({ isLoading: true })
        addOrUpdateRot(obj).then((response) => {
            this.setState({ isLoading: false })
            if (response.data && response.data.error) {
                toast.error(response.data.message, {
                    type: toast.TYPE.ERROR,
                    autoClose: 2000
                });
                return
            } else {
                this.props.getRoi();
                this.setState({ showModal: false });
                toast.success(response.data.message, {
                    type: toast.TYPE.SUCCESS,
                    autoClose: 2000
                });
                this.props.handlePageChanges(0)
            }
        });
    }

    handleSegmentSelectChanges = (e, name) => {
        console.log("handleSegmentSelectChanges", e, name);
        let { form } = this.state;
        let { segmentcode } = e;
        form[name] = segmentcode;
        this.setState({ form });
    }

    handleProductSelectChange = (e, name) => {
        console.log("handleProductSelectChange", e, name)
        let { form } = this.state;
        let { productdescription } = e;
        form[name] = productdescription;
        this.setState({ form });
    }

    handleLoanTypeSelectChanges = (e, name) => {
        console.log("handleLoanTypeSelectChanges", e, name)
        let { form } = this.state;
        let { laonTypeValue } = e;
        form[name] = laonTypeValue;
        this.setState({ form });
    }

    handleLevelSelectChanges = (e, name) => {
        console.log("handleLevelSelectChanges", e, name)
        let { form } = this.state;
        let { levelcode } = e;
        form[name] = levelcode;
        this.setState({ form });
    }

    handleInputChange = (e, name) => {
        e.preventDefault();
        let { form } = this.state;
        console.log("handleInputChange", e, name, e.target.value)
        form[name] = e.target.value;
        this.setState({ form });
    }

    onValidate = (e, name) => {
        console.log("state*******", e);
        e.preventDefault();
        let { form, errors } = this.state;
        // let { product, loantype, lowerloanamtnorms, baseamt, state, percentagevalue } = form;
        if (form[name] == "" || form[name] == null || form[name] == undefined) {
            errors[name] = true;
            this.setState({ errors });
        } else {
            errors[name] = null;
            this.setState({ errors });
        }
    }

    render() {
        let { form, showModal, showInner, isLoading, productDropdownValue, segmentDropdownValue, errors } = this.state;
        let { levelDropdownOptions } = this.props;
        let diableSave = false;
        let isRequiredFeildFilled = false;
        Object.keys(form).map((name) => {
            console.log("form", form[name])
            if ((form[name] == "" || form[name] == null || form[name] == undefined) && name != "id") {
                isRequiredFeildFilled = true;
                return true
            }
        })

        errors && Object.keys(errors).map((error) => {
            console.log("error", errors[error])
            if (errors[error] == true && errors[error] != null) {
                diableSave = true;
                return true
            }
        })
        console.log("form", form)

        return (
            <>
                {levelDropdownOptions && levelDropdownOptions.length > 0 && productDropdownValue && productDropdownValue.length > 0 && segmentDropdownValue && segmentDropdownValue.length > 0 && LoanTypeOption && LoanTypeOption.length > 0 && <div>
                    <Modal
                        className="product-norm-container"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={showModal}
                        size="lg"
                    >
                        <Modal.Header>
                            <Modal.Title>
                                {this.props.action == "edit" ? "Edit ROI" : "Add ROI"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-4">
                            {isLoading ? (
                                <h4 className="text-center">LOADING...</h4>
                            ) : (
                                    <div className="row ">
                                        <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                                            <label>
                                                <i className="text-danger">*</i> Segment
                                                </label>
                                            <Select
                                                defaultValue={segmentDropdownValue.filter(({ segmentcode }) => segmentcode == form.segment)[0]}
                                                onChange={e => {
                                                    this.handleSegmentSelectChanges(e, "segment");
                                                }}
                                                onBlur={(e) => this.onValidate(e, "segment")}
                                                name="segment"
                                                options={segmentDropdownValue}
                                                placeholder="Select Segment"
                                                valueKey="segmentcode"
                                                labelKey="segmentdescription"
                                                getOptionLabel={option => option.segmentdescription}
                                                getOptionValue={option => option.segmentcode}
                                                className={`${errors && errors.segment ? "error-input-border" : ""
                                                    } `}
                                                isDisabled={false}
                                            />
                                            {errors && errors.segment && (
                                                <span className="errorMsg">
                                                    Please Select Segment
                                                </span>
                                            )}
                                        </div>
                                        <div className="mb-4 col-md-4 ">
                                            <label>
                                                <i className="text-danger">*</i> Type
                                                </label>
                                            <Select
                                                defaultValue={LoanTypeOption.filter(({ laonTypeValue }) => laonTypeValue == form.type)[0]}
                                                onChange={e => {
                                                    this.handleLoanTypeSelectChanges(e, "type");
                                                }}
                                                onBlur={(e) => this.onValidate(e, "type")}
                                                name="type"
                                                options={LoanTypeOption}
                                                placeholder="Select Loan Type"
                                                valueKey="laonTypeValue"
                                                labelKey="laonTypeLabel"
                                                getOptionLabel={option => option.laonTypeLabel}
                                                getOptionValue={option => option.laonTypeValue}
                                                className={`${errors && errors.type ? "error-input-border" : ""
                                                    } `}
                                                isDisabled={false}
                                            />
                                            {errors && errors.type && (
                                                <span className="errorMsg">
                                                    Please Enter Loan Type
                                                </span>
                                            )}
                                        </div>
                                        <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                                            <label>
                                                <i className="text-danger">*</i> Product
                                                </label>
                                            <Select
                                                defaultValue={productDropdownValue.filter(({ productdescription }) => productdescription == form.product)[0]}
                                                onChange={e => {
                                                    this.handleProductSelectChange(e, "product");
                                                }}
                                                onBlur={(e) => this.onValidate(e, "product")}
                                                name="product"
                                                options={productDropdownValue}
                                                placeholder="Select Product"
                                                valueKey="productdescription"
                                                labelKey="productdescription"
                                                getOptionLabel={option => option.productdescription}
                                                getOptionValue={option => option.productdescription}
                                                className={`${errors && errors.product ? "error-input-border" : ""
                                                    } `}
                                                isDisabled={false}
                                            />
                                            {errors && errors.product && (
                                                <span className="errorMsg">
                                                    Please Select Product
                                                </span>
                                            )}
                                        </div>
                                        <div className="mb-4 col-md-4 ">
                                            <label>
                                                <i className="text-danger">*</i> Level
                  </label>
                                            <Select
                                                defaultValue={
                                                    levelDropdownOptions.filter(
                                                        ({ levelcode }) => levelcode == form.level
                                                    )[0]
                                                }
                                                onChange={(e) => {
                                                    this.handleLevelSelectChanges(e, "level");
                                                }}
                                                onBlur={(e) => this.onValidate(e, "level")}
                                                name="level"
                                                options={levelDropdownOptions && levelDropdownOptions.length > 0 ? levelDropdownOptions : []}
                                                placeholder="Select level"
                                                valueKey="levelcode"
                                                labelKey="levelcode"
                                                getOptionLabel={(option) => option.levelcode}
                                                getOptionValue={(option) => option.levelcode}
                                                className={`${errors.level ? "error-input-border" : ""} `}
                                                isDisabled={false}
                                            />
                                            {errors.level && (
                                                <span className="errorMsg">Please Enter Level</span>
                                            )}
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-md-4">
                                            <label>
                                                <i className="text-danger">*</i> ROI(%)
                                        </label>
                                            <div class="inner-addon right-addon">
                                                <input
                                                    type="text"
                                                    // pattern="\d*"
                                                    name="roi"
                                                    value={form.roi}
                                                    onChange={e => {
                                                        if (
                                                            e.target.value == "" ||
                                                            (Math.ceil(e.target.value) <= 100 && parseInt(e.target.value) >= 0)
                                                        ) {
                                                            e.target.value = e.target.value
                                                                .toString()
                                                                .split(".")
                                                                .map((el, i) =>
                                                                    i ? el.split("").slice(0, 2).join("") : el
                                                                )
                                                                .join(".");
                                                            this.handleInputChange(e, "roi")
                                                        }

                                                    }}
                                                    onBlur={(e) => this.onValidate(e, "roi")}
                                                    placeholder="Please Enter ROI(%)"
                                                    className={`form-input ${errors && errors.roi ? "error-input-border"
                                                        : ""
                                                        } `}
                                                    disabled={false}
                                                />
                                                {errors && errors.roi && (
                                                    <span className="errorMsg">
                                                        Please Enter ROI(%)
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn-danger" onClick={this.handleModalClose}>
                                Cancel
                  </Button>
                            <Button className="btn-success" disabled={diableSave || isRequiredFeildFilled || isLoading} onClick={this.handleSubmit}>
                                {this.props.action == "edit" ? "Update" : "Create"}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <CloseModal
                        show={showInner}
                        button2={this.handleModalClose}
                        button1={this.innerModalClose}
                        title="Do you want to close ?"
                    />
                </div>}
            </>
        )
    }
}

export default AddEditRoi;
