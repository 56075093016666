import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import WebNotification from "./WebNotificationMaster";
import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import {
  findAllNotificationListing,
  deleteNotificationById,
} from "../../../Utils/Services/WebNotification";
toast.configure();

const WebNotificationMaster = (props) => {
  let [WebNotificationData, setWebNotificationData] = useState([]);
  let [showAddProductNormModal, setshowAddProductNormModal] = useState(false);
  let [showEditNormModal, setshowEditNormModal] = useState(false);
  let [editData, setEditData] = useState({});

  useEffect(() => {
    WebNotificationMaster();
  }, []);

  const WebNotificationMaster = () => {
    findAllNotificationListing().then((response) => {
      if (response.error) {
        return;
      }
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        setWebNotificationData(response.data.data);
      }
    });
  };

  const deleteWebNotificationById = (id) => {
    let postData = {
      id: id,
    };
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes, Delete It!",
          onClick: () => {
            deleteNotificationById(postData).then((response) => {
              console.log("deleteProductNormById", response);
              if (response.error) {
                return;
              }
              if (response.data && response.data.error) {
                toast.error(response.data.message, {
                  type: toast.TYPE.ERROR,
                  autoClose: 2000,
                });
              } else {
                toast.success(response.data.message, {
                  type: toast.TYPE.SUCCESS,
                  autoClose: 2000,
                });
                WebNotificationMaster();
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            // toast.error("Role Can Not be Deleted", {
            //   type: toast.TYPE.ERROR,
            //   autoClose: 2000
            // });
            // this.RoleList();
          },
        },
      ],
    });
  };

  const columns = [
    {
      Header: "SR. No",
      width: 70,
      Cell: (data) => {
        return data.index + 1;
      },
    },

    {
      Header: "Event",
      Cell: ({ original }) => {
        console.log("ori", original);
        return original && original.events
          ? original.events.map((res, index) => {
              return (
                <span key={`${index}`}>
                  {(index ? ", " : "") + res.eventName}
                </span>
              );
            })
          : null;
      },
    },
    {
      Header: "Notification Type",
      Cell: ({ original }) => {
        return original && original.notificationType
          ? original.notificationType
          : null;
      },
    },
    {
      Header: "Frequency",
      Cell: ({ original }) => {
        return original && original.frequency ? original.frequency : null;
      },
    },
    // {
    //   Header: "Start Date",
    //   Cell: ({ original }) => {
    //     return original && original.startDate ? original.startDate : "NA";
    //   },
    // },
    // {
    //   Header: "End Date",
    //   Cell: ({ original }) => {
    //     return original && original.endDate ? original.endDate : "NA";
    //   },
    // },

    // {
    //   Header: "Month Date",
    //   Cell: ({ original }) => {
    //     return original && original.selectMonthDate
    //       ? original.selectMonthDate
    //       : "NA";
    //   },
    // },

    // {
    //   Header: "Week Days",
    //   Cell: ({ original }) => {
    //     return original && original.weekdays
    //       ? original.weekdays.map((res, index) => {
    //           return (
    //             <span key={`${index}`}>
    //               {(index ? ", " : "") + res.weekdayName}
    //             </span>
    //           );
    //         })
    //       : "NA";
    //   },
    // },

    {
      Header: "Type",
      width: 200,
      Cell: ({ original }) => {
        return original && original.type ? original.type : "NA";
      },
    },

    {
      Header: "Title",
      width: 200,
      Cell: ({ original }) => {
        return original && original.title ? original.title : "NA";
      },
    },
    {
      Header: "Trigger Notification",
      width: 200,
      Cell: ({ original }) => {
        return original && original.triggerNotification
          ? original.triggerNotification
          : "NA";
      },
    },

    {
      Header: "Action",
      width: 125,
      disabled: false,
      accessor: "delete",
      accessor: "edit",

      Cell: ({ original }) => {
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              onClick={() => deleteWebNotificationById(original.id)}
              className="fa fa-trash"
              style={{ cursor: "pointer" }}
            />
            <i
              disabled={false}
              onClick={() => handleEditWebNotificationMaster(original)}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const handleAddWebNotification = (id) => {
    setshowAddProductNormModal(!showAddProductNormModal);
  };

  const handleEditWebNotificationMaster = (obj) => {
    setshowEditNormModal(!showEditNormModal);
    setEditData(obj);
  };

  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box">
            {" "}
            Notification Master
          </h5>
          <div className="d-flex align-items-center mt-5">
            <button
              disabled={false}
              onClick={handleAddWebNotification}
              className="add-btn ml-auto btn btn-info btn-lg"
            >
              {" "}
              Add{" "}
            </button>
            {showAddProductNormModal && (
              <WebNotification
                action={"add"}
                WebNotificationMaster={WebNotificationMaster}
                handleAddWebNotification={handleAddWebNotification}
              />
            )}
            {showEditNormModal && (
              <WebNotification
                action={"edit"}
                WebNotificationMaster={WebNotificationMaster}
                editData={editData}
                handleEditWebNotificationMaster={
                  handleEditWebNotificationMaster
                }
              />
            )}
          </div>

          <div className={`mt-4 react-table`}>
            <ReactTable
              data={WebNotificationData}
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default WebNotificationMaster;
