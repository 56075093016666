import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import { getAllLevel } from "../../../Utils/Services/levelmaster";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddEditLevelMaster from "../LevelMaster/AddEditLevelMaster";
toast.configure();

const LevelMaster = (props) => {
  let [editSegment, setEditSegement] = useState([]);
  let [level, setLevel] = useState([]);

  useEffect(() => {
    getLevelMaster();
  }, []);

  //getLevelMaster function for calling api and getdata
  const getLevelMaster = () => {
    getAllLevel().then((res) => {
      console.log("getAllLevel", res);
      if (res.error) {
        return;
      } else if (res.data && res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        if (res && res.data && res.data.data) {
          setLevel(res.data.data);
        }
      }
    });
  };

  const columns = [
    {
      Header: "SR. No",
      width: 70,
      Cell: (data) => {
        // console.log("data...", data)
        return data.index + 1;
      },
      /* Cell: ({ original }) => {
                return original.id && original.id ? original.id : null;
            } */
    },
    {
      Header: "Level Code",
      width: 200,
      Cell: ({ original }) => {
        return original.levelCode && original.levelCode
          ? original.levelCode
          : null;
      },
    },
    {
      Header: "Level Description",
      Cell: ({ original }) => {
        return original.levelDescription && original.levelDescription
          ? original.levelDescription
          : null;
      },
    },

    {
      Header: "Action",
      width: 125,
      disabled: false,
      accessor: "delete",
      accessor: "edit",

      Cell: ({ original }) => {
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              // onClick={() => handleEditRoi(original)}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box"> Level Master</h5>
          <div className="d-flex align-items-center mt-5"></div>

          <div className={`mt-4 react-table`}>
            <ReactTable data={level} columns={columns} defaultPageSize={10} />
          </div>
        </section>
      </main>
    </div>
  );
};

export default LevelMaster;
