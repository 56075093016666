import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddEditProductFundingNorms from "./AddEditProductFundingNorms";
import { formatIndianCurrency } from "../../../Utils/helperFunctions";
import {
  deleteProductFundById,
  getAllProductFund,
} from "../../../Utils/Services/productfund";
import {
  getAllProducts,
  getAllSegmentList,
  getAllLevelsDropdown,
} from "../../../Utils/Services/common";

toast.configure();

let data = [
  {
    product: "BL",
    segment: "MSE",
    productNormUpperLimit: "20000",
    productNormLowerLimit: "10000",
    level: "level 1",
    id: 1,
  },
];
const ProductFundingNorms = (props) => {
  let [search, setSearch] = useState("");
  let [productNormData, setproductNormData] = useState([]);
  let [segmentDropdownValue, setsegmentDropdownValue] = useState([]);
  let [levelDropdownOptions, setlevelDropdownOptions] = useState([]);
  let [productDropdownValue, setproductDropdownValue] = useState([]);
  let [showAddProductNormModal, setshowAddProductNormModal] = useState(false);
  let [showEditNormModal, setshowEditNormModal] = useState(false);
  let [editData, setEditData] = useState({});

  useEffect(() => {
    getProductNorm();
    getSegments();
    getLevelValue();
    getProducts();
  }, []);

  const getSegments = () => {
    getAllSegmentList().then((result) => {
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        toast.error(result.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        if (result && result.data && result.data.data) {
          setsegmentDropdownValue(result.data.data);
        }
      }
    });
  };

  const getLevelValue = () => {
    getAllLevelsDropdown().then((result) => {
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        toast.error(result.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        if (result && result.data && result.data.data) {
          setlevelDropdownOptions(result.data.data);
        }
      }
    });
  };

  const getProducts = () => {
    getAllProducts().then((result) => {
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        toast.error(result.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        if (result && result.data && result.data.data) {
          setproductDropdownValue(result.data.data);
        }
      }
    });
  };

  const getProductNorm = () => {
    getAllProductFund().then((res) => {
      console.log("getAllProductFund", res);
      if (res.error) {
        return;
      }
      if (res.data && res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        /* toast.success(res.data.message, {
                    type: toast.TYPE.SUCCESS,
                    autoClose: 2000
                }); */
        setproductNormData(res.data.data);
      }
    });
  };

  const deleteProductNormById = (id) => {
    let postData = {
      id: id,
    };
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes, Delete It!",
          onClick: () => {
            deleteProductFundById(postData).then((response) => {
              console.log("deleteProductNormById", response);
              if (response.error) {
                return;
              }
              if (response.data && response.data.error) {
                toast.error(response.data.message, {
                  type: toast.TYPE.ERROR,
                  autoClose: 2000,
                });
              } else {
                toast.success(response.data.message, {
                  type: toast.TYPE.SUCCESS,
                  autoClose: 2000,
                });
                getProductNorm();
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            // toast.error("Role Can Not be Deleted", {
            //   type: toast.TYPE.ERROR,
            //   autoClose: 2000
            // });
            // this.RoleList();
          },
        },
      ],
    });
  };

  const columns = [
    {
      Header: "SR. No",
      width: 70,
      Cell: (data) => {
        // console.log("data...", data)
        return data.index + 1;
      },
      /* Cell: ({ original }) => {
                return original.id && original.id ? original.id : null;
            } */
    },
    {
      Header: "Product",
      width: 200,
      Cell: ({ original }) => {
        return original && original.product ? original.product : null;
      },
    },
    {
      Header: "Segment",
      Cell: ({ original }) => {
        return original && original.segment ? original.segment : null;
      },
    },
    {
      Header: "Level",
      Cell: ({ original }) => {
        return original && original.level ? original.level : null;
      },
    },
    {
      Header: "Product Norm (In Rupees)",
      Cell: (data) => {
        let { original } = data;
        let text = "Not applicable";
        if (
          original.productNormUpperLimit != "" &&
          original.productNormLowerLimit != "" &&
          original.productNormUpperLimit != undefined &&
          original.productNormLowerLimit != undefined /* &&
          original.productNormUpperLimit != 0 &&
          original.productNormLowerLimit != 0 */
        ) {
          text = `${
            original.productNormLowerLimit &&
            formatIndianCurrency(original.productNormLowerLimit)
          }-${
            original.productNormUpperLimit &&
            formatIndianCurrency(original.productNormUpperLimit)
          }`;
        } else if (
          (original.productNormUpperLimit != undefined &&
            original.productNormLowerLimit != undefined) ||
          (original.productNormUpperLimit == 0 &&
            original.productNormLowerLimit == 0)
        ) {
          text = `Not applicable`;
        }
        return text;
      },
    },
    {
      Header: "Action",
      width: 125,
      disabled: false,
      accessor: "delete",
      accessor: "edit",

      Cell: ({ original }) => {
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              onClick={() => deleteProductNormById(original.id)}
              className="fa fa-trash"
              style={{ cursor: "pointer" }}
            />
            <i
              disabled={false}
              onClick={() => handleEditProductNorm(original)}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const handleAddProductNorm = (id) => {
    setshowAddProductNormModal(!showAddProductNormModal);
  };

  const handleEditProductNorm = (obj) => {
    setshowEditNormModal(!showEditNormModal);
    setEditData(obj);
  };

  const handleSearch = (e) => {
    /* let { roleContainer } = this.state;
        let array = [];
        roleContainer.map(res => {
            let roleName = res.roleName;
            if (roleName)
                if (res.roleName.match(e.target.value.toUpperCase())) {
                    array.push(res);
                }
        });
        this.setState({
            search: e.target.value,
            roleData: e.target.value ? array : roleContainer
        }); */
  };

  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box">
            {" "}
            Product funding norms
          </h5>
          <div className="d-flex align-items-center mt-5">
            <button
              disabled={false}
              onClick={handleAddProductNorm}
              className="add-btn ml-auto btn btn-info btn-lg"
            >
              {" "}
              Add norm{" "}
            </button>
            {showAddProductNormModal && (
              <AddEditProductFundingNorms
                action={"add"}
                getProductNorm={getProductNorm}
                handleAddProductNorm={handleAddProductNorm}
                productDropdownValue={productDropdownValue}
                segmentDropdownValue={segmentDropdownValue}
                levelDropdownOptions={levelDropdownOptions}
              />
            )}
            {showEditNormModal && (
              <AddEditProductFundingNorms
                action={"edit"}
                getProductNorm={getProductNorm}
                editData={editData}
                handleEditProductNorm={handleEditProductNorm}
                productDropdownValue={productDropdownValue}
                segmentDropdownValue={segmentDropdownValue}
                levelDropdownOptions={levelDropdownOptions}
              />
            )}
          </div>

          <div className={`mt-4 react-table`}>
            <ReactTable
              data={productNormData}
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default ProductFundingNorms;
