import { get, post } from "../httpInterceptor";

// API for get all legal valuation

export const getAllLegalValution = () => {
  return get(`/sarvagram-credit/master/findalllegalvaluationfee`).then(
    (res) => {
      console.log("RESPONSE :: getAllLegalValution ::: ", res);
      return res;
    }
  );
};

// API for save or update legal valuation

export const addOrUpdateLegalValution = (obj) => {
  return post(
    `/sarvagram-credit/master/saveorupdatelegalvauation`,
    obj,
    false
  ).then((res) => {
    console.log("addOrUpdateLegalValution", res);
    return res;
  });
};
