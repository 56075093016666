import { get, post, awspost } from "../httpInterceptor";
const CORS_URL = "https://cors-anywhere.herokuapp.com/";
// getfranchiselist
export const FranchiseApprovalList = (obj) => {
  return post(`/franchise/getfranchiselist`, obj, false).then((res) => {
    console.log("FranchiseApprovalList", res);
    return res;
  });
};

export const getFranchiseAppraiserMasterList = (obj) => {
  return post(`/franchise/getfranchiselist`, obj, false).then((res) => {
    console.log("getFranchiseAppraiserMasterList", res);
    return res;
  });
};

//francjhise by app id
export const findfranchiseByApplication = (obj) => {
  return post(`/franchise/getfranchiseByAppId`, obj, false).then((res) => {
    console.log("findfranchiseByApplication", res);
    return res;
  });
};

//save and update franchiselist
export const saveOrUpdatefranchise = (obj) => {
  return post(`/franchise/saveorupdatefranchise`, obj, false).then((res) => {
    console.log("saveOrUpdatefranchise", res);
    return res;
  });
};

//upload documents

export const uploadFranchiseAppraiserDoc = (obj) => {
  return post(`/franchise/uploadDoc`, obj, false, true).then((res) => {
    console.log("uploadFranchiseAppraiserDoc", res);
    return res;
  });
};

//check dedupe franchiseCode
export const checkFranchiseDedupe = (obj) => {
  return post(`/franchise/checkfranchiserispresent`, obj, false).then((res) => {
    console.log("checkFranchiseDedupe", res);
    return res;
  });
};

//delete by id

export const deleteDocumentById = (obj) => {
  return post(`/franchise/deleteDocument`, obj, false).then((res) => {
    console.log("deleteDocumentById", res);
    return res;
  });
};

export const checkVillageIsAlreadyMappedToFranchiseOnUpdate = (obj) => {
  return post(
    `/franchise/checkVillageIsAlreadyMappedToFranchiseOnUpdate`,
    obj,
    false
  ).then((res) => {
    console.log("checkVillageIsAlreadyMappedToFranchiseOnUpdate", res);
    return res;
  });
};
