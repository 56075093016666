import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LeftMenu from "../../../../Components/LeftMenu";
import Header from "../../../../Components/Header";
import ReactTable from "react-table";
import { FullScreenLoader } from "../../../../Components/Assets/Loader";
import { getFranchiseAppraiserMasterList } from "../../../../Utils/Services/FranchiseAppraiser";
import "react-table/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FranchiseAppraiser from "./FranchiseAppraiser";
toast.configure();

class FranchiseAppraiserMasterListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showModal: false,
      search: "",
      appraiserData: [],
      appraiserContainer: [],
      verifyObj: "",
      status: "",
    };
  }

  componentDidMount = () => {
    this.getFranchiseAppraiser();
  };

  getFranchiseAppraiser = () => {
    let obj = {
      status: "Approved",
    };
    this.setState({ loading: true });
    getFranchiseAppraiserMasterList(obj).then((res) => {
      this.setState({ loading: false });
      if (res.error) {
        this.setState({ loading: false });

        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        // console.log("res.data/.///", res.data);
        if (res.data && res.data.data && res.data.data.length > 0) {
          this.setState({
            appraiserData: res.data.data,
            appraiserContainer: res.data.data,
          });
        }
      }
    });
  };

  addAppraiser = (id, status) => {
    let { appraiserData } = this.state;
    let appraiserDataTemp = appraiserData.find(
      (appraiserData) => appraiserData.applicationnumber === id
    );

    this.setState({
      showModal: !this.state.showModal,
      verifyObj: appraiserDataTemp ? appraiserDataTemp.applicationnumber : null,
      status: status,
    });
  };

  handleModalAction = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  search = (e) => {
    let { appraiserContainer } = this.state;
    let array = [];
    appraiserContainer.map((res) => {
      let name = res.appraiserCode;

      if (
        res.franchiseCode ||
        (res.companyname && res.companyname) ||
        (res.companypan && res.companypan) ||
        (res.emailid && res.emailid) ||
        (res.mobilenumber && res.mobilenumber) ||
        (res.status && res.status) ||
        (res.updatedOn && res.updatedOn)
      )
        if (
          (res &&
            res.franchiseCode &&
            res.franchiseCode
              .toLowerCase()
              .match(
                e && e.target && e.target.value && e.target.value.toLowerCase()
              )) ||
          (res &&
            res.companypan &&
            res.companypan
              .toLowerCase()
              .match(
                e && e.target && e.target.value && e.target.value.toLowerCase()
              )) ||
          (res &&
            res.companyname &&
            res.companyname
              .toLowerCase()
              .match(
                e && e.target && e.target.value && e.target.value.toLowerCase()
              )) ||
          (res &&
            res.emailid &&
            res.emailid
              .toLowerCase()
              .match(
                e && e.target && e.target.value && e.target.value.toLowerCase()
              )) ||
          (res &&
            res.mobilenumber &&
            res.mobilenumber
              .toString()
              .toLowerCase()
              .match(
                e && e.target && e.target.value && e.target.value.toLowerCase()
              )) ||
          (res &&
            res.status &&
            res.status
              .toString()
              .toLowerCase()
              .match(
                e && e.target && e.target.value && e.target.value.toLowerCase()
              ))
        ) {
          array.push(res);
        }
    });
    this.setState({
      search: e.target.value,
      appraiserData: e.target.value ? array : appraiserContainer,
    });
  };

  render() {
    let { loading, appraiserData, verifyObj, status, showModal } = this.state;

    const columns = [
      {
        Header: "Application Number",

        Cell: ({ original }) => {
          return original.franchiseCode;
        },
      },
      {
        Header: "Company Name",

        Cell: ({ original }) => {
          return original.companyname;
        },
      },
      {
        Header: "Company Pan",

        Cell: ({ original }) => {
          return original.companypan && original.companypan;
        },
      },
      {
        Header: "Email Id",

        Cell: ({ original }) => {
          return original.emailid;
        },
      },
      {
        Header: "Mobile Number",

        Cell: ({ original }) => {
          return original.mobilenumber;
        },
      },
      {
        Header: "status",

        Cell: ({ original }) => {
          return original.status;
        },
      },
      {
        Header: "Updated On",
        accessor: "updatedOn",

        Cell: ({ original }) => {
          return original.updatedOn;
        },
      },
      {
        Header: "Action",
        accessor: "edit",

        Cell: ({ original }) => {
          return (
            <div disabled={false}>
              <i
                onClick={() =>
                  this.addAppraiser(original.applicationnumber, original.status)
                }
                className="fas fa-user-edit"
                style={{ cursor: "pointer" }}
              />
            </div>
          );
        },
      },
    ];
    return (
      <div className="dash_grid">
        <FullScreenLoader />
        <LeftMenu />
        <main className="bg-white">
          <Header {...this.props} />
          <section className="container-fluid">
            <ToastContainer />
            <h5 className="text-center mt-5 mx-auto user-box">
              Franchise Appraiser Master Listing
            </h5>
            {!loading ? (
              <React.Fragment>
                <div className="d-flex align-items-center mt-5">
                  <div className="form-group m-0 search-input">
                    <div className="inner-addon right-addon">
                      <i className="glyphicon glyphicon-search mt-1 font-size-20"></i>
                      <input
                        type="text"
                        className="form-input"
                        name="search"
                        placeholder="Search..."
                        onChange={this.search}
                      />
                    </div>
                  </div>
                </div>
                <div className={`mt-4 react-table`}>
                  <ReactTable
                    scroll={{ x: "max-content", y: 350 }}
                    data={appraiserData}
                    columns={columns}
                    defaultPageSize={10}
                    className="-striped -highlight"
                  />
                </div>
              </React.Fragment>
            ) : (
              <h4 className="text-center mt-5">LOADING...</h4>
            )}
            {showModal && (
              <FranchiseAppraiser
                addAppraiser={this.addAppraiser}
                verifyObj={verifyObj}
                status={status}
                fetchAppraiser={this.getFranchiseAppraiser}
                handleModalAction={this.handleModalAction}
              />
            )}
          </section>
        </main>
      </div>
    );
  }
}

export default withRouter(FranchiseAppraiserMasterListing);
