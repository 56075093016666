import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddEditPayment from "./AddEditPayment";
import { formatIndianCurrency } from "../../../Utils/helperFunctions";
import {
  deleteProductFundById,
  getAllPayoutMasterDetails,
  getFranchiseeList,
  deletepayoutmasterId,
} from "../../../Utils/Services/productfund";
toast.configure();

export const Payment = (props) => {
  let [search, setSearch] = useState("");
  let [productNormData, setproductNormData] = useState([]);
  //   let [segmentDropdownValue, setsegmentDropdownValue] = useState([]);
  //   let [levelDropdownOptions, setlevelDropdownOptions] = useState([]);
  let [franchiseeDropdownValue, setfranchiseeDropdownValue] = useState([]);
  let [showAddProductNormModal, setshowAddProductNormModal] = useState(false);
  let [showEditNormModal, setshowEditNormModal] = useState(false);
  let [editData, setEditData] = useState({});

  useEffect(() => {
    getPayoutMasterDetails();
    FranchiseeList();
  }, []);

  const getPayoutMasterDetails = () => {
    getAllPayoutMasterDetails().then((res) => {
      console.log("getPayoutMasterDetails", res);
      if (res.error) {
        return;
      }
      if (res.data && res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        /* toast.success(res.data.message, {
                    type: toast.TYPE.SUCCESS,
                    autoClose: 2000
                }); */
        setproductNormData(res.data.data);
      }
    });
  };

  const FranchiseeList = () => {
    getFranchiseeList().then((result) => {
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        toast.error(result.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        if (result && result.data && result.data.data) {
          setfranchiseeDropdownValue(result.data.data);
        }
      }
    });
  };

  const deleteProductNormById = (id) => {
    let postData = {
      id: id,
    };
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes, Delete It!",
          onClick: () => {
            deletepayoutmasterId(postData).then((response) => {
              console.log("deleteProductNormById", response);
              if (response.error) {
                return;
              }
              if (response.data && response.data.error) {
                toast.error(response.data.message, {
                  type: toast.TYPE.ERROR,
                  autoClose: 2000,
                });
              } else {
                toast.success(response.data.message, {
                  type: toast.TYPE.SUCCESS,
                  autoClose: 2000,
                });
                getPayoutMasterDetails();
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            // toast.error("Role Can Not be Deleted", {
            //   type: toast.TYPE.ERROR,
            //   autoClose: 2000
            // });
            // this.RoleList();
          },
        },
      ],
    });
  };

  const columns = [
    {
      Header: "SR. No",
      width: 70,
      Cell: (data) => {
        // console.log("data...", data)
        return data.index + 1;
      },
    },
    {
      Header: "Franchisee",
      width: 200,
      Cell: ({ original }) => {
        return original && original.franchisee ? original.franchisee : null;
      },
    },
    {
      Header: "SG share",
      Cell: ({ original }) => {
        return original && original.sgShare ? original.sgShare : null;
      },
    },
    {
      Header: "Franchisee Share",
      Cell: ({ original }) => {
        return original && original.franchiseeShare
          ? original.franchiseeShare
          : null;
      },
    },

    {
      Header: "Action",
      width: 125,
      disabled: false,
      accessor: "delete",
      accessor: "edit",

      Cell: ({ original }) => {
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              onClick={() => deleteProductNormById(original.id)}
              className="fa fa-trash"
              style={{ cursor: "pointer" }}
            />
            <i
              disabled={false}
              onClick={() => handleEditProductNorm(original)}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const handleAddProductNorm = (id) => {
    setshowAddProductNormModal(!showAddProductNormModal);
  };

  const handleEditProductNorm = (obj) => {
    setshowEditNormModal(!showEditNormModal);
    setEditData(obj);
  };

  return (
    <div>
      <div className="dash_grid">
        <ToastContainer />
        <LeftMenu />
        <main className="bg-white">
          <Header {...props} />
          <section className="container-fluid mb-5">
            <h5 className="text-center mt-5 mx-auto user-box">
              {" "}
              Share of Payout Master
            </h5>
            <div className="d-flex align-items-center mt-5">
              <button
                disabled={false}
                onClick={handleAddProductNorm}
                className="add-btn ml-auto btn btn-info btn-lg"
              >
                {" "}
                Add Share{" "}
              </button>
              {showAddProductNormModal && (
                <AddEditPayment
                  action={"add"}
                  getPayoutMasterDetails={getPayoutMasterDetails}
                  handleAddProductNorm={handleAddProductNorm}
                  franchiseeDropdownValue={franchiseeDropdownValue}
                  //   segmentDropdownValue={segmentDropdownValue}
                  //   levelDropdownOptions={levelDropdownOptions}
                />
              )}
              {showEditNormModal && (
                <AddEditPayment
                  action={"edit"}
                  getPayoutMasterDetails={getPayoutMasterDetails}
                  editData={editData}
                  handleEditProductNorm={handleEditProductNorm}
                  franchiseeDropdownValue={franchiseeDropdownValue}
                  //   segmentDropdownValue={segmentDropdownValue}
                  //   levelDropdownOptions={levelDropdownOptions}
                />
              )}
            </div>

            <div className={`mt-4 react-table`}>
              <ReactTable
                data={productNormData}
                columns={columns}
                defaultPageSize={10}
              />
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default Payment;
