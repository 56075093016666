import React, { useState, useEffect, Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from "lodash";

import CloseModal from "../../../Components/CloseModal";
import {
    formatIndianCurrency,
    replaceComma,
    checkIfNotDecimal,
} from "../../../Utils/helperFunctions";
import {
    saveorupdatecancellationPermaster,
} from "../../../Utils/Services/CancelPerMaster";

toast.configure();

const initForm = {
    percentage: 0,
    id: null,
    errors: {
        percentage: null,
    },
};

export class AddEditPerCancel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            isLoading: false,
            showInner: false,
            form: cloneDeep(initForm),
        };
    }

    componentDidMount = () => {
        let { form } = this.state;
        this.setState({ showModal: true });
        if (this.props.editData && this.props.action == "edit") {
            let { form } = this.state;
            let obj = { ...form, ...this.props.editData };
            this.setState({ form: obj });
        }
    };

    handleModalClose = () => {
        this.setState({ showInner: !this.state.showInner });
    };

    innerModalClose = (modclose) => {
        if (!modclose) {
            this.handleModalClose();
        }
        this.setState({ showModal: false });
        this.props.handleEditCancelMaster();
    };

    handleSubmit = () => {
        let { form } = this.state;
        let obj = form;
        this.setState({ isLoading: true });
        saveorupdatecancellationPermaster(obj).then((response) => {
            this.setState({ isLoading: false });
            if (response.data && response.data.error) {
                toast.error(response.data.message, {
                    type: toast.TYPE.ERROR,
                    autoClose: 2000,
                });
                return;
            } else {
                this.props.cancelMaster();
                this.props.handleEditCancelMaster();
                this.setState({ showModal: false });
                toast.success(response && response.data && response.data.message, {
                    type: toast.TYPE.SUCCESS,
                    autoClose: 2000,
                });
            }
        });
    };

    handleInputChange = (e, name) => {
        e.preventDefault();
        let { form } = this.state;
        form[name] = e.target.value;
        this.setState({ form });
    }

    onValidate = (e, name) => {
        e.preventDefault();
        let { form } = this.state;
        if (form[name] === "" || form[name] === null || form[name] === undefined) {
            form.errors[name] = true;
            this.setState({ form });
        } else {
            form.errors[name] = null;
            this.setState({ form });
        }
    };

    render() {
        let { form, showModal, showInner, isLoading } = this.state;
        let { errors } = form;
        let diableSave = false;
        let isRequiredFeildFilled = false;

        Object.keys(form).map((name) => {
            if (
                form[name] === "" ||
                form[name] === null ||
                form[name] === undefined
            ) {
                isRequiredFeildFilled = true;
                return true;
            }
        });

        Object.keys(errors).map((error) => {
            if (errors[error] === true || errors[error] != null) {
                diableSave = true;
                return true;
            }
        });
        return (
            <React.Fragment>
                <Modal
                    className="product-norm-container"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showModal}
                    size="lg"
                >
                    <Modal.Header>
                        <Modal.Title>
                            Allowed % value for cancellation
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        {isLoading ? (
                            <h4 className="text-center">LOADING...</h4>
                        ) : (
                            <div className="row ">
                                <div className=" col-md-12 col-lg-12 col-sm-12">
                                    <div className="p-3 col-md-4 col-lg-4 col-sm-12">
                                        <label>
                                            <i className="text-danger">*</i> Allowed % value for cancellation
                                        </label>
                                    </div>
                                    <div className="inner-addon right-addon col-md-6 col-lg-6">
                                        <input
                                            type="text"
                                            // pattern="\d*"
                                            name="percentage"
                                            value={form.percentage}
                                            onChange={e => {
                                                if (
                                                    e.target.value == "" ||
                                                    (Math.ceil(e.target.value) <= 100 && parseInt(e.target.value) >= 0)
                                                ) {
                                                    e.target.value = e.target.value
                                                        .toString()
                                                        .split(".")
                                                        .map((el, i) =>
                                                            i ? el.split("").slice(0, 2).join("") : el
                                                        )
                                                        .join(".");
                                                    this.handleInputChange(e, "percentage")
                                                }

                                            }}
                                            onBlur={(e) => this.onValidate(e, "percentage")}
                                            placeholder="Please Enter Allowed % value for cancellation"
                                            className={`form-input ${errors && errors.percentage ? "error-input-border"
                                                : ""
                                                } `}
                                            disabled={false}
                                        />
                                        {errors && errors.percentage && (
                                            <span className="errorMsg">
                                                Please Enter Allowed % value for cancellation
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-danger" onClick={this.handleModalClose}>
                            Cancel
            </Button>
                        <Button
                            className="btn-success"
                            disabled={diableSave || isRequiredFeildFilled || isLoading}
                            onClick={this.handleSubmit}
                        >
                            Update
            </Button>
                    </Modal.Footer>
                </Modal>
                <CloseModal
                    show={showInner}
                    button2={this.handleModalClose}
                    button1={this.innerModalClose}
                    title="Do you want to close ?"
                />
            </React.Fragment>
        );
    }
}

export default AddEditPerCancel;
