import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddCampaignMediumMaster from "./AddCampaignMediumMaster";
import { deleteCampaignMediumById, getAllCampaignMedium, saveUpdateCampaignMedium } from "../../../Utils/Services/CampaignMedium";

toast.configure();

const CampaignMediumMaster = (props) => {
	let [showAddModal, setShowAddModal] = useState(false);
	let [showEditModal, setShowEditModal] = useState(false);
	let [campaignMediumData, setCampaignMediumData] = useState([]);
	let [editData, setEditData] = useState({});

	useEffect(() => {
		getCampaignMediumMaster();
	}, []);

    const getCampaignMediumMaster = () => {
		//product campaign api call will be here
		getAllCampaignMedium().then((response) => {
			if (response.error) {
				return;
			}
			if (response.data && response.data.error) {
				toast.error(response.data.message, {
					type: toast.TYPE.ERROR,
					autoClose: 2000,
				});
			} else {
				setCampaignMediumData(response.data.data);
			}
		});
		// setProductCampaignData(SAMPLE_DATA);
	};

    const deleteCampaignMedium = (id) => {
        //delete api call will be here
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        console.log("yes ", id);
                        deleteCampaignMediumById(id)
                            .then((res) => {
                                if (res.error) {
                                    toast.error(res.error);
                                } else {
                                    toast.success("Deleted Successfully");
                                    // setProductCampaignData(productCampaignData.filter((item) => item.id !== id));
									setCampaignMediumData();
                                    getCampaignMediumMaster();
                                }
                            })
                            .catch((err) => {
                                console.log("err", err);
                            });
                    },
                },
                {
					label: "No",
					onClick: () => {
						console.log("no");
					},
				},
			],
		});
	};
    const columns = [
		{
			Header: "SR. No",
			// width: 70,
			Cell: (data) => {
				// console.log("dat/a...", data)
				return data.index + 1;
			},
		},
        {
			Header: "Campaign Medium",
			// width: 250,
			Cell: (data) => {
				const { original } = data;
				return original && original.campaignMedium ? original.campaignMedium : null;
			},
		},
        {
			Header: "Campaign Medium Description",
			// width: 250,
			Cell: (data) => {
				const { original } = data;
				return original && original.campaignMediumDescription ? original.campaignMediumDescription : null;
			},
		},
		{
			Header: "Product Type",
			// width: 250,
			Cell: (data) => {
				const { original } = data;
				return original && original.productType ? original.productType : null;
			},
		},
		{
			Header: "Campaign Start Date",
			// width: 250,
			Cell: (data) => {
				const { original } = data;
				return original && original.startDate ? original.startDate : null;
			},
		},
        {
			Header: "Campaign End Date",
			// width: 250,
			Cell: (data) => {
				const { original } = data;
				return original && original.endDate ? original.endDate : null;
			},
		},
		{
			Header: "Action",
			// width: 125,
			disabled: false,
			accessor: "delete",
			accessor: "edit",

			Cell: ({ original }) => {
				return (
					<div disabled={false} style={{ display: "flex", justifyContent: "space-around" }}>
						<i disabled={false} onClick={() => deleteCampaignMedium(original.campaignMediumId)} className="fa fa-trash" style={{ cursor: "pointer" }} />
                        <i
							disabled={false}
							onClick={() => handleEditCampaignModal(original)}
							className="fas fa-user-edit"
							style={{ cursor: "pointer" }}
						/>
					</div>
				);
			},
		},
	];
    const handleAddCampaignModal = (obj) => {
		// setProductCampaignData([...productCampaignData, obj]);
		setShowAddModal(!showAddModal);
	};

	const handleEditCampaignModal = (obj) => {
		setShowEditModal(!showEditModal);
		setEditData(obj);
	};
    const addCampaignMedium = (obj) => {
		setShowAddModal(!showAddModal);
		let newCampaign = {
			...obj,
			startDate: format(obj.startDate, "yyyy-MM-dd"),
			endDate: format(obj.endDate, "yyyy-MM-dd"),
		};
		console.log("newCampaign...", newCampaign);
		saveUpdateCampaignMedium(newCampaign)
			.then((res) => {
				if (res.error) {
					toast.error(res.data.message);
				} else {
					if (res.data.error) {
						toast.error(res.data.message);
					} else {
						toast.success(res.data.message);
						getCampaignMediumMaster();
					}
				}
			})
            .catch((err) => {
				console.log("err", err);
			});
	};
    const editCampaignMedium = (obj) => {
		setShowEditModal(!showEditModal);
		let editedCampaign = {
			...obj,
			startDate: format(obj.startDate, "yyyy-MM-dd"),
			endDate: format(obj.endDate, "yyyy-MM-dd"),
		};
        console.log("editedCampaign...", editedCampaign);
		saveUpdateCampaignMedium(editedCampaign)
			.then((res) => {
				if (res.error) {
					toast.error("Server Error");
				} else {
					if (res.data.error) {
						toast.error("Error Updating Campaign");
					} else {
						toast.success("Campaign Updated Successfully");
						getCampaignMediumMaster();
					}
				}
			})
			.catch((err) => {
				console.log("err", err);
			});
	};
    return (
		<div className="dash_grid">
			<ToastContainer />
			<LeftMenu />
			<main className="bg-white">
				<Header {...props} />
				<section className="container-fluid mb-5">
					<h5 className="text-center mt-5 mx-auto user-box"> Campaign Medium Master</h5>
					<div className="d-flex align-items-center mt-5">
						<button disabled={false} onClick={handleAddCampaignModal} className="add-btn ml-auto btn btn-info btn-lg">
							Add
						</button>
						{showAddModal && (
							<AddCampaignMediumMaster action={"add"} handleAddCampaignModal={handleAddCampaignModal} addCampaignMedium={addCampaignMedium} />
                            )}
						{showEditModal && (
							<AddCampaignMediumMaster
								action={"edit"}
								editData={editData}
								handleEditCampaignModal={handleEditCampaignModal}
								editCampaignMedium={editCampaignMedium}
							/>
						)}
					</div>
					<div className={`mt-4 react-table`}>
						<ReactTable data={campaignMediumData} columns={columns} defaultPageSize={10} />
					</div>
				</section>
			</main>
		</div>
	);
};
export default CampaignMediumMaster;