import { get, post } from "../httpInterceptor";

// http://localhost:8080/getModuleList
export const getModuleList = () => {
  return get(`/getModuleList`).then((res) => {
    console.log("RESPONSE :: getModuleList ::: ", res);
    return res;
  });
};

// localhost:8080/findAllRoles
export const findAllRoles = () => {
  return get(`/findAllRoles`).then((res) => {
    console.log("RESPONSE :: findAllRoles ::: ", res);
    return res;
  });
};

// http://localhost:8080/fetchingrolebasedmoduleaccessbyemployeeid?roleId=1
export const fetchingrolebasedmoduleaccessbyemployeeid = (roleId) => {
  return get(
    `/fetchingrolebasedmoduleaccessbyemployeeid?roleId=${roleId}`,
    {},
    false
  ).then((res) => {
    console.log("fetchingrolebasedmoduleaccessbyemployeeid", res);
    return res;
  });
};

// http://localhost:8080/addrolebasedmoduleaccess
export const addrolebasedmoduleaccess = (obj) => {
  return post(`/addrolebasedmoduleaccess`, obj, false).then((res) => {
    console.log("addrolebasedmoduleaccess", res);
    return res;
  });
};
