import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

import CloseModal from "../../../Components/CloseModal";
import {
  formatIndianCurrency,
  replaceComma,
  checkIfNotDecimal,
} from "../../../Utils/helperFunctions";
import { addOrUpdateLegalValution } from "../../../Utils/Services/legalValution";
import "./style.css";
toast.configure();

const AddEditLegalValution = (props) => {
  let [showModal, setShowModal] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  let [showInner, setShowInner] = useState(false);
  let [formState, setFormState] = useState({
    legalandvaluationfee: "",
    errors: {
      legalandvaluationfee: null,
    },
  });

  let { errors } = formState;

  useEffect(() => {
    setShowModal(true);
    console.log("props.editData", props.editData);
    if (props.editData && props.action == "edit") {
      setFormState({ ...formState, ...props.editData });
    }
  }, []);

  const handleModalClose = (modclose) => {
    setShowInner(!showInner);
  };

  const innerModalClose = (modclose) => {
    if (!modclose) handleModalClose();
    setShowModal(false);
    if (props.action == "edit") {
      props.handleEditLegalValution();
    } else {
      props.handleAddLegalValution();
    }
  };

  const handleSubmit = () => {
    let obj = { ...formState };
    addOrUpdateLegalValution(obj).then((response) => {
      if (response.error) {
        return;
      } else if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        props.getLegalValution();
        setShowModal(false);
        toast.success(response.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      }
    });
  };

  const handleSelectChange = (e, name) => {
    e.preventDefault();
    let { id, value, label } = e;
    let tempState = { ...formState };
    tempState[name] = id;
    setFormState(tempState);
  };

  const handleInputChange = (e, name) => {
    e.preventDefault();
    console.log("handleInputChange", e, name, e.target.value);
    let tempState = { ...formState };
    tempState[name] = e.target.value;
    setFormState(tempState);
  };

  const onValidate = (e, name) => {
    console.log("state*******", e);
    e.preventDefault();
    // let { product, segment, loanamtnorms, LegalValution, state, percentagevalue } = formState;
    if (
      formState[name] == "" ||
      formState[name] == null ||
      formState[name] == undefined
    ) {
      let tempState = { ...formState };
      tempState.errors[name] = true;
      setFormState(tempState);
    }
  };

  let diableSave = false;
  let isRequiredFeildFilled = false;
  Object.keys(formState).map((name) => {
    console.log("formState", formState[name]);
    if (
      formState[name] == "" ||
      formState[name] == null ||
      formState[name] == undefined
    ) {
      isRequiredFeildFilled = true;
      return true;
    }
  });
  Object.keys(errors).map((error) => {
    console.log("error", errors[error]);
    if (errors[error] == true && errors[error] != null) {
      diableSave = true;
      return true;
    }
  });
  console.log("formState", formState);
  return (
    <>
      <div>
        <Modal
          className="processing-fee-container"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showModal}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>
              {props.action == "edit"
                ? "Edit Legal & Valuation Fee (Excl GST)"
                : "Add Legal & Valuation Fee (Excl GST)"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            {isLoading ? (
              <h4 className="text-center">LOADING...</h4>
            ) : (
              <div className="col-lg-12 col-sm-12 col-md-12">
                <label>
                  <i className="text-danger">*</i> Legal & Valuation Fee - In
                  Rupees (Excl GST)
                </label>
                <div class="inner-addon right-addon">
                  <input
                    type="text"
                    // pattern="\d*"
                    maxLength="10"
                    name="legalandvaluationfee"
                    value={
                      formState.legalandvaluationfee &&
                      formatIndianCurrency(formState.legalandvaluationfee)
                    }
                    onChange={(e) => {
                      e.target.value = replaceComma(e.target.value);
                      if (checkIfNotDecimal(e.target.value)) {
                        handleInputChange(e, "legalandvaluationfee");
                      }
                    }}
                    onBlur={(e) => onValidate(e, "legalandvaluationfee")}
                    placeholder="Please Enter Legal & Valuation Fee"
                    className={`form-input ${
                      errors.legalandvaluationfee ? "error-input-border" : ""
                    } `}
                    disabled={false}
                  />
                  {errors.legalandvaluationfee && (
                    <span className="errorMsg">
                      Please Enter Legal & Valuation Fee
                    </span>
                  )}
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-danger" onClick={handleModalClose}>
              Cancel
            </Button>
            <Button
              className="btn-success"
              disabled={diableSave || isRequiredFeildFilled}
              onClick={handleSubmit}
            >
              {props.action == "edit" ? "Update" : "Create"}
            </Button>
          </Modal.Footer>
        </Modal>
        <CloseModal
          show={showInner}
          button2={handleModalClose}
          button1={innerModalClose}
          title="Do you want to close ?"
        />
      </div>
    </>
  );
};

export default AddEditLegalValution;
