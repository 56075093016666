import React, { Component } from "react";
import LeftMenu from "../../../Components/LeftMenu";
import Header from "../../../Components/Header";
import GoldOnboardingMasterForm from "./GoldOnboardingMasterForm";

export class GoldOnboardingMaster extends Component {
    state = {
        search: "",
        showModal: false,
        loading: false,
    };
    addGoldOnboardingMaster = () => {
        this.setState({
            showModal: !this.state.showModal
        });
    };

    render() {
        const { search, loading } = this.state;
        return (
            <div className="dash_grid">
                <LeftMenu />
                <main className="bg-white">
                    <Header {...this.props} />
                    <section className="container-fluid">
                        <div className="d-flex align-items-center mt-5">
                            <GoldOnboardingMasterForm addGoldOnboardingMaster={this.addGoldOnboardingMaster} />
                            {this.state.showModal && <GoldOnboardingMasterForm addGoldOnboardingMaster={this.addGoldOnboardingMaster} />}
                        </div>
                    </section>
                </main>
            </div>
        );
    }
}

export default GoldOnboardingMaster;
