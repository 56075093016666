let backendUrl;

let hostname = window.location.hostname;

backendUrl = "https://uat-api.sarvagram.net/admin/v1.0";

if (hostname === "central-admin.sarvagram.com") {
  backendUrl = "https://gateway.sarvagram.com/admin/v1.0";
} else if (hostname === "uat-central-admin.sarvagram.net") {
  backendUrl = "https://uat-api.sarvagram.net/admin/v1.0";
} else {
  backendUrl = "https://dev-api.sarvagram.net/admin/v1.0";
}

export const API_URL = backendUrl;

export const FrontendURL = `${window.location.protocol}//${window.location.hostname}:3000`;
export const FileUrl = `${window.location.protocol}//${window.location.hostname}/`;
