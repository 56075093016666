import { get, post, postFile } from "../httpInterceptor";
const CORS_URL = "https://cors-anywhere.herokuapp.com/";

//save and update franchiselist
export const saveOrUpdatefranchise = (obj) => {
  return post(`/franchise/saveorupdatefranchise`, obj, false).then((res) => {
    console.log("saveOrUpdatefranchise", res);
    return res;
  });
};

//upload documents

export const uploadFranchiseAppraiserDoc = (obj) => {
  return post(`/franchise/uploadDoc`, obj, false, true).then((res) => {
    console.log("uploadFranchiseAppraiserDoc", res);
    return res;
  });
};

//check dedupe franchiseCode
export const checkFranchiseDedupe = (obj) => {
  return post(`/franchise/checkfranchiserispresent`, obj, false).then((res) => {
    console.log("checkFranchiseDedupe", res);
    return res;
  });
};

//check email present
export const checkemailidispresent = (obj) => {
  return post(`/franchise/checkemailidispresent`, obj, false).then((res) => {
    console.log("checkemailidispresent", res);
    return res;
  });
};

//check email present
export const checkMobileNumberIsPresent = (obj) => {
  return post(`/franchise/checkMobileNumberIsPresent`, obj, false).then(
    (res) => {
      console.log("checkMobileNumberIsPresent", res);
      return res;
    }
  );
};

export const checkVillageIsAlreadyMappedToFranchise = (obj) => {
  return post(
    `/franchise/checkVillageIsAlreadyMappedToFranchise`,
    obj,
    false
  ).then((res) => {
    console.log("checkVillageIsAlreadyMappedToFranchise", res);
    return res;
  });
};

// export const getPanVerify= (obj) => {
//     return post(`https://7bt254zc57.execute-api.ap-south-1.amazonaws.com/test/pan-authentication`, obj,
//     false
//     ).then(res => {
//         console.log("getPanVerify ", res);
//         return res;
//     });
// };

// export const deleteDocumentById = (obj) => {
//   return post(`/franchise/deleteDocument`, obj, false).then(
//     (res) => {
//       console.log("deleteDocumentById", res);
//       return res;
//     }
//   );
// };

// export const saveOrUpdatefranchise = (obj) => {
//   return post(
//     `/franchise/saveorupdatefranchise`,
//     obj,
//     false
//   ).then((res) => {
//     console.log("saveOrUpdatefranchise", res);
//     return res;
//   });
// };

// export const uploadFranchiseAppraiserDoc = (obj) => {
//   return post(`/franchise/uploadDoc`, obj, false, true).then(
//     (res) => {
//       console.log("uploadFranchiseAppraiserDoc", res);
//       return res;
//     }
//   );
// };

// http://182.71.191.91:8080/sarvagram-order/farmorder/findallvillagesdropdown
export const findallvillagesdropdown = () => {
  return get(`/franchise/findallvillagesdropdown`).then((res) => {
    console.log("RESPONSE :: findallvillagesdropdown ::: ", res);
    return res;
  });
};
