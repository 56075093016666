import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from "lodash";
import {
  adduser,
  getallzones,
  getallregions,
  getallbranches,
  getallroles,
  findAllVillage,
} from "../../Utils/Services/role";
import CloseModal from "../../Components/CloseModal";
import {
  formatIndianCurrency,
  replaceComma,
  checkIfNotDecimal,
} from "../../Utils/helperFunctions";
toast.configure();

const initForm = {
  employeeName: "",
  employeeId: "",
  emailId: "",
  mobileNo: "",
  role: "",
  zone: "",
  region: "",
  branch: "",
  status: "ACTIVE",
  errors: {
    employeeName: null,
    employeeId: null,
    emailId: null,
    mobileNo: null,
    role: null,
    zone: null,
    region: null,
    branch: null,
    status: null,
  },
};

const statusType = [
  { statusLabel: "ACTIVE", statusValue: "ACTIVE" },
  { statusLabel: "INACTIVE", statusValue: "INACTIVE" },
];

const dropstyle = {
  container: (base) => ({
    ...base,
    flex: 1,
  }),
};

export class Addedituser extends Component {
  state = {
    showModal: false,
    isLoading: false,
    showInner: false,
    form: cloneDeep(initForm),
    key: Math.random(),
    userBranch: [],
    userZone: [],
    userRegion: [],
    userVillage: [],
    zoneDropdownValue: [],
    branchDropdownValue: [],
    regionDropdownValue: [],
    villageDropdownValue: [],
    loaderForSpoke: false,
  };

  componentDidMount = () => {
    let { form } = this.state;
    let { editData } = this.props;
    this.setState({ showModal: true });
    // console.log("props.editData", this.props.editData);
    if (this.props.editData && this.props.action == "edit") {
      let { form } = this.state;

      let obj = {
        ...form,
        ...this.props.editData,
      };
      // console.log("okkk", obj);

      // indexNew variable is used to get the ALL value

      let indexNew =
        editData &&
        editData.village &&
        editData.village.findIndex((e) => e.villageName === "All");
      let userVillage1 = [];
      if (indexNew > -1) {
        userVillage1.push(
          editData &&
            editData.village &&
            editData.village.filter((e) => e.villageName === "All")
        );
      }
      let newdata =
        userVillage1 && userVillage1.length > 0
          ? userVillage1[0]
          : editData.village;

      newdata &&
        newdata.map((e) => {
          e.id = e.pincodeVillageMappingId;
        });
      this.setState(
        {
          form: obj,
          userVillage: newdata,
          key: Math.random(),
        },
        () => {
          this.getzones();
          this.getregions();
          this.getbranches();
          this.getvillage();
        }
      );
    }
    this.getzones();
  };

  getzones = () => {
    getallzones().then((result) => {
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        toast.error(result.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        if (result && result.data && result.data.data) {
          this.setState({
            zoneDropdownValue: result && result.data && result.data.data,
          });
        }
      }
    });
  };

  getregions = () => {
    let { form } = this.state;
    let obj = {
      zonecode: form.zone,
    };
    getallregions(obj).then((result) => {
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        // toast.error(result.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 2000,
        // });
      } else {
        if (result && result.data && result.data.data) {
          this.setState({
            regionDropdownValue: result && result.data && result.data.data,
          });
        }
      }
    });
  };

  getbranches = () => {
    let { form } = this.state;
    let obj = {
      zonecode: form.zone,
      regioncode: form.region,
    };
    getallbranches(obj).then((result) => {
      // console.log("reee", result);
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        // toast.error(result.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 2000,
        // });
      } else {
        if (result && result.data && result.data.data) {
          this.setState({
            branchDropdownValue: result && result.data && result.data.data,
          });
        }
      }
    });
  };

  getvillage = () => {
    let { form, loaderForSpoke } = this.state;
    let obj = {
      zonecode: form.zone,
      regioncode: form.region,
      branchcode: form.branch,
    };
    this.setState({ loaderForSpoke: true });
    findAllVillage(obj).then((result) => {
      if (result.error) {
        this.setState({ loaderForSpoke: false });
        return;
      } else if (result.data && result.data.error) {
        // toast.error(result.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 2000,
        // });
        this.setState({ loaderForSpoke: false });
      } else {
        if (result && result.data && result.data.data) {
          this.setState({ loaderForSpoke: false });
          let villagedropdown = result.data.data;
          villagedropdown.forEach((res) => {
            if (res.villageName === "All") {
              // condition is used for only show the village name for ALL
              res.info = `${res.villageName}`;
            } else {
              res.info = `${res.villageName}` + `(${res.pincode})`;
            }
          });
          this.setState({
            villageDropdownValue: villagedropdown,
          });
        }
      }
    });
  };

  handleModalClose = () => {
    this.setState({ showInner: !this.state.showInner });
    // this.this.props.addRole();
  };

  innerModalClose = (modclose) => {
    if (!modclose) {
      this.handleModalClose();
    }
    this.setState({ showModal: false });
    if (this.props.action == "edit") {
      this.props.handleEditUser();
    } else {
      this.props.handleAddUser();
    }
  };

  handleSubmit = () => {
    let {
      form,
      userBranch,
      userZone,
      userRegion,
      userVillage,
      villageDropdownValue,
    } = this.state;
    let { editData } = this.props;
    // form.zone = [{ zonecode: form.zone }];
    // form.region = [{ regioncode: form.region }];
    // form.branch = [{ branchcode: form.branch }];
    this.setState({ form });
    let obj = cloneDeep(form);
    obj.employeeId =
      form &&
      form.employeeId &&
      form.employeeId.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
    delete obj.errors;
    let userData = JSON.parse(localStorage.getItem("userData"));
    // console.log("userdata", userData);
    // let branch = [];
    // userBranch &&
    //   userBranch.map((val) => {
    //     branch.push({
    //       employeeId: userData && userData.adminEmployeeId,
    //       branchcode: val.branchcode,
    //       id: val.id,
    //     });
    //   });

    // let zone = [];
    // userZone &&
    //   userZone.map((val) => {
    //     zone.push({
    //       employeeId: userData && userData.adminEmployeeId,
    //       zonecode: val.zonecode,
    //       id: val.id,
    //     });
    //   });

    // let region = [];
    // userRegion &&
    //   userRegion.map((val) => {
    //     region.push({
    //       employeeId: userData && userData.adminEmployeeId,
    //       regioncode: val.regioncode,
    //       id: val.id,
    //     });
    //   });
    let villageobj = [];
    villageDropdownValue &&
      villageDropdownValue.map((res) => {
        userVillage &&
          userVillage.map((val) => {
            if (res.id == val.id) {
              villageobj.push(res);
            }
          });
      });
    userVillage = villageobj;
    let village = [];
    userVillage &&
      userVillage.map((val) => {
        village.push({
          employeeId:
            this.props.action == "edit"
              ? editData && editData.employeeId
              : form && form.employeeId,
          villageName: val && val.villageName,
          pincodeVillageMappingId:
            this.props.action == "edit" ? val && val.id : val && val.id,
        });
      });

    // obj.branch = branch;
    // obj.zone = zone;
    // obj.region = region;
    obj.village = village;

    this.setState({ isLoading: true });
    adduser(obj).then((response) => {
      this.setState({ isLoading: false });
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        this.props.allusers();
        if (this.props.editData && this.props.action == "edit") {
          this.props.handleEditUser();
        } else {
          this.props.handleAddUser();
        }
        this.setState({ showModal: false });
        toast.success(response.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      }
    });
  };

  handleInputChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("handleInputChange", e, name, e.target.value);
    form[name] = e.target.value;
    this.setState({ form });
  };

  handleroleSelectChange = (e, name) => {
    console.log("roleCode", e, name);
    let { form } = this.state;
    let { roleCode } = e;
    form[name] = roleCode;
    this.setState({ form });
  };

  handleZoneSelectChange = (e, name) => {
    console.log("zonecode", e, name);
    let { form } = this.state;
    let { zonecode } = e;
    form[name] = zonecode;
    if (
      form &&
      (form[name] === "" || form[name] === null || form[name] === undefined)
    ) {
      if (form && form.errors) {
        form.errors[name] = true;
        this.setState({ form });
      }
    } else {
      if (form && form.errors) {
        form.errors[name] = null;
        this.setState({ form });
      }
    }
    this.setState({ form, key: Math.random() });
    this.clearAll();
    this.getregions();
  };

  handleRegionSelectChange = (e, name) => {
    console.log("regioncode", e, name);
    let { form } = this.state;
    let { regioncode } = e;
    form[name] = regioncode;
    if (
      form &&
      (form[name] === "" || form[name] === null || form[name] === undefined)
    ) {
      if (form && form.errors) {
        form.errors[name] = true;
        this.setState({ form });
      }
    } else {
      if (form && form.errors) {
        form.errors[name] = null;
        this.setState({ form });
      }
    }
    this.setState({ form, key: Math.random() });
    this.clearAllBranch();
    this.getbranches();
  };

  handleBranchSelectChange = (e, name) => {
    console.log("branchcode", e, name);
    let { form } = this.state;
    let { branchcode } = e;
    form[name] = branchcode;
    if (
      form &&
      (form[name] === "" || form[name] === null || form[name] === undefined)
    ) {
      if (form && form.errors) {
        form.errors[name] = true;
        this.setState({ form });
      }
    } else {
      if (form && form.errors) {
        form.errors[name] = null;
        this.setState({ form });
      }
    }
    this.setState({ form });
    this.getvillage();
  };

  // handlebranch = (branch) => {
  //   // let { errors } = this.state;

  //   // if (
  //   //   branch === "" ||
  //   //   branch === null ||
  //   //   branch === undefined ||
  //   //   branch.length === 0
  //   // ) {
  //   //   this.setState({ errors: { ...errors, branch: true } });
  //   // } else {
  //   //   this.setState({ errors: { ...errors, branch: false } });
  //   // }

  //   let brancharray = [];
  //   branch && brancharray.push(branch);

  //   this.setState({ userBranch: brancharray[0] });
  // };

  // handleZone = (zone) => {
  //   // let { errors } = this.state;
  //   // if (
  //   //   zone === "" ||
  //   //   zone === null ||
  //   //   zone === undefined ||
  //   //   zone.length === 0
  //   // ) {
  //   //   this.setState({ errors: { ...errors, zone: true } });
  //   // } else {
  //   //   this.setState({ errors: { ...errors, zone: false } });
  //   // }

  //   let zonearray = [];
  //   zone && zonearray.push(zone);

  //   this.setState({ userZone: zonearray[0] });
  // };

  // handleRegion = (region) => {
  //   // let { errors } = this.state;
  //   // if (
  //   //   region === "" ||
  //   //   region === null ||
  //   //   region === undefined ||
  //   //   region.length === 0
  //   // ) {
  //   //   this.setState({ errors: { ...errors, region: true } });
  //   // } else {
  //   //   this.setState({ errors: { ...errors, region: false } });
  //   // }

  //   let regionarray = [];
  //   region && regionarray.push(region);

  //   this.setState({ userRegion: regionarray[0] });
  // };

  handleVillage = (village) => {
    let { userVillage, villageDropdownValue } = this.state;
    let villagearray = [];
    village && villagearray.push(village);

    // newindex variable is used to get the ALL value

    let newindex =
      villagearray &&
      villagearray[0] &&
      villagearray[0].findIndex((e) => e.villageName === "All");
    if (newindex > -1 && newindex === village.length - 1) {
      villagearray = [];
      villagearray.push(
        villageDropdownValue &&
          villageDropdownValue.filter((e) => e.villageName === "All")
      );
    } else if (newindex == 0) {
      villagearray[0].splice(0, 1);
    }
    this.setState({ userVillage: villagearray[0] });
  };

  onValidate = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    if (form[name] == "" || form[name] == null || form[name] == undefined) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      form.errors[name] = null;
      this.setState({ form });
    }
    console.log("foo", form);
  };

  handleSelectChanges = (e, name) => {
    console.log("handleSegmentSelectChanges", e, name);
    let { form } = this.state;
    let { statusValue } = e;
    form[name] = statusValue;
    this.setState({ form });
  };

  clearAll = () => {
    let { form } = this.state;
    form.region = "";
    form.branch = "";
    this.setState({ form });
  };

  clearAllBranch = () => {
    let { form } = this.state;
    form.branch = "";
    this.setState({ form });
  };

  render() {
    let {
      form,
      showModal,
      showInner,
      isLoading,
      userBranch,
      userZone,
      userRegion,
      userVillage,
      key,
      zoneDropdownValue,
      regionDropdownValue,
      branchDropdownValue,
      villageDropdownValue,
      loaderForSpoke,
    } = this.state;
    let { roleDropdownValue, role, userInfo } = this.props;
    let { errors } = form;
    let diableSave = false;
    let isRequiredFeildFilled = false;

    // console.log("frm", branchDropdownValue);
    Object.keys(form).map((name) => {
      // console.log("form", form[name]);
      if (form[name] == "" || form[name] == null || form[name] == undefined) {
        isRequiredFeildFilled = true;
        return true;
      }
    });
    // let userData = JSON.parse(localStorage.getItem("userData"));
    // console.log("userdata", userData);
    // let userBranchmapping = [];
    // userBranch &&
    //   userBranch.map((val) => {
    //     userBranchmapping.push({
    //       employeeId: userData && userData.adminEmployeeId,
    //       branchcode: val.branchcode,
    //       id: val.id,
    //     });
    //   });

    // let branchobj = [];
    // branchDropdownValue.map((res) => {
    //   userBranch &&
    //     userBranch.map((val) => {
    //       if (res.branchcode == val.branchcode) {
    //         branchobj.push(res);
    //       }
    //     });
    // });
    // console.log("branchobj", branchobj);

    // let zoneobj = [];
    // zoneDropdownValue.map((res) => {
    //   userZone &&
    //     userZone.map((val) => {
    //       if (res.zonecode == val.zonecode) {
    //         zoneobj.push(res);
    //       }
    //     });
    // });

    // let regionobj = [];
    // regionDropdownValue.map((res) => {
    //   userRegion &&
    //     userRegion.map((val) => {
    //       if (res.regioncode == val.regioncode) {
    //         regionobj.push(res);
    //       }
    //     });
    // });

    let villageobj = [];
    villageDropdownValue &&
      villageDropdownValue.map((res) => {
        userVillage &&
          userVillage.map((val) => {
            if (res.id == val.id) {
              villageobj.push(res);
            }
          });
      });

    Object.keys(errors).map((error) => {
      if (errors[error] == true || errors[error] != null) {
        diableSave = true;
        return true;
      }
    });

		if (zoneDropdownValue.length > 0) {
			if (role === "ZBH" || role === "ZCM" || role === "RBH" || role === "RCM" || role === "BM") {
				zoneDropdownValue = (zoneDropdownValue && zoneDropdownValue.filter((e) => e.zonename.includes(userInfo.zone))) || [];
			}
		}

		if (regionDropdownValue.length > 0) {
			if (role === "RBH" || role === "RCM" || role === "BM") {
				regionDropdownValue = (regionDropdownValue && regionDropdownValue.filter((e) => e.regionname.includes(userInfo.region))) || [];
			}
		}

		if (branchDropdownValue.length > 0) {
			if (role === "BM") {
				branchDropdownValue = (branchDropdownValue && branchDropdownValue.filter((e) => e.branchname.includes(userInfo.branch))) || [];
			}
		}

    return (
      <>
        <Modal
          className="add-role"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showModal}
        >
          <Modal.Header>
            <Modal.Title>
              {this.props.action == "edit" ? "Edit User" : "Add User"}
            </Modal.Title>
          </Modal.Header>
          {/* {roleDropdownValue &&
            roleDropdownValue.length > 0 &&
            zoneDropdownValue &&
            zoneDropdownValue.length > 0 &&
            regionDropdownValue &&
            regionDropdownValue.length > 0 &&
            branchDropdownValue &&
            branchDropdownValue.length > 0 && ( */}
          <Modal.Body className="p-4">
            {isLoading ? (
              <h4 className="text-center">LOADING...</h4>
            ) : (
              <>
                <div className="row ">
                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label>
                        <i className="text-danger">*</i> Employee Name
                      </label>
                      <div class="inner-addon right-addon">
                        <input
                          type="text"
                          // pattern="\d*"
                          //   maxLength="9"
                          name="employeeName"
                          value={form.employeeName.replace(/[^a-zA-Z ]/g, "")}
                          onChange={(e) => {
                            this.handleInputChange(e, "employeeName");
                          }}
                          onBlur={(e) => {
                            this.onValidate(e, "employeeName");
                          }}
                          placeholder="Enter employeeName"
                          className={`form-input ${
                            errors.employeeName ? "error-input-border" : ""
                          } `}
                        />
                        {errors.employeeName && (
                          <span className="errorMsg">
                            {errors.employeeName == true
                              ? "Enter Employee Name"
                              : errors.employeeName}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* emp ID */}
                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label>
                        <i className="text-danger">*</i> Employee ID
                      </label>
                      <div class="inner-addon right-addon">
                        <input
                          type="text"
                          pattern="\d*"
                          //   maxLength="9"
                          name="employeeId"
                          value={
                            form &&
                            form.employeeId
                              .replace(/[^a-zA-Z0-9]/g, "")
                              .toUpperCase()
                          }
                          onChange={(e) => {
                            this.handleInputChange(e, "employeeId");
                          }}
                          onBlur={(e) => {
                            this.onValidate(e, "employeeId");
                          }}
                          placeholder="Enter employeeId"
                          className={`form-input ${
                            errors.employeeId ? "error-input-border" : ""
                          } `}
                          disabled={this.props.action == "edit"}
                        />
                        {errors.employeeId && (
                          <span className="errorMsg">
                            {errors.employeeId == true
                              ? "Enter Employee ID"
                              : errors.employeeId}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* email ID */}
                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label>
                        <i className="text-danger">*</i> Email ID
                      </label>
                      <div class="inner-addon right-addon">
                        <input
                          type="text"
                          // pattern="\d*"
                          //   maxLength="9"
                          name="emailId"
                          value={form.emailId}
                          onChange={(e) => {
                            this.handleInputChange(e, "emailId");
                          }}
                          onBlur={(e) => {
                            this.onValidate(e, "emailId");
                          }}
                          placeholder="Enter Email ID"
                          className={`form-input ${
                            errors.emailId ? "error-input-border" : ""
                          } `}
                          disabled={this.props.action == "edit"}
                        />
                        {errors.emailId && (
                          <span className="errorMsg">
                            {errors.emailId == true
                              ? "Enter Email ID"
                              : errors.emailId}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* mobile number */}
                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label>
                        <i className="text-danger">*</i> Mobile Number
                      </label>
                      <div class="inner-addon right-addon">
                        <input
                          type="text"
                          // pattern="\d*"
                          maxLength="10"
                          name="mobileNo"
                          value={form && form.mobileNo}
                          onChange={(e) => {
                            this.handleInputChange(e, "mobileNo");
                          }}
                          onBlur={(e) => {
                            this.onValidate(e, "mobileNo");
                          }}
                          placeholder="Enter Mobile Number"
                          className={`form-input ${
                            errors.mobileNo ? "error-input-border" : ""
                          } `}
                          
                          reqType="number"
                        />
                        {errors.mobileNo && (
                          <span className="errorMsg">
                            {errors.mobileNo == true
                              ? "Enter Mobile Number"
                              : errors.mobileNo}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Role */}
                  <div className="col-md-12 mt-3">
                    <label>
                      <i className="text-danger">*</i> Role
                    </label>
                    <Select
                      defaultValue={
                        roleDropdownValue.filter(
                          ({ roleCode }) => roleCode == form.role
                        )[0]
                      }
                      onChange={(e) => {
                        this.handleroleSelectChange(e, "role");
                      }}
                      onBlur={(e) => this.onValidate(e, "role")}
                      name="role"
                      options={roleDropdownValue}
                      placeholder="Select role"
                      valueKey="roleCode"
                      labelKey="roleName"
                      getOptionLabel={(option) => option.roleName}
                      getOptionValue={(option) => option.roleCode}
                      className={`${errors.role ? "error-input-border" : ""} `}
                      
                    />
                    {errors.role && (
                      <span className="errorMsg">Please Select Role</span>
                    )}
                  </div>

                  {/* status */}
                  {this.props.action == "edit" && (
                    <div className="col-md-12 mt-3">
                      <label>
                        <i className="text-danger">*</i> Status
                      </label>
                      <Select
                        defaultValue={
                          statusType.filter(
                            ({ statusValue }) => statusValue == form.status
                          )[0]
                        }
                        onChange={(e) => {
                          this.handleSelectChanges(e, "status");
                        }}
                        onBlur={(e) => this.onValidate(e, "status")}
                        name="status"
                        options={statusType}
                        placeholder="Select Status"
                        valueKey="statusLabel"
                        labelKey="statusValue"
                        getOptionLabel={(option) => option.statusValue}
                        getOptionValue={(option) => option.statusLabel}
                        className={`${
                          errors.status ? "error-input-border" : ""
                        } `}
                        
                      />
                      {errors.status && (
                        <span className="errorMsg">Please Select Status</span>
                      )}
                    </div>
                  )}
                  {/* Zone */}
                  {/* <div className="col-md-12 mt-3">
                    <label>
                      <i className="text-danger">*</i> Zone
                    </label>
                    <Select
                      defaultValue={zoneDropdownValue.filter(
                        ({ zonecode }) => zonecode == form.zone
                      )}
                      //   value={form.zone}
                      onChange={(e, value) => {
                        this.handlezoneSelectChange(e, "zone", value);
                      }}
                      onBlur={(e) => this.onValidate(e, "zone")}
                      name="zone"
                      options={zoneDropdownValue}
                      placeholder="Select zone"
                      valueKey="zonecode"
                      labelKey="zonename"
                      getOptionLabel={(option) => option.zonename}
                      getOptionValue={(option) => option.zonecode}
                      className={`${errors.zone ? "error-input-border" : ""} `}
                      isDisabled={false}
                      isMulti={true}
                    />
                    {errors.zone && (
                      <span className="errorMsg">Please Select Zone</span>
                    )}
                  </div> */}
                  {zoneDropdownValue && zoneDropdownValue.length > 0 && (
                    <div className="col-md-12 mt-3">
                      <label>
                        <i className="text-danger">*</i> Zone
                      </label>
                      {/* <Select
                      value={zoneobj}
                      onChange={this.handleZone}
                      // onBlur={(e) => this.onValidate(e, "zone")}
                      name="zoneobj"
                      options={zoneDropdownValue}
                      placeholder="Select zone"
                      valueKey="zonecode"
                      labelKey="zonename"
                      getOptionLabel={(option) => option.zonename}
                      getOptionValue={(option) => option.zonecode}
                      className={`${errors.zone ? "error-input-border" : ""} `}
                      isDisabled={false}
                      isMulti={true}
                    /> */}
                      {/* {errors.zone && (
                      <span className="errorMsg">Please Select Zone</span>
                    )} */}
                      <Select
                        defaultValue={
                          zoneDropdownValue.filter(
                            ({ zonecode }) => zonecode == form.zone
                          )[0]
                        }
                        onChange={(e) => {
                          this.handleZoneSelectChange(e, "zone");
                        }}
                        onBlur={(e) => this.onValidate(e, "zone")}
                        name="zone"
                        options={zoneDropdownValue}
                        placeholder="Select Zone"
                        valueKey="zonecode"
                        labelKey="zonecode"
                        getOptionLabel={(option) => option.zonecode}
                        getOptionValue={(option) => option.zonecode}
                        className={`${
                          errors && errors.zone ? "error-input-border" : ""
                        } `}
                        
                        key={key}
                      />
                      {errors && errors.zone && (
                        <span className="errorMsg">Please Select Zone</span>
                      )}
                    </div>
                  )}

                  {/* new Zone */}
                  {/* Region */}

                  {/* <div className="col-md-12 mt-3">
                    <label>
                      <i className="text-danger">*</i> Region
                    </label>
                    <Select
                      defaultValue={regionDropdownValue.filter(
                        ({ regioncode }) => regioncode == form.region
                      )}
                      onChange={(e, value) => {
                        this.handleregionSelectChange(e, "region", value);
                      }}
                      onBlur={(e) => this.onValidate(e, "region")}
                      name="region"
                      options={regionDropdownValue}
                      placeholder="Select region"
                      valueKey="regioncode"
                      labelKey="regionname"
                      getOptionLabel={(option) => option.regionname}
                      getOptionValue={(option) => option.regioncode}
                      className={`${
                        errors.region ? "error-input-border" : ""
                      } `}
                      isDisabled={false}
                      isMulti={true}
                    />
                    {errors.region && (
                      <span className="errorMsg">Please Select Region</span>
                    )}
                  </div> */}

                  {/* new Region */}
                  {regionDropdownValue && regionDropdownValue.length > 0 && (
                    <div className="col-md-12 mt-3">
                      <label>
                        <i className="text-danger">*</i> Region
                      </label>
                      {/* <Select
                      value={regionobj}
                      onChange={this.handleRegion}
                      // onBlur={(e) => this.onValidate(e, "region")}
                      name="regionobj"
                      options={regionDropdownValue}
                      placeholder="Select region"
                      valueKey="regioncode"
                      labelKey="regionname"
                      getOptionLabel={(option) => option.regionname}
                      getOptionValue={(option) => option.regioncode}
                      className={`${
                        errors.region ? "error-input-border" : ""
                      } `}
                      isDisabled={false}
                      isMulti={true}
                    /> */}
                      {/* {errors.region && (
                      <span className="errorMsg">Please Select Region</span>
                    )} */}
                      <Select
                        defaultValue={
                          (console.log(
                            "regionDropdownValue",
                            regionDropdownValue &&
                              regionDropdownValue.filter(
                                ({ regioncode }) => regioncode == form.region
                              )[0]
                          ),
                          regionDropdownValue &&
                            regionDropdownValue.filter(
                              ({ regioncode }) => regioncode == form.region
                            )[0])
                        }
                        onChange={(e) => {
                          this.handleRegionSelectChange(e, "region");
                        }}
                        onBlur={(e) => this.onValidate(e, "region")}
                        name="region"
                        options={regionDropdownValue}
                        placeholder="Select Region"
                        valueKey="regioncode"
                        labelKey="regioncode"
                        getOptionLabel={(option) => option.regioncode}
                        getOptionValue={(option) => option.regioncode}
                        className={`${
                          errors && errors.region ? "error-input-border" : ""
                        } `}
                        
                        key={key}
                      />
                      {errors && errors.region && (
                        <span className="errorMsg">Please Select Region</span>
                      )}
                    </div>
                  )}

                  {/* Branch */}
                  {/* <div className="col-md-12 mt-3">
                    <label>
                      <i className="text-danger">*</i> Branch
                    </label>
                    <Select
                      defaultValue={branchDropdownValue.map(
                        ({ branchcode }) => branchcode == form.branch
                      )}
                      onChange={(e, value) => {
                        this.handlebranchSelectChange(e, "branch", value);
                      }}
                      onBlur={(e) => this.onValidate(e, "branch")}
                      name="branch"
                      options={branchDropdownValue}
                      placeholder="Select branch"
                      valueKey="branchcode"
                      labelKey="branchcode"
                      getOptionLabel={(option) => option.branchcode}
                      getOptionValue={(option) => option.branchcode}
                      className={`${
                        errors.branch ? "error-input-border" : ""
                      } `}
                      isDisabled={false}
                      isMulti
                    />
                    {errors.branch && (
                      <span className="errorMsg">Please Select Branch</span>
                    )}
                  </div> */}

                  {/* new branch */}
                  {branchDropdownValue && branchDropdownValue.length > 0 && (
                    <div className="col-md-12 mt-3">
                      <label>
                        <i className="text-danger">*</i> Branch
                      </label>
                      {/* <Select
                      // defaultValue={branchDropdownValue.map(
                      //   ({ branchcode }) => branchcode == form.branch
                      // )}
                      value={branchobj}
                      onChange={this.handlebranch}
                      // onBlur={(e) => this.onValidate(e, "branch")}
                      name="branchobj"
                      options={branchDropdownValue}
                      placeholder="Select branch"
                      valueKey="branchcode"
                      labelKey="branchname"
                      getOptionLabel={(option) => option["branchname"]}
                      getOptionValue={(option) => option["branchcode"]}
                      styles={dropstyle}
                      // className={`${
                      //   errors.branch ? "error-input-border" : ""
                      // } `}
                      isDisabled={false}
                      isMulti
                    /> */}
                      {/* {errors.branch && (
                      <span className="errorMsg">Please Select Branch</span>
                    )} */}
                      <Select
                        defaultValue={
                          branchDropdownValue.filter(
                            ({ branchcode }) => branchcode == form.branch
                          )[0]
                        }
                        onChange={(e) => {
                          this.handleBranchSelectChange(e, "branch");
                        }}
                        onBlur={(e) => this.onValidate(e, "branch")}
                        name="branch"
                        options={branchDropdownValue}
                        placeholder="Select Branch"
                        valueKey="branchcode"
                        labelKey="branchcode"
                        getOptionLabel={(option) => option.branchcode}
                        getOptionValue={(option) => option.branchcode}
                        className={`${
                          errors && errors.branch ? "error-input-border" : ""
                        } `}
                        
                        key={key}
                      />
                      {errors && errors.branch && (
                        <span className="errorMsg">Please Select Branch</span>
                      )}
                    </div>
                  )}
                  <div className="col-md-12 mt-3">
                    {loaderForSpoke ? (
                      <React.Fragment>
                        <i class="fa fa-spinner fa-spin ml-3"></i>
                        <span className="ml-2">Please wait...</span>
                      </React.Fragment>
                    ) : (
                      <>
                        <label>
                          <i className="text-danger">*</i> Spoke
                        </label>
                        <Select
                          value={villageobj}
                          onChange={this.handleVillage}
                          // onBlur={(e) => this.onValidate(e, "region")}
                          name="regionobj"
                          options={villageDropdownValue}
                          placeholder="Select Village / Spoke"
                          valueKey="id"
                          labelKey="info"
                          getOptionLabel={(option) => option.info}
                          getOptionValue={(option) => option.id}
                          className={`${
                            errors.village ? "error-input-border" : ""
                          } `}
                          isDisabled={false}
                          isMulti={true}
                          key={key}
                        />
                      </>
                    )}
                    {/* {errors.village && (
                      <span className="errorMsg">
                        Please Select Village / Spoke
                      </span>
                    )} */}
                  </div>
                </div>
              </>
            )}
            <Modal.Footer>
              <Button className="btn-danger" onClick={this.handleModalClose}>
                Cancel
              </Button>
              <Button
                className="btn-success"
                disabled={
                  diableSave ||
                  isRequiredFeildFilled ||
                  isLoading ||
                  // userBranch == undefined ||
                  // userZone == undefined ||
                  // userRegion == undefined ||
                  // (userBranch && userBranch.length == 0) ||
                  // (userZone && userZone.length == 0) ||
                  // (userVillage && userVillage.length == 0)
                  (villageobj && villageobj.length == 0)
                }
                onClick={this.handleSubmit}
              >
                {this.props.action == "edit" ? "Update" : "Create"}
              </Button>
            </Modal.Footer>
          </Modal.Body>
          {/* )} */}
        </Modal>
        <CloseModal
          show={showInner}
          button2={this.handleModalClose}
          button1={this.innerModalClose}
          title="Do you want to close ?"
        />
      </>
    );
  }
}

export default Addedituser;
