import { get, post } from "../httpInterceptor";

//list all village and pincode
export const findallvillagesmaster = () => {
  return get(`/villagemaster/findallvillagesmaster`).then((res) => {
    console.log("RESPONSE :: findallvillagesmaster ::: ", res);
    return res;
  });
};

//save or update village

export const saveorupdatevillagemaster = (obj) => {
  return post(`/villagemaster/saveorupdatevillagemaster`, obj, false).then(
    (res) => {
      console.log("saveorupdatevillagemaster", res);
      return res;
    }
  );
};

//delete village by id
export const deletevillagemasterbyid = (obj) => {
  return post(`/villagemaster/deletevillagemasterbyid`, obj, false).then(
    (res) => {
      console.log("deletevillagemasterbyid", res);
      return res;
    }
  );
};

export const migrateSpoke = (obj) => {
  return post(`/villagemaster/spoke/migration`, obj, false).then((res) => {
    return res;
  });
};
