import React, { useState, useEffect, Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from "lodash";

import CloseModal from "../../../Components/CloseModal";
import {
  formatIndianCurrency,
  replaceComma,
  checkIfNotDecimal,
} from "../../../Utils/helperFunctions";
import { saveorupdatevillagemaster } from "../../../Utils/Services/VillageMaster";

toast.configure();

const initForm = {
  villageName: "",
  pincode: "",
  dwaraid:"",
  tehsil:"",
  district:"",
  state:"",
  errors: {
    villageName: null,
    pincode: null,
    dwaraid: null,
    tehsil: null,
    district: null,
    state: null,
  },
};

const stateDropdownValues = [
  { value: "Maharastra", label: "Maharastra" },
  { value: "Gujarat", label: "Gujarat" },
  { value: "Karnataka", label: "Karnataka" },
  { value: "Rajasthan", label: "Rajasthan" },
]

export class AddEditVillage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      Loading: false,
      showInner: false,
      form: cloneDeep(initForm),
    };
  }

  componentDidMount = () => {
    let { form } = this.state;
    this.setState({ showModal: true });
    console.log("props.editData", this.props.editData);
    if (this.props.editData && this.props.action == "edit") {
      let { form } = this.state;
      let obj = { ...form, ...this.props.editData };
      this.setState({ form: obj });
    }
  };

  handleModalClose = () => {
    this.setState({ showInner: !this.state.showInner });
    // this.this.props.addRole();
  };

  innerModalClose = (modclose) => {
    if (!modclose) {
      this.handleModalClose();
    }
    this.setState({ showModal: false });
    if (this.props.action == "edit") {
      this.props.handleEditVillageMaster();
    } else {
      this.props.handleAddVillage();
    }
  };

  //saveupdate api
  handleSubmit = () => {
    let { form } = this.state;
    let obj = form;
    this.setState({ Loading: true });
    saveorupdatevillagemaster(obj).then((response) => {
      this.setState({ Loading: false });
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        this.props.villageMaster();
        if (this.props.editData && this.props.action == "edit") {
          this.props.handleEditVillageMaster();
        } else {
          this.props.handleAddVillage();
        }
        this.setState({ showModal: false });
        toast.success(response.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      }
    });
  };

  handleInputChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("handleInputChange", e, name, e.target.value);
    form[name] = e.target.value;
    this.setState({ form });
  };

  onValidate = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    if (form[name] === "" || form[name] === null || form[name] === undefined) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      form.errors[name] = null;
      this.setState({ form });
    }
  };

  //pincode valdtn
  restrictAlphabets = (e) => {
    const regx = "^[0-9]*$";
    if (e.key.match(regx)) {
      return true;
    } else {
      e.preventDefault();
    }
  };
  handledwaraid = (e, name) => {
    // e.preventDefault();
    let { form } = this.state;
    console.log("handledwaraid", e, name);
    form[name] = e.target.value;
    this.setState({ form });
  };


  handleVillageTehsilDistrict = (e, name) => {
    let regx = /^[a-zA-Z][a-zA-Z0-9()\-\. ]*$/g;
      if (e.target.value.match(regx) || e.target.value === "") {
      let { form } = this.state;
      console.log("handledwaraid", e, name);
      form[name] = e.target.value;
      this.setState({ form });
    }
  }

  validateVillageTehsilDistrict = (e, name) => {
    e.preventDefault();
    let regx = /^[a-zA-Z][a-zA-Z0-9()\-\. ]*$/g;
    let { form, errors } = this.state;
    let value = form[name];
    if (value.match(regx) || value === "") {
      form.errors[name] = null;
      this.setState({ form });
    } else {
      form.errors[name] = true;
      this.setState({ form });
    }
  }

  handleStateSelectChange = (e, name) => {
    console.log("handleStateSelectChange", e, name);
    let { form } = this.state;
    let { value } = e;
    form[name] = value;
    this.setState({ form });
  };

  handlePincode = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("handlePincode", e, name, e.target.value);
    form[name] = e.target.value;
    this.setState({ form });
  };

  handleValidatePincode = (e, name) => {
    e.preventDefault();
    let { form, errors } = this.state;
    console.log("uuu", e, name);
    let value = form[name];
    if (
      value === "" ||
      value === null ||
      value === undefined ||
      (name == "pincode" && value && value.length !== 6)
    ) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      form.errors[name] = null;
      this.setState({ form });
    }
  };
  handleValidatedwaraid = (e, name) => {
    e.preventDefault();
    let { form, errors } = this.state;
    let value = form[name];
    // console.log("uuu", e, name, value.length);
    if(name=="dwaraid" && value.length==0 || e.target.value.length===0){
      return this.setState({form:{...form, errors:{...form.errors ,dwaraid:null}}});
    }

    if (
      value === "" ||
      value === null ||
      value === undefined ||
      (name == "dwaraid" && value.length<4 && value.length !== 6)
    ) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      form.errors[name] = null;
      this.setState({ form });
    }
  };

  render() {
    let { form, showModal, showInner, Loading } = this.state;
    let { errors } = form;
    let diableSave = false;
    let isRequiredFeildFilled = false;
    
    Object.keys(form).map((name) => {
      console.log("form", form[name]);
      if(name=="dwaraid" && form[name].length==0){
        return; 
      }

      if (
        form[name] === "" ||
        form[name] === null ||
        form[name] === undefined
        ) {
          isRequiredFeildFilled = true;
          return true;
        }
      
      });
      
      Object.keys(errors).map((error) => {
      console.log("error", errors[error]);
      if (errors[error] == true || errors[error] != null) {
        diableSave = true;
        return true;
      }
    });
    
    console.log("error", diableSave);
    console.log("form", form);
    return (
      <React.Fragment>
        <Modal
          className="product-norm-container"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showModal}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>
              {this.props.action == "edit"
                ? "Edit Village &  Pincode"
                : "Add Village & Pincode"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            {Loading ? (
              <h4 className="text-center">LOADING...</h4>
            ) : (
              <>
              <div className="row ">
                <div className="mb-4 col-md-4">
                  <label>
                    <i className="text-danger">*</i> Village
                  </label>
                  <input
                    type="text"
                    pattern="\d*"
                    maxLength="100"
                    className="form-input"
                    name="villageName"
                    value={form.villageName}
                    onChange={(e) => {
                      this.handleVillageTehsilDistrict(e, "villageName");
                    }}
                    onBlur={(e) => this.validateVillageTehsilDistrict(e, "villageName")}
                    placeholder="Enter Village"
                    className={`form-input ${
                      errors.villageName ? "error-input-border" : ""
                    } `}
                    />
                  {errors.villageName && (
                    <span className="errorMsg">
                      {errors.villageName == true
                        ? "Enter Village Name"
                        : errors.villageName}
                    </span>
                  )}
                </div>
                <div className="mb-4 col-md-4 ">
                  <label>
                    <i className="text-danger">*</i> Pincode
                  </label>
                  <input
                    type="text"
                    pattern="\d*"      
                    className="form-input"
                    name="pincode"
                    maxLength="6"
                    //id="pincode"
                    value={form.pincode}
                    onKeyPress={this.restrictAlphabets}
                    onChange={(e) => {
                      this.handlePincode(e, "pincode");
                    }}
                    onBlur={(e) => this.handleValidatePincode(e, "pincode")}
                    placeholder="Enter Pincode"
                    className={`form-input ${
                      errors.pincode ? "error-input-border" : ""
                    } `}
                    // id="pincode"
                  />
                
                  {errors.pincode && (
                    <span className="errorMsg">
                      {errors.pincode == true
                        ? "Please enter valid pincode"
                        : errors.pincode}
                    </span>
                  )}
                
                </div>
                <div className="mb-4 col-md-4">
                 <label>
                    {/* <i className="text-danger">*</i> */}
                     Dwara Id
                  </label>
                  <input
                    type="text"
                    pattern="\d*"
                    maxLength="6"
                    className="form-input"
                    name="dwaraid"
                    value={form.dwaraid}
                    onKeyPress={this.restrictAlphabets}
                    onChange={(e) => {
                      this.handledwaraid(e, "dwaraid");
                    }}
                    onBlur={(e) => this.handleValidatedwaraid(e, "dwaraid")}
                    placeholder="Enter Dwara Id"
                    className={`form-input ${
                      errors.dwaraid ? "error-input-border" : ""
                    } `}
                    // id="pincode"
                  />
                  {errors.dwaraid && (
                    <span className="errorMsg">
                      {errors.dwaraid == true
                        ? "Please enter valid Dwara Id"
                        : errors.dwaraid}
                    </span>
                  )}
                  </div>
              </div>
              <div className="row">
                <div className="mb-4 col-md-4">
                 <label>
                    <i className="text-danger">*</i> Tehsil
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    name="tehsil"
                    value={form.tehsil}
                    onChange={(e) => {
                      this.handleVillageTehsilDistrict(e, "tehsil"); //the same logic can be used for this input also
                    }}
                    onBlur={(e) => this.validateVillageTehsilDistrict(e, "tehsil")}
                    placeholder="Enter Tehsil"
                    className={`form-input ${
                      errors.tehsil ? "error-input-border" : ""
                    } `}
                  />
                  {errors.tehsil && (
                    <span className="errorMsg">
                      {errors.tehsil == true
                        ? "Please enter Tehsil"
                        : errors.tehsil}
                    </span>
                  )}
                  </div>
                  <div className="mb-4 col-md-4">
                 <label>
                    <i className="text-danger">*</i> District
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    name="district"
                    value={form.district}
                    onChange={(e) => {
                      this.handleVillageTehsilDistrict(e, "district"); //the same logic can be used for this input also
                    }}
                    onBlur={(e) => this.handleVillageTehsilDistrict(e, "district")}
                    placeholder="Enter District"
                    className={`form-input ${
                      errors.district ? "error-input-border" : ""
                    } `}
                  />
                  {errors.district && (
                    <span className="errorMsg">
                      {errors.district == true
                        ? "Please enter District"
                        : errors.district}
                    </span>
                  )}
                  </div>
                  <div className="mb-4 col-md-4">
                 <label>
                    <i className="text-danger">*</i> State
                  </label>
                  <Select
                          defaultValue={
                            stateDropdownValues.filter(
                              ({ value }) => value == form.state
                            )[0]
                          }
                          onChange={(e) => {
                            this.handleStateSelectChange(e, "state"); //the same logic can be used for this input also
                          }}
                          onBlur={(e) => this.handleValidatedwaraid(e, "state")}
                          name="state"
                          options={stateDropdownValues}
                          placeholder="Select State"
                          valueKey="label"
                          labelKey="value"
                          getOptionLabel={(option) => option["label"]}
                          getOptionValue={(option) => option["value"]}
                          className={`${
                            errors.state ? "error-input-border" : ""
                          } `}
                          isDisabled={false}
                        />
                        {errors.state && (
                          <span className="errorMsg">Please Select State</span>
                        )}
                  </div>
                </div>
            </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-danger" onClick={this.handleModalClose}>
              Cancel
            </Button>
            <Button
              className="btn-success"
              disabled={diableSave || isRequiredFeildFilled || Loading}
              onClick={this.handleSubmit}
            >
              {this.props.action == "edit" ? "Update" : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
        <CloseModal
          show={showInner}
          button2={this.handleModalClose}
          button1={this.innerModalClose}
          title="Do you want to close ?"
        />
      </React.Fragment>
    );
  }
}

export default AddEditVillage;
