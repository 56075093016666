import { get, post } from "../httpInterceptor";

// API for get all processing fees

export const getAllProcessingFee = () => {
  return get(`/sarvagram-credit/master/findallprocesfess`).then((res) => {
    console.log("RESPONSE :: getAllProcessingFee ::: ", res);
    return res;
  });
};

// API for save or update processing fees

export const addOrUpdateProcessingFee = (obj) => {
  return post(
    `/sarvagram-credit/master/saveorupdateprocessingfee`,
    obj,
    false
  ).then((res) => {
    console.log("addOrUpdateProcessingFee", res);
    return res;
  });
};
