import React, { useState, useEffect, Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from "lodash";

import CloseModal from "../../../Components/CloseModal";
import {
  formatIndianCurrency,
  replaceComma,
  checkIfNotDecimal,
} from "../../../Utils/helperFunctions";
import { updateProductFundById } from "../../../Utils/Services/productfund";
import {
  getAllProducts,
  getAllSegmentList,
  getAllLevelsDropdown,
} from "../../../Utils/Services/common";
import "./style.css";
toast.configure();

const segmentOptions = [
  {
    segmentdescription: "Farmer",
    segmentcode: "Farmer",
    id: 2104,
  },
  {
    segmentdescription: "Medium Small Enterprise",
    segmentcode: "MSE",
    id: 2105,
  },
  {
    segmentdescription: "Salaried",
    segmentcode: "Salaried",
    id: 2106,
  },
];

const levelOptions = [
  {
    levelcode: "Level 1",
    levelcode: "Level",
    id: 2104,
  },
];

let initForm = {
  product: "",
  segment: "",
  productNormUpperLimit: "",
  productNormLowerLimit: "",
  level: "",
  errors: {
    product: null,
    segment: null,
    productNormUpperLimit: null,
    productNormLowerLimit: null,
    level: null,
  },
};
class AddEditProductFundingNorms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      showInner: false,
      form: cloneDeep(initForm),
      productDropdownValue: [],
      segmentDropdownValue: [],
      levelDropdownOptions: [],
    };
  }

  componentDidMount = () => {
    let { form } = this.state;
    this.setState({ showModal: true });
    console.log("props.editData", this.props.editData);
    if (this.props.editData && this.props.action == "edit") {
      let { form } = this.state;
      let obj = { ...form, ...this.props.editData };
      this.setState({ form: obj });
    }
  };

  handleModalClose = () => {
    this.setState({ showInner: !this.state.showInner });
    // this.this.props.addRole();
  };

  innerModalClose = (modclose) => {
    if (!modclose) {
      this.handleModalClose();
    }
    this.setState({ showModal: false });
    if (this.props.action == "edit") {
      this.props.handleEditProductNorm();
    } else {
      this.props.handleAddProductNorm();
    }
  };

  handleSubmit = () => {
    let { form } = this.state;
    let obj = form;
    this.setState({ isLoading: true });
    updateProductFundById(obj).then((response) => {
      this.setState({ isLoading: false });
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        this.props.getProductNorm();
        if (this.props.editData && this.props.action == "edit") {
          this.props.handleEditProductNorm();
        } else {
          this.props.handleAddProductNorm();
        }
        this.setState({ showModal: false });
        toast.success(response.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      }
    });
  };

  handleSegmentSelectChanges = (e, name) => {
    console.log("handleSegmentSelectChanges", e, name);
    let { form } = this.state;
    let { segmentcode } = e;
    form[name] = segmentcode;
    this.setState({ form });
  };

  handleProductSelectChange = (e, name) => {
    console.log("handleProductSelectChange", e, name);
    let { form } = this.state;
    let { productdescription } = e;
    form[name] = productdescription;
    this.setState({ form });
  };

  handleLevelSelectChanges = (e, name) => {
    console.log("handleLevelSelectChanges", e, name);
    let { form } = this.state;
    let { levelcode } = e;
    form[name] = levelcode;
    this.setState({ form });
  };

  handleInputChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("handleInputChange", e, name, e.target.value);
    form[name] = e.target.value;
    this.setState({ form });
  };

  onValidate = (e, name) => {
    console.log("state*******", e);
    e.preventDefault();
    let { form } = this.state;
    if (
      ((form.productNormLowerLimit != 0 || form.productNormUpperLimit != 0) &&
        form.productNormLowerLimit != "") ||
      form.productNormUpperLimit != ""
    ) {
      if (
        name == "productNormUpperLimit" &&
        parseInt(e.target.value) <= parseInt(form.productNormLowerLimit)
      ) {
        form.errors.productNormUpperLimit =
          "Upper limit can not be less than or equal to Lower limit.";
        form.errors.productNormLowerLimit = null;
      } else if (
        name == "productNormUpperLimit" &&
        parseInt(e.target.value) > parseInt(form.productNormLowerLimit)
      ) {
        form.errors.productNormUpperLimit = null;
        form.errors.productNormLowerLimit = null;
      } else if (
        name == "productNormLowerLimit" &&
        parseInt(e.target.value) >= parseInt(form.productNormUpperLimit)
      ) {
        form.errors.productNormLowerLimit =
          "Lower limit can not be less than or equal to Upper limit.";
        form.errors.productNormUpperLimit = null;
      } else if (
        name == "productNormLowerLimit" &&
        parseInt(e.target.value) < parseInt(form.productNormUpperLimit)
      ) {
        form.errors.productNormLowerLimit = null;
        form.errors.productNormUpperLimit = null;
      } else {
        form.errors[name] = null;
      }

      this.setState({ form });
    } else {
      if (form[name] == "" || form[name] == null || form[name] == undefined) {
        form.errors[name] = true;
        this.setState({ form });
      } else {
        form.errors[name] = null;
        this.setState({ form });
      }
    }
    // let { product, loantype, lowerloanamtnorms, baseamt, state, percentagevalue } = form;
  };

  render() {
    let { form, showModal, showInner, isLoading } = this.state;
    let {
      productDropdownValue,
      levelDropdownOptions,
      segmentDropdownValue,
    } = this.props;
    let { errors } = form;
    let diableSave = false;
    let isRequiredFeildFilled = false;
    Object.keys(form).map((name) => {
      console.log("form", form[name]);
      if (form[name] == "" || form[name] == null || form[name] == undefined) {
        isRequiredFeildFilled = true;
        return true;
      }
    });

    Object.keys(errors).map((error) => {
      console.log("error", errors[error]);
      if (errors[error] == true || errors[error] != null) {
        diableSave = true;
        return true;
      }
    });
    console.log("form", form);

    return (
      <>
        {productDropdownValue &&
          productDropdownValue.length > 0 &&
          segmentDropdownValue &&
          segmentDropdownValue.length > 0 &&
          levelDropdownOptions &&
          levelDropdownOptions.length > 0 && (
            <div>
              <Modal
                className="product-norm-container"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showModal}
                size="lg"
              >
                <Modal.Header>
                  <Modal.Title>
                    {this.props.action == "edit"
                      ? "Edit Product Norm"
                      : "Add Product Norm"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                  {isLoading ? (
                    <h4 className="text-center">LOADING...</h4>
                  ) : (
                    <div className="row ">
                      <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                        <label>
                          <i className="text-danger">*</i> Product
                        </label>
                        <Select
                          defaultValue={
                            productDropdownValue.filter(
                              ({ productdescription }) =>
                                productdescription == form.product
                            )[0]
                          }
                          onChange={(e) => {
                            this.handleProductSelectChange(e, "product");
                          }}
                          onBlur={(e) => this.onValidate(e, "product")}
                          name="product"
                          options={productDropdownValue}
                          placeholder="Select Product"
                          valueKey="productdescription"
                          labelKey="productdescription"
                          getOptionLabel={(option) => option.productdescription}
                          getOptionValue={(option) => option.productdescription}
                          className={`${
                            errors.product ? "error-input-border" : ""
                          } `}
                          isDisabled={false}
                        />
                        {errors.product && (
                          <span className="errorMsg">
                            Please Select Product
                          </span>
                        )}
                      </div>
                      <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                        <label>
                          <i className="text-danger">*</i> Segment
                        </label>
                        <Select
                          defaultValue={
                            segmentDropdownValue.filter(
                              ({ segmentcode }) => segmentcode == form.segment
                            )[0]
                          }
                          onChange={(e) => {
                            this.handleSegmentSelectChanges(e, "segment");
                          }}
                          onBlur={(e) => this.onValidate(e, "segment")}
                          name="segment"
                          options={segmentDropdownValue}
                          placeholder="Select Segment"
                          valueKey="segmentcode"
                          labelKey="segmentdescription"
                          getOptionLabel={(option) => option.segmentdescription}
                          getOptionValue={(option) => option.segmentcode}
                          className={`${
                            errors.segment ? "error-input-border" : ""
                          } `}
                          isDisabled={false}
                        />
                        {errors.segment && (
                          <span className="errorMsg">
                            Please Select Segment
                          </span>
                        )}
                      </div>
                      <div className="mb-4 col-md-4 ">
                        <label>
                          <i className="text-danger">*</i> Level
                        </label>
                        <Select
                          defaultValue={
                            levelDropdownOptions.filter(
                              ({ levelcode }) => levelcode == form.level
                            )[0]
                          }
                          onChange={(e) => {
                            this.handleLevelSelectChanges(e, "level");
                          }}
                          onBlur={(e) => this.onValidate(e, "level")}
                          name="level"
                          options={levelDropdownOptions}
                          placeholder="Select level"
                          valueKey="levelcode"
                          labelKey="levelcode"
                          getOptionLabel={(option) => option.levelcode}
                          getOptionValue={(option) => option.levelcode}
                          className={`${
                            errors.level ? "error-input-border" : ""
                          } `}
                          isDisabled={false}
                        />
                        {errors.level && (
                          <span className="errorMsg">Please Enter Level</span>
                        )}
                      </div>
                      <div className="form-group col-md-4 ">
                        <label>
                          <i className="text-danger">*</i> Product Norms (lower
                          limit - In Rupees)
                        </label>
                        <div class="inner-addon right-addon">
                          <input
                            type="text"
                            // pattern="\d*"
                            maxLength="9"
                            name="productNormLowerLimit"
                            value={
                              form.productNormLowerLimit &&
                              formatIndianCurrency(form.productNormLowerLimit)
                            }
                            onChange={(e) => {
                              e.target.value = replaceComma(e.target.value);
                              if (checkIfNotDecimal(e.target.value)) {
                                this.handleInputChange(
                                  e,
                                  "productNormLowerLimit"
                                );
                              }
                            }}
                            onBlur={(e) => {
                              e.target.value = replaceComma(e.target.value);
                              this.onValidate(e, "productNormLowerLimit");
                            }}
                            placeholder="Enter Product Norms (Lower Limit)"
                            className={`form-input ${
                              errors.productNormLowerLimit
                                ? "error-input-border"
                                : ""
                            } `}
                            disabled={false}
                          />
                          {errors.productNormLowerLimit && (
                            <span className="errorMsg">
                              {errors.productNormLowerLimit == true
                                ? "Enter Product Norms (Lower Limit)"
                                : errors.productNormLowerLimit}
                            </span>
                          )}
                          {/* {!errors.loanamtnorms && invalidPanNumber && (
                                                        <span className="errorMsg">Please enter valid PAN</span>
                                                    )} */}
                        </div>
                      </div>
                      <div className="form-group col-md-4 ">
                        <label>
                          <i className="text-danger">*</i> Product Norms (upper
                          limit - In Rupees)
                        </label>
                        <div class="inner-addon right-addon">
                          <input
                            type="text"
                            // pattern="\d*"
                            maxLength="9"
                            name="productNormUpperLimit"
                            value={
                              form.productNormUpperLimit &&
                              formatIndianCurrency(form.productNormUpperLimit)
                            }
                            onChange={(e) => {
                              e.target.value = replaceComma(e.target.value);
                              if (checkIfNotDecimal(e.target.value)) {
                                this.handleInputChange(
                                  e,
                                  "productNormUpperLimit"
                                );
                              }
                            }}
                            onBlur={(e) => {
                              e.target.value = replaceComma(e.target.value);
                              this.onValidate(e, "productNormUpperLimit");
                            }}
                            placeholder="Enter Product Norms (upper limit)"
                            className={`form-input ${
                              errors.productNormUpperLimit
                                ? "error-input-border"
                                : ""
                            } `}
                            disabled={false}
                          />
                          {errors.productNormUpperLimit && (
                            <span className="errorMsg">
                              {errors.productNormUpperLimit == true
                                ? "Enter Product Norms (upper limit)"
                                : errors.productNormUpperLimit}
                            </span>
                          )}
                          {/* {!errors.productNormUpperLimit && invalidPanNumber && (
                                                        <span className="errorMsg">Please enter valid PAN</span>
                                                    )} */}
                        </div>
                      </div>
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="btn-danger"
                    onClick={this.handleModalClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-success"
                    disabled={diableSave || isRequiredFeildFilled || isLoading}
                    onClick={this.handleSubmit}
                  >
                    {this.props.action == "edit" ? "Update" : "Create"}
                  </Button>
                </Modal.Footer>
              </Modal>
              <CloseModal
                show={showInner}
                button2={this.handleModalClose}
                button1={this.innerModalClose}
                title="Do you want to close ?"
              />
            </div>
          )}
      </>
    );
  }
}

export default AddEditProductFundingNorms;
