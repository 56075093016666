export const LoanTypeOption = [
  {
    laonTypeLabel: "Unsecured",
    laonTypeValue: "Unsecured",
  },
  {
    laonTypeLabel: "Secured",
    laonTypeValue: "Secured",
  },
];

export const CategoryOption = [
  {
    categoryTypeLabel: "Director's Club",
    categoryTypeValue: "Director's Club",
  },
  {
    categoryTypeLabel: "Other's Announcement",
    categoryTypeValue: "Other's Announcement",
  },
];

const hostname = window.location.hostname;
let tempLoginUrl;

if (hostname === "central-admin.sarvagram.com")
  tempLoginUrl = "https://auth.sarvagram.com";
else if (hostname === "uat-central-admin.sarvagram.net")
  tempLoginUrl = "https://uat-login.sarvagram.net";
else tempLoginUrl = "https://dev-login.sarvagram.net";

export const loginUrl = tempLoginUrl;
