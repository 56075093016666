import React, { useState, useEffect } from 'react';
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddEditDfpMaster from "./AddEditDfpMaster";
import { formatIndianCurrency } from "../../../Utils/helperFunctions";
import { deleteDfpMasterById, getAllDfpMasterData, findallroledropdown } from "../../../Utils/Services/dfpMater";
import { getAllProducts } from "../../../Utils/Services/common";

toast.configure();

let data = [
    {
        role: "",
        loanType: "",
        producttype: "",
        pricingUpperLimit: "",
        pricingLowerLimit: "",
        active: "",
        id: null
    }
]
const DfpMaster = (props) => {
    let [search, setSearch] = useState("");
    let [DfpMasterData, setDfpMasterData] = useState([]);
    let [RoleDropdownValue, setRoleDropdownValue] = useState([]);
    let [levelDropdownOptions, setlevelDropdownOptions] = useState([]);
    let [productDropdownValue, setproductDropdownValue] = useState([]);
    let [showAddDfpMasterModal, setshowAddDfpMasterModal] = useState(false);
    let [showEditDfpMasterModal, setshowEditDfpMasterModal] = useState(false);
    let [editData, setEditData] = useState({});

    useEffect(() => {
        getDfpMaster();
        getRole();
        getProducts();
    }, []);

    const getRole = () => {
        findallroledropdown().then((result) => {
            if (result.error) {
                return;
            } else if (result.data && result.data.error) {
                toast.error(result.data.message, {
                    type: toast.TYPE.ERROR,
                    autoClose: 2000
                });
                return;
            } else {
                if (result && result.data && result.data.data) {
                    setRoleDropdownValue(result.data.data)
                }
            }
        })
    }


    const getProducts = () => {
        getAllProducts().then((result) => {
            if (result.error) {
                return;
            } else if (result.data && result.data.error) {
                toast.error(result.data.message, {
                    type: toast.TYPE.ERROR,
                    autoClose: 2000
                });
            } else {
                if (result && result.data && result.data.data) {
                    setproductDropdownValue(result.data.data)
                }
            }
        })
    }

    const getDfpMaster = () => {
        getAllDfpMasterData().then((res) => {
            console.log("getAllDfpMasterData", res)
            if (res.error) {
                return;
            }
            if (res.data && res.data.error) {
                toast.error(res.data.message, {
                    type: toast.TYPE.ERROR,
                    autoClose: 2000
                });
            } else {
                /* toast.success(res.data.message, {
                    type: toast.TYPE.SUCCESS,
                    autoClose: 2000
                }); */
                setDfpMasterData(res.data.data)
            }
        })
    }

    const deleteDfpById = id => {
        let postData = {
            id: id
        }
        confirmAlert({
            title: "Confirm to Delete",
            message: "Are you sure to do this?",
            buttons: [
                {
                    label: "Yes, Delete It!",
                    onClick: () => {
                        deleteDfpMasterById(postData).then(response => {
                            console.log("deleteDfpMasterById", response)
                            if (response.error) {
                                return;
                            }
                            if (response.data && response.data.error) {
                                toast.error(response.data.message, {
                                    type: toast.TYPE.ERROR,
                                    autoClose: 2000
                                });
                            } else {
                                toast.success(response.data.message, {
                                    type: toast.TYPE.SUCCESS,
                                    autoClose: 2000
                                });
                                getDfpMaster();
                            }
                        });
                    }
                },
                {
                    label: "No",
                    onClick: () => {
                        // toast.error("Role Can Not be Deleted", {
                        //   type: toast.TYPE.ERROR,
                        //   autoClose: 2000

                        // });
                        // this.RoleList();
                    }
                }
            ]
        });
    };

    const columns = [
        {
            Header: "SR. No",
            width: 70,
            Cell: ((data) => {
                // console.log("data...", data)
                return data.index + 1
            })
            /* Cell: ({ original }) => {
                return original.id && original.id ? original.id : null;
            } */
        },
        {
            Header: "Product",
            width: 200,
            Cell: ({ original }) => {
                return original && original.product ? original.product : null;
            }
        },
        {
            Header: "Type",
            Cell: ({ original }) => {
                return original && original.loanType ? original.loanType : null;
            }
        },
        {
            Header: "Role",
            Cell: ({ original }) => {
                return original && original.roleName ? original.roleName : null;
            }
        },
        {
            Header: "Pricing limit (In Rupees)",
            Cell: ((data) => {
                let { original } = data;
                let text = "Not applicable";
                if ((original.pricingUpperLimit != "" && original.pricingLowerLimit != "") && (original.pricingUpperLimit != undefined && original.pricingLowerLimit != undefined) && (original.pricingUpperLimit != 0 && original.pricingLowerLimit != 0)) {
                    text = `${original.pricingLowerLimit && formatIndianCurrency(original.pricingLowerLimit)}-${original.pricingUpperLimit && formatIndianCurrency(original.pricingUpperLimit)}`
                } else if ((original.pricingUpperLimit != "" && original.pricingLowerLimit != "") && (original.pricingUpperLimit != undefined && original.pricingLowerLimit != undefined) && (original.pricingUpperLimit == 0 && original.pricingLowerLimit != 0)) {
                    text = `${original.pricingLowerLimit && formatIndianCurrency(original.pricingLowerLimit)}-${original.pricingUpperLimit && formatIndianCurrency(original.pricingUpperLimit)}`
                } else if ((original.pricingUpperLimit != "" && original.pricingLowerLimit != "") && (original.pricingUpperLimit != undefined && original.pricingLowerLimit != undefined) && (original.pricingUpperLimit != 0 && original.pricingLowerLimit == 0)) {
                    text = `${original.pricingLowerLimit && formatIndianCurrency(original.pricingLowerLimit)}-${original.pricingUpperLimit && formatIndianCurrency(original.pricingUpperLimit)}`
                } else if ((original.pricingUpperLimit
                    != undefined && original.pricingLowerLimit
                    != undefined) || (original.pricingUpperLimit == 0 && original.pricingLowerLimit == 0)) {
                    text = `Not applicable`
                }
                return text
            })
        },
        /* {
            Header: "Active",
            Cell: ({ original }) => {
                return original && original.active ? original.active : null;
            }
        }, */
        {
            Header: "Action",
            width: 125,
            disabled: false,
            accessor: "delete",
            accessor: "edit",

            Cell: ({ original }) => {
                return (
                    <div
                        disabled={false}
                        style={{ display: "flex", justifyContent: "space-around" }}
                    >
                        <i
                            disabled={false}
                            onClick={() => deleteDfpById(original.id)}
                            className="fa fa-trash"
                            style={{ cursor: "pointer" }}
                        />
                        <i
                            disabled={false}
                            onClick={() => handleEditDfpMaster(original)}
                            className="fas fa-user-edit"
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                );
            }
        }
    ];

    const handleAddDfpMaster = id => {
        setshowAddDfpMasterModal(!showAddDfpMasterModal);
    };

    const handleEditDfpMaster = obj => {
        setshowEditDfpMasterModal(!showEditDfpMasterModal);
        setEditData(obj)
    };

    const handleSearch = e => {
        /* let { roleContainer } = this.state;
        let array = [];
        roleContainer.map(res => {
            let roleName = res.roleName;
            if (roleName)
                if (res.roleName.match(e.target.value.toUpperCase())) {
                    array.push(res);
                }
        });
        this.setState({
            search: e.target.value,
            roleData: e.target.value ? array : roleContainer
        }); */
    };

    return (
        <div className="dash_grid">
            <ToastContainer />
            <LeftMenu />
            <main className="bg-white">
                <Header {...props} />
                <section className="container-fluid mb-5">
                    <h5 className="text-center mt-5 mx-auto user-box"> DFP Master</h5>
                    <div className="d-flex align-items-center mt-5">
                        <button
                            disabled={false}
                            onClick={handleAddDfpMaster}
                            className="add-btn ml-auto btn btn-info btn-lg"
                        >
                            {" "}
                    Add {" "}
                        </button>
                        {showAddDfpMasterModal && (
                            <AddEditDfpMaster
                                action={"add"}
                                getDfpMaster={getDfpMaster}
                                handleAddDfpMaster={handleAddDfpMaster}
                                productDropdownValue={productDropdownValue}
                                RoleDropdownValue={RoleDropdownValue}
                            />
                        )}
                        {showEditDfpMasterModal && (
                            <AddEditDfpMaster
                                action={"edit"}
                                getDfpMaster={getDfpMaster}
                                editData={editData}
                                handleEditDfpMaster={handleEditDfpMaster}
                                productDropdownValue={productDropdownValue}
                                RoleDropdownValue={RoleDropdownValue}
                            />
                        )}
                    </div>

                    <div
                        className={`mt-4 react-table`}
                    >
                        <ReactTable
                            data={DfpMasterData}
                            columns={columns}
                            defaultPageSize={10}
                        />
                    </div>
                </section>
            </main>
        </div>
    );
};

export default DfpMaster;