import React, { Component } from "react";
// import { resetPassword } from "../Utils/authentication";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export class ForgetPassword extends Component {
  state = {
    username: "",
    otp: "",
    newpassword: "",
    ConfirmPassword: "",
    invalidUsername: false,
    invalidConfirmPassword: false,
    invalidPassword: false,
    isLoading: false,
    errors: {
      usernameError: null,
      otpError: null,
      newpasswordError: null,
      ConfirmPasswordError: null,
    },
  };

  // handleKeyDown = e => {
  //   if (e.key === "Enter") {
  //     this.handleSubmit(e);
  //   }
  // };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  //restrict any special characters used in password
  restrictAlphabets = (e) => {
    const regx = "^[0-9]*$";
    if (e.key.match(regx)) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  //validate confirmpassword and newpassword
  handleValidate = (e) => {
    const { errors, newpassword, ConfirmPassword } = this.state;
    let name = e.target.name;
    let value = e.target.value;
    if (value === "" || value === null || value === undefined) {
      this.setState({ errors: { ...errors, [name + "Error"]: true } });
    } else {
      this.setState({ errors: { ...errors, [name + "Error"]: false } });
    }

    if (name === "username") {
      let usernameRegx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value && !value.match(usernameRegx)) {
        this.setState({
          invalidUsername: true,
        });
      } else {
        this.setState({
          invalidUsername: false,
        });
      }
    }

    if (name === "ConfirmPassword") {
      if (value && value !== newpassword) {
        this.setState({
          invalidConfirmPassword: true,
        });
      } else {
        this.setState({
          invalidConfirmPassword: false,
        });
      }
    }

    if (name === "newpassword") {
      let passwordRegx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (value && value !== ConfirmPassword) {
        this.setState({
          invalidConfirmPassword: true,
        });
      } else {
        this.setState({
          invalidConfirmPassword: false,
        });
      }

      if (value && !value.match(passwordRegx)) {
        this.setState({
          invalidPassword: true,
        });
      } else {
        this.setState({
          invalidPassword: false,
        });
      }
    }
  };

  handleSubmit = () => {
    const { username, newpassword, otp, errors } = this.state;
    let isLogin = true;
    for (var val in errors) {
      if (errors[val] === null || errors[val]) {
        errors[val] = true;
        isLogin = false;
      }
    }

    let obj = { username, newpassword, otp };
    if (isLogin) {
      this.setState({ isLoading: true });
      /* resetPassword(obj).then(response => {
        console.log("REset.....", response.data && response.data);
        this.setState({ isLoading: false });
        if (response.data && response.data.error === "false") {
          this.props.history.push("/dashboard");
          toast.success(response.data.message, {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000
          });
          console.log(response.data && response.data);
        }
        if (response.data && response.data.error === "true") {
          toast.error(response.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000
          });
          return false;
        }
      }); */
    }
  };

  render() {
    const {
      username,
      otp,
      newpassword,
      ConfirmPassword,
      invalidConfirmPassword,
      invalidUsername,
      errors,
      invalidPassword,
    } = this.state;
    console.log(invalidConfirmPassword && invalidConfirmPassword);

    return (
      <div>
        <ToastContainer />
        <section className="login-section">
          <div className="page-container">
            <div
              style={{ width: "450px", marginTop: "140px" }}
              className="content"
            >
              <h3 className="text-center title-font mb-3">Reset Password</h3>
              <div className="form-group">
                <input
                  type="email"
                  pattern="\d*"
                  maxLength="50"
                  className="form-input"
                  name="username"
                  value={username}
                  onChange={this.handleChange}
                  onBlur={this.handleValidate}
                  onKeyDown={this.handleKeyDown}
                  placeholder="Email ID"
                />
                {errors.usernameError && (
                  <span className="errorMsg">Please enter email</span>
                )}
                {invalidUsername && (
                  <span className="errorMsg">Please enter valid email</span>
                )}
              </div>
              <div className="form-group">
                <input
                  type="number"
                  className="form-input"
                  name="otp"
                  value={otp}
                  onChange={this.handleChange}
                  onBlur={this.handleValidate}
                  onKeyDown={this.handleKeyDown}
                  onKeyPress={this.restrictAlphabets}
                  placeholder="OTP"
                />
                {errors.otpError && (
                  <span className="errorMsg">Please enter OTP</span>
                )}
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-input"
                  name="newpassword"
                  value={newpassword}
                  onChange={this.handleChange}
                  onBlur={this.handleValidate}
                  onKeyDown={this.handleKeyDown}
                  placeholder="New Password"
                />
                {errors.newpasswordError && (
                  <span className="errorMsg">Please enter New password</span>
                )}
                {invalidPassword && (
                  <span className="errorMsg">
                    Password must contain at least 8 characters, including
                    uppercase, lowercase and numbers
                  </span>
                )}
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-input"
                  name="ConfirmPassword"
                  value={ConfirmPassword}
                  onChange={this.handleChange}
                  onBlur={this.handleValidate}
                  onKeyDown={this.handleKeyDown}
                  placeholder="Confirm Password"
                />
                {errors.ConfirmPasswordError && (
                  <span className="errorMsg">
                    Please enter Confirm password
                  </span>
                )}
                {invalidConfirmPassword && (
                  <span className="errorMsg">
                    Please Enter Correct Confirm Password
                  </span>
                )}
              </div>

              <div className="form-group">
                <button
                  onClick={this.handleSubmit}
                  // disabled={invalidConfirmPassword}
                  className="form-submit"
                  disabled={invalidConfirmPassword || invalidPassword}
                >
                  {" "}
                  Login
                  {this.state.isLoading ? (
                    <i
                      class="fa fa-spinner fa-spin"
                      style={{ fontSize: "24px" }}
                    ></i>
                  ) : (
                    ""
                  )}
                </button>
              </div>

              {/* <div className="form-group m-0">
                <Link to="#" className="forgot-password">
                  <i className="fas fa-key mr-2"></i>Forgot password?
                </Link>
              </div> */}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ForgetPassword;
