import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import ImageUploader from "react-images-upload";
import { cloneDeep, fromPairs } from "lodash";
import { CategoryOption } from "../../Utils/constant";
import { FileUrl, API_URL } from "../../Utils/config";
import axios from "axios";
import CloseModal from "../../Components/CloseModal";

import { uploadImageDoc } from "../../Utils/Services/Campaign";
import moment from "moment";
toast.configure();

const initForm = {
  id: "",
  bannerTitle: "",
  startDate: "",
  endDate: "",
  category: "",
  imageFile: "",
  banner: "",
  imageOne: "",
  notificationTitle: "",
  notificationSubTitle: "",
  notificationDesc: "",
  token: "",
  errors: {
    bannerTitle: null,
    startDate: null,
    endDate: null,
    category: null,
    imageFile: null,
    banner: null,
    imageOne: null,
    notificationTitle: null,
    notificationSubTitle: null,
    notificationDesc: null,
    token: null,
  },
};

const statusType = [
  { statusLabel: "ACTIVE", statusValue: "ACTIVE" },
  { statusLabel: "INACTIVE", statusValue: "INACTIVE" },
];

const dropstyle = {
  container: (base) => ({
    ...base,
    flex: 1,
  }),
};

export class Addedituser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      showInner: false,
      form: cloneDeep(initForm),
      key: Math.random(),
      userBranch: [],
      imageOne: [],
      userZone: [],
      userRegion: [],
      userVillage: [],
      zoneDropdownValue: [],
      branchDropdownValue: [],
      regionDropdownValue: [],
      categoryDropdownValue: [],
      villageDropdownValue: [],
      loaderForSpoke: false,
      imageLoading1: false,
      DisableFields: false,
      isStartDateSet: false,
      disableImageFile: false,
      isValid: false,

      errors: {
        imageOne: null,
      },
    };
  }

  componentDidMount = () => {
    let { form } = this.state;
    let { editData } = this.props;
    this.setState({ showModal: true });
    if (this.props.editData && this.props.action == "edit") {
      let { form } = this.state;
      let obj = {
        ...form,
        ...this.props.editData,
      };
      let indexNew =
        editData &&
        editData.village &&
        editData.village.findIndex((e) => e.villageName === "All");
      let userVillage1 = [];
      if (indexNew > -1) {
        userVillage1.push(
          editData &&
            editData.village &&
            editData.village.filter((e) => e.villageName === "All")
        );
      }
      let newdata =
        userVillage1 && userVillage1.length > 0
          ? userVillage1[0]
          : editData.village;

      newdata &&
        newdata.map((e) => {
          e.id = e.pincodeVillageMappingId;
        });
      this.setState({
        form: obj,
        userVillage: newdata,
        key: Math.random(),
      });
    }
  };

  handleModalClose = () => {
    this.setState({ showInner: !this.state.showInner });
    // this.this.props.addRole();
  };

  innerModalClose = (modclose) => {
    if (!modclose) {
      this.handleModalClose();
    }
    this.setState({ showModal: false });
    if (this.props.action == "edit") {
      this.props.handleEditUser();
    } else {
      this.props.handleAddUser();
    }
  };

  sendRequest = () => {
    let { form, imageOne, selectedFile } = this.state;
    let { editData } = this.props;
    console.log("logging", this.state);
    console.log("imagecheck", imageOne);
    form.imageFile = imageOne;
    let userData = JSON.parse(localStorage.getItem("userData"));
    // let { imageOne, form } = this.state;
    let Margin = imageOne && imageOne[0] && imageOne[0].name;
    let content = imageOne[0];
    console.log("formData", form);
    // let _this = this;
    // let blob = new Blob([content], { type: "image" });
    const formData = new FormData();
    formData.append("imageFile", form.selectedFile);
    console.log("selected", form.selectedFile);
    console.log("margin is", imageOne);
    formData.append(
      "notificationBannerData",
      JSON.stringify({
        id: form.originalId,
        notificationTitle: form.notificationTitle,
      })
    );

    this.setState({ isLoading: true });
    axios
      .post(
        `${API_URL}/admin-notification/saveorupdatenotification`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${userData.adminAccessToken}`,
          },
        }
      )
      .then((response) => {
        console.log("testing imageOne", imageOne);
        console.log();
        // this.setState({ isLoading: false });
        if (response.data && response.data.error) {
          toast.error(response.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
          return;
        } else {
          if (imageOne && imageOne[0] && imageOne[0].name !== "") {
            this.setState({ imageLoading1: true });
          }
          this.setState({ showModal: false });
          toast.success(response.data.message, {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
          window.location.reload(true);
        }
        this.props.getCampaigns();
      });
  };

  handleSubmit = () => {
    let { form, imageOne, selectedFile } = this.state;
    let { editData } = this.props;
    console.log("logging", this.state);
    console.log("imagecheck", imageOne);
    form.imageFile = imageOne;
    let userData = JSON.parse(localStorage.getItem("userData"));
    let Margin = imageOne && imageOne[0] && imageOne[0].name;
    let content = imageOne[0];
    console.log("formData", form);
    const formData = new FormData();
    formData.append("imageFile", form.selectedFile);
    console.log("selected", form.selectedFile);
    console.log("margin is", imageOne);
    console.log("formData1", formData);
    formData.append(
      "notificationBannerData",
      JSON.stringify({
        bannerTitle: form.bannerTitle,
        startDate: form.startDate,
        endDate: form.endDate,
        category: form.category,
        id: form.originalId,
        notificationTitle: form.notificationTitle,
        notificationSubTitle: form.notificationSubTitle,
        notificationDesc: form.notificationDesc,
      })
    );
    this.setState({ isLoading: true });
    console.log("formData1", formData);
    axios
      .post(
        `${API_URL}/admin-notification/saveorupdatenotification`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${userData.adminAccessToken}`,
          },
        }
      )
      .then((response) => {
        console.log("testing imageOne", imageOne);
        console.log();
        if (response.data && response.data.error) {
          toast.error(response.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
          return;
        } else {
          if (imageOne && imageOne[0] && imageOne[0].name !== "") {
            this.setState({ imageLoading1: true });
          }
          this.setState({ showModal: false });
          toast.success(response.data.message, {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
          window.location.reload(2000);
        }
      });
  };
  onDrop = async (file, picture, name) => {
    let { errors, uploadCount, form } = this.state;
    let { imageOne } = this.state.form;
    let state = this.state;
    let count = uploadCount;
    let fileName = file[0].name;
    console.log("file", file, imageOne, fileName);
    console.log("picture", picture.length);
    console.log("name", file, file && file.length > 0);
    if (picture.length == 1) {
      errors = { ...errors, [name]: false };
    } else {
      errors = { ...errors, [name]: true };
    }
    if (file && file.length > 0) {
      count = uploadCount + 1;
      console.log("name", count);
      this.setState({
        ...state,
        form: { ...form, imageOne: [...imageOne, fileName] },
      });
      this.setState({
        uploadCount: count,
        [name]: file[0].name,
        errors: errors,
      });
    } else {
      count = uploadCount - 1;
      this.setState({ uploadCount: count, [name]: file.name, errors: errors });
    }
    this.setState({ imageOne: file });
    console.log("theloginondrop", this.state);
  };
  uploadDocuments = (formData, imageNumber) => {
    let { responseCount, form } = this.state;
    let count = responseCount;
    console.log("check our data", this.state);
    uploadImageDoc(formData).then((res) => {
      if (imageNumber == "imageOne") {
        this.setState({ imageLoading1: false }, () => {
          if (this.state.imageLoading1 == false) {
            this.setState({ [imageNumber]: [] });
          }
          console.log("herre...", "1", this.state.imageLoading1 == false);
          if (this.state.imageLoading1 == false) {
            this.setState({ showModal: false });
            this.props.findAllVillage();
            toast.success(res && res.data && res.data.message, {
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }
        });
      } else if (imageNumber == "imageTwo") {
        this.setState({ imageLoading2: false }, () => {
          if (this.state.imageLoading2 == false) {
            this.fetchBySerialNumber(form.serialNumber);
            this.setState({ [imageNumber]: [] });
          }
          console.log("herre...", "2", this.state.imageLoading1 == false);
          if (this.state.imageLoading1 == false) {
            this.setState({ showModal: false });
            this.props.getImplementInventory();
            if (this.props.serialNumberimp && this.props.action == "edit") {
              this.props.handleEditInventoryMaster();
            } else {
              this.props.handleAddCancel();
            }
            toast.success(res && res.data && res.data.message, {
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }
        });
      } else if (imageNumber == "imageThree") {
        this.setState({ imageLoading3: false }, () => {
          if (this.state.imageLoading3 == false) {
            this.fetchBySerialNumber(form.serialNumber);
            this.setState({ [imageNumber]: [] });
          }
          console.log("herre...", "3", this.state.imageLoading1 == false);
          if (this.state.imageLoading1 == false) {
            this.setState({ showModal: false });
            this.props.getImplementInventory();
            if (this.props.serialNumberimp && this.props.action == "edit") {
              this.props.handleEditInventoryMaster();
            } else {
              this.props.handleAddCancel();
            }
            toast.success(res && res.data && res.data.message, {
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }
        });
      } else if (imageNumber == "imageFour") {
        this.setState({ imageLoading4: false }, () => {
          if (this.state.imageLoading4 == false) {
            this.fetchBySerialNumber(form.serialNumber);
            this.setState({ [imageNumber]: [] });
          }
          console.log("herre...", "4", this.state.imageLoading1 == false);
          if (this.state.imageLoading1 == false) {
            this.setState({ showModal: false });
            this.props.getImplementInventory();
            if (this.props.serialNumberimp && this.props.action == "edit") {
              this.props.handleEditInventoryMaster();
            } else {
              this.props.handleAddCancel();
            }
            toast.success(res && res.data && res.data.message, {
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }
        });
      } else if (imageNumber == "imageFive") {
        this.setState({ imageLoading5: false }, () => {
          if (this.state.imageLoading5 == false) {
            this.fetchBySerialNumber(form.serialNumber);
            this.setState({ [imageNumber]: [] });
          }
          console.log("herre...", "5", this.state.imageLoading1 == false);
          if (this.state.imageLoading1 == false) {
            this.setState({ showModal: false });
            this.props.getImplementInventory();
            if (this.props.serialNumberimp && this.props.action == "edit") {
              this.props.handleEditInventoryMaster();
            } else {
              this.props.handleAddCancel();
            }
            toast.success(res && res.data && res.data.message, {
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }
        });
      }
      if (res && res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res && res.data && res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        this.setState({ [imageNumber]: "" });
        return;
      } else {
      }
    });
  };

  handleInputChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("handleInputChange", e, name, e.target.value);
    form[name] = e.target.value.trim();
    if (/^\s/.test(e.target.value)) e.target.value = "";
    this.setState({ form });
  };

  handleFileSelect = (e) => {
    let { form } = this.state;
    console.log(e.target.files[0]);
    if (e.target.files[0].size > 1153433) {
      form.errors.imageFile = "Image size should be less than 1 MB";
    } else {
      form["selectedFile"] = e.target.files[0];
      form.errors.imageFile = null;
    }
    this.setState({ form });
  };

  handleroleSelectChange = (e, name) => {
    console.log("roleCode", e, name);
    let { form } = this.state;
    let { roleCode } = e;
    form[name] = roleCode;
    this.setState({ form });
  };

  handleZoneSelectChange = (e, name) => {
    console.log("zonecode", e, name);
    let { form } = this.state;
    let { zonecode } = e;
    form[name] = zonecode;
    if (
      form &&
      (form[name] === "" || form[name] === null || form[name] === undefined)
    ) {
      if (form && form.errors) {
        form.errors[name] = true;
        this.setState({ form });
      }
    } else {
      if (form && form.errors) {
        form.errors[name] = null;
        this.setState({ form });
      }
    }
    this.setState({ form, key: Math.random() });
    this.clearAll();
    this.getregions();
  };

  handleRegionSelectChange = (e, name) => {
    console.log("regioncode", e, name);
    let { form } = this.state;
    let { regioncode } = e;
    form[name] = regioncode;
    if (
      form &&
      (form[name] === "" || form[name] === null || form[name] === undefined)
    ) {
      if (form && form.errors) {
        form.errors[name] = true;
        this.setState({ form });
      }
    } else {
      if (form && form.errors) {
        form.errors[name] = null;
        this.setState({ form });
      }
    }
    this.setState({ form, key: Math.random() });
    this.clearAllBranch();
    this.getbranches();
  };

  handleBranchSelectChange = (e, name) => {
    console.log("branchcode", e, name);
    let { form } = this.state;
    let { branchcode } = e;
    form[name] = branchcode;
    if (
      form &&
      (form[name] === "" || form[name] === null || form[name] === undefined)
    ) {
      if (form && form.errors) {
        form.errors[name] = true;
        this.setState({ form });
      }
    } else {
      if (form && form.errors) {
        form.errors[name] = null;
        this.setState({ form });
      }
    }
    this.setState({ form });
    this.getvillage();
  };
  handleLoanTypeSelectChange = (e, name) => {
    console.log("handleLoanTypeSelectChange", e, name);
    let { categoryTypeLabel, categoryTypeValue } = e;
    let { form } = this.state;
    form[name] = categoryTypeValue;
    this.setState({ form });
  };
  onValidate = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    if (
      form[name].trim() == "" ||
      form[name] == null ||
      form[name] == undefined
    ) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      form.errors[name] = null;
      this.setState({ form });
    }
    console.log("foo", form);
  };

  handleSelectChanges = (e, name) => {
    console.log("handleSegmentSelectChanges", e, name);
    let { form } = this.state;
    let { statusValue } = e;
    form[name] = statusValue;
    this.setState({ form });
  };

  clearAll = () => {
    let { form } = this.state;
    form.region = "";
    form.branch = "";
    this.setState({ form });
  };

  render() {
    console.log(this.state);
    let { form, showModal, showInner, isLoading } = this.state;
    let { errors } = form;
    let diableSave = false;
    let isRequiredFeildFilled = false;

    Object.keys(form).map((name) => {
      // console.log("form", form[name]);
      if (form[name] == "" || form[name] == null || form[name] == undefined) {
        isRequiredFeildFilled = true;
        return true;
      }
    });

    Object.keys(errors).map((error) => {
      if (errors[error] == true || errors[error] != null) {
        diableSave = true;
        return true;
      }
    });
    console.log("propsData", this.props, this.state.form);
    return (
      <>
        <Modal
          className="add-role"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showModal}
        >
          <Modal.Header>
            <Modal.Title>
              {this.props.action == "edit" ? "Reminder" : "Create Campaign"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            {isLoading ? (
              <h4 className="text-center">LOADING...</h4>
            ) : (
              <>
                <div className="row ">
                  {this.props.action != "edit" && (
                    <div className="col-md-12 mt-3">
                      <div className="form-group">
                        <label>
                          <i className="text-danger">*</i> Banner Title
                        </label>
                        <div class="inner-addon right-addon">
                          <input
                            type="text"
                            // pattern="\d*"
                            //   maxLength="9"
                            name="Banner Title"
                            id="noWhiteSpaceAtTheStart"
                            // value={form.bannerTitle.replace(/[^a-zA-Z ]/g, "")}
                            Value={
                              this.props.action == "edit"
                                ? this.props.editData.data[0].bannerTitle
                                : "" &&
                                  form.bannerTitle.replace(/[^a-zA-Z0-9]/g, "")
                            }
                            // value={form.bannerTitle}
                            onChange={(e) => {
                              this.handleInputChange(e, "bannerTitle");
                            }}
                            maxLength={25}
                            onBlur={(e) => {
                              this.onValidate(e, "bannerTitle");
                            }}
                            placeholder="Enter bannerTitle"
                            disabled={this.props.action == "edit"}
                            className={`form-input ${
                              errors.bannerTitle ? "error-input-border" : ""
                            } `}
                          />
                          {errors.bannerTitle && (
                            <span className="errorMsg">
                              {errors.bannerTitle == true
                                ? "Enter Banner Title"
                                : errors.bannerTitle}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {this.props.action == "edit" && (
                    <div className="col-md-12 mt-3">
                      <div className="form-group">
                        <label>
                          <i className="text-danger">*</i> Title
                        </label>
                        <div class="inner-addon right-addon">
                          <input
                            type="text"
                            // pattern="\d*"
                            //   maxLength="9"
                            name="Title"
                            id="noWhiteSpaceAtTheStart"
                            // defaultValue={this.props.editData.data[0].notificationTitle.replace(/[^a-zA-Z ]/g, "")}
                            onChange={(e) => {
                              this.handleInputChange(e, "notificationTitle");
                            }}
                            maxLength={25}
                            onBlur={(e) => {
                              this.onValidate(e, "notificationTitle");
                            }}
                            placeholder="Title"
                            className={`form-input ${
                              errors.notificationTitle
                                ? "error-input-border"
                                : ""
                            } `}
                            disabled={false}
                          />
                          {errors.notificationTitle && (
                            <span className="errorMsg">
                              {errors.notificationTitle == true
                                ? "Enter Reminder Title"
                                : errors.notificationTitle}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* emp ID */}
                  {this.props.action != "edit" && (
                    <div className="col-md-12 mt-3">
                      <div className="form-group">
                        <label>
                          {this.props.action === "add" && (
                            <i className="text-danger">*</i>
                          )}{" "}
                          Banner Image
                        </label>
                        <div class="inner-addon right-addon">
                          <input
                            type="file"
                            //   maxLength="9"
                            name="Banner Image/Video"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(e) => {
                              this.handleFileSelect(e);
                              this.setState({ disableImageFile: true });
                            }}
                            pattern="[^\s]+"
                            placeholder="Choose"
                            className={`form-input ${
                              errors.imageFile ? "error-input-border" : ""
                            } `}
                            // disabled={this.props.action == "edit"}
                          />
                          {errors.imageFile && (
                            <span className="errorMsg">
                              {errors.imageFile === true
                                ? "Please select an image"
                                : errors.imageFile}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* email ID */}
                  {this.props.action != "edit" && (
                    <div className="col-md-12 mt-3">
                      <div className="form-group">
                        <label>
                          <i className="text-danger">*</i> Start date
                        </label>
                        <div class="inner-addon right-addon">
                          <input
                            type="date"
                            // pattern="\d*"
                            //   maxLength="9"
                            name="Start Date"
                            defaultValue={
                              this.props.action == "edit"
                                ? this.props.editData.data[0].startDate.split(
                                    " "
                                  )[0]
                                : ""
                            }
                            // value={form.startDate}
                            onChange={(e) => {
                              this.handleInputChange(e, "startDate");
                              this.setState({ isStartDateSet: true });
                              if (e.target.value == "") {
                                this.setState({ isStartDateSet: false });
                              }
                            }}
                            min={moment(new Date()).format("YYYY-MM-DD")}
                            onBlur={(e) => {
                              this.onValidate(e, "startDate");
                            }}
                            placeholder="Start Date"
                            disabled={this.props.action == "edit"}
                            className={`form-input ${
                              errors.startDate ? "error-input-border" : ""
                            } `}
                            // disabled={this.props.action == "edit"}
                          />
                          {errors.startDate && (
                            <span className="errorMsg">
                              {errors.startDate == true
                                ? "Start Date"
                                : errors.startDate}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* mobile number */}
                  {this.props.action != "edit" && (
                    <div className="col-md-12 mt-3">
                      <div className="form-group">
                        <label>
                          <i className="text-danger">*</i> End Date
                        </label>
                        <div class="inner-addon right-addon">
                          <input
                            type="date"
                            // pattern="\d*"

                            name="End Date"
                            defaultValue={
                              this.props.action == "edit"
                                ? this.props.editData.data[0].endDate.split(
                                    " "
                                  )[0]
                                : ""
                            }
                            // value={form.endDate}
                            onChange={(e) => {
                              this.handleInputChange(e, "endDate");
                            }}
                            onBlur={(e) => {
                              this.onValidate(e, "endDate");
                            }}
                            min={
                              form.startDate
                                ? form.startDate
                                : moment(new Date()).format("YYYY-MM-DD")
                            }
                            placeholder="End Date"
                            // disabled={this.props.action == "edit" || this.state.isStartDateSet==false}
                            disabled={
                              this.props.action == "edit" ||
                              this.state.isStartDateSet == false
                            }
                            className={`form-input ${
                              errors.endDate ? "error-input-border" : ""
                            } `}
                          />
                          {errors.endDate && (
                            <span className="errorMsg">
                              {errors.endDate == true
                                ? "Enter End Date"
                                : errors.endDate}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.props.action != "edit" && (
                    <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                      <label>
                        <i className="text-danger">*</i> Category
                      </label>
                      <Select
                        defaultValue={
                          this.props.action == "edit"
                            ? CategoryOption.filter(
                                ({ categoryTypeValue }) =>
                                  categoryTypeValue ==
                                  this.props.editData.data[0].category
                              )[0]
                            : ""
                        }
                        onChange={(e) => {
                          this.handleLoanTypeSelectChange(e, "category");
                        }}
                        isDisabled={this.props.action == "edit"}
                        onBlur={(e) => this.onValidate(e, "category")}
                        name="category"
                        options={CategoryOption}
                        placeholder="Select Category"
                        valueKey="categoryTypeValue"
                        labelKey="categoryTypeLabel"
                        getOptionLabel={(option) => option.categoryTypeLabel}
                        getOptionValue={(option) => option.categoryTypeValue}
                        className={`${
                          errors.category ? "error-input-border" : ""
                        } `}
                      />
                      {errors.category && (
                        <span className="errorMsg">Please Select Category</span>
                      )}
                    </div>
                  )}

                  {/* <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label>
                        <i className="text-danger">*</i> Sub-Title
                      </label>
                      <div class="inner-addon right-addon">
                        <input
                          type="text"
                          // pattern="\d*"
                          //   maxLength="9"
                          name="Sub-Title"
                          // defaultValue={this.props.editData.data[0].notificationSubTitle.replace(/[^a-zA-Z ]/g, "")}
                          value={form.notificationSubTitle}
                          onChange={(e) => {
                            this.handleInputChange(e, "notificationSubTitle");
                          }}
                          onBlur={(e) => {
                            this.onValidate(e, "notificationSubTitle");
                          }}
                          placeholder="Sub-Title"
                          className={`form-input ${
                            errors.notificationSubTitle ? "error-input-border" : ""
                          } `}
                          disabled={false}
                        />
                        {errors.notificationSubTitle && (
                          <span className="errorMsg">
                            {errors.notificationSubTitle == true
                              ? "Enter Sub- Title"
                              : errors.notificationSubTitle}
                          </span>
                        )}
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label>
                        <i className="text-danger">*</i> Description
                      </label>
                      <div class="inner-addon right-addon">
                        <input
                          type="text"
                          // pattern="\d*"
                          //   maxLength="9"
                          name="Description"
                          // defaultValue={this.props.editData.data[0].notificationDesc.replace(/[^a-zA-Z ]/g, "")}
                          value={form.notificationDesc}
                          onChange={(e) => {
                            this.handleInputChange(e, "notificationDesc");
                          }}
                          onBlur={(e) => {
                            this.onValidate(e, "notificationDesc");
                          }}
                          placeholder="Description"
                          className={`form-input ${
                            errors.notificationDesc ? "error-input-border" : ""
                          } `}
                          disabled={false}
                        />
                        {errors.notificationDesc && (
                          <span className="errorMsg">
                            {errors.notificationDesc == true
                              ? "Enter Banner Title"
                              : errors.notificationDesc}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                   */}

                  {/* Role */}
                  {/* <div className="col-md-12 mt-3">
                    <label>
                      <i className="text-danger">*</i> Category
                    </label>
                    <Select
                      defaultValue={
                        roleDropdownValue.filter(
                          ({ roleCode }) => roleCode == form.role
                        )[0]
                      }
                      onChange={(e) => {
                        this.handleroleSelectChange(e, "role");
                      }}
                      onBlur={(e) => this.onValidate(e, "role")}
                      name="role"
                      options={roleDropdownValue}
                      placeholder="Select role"
                      valueKey="roleCode"
                      labelKey="roleName"
                      getOptionLabel={(option) => option.roleName}
                      getOptionValue={(option) => option.roleCode}
                      className={`${errors.role ? "error-input-border" : ""} `}
                      isDisabled={false}
                    />
                    {errors.role && (
                      <span className="errorMsg">Please Select Role</span>
                    )}
                  </div> */}

                  {/* status */}
                  {/* {this.props.action == "edit" && (
                    <div className="col-md-12 mt-3">
                      <label>
                        <i className="text-danger">*</i> Status
                      </label>
                      <Select
                        defaultValue={
                          statusType.filter(
                            ({ statusValue }) => statusValue == form.status
                          )[0]
                        }
                        onChange={(e) => {
                          this.handleSelectChanges(e, "status");
                        }}
                        onBlur={(e) => this.onValidate(e, "status")}
                        name="status"
                        options={statusType}
                        placeholder="Select Status"
                        valueKey="statusLabel"
                        labelKey="statusValue"
                        getOptionLabel={(option) => option.statusValue}
                        getOptionValue={(option) => option.statusLabel}
                        className={`${
                          errors.status ? "error-input-border" : ""
                        } `}
                        isDisabled={false}
                      />
                      {errors.status && (
                        <span className="errorMsg">Please Select Status</span>
                      )}
                    </div>
                  )} */}
                  {/* Zone */}
                  {/* <div className="col-md-12 mt-3">
                    <label>
                      <i className="text-danger">*</i> Zone
                    </label>
                    <Select
                      defaultValue={zoneDropdownValue.filter(
                        ({ zonecode }) => zonecode == form.zone
                      )}
                      //   value={form.zone}
                      onChange={(e, value) => {
                        this.handlezoneSelectChange(e, "zone", value);
                      }}
                      onBlur={(e) => this.onValidate(e, "zone")}
                      name="zone"
                      options={zoneDropdownValue}
                      placeholder="Select zone"
                      valueKey="zonecode"
                      labelKey="zonename"
                      getOptionLabel={(option) => option.zonename}
                      getOptionValue={(option) => option.zonecode}
                      className={`${errors.zone ? "error-input-border" : ""} `}
                      isDisabled={false}
                      isMulti={true}
                    />
                    {errors.zone && (
                      <span className="errorMsg">Please Select Zone</span>
                    )}
                  </div> */}
                  {/* {zoneDropdownValue && zoneDropdownValue.length > 0 && (
                    <div className="col-md-12 mt-3">
                      <label>
                        <i className="text-danger">*</i> Zone
                      </label> */}
                  {/* <Select
                      value={zoneobj}
                      onChange={this.handleZone}
                      // onBlur={(e) => this.onValidate(e, "zone")}
                      name="zoneobj"
                      options={zoneDropdownValue}
                      placeholder="Select zone"
                      valueKey="zonecode"
                      labelKey="zonename"
                      getOptionLabel={(option) => option.zonename}
                      getOptionValue={(option) => option.zonecode}
                      className={`${errors.zone ? "error-input-border" : ""} `}
                      isDisabled={false}
                      isMulti={true}
                    /> */}
                  {/* {errors.zone && (
                      <span className="errorMsg">Please Select Zone</span>
                    )} */}
                  {/* <Select
                        defaultValue={
                          zoneDropdownValue.filter(
                            ({ zonecode }) => zonecode == form.zone
                          )[0]
                        }
                        onChange={(e) => {
                          this.handleZoneSelectChange(e, "zone");
                        }}
                        onBlur={(e) => this.onValidate(e, "zone")}
                        name="zone"
                        options={zoneDropdownValue}
                        placeholder="Select Zone"
                        valueKey="zonecode"
                        labelKey="zonecode"
                        getOptionLabel={(option) => option.zonecode}
                        getOptionValue={(option) => option.zonecode}
                        className={`${
                          errors && errors.zone ? "error-input-border" : ""
                        } `}
                        isDisabled={false}
                        key={key}
                      />
                      {errors && errors.zone && (
                        <span className="errorMsg">Please Select Zone</span>
                      )}
                    </div>
                  )} */}

                  {/* new Zone */}
                  {/* Region */}

                  {/* <div className="col-md-12 mt-3">
                    <label>
                      <i className="text-danger">*</i> Region
                    </label>
                    <Select
                      defaultValue={regionDropdownValue.filter(
                        ({ regioncode }) => regioncode == form.region
                      )}
                      onChange={(e, value) => {
                        this.handleregionSelectChange(e, "region", value);
                      }}
                      onBlur={(e) => this.onValidate(e, "region")}
                      name="region"
                      options={regionDropdownValue}
                      placeholder="Select region"
                      valueKey="regioncode"
                      labelKey="regionname"
                      getOptionLabel={(option) => option.regionname}
                      getOptionValue={(option) => option.regioncode}
                      className={`${
                        errors.region ? "error-input-border" : ""
                      } `}
                      isDisabled={false}
                      isMulti={true}
                    />
                    {errors.region && (
                      <span className="errorMsg">Please Select Region</span>
                    )}
                  </div> */}

                  {/* new Region */}
                  {/* {regionDropdownValue && regionDropdownValue.length > 0 && (
                    <div className="col-md-12 mt-3">
                      <label>
                        <i className="text-danger">*</i> Region
                      </label> */}
                  {/* <Select
                      value={regionobj}
                      onChange={this.handleRegion}
                      // onBlur={(e) => this.onValidate(e, "region")}
                      name="regionobj"
                      options={regionDropdownValue}
                      placeholder="Select region"
                      valueKey="regioncode"
                      labelKey="regionname"
                      getOptionLabel={(option) => option.regionname}
                      getOptionValue={(option) => option.regioncode}
                      className={`${
                        errors.region ? "error-input-border" : ""
                      } `}
                      isDisabled={false}
                      isMulti={true}
                    /> */}
                  {/* {errors.region && (
                      <span className="errorMsg">Please Select Region</span>
                    )} */}
                  {/* <Select
                        defaultValue={
                          (console.log(
                            "regionDropdownValue",
                            regionDropdownValue &&
                              regionDropdownValue.filter(
                                ({ regioncode }) => regioncode == form.region
                              )[0]
                          ),
                          regionDropdownValue &&
                            regionDropdownValue.filter(
                              ({ regioncode }) => regioncode == form.region
                            )[0])
                        }
                        onChange={(e) => {
                          this.handleRegionSelectChange(e, "region");
                        }}
                        onBlur={(e) => this.onValidate(e, "region")}
                        name="region"
                        options={regionDropdownValue}
                        placeholder="Select Region"
                        valueKey="regioncode"
                        labelKey="regioncode"
                        getOptionLabel={(option) => option.regioncode}
                        getOptionValue={(option) => option.regioncode}
                        className={`${
                          errors && errors.region ? "error-input-border" : ""
                        } `}
                        isDisabled={false}
                        key={key}
                      />
                      {errors && errors.region && (
                        <span className="errorMsg">Please Select Region</span>
                      )}
                    </div>
                  )} */}

                  {/* Branch */}
                  {/* <div className="col-md-12 mt-3">
                    <label>
                      <i className="text-danger">*</i> Branch
                    </label>
                    <Select
                      defaultValue={branchDropdownValue.map(
                        ({ branchcode }) => branchcode == form.branch
                      )}
                      onChange={(e, value) => {
                        this.handlebranchSelectChange(e, "branch", value);
                      }}
                      onBlur={(e) => this.onValidate(e, "branch")}
                      name="branch"
                      options={branchDropdownValue}
                      placeholder="Select branch"
                      valueKey="branchcode"
                      labelKey="branchcode"
                      getOptionLabel={(option) => option.branchcode}
                      getOptionValue={(option) => option.branchcode}
                      className={`${
                        errors.branch ? "error-input-border" : ""
                      } `}
                      isDisabled={false}
                      isMulti
                    />
                    {errors.branch && (
                      <span className="errorMsg">Please Select Branch</span>
                    )}
                  </div> */}

                  {/* new branch */}
                  {/* {branchDropdownValue && branchDropdownValue.length > 0 && (
                    <div className="col-md-12 mt-3">
                      <label>
                        <i className="text-danger">*</i> Branch
                      </label> */}
                  {/* <Select
                      // defaultValue={branchDropdownValue.map(
                      //   ({ branchcode }) => branchcode == form.branch
                      // )}
                      value={branchobj}
                      onChange={this.handlebranch}
                      // onBlur={(e) => this.onValidate(e, "branch")}
                      name="branchobj"
                      options={branchDropdownValue}
                      placeholder="Select branch"
                      valueKey="branchcode"
                      labelKey="branchname"
                      getOptionLabel={(option) => option["branchname"]}
                      getOptionValue={(option) => option["branchcode"]}
                      styles={dropstyle}
                      // className={`${
                      //   errors.branch ? "error-input-border" : ""
                      // } `}
                      isDisabled={false}
                      isMulti
                    /> */}
                  {/* {errors.branch && (
                      <span className="errorMsg">Please Select Branch</span>
                    )} */}
                  {/* <Select
                        defaultValue={
                          branchDropdownValue.filter(
                            ({ branchcode }) => branchcode == form.branch
                          )[0]
                        }
                        onChange={(e) => {
                          this.handleBranchSelectChange(e, "branch");
                        }}
                        onBlur={(e) => this.onValidate(e, "branch")}
                        name="branch"
                        options={branchDropdownValue}
                        placeholder="Select Branch"
                        valueKey="branchcode"
                        labelKey="branchcode"
                        getOptionLabel={(option) => option.branchcode}
                        getOptionValue={(option) => option.branchcode}
                        className={`${
                          errors && errors.branch ? "error-input-border" : ""
                        } `}
                        isDisabled={false}
                        key={key}
                      />
                      {errors && errors.branch && (
                        <span className="errorMsg">Please Select Branch</span>
                      )}
                    </div>
                  )} */}
                  {/* <div className="col-md-12 mt-3">
                    {loaderForSpoke ? (
                      <React.Fragment>
                        <i class="fa fa-spinner fa-spin ml-3"></i>
                        <span className="ml-2">Please wait...</span>
                      </React.Fragment>
                    ) : (
                      <>
                        <label>
                          <i className="text-danger">*</i> Spoke
                        </label>
                        <Select
                          value={villageobj}
                          onChange={this.handleVillage}
                          // onBlur={(e) => this.onValidate(e, "region")}
                          name="regionobj"
                          options={villageDropdownValue}
                          placeholder="Select Village / Spoke"
                          valueKey="id"
                          labelKey="info"
                          getOptionLabel={(option) => option.info}
                          getOptionValue={(option) => option.id}
                          className={`${
                            errors.village ? "error-input-border" : ""
                          } `}
                          isDisabled={false}
                          isMulti={true}
                          key={key}
                        />
                      </>
                    )}
                    </div> */}
                  {/* {errors.village && (
                      <span className="errorMsg">
                        Please Select Village / Spoke
                      </span>
                    )} */}
                </div>
              </>
            )}
            <Modal.Footer>
              <Button className="btn-danger" onClick={this.handleModalClose}>
                Cancel
              </Button>
              <Button
                className="btn-success"
                disabled={
                  this.props.action == "edit"
                    ? !form.notificationTitle
                    : !form.category ||
                      !form.bannerTitle ||
                      !form.endDate ||
                      !form.startDate ||
                      this.state.disableImageFile == "" ||
                      diableSave
                  // userBranch == undefined ||
                  // userZone == undefined ||
                  // userRegion == undefined ||
                  // (userBranch && userBranch.length == 0) ||
                  // (userZone && userZone.length == 0) ||
                  // (userVillage && userVillage.length == 0)
                }
                onClick={
                  this.props.action == "edit"
                    ? this.sendRequest
                    : this.handleSubmit
                }
              >
                {this.props.action == "edit" ? "Send" : "Create"}
              </Button>
            </Modal.Footer>
          </Modal.Body>
          {/* )} */}
        </Modal>
        <CloseModal
          show={showInner}
          button2={this.handleModalClose}
          button1={this.innerModalClose}
          title="Do you want to close ?"
        />
      </>
    );
  }
}

export default Addedituser;
