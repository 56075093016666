import { get, post } from "../httpInterceptor";

// API for delete franking fee

export const deleteFrankingFeeById = (obj) => {
	return post(`/sarvagram-credit/master/deleteFrankingfeesbyid`, obj, false).then((res) => {
		console.log("deleteFrankingFeeById", res);
		return res;
	});
};

// API for get franking fee

export const getAllFrankingFee = () => {
	return get(`/sarvagram-credit/master/findallFrankingfees`).then((res) => {
		console.log("RESPONSE :: getAllFrankingFee ::: ", res);
		return res;
	});
};

// API for save franking fee

export const addFrankingFee = (obj) => {
	return post(`/sarvagram-credit/master/saveorupdateFrankingfees`, obj, false).then((res) => {
		console.log("addFrankingFee", res);
		return res;
	});
};

// API for update franking fee

export const updateFrankingFeeById = (obj) => {
	return post(`/sarvagram-credit/master/saveorupdateFrankingfees`, obj, false).then((res) => {
		console.log("updateFrankingFeeById", res);
		return res;
	});
};
