import { get, post } from "../httpInterceptor";

// API for find all cancellation permaster

export const findallcancellationPermaster = () => {
  return get(`/cancellation/findAllCancellationPercentageFarm`).then((res) => {
    console.log("RESPONSE :: findallcancellationPermaster ::: ", res);
    return res;
  });
};

// API for save or update cancellation permaster

export const saveorupdatecancellationPermaster = (obj) => {
  return post(`/cancellation/saveOrUpdatecancellationper`, obj, false).then(
    (res) => {
      console.log("saveorupdatecancellationPermaster", res);
      return res;
    }
  );
};
