import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import AddEditCancel from "./AddEditCancel";
import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import {
  findallcancellationfeesmaster,
  saveorupdatecancellationmaster,
  deletecancellationfeesbyid,
  findallproductdropdown,
} from "../../../Utils/Services/CancelMaster";
toast.configure();

const CancelMaster = (props) => {
  let [cancelData, setcancelData] = useState([]);
  let [productDropdownOptions, setproductDropdownOptions] = useState([]);
  let [showAddProductNormModal, setshowAddProductNormModal] = useState(false);
  let [showEditNormModal, setshowEditNormModal] = useState(false);
  let [editData, setEditData] = useState({});

  useEffect(() => {
    getProductValue();
    cancelMaster();
  }, []);

  const cancelMaster = () => {
    findallcancellationfeesmaster().then((response) => {
      if (response.error) {
        return;
      }
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        setcancelData(response.data.data);
      }
    });
  };

  const getProductValue = () => {
    findallproductdropdown().then((result) => {
      console.log("res", result);
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        toast.error(result.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        if (result && result.data && result.data.data) {
          setproductDropdownOptions(result.data.data);
        }
      }
    });
  };

  const deleteCancelById = (id) => {
    let postData = {
      id: id,
    };
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes, Delete It!",
          onClick: () => {
            deletecancellationfeesbyid(postData).then((response) => {
              console.log("deleteProductNormById", response);
              if (response.error) {
                return;
              }
              if (response.data && response.data.error) {
                toast.error(response.data.message, {
                  type: toast.TYPE.ERROR,
                  autoClose: 2000,
                });
              } else {
                toast.success(response.data.message, {
                  type: toast.TYPE.SUCCESS,
                  autoClose: 2000,
                });
                cancelMaster();
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            // toast.error("Role Can Not be Deleted", {
            //   type: toast.TYPE.ERROR,
            //   autoClose: 2000
            // });
            // this.RoleList();
          },
        },
      ],
    });
  };

  const columns = [
    {
      Header: "SR. No",
      width: 70,
      Cell: (data) => {
        return data.index + 1;
      },
    },

    {
      Header: "Remarks",
      Cell: ({ original }) => {
        return original && original.remark ? original.remark : null;
      },
    },
    {
      Header: "Product",
      Cell: ({ original }) => {
        return original && original.product ? original.product : null;
      },
    },
    {
      Header: "Status",
      Cell: ({ original }) => {
        return original && original.status ? original.status : null;
      },
    },
    {
      Header: "Fees (In Rupees)",
      width: 200,
      Cell: ({ original }) => {
        return original && original.fees ? original.fees : "0";
      },
    },

    {
      Header: "Action",
      width: 125,
      disabled: false,
      accessor: "delete",
      accessor: "edit",

      Cell: ({ original }) => {
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              onClick={() => deleteCancelById(original.id)}
              className="fa fa-trash"
              style={{ cursor: "pointer" }}
            />
            <i
              disabled={false}
              onClick={() => handleEditCancelMaster(original)}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const handleAddCancel = (id) => {
    setshowAddProductNormModal(!showAddProductNormModal);
  };

  const handleEditCancelMaster = (obj) => {
    setshowEditNormModal(!showEditNormModal);
    setEditData(obj);
  };

  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box">
            {" "}
            Cancellation Master
          </h5>
          <div className="d-flex align-items-center mt-5">
            <button
              disabled={false}
              onClick={handleAddCancel}
              className="add-btn ml-auto btn btn-info btn-lg"
            >
              {" "}
              Add{" "}
            </button>
            {showAddProductNormModal && (
              <AddEditCancel
                action={"add"}
                cancelMaster={cancelMaster}
                handleAddCancel={handleAddCancel}
                productDropdownOptions={productDropdownOptions}
              />
            )}
            {showEditNormModal && (
              <AddEditCancel
                action={"edit"}
                cancelMaster={cancelMaster}
                editData={editData}
                handleEditCancelMaster={handleEditCancelMaster}
                productDropdownOptions={productDropdownOptions}
              />
            )}
          </div>

          <div className={`mt-4 react-table`}>
            <ReactTable
              data={cancelData}
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default CancelMaster;
