import React, { useState, useEffect, Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from "lodash";
import ImageUploader from "react-images-upload";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CloseModal from "../../../Components/CloseModal";
import {
  formatIndianCurrency,
  replaceComma,
  checkIfNotDecimal,
} from "../../../Utils/helperFunctions";
import {
  getAllEvents,
  findAllNotificationTypeDropdown,
  saveorupdateDetails,
  notificationImageAndVideoUpload,
} from "../../../Utils/Services/WebNotification";
import { FileUrl } from "../../../Utils/config";

toast.configure();

const initForm = {
  notificationType: "",
  frequency: "",
  type: "",
  triggerNotification: "",
  errors: {
    notificationType: null,
    frequency: null,
    type: null,
    triggerNotification: null,
  },
};

const WeekValues = [
  {
    Weeklabel: "Monday",
    weekdayName: "Monday",
  },
  {
    Weeklabel: "Tuesday",
    weekdayName: "Tuesday",
  },
  {
    Weeklabel: "Wednesday",
    weekdayName: "Wednesday",
  },
  {
    Weeklabel: "Thursday",
    weekdayName: "Thursday",
  },
  {
    Weeklabel: "Friday",
    weekdayName: "Friday",
  },
  {
    Weeklabel: "Saturday",
    weekdayName: "Saturday",
  },
  {
    Weeklabel: "Sunday",
    weekdayName: "Sunday",
  },
];

const Frequency = [
  {
    FrequencyLebel: "Daily",
    FrequencyValue: "Daily",
  },
  {
    FrequencyLebel: "Weekly",
    FrequencyValue: "Weekly",
  },
  { FrequencyLebel: "Monthly", FrequencyValue: "Monthly" },
  {
    FrequencyLebel: "Custom",
    FrequencyValue: "Custom",
  },
];

const TriggerNotification = [
  {
    TriggerNotificationLebel: "ON",
    TriggerNotificationValue: "ON",
  },
  {
    TriggerNotificationLebel: "OFF",
    TriggerNotificationValue: "OFF",
  },
];

const TypeSpecific = [
  {
    TypeSpecificLebel: "Text",
    TypeSpecificValue: "Text",
  },
  {
    TypeSpecificLebel: "Image",
    TypeSpecificValue: "Image",
  },
  {
    TypeSpecificLebel: "URL",
    TypeSpecificValue: "URL",
  },
  {
    TypeSpecificLebel: "video",
    TypeSpecificValue: "video",
  },
];

export class WebNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      showInner: false,
      userEvent: [],
      EventDropdownValue: [],
      userWeekDay: [],
      NotificationType: [],
      startDate: "",
      endDate: "",
      textData: "",
      selectMonthDate: "",
      title: "",
      form: cloneDeep(initForm),
      imageOne: [],
      err: {
        imageOne: null,
      },
      errForDate: {
        startDate: null,
        endDate: null,
      },
      errForTextUrl: {
        textData: null,
      },
      errMonthDate: {
        selectMonthDate: null,
      },
      titleErr: {
        title: null,
      },
    };
  }

  componentDidMount = () => {
    let { form } = this.state;
    this.setState({ showModal: true });
    console.log("props.editData", this.props.editData);
    if (this.props.editData && this.props.action == "edit") {
      let { form } = this.state;
      let obj = { ...form, ...this.props.editData };
      console.log("ooo", obj);

      this.setState(
        {
          form: obj,
          userEvent: obj.events,
          userWeekDay: obj.weekdays,
          textData: obj.typeOfNotificationContent,
          selectMonthDate: obj.selectMonthDate,
          startDate: obj.startDate,
          endDate: obj.endDate,
          title: obj.title,
        },
        () => {
          this.getEvents();
          this.getNotificationType();
        }
      );
    }
    this.getEvents();
    this.getNotificationType();
    //   this.setState(
    //     {
    //       form: obj,
    //       userVillage: newdata,
    //       key: Math.random(),
    //     },
    //     () => {
    //       this.getEvents();
    //       this.getregions();
    //       this.getbranches();
    //       this.getvillage();
    //     }
    //   );
    // }
  };

  handleModalClose = () => {
    this.setState({ showInner: !this.state.showInner });
    // this.this.props.addRole();
  };

  innerModalClose = (modclose) => {
    if (!modclose) {
      this.handleModalClose();
    }
    this.setState({ showModal: false });
    if (this.props.action == "edit") {
      this.props.handleEditWebNotificationMaster();
    } else {
      this.props.handleAddWebNotification();
    }
  };

  handleSubmit = () => {
    let {
      userEvent,
      userWeekDay,
      form,
      startDate,
      endDate,
      selectMonthDate,
      textData,
      imageOne,
      title,
    } = this.state;
    let { editData } = this.props;

    let obj = {
      id: editData && editData.id,
      notificationType: form && form.notificationType,
      frequency: form && form.frequency,
      type: form && form.type,
      triggerNotification: form && form.triggerNotification,
      typeOfNotificationContent: textData,
      startDate: startDate,
      endDate: endDate,
      selectMonthDate: selectMonthDate,
      title: title,
    };
    let event = [];
    userEvent &&
      userEvent.map((val) => {
        event.push({
          eventName: val.eventName,
        });
      });

    let week = [];
    userWeekDay &&
      userWeekDay.map((val) => {
        week.push({
          weekdayName: val.weekdayName,
        });
      });
    obj.events = event;
    obj.weekdays = week;
    this.setState({ isLoading: true });
    saveorupdateDetails(obj).then((response) => {
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        // this.props.WebNotificationMaster();
        // if (this.props.editData && this.props.action == "edit") {
        //   this.props.handleEditWebNotificationMaster();
        // } else {
        //   this.props.handleAddWebNotification();
        // }
        // toast.success(response && response.data && response.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 2000,
        // });
        let id = response && response.data && response.data.data.id;
        if (
          (response && response.data && response.data.data.type === "Image") ||
          (response && response.data && response.data.data.type === "video")
        ) {
          if (imageOne && imageOne[0] && imageOne[0].name !== "") {
            this.handleUpload(id);
            this.setState({
              showModal: true,
              isLoading: true,
            });
          } else {
            this.setState({
              showModal: false,
              isLoading: false,
            });
          }
        } else {
          this.setState({
            showModal: false,
            isLoading: false,
          });
          this.props.WebNotificationMaster();
          if (this.props.editData && this.props.action == "edit") {
            this.props.handleEditWebNotificationMaster();
          } else {
            this.props.handleAddWebNotification();
          }
          toast.success(response && response.data && response.data.message, {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        }
      }
    });
  };

  handleProductSelectChanges = (e, name) => {
    console.log("handleProductSelectChanges", e, name);
    let { form } = this.state;
    let { productName } = e;
    form[name] = productName;
    this.setState({ form });
  };

  handleInputChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("handleInputChange", e, name, e.target.value);
    form[name] = e.target.value;
    this.setState({ form });
  };

  handleTextChange = (e, name) => {
    e.preventDefault();
    let { textData } = this.state;
    console.log("handleTextChange", e, name, e.target.value);
    textData = e.target.value;
    this.setState({ textData });
  };

  handleTitleChange = (e, name) => {
    e.preventDefault();
    let { title } = this.state;
    console.log("handleTitleChange", e, name, e.target.value);
    title = e.target.value;
    this.setState({ title });
  };

  handleEventelectChanges = (e, name) => {
    console.log("handleEventelectChanges", e, name);
    let { form } = this.state;
    let { EventValue } = e;
    form[name] = EventValue;
    this.setState({ form });
  };

  handleNotificationTypeSelectChanges = (e, name) => {
    console.log("handleNotificationTypeSelectChanges", e, name);
    let { form } = this.state;
    let { notificationTypeName } = e;
    form[name] = notificationTypeName;
    this.setState({ form });
  };

  handleFrequencySelectChanges = (e, name) => {
    console.log("handleNotificationTypeSelectChanges", e, name);
    let { form } = this.state;
    let { FrequencyValue } = e;
    form[name] = FrequencyValue;
    this.setState({ form });
    this.clearDate();
  };

  clearDate = () => {
    let { startDate, endDate, selectMonthDate, userWeekDay } = this.state;
    startDate = "";
    endDate = "";
    selectMonthDate = "";
    userWeekDay = null;
    this.setState({ startDate, endDate, selectMonthDate, userWeekDay });
  };

  clearTextUrl = () => {
    let { textData, imageOne, form } = this.state;
    textData = "";
    imageOne = null;
    this.setState({ textData, imageOne });
  };

  handleTypeSpecificSelectChanges = (e, name) => {
    console.log("handleTypeSpecificSelectChanges", e, name);
    let { form } = this.state;
    let { TypeSpecificValue } = e;
    form[name] = TypeSpecificValue;
    this.setState({ form });
    this.clearTextUrl();
  };

  handleTriggerNotificationSelectChanges = (e, name) => {
    console.log("handleTriggerNotificationSelectChanges", e, name);
    let { form } = this.state;
    let { TriggerNotificationValue } = e;
    form[name] = TriggerNotificationValue;
    this.setState({ form });
  };

  handleStartDate = (date, e) => {
    console.log("handleStartDate", date, e);
    let { startDate } = this.state;
    startDate = date;
    this.setState({ startDate });
  };

  handleEndDate = (date, e) => {
    console.log("handleEndDate", date, e);
    let { endDate } = this.state;
    endDate = date;
    this.setState({ endDate });
  };

  handleMonthDate = (date, e) => {
    console.log("handleMonthDate", date, e);
    let { selectMonthDate } = this.state;
    selectMonthDate = date;
    this.setState({ selectMonthDate });
  };

  onDrop = async (file, picture, name) => {
    let { err, imageOne, textData } = this.state;
    console.log("file", file);
    console.log("picture", picture.length);
    console.log("name", name);
    if (picture.length == 1) {
      err = { ...err, [name]: false };
    } else {
      err = { ...err, [name]: true };
    }

    if (this.props.editData && this.props.action == "edit") {
      if (imageOne != []) {
        textData = "";
        this.setState({ textData });
      }
    }

    this.setState({ [name]: file, err: err });
  };

  onValidate = (e, name) => {
    e.preventDefault();
    let { form, startDate, endDate, errForDate } = this.state;
    if (form[name] === "" || form[name] === null || form[name] === undefined) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      form.errors[name] = null;
      this.setState({ form });
    }
  };

  onStartDateValidate = (e, name) => {
    e.preventDefault();
    let { errForDate, startDate } = this.state;
    if (startDate === "" || startDate === null || startDate === undefined) {
      errForDate[name] = true;
      this.setState({ errForDate });
    } else {
      errForDate[name] = null;
      this.setState({ errForDate });
    }
  };

  onEndDateValidate = (e, name) => {
    e.preventDefault();
    let { errForDate, endDate } = this.state;
    if (endDate === "" || endDate === null || endDate === undefined) {
      errForDate[name] = true;
      this.setState({ errForDate });
    } else {
      errForDate[name] = null;
      this.setState({ errForDate });
    }
  };

  onTitletValidate = (e, name) => {
    e.preventDefault();
    let { titleErr, title } = this.state;
    if (title === "" || title === null || title === undefined) {
      titleErr[name] = true;
      this.setState({ titleErr });
    } else {
      titleErr[name] = null;
      this.setState({ titleErr });
    }
  };

  onTextValidate = (e, name) => {
    e.preventDefault();
    let { errForTextUrl, textData } = this.state;
    if (textData === "" || textData === null || textData === undefined) {
      errForTextUrl[name] = true;
      this.setState({ errForTextUrl });
    } else {
      errForTextUrl[name] = null;
      this.setState({ errForTextUrl });
    }
  };

  onMonthSelectValidate = (e, name) => {
    e.preventDefault();
    let { errMonthDate, selectMonthDate } = this.state;
    if (
      selectMonthDate === "" ||
      selectMonthDate === null ||
      selectMonthDate === undefined
    ) {
      errMonthDate[name] = true;
      this.setState({ errMonthDate });
    } else {
      errMonthDate[name] = null;
      this.setState({ errMonthDate });
    }
  };

  getEvents = () => {
    getAllEvents().then((result) => {
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        toast.error(result.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        if (result && result.data && result.data.data) {
          this.setState({
            EventDropdownValue: result && result.data && result.data.data,
          });
        }
      }
    });
  };

  getNotificationType = () => {
    findAllNotificationTypeDropdown().then((result) => {
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        toast.error(result.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        if (result && result.data && result.data.data) {
          console.log("not", result);
          this.setState({
            NotificationType: result && result.data && result.data.data,
          });
        }
      }
    });
  };

  handleEvent = (Event) => {
    let Eventarray = [];
    Event && Eventarray.push(Event);

    this.setState({ userEvent: Eventarray[0] });
  };

  handleWeek = (Week) => {
    let Weekarray = [];
    Week && Weekarray.push(Week);

    this.setState({ userWeekDay: Weekarray[0] });
  };

  handleUpload = (id) => {
    let { imageOne } = this.state;
    console.log("a", imageOne, id);
    let Upload = imageOne && imageOne[0] && imageOne[0].name;
    let content = imageOne && imageOne[0];
    console.log("c", content);
    console.log(Upload);
    let userDoc = [];
    userDoc.push({
      id: id,
    });

    let blob = new Blob([content], { type: ".xlsx" });
    const formData = new FormData();
    formData.append("file", blob, Upload);
    formData.append("notificationDocs", JSON.stringify(userDoc));
    this.setState({ isLoading: true });
    notificationImageAndVideoUpload(formData).then((response) => {
      if (response.error) {
        this.setState({
          showModal: false,
          isLoading: false,
        });
        return;
      }
      if (response.data.error) {
        toast.error(response && response.data && response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({
          showModal: false,
          isLoading: false,
        });
        return;
      } else {
        this.setState({ isLoading: false }, () => {
          this.props.WebNotificationMaster();
          this.setState({
            showModal: false,
          });
          toast.success(response && response.data && response.data.message, {
            type: toast.TYPE.SUCCESS,
            autoClose: 4000,
          });
          if (this.props.editData && this.props.action == "edit") {
            this.props.handleEditWebNotificationMaster();
          } else {
            this.props.handleAddWebNotification();
          }
        });
      }
    });
  };

  render() {
    let {
      form,
      showModal,
      showInner,
      isLoading,
      errForDate,
      errMonthDate,
      selectMonthDate,
      startDate,
      endDate,
      textData,
      title,
      titleErr,
      errForTextUrl,
      EventDropdownValue,
      userEvent,
      userWeekDay,
      NotificationType,
      imageOne,
    } = this.state;
    let { errors } = form;
    let diableSave = false;
    let isRequiredFeildFilled = false;
    let dateReq = false;
    let textUrlReq = false;
    let MonthDateReq = false;
    let WeeklyReq = false;
    let mediaReq = false;
    let titleReq = false;
    console.log("re", Event);

    Object.keys(form).map((name) => {
      console.log("form", form[name]);
      if (
        form[name] === "" ||
        form[name] === null ||
        form[name] === undefined
      ) {
        isRequiredFeildFilled = true;
        return true;
      }
    });

    if (form && form.type === "Text") {
      if (form && form.title === "") {
        isRequiredFeildFilled = false;
      }
    }

    let Eventobj = [];
    EventDropdownValue.map((res) => {
      userEvent &&
        userEvent.map((val) => {
          if (res.eventName == val.eventName) {
            Eventobj.push(res);
          }
        });
    });

    let Weekobj = [];
    WeekValues.map((res) => {
      userWeekDay &&
        userWeekDay.map((val) => {
          if (res.weekdayName == val.weekdayName) {
            Weekobj.push(res);
          }
        });
    });

    if (form && form.frequency === "Custom") {
      if (
        startDate === "" ||
        startDate === null ||
        startDate === undefined ||
        endDate === "" ||
        endDate === null ||
        endDate === undefined
      ) {
        dateReq = true;
      }
    }

    if (form && form.frequency === "Monthly") {
      if (
        selectMonthDate === "" ||
        selectMonthDate === null ||
        selectMonthDate === undefined
      ) {
        MonthDateReq = true;
      }
    }

    if (form && form.frequency === "Weekly") {
      if ((userWeekDay && userWeekDay.length === 0) || userWeekDay === null) {
        WeeklyReq = true;
      }
    }

    if ((form && form.type === "Image") || (form && form.type === "video")) {
      if (
        ((imageOne && imageOne.length === 0) || imageOne === null) &&
        textData === ""
      ) {
        mediaReq = true;
      }
    }

    if ((form && form.type === "Text") || (form && form.type === "URL")) {
      if (textData === "" || textData === null || textData === undefined) {
        textUrlReq = true;
      }
    }

    if (
      (form && form.type === "Image") ||
      (form && form.type === "URL") ||
      (form && form.type === "video")
    ) {
      if (title === "" || title === null || title === undefined) {
        titleReq = true;
      }
    }

    Object.keys(errors).map((error) => {
      console.log("error", errors[error]);
      if (errors[error] === true || errors[error] != null) {
        diableSave = true;
        return true;
      }
    });
    console.log("error", diableSave);
    console.log("form", form);
    console.log("ir", isRequiredFeildFilled, dateReq);
    console.log(
      "reqq",
      diableSave,
      isRequiredFeildFilled,
      isLoading,
      dateReq,
      textUrlReq,
      MonthDateReq,
      WeeklyReq,
      mediaReq,
      titleReq,
      userEvent && userEvent.length == 0
    );
    return (
      <React.Fragment>
        <Modal
          className="product-norm-container"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showModal}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>
              {this.props.action == "edit"
                ? "Edit Notification"
                : "Add Notification"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            {isLoading ? (
              <h4 className="text-center">LOADING...</h4>
            ) : (
              <div className="row ">
                {EventDropdownValue && EventDropdownValue.length > 0 && (
                  <div className="mb-4 cogl-md-4 col-lg-4 col-sm-12">
                    <label>
                      <i className="text-danger">*</i> Event
                    </label>
                    <Select
                      value={Eventobj}
                      onChange={this.handleEvent}
                      // onBlur={(e) => this.onValidate(e, "event")}
                      name="event"
                      options={EventDropdownValue}
                      placeholder="Select Event"
                      valueKey="eventName"
                      labelKey="eventName"
                      getOptionLabel={(option) => option.eventName}
                      getOptionValue={(option) => option.eventName}
                      className={`${errors.event ? "error-input-border" : ""} `}
                      isDisabled={false}
                      isMulti={true}
                    />
                    {/* {errors.event && (
                      <span className="errorMsg">Please Select Event</span>
                    )} */}
                  </div>
                )}

                {NotificationType && NotificationType.length > 0 && (
                  <div className="mb-4 col-md-4 ">
                    <label>
                      <i className="text-danger">*</i> Notification Type
                    </label>
                    <Select
                      defaultValue={
                        NotificationType.filter(
                          ({ notificationTypeName }) =>
                            notificationTypeName == form.notificationType
                        )[0]
                      }
                      onChange={(e) => {
                        this.handleNotificationTypeSelectChanges(
                          e,
                          "notificationType"
                        );
                      }}
                      onBlur={(e) => this.onValidate(e, "notificationType")}
                      name="notificationType"
                      options={NotificationType}
                      placeholder="Select Notification Type"
                      // value={form.NotificationType}
                      valueKey="notificationTypeName"
                      labelKey="notificationTypeName"
                      getOptionLabel={(option) => option.notificationTypeName}
                      getOptionValue={(option) => option.notificationTypeName}
                      className={`${
                        errors.notificationType ? "error-input-border" : ""
                      } `}
                      isDisabled={false}
                    />

                    {errors.notificationType && (
                      <span className="errorMsg">
                        Please Select NotificationType
                      </span>
                    )}
                  </div>
                )}
                <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                  <label>
                    <i className="text-danger">*</i> Frequency
                  </label>
                  <Select
                    defaultValue={
                      Frequency.filter(
                        ({ FrequencyValue }) => FrequencyValue == form.frequency
                      )[0]
                    }
                    onChange={(e) => {
                      this.handleFrequencySelectChanges(e, "frequency");
                    }}
                    onBlur={(e) => this.onValidate(e, "frequency")}
                    name="frequency"
                    options={Frequency}
                    placeholder="Select Frequency"
                    valueKey="FrequencyValue"
                    labelKey="FrequencyLebel"
                    getOptionLabel={(option) => option.FrequencyLebel}
                    getOptionValue={(option) => option.FrequencyValue}
                    className={`${
                      errors.frequency ? "error-input-border" : ""
                    } `}
                    isDisabled={false}
                  />
                  {errors.frequency && (
                    <span className="errorMsg">Please Enter Frequency</span>
                  )}
                </div>
                {form && form.frequency === "Custom" ? (
                  <>
                    <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                      <label>
                        <i className="text-danger">*</i> Start Date
                      </label>
                      <div className="inner-addon right-addon">
                        <DatePicker
                          selected={
                            this.state.startDate
                              ? new Date(this.state.startDate)
                              : ""
                          }
                          onBlur={(e) =>
                            this.onStartDateValidate(e, "startDate")
                          }
                          onChange={this.handleStartDate}
                          dateFormat="dd-MM-yyyy"
                          placeholderText="DD-MM-YYYY"
                          showYearDropdown
                          showMonthDropdown
                          className={`form-input ${
                            errForDate.startDate ? "error-input-border" : ""
                          } `}
                          disabled={false}
                          maxDate={this.state.endDate}
                          // maxDate={new Date()}
                        />
                      </div>
                      {errForDate.startDate && (
                        <span className="errorMsg">
                          Please select start date
                        </span>
                      )}
                    </div>
                  </>
                ) : form && form.frequency === "Monthly" ? (
                  <div className="mb-4 col-md-4 ">
                    <label>
                      <i className="text-danger">*</i> Select Date
                    </label>
                    <div className="inner-addon right-addon">
                      <DatePicker
                        selected={
                          this.state.selectMonthDate
                            ? new Date(this.state.selectMonthDate)
                            : ""
                        }
                        onBlur={(e) =>
                          this.onMonthSelectValidate(e, "selectMonthDate")
                        }
                        onChange={this.handleMonthDate}
                        dateFormat="dd-MM-yyyy"
                        placeholderText="DD-MM-YYYY"
                        showYearDropdown
                        showMonthDropdown
                        className={`form-input ${
                          errMonthDate.selectMonthDate
                            ? "error-input-border"
                            : ""
                        } `}
                        disabled={false}
                        // maxDate={new Date()}
                      />
                    </div>
                    {errMonthDate.selectMonthDate && (
                      <span className="errorMsg">Please select a date</span>
                    )}
                  </div>
                ) : form && form.frequency === "Weekly" ? (
                  <>
                    {WeekValues && WeekValues.length > 0 && (
                      <div className="mb-4 cogl-md-4 col-lg-4 col-sm-12">
                        <label>
                          <i className="text-danger">*</i> Week Days
                        </label>
                        <Select
                          value={Weekobj}
                          onChange={this.handleWeek}
                          // onBlur={(e) => this.onValidate(e, "event")}
                          name="event"
                          options={WeekValues}
                          placeholder="Select Week Days"
                          valueKey="weekdayName"
                          labelKey="Weeklabel"
                          getOptionLabel={(option) => option.Weeklabel}
                          getOptionValue={(option) => option.weekdayName}
                          className={`${
                            errors.event ? "error-input-border" : ""
                          } `}
                          isDisabled={false}
                          isMulti={true}
                        />
                        {/* {errors.event && (
                        <span className="errorMsg">Please Select Event</span>
                      )} */}
                      </div>
                    )}
                  </>
                ) : null}
                {form && form.frequency === "Custom" && (
                  <>
                    <div className="mb-4 col-md-4 ">
                      <label>
                        <i className="text-danger">*</i> End Date
                      </label>
                      <div className="inner-addon right-addon">
                        <DatePicker
                          selected={
                            this.state.endDate
                              ? new Date(this.state.endDate)
                              : ""
                          }
                          onBlur={(e) => this.onEndDateValidate(e, "endDate")}
                          onChange={this.handleEndDate}
                          dateFormat="dd-MM-yyyy"
                          placeholderText="DD-MM-YYYY"
                          showYearDropdown
                          showMonthDropdown
                          className={`form-input ${
                            errForDate.endDate ? "error-input-border" : ""
                          } `}
                          disabled={false}
                          // maxDate={new Date()}
                          minDate={this.state.startDate}
                        />
                      </div>
                      {errForDate.endDate && (
                        <span className="errorMsg">Please select end date</span>
                      )}
                    </div>
                  </>
                )}
                <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                  <label>
                    <i className="text-danger">*</i> Type
                  </label>
                  <Select
                    defaultValue={
                      TypeSpecific.filter(
                        ({ TypeSpecificValue }) =>
                          TypeSpecificValue == form.type
                      )[0]
                    }
                    onChange={(e) => {
                      this.handleTypeSpecificSelectChanges(e, "type");
                    }}
                    onBlur={(e) => this.onValidate(e, "type")}
                    name="type"
                    options={TypeSpecific}
                    placeholder="Select typeSpecific"
                    // value={form.NotificationType}
                    valueKey="TypeSpecificValue"
                    labelKey="TypeSpecificLebel"
                    getOptionLabel={(option) => option.TypeSpecificLebel}
                    getOptionValue={(option) => option.TypeSpecificValue}
                    className={`${errors.type ? "error-input-border" : ""} `}
                    isDisabled={false}
                  />

                  {errors.type && (
                    <span className="errorMsg">Please Select typeSpecific</span>
                  )}
                </div>
                {(form && form.type === "Text") ||
                (form && form.type === "URL") ? (
                  <>
                    <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                      <label>
                        <i className="text-danger">*</i>
                        {form && form.type === "Text"
                          ? "Enter Text"
                          : "Enter URL"}
                      </label>
                      <div class="inner-addon right-addon">
                        <textarea
                          type="text"
                          // pattern="\d*"
                          //   maxLength="9"
                          name="textData"
                          style={{ marginLeft: "-1px" }}
                          value={textData}
                          onChange={(e) => {
                            this.handleTextChange(e, "textData");
                          }}
                          onBlur={(e) => {
                            this.onTextValidate(e, "textData");
                          }}
                          placeholder={
                            form && form.type === "Text"
                              ? "Enter Text"
                              : "Enter URL"
                          }
                          className={`form-input ${
                            errForTextUrl.textData ? "error-input-border" : ""
                          } `}
                          //   disabled={handleZero}
                        />
                      </div>

                      {errForTextUrl.textData && (
                        <span className="errorMsg">Please enter text data</span>
                      )}
                    </div>
                  </>
                ) : null}

                {form && form.type === "Image" ? (
                  <>
                    <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                      <label>
                        <i className="text-danger">*</i> Upload Image
                      </label>
                      <ImageUploader
                        name="imageOne"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="Upload Image"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "imageOne");
                        }}
                        imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                        maxFileSize={5242880}
                        singleImage={true}
                      />
                      {form &&
                      form.type == "Image" &&
                      this.state.textData != "" ? (
                        <a
                          href={`${FileUrl}${this.state.textData}`}
                          download
                          target="_blank"
                        >
                          {this.state.textData.split("/").pop()}
                        </a>
                      ) : null}
                    </div>
                  </>
                ) : null}

                {form && form.type === "video" ? (
                  <>
                    <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                      <label>
                        <i className="text-danger">*</i> Upload Video
                      </label>
                      <ImageUploader
                        name="imageOne"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="video/*"
                        buttonText="Upload Video"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "imageOne");
                        }}
                        imgExtension={[".mp4"]}
                        maxFileSize={52428800}
                        singleImage={true}
                      />
                      {form &&
                      form.type == "video" &&
                      this.state.textData != "" ? (
                        <a
                          href={`${FileUrl}${this.state.textData}`}
                          download
                          target="_blank"
                        >
                          {this.state.textData.split("/").pop()}
                        </a>
                      ) : null}
                    </div>
                  </>
                ) : null}
                <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                  <label>
                    <i className="text-danger">*</i> Trigger Notification
                  </label>
                  <Select
                    defaultValue={
                      TriggerNotification.filter(
                        ({ TriggerNotificationValue }) =>
                          TriggerNotificationValue == form.triggerNotification
                      )[0]
                    }
                    onChange={(e) => {
                      this.handleTriggerNotificationSelectChanges(
                        e,
                        "triggerNotification"
                      );
                    }}
                    onBlur={(e) => this.onValidate(e, "triggerNotification")}
                    name="triggerNotification"
                    options={TriggerNotification}
                    placeholder="Select Trigger Notification"
                    // value={form.NotificationType}
                    valueKey="TriggerNotificationValue"
                    labelKey="TriggerNotificationLebel"
                    getOptionLabel={(option) => option.TriggerNotificationLebel}
                    getOptionValue={(option) => option.TriggerNotificationValue}
                    className={`${
                      errors.triggerNotification ? "error-input-border" : ""
                    } `}
                    isDisabled={false}
                  />

                  {errors.triggerNotification && (
                    <span className="errorMsg">Please Select typeSpecific</span>
                  )}
                </div>
                {(form && form.type === "URL") ||
                (form && form.type === "Image") ||
                (form && form.type === "video") ? (
                  <>
                    <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                      <label>
                        <i className="text-danger">*</i>
                        {form && form.type === "URL"
                          ? "Enter title for URL"
                          : form && form.type === "Image"
                          ? "Enter title for Image"
                          : "Enter title for Video"}
                      </label>
                      <div class="inner-addon right-addon">
                        <input
                          type="text"
                          // pattern="\d*"
                          //   maxLength="9"
                          name="title"
                          style={{ marginLeft: "-1px" }}
                          value={title}
                          onChange={(e) => {
                            this.handleTitleChange(e, "title");
                          }}
                          onBlur={(e) => {
                            this.onTitletValidate(e, "title");
                          }}
                          placeholder={
                            form && form.type === "URL"
                              ? "Enter title for URL"
                              : form && form.type === "Image"
                              ? "Enter title for Image"
                              : "Enter title for Video"
                          }
                          className={`form-input ${
                            titleErr.title ? "error-input-border" : ""
                          } `}
                          //   disabled={handleZero}
                        />
                      </div>

                      {titleErr.title && (
                        <span className="errorMsg">Please enter title</span>
                      )}
                    </div>
                  </>
                ) : null}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-danger" onClick={this.handleModalClose}>
              Cancel
            </Button>
            <Button
              className="btn-success"
              disabled={
                diableSave ||
                isRequiredFeildFilled ||
                isLoading ||
                dateReq ||
                textUrlReq ||
                MonthDateReq ||
                WeeklyReq ||
                mediaReq ||
                titleReq ||
                (userEvent && userEvent.length == 0)
              }
              onClick={this.handleSubmit}
            >
              {this.props.action == "edit" ? "Update" : "Create"}
            </Button>
          </Modal.Footer>
        </Modal>
        <CloseModal
          show={showInner}
          button2={this.handleModalClose}
          button1={this.innerModalClose}
          title="Do you want to close ?"
        />
      </React.Fragment>
    );
  }
}

export default WebNotification;
