import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from "lodash";
import {
  findAllRoles,
  getRoleById,
  saveOrUpdateRole,
} from "../../../Utils/Services/role";
import CloseModal from "../../../Components/CloseModal";
import {
  formatIndianCurrency,
  replaceComma,
  checkIfNotDecimal,
} from "../../../Utils/helperFunctions";
toast.configure();

const initForm = {
  roleName: "",
  description: "",
  geography:"",
  levelling: "",
  rank: "",
  errors: {
    roleName: null,
    description: null,
  },
};

export class AddEditRole extends Component {
  state = {
    showModal: false,
    isLoading: false,
    showInner: false,
    geographyDropdownValue :[
      {
        geographyCode: "State",
        geographyName: "State",
      },
      {
        geographyCode: "Zone",
        geographyName: "Zone",
      },
      {
        geographyCode: "Region",
        geographyName: "Region",
      },
      {
        geographyCode: "Branch",
        geographyName: "Branch",
      }, {
        geographyCode: "Spoke",
        geographyName: "Spoke",
      },
      {
        geographyCode: "Nation",
        geographyName: "Nation",
      },
    ],

    form: cloneDeep(initForm),
  };
  componentDidMount = () => {
    let { form } = this.state;
    this.setState({ showModal: true });
    console.log("props.editData", this.props.editData);
    if (this.props.editData && this.props.action == "edit") {
      let { form } = this.state;
      let obj = { ...form, ...this.props.editData };
      this.setState({ form: obj });
    }
  };

  handleModalClose = () => {
    this.setState({ showInner: !this.state.showInner });
    // this.this.props.addRole();
  };

  innerModalClose = (modclose) => {
    if (!modclose) {
      this.handleModalClose();
    }
    this.setState({ showModal: false });
    this.props.handleEditRole();
  };

  handleSubmit = () => {
    let { form } = this.state;
    let obj = form;
    // delete obj.errors;
    this.setState({ isLoading: true });
    saveOrUpdateRole(obj).then((response) => {
      this.setState({ isLoading: false });
      if (response.error) {
        return;
      } else if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        toast.success(response.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        this.props.allRoles();
        this.props.handleEditRole();
        this.setState({ showModal: false });
      }
    });
  };

  handleInputChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("form role",form);
    console.log("handleInputChange", e, name, e.target.value);
    form[name] = e.target.value;
    this.setState({ form });
  };

  onValidate = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    // let { product, loantype, lowerloanamtnorms, baseamt, state, percentagevalue } = form;
    console.log("onValidate", name, e.target.value);
    if (form[name] == "" || form[name] == null || form[name] == undefined) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      form.errors[name] = null;
      this.setState({ form });
    }
  };
  handleStateSelectChange = (e, name) => {
    console.log("handleStateSelectChange", e, name);
    let { form } = this.state;
    let { value } = e;
    form[name] = e;
    console.log("form",form[name]);
    this.setState({ form });
  };
handleGeography=(e,name)=>{
  let { form } = this.state;
  console.log("geograpnyname",e.geographyName);
  form.geography=e.geographyName;
  this.setState({ form });

}
  render() {
    let { form, showModal, showInner, isLoading,geographyDropdownValue } = this.state;
    let { errors } = form;
    let diableSave = false;
    let isRequiredFeildFilled = false;
    Object.keys(form).map((name) => {
      console.log("form", form[name]);
      if (form[name] == "" || form[name] == null || form[name] == undefined) {
        isRequiredFeildFilled = true;
        return true;
      }
    });

    Object.keys(errors).map((error) => {
      console.log("error", errors[error]);
      if (errors[error] == true || errors[error] != null) {
        diableSave = true;
        return true;
      }
    });
    console.log("form", form);
    return (
      <>
        <Modal
          className="add-role"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showModal}
        >
          <Modal.Header>
            <Modal.Title>
              {this.props.action == "edit" ? "Edit Role" : "Add Role"}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="p-4">
            {isLoading ? (
              <h4 className="text-center">LOADING...</h4>
            ) : (
              <>
                <div className="row ">
                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label>
                        <i className="text-danger">*</i> Role Name
                      </label>
                      <div class="inner-addon right-addon">
                        <input
                          type="text"
                          // pattern="\d*"
                          maxLength="10"
                          name="roleName"
                          value={form.roleName}
                          onChange={(e) => {
                            this.handleInputChange(e, "roleName");
                          }}
                          onBlur={(e) => {
                            this.onValidate(e, "roleName");
                          }}
                          placeholder="Enter Role Code"
                          className={`form-input ${
                            errors.roleName ? "error-input-border" : ""
                          } `}
                          disabled={false}
                        />
                        {errors.roleName && (
                          <span className="errorMsg">
                            {errors.roleName == true
                              ? "Enter Role Name"
                              : errors.productNormUpperLimit}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* description */}
                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label>
                        <i className="text-danger">*</i> Description
                      </label>
                      <div class="inner-addon right-addon">
                        <input
                          type="text"
                          // pattern="\d*"
                          maxLength="100"
                          name="description"
                          value={form.description}
                          onChange={(e) => {
                            this.handleInputChange(e, "description");
                          }}
                          onBlur={(e) => {
                            this.onValidate(e, "description");
                          }}
                          placeholder="Enter Role Code"
                          className={`form-input ${
                            errors.description ? "error-input-border" : ""
                          } `}
                          disabled={false}
                        />
                        {errors.description && (
                          <span className="errorMsg">
                            {errors.description == true
                              ? "Enter description"
                              : errors.description}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                   {/* description */}
                   <div className="col-md-12 mt-3">
                      <label>
                        <i className="text-danger">*</i> Geography Mapping
                      </label>
                      <Select
                          defaultValue={ geographyDropdownValue.filter(                       
                              ({ geographyCode }) => geographyCode == form.geography
                          )[0] }
                          onChange={(e) => {
                            this.handleGeography(e, "geography"); //the same logic can be used for this input also
                          }}
                          onBlur={(e) => this.onValidate(e, "geography")}
                          name="geography"
                          options={geographyDropdownValue}
                          placeholder="Select Geography"
                          valueKey="geographyName"
                          labelKey="geographyCode"
                          getOptionLabel={(option) => option["geographyName"]}
                          getOptionValue={(option) => option["geographyCode"]}
                          className={`${
                            errors.state ? "error-input-border" : ""
                          } `}
                          isDisabled={false}
                        />

                        {errors.geography && (
                          <span className="errorMsg">
                            {errors.geography == true
                              ? "Enter GeographyMapping"
                              : errors.geography}
                          </span>
                        )}
                      </div>
                    
                  
                  {/* Level */}
                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label>
                        <i className="text-danger">*</i> Level
                      </label>
                      <div class="inner-addon right-addon">
                        <input
                          type="text"
                          // pattern="\d*"
                          //   maxLength="9"
                          name="levelling"
                          value={form.levelling}
                          onChange={(e) => {
                            this.handleInputChange(e, "levelling");
                          }}
                          onBlur={(e) => {
                            this.onValidate(e, "levelling");
                          }}
                          placeholder="Enter Level"
                          className={`form-input `}
                          disabled={true}
                        />
                        {/* {errors.description && (
                          <span className="errorMsg">
                            {errors.roleName == true
                              ? "Enter description"
                              : errors.description}
                          </span>
                        )} */}
                      </div>
                    </div>
                  </div>
                  {/* Rank */}
                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label>
                        <i className="text-danger">*</i> Rank
                      </label>
                      <div class="inner-addon right-addon">
                        <input
                          type="text"
                          // pattern="\d*"
                          //   maxLength="9"
                          name="rank"
                          value={form.rank}
                          onChange={(e) => {
                            this.handleInputChange(e, "levelling");
                          }}
                          onBlur={(e) => {
                            this.onValidate(e, "levelling");
                          }}
                          placeholder="Enter Rank"
                          className={`form-input `}
                          disabled={true}
                        />
                        {/* {errors.description && (
                          <span className="errorMsg">
                            {errors.roleName == true
                              ? "Enter description"
                              : errors.description}
                          </span>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <Modal.Footer>
              <Button className="btn-danger" onClick={this.handleModalClose}>
                Cancel
              </Button>
              <Button
                className="btn-success"
                disabled={diableSave || isRequiredFeildFilled || isLoading}
                onClick={this.handleSubmit}
              >
                {this.props.action == "edit" ? "Update" : "Create"}
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
        <CloseModal
          show={showInner}
          button2={this.handleModalClose}
          button1={this.innerModalClose}
          title="Do you want to close ?"
        />
      </>
    );
  }
}

export default AddEditRole;
