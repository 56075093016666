import { get, post } from "../httpInterceptor";
//getAllbranches inventory master

export const getAllBranchInventory = () => {
  return get(`/getBranchList`).then((res) => {
    console.log("RESPONSE :: getAllBranchInventory ::: ", res);
    return res;
  });
};

//getfranchise-location list

export const getAllFranchiseLocation = (branchName) => {
  return get(`/getFrechiseeList?branchName=${branchName}`).then((res) => {
    console.log("RESPONSE :: getAllFranchiseLocation ::: ", res);
    return res;
  });
};

//getlisting
//getAllinventoryList

export const getAllinventoryList = () => {
  return get(`/getImplInventoryMaster`).then((res) => {
    console.log("RESPONSE :: getAllinventoryList ::: ", res);
    return res;
  });
};

//save
export const saveImplInventoryMaster = (obj) => {
  return post(`/saveImplInventoryMaster`, obj, false).then((res) => {
    console.log("saveImplInventoryMaster", res);
    return res;
  });
};

//update and inactive API
export const updateImplInventoryMaster = (obj) => {
  return post(`/updateImplInventoryMaster`, obj, false).then((res) => {
    console.log("updateImplInventoryMaster", res);
    return res;
  });
};

//inactive api

export const inactiveImplement = (obj) => {
  return post(`/changeInventoryStatus`, obj, false).then((res) => {
    console.log("inactiveImplement", res);
    return res;
  });
};

//image api

export const uploadInventoryDoc = (obj) => {
  return post(`/uploadImages`, obj, false, true).then((res) => {
    console.log("uploadInventoryDoc", res);
    return res;
  });
};

// delete img

export const deleteinventoryImage = (obj) => {
  return post(`/deleteMedia`, obj, false).then((res) => {
    console.log("deleteinventoryImage", res);
    return res;
  });
};

// getImplementbySerialnumber , fetch data by serial number

export const getImplInventoryMasterBySerialNumber = (obj) => {
  return post(`/getImplInventoryMasterBySerialNumber`, obj, false).then(
    (res) => {
      console.log("getImplInventoryMasterBySerialNumber", res);
      return res;
    }
  );
};

// getImplementbySerialnumberForNew

export const getImplInventoryMasterBySerialNumberNew = (obj) => {
  return post(`/getImplInventoryMasterBySerialNumberNew`, obj, false).then(
    (res) => {
      console.log("getImplInventoryMasterBySerialNumberNew", res);
      return res;
    }
  );
};

// delete video
export const deleteinventoryVideo = (obj) => {
  return post(`/deleteMedia`, obj, false).then((res) => {
    console.log("deleteinventoryVideo", res);
    return res;
  });
};

// video api
export const uploadInventoryVideo = (
  serialNumber,
  franchisee,
  serviceName,
  obj
) => {
  return post(
    `/uploadVideo?serialNumber=${serialNumber}&franchisee=${franchisee}&serviceName=${serviceName}`,
    obj,
    false
  ).then((res) => {
    console.log("uploadInventoryVideo", res);
    return res;
  });
};

// getvillagebyfranchisedropdown

export const getVillageListByFranchiseeCode = (villageNameId) => {
  return post(
    `/getVillageListByFranchiseeCode?villageNameId=${villageNameId}`,
    {},
    false
  ).then((res) => {
    console.log("getVillageListByFranchiseeCode", res);
    return res;
  });
};
