import React, { useState, useEffect, Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from "lodash";

import CloseModal from "../../../Components/CloseModal";
import {
  findVillagesByPincode,
  saveOrUpdateMappingMaster,
  setStatusActiveOrInactive,
} from "../../../Utils/Services/Spoke";

toast.configure();

let initForm = {
  zonename: "",
  regionname: "",
  branchname: "",
  pincode: "",
  errors: {
    zonename: null,
    regionname: null,
    branchname: null,
    pincode: null,
  },
};
class AddEditVillageSpoke extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      showInner: false,
      key: Math.random(),
      form: cloneDeep(initForm),
      productDropdownValue: [],
      pincodeVillageMapping: [],
      loadingVillage: false,
    };
  }

  componentDidMount = () => {
    let { form } = this.state;
    this.setState({ showModal: true });
    console.log("props.editData", this.props.editData);
    let { editData } = this.props;
    if (this.props.editData && this.props.action == "edit") {
      let { form } = this.state;
      let obj = { ...form, ...this.props.editData };
      this.setState({
        form: obj,
        pincodeVillageMapping: editData.pincodeVillageMapping,
        key: Math.random(),
      });
    }
    // this.getSegments();
  };

  handleModalClose = () => {
    this.setState({ showInner: !this.state.showInner });
    // this.this.props.addRole();
  };

  innerModalClose = (modclose) => {
    if (!modclose) {
      this.handleModalClose();
    }
    this.setState({ showModal: false });
    if (this.props.action == "edit") {
      this.props.handleEditMapping();
    } else {
      this.props.handleAddMapping();
    }
  };

  handleSubmit = () => {
    let { form, pincodeVillageMapping } = this.state;
    let { getMap, editData } = this.props;
    let obj = {
      id: editData && editData.id,
      zonename: form.zonename,
      regionname: form.regionname,
      branchname: form.branchname,
      pincode: form.pincode,
      pincodeVillageMapping: pincodeVillageMapping,
      status:
        this.props.action == "edit" ? editData && editData.status : "Active",
      isUsedByUser:
        this.props.action == "edit" ? editData && editData.isUsedByUser : false,
    };

    this.setState({ isLoading: true });
    saveOrUpdateMappingMaster(obj).then((response) => {
      this.setState({ isLoading: false });
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        getMap();
        this.setState({ showModal: false });
        toast.success(response.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        this.props.handlePageChanges(0);
      }
    });
  };

  findVillage = () => {
    let { form } = this.state;
    this.setState({ loadingVillage: true });
    findVillagesByPincode(form.pincode).then((response) => {
      let PinVillage = response.data && response.data.data;
      console.log("data", PinVillage);
      if (response.data && response.data.error) {
        this.setState({ loadingVillage: false });
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        this.setState({
          pincodeVillageMapping: PinVillage,
          loadingVillage: false,
        });
      }
    });
  };

  ActiveOrInactive = () => {
    let { form } = this.state;
    let { editData, getMap } = this.props;
    console.log("edt", editData.id);
    this.setState({ loadingActiveOrInactive: true });
    let ActOrNot = editData && editData.status;
    let obj = {
      status: ActOrNot,
      id: editData && editData.id,
    };
    this.setState({ isLoading: true });
    setStatusActiveOrInactive(obj).then((response) => {
      if (response.data && response.data.error) {
        this.setState({ loadingActiveOrInactive: false });
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        getMap();
        this.setState({ showModal: false });
        toast.success(response.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        this.setState({
          loadingActiveOrInactive: false,
        });
        this.props.handlePageChanges(0);
      }
    });
  };

  handleZoneSelectChanges = (e, name) => {
    console.log("handleZoneSelectChanges", e, name);
    let { form } = this.state;
    let { zonecode } = e;
    form[name] = zonecode;
    if (
      form &&
      (form[name] === "" || form[name] === null || form[name] === undefined)
    ) {
      if (form && form.errors) {
        form.errors[name] = true;
        this.setState({ form });
      }
    } else {
      if (form && form.errors) {
        form.errors[name] = null;
        this.setState({ form });
      }
    }
    this.setState({ form, key: Math.random() });
  };

  handleRegionSelectChange = (e, name) => {
    console.log("handleRegionSelectChange", e, name);
    let { form } = this.state;
    let { regioncode } = e;
    form[name] = regioncode;
    if (
      form &&
      (form[name] === "" || form[name] === null || form[name] === undefined)
    ) {
      if (form && form.errors) {
        form.errors[name] = true;
        this.setState({ form });
      }
    } else {
      if (form && form.errors) {
        form.errors[name] = null;
        this.setState({ form });
      }
    }
    this.setState({ form, key: Math.random() });
  };

  handleBranchSelectChanges = (e, name) => {
    console.log("handleBranchSelectChanges", e, name);
    let { form } = this.state;
    let { branchcode } = e;
    form[name] = branchcode;
    if (
      form &&
      (form[name] === "" || form[name] === null || form[name] === undefined)
    ) {
      if (form && form.errors) {
        form.errors[name] = true;
        this.setState({ form });
      }
    } else {
      if (form && form.errors) {
        form.errors[name] = null;
        this.setState({ form });
      }
    }
    this.setState({ form, key: Math.random() });
  };

  handlePincodeSelectChanges = (e, name) => {
    console.log("handlePincodeSelectChanges", e, name);
    let { form } = this.state;
    let { pincode } = e;
    form[name] = pincode;
    if (
      form &&
      (form[name] === "" || form[name] === null || form[name] === undefined)
    ) {
      if (form && form.errors) {
        form.errors[name] = true;
        this.setState({ form });
      }
    } else {
      if (form && form.errors) {
        form.errors[name] = null;
        this.setState({ form });
      }
    }
    this.setState({ form });
    this.findVillage();
  };

  onValidate = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    if (
      form &&
      (form[name] === "" || form[name] === null || form[name] === undefined)
    ) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      form.errors[name] = null;
      this.setState({ form });
    }
    this.setState({ form });
  };

  render() {
    let {
      form,
      showModal,
      showInner,
      isLoading,
      pincodeVillageMapping,
      loadingVillage,
      key,
    } = this.state;
    let { errors } = form;
    let {
      zoneDropdownOptions,
      regionDropdownOptions,
      branchDropdownOptions,
      pincodeDropdownOptions,
      editData,
    } = this.props;
    let diableSave = false;
    errors &&
      Object.keys(errors).map((error) => {
        console.log("error", errors[error]);
        if (errors[error] == true && errors[error] != null) {
          diableSave = true;
          return true;
        }
      });
    let valPin =
      pincodeVillageMapping &&
      pincodeVillageMapping.map((res) => {
        return res.villageName;
      });

    console.log(
      "dis",
      diableSave,

      isLoading,
      loadingVillage
    );

    return (
      <>
        <div>
          <Modal
            className="product-norm-container"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            size="lg"
          >
            <Modal.Header>
              <Modal.Title>
                {this.props.action == "edit"
                  ? "Edit Zone-Region-Branch-Spoke-Mapping Master"
                  : "Add Zone-Region-Branch-Spoke-Mapping Master"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
              {isLoading ? (
                <h4 className="text-center">LOADING...</h4>
              ) : (
                <div className="row ">
                  <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                    <label>
                      <i className="text-danger">*</i> Zone
                    </label>
                    <Select
                      defaultValue={
                        zoneDropdownOptions.filter(
                          ({ zonecode }) => zonecode == form.zonename
                        )[0]
                      }
                      onChange={(e) => {
                        this.handleZoneSelectChanges(e, "zonename");
                      }}
                      onBlur={(e) => this.onValidate(e, "zonename")}
                      name="zonename"
                      options={zoneDropdownOptions}
                      placeholder="Select Zone"
                      valueKey="zonecode"
                      labelKey="zonecode"
                      getOptionLabel={(option) => option.zonecode}
                      getOptionValue={(option) => option.zonecode}
                      className={`${
                        errors && errors.zonename ? "error-input-border" : ""
                      } `}
                      isDisabled={
                        (editData && editData.status == "Inactive")
                      }
                      key={key}
                    />
                    {errors && errors.zonename && (
                      <span className="errorMsg">Please Select Zone</span>
                    )}
                  </div>
                  <div className="mb-4 col-md-4 ">
                    <label>
                      <i className="text-danger">*</i> Region
                    </label>
                    <Select
                      defaultValue={
                        regionDropdownOptions.filter(
                          ({ regioncode }) => regioncode == form.regionname
                        )[0]
                      }
                      onChange={(e) => {
                        this.handleRegionSelectChange(e, "regionname");
                      }}
                      onBlur={(e) => this.onValidate(e, "regionname")}
                      name="regionname"
                      options={regionDropdownOptions}
                      placeholder="Select Region"
                      valueKey="regioncode"
                      labelKey="regioncode"
                      getOptionLabel={(option) => option.regioncode}
                      getOptionValue={(option) => option.regioncode}
                      className={`${
                        errors && errors.regionname ? "error-input-border" : ""
                      } `}
                      isDisabled={
                        (editData && editData.status == "Inactive")
                      }
                      key={key}
                    />
                    {errors && errors.regionname && (
                      <span className="errorMsg">Please Select Region</span>
                    )}
                  </div>
                  {branchDropdownOptions && branchDropdownOptions.length > 0 && (
                    <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                      <label>
                        <i className="text-danger">*</i>Branch
                      </label>
                      <Select
                        defaultValue={
                          branchDropdownOptions &&
                          branchDropdownOptions.filter(
                            ({ branchcode }) => branchcode == form.branchname
                          )[0]
                        }
                        onChange={(e) => {
                          this.handleBranchSelectChanges(e, "branchname");
                        }}
                        onBlur={(e) => this.onValidate(e, "branchname")}
                        name="branchname"
                        options={branchDropdownOptions}
                        placeholder="Select Branch"
                        valueKey="branchcode"
                        labelKey="branchcode"
                        getOptionLabel={(option) => option.branchcode}
                        getOptionValue={(option) => option.branchcode}
                        className={`${
                          errors && errors.branchname
                            ? "error-input-border"
                            : ""
                        } `}
                        isDisabled={
                          (editData && editData.status == "Inactive")
                        }
                        key={key}
                      />
                      {errors && errors.branchname && (
                        <span className="errorMsg">Please Select Branch</span>
                      )}
                    </div>
                  )}

                  <div className="mb-4 col-md-4 ">
                    <label>
                      <i className="text-danger">*</i>Pincode
                    </label>
                    <Select
                      defaultValue={
                        pincodeDropdownOptions.filter(
                          ({ pincode }) => pincode == form.pincode
                        )[0]
                      }
                      onChange={(e) => {
                        this.handlePincodeSelectChanges(e, "pincode");
                      }}
                      onBlur={(e) => this.onValidate(e, "pincode")}
                      name="pincode"
                      options={
                        // levelDropdownOptions && levelDropdownOptions.length > 0
                        //   ? levelDropdownOptions
                        //   : []
                        pincodeDropdownOptions
                      }
                      placeholder="Select Pincode"
                      valueKey="pincode"
                      labelKey="pincode"
                      getOptionLabel={(option) => option.pincode}
                      getOptionValue={(option) => option.pincode}
                      className={`${
                        errors && errors.pincode ? "error-input-border" : ""
                      } `}
                      isDisabled={
                        (editData && editData.status == "Inactive")
                      }
                    />
                    {errors && errors.pincode && (
                      <span className="errorMsg">Please Select Pincode</span>
                    )}
                  </div>
                  {loadingVillage ? (
                    <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                      <label>Loading...</label>
                    </div>
                  ) : (
                    <div
                      className={`${
                        valPin && valPin.length > 4
                          ? "col-lg-8 col-sm-8 col-md-8"
                          : "col-lg-4 col-sm-4 col-md-4"
                      }`}
                    >
                      <label>
                        <i className="text-danger">*</i>Spoke
                      </label>

                      <div class="inner-addon right-addon">
                        <textarea
                          type="text"
                          name="village"
                          value={valPin && valPin.join(" ,  ")}
                          placeholder="Village/Spoke"
                          className={`form-input ${
                            errors && errors.village ? "error-input-border" : ""
                          } `}
                          disabled={true}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-danger" onClick={this.handleModalClose}>
                Cancel
              </Button>
              {this.props.action == "edit" ? (
                <Button
                  className="btn-primary"
                  onClick={this.ActiveOrInactive}
                  disabled={
                    isLoading 
                  }
                >
                  {editData && editData.status == "Active"
                    ? "Inactive"
                    : "Active"}
                </Button>
              ) : null}
              <Button
                className="btn-success"
                disabled={
                  diableSave ||
                  isLoading ||
                  loadingVillage ||
                  form.zonename == "" ||
                  form.zonename == undefined ||
                  form.branchname == "" ||
                  form.branchname == undefined ||
                  form.regionname == "" ||
                  form.regionname == undefined ||
                  form.pincode == "" ||
                  form.pincode == undefined ||
                  (editData && editData.status == "Inactive") 
                }
                onClick={this.handleSubmit}
              >
                {this.props.action == "edit" ? "Update" : "Create"}
              </Button>
            </Modal.Footer>
          </Modal>
          <CloseModal
            show={showInner}
            button2={this.handleModalClose}
            button1={this.innerModalClose}
            title="Do you want to close ?"
          />
        </div>
      </>
    );
  }
}

export default AddEditVillageSpoke;
