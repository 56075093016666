import { get, post } from "../httpInterceptor";

// API for find cancellation fees

export const findallcancellationfeesmaster = () => {
  return get(`/cancellation/findallcancellationfeesmaster`).then((res) => {
    console.log("RESPONSE :: findallcancellationfeesmaster ::: ", res);
    return res;
  });
};

// API for find all product dropdown values

export const findallproductdropdown = () => {
  return get(`/sarvagram-lms/cdproductsapis/getAllProductName`).then((res) => {
    console.log("RESPONSE :: findallproductdropdown ::: ", res);
    return res;
  });
};

// API for save or update cancellation data

export const saveorupdatecancellationmaster = (obj) => {
  return post(`/cancellation/saveorupdatecancellationmaster`, obj, false).then(
    (res) => {
      console.log("saveorupdatecancellationmaster", res);
      return res;
    }
  );
};

// API for delete cancellation data by id

export const deletecancellationfeesbyid = (obj) => {
  return post(`/cancellation/deletecancellationfeesbyid`, obj, false).then(
    (res) => {
      console.log("deletecancellationfeesbyid", res);
      return res;
    }
  );
};
