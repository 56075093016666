import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import AddEditService from "./AddEditService";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import {
  getAllinventoryList,
  getAllBranchInventory,
} from "../../../Utils/Services/InventoryMaster";

toast.configure();

const ServiceMaster = (props) => {
  let [servData, setcancelData] = useState([]);

  let [search, setSearch] = useState("");
  let [usereData, setuserData] = useState([]);
  let [userContainer, setuserContainer] = useState([]);
  let [implementList, setimplementList] = useState([]);
  let [productDropdownOptions, setproductDropdownOptions] = useState([]);
  let [showAddProductNormModal, setshowAddProductNormModal] = useState(false);
  let [showEditNormModal, setshowEditNormModal] = useState(false);
  let [serialNumberimp, setSerialNumber] = useState();
  let [franchiseName, setFranchiseName] = useState();
  let [service, setService] = useState();
  let [branchDropdownValue, setbranchDropdownValue] = useState([]);

  //search

  // this.setState({
  //   search: e.target.value,
  //   userData: e.target.value ? array : userContainer
  // });

  useEffect(() => {
    getAllbranches();
    getImplementInventory();
    window.scrollTo(0, 0);
  }, []);

  //getImplementInventory fucntion to fetch all data
  const getImplementInventory = (res) => {
    getAllinventoryList().then((response) => {
      if (response.error) {
        return;
      }
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        setuserData(response.data.data);
        setimplementList(response.data.data);
        setSearch("");
      }
    });
  };

  //getallbranch
  const getAllbranches = () => {
    getAllBranchInventory().then((result) => {
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        toast.error(result.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        if (result && result.data && result.data.data) {
          setbranchDropdownValue(result.data.data);
        }
      }
    });
  };
  const columns = [
    {
      Header: "SR. No",
      width: 20,
      Cell: (data) => {
        return data.index + 1;
      },
    },

    {
      Header: "Serial No",
      width: 70,
      Cell: ({ original }) => {
        console.log("or", original);
        return original && original.serialNumber ? original.serialNumber : null;
      },
    },

    {
      Header: "Service type",
      //  width: 50,
      Cell: ({ original }) => {
        return original && original.service ? original.service : null;
      },
    },
    {
      Header: "Implement",
      //  width: 50,
      Cell: ({ original }) => {
        return original && original.implement ? original.implement : null;
      },
    },
    {
      Header: "Branch",
      Cell: ({ original }) => {
        return original && original.branch ? original.branch : null;
      },
    },

    {
      Header: "Franchisee",
      Cell: ({ original }) => {
        return original && original.franchisee ? original.franchisee : null;
      },
    },

    {
      Header: "Franchisee Code",
      Cell: ({ original }) => {
        return original && original.franchiseeCode ? original.franchiseeCode : null;
      },
    },

    {
      Header: "Village",
      //  width: 50,
      Cell: ({ original }) => {
        console.log("or", original);
        return original && original.village ? original.village : "";
      },
    },

    {
      Header: "Unit",
      //  width: 50,
      Cell: ({ original }) => {
        return original && original.unit ? original.unit : "0";
      },
    },

    {
      Header: "Rate",
      //  width: 50,
      Cell: ({ original }) => {
        return original && original.rate ? original.rate : "0";
      },
    },

    {
      Header: "Variable cost",
      //  width: 50,
      Cell: ({ original }) => {
        return original && original.variableCost ? original.variableCost : "0";
      },
    },

    {
      Header: "Status",
      //  width: 50,
      Cell: ({ original }) => {
        return original && original.status ? original.status : "0";
      },
    },

    {
      Header: "In-Use",
      width: 30,
      Cell: ({ original }) => {
        return original && original.inUse ? original.inUse : null;
      },
    },

    {
      Header: "Action",
      //  width: 50,
      disabled: false,
      accessor: "delete",
      accessor: "edit",

      Cell: ({ original }) => {
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            {/* <i
              disabled={false}
              //   onClick={() => deleteCancelById(original.id)}
              className="fa fa-trash"
              style={{ cursor: "pointer" }}
            /> */}
            <i
              disabled={false}
              onClick={() =>
                handleEditInventoryMaster(
                  original && original.serialNumber,
                  original && original.franchisee,
                  original && original.service
                )
              }
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const handleAddCancel = (id) => {
    setshowAddProductNormModal(!showAddProductNormModal);
  };

  const handleEditInventoryMaster = (
    serialNumberimp,
    franchiseName,
    service
  ) => {
    setshowEditNormModal(!showEditNormModal);
    setSerialNumber(serialNumberimp);
    setFranchiseName(franchiseName);
    setService(service);
  };

 



  const handleSearch = (e) => {
    let array = [];
    implementList && implementList.map((res) => {
      let name = res.name;
      if (
        res && res.serialNumber &&
        res && res.service &&
        res && res.implement &&
        res && res.variableCost &&
        res && res.status&&
        res && res.frenchiseeShearPersentage &&
        res && res.branch &&
        res && res.franchisee &&
        res && res.village &&
        res && res.unit &&
        res && res.rate &&
        res && res.inUse 
      )
        if (
          res && res.serialNumber.toLowerCase().match(e.target.value.toLowerCase()) ||
          res && res.service.toLowerCase().match(e.target.value.toLowerCase()) ||
          res && res.implement.toLowerCase().match(e.target.value.toLowerCase()) ||
          res && res.variableCost.toString().match(e.target.value.toString()) ||
          res && res.frenchiseeShearPersentage
            .toString()
            .match(e.target.value.toString()) ||
          res && res.branch.toLowerCase().match(e.target.value.toLowerCase()) ||
          res && res.status.toLowerCase().match(e.target.value.toLowerCase()) ||
          res && res.franchisee.toLowerCase().match(e.target.value.toLowerCase()) ||
          res && res.village.toString().match(e.target.value.toString()) ||
          res && res.unit.toLowerCase().match(e.target.value.toLowerCase()) ||
          res && res.rate.toString().match(e.target.value.toString()) ||
           res.inUse.toLowerCase().match( e.target.value.toLowerCase())
        ) {
          array.push(res);
        }

    });
    setSearch(e.target.value);
    setuserData(e.target.value ? array : implementList);
  };

  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box">
            {" "}
            Implement Inventory Master
          </h5>
          <div className="d-flex align-items-center mt-5">
            {/* search */}
            <div className="form-group m-0 search-input">
              <div className="inner-addon right-addon">
                <i className="glyphicon glyphicon-search mt-1 font-size-20"></i>
                <input
                  type="text"
                  className="form-input"
                  name="search"
                  value={search}
                  onChange={(e) => {
                    handleSearch(e);
                  }}
                  // onBlur={this.handleValidate}
                  placeholder="Search..."
                />
              </div>
            </div>
            <button
              disabled={false}
              onClick={handleAddCancel}
              className="add-btn ml-auto btn btn-info btn-lg addBtn"
            >
              {" "}
              Add{" "}
            </button>
            {showAddProductNormModal && (
              <AddEditService
                action={"add"}
                getImplementInventory={getImplementInventory}
                handleAddCancel={handleAddCancel}
                productDropdownOptions={productDropdownOptions}
                branchDropdownValue={branchDropdownValue}
              />
            )}
            {showEditNormModal && (
              <AddEditService
                action={"edit"}
                getImplementInventory={getImplementInventory}
                serialNumberimp={serialNumberimp}
                franchiseName={franchiseName}
                service={service}
                handleEditInventoryMaster={handleEditInventoryMaster}
                productDropdownOptions={productDropdownOptions}
                branchDropdownValue={branchDropdownValue}
              />
            )}
          </div>

          <div className={`mt-4 react-table`}>
            <ReactTable
              // data={implementList}
              data={usereData}
              // data={servData}
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default ServiceMaster;
