import React, {Component}from "react";
import DatePicker from "react-datepicker";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { cloneDeep } from "lodash";
import "./style.css"; 
import CloseModal from "../../../Components/CloseModal";

import { toast } from "react-toastify";
import { getAllCampaignMedium } from "../../../Utils/Services/CampaignMedium";

toast.configure();

let initForm = {
	
	campaignName: "",
    campaignMedium:"",
	campaignNameDescription: "",
	startDate: "",
	endDate: "",
	errors: {
        campaignName: null,
		campaignMedium: null,
		campaignNameDescription: null,
		startDate: null,
		endDate: null,
	},
};
class AddCampaignNameMaster extends Component{

    constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			isLoading: false,
			showInner: false,
			form: cloneDeep(initForm),
			CampaignList: [],
		};
	}

    componentDidMount = () => {
		console.log("this.props.editData", this.props);
		if (this.props.editData && this.props.action == "edit") {
			let { form } = this.state;
			console.log("this.props.editData", this.props.editData);
			let obj = {
				...form,
				...this.props.editData,
				startDate: new Date(this.props.editData.startDate),
				endDate: new Date(this.props.editData.endDate),
			};
			this.setState({ form: obj });
		}
		this.setState({ showModal: true });
		getAllCampaignMedium().then((res) => {
			console.log("RESPONSE :: getAllCampaignMedium ::: ", res);
			this.setState({ CampaignList: res.data && res.data.data ? res.data.data : [] });
		});
	};

    handleModalClose = () => {
		this.setState({ showInner: !this.state.showInner });
	};

	innerModalClose = (modclose) => {
		if (!modclose) {
			this.handleModalClose();
		}
		this.setState({ showModal: false });
		if (this.props.action == "edit") {
			this.props.handleEditCampaignModal();
		} else {
			this.props.handleAddCampaignModal();
		}
	};
    handleInputChange = (e, name) => {
		e.preventDefault();
		let { form } = this.state;
		form[name] = e.target.value;
		this.setState({ form });
	};
    onValidate = (e, name) => {
		e.preventDefault();
		if (name == "campaignName") {
			e.target.value <= 0
				? this.setState({
						form: { ...this.state.form, errors: { ...this.state.form.errors, campaignName: "Campaign Name must not be blank." } },
				  })
				: this.setState({ form: { ...this.state.form, errors: { ...this.state.form.errors, campaignName: null } } });
		}

        if (name == "campaignNameDescription") {
			e.target.value.length <= 0
				? this.setState({
						form: { ...this.state.form, errors: { ...this.state.form.errors, campaignNameDescription: "Campaign Name Description must not be blank." } },
				  })
				: this.setState({ form: { ...this.state.form, errors: { ...this.state.form.errors, campaignNameDescription: null } } });
		}
        if (name == "campaignMedium") {
			const { form } = this.state;
			console.log("campaignMedium", e);
			if (e.campaignMedium == "" || e.campaignMedium == null || e.campaignMedium == undefined) {
				this.setState({
					form: { ...form, errors: { ...form.errors, campaignMedium: true } },
				});
			}
			if (form.campaignMedium) {
				this.setState({
					form: { ...form, errors: { ...form.errors, campaignMedium: null } },
				});
			}
		}
	};
    handleStartDateChange = (date) => {
		console.log("handleStartDateChange", date);
		this.setState({
			form: { ...this.state.form, startDate: date },
		});
	};

	handleEndDateChange = (date) => {
		console.log("handleEndDateChange", date);
		this.setState({
			form: { ...this.state.form, endDate: date },
		});
	};
    validateStartDate = () => {
		console.log("HAPPENED");
		let { form } = this.state;
		if (form.startDate > form.endDate) {
			this.setState({
				form: { ...this.state.form, errors: { ...this.state.form.errors, startDate: "Start Date can not be greater than End Date." } },
			});
		} else {
			this.setState({
				form: { ...this.state.form, errors: { ...this.state.form.errors, startDate: null } },
			});
		}
	};
    validateDateInput = () => {
		console.log("HAPPENED");
		let { form } = this.state;
		if (form.endDate < form.startDate) {
			this.setState({
				form: { ...this.state.form, errors: { ...this.state.form.errors, endDate: "End Date can not be less than Start Date." } },
			});
		} else {
			this.setState({
				form: { ...this.state.form, errors: { ...this.state.form.errors, endDate: null } },
			});
		}
		if (form.startDate > form.endDate) {
			this.setState({
				form: { ...this.state.form, errors: { ...this.state.form.errors, startDate: "Start Date can not be greater than End Date." } },
			});
		} else {
			this.setState({
				form: { ...this.state.form, errors: { ...this.state.form.errors, startDate: null } },
			});
		}
	};
    handleSubmit = () => {
		if (this.props.action == "edit") {
			this.props.editCampaignName(this.state.form);
		} else {
			this.props.addCampaignName(this.state.form);
		}
	};
    handleProductChange = (e, name) => {
		console.log("handleStateSelectChange", e, name);
		let { form } = this.state;
		let { campaignMedium } = e;
		form[name] = campaignMedium;
		this.setState({ form });
	};
    render() {
		let { form, showModal, showInner, isLoading, CampaignList } = this.state;
		let { errors } = form;
		let disableSave = false;

		Object.keys(errors).map((error) => {
			if (errors[error] == true || errors[error] != null) {
				disableSave = true;
				return true;
			}
		});
		Object.keys(form).map((key) => {
			if (form[key] == null || form[key] == "") {
				disableSave = true;
				return true;
			}
		});
        return (
			<>
				<div>
					<Modal className="franking-fee-container" aria-labelledby="contained-modal-title-vcenter" centered show={showModal} size="lg">
						<Modal.Header>
							<Modal.Title>{this.props.action == "edit" ? "Edit Campaign Name Master" : "Add Campaign Name Master"}</Modal.Title>
						</Modal.Header>
						<Modal.Body className="p-4">
							{isLoading ? (
								<h4 className="text-center">LOADING...</h4>
							) : (
								<>
                                <div className="row">
                                        <div className="form-group col-md-4 ">
											<label>Campaign Name {<i className="text-danger">*</i>}</label>
											<div className="inner-addon right-addon">
												<input
													type="text"
													maxLength="255"
													name="campaignName"
													value={form.campaignName && form.campaignName}
													onChange={(e) => {
														this.handleInputChange(e, "campaignName");
													}}
													onBlur={(e) => {
														this.onValidate(e, "campaignName");
													}}
													// placeholder="Enter Campaign Name"
													className={`form-input ${errors.campaignName ? "error-input-border" : ""} `}
													disabled={false}
												/>
												{errors.campaignName && <span className="errorMsg">{errors.campaignName}</span>}
											</div>
										</div>
                                        <div className="mb-4 col-md-4">
											<label>
												<i className="text-danger">*</i> Campaign Medium
											</label>
											<Select
												value={CampaignList.filter((item) => item.campaignMedium == form.campaignMedium)[0]}
												onChange={(e) => {
													this.handleProductChange(e, "campaignMedium");
												}}
												onBlur={(e) => this.onValidate(e, "campaignMedium")}
												name="campaignMedium"
												options={CampaignList}
												placeholder="Select Campaign"
												valueKey="campaignMedium"
												labelKey="campaignMedium"
												getOptionLabel={(option) => option["campaignMedium"]}
												getOptionValue={(option) => option["campaignMedium"]}
												className={`${errors.campaignMedium ? "error-input-border" : ""} `}
												isDisabled={false}
											/>
											{errors.campaignMedium && <span className="errorMsg">Please Select Campaign Medium</span>}
										</div>
									</div>
                                    <div className="row">
										<div className="form-group col-md-6">
											<label>Campaign Name Description {<i className="text-danger">*</i>}</label>
											<div>
												<textarea
													style={{ resize: "none" }}
													rows="5"
													type="text"
													maxLength="255"
													name="campaignNameDescription"
													value={form.campaignNameDescription && form.campaignNameDescription}
													onChange={(e) => {
														this.handleInputChange(e, "campaignNameDescription");
													}}
													onBlur={(e) => {
														this.onValidate(e, "campaignNameDescription");
													}}
													// placeholder="Enter Campaign Description"
													className={`form-input ${errors.campaignNameDescription ? "error-input-border" : ""} `}
													disabled={false}
												/>
												{errors.campaignNameDescription && <span className="errorMsg">{errors.campaignNameDescription}</span>}
											</div>
										</div>
                                        <div className="form-group col-md-3 ">
											<label>Start Date {<i className="text-danger">*</i>}</label>
											<div>
												<DatePicker
													selected={form && form.startDate}
													onBlur={this.validateDateInput}
													onChange={this.handleStartDateChange}
													dateFormat="dd-MM-yyyy"
													placeholderText="DD-MM-YYYY"
													showYearDropdown
													showMonthDropdown
													 minDate={new Date()}													
													className={`form-input ${errors.startDate ? "error-input-border" : ""} `}
													disabled={false}
													// maxDate={new Date()}
												/>
											</div>
											{errors.startDate && <span className="errorMsg">{errors.startDate}</span>}
										</div>
                                        <div className="form-group col-md-3">
											<label>End Date {<i className="text-danger">*</i>}</label>
											<div class="inner-addon right-addon relative-position">
												<DatePicker
													selected={form && form.endDate}
													onBlur={this.validateDateInput}
													onChange={this.handleEndDateChange}
													dateFormat="dd-MM-yyyy"
													placeholderText="DD-MM-YYYY"
													showYearDropdown
													showMonthDropdown
													 minDate={new Date()}
													className={`form-input ${errors.endDate ? "error-input-border" : ""} `}
													disabled={false}
													// maxDate={new Date()}
												/>
											</div>
											{errors.endDate && <span className="errorMsg">{errors.endDate}</span>}
										</div>
										<div className="dummy-div"></div>
									</div>
								</>
							)}
						</Modal.Body>
                        <Modal.Footer>
							<Button className="btn-danger" onClick={this.handleModalClose}>
								Cancel
							</Button>
							<Button className="btn-success" disabled={disableSave || isLoading} onClick={this.handleSubmit}>
								{this.props.action == "edit" ? "Update" : "Create"}
							</Button>
						</Modal.Footer>
					</Modal>
					<CloseModal show={showInner} button2={this.handleModalClose} button1={this.innerModalClose} title="Do you want to close ?" />
				</div>
			</>
		);
	}
}
export default AddCampaignNameMaster;