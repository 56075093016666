import { get, post } from "../httpInterceptor";

// Update Password
export const getUserByEmployeeId = (employeeId) => {
  return post(
    `/sarvagram-credit/user/getUserByEmployeeId?employeeId=${employeeId}`,
    {},
    false
  ).then((res) => {
    console.log("getUserByEmployeeId", res);
    return res;
  });
};

export const newGetUserByEmployeeId = (objBody = {}) => {
  return post(
    `/sarvagram-credit/user/getUserByEmployeeId/`,
    objBody,
    false
  ).then((res) => {
    console.log("getUserByEmployeeId", res);
    return res;
  });
};

export const getUserInfo = (empId) => {
  return get(`/user/${empId}`, false).then((res) => {
    console.log("getUserZoneAndRegion", res);
    return res;
  });
};
