import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import LeftMenu from "../../../../Components/LeftMenu";
import Header from "../../../../Components/Header";
import ReactTable from "react-table";
import { FullScreenLoader } from "../../../../Components/Assets/Loader";
import GoldApprovalMasterForm from "./GoldApprovalMasterForm";
import {  getGoldAppraiserApprovalList } from "../../../../Utils/Services/goldAppraiser"
import "react-table/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();


class GoldApprovalMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showModal: false,
            search: "",
            appraiserData: [],
            appraiserContainer: [],
            verifyObj: "",
            status: ""
        }
    }

    componentDidMount = () => {
        this.getGoldAppraiser();
    }

    getGoldAppraiser = () => {
        this.setState({ loading: true })
        getGoldAppraiserApprovalList().then((res) => {
            this.setState({ loading: false })
            if (res.error) {
                this.setState({ loading: false });

                return;
            }
            if (res.data.error) {
                toast.error(res.data.message, {
                    type: toast.TYPE.ERROR,
                    autoClose: 4000
                });
                this.setState({ loading: false });
                return
            } else {
                // console.log("res.data/.///", res.data);
                if (res.data && res.data.data && res.data.data.length > 0) {
                    this.setState({ appraiserData: res.data.data, appraiserContainer: res.data.data })
                }
            }
        });
    }

    addAppraiser = (id, status) => {
        let { appraiserData } = this.state;
        let appraiserDataTemp = appraiserData.find(
            appraiserData => appraiserData.applicationNumber === id
        );

        this.setState(
            {
                showModal: !this.state.showModal,
                verifyObj: appraiserDataTemp ? appraiserDataTemp.applicationNumber : null,
                status: status
            }
        );
    };

    search = e => {
        let { appraiserContainer } = this.state;
        let array = [];
        appraiserContainer.map(res => {
            let name = res.apraiserCode;

            if (
                res.apraiserCode ||
                (res && res.companyPanNumber) ||
                (res && res.shopName) ||
                (res && res.emailId) ||
                (res && res.mobileNumber) ||
                (res.status && res.status) ||
                (res.updatedOn && res.updatedOn)
            )
                if (
                    res.apraiserCode && res.apraiserCode.toLowerCase().match(e.target.value.toLowerCase()) ||
                    res.companyPanNumber && res.companyPanNumber.toLowerCase().match(e.target.value.toLowerCase()) ||
                    res && res.emailId.toLowerCase().match(e.target.value.toLowerCase()) ||
                    res.mobilenumber && res.mobilenumber
                        .toLowerCase()
                        .match(e.target.value.toLowerCase()) ||
                    res && res.mobileNumber.toString().match(e.target.value.toString()) ||
                    res.updatedOn && res.updatedOn.toString().match(e.target.value.toString()) ||
                    res.status && res.status
                        .toString()
                        .toLowerCase()
                        .match(e.target.value.toString().toLowerCase())
                ) {
                    array.push(res);
                }
        });
        this.setState({
            search: e.target.value,
            appraiserData: e.target.value ? array : appraiserContainer
        });
    };


    render() {
        let { loading, showModal, appraiserData, verifyObj, status } = this.state;

        const columns = [
            {
                Header: "Application Number",
                // foldable: true,

                Cell: ({ original }) => {
                    return original.appraiserCode;
                    // return (onclick = () => this.addAppraiser(original.dsacode));
                }
            },
            {
                Header: "Pan Name",
                // foldable: true,

                Cell: ({ original }) => {
                    return original.shopName;
                }
            },
            {
                Header: "Pan",
                // foldable: true,

                Cell: ({ original }) => {
                    return original.companyPanNumber;
                }
            },
            {
                Header: "Email Id",
                // foldable: true,

                Cell: ({ original }) => {
                    return original.emailId;
                }
            },
            {
                Header: "Mobile Number",
                // foldable: true,

                Cell: ({ original }) => {
                    return original.mobileNumber;
                }
            },
            {
                Header: "Status",

                Cell: ({ original }) => {
                    return original.status;
                }
            },
            {
                Header: "Updated On",
                accessor: "updatedOn",

                Cell: ({ original }) => {
                    return original.updatedOn;
                }
            },
            {
                Header: "Action",
                accessor: "edit",

                Cell: ({ original }) => {
                    console.log("original...",original && original.applicationNumber, original && original.status)
                    return (
                        <div disabled={false}>
                            <i
                                onClick={() =>
                                    this.addAppraiser(original && original.applicationNumber, original && original.status)
                                }
                                className="fas fa-user-edit"
                            />
                        </div>
                    );
                }
            }
        ];
        return (
            <div className="dash_grid">
                <FullScreenLoader />
                <LeftMenu />
                <main className="bg-white">
                    <Header {...this.props} />
                    <section className="container-fluid">
                        <ToastContainer />
                        <h5 className="text-center mt-5 mx-auto user-box">Gold Approval Master</h5>
                        {!loading ? (
                            <React.Fragment>
                                <div className="d-flex align-items-center mt-5">
                                    <div className="form-group m-0 search-input">
                                        <div className="inner-addon right-addon">
                                            <i className="glyphicon glyphicon-search mt-1 font-size-20"></i>
                                            <input
                                                type="text"
                                                className="form-input"
                                                name="search"
                                                placeholder="Search..."
                                                onChange={this.search}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={`mt-4 react-table`}>
                                    <ReactTable
                                        scroll={{ x: "max-content", y: 350 }}
                                        data={appraiserData}
                                        columns={columns}
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                    />
                                </div>
                            </React.Fragment>
                        ) : (
                                <h4 className="text-center mt-5">LOADING...</h4>
                            )}
                        {showModal && (
                            <GoldApprovalMasterForm
                                addAppraiser={this.addAppraiser}
                                verifyObj={verifyObj}
                                status={status}
                                fetchAppraiser={this.getGoldAppraiser}
                            />
                        )}
                    </section>
                </main>
            </div>
        )
    }
}


export default withRouter(GoldApprovalMaster);
