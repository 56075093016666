import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddEditVillageSpoke from "./AddEditVillageSpoke";
import { formatIndianCurrency } from "../../../Utils/helperFunctions";

import {
  getAllBranches,
  getAllPincodes,
  getAllRegions,
  getAllZones,
  findAllMapping,
} from "../../../Utils/Services/Spoke";

toast.configure();

const VillageSpoke = (props) => {
  let [search, setSearch] = useState("");
  let [showMapModal, setshowMapModal] = useState(false);
  let [showEditMapModal, setshowEditMapModal] = useState(false);
  let [editData, setEditData] = useState({});
  let [zoneDropdownOptions, setzoneDropdownOptions] = useState([]);
  let [regionDropdownOptions, setregionDropdownOptions] = useState([]);
  let [branchDropdownOptions, setbranchDropdownOptions] = useState([]);
  let [pincodeDropdownOptions, setpincodeDropdownOptions] = useState([]);
  let [getMapping, setgetMapping] = useState([]);
  let [page, setPage] = useState(0);

  useEffect(() => {
    getMap();
    getZone();
    getRegion();
    getBranch();
    getPincode();
    window.scrollTo(0, 0);
  }, []);

  const getMap = () => {
    findAllMapping().then((res) => {
      console.log("findAllMapping", res);
      if (res.error) {
        return;
      } else {
        if (res.data && res.data.error) {
          toast.error(res.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        } else {
          /* toast.success(res.data.message, {
                          type: toast.TYPE.SUCCESS,
                          autoClose: 2000
                      }); */
          setgetMapping(res.data.data);
        }
      }
    });
  };

  const getZone = () => {
    getAllZones().then((result) => {
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        toast.error(result.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        if (result && result.data && result.data.data) {
          setzoneDropdownOptions(result.data.data);
        }
      }
    });
  };

  const getRegion = () => {
    getAllRegions().then((result) => {
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        toast.error(result.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        if (result && result.data && result.data.data) {
          setregionDropdownOptions(result.data.data);
        }
      }
    });
  };

  const getBranch = () => {
    getAllBranches().then((result) => {
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        toast.error(result.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        if (result && result.data && result.data.data) {
          setbranchDropdownOptions(result.data.data);
        }
      }
    });
  };

  const getPincode = () => {
    getAllPincodes().then((result) => {
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        toast.error(result.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        if (result && result.data && result.data.data) {
          let newPin = result.data.data;
          newPin.push({
            id: null,
            pincode: "1000001",
          });
          setpincodeDropdownOptions(newPin);
        }
      }
    });
  };

  const columns = [
    {
      Header: "SR. No",
      width: 70,
      Cell: (data) => {
        // console.log("data...", data)
        return data.index + 1;
      },
      /* Cell: ({ original }) => {
                return original.id && original.id ? original.id : null;
            } */
    },
    {
      Header: "Zone",
      width: 200,
      Cell: ({ original }) => {
        console.log("org", original);
        return original.zonename && original.zonename
          ? original.zonename
          : null;
      },
    },
    {
      Header: "Region",
      Cell: ({ original }) => {
        return original.regionname && original.regionname
          ? original.regionname
          : null;
      },
    },
    {
      Header: "Branch",
      Cell: ({ original }) => {
        return original.branchname && original.branchname
          ? original.branchname
          : null;
      },
    },
    {
      Header: "Pin Code",
      Cell: ({ original }) => {
        return original && original.pincode ? original.pincode : null;
      },
    },
    {
      Header: "Status",
      Cell: ({ original }) => {
        return original && original.status ? original.status : null;
      },
    },
    {
      Header: "Action",
      width: 125,
      disabled: false,
      accessor: "edit",

      Cell: ({ original }) => {
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              onClick={() => handleEditMapping(original)}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const handleAddMapping = (id) => {
    setshowMapModal(!showMapModal);
  };

  const handleEditMapping = (obj) => {
    setshowEditMapModal(!showEditMapModal);
    setEditData(obj);
  };

  const handleSearch = (e) => {
    /* let { roleContainer } = this.state;
        let array = [];
        roleContainer.map(res => {
            let roleName = res.roleName;
            if (roleName)
                if (res.roleName.match(e.target.value.toUpperCase())) {
                    array.push(res);
                }
        });
        this.setState({
            search: e.target.value,
            roleData: e.target.value ? array : roleContainer
        }); */
  };

  const handlePageChanges = (page) => {
    setPage(page);
  };

  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box">
            Zone-Region-Branch-Spoke-Mapping Master
          </h5>
          <div className="d-flex align-items-center mt-5">
            <button
              disabled={false}
              onClick={handleAddMapping}
              className="add-btn ml-auto btn btn-info btn-lg"
            >
              {" "}
              Add{" "}
            </button>
            {showMapModal && (
              <AddEditVillageSpoke
                action={"add"}
                getMap={getMap}
                // levelDropdownOptions={levelDropdownOptions}
                zoneDropdownOptions={zoneDropdownOptions}
                regionDropdownOptions={regionDropdownOptions}
                branchDropdownOptions={branchDropdownOptions}
                pincodeDropdownOptions={pincodeDropdownOptions}
                handleAddMapping={handleAddMapping}
                handlePageChanges={handlePageChanges}
              />
            )}
            {showEditMapModal && (
              <AddEditVillageSpoke
                action={"edit"}
                getMap={getMap}
                editData={editData}
                zoneDropdownOptions={zoneDropdownOptions}
                regionDropdownOptions={regionDropdownOptions}
                branchDropdownOptions={branchDropdownOptions}
                pincodeDropdownOptions={pincodeDropdownOptions}
                handleEditMapping={handleEditMapping}
                handlePageChanges={handlePageChanges}
              />
            )}
          </div>

          <div className={`mt-4 react-table`}>
            <ReactTable
              data={getMapping}
              columns={columns}
              defaultPageSize={10}
              page={page}
              onPageChange={(page) => handlePageChanges(page)}
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default VillageSpoke;
