import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddEditLegalValution from "./AddEditLegalValution";
import { formatIndianCurrency } from "../../../Utils/helperFunctions";
import { getAllLegalValution } from "../../../Utils/Services/legalValution";
toast.configure();

let data = [
  {
    legalValution: "1002",
    id: 1,
  },
  {
    legalValution: "3000",
    id: 2,
  },
];
const LegalValution = (props) => {
  let [search, setSearch] = useState("");
  let [legalValutionData, setlegalValutionData] = useState([]);
  let [showAddLegalValutionModal, setshowAddLegalValutionModal] = useState(
    false
  );
  let [showEditLegalValutionModal, setshowEditLegalValutionModal] = useState(
    false
  );
  let [editData, setEditData] = useState({});

  useEffect(() => {
    getLegalValution();
  }, []);

  const getLegalValution = () => {
    getAllLegalValution().then((res) => {
      console.log("getAllLegalValution", res);
      if (res.error) {
        return;
      }
      if (res.data && res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        /*  toast.success(res.data.message, {
                    type: toast.TYPE.SUCCESS,
                    autoClose: 2000
                }); */
        setlegalValutionData(res.data.data);
      }
    });
  };
  const columns = [
    {
      Header: "SR. No",
      Cell: (data) => {
        // console.log("data...", data)
        return data.index + 1;
      },
      /*  Cell: ({ original }) => {
                return original.id && original.id ? original.id : null;
            } */
    },
    {
      Header: "Legal & Valuation Fee - In Rupees (Excl GST)",
      Cell: ({ original }) => {
        return original.legalandvaluationfee && original.legalandvaluationfee
          ? `${formatIndianCurrency(original.legalandvaluationfee)}`
          : null;
      },
    },
    {
      Header: "Action",
      disabled: false,
      accessor: "delete",
      accessor: "edit",

      Cell: ({ original }) => {
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              onClick={() => handleEditLegalValution(original)}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const handleAddLegalValution = (id) => {
    setshowAddLegalValutionModal(!showAddLegalValutionModal);
  };

  const handleEditLegalValution = (obj) => {
    setshowEditLegalValutionModal(!showEditLegalValutionModal);
    setEditData(obj);
  };

  const handleSearch = (e) => {
    /* let { roleContainer } = this.state;
        let array = [];
        roleContainer.map(res => {
            let roleName = res.roleName;
            if (roleName)
                if (res.roleName.match(e.target.value.toUpperCase())) {
                    array.push(res);
                }
        });
        this.setState({
            search: e.target.value,
            roleData: e.target.value ? array : roleContainer
        }); */
  };

  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box">
            {" "}
            Legal & Valuation Fee (Excl GST)
          </h5>
          <div className="d-flex align-items-center mt-5">
            {/* <button
                            disabled={false}
                            onClick={handleAddLegalValution}
                            className="add-btn ml-auto btn btn-info btn-lg"
                        >
                            {" "}
                    Add {" "}
                        </button> */}
            {showAddLegalValutionModal && (
              <AddEditLegalValution
                action={"add"}
                getLegalValution={getLegalValution}
                handleAddLegalValution={handleAddLegalValution}
              />
            )}
            {showEditLegalValutionModal && (
              <AddEditLegalValution
                action={"edit"}
                getLegalValution={getLegalValution}
                editData={editData}
                handleEditLegalValution={handleEditLegalValution}
              />
            )}
          </div>

          <div className={`mt-4 react-table`}>
            <ReactTable
              data={legalValutionData}
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default LegalValution;
