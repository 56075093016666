import React, { useState, useEffect } from 'react';
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddEditProcessingFee from "./AddEditProcessingFee";
import { formatIndianCurrency } from "../../../Utils/helperFunctions";
import { getAllProcessingFee } from "../../../Utils/Services/processingFee";
toast.configure();

let data = [
    {
        processingFee: "2",
        id: 1
    },
    {
        processingFee: "3",
        id: 2
    },
]
const Processing = (props) => {
    let [search, setSearch] = useState("");
    let [processingFeeData, setprocessingFeeData] = useState([]);
    let [showAddProcessingFeeModal, setshowAddProcessingFeeModal] = useState(false);
    let [showEditProcessingFeeModal, setshowEditProcessingFeeModal] = useState(false);
    let [editData, setEditData] = useState({});

    useEffect(() => {
        getProcessingFee();
    }, []);

    const getProcessingFee = () => {
        getAllProcessingFee().then((res) => {
            console.log("getAllProcessingFee", res)
            if (res.error) {
                return;
            }
            if (res.data && res.data.error) {
                toast.error(res.data.message, {
                    type: toast.TYPE.ERROR,
                    autoClose: 2000
                });
            } else {
               /*  toast.success(res.data.message, {
                    type: toast.TYPE.SUCCESS,
                    autoClose: 2000
                }); */
                setprocessingFeeData(res.data.data)
            }
        })
    }
    const columns = [
        {
            Header: "SR. No",
            Cell: ((data) => {
                // console.log("data...", data)
                return data.index + 1
            })
            /* Cell: ({ original }) => {
                return original.id && original.id ? original.id : null;
            } */
        },
        {
            Header: "Processing Fee",
            Cell: ({ original }) => {
                return original.processingfee && original.processingfee ? `${original.processingfee}%` : null;
            }
        },
        {
            Header: "Action",
            disabled: false,
            accessor: "delete",
            accessor: "edit",

            Cell: ({ original }) => {
                return (
                    <div
                        disabled={false}
                        style={{ display: "flex", justifyContent: "space-around" }}
                    >
                        <i
                            disabled={false}
                            onClick={() => handleEditProcessingFee(original)}
                            className="fas fa-user-edit"
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                );
            }
        }
    ];

    const handleAddProcessingFee = id => {
        setshowAddProcessingFeeModal(!showAddProcessingFeeModal);
    };

    const handleEditProcessingFee = obj => {
        setshowEditProcessingFeeModal(!showEditProcessingFeeModal);
        setEditData(obj)
    };

    const handleSearch = e => {
        /* let { roleContainer } = this.state;
        let array = [];
        roleContainer.map(res => {
            let roleName = res.roleName;
            if (roleName)
                if (res.roleName.match(e.target.value.toUpperCase())) {
                    array.push(res);
                }
        });
        this.setState({
            search: e.target.value,
            roleData: e.target.value ? array : roleContainer
        }); */
    };

    return (
        <div className="dash_grid">
            <ToastContainer />
            <LeftMenu />
            <main className="bg-white">
                <Header {...props} />
                <section className="container-fluid mb-5">
                    <h5 className="text-center mt-5 mx-auto user-box"> Processing Fee</h5>
                    <div className="d-flex align-items-center mt-5">
                       {/*  <button
                            disabled={false}
                            onClick={handleAddProcessingFee}
                            className="add-btn ml-auto btn btn-info btn-lg"
                        >
                            {" "}
                    Add {" "}
                        </button> */}
                        {showAddProcessingFeeModal && (
                            <AddEditProcessingFee
                                action={"add"}
                                getProcessingFee={getProcessingFee}
                                handleAddProcessingFee={handleAddProcessingFee}
                            />
                        )}
                        {showEditProcessingFeeModal && (
                            <AddEditProcessingFee
                                action={"edit"}
                                getProcessingFee={getProcessingFee}
                                editData={editData}
                                handleEditProcessingFee={handleEditProcessingFee}
                            />
                        )}
                    </div>

                    <div
                        className={`mt-4 react-table`}
                    >
                        <ReactTable
                            data={processingFeeData}
                            columns={columns}
                            defaultPageSize={10}
                        />
                    </div>
                </section>
            </main>
        </div>
    );
};

export default Processing;