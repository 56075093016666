import { get, post } from "../httpInterceptor";

// API for find all income multiplier

export const findallincomemultipliermaster = () => {
  return get(`/sarvagram-credit/master/findallincomemultipliermaster`).then(
    (res) => {
      console.log("RESPONSE :: findallincomemultipliermaster ::: ", res);
      return res;
    }
  );
};

// API for get all income multiplier

export const getAllIncomeMultiplier = () => {
  return get(`/sarvagram-credit/master/findallIncomeMultipliers`).then(
    (res) => {
      console.log("RESPONSE :: getAllIncomeMultiplier ::: ", res);
      return res;
    }
  );
};

// API for update income multiplier

export const updateIncomeMultiplierById = (obj) => {
  return post(
    `/sarvagram-credit/master/saveorupdateIncomeMultipliers`,
    obj,
    false
  ).then((res) => {
    console.log("updateIncomeMultiplierById", res);
    return res;
  });
};

// API for save income multiplier

export const savepincomemultipliermaster = (obj) => {
  return post(
    `/sarvagram-credit/master/savepincomemultipliermaster`,
    obj,
    false
  ).then((res) => {
    console.log("savepincomemultipliermaster", res);
    return res;
  });
};
