import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import ImageUploader from "react-images-upload";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { FileUrl } from "../../../../Utils/config";
import { viewDocument } from "../../../../Utils/Services/documents"

import {
  SpinnerLoader,
  FullScreenLoader,
} from "../../../../Components/Assets/Loader";
import { CloseSection } from "../../../../Components/Assets/CloseSection";
import { cloneDeep } from "lodash";
import {
  findAppraiserByApplication,
  saveOrUpdateGoldAppraiser,
} from "../../../../Utils/Services/goldAppraiser";
import { getAllBranch } from "../../../../Utils/Services/common";
import "../style.css";

toast.configure();

let panAvailabilityOption = [
  { id: "YES", label: "YES" },
  { id: "NO", label: "NO" },
];

let initForm = {
  panAvailability: "",
  companyPanNumber: "",
  dataOfEstablishment: "",
  shopName: "",
  apraiserCode: "",
  gstNumber: "",
  otherBankAppraiserWorking: "NO",
  otherBankAppraiserName: "",
  yearOfExperienceInGoldOrnaments: "",
  ownJewelleryShop: "NO",
  ownJewelleryShopName: "",
  goldSmith: "NO",
  governmentApprovedGoldSmith: "NO",
  degreeInGoldOrnament: "NO",
  instituteNameOfDegree: "",
  unifiedByLocalJewelleryAssociation: "NO",
  registeredAddress: {
    state: "",
    city: "",
    branch: "",
    pinCode: "",
    contactName: "",
    mobileNumber: "",
    emailId: "",
    address: "",
  },
  communicationAddress: {
    state: "",
    city: "",
    branch: "",
    pinCode: "",
    contactName: "",
    mobileNumber: "",
    emailId: "",
    address: "",
  },
  communicationAddressSameAsRegistered: false,
  bankAccountName: "",
  bankName: "",
  accountNumber: "",
  ifscCode: "",
  companyPanNumberVerified: false,
  bankVerified: false,
  remark: "",
};
class GoldApprovalMasterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchList: [],
      previousStatus: "",
      verifyingBank: false,
      key: Math.random(),
      form: cloneDeep(initForm),
      show: false,
      showInner: false,
      loading: false,
      invalidPanNumber: false,
      isVerifyingPan: false,
      errors: {
        remark: null,
        panAvailability: null,
        companyPanNumber: null,
        PanNumberError: null,
        dataOfEstablishment: null,
        shopName: null,
        apraiserCode: null,
        gstNumber: null,
        otherBankAppraiserName: null,
        yearOfExperienceInGoldOrnaments: null,
        ownJewelleryShopName: null,
        instituteNameOfDegree: null,
        accountNumber: null,
        bankAccountName: null,
        bankName: null,
        accountNumber: null,
        isValidIfsc: null,
        validAccountNumber: null,
        registeredAddress: {
          state: null,
          city: null,
          branch: null,
          pinCode: null,
          contactName: null,
          mobileNumber: null,
          emailId: null,
          validPincode: null,
          validEmail: null,
          validMobile: null,
          address: null,
        },
        communicationAddress: {
          state: null,
          city: null,
          branch: null,
          pinCode: null,
          contactName: null,
          mobileNumber: null,
          emailId: null,
          validPincode: null,
          validEmail: null,
          validMobile: null,
          address: null,
        },
      },
    };
  }

  componentDidMount = () => {
    this.handleShow();
    // console.log(".....", this.props)
    this.getAppraiserByAppicationId();
    this.getBranchList();
  };

  getBranchList = () => {
    getAllBranch().then((res) => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        return;
      } else {
        if (res.data && res.data.data && res.data.data.length > 0) {
          this.setState({ branchList: res.data.data });
        }
      }
    });
  };

  getAppraiserByAppicationId = () => {
    let { verifyObj } = this.props;
    this.setState({ loading: true });
    findAppraiserByApplication({ applicationNumber: verifyObj }).then((res) => {
      this.setState({ loading: false });
      if (res.error) {
        this.setState({ loading: false });

        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        // console.log("res.data/.///", res.data);
        if (
          res.data &&
          res.data.data &&
          Object.keys(res.data.data).length > 0
        ) {
          this.setState({
            form: res.data.data,
            key: Math.random(),
            previousStatus: res.data.data.status,
          });
        }
      }
    });
  };

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  handleViewDoc = (path) => {
		viewDocument(path);
	};

  handleClose = (close) => {
    if (!close) this.close();
    this.setState({ show: false });
    // this.props.addUser();
  };

  close = (close) => {
    this.setState({ showInner: !this.state.showInner });
  };

  handleChange = (e, name) => {
    console.log("handleChange", e, name);
    let { form } = this.state;
    let { id } = e;
    form[name] = id;
    this.setState({ form });
  };

  handlebranchSelectChange = (e, name) => {
    console.log("handlebranchSelectChange", e, name);
    let { form } = this.state;
    let { branchCode } = e;
    form[name].branch = branchCode;
    this.setState({ form });
  };

  onValidatebranch = (e, name) => {
    const { errors, form } = this.state;
    let value = form[name].branch;
    if (value === "" || value === null || value === undefined) {
      errors[name].branch = true;
      this.setState({ errors });
    } else {
      errors[name].branch = false;
      this.setState({ errors });
    }
  };

  handleRadioChange = (e, name) => {
    let { form } = this.state;
    console.log("handleRadioChange", e.target, form[name]);
    if (name == "communicationAddressSameAsRegistered") {
      form[name] = !form[name];
      this.setState({ form }, () => {
        let { form } = this.state;
        if (form.communicationAddressSameAsRegistered == true) {
          let { registeredAddress } = form;
          form.communicationAddress = cloneDeep(registeredAddress);
          this.setState({ form, key: Math.random() });
        } else {
          console.log("handleRadioChange else.....");

          form.communicationAddress = cloneDeep({
            state: "",
            city: "",
            branch: "",
            pinCode: "",
            contactName: "",
            mobileNumber: "",
            emailId: "",
            validPincode: "",
            validEmail: "",
            validMobile: "",
          });
          this.setState({ form, key: Math.random() });
        }
      });
    } else {
      if (name == "gstNumber" || name == "apraiserCode" || name == "ifscCode") {
        form[name] = e.target.value.toUpperCase();
      } else {
        form[name] = e.target.value;
      }
      this.setState({ form });
    }
  };

  handleDateOfEstablishment = (date, e) => {
    console.log("handleDateOfEstablishment", date, e);
    let { form } = this.state;
    form.dataOfEstablishment = date;
    this.setState({ form });
  };

  handlePan = (e) => {
    let { errors, form } = this.state;
    if (e.target.value.trim() !== "") {
      errors = { ...errors, PanNumberError: false };
    } else {
      errors = { ...errors, PanNumberError: true };
    }
    form.companyPanNumber = e.target.value.toUpperCase();
    this.setState({
      form,
      errors,
    });
  };

  restrictAlphabets = (e) => {
    const regx = "^[0-9]*$";
    if (e.key.match(regx)) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  handleAddressChange = (e, name, outerName) => {
    // console.log("handleAddressChange", e, name, outerName)
    let { form } = this.state;
    form[outerName][name] = e.target.value;
    this.setState({ form });
  };

  handleValidateAddressChange = (e, name, outerName) => {
    const { errors, form } = this.state;
    // console.log("handleValidateAddressChange", e, name, outerName, name == "emailId")
    let value = form[outerName][name];
    let mandatory = ["branch", "pinCode"];

    if (
      (value === "" || value === null || value === undefined) &&
      mandatory.includes(value)
    ) {
      errors[outerName][name] = true;
      this.setState({ errors });
    } else {
      errors[outerName][name] = false;
      this.setState({ errors });
    }

    if (name == "pinCode" && value && value.length !== 6) {
      errors[outerName].validPincode = false;
      this.setState({ errors });
    } else {
      errors[outerName].validPincode = false;
      this.setState({ errors });
    }

    if (name == "emailId") {
      let emailRegx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // console.log("handleValidateAddressChange", value, value && !value.match(emailRegx))
      if (value && !value.match(emailRegx)) {
        errors[outerName].validEmail = true;
        this.setState({ errors });
      } else {
        errors[outerName].validEmail = false;
        this.setState({ errors });
      }
    }

    if (name == "mobileNO") {
      let indianMobilenumber = /^[6789]\d{9}$/;
      if ((value && value.length < 10) || !value.match(indianMobilenumber)) {
        errors[outerName].validMobile = true;
        this.setState({ errors });
      } else {
        errors[outerName].validMobile = false;
        this.setState({ errors });
      }
    }
  };

  handleValidate = (e, name) => {
    console.log("shhh", e.target);

    const { errors, form } = this.state;
    let value = form[name];
    let nonMandatory = ["shopName", "dataOfEstablishment", "gstNumber"];
    if (
      (value === "" || value === null || value === undefined) &&
      !nonMandatory.includes(name)
    ) {
      errors[name] = true;
      this.setState({ errors });
    } else {
      errors[name] = false;
      this.setState({ errors });
    }

    if (name == "otherBankAppraiserWorking") {
      if (value == "YES" && form.otherBankAppraiserName == "") {
        errors.otherBankAppraiserName = true;
        this.setState({ errors });
      } else {
        errors.otherBankAppraiserName = false;
        this.setState({ errors });
      }
    }

    if (name == "ownJewelleryShop") {
      if (value == "YES" && form.ownJewelleryShopName == "") {
        errors.ownJewelleryShopName = true;
        this.setState({ errors });
      } else {
        errors.ownJewelleryShopName = false;
        this.setState({ errors });
      }
    }

    if (name == "degreeInGoldOrnament") {
      if (value == "YES" && form.instituteNameOfDegree == "") {
        errors.instituteNameOfDegree = true;
        this.setState({ errors });
      } else {
        errors.instituteNameOfDegree = false;
        this.setState({ errors });
      }
    }

    if (name === "ifscCode") {
      if (value.trim() == "") {
        this.setState({ errors: { ...errors, ifscCodeError: true } });
      } else {
        this.setState({ errors: { ...errors, ifscCodeError: false } });
      }
    }

    if (name === "panAvailability" && form[name] == "YES") {
      let { errors } = this.state;
      if (form.companyPanNumber == "") {
        errors.companyPanNumber = true;
        this.setState({
          errors,
        });
      } else if (form.companyPanNumber && form.companyPanNumber.length < 10) {
        this.setState({
          invalidPanNumber: true,
        });
      } else {
        errors.companyPanNumber = false;
        this.setState({
          invalidPanNumber: false,
          errors,
        });
      }
    }
    if (name === "panAvailability" && form[name] == "NO") {
      let { errors } = this.state;
      if (form.companyPanNumber == "") {
        errors.companyPanNumber = false;
        this.setState({
          errors,
        });
      } else {
        errors.companyPanNumber = false;
        this.setState({
          invalidPanNumber: false,
          errors,
        });
      }
    }

    if (name === "companyPanNumber") {
      var pan_value = value.toUpperCase();
      var reg = /([a-zA-Z]{3})([PCHFATBLJGE]{1})([a-zA-Z]{1})([0-9]{4})([a-zA-Z]{1})/;

      if (pan_value.match(reg)) {
        this.setState({
          invalidPanNumber: false,
        });
      } else {
        this.setState({
          invalidPanNumber: true,
        });
      }
    }

    if (name === "permanantAddress") {
      if (value && value.length > 700) {
        this.setState({
          permanantAddressError: true,
        });
      }
    }

    if (name == "accountNumber") {
      if (
        (e.target.value.trim().length == 9 ||
          e.target.value.trim().length > 9) &&
        (e.target.value.trim().length == 16 ||
          e.target.value.trim().length < 16)
      ) {
        errors.validAccountNumber = false;
        this.setState({ errors });
      } else {
        errors.validAccountNumber = true;
        this.setState({ errors });
      }
    }

    if (name == "ifscCode") {
      let verifyIfsc = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/;
      if (verifyIfsc.test(value)) {
        errors.isValidIfsc = false;
        this.setState({ errors });
      } else {
        errors.isValidIfsc = true;
        this.setState({ errors });
      }
    }

    //  ^[A-Za-z]{4}0[A-Z0-9a-z]{6}$
  };

  onDrop = (file, picture, name) => {
    let { errors } = this.state;
    console.log("file", file);
    console.log("picture", picture.length);
    console.log("name", name);

    /* if (picture.length > 0) {
            errors = { ...errors, [name + "Error"]: false };
        } else {
            errors = { ...errors, [name + "Error"]: true };
        }
        this.setState({ [name]: file, errors: errors }); */
  };

  handleApproveReject = (e, status) => {
    e.preventDefault();
    let { form, errors } = this.state;
    form.status = status;
    if (status == "APPROVED") {
      errors.remark = false;
      form.remark = "";
    } else {
      errors.remark = true;
    }
    this.setState({ form, errors });
  };

  handleSubmit = () => {
    let { form, errors } = this.state;
    let obj = form;
    if (form.status == "APPROVED") {
      obj.active = true;
    } else {
      obj.active = false;
    }
    this.setState({ loading: true });
    saveOrUpdateGoldAppraiser(obj).then((res) => {
      if (res.error) {
        this.setState({ loading: false });

        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        let msg =
          res.data &&
            res.data.data &&
            res.data.data.status &&
            res.data.data.status == "REJECTED"
            ? "Gold appraiser rejected successfully"
            : res.data &&
              res.data.data &&
              res.data.data.status &&
              res.data.data.status == "APPROVED"
              ? "Gold appraiser approved successfully"
              : "";
        toast.success(msg, {
          type: toast.TYPE.SUCCESS,
          autoClose: 4000,
        });
        this.setState({ loading: false, show: false });
        this.props.fetchAppraiser();
      }
    });
  };

  render() {
    let {
      showInner,
      show,
      loading,
      errors,
      form,
      invalidPanNumber,
      isVerifyingPan,
      key,
      previousStatus,
      verifyingBank,
      branchList,
    } = this.state;
    let { registeredAddress, communicationAddress } = form;
    let disableSave = false;
    if (
      (form &&
        form.status == "REJECTED" &&
        (form.remark == "" ||
          form.remark == null ||
          form.remark == undefined)) ||
      (form && form.status == "PENDING")
    ) {
      disableSave = true;
    }

    console.log(
      "sadsadas",
      form &&
      form.registeredAddress &&
      form.registeredAddress.branch &&
      branchList.filter(
        ({ branchCode }) => branchCode == form.registeredAddress.branch
      )[0]
    );
    return (
      <>
        {branchList && branchList.length > 0 ? (
          <>
            <div className="disable-div">
              <div className="disable-div"></div>
              <FullScreenLoader show={false} />
              <ToastContainer />
              <Modal
                className="add-user"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show && show}
                id="gold-appraiser"
                onHide={() => {
                  this.close();
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="w-100">
                    Gold appraiser master form
                    <span className="right"> {`${previousStatus}`} </span>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  className={`p-4 `}
                  style={{ background: "#f8f9fa" }}
                >
                  <div className="row">
                    <section className="tab-body mt-3 mb-3">
                      <div className="row">
                        <h5 className="col-md-12 text-center mb-4">
                          Company Details
                        </h5>
                        <div className="col-md-4">
                          <label>
                            {<i className="text-danger">*</i>} PAN availability
                          </label>
                          <Select
                            defaultValue={
                              panAvailabilityOption.filter(
                                ({ id }) => id == form.panAvailability
                              )[0]
                            }
                            onBlur={(e) =>
                              this.handleValidate(e, "panAvailability")
                            }
                            onChange={(e) => {
                              this.handleChange(e, "panAvailability");
                            }}
                            name="panAvailability"
                            options={panAvailabilityOption}
                            placeholder="Please Select Pan Availability"
                            valueKey="id"
                            labelKey="label"
                            getOptionLabel={(option) => option["label"]}
                            getOptionValue={(option) => option["id"]}
                            className={`${errors.panAvailability ? "error-input-border" : ""
                              } `}
                            isDisabled={true}
                            key={key}
                          />
                          {errors.panAvailability && (
                            <span className="errorMsg">
                              Please Select Pan Availability
                            </span>
                          )}
                        </div>
                        <div className="form-group col-md-4 ">
                          <label>
                            {form && form.panAvailability == "YES" && (
                              <i className="text-danger">*</i>
                            )}{" "}
                            Company PAN Number
                          </label>
                          <div className="d-flex">
                            <div class="inner-addon right-addon flex-grow-1">
                              {form.companyPanNumberVerified &&
                                form.companyPanNumberVerified == "Active" && (
                                  <i class="glyphicon glyphicon-ok"></i>
                                )}
                              <input
                                type="text"
                                pattern="\d*"
                                maxLength="10"
                                name="companyPanNumber"
                                value={form.companyPanNumber}
                                onChange={(e) => {
                                  let regex = /[\!\\/@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                                  if (e.target.value.match(regex) == null)
                                    this.handlePan(e);
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(e, "companyPanNumber")
                                }
                                placeholder="Company PAN Number"
                                className={`form-input ${errors.PanNumberError || invalidPanNumber
                                    ? "error-input-border"
                                    : ""
                                  } `}
                                disabled={true}
                              />
                              {errors.companyPanNumber && (
                                <span className="errorMsg">
                                  Please Enter PAN Number
                                </span>
                              )}
                              {!errors.PanNumberError && invalidPanNumber && (
                                <span className="errorMsg">
                                  Please enter valid PAN
                                </span>
                              )}
                            </div>
                            <div className="pl-2 ">
                              <button
                                type="button"
                                disabled={true}
                                class="btn btn-primary btn-sm"
                                style={{ width: 100 }}
                                onClick={this.handleVerifyPan}
                              >
                                {form && form.companyPanNumberVerified
                                  ? "Verified"
                                  : "Verify"}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group overflow-visible col-md-4">
                          <label> {"Date of establishment"}</label>
                          <div class="inner-addon right-addon">
                            <DatePicker
                              selected={
                                form.dataOfEstablishment &&
                                new Date(form.dataOfEstablishment)
                              }
                              onBlur={(e) =>
                                this.handleValidate(e, "dataOfEstablishment")
                              }
                              onChange={this.handleDateOfEstablishment}
                              dateFormat="yyyy-MM-dd"
                              placeholderText="YYYY-MM-DD"
                              showYearDropdown
                              showMonthDropdown
                              className={`form-input ${errors.dataOfEstablishment
                                  ? "error-input-border"
                                  : ""
                                } `}
                              disabled={true}
                            />
                          </div>
                          {errors.dataOfEstablishment && (
                            <span className="errorMsg">
                              Please Select Date Of Establishment
                            </span>
                          )}
                        </div>
                        <div className="form-group col-md-4 ">
                          <label>Company / Shop name</label>
                          <div class="inner-addon right-addon">
                            <input
                              type="text"
                              pattern="\d*"
                              name="shopName"
                              value={form.shopName}
                              onChange={(e) => {
                                this.handleChange(e, "shopName");
                              }}
                              onBlur={(e) => this.handleValidate(e, "shopName")}
                              placeholder="Company / Shop name"
                              className={`form-input ${errors.shopName ? "error-input-border" : ""
                                } `}
                              disabled={true}
                            />
                            {errors.shopName && (
                              <span className="errorMsg">
                                Please Enter Company/Shop name
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="form-group col-md-4 ">
                          <label>
                            <i className="text-danger">*</i> Appraiser code
                          </label>
                          <div class="inner-addon right-addon">
                            <input
                              type="text"
                              pattern="\d*"
                              name="apraiserCode"
                              value={form.apraiserCode}
                              onChange={(e) => {
                                let regex = /[\/\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                                if (!regex.test(e.target.value))
                                  this.handleRadioChange(e, "apraiserCode");
                              }}
                              onBlur={(e) =>
                                this.handleValidate(e, "apraiserCode")
                              }
                              placeholder="Appraiser code"
                              className={`form-input ${errors.apraiserCode ? "error-input-border" : ""
                                } `}
                              disabled={true}
                            />
                            {errors.apraiserCode && (
                              <span className="errorMsg">
                                Please Enter Appraiser code
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="form-group col-md-4 ">
                          <label>
                            {/* <i className="text-danger">*</i>  */}GST number
                          </label>
                          <div class="inner-addon right-addon">
                            <input
                              type="text"
                              pattern="\d*"
                              name="gstNumber"
                              value={form.gstNumber}
                              onChange={(e) => {
                                let regex = /[\/\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                                if (!regex.test(e.target.value))
                                  this.handleRadioChange(e, "gstNumber");
                              }}
                              onBlur={(e) =>
                                this.handleValidate(e, "gstNumber")
                              }
                              placeholder="GST number"
                              className={`form-input ${errors.gstNumber ? "error-input-border" : ""
                                } `}
                              disabled={true}
                            />
                            {errors.gstNumber && (
                              <span className="errorMsg">
                                Please Enter GST number
                              </span>
                            )}
                          </div>
                        </div>
                        {/* years of exp */}
                        <div className="p-0 form-group col-12">
                          <div className="col-md-8 col-sm-6 col-lg-8">
                            <label>
                              {" "}
                              <i className="text-danger">*</i> Years of
                              experience in gold ornaments
                            </label>
                          </div>
                          <div className="inner-addon right-addon col-md-4 col-sm-6 col-lg-4">
                            <input
                              type="text"
                              pattern="\d*"
                              name="yearOfExperienceInGoldOrnaments"
                              value={form.yearOfExperienceInGoldOrnaments}
                              onChange={(e) => {
                                this.handleRadioChange(
                                  e,
                                  "yearOfExperienceInGoldOrnaments"
                                );
                              }}
                              onBlur={(e) =>
                                this.handleValidate(
                                  e,
                                  "yearOfExperienceInGoldOrnaments"
                                )
                              }
                              placeholder="Years of experience in gold ornaments"
                              className={`form-input ${errors.yearOfExperienceInGoldOrnaments
                                  ? "error-input-border"
                                  : ""
                                } `}
                              disabled={true}
                            />
                            {errors.yearOfExperienceInGoldOrnaments && (
                              <span className="errorMsg">
                                Please Enter Years of experience in gold
                                ornaments
                              </span>
                            )}
                          </div>
                        </div>
                        {/* appraiser working */}
                        <div className="p-0  form-group col-md-12 col-lg-12 col-sm-12">
                          <div className="form-check col-md-4 col-lg-4 col-sm-6">
                            <label>
                              {" "}
                              <i className="text-danger">*</i>Any other Bank
                              Appraiser is working
                            </label>
                          </div>
                          <div className="form-check col-md-2 col-lg-2 col-sm-3">
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="otherBankAppraiserWorking"
                                id="otherBankAppraiserWorkingYes"
                                value="YES"
                                onChange={(e) => {
                                  this.handleRadioChange(
                                    e,
                                    "otherBankAppraiserWorking"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(
                                    e,
                                    "otherBankAppraiserWorking"
                                  )
                                }
                                disabled={true}
                                checked={
                                  form.otherBankAppraiserWorking == "YES"
                                }
                              />
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <label
                                className="form-check-label"
                                for="otherBankAppraiserWorkingYes"
                              >
                                YES
                              </label>
                            </div>
                          </div>
                          <div className="form-check col-md-2 col-lg-2 col-sm-3">
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="otherBankAppraiserWorking"
                                id="otherBankAppraiserWorkingNo"
                                value="NO"
                                disabled={true}
                                checked={form.otherBankAppraiserWorking == "NO"}
                                onChange={(e) => {
                                  this.handleRadioChange(
                                    e,
                                    "otherBankAppraiserWorking"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(
                                    e,
                                    "otherBankAppraiserWorking"
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <label
                                className="form-check-label"
                                for="otherBankAppraiserWorkingNo"
                              >
                                NO
                              </label>
                            </div>
                          </div>
                          <div className="form-group col-md-4">
                            <textarea
                              type="text"
                              pattern="\d*"
                              maxLength="700"
                              className="form-input"
                              name="otherBankAppraiserName"
                              value={form.otherBankAppraiserName}
                              onChange={(e) => {
                                this.handleChange(e, "otherBankAppraiserName");
                              }}
                              onBlur={(e) =>
                                this.handleValidate(e, "otherBankAppraiserName")
                              }
                              placeholder="Enter Bank Name"
                              disabled={true}
                              className={`form-input ${errors.otherBankAppraiserName
                                  ? "error-input-border"
                                  : ""
                                } `}
                              autocomplete="false"
                            />

                            {errors.otherBankAppraiserName && (
                              <span className="errorMsg">
                                Please Enter bank name
                              </span>
                            )}
                          </div>
                        </div>
                        {/* own shop */}
                        <div className="p-0  form-group col-md-12 col-lg-12 col-sm-12">
                          <div className="form-check col-md-4 col-lg-4 col-sm-6">
                            <label>
                              <i className="text-danger">*</i> Own jwellery shop
                            </label>
                          </div>
                          <div className="form-check col-md-2 col-lg-2 col-sm-3">
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="ownJewelleryShop"
                                id="ownJewelleryShopYes"
                                value="YES"
                                onChange={(e) => {
                                  this.handleRadioChange(e, "ownJewelleryShop");
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(e, "ownJewelleryShop")
                                }
                                disabled={true}
                                checked={form.ownJewelleryShop == "YES"}
                              />
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <label
                                className="form-check-label"
                                for="ownJewelleryShopYes"
                              >
                                YES
                              </label>
                            </div>
                          </div>
                          <div className="form-check col-md-2 col-lg-2 col-sm-3">
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="ownJewelleryShop"
                                id="ownJewelleryShopNo"
                                value="NO"
                                disabled={true}
                                checked={form.ownJewelleryShop == "NO"}
                                onChange={(e) => {
                                  this.handleRadioChange(e, "ownJewelleryShop");
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(e, "ownJewelleryShop")
                                }
                              />
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <label
                                className="form-check-label"
                                for="ownJewelleryShopNo"
                              >
                                NO
                              </label>
                            </div>
                          </div>
                          <div className="form-group col-md-4">
                            <textarea
                              type="text"
                              pattern="\d*"
                              maxLength="700"
                              className="form-input"
                              name="ownJewelleryShopName"
                              value={form.ownJewelleryShopName}
                              onChange={(e) => {
                                this.handleRadioChange(
                                  e,
                                  "ownJewelleryShopName"
                                );
                              }}
                              onBlur={(e) =>
                                this.handleValidate(e, "ownJewelleryShopName")
                              }
                              placeholder="Enter shop name"
                              disabled={true}
                              className={`form-input ${errors.ownJewelleryShopName
                                  ? "error-input-border"
                                  : ""
                                } `}
                              autocomplete="false"
                            />

                            {errors.ownJewelleryShopName && (
                              <span className="errorMsg">
                                Please Enter shop name
                              </span>
                            )}
                          </div>
                        </div>
                        {/* degree/diploma */}
                        <div className="p-0  form-group col-md-12 col-lg-12 col-sm-12">
                          <div className="form-check col-md-4 col-lg-4 col-sm-6">
                            <label>
                              <i className="text-danger">*</i> Degree / Diploma
                              in gold ornament valuation
                            </label>
                          </div>
                          <div className="form-check col-md-2 col-lg-2 col-sm-3">
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="degreeInGoldOrnament"
                                id="degreeInGoldOrnamentYes"
                                value="YES"
                                onChange={(e) => {
                                  this.handleRadioChange(
                                    e,
                                    "degreeInGoldOrnament"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(e, "degreeInGoldOrnament")
                                }
                                disabled={true}
                                checked={form.degreeInGoldOrnament == "YES"}
                              />
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <label
                                className="form-check-label"
                                for="degreeInGoldOrnamentYes"
                              >
                                YES
                              </label>
                            </div>
                          </div>
                          <div className="form-check col-md-2 col-lg-2 col-sm-3">
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="degreeInGoldOrnament"
                                id="degreeInGoldOrnamentNo"
                                value="NO"
                                disabled={true}
                                checked={form.degreeInGoldOrnament == "NO"}
                                onChange={(e) => {
                                  this.handleRadioChange(
                                    e,
                                    "degreeInGoldOrnament"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(e, "degreeInGoldOrnament")
                                }
                              />
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <label
                                className="form-check-label"
                                for="degreeInGoldOrnamentNo"
                              >
                                NO
                              </label>
                            </div>
                          </div>
                          <div className="form-group col-md-4">
                            <textarea
                              type="text"
                              pattern="\d*"
                              maxLength="700"
                              className="form-input"
                              name="instituteNameOfDegree"
                              value={form.instituteNameOfDegree}
                              onChange={(e) => {
                                this.handleRadioChange(
                                  e,
                                  "instituteNameOfDegree"
                                );
                              }}
                              onBlur={(e) =>
                                this.handleValidate(e, "instituteNameOfDegree")
                              }
                              placeholder="Enter institute name"
                              disabled={true}
                              className={`form-input ${errors.instituteNameOfDegree
                                  ? "error-input-border"
                                  : ""
                                } `}
                              autocomplete="false"
                            />

                            {errors.instituteNameOfDegree && (
                              <span className="errorMsg">
                                Please Enter institute name
                              </span>
                            )}
                          </div>
                        </div>
                        {/* gold smith */}
                        <div className="p-0  form-group col-md-12 col-lg-12 col-sm-12">
                          <div className="form-check col-md-4 col-lg-4 col-sm-6">
                            <label>
                              <i className="text-danger">*</i> Gold smith
                            </label>
                          </div>
                          <div className="form-check col-md-2 col-lg-2 col-sm-3">
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="goldSmith"
                                id="goldSmithYes"
                                value="YES"
                                onChange={(e) => {
                                  this.handleRadioChange(e, "goldSmith");
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(e, "goldSmith")
                                }
                                disabled={true}
                                checked={form.goldSmith == "YES"}
                              />
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <label
                                className="form-check-label"
                                for="goldSmithYes"
                              >
                                YES
                              </label>
                            </div>
                          </div>
                          <div className="form-check col-md-2 col-lg-2 col-sm-3">
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="goldSmith"
                                id="goldSmithNo"
                                value="NO"
                                disabbled={true}
                                checked={form.goldSmith == "NO"}
                                onChange={(e) => {
                                  this.handleRadioChange(e, "goldSmith");
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(e, "goldSmith")
                                }
                              />
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <label
                                className="form-check-label"
                                for="goldSmithNo"
                              >
                                NO
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* gov apprv gold smith */}
                        <div className="p-0  form-group col-md-12 col-lg-12 col-sm-12">
                          <div className="form-check col-md-4 col-lg-4 col-sm-6">
                            <label>
                              <i className="text-danger">*</i> Government
                              approved gold smith
                            </label>
                          </div>
                          <div className="form-check col-md-2 col-lg-2 col-sm-3">
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="governmentApprovedGoldSmith"
                                id="governmentApprovedGoldSmithYes"
                                value="YES"
                                onChange={(e) => {
                                  this.handleRadioChange(
                                    e,
                                    "governmentApprovedGoldSmith"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(
                                    e,
                                    "governmentApprovedGoldSmith"
                                  )
                                }
                                disabled={true}
                                checked={
                                  form.governmentApprovedGoldSmith == "YES"
                                }
                              />
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <label
                                className="form-check-label"
                                for="governmentApprovedGoldSmithYes"
                              >
                                YES
                              </label>
                            </div>
                          </div>
                          <div className="form-check col-md-2 col-lg-2 col-sm-3">
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="governmentApprovedGoldSmith"
                                id="governmentApprovedGoldSmithNo"
                                value="NO"
                                disabled={true}
                                checked={
                                  form.governmentApprovedGoldSmith == "NO"
                                }
                                onChange={(e) => {
                                  this.handleRadioChange(
                                    e,
                                    "governmentApprovedGoldSmith"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(
                                    e,
                                    "governmentApprovedGoldSmith"
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <label
                                className="form-check-label"
                                for="governmentApprovedGoldSmithNo"
                              >
                                NO
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* unified */}
                        <div className="p-0  form-group col-md-12 col-lg-12 col-sm-12">
                          <div className="form-check col-md-4 col-lg-4 col-sm-6">
                            <label>
                              <i className="text-danger">*</i> Unified by local
                              jewellery association
                            </label>
                          </div>
                          <div className="form-check col-md-2 col-lg-2 col-sm-3">
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="unifiedByLocalJewelleryAssociation"
                                id="unifiedByLocalJewelleryAssociationYes"
                                value="YES"
                                onChange={(e) => {
                                  this.handleRadioChange(
                                    e,
                                    "unifiedByLocalJewelleryAssociation"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(
                                    e,
                                    "unifiedByLocalJewelleryAssociation"
                                  )
                                }
                                disabled={true}
                                checked={
                                  form.unifiedByLocalJewelleryAssociation ==
                                  "YES"
                                }
                              />
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <label
                                className="form-check-label"
                                for="unifiedByLocalJewelleryAssociationYes"
                              >
                                YES
                              </label>
                            </div>
                          </div>
                          <div className="form-check col-md-2 col-lg-2 col-sm-3">
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="unifiedByLocalJewelleryAssociation"
                                id="unifiedByLocalJewelleryAssociationNo"
                                value="NO"
                                disabled={true}
                                checked={
                                  form.unifiedByLocalJewelleryAssociation ==
                                  "NO"
                                }
                                onChange={(e) => {
                                  this.handleRadioChange(
                                    e,
                                    "unifiedByLocalJewelleryAssociation"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(
                                    e,
                                    "unifiedByLocalJewelleryAssociation"
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6">
                              <label
                                className="form-check-label"
                                for="unifiedByLocalJewelleryAssociationNo"
                              >
                                NO
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    {/* address section */}

                    <section className="tab-body mt-3 mb-3">
                      <div className="row">
                        <h5 className="col-md-12 text-center mb-4">
                          Address Details
                        </h5>
                      </div>
                      <section className="tab-body mt-3 mb-3">
                        <div className="row">
                          <h5 className="col-md-12 text-center mb-4">
                            Registered Address
                          </h5>
                          <div className="form-group col-md-4 ">
                            <label>
                              <i className="text-danger">*</i> Pin Code
                            </label>
                            <input
                              type="text"
                              pattern="\d*"
                              maxLength="6"
                              className="form-input"
                              name="pinCode"
                              value={
                                form &&
                                form.registeredAddress &&
                                form.registeredAddress.pinCode
                              }
                              onKeyPress={this.restrictAlphabets}
                              onChange={(e) => {
                                this.handleAddressChange(
                                  e,
                                  "pinCode",
                                  "registeredAddress"
                                );
                              }}
                              onBlur={(e) =>
                                this.handleValidateAddressChange(
                                  e,
                                  "pinCode",
                                  "registeredAddress"
                                )
                              }
                              placeholder="Enter Pin Code"
                              className={`form-input ${errors.registeredAddress.pinCode
                                  ? "error-input-border"
                                  : ""
                                } `}
                              id="pincode"
                              disabled={true}
                            />
                            {errors.registeredAddress.pinCode && (
                              <span className="errorMsg">
                                Please Enter Pincode
                              </span>
                            )}
                            {!errors.registeredAddress.pinCode &&
                              errors.registeredAddress.validPincode && (
                                <span className="errorMsg">
                                  Please Enter Valid Pincode
                                </span>
                              )}
                          </div>
                          <div className="form-group col-md-4">
                            <label>City</label>
                            <input
                              type="text"
                              pattern="\d*"
                              className="form-input"
                              name="city"
                              value={
                                form &&
                                form.registeredAddress &&
                                form.registeredAddress.city
                              }
                              placeholder="Enter City"
                              id="city"
                              disabled={true}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label>State</label>
                            <input
                              type="text"
                              pattern="\d*"
                              className="form-input"
                              name="state"
                              value={
                                form &&
                                form.registeredAddress &&
                                form.registeredAddress.state
                              }
                              placeholder="Enter State"
                              className={`form-input ${errors.registeredAddress.state
                                  ? "error-input-border"
                                  : ""
                                } `}
                              id="state"
                              disabled={true}
                            />
                          </div>
                         {/* <div className=" col-md-4">
                            <label>
                              <i className="text-danger">*</i> Branch
                            </label>
                            <Select
                              defaultValue={
                                form &&
                                form.registeredAddress &&
                                form.registeredAddress.branch &&
                                branchList.filter(
                                  ({ branchCode }) =>
                                    branchCode == form.registeredAddress.branch
                                )[0]
                              }
                              onChange={(e) => {
                                this.handlebranchSelectChange(
                                  e,
                                  "registeredAddress"
                                );
                              }}
                              key={key}
                              onBlur={(e) =>
                                this.onValidatebranch(e, "registeredAddress")
                              }
                              // isMulti
                              options={branchList}
                              name="branch"
                              placeholder="Branch"
                              valueKey="branchCode"
                              labelKey="branchCode"
                              getOptionLabel={(option) => option["branchCode"]}
                              getOptionValue={(option) => option["branchCode"]}
                              className={`${errors.registeredAddress.branch
                                  ? "error-input-border"
                                  : ""
                                } `}
                              isDisabled={true}
                            />
                            {errors.registeredAddress.branch && (
                              <span className="errorMsg">
                                Please select Branch
                              </span>
                            )}
                            </div>*/}
                             <div className="form-group col-md-4">
                            <label>Branch</label>
                            <input
                              type="text"
                              pattern="\d*"
                              className="form-input"
                              name="branch"
                              value={
                                form &&
                                form.registeredAddress &&
                                form.registeredAddress.branch
                              }
                              placeholder="Enter Branch"
                              id="branch"
                              disabled={true}
                            />
                          </div>
                          <div className="form-group col-md-4 ">
                            <label>Contact Name</label>
                            <input
                              type="text"
                              pattern="\d*"
                              maxLength="100"
                              className="form-input"
                              name="contactName"
                              value={
                                form &&
                                form.registeredAddress &&
                                form.registeredAddress.contactName
                              }
                              onChange={(e) => {
                                this.handleAddressChange(
                                  e,
                                  "contactName",
                                  "registeredAddress"
                                );
                              }}
                              onBlur={(e) =>
                                this.handleValidateAddressChange(
                                  e,
                                  "contactName",
                                  "registeredAddress"
                                )
                              }
                              placeholder="Enter Contact Name"
                              className={`form-input ${errors.registeredAddress.contactName
                                  ? "error-input-border"
                                  : ""
                                } `}
                              disabled={true}
                            />
                            {errors.registeredAddress.contactName && (
                              <span className="errorMsg">
                                Please enter Contact Name
                              </span>
                            )}
                          </div>

                          <div className="form-group col-md-4  ">
                            <label className="">Mobile Number</label>
                            <div class="inner-addon right-addon">
                              {/* {mobileVerifyLoading && (
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    )} */}
                              <input
                                type="text"
                                pattern="\d*"
                                maxLength="10"
                                className="form-input"
                                name="mobileNumber"
                                value={
                                  form &&
                                  form.registeredAddress &&
                                  form.registeredAddress.mobileNumber
                                }
                                onKeyPress={this.restrictAlphabets}
                                onChange={(e) => {
                                  this.handleAddressChange(
                                    e,
                                    "mobileNumber",
                                    "registeredAddress"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidateAddressChange(
                                    e,
                                    "mobileNumber",
                                    "registeredAddress"
                                  )
                                }
                                placeholder="Enter Mobile Number"
                                className={`form-input ${errors.registeredAddress.mobileNumber
                                    ? "error-input-border"
                                    : ""
                                  } `}
                                disabled={true}
                              />
                            </div>

                            {errors.registeredAddress.mobileNumber && (
                              <span className="errorMsg">
                                Please enter Mobile Number
                              </span>
                            )}
                            {!errors.registeredAddress.mobileNumber &&
                              errors.registeredAddress.validMobile && (
                                <span className="errorMsg">
                                  Please enter valid Mobile Number
                                </span>
                              )}
                          </div>
                          <div className="form-group col-md-4 ">
                            <label> Email ID</label>
                            <div class="inner-addon right-addon">
                              {/* {emailVerifyLoading && (
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    )} */}
                              <input
                                type="text"
                                pattern="\d*"
                                maxLength="50"
                                className="form-input"
                                name="emailId"
                                value={
                                  form &&
                                  form.registeredAddress &&
                                  form.registeredAddress.emailId
                                }
                                onChange={(e) => {
                                  this.handleAddressChange(
                                    e,
                                    "emailId",
                                    "registeredAddress"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidateAddressChange(
                                    e,
                                    "emailId",
                                    "registeredAddress"
                                  )
                                }
                                placeholder="Enter Email Address"
                                className={`form-input ${errors.registeredAddress.emailId ||
                                    errors.registeredAddress.validEmail
                                    ? "error-input-border"
                                    : ""
                                  } `}
                                disabled={true}
                              />
                            </div>
                            {errors.registeredAddress.emailId && (
                              <span className="errorMsg">
                                Please enter Email Address
                              </span>
                            )}
                            {errors.registeredAddress.validEmail && (
                              <span className="errorMsg">
                                Please enter valid Email Address
                              </span>
                            )}
                          </div>
                          <div className="form-group col-md-4">
                            <label>
                              <i className="text-danger">*</i>
                              Address
                            </label>
                            <div class="inner-addon right-addon">
                              <textarea
                                type="text"
                                pattern="\d*"
                                maxLength="700"
                                className="form-input"
                                name="address"
                                value={
                                  form &&
                                  form.communicationAddress &&
                                  form.communicationAddress.address
                                }
                                onChange={(e) => {
                                  this.handleAddressChange(
                                    e,
                                    "address",
                                    "communicationAddress"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidateAddressChange(
                                    e,
                                    "address",
                                    "communicationAddress"
                                  )
                                }
                                placeholder="Enter Address"
                                disabled={true}
                                className={`form-input ${errors.address ? "error-input-border" : ""
                                  } `}
                                autocomplete="false"
                              />

                              {errors.address && (
                                <span className="errorMsg">
                                  Please Enter Address
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </section>
                      {/* communication address */}
                      <section className="tab-body mt-3 mb-3">
                        <div className="row">
                          <h5 className="col-md-12 text-center mb-4">
                            Communication Address
                          </h5>
                          <div className="form-group col-md-4 ">
                            <label>
                              <i className="text-danger">*</i> Pin Code
                            </label>
                            <input
                              type="text"
                              pattern="\d*"
                              maxLength="6"
                              className="form-input"
                              name="pinCode"
                              value={
                                form &&
                                communicationAddress &&
                                communicationAddress.pinCode
                              }
                              onKeyPress={this.restrictAlphabets}
                              onChange={(e) => {
                                this.handleAddressChange(
                                  e,
                                  "pinCode",
                                  "communicationAddress"
                                );
                              }}
                              onBlur={(e) =>
                                this.handleValidateAddressChange(
                                  e,
                                  "pinCode",
                                  "communicationAddress"
                                )
                              }
                              placeholder="Enter Pin Code"
                              className={`form-input ${errors.communicationAddress.pinCode
                                  ? "error-input-border"
                                  : ""
                                } `}
                              id="pincode"
                              disabled={true}
                            />
                            {errors.communicationAddress.pinCode && (
                              <span className="errorMsg">
                                Please Enter Pincode
                              </span>
                            )}
                            {!errors.communicationAddress.pinCode &&
                              errors.communicationAddress.validPincode && (
                                <span className="errorMsg">
                                  Please Enter Valid Pincode
                                </span>
                              )}
                          </div>
                          <div className="form-group col-md-4">
                            <label>City</label>
                            <input
                              type="text"
                              pattern="\d*"
                              className="form-input"
                              name="city"
                              value={
                                form &&
                                communicationAddress &&
                                communicationAddress.city
                              }
                              placeholder="Enter City"
                              id="city"
                              disabled={true}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label>State</label>
                            <input
                              type="text"
                              pattern="\d*"
                              className="form-input"
                              name="state"
                              value={
                                form &&
                                communicationAddress &&
                                communicationAddress.state
                              }
                              placeholder="Enter State"
                              className={`form-input ${errors.communicationAddress.state
                                  ? "error-input-border"
                                  : ""
                                } `}
                              id="state"
                              disabled={true}
                            />
                          </div>
                          
                         {/* <div className=" col-md-4">
                            <label>
                              <i className="text-danger">*</i> Branch
                            </label>
                            <Select
                              defaultValue={
                                form &&
                                communicationAddress &&
                                communicationAddress.branch &&
                                branchList.filter(
                                  ({ branchCode }) =>
                                    branchCode == communicationAddress.branch
                                )[0]
                              }
                              onChange={(e) => {
                                this.handlebranchSelectChange(
                                  e,
                                  "communicationAddress"
                                );
                              }}
                              onBlur={(e) =>
                                this.onValidatebranch(e, "communicationAddress")
                              }
                              // isMulti
                              options={branchList}
                              name="branch"
                              placeholder="Branch"
                              valueKey="branchCode"
                              labelKey="branchCode"
                              getOptionLabel={(option) => option["branchCode"]}
                              getOptionValue={(option) => option["branchCode"]}
                              className={`${errors.communicationAddress.branch
                                  ? "error-input-border"
                                  : ""
                                } `}
                              isDisabled={true}
                              key={key}
                            />
                            {errors.communicationAddress.branch && (
                              <span className="errorMsg">
                                Please select Branch
                              </span>
                            )}
                            </div>*/}
                             <div className="form-group col-md-4">
                            <label>Branch</label>
                            <input
                              type="text"
                              pattern="\d*"
                              className="form-input"
                              name="branch"
                              value={
                                form &&
                                communicationAddress &&
                                communicationAddress.branch
                              }
                              placeholder="Enter Branch"
                              id="branch"
                              disabled={true}
                            />
                          </div>
                          <div className="form-group col-md-4 ">
                            <label>Contact Name</label>
                            <input
                              type="text"
                              pattern="\d*"
                              maxLength="100"
                              className="form-input"
                              name="contactName"
                              value={
                                form &&
                                communicationAddress &&
                                communicationAddress.contactName
                              }
                              onChange={(e) => {
                                this.handleAddressChange(
                                  e,
                                  "contactName",
                                  "communicationAddress"
                                );
                              }}
                              onBlur={(e) =>
                                this.handleValidateAddressChange(
                                  e,
                                  "contactName",
                                  "communicationAddress"
                                )
                              }
                              placeholder="Enter Contact Name"
                              className={`form-input ${errors.communicationAddress.contactName
                                  ? "error-input-border"
                                  : ""
                                } `}
                              disabled={true}
                            />
                            {errors.communicationAddress.contactName && (
                              <span className="errorMsg">
                                Please enter Contact Name
                              </span>
                            )}
                          </div>

                          <div className="form-group col-md-4  ">
                            <label className="">Mobile Number</label>
                            <div class="inner-addon right-addon">
                              {/* {mobileVerifyLoading && (
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    )} */}
                              <input
                                type="text"
                                pattern="\d*"
                                maxLength="10"
                                className="form-input"
                                name="mobileNumber"
                                value={
                                  form &&
                                  communicationAddress &&
                                  communicationAddress.mobileNumber
                                }
                                onKeyPress={this.restrictAlphabets}
                                onChange={(e) => {
                                  this.handleAddressChange(
                                    e,
                                    "mobileNumber",
                                    "communicationAddress"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidateAddressChange(
                                    e,
                                    "mobileNumber",
                                    "communicationAddress"
                                  )
                                }
                                placeholder="Enter Mobile Number"
                                className={`form-input ${errors.communicationAddress.mobileNumber
                                    ? "error-input-border"
                                    : ""
                                  } `}
                                disabled={true}
                              />
                            </div>

                            {errors.communicationAddress.mobileNumber && (
                              <span className="errorMsg">
                                Please enter Mobile Number
                              </span>
                            )}
                            {!errors.communicationAddress.mobileNumber &&
                              errors.communicationAddress.validMobile && (
                                <span className="errorMsg">
                                  Please enter valid Mobile Number
                                </span>
                              )}
                          </div>
                          <div className="form-group col-md-4 ">
                            <label> Email ID</label>
                            <div class="inner-addon right-addon">
                              {/* {emailVerifyLoading && (
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    )} */}
                              <input
                                type="text"
                                pattern="\d*"
                                maxLength="50"
                                className="form-input"
                                name="emailId"
                                value={
                                  form &&
                                  communicationAddress &&
                                  communicationAddress.emailId
                                }
                                onChange={(e) => {
                                  this.handleAddressChange(
                                    e,
                                    "emailId",
                                    "communicationAddress"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidateAddressChange(
                                    e,
                                    "emailId",
                                    "communicationAddress"
                                  )
                                }
                                placeholder="Enter Email Address"
                                className={`form-input ${errors.communicationAddress.emailId ||
                                    errors.communicationAddress.validEmail
                                    ? "error-input-border"
                                    : ""
                                  } `}
                                disabled={true}
                              />
                            </div>
                            {errors.communicationAddress.emailId && (
                              <span className="errorMsg">
                                Please enter Email Address
                              </span>
                            )}
                            {errors.communicationAddress.validEmail && (
                              <span className="errorMsg">
                                Please enter valid Email Address
                              </span>
                            )}
                          </div>
                          <div className="form-group col-md-4">
                            <label>
                              <i className="text-danger">*</i>
                              Address
                            </label>
                            <div class="inner-addon right-addon">
                              <textarea
                                type="text"
                                pattern="\d*"
                                maxLength="700"
                                className="form-input"
                                name="address"
                                value={
                                  form &&
                                  form.communicationAddress &&
                                  form.communicationAddress.address
                                }
                                onChange={(e) => {
                                  this.handleAddressChange(
                                    e,
                                    "address",
                                    "communicationAddress"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidateAddressChange(
                                    e,
                                    "address",
                                    "communicationAddress"
                                  )
                                }
                                placeholder="Enter Address"
                                disabled={true}
                                className={`form-input ${errors.address ? "error-input-border" : ""
                                  } `}
                                autocomplete="false"
                              />

                              {errors.address && (
                                <span className="errorMsg">
                                  Please Enter Address
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group row col-md-4 mt-4">
                            <div className="col-2 align-center">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={
                                  form.communicationAddressSameAsRegistered
                                }
                                checked={
                                  form.communicationAddressSameAsRegistered
                                }
                                id="communicationAddressSameAsRegistered"
                                onChange={(e) => {
                                  this.handleRadioChange(
                                    e,
                                    "communicationAddressSameAsRegistered"
                                  );
                                }}
                                disabled={true}
                              />
                            </div>
                            <div className="col-10 align-center">
                              <label
                                className="form-check-label"
                                for="communicationAddressSameAsRegistered"
                              >
                                Same as registered address
                              </label>
                            </div>
                          </div>
                        </div>
                      </section>
                    </section>
                    {/* bank section */}
                    <section className="tab-body mt-3 mb-3">
                      <div className="row">
                        <h5 className="col-md-12 text-center mb-4">
                          Bank Details
                        </h5>
                        <div className="form-group col-md-4">
                          <label>
                            <i className="text-danger">*</i> Bank account name
                          </label>
                          <input
                            type="text"
                            pattern="\d*"
                            maxLength="100"
                            className="form-input"
                            name="bankAccountName"
                            value={form.bankAccountName}
                            onChange={(e) => {
                              this.handleRadioChange(e, "bankAccountName");
                            }}
                            onBlur={(e) =>
                              this.handleValidate(e, "bankAccountName")
                            }
                            placeholder="Enter Bank account name"
                            disabled={true}
                          />
                          {errors.bankAccountName && (
                            <span className="errorMsg">
                              Please Enter Bank account name
                            </span>
                          )}
                        </div>

                        <div className="form-group col-md-4">
                          <label>
                            <i className="text-danger">*</i> Bank Name
                          </label>
                          <input
                            type="text"
                            pattern="\d*"
                            maxLength="100"
                            className="form-input"
                            name="bankName"
                            value={form.bankName}
                            onChange={(e) => {
                              this.handleRadioChange(e, "bankName");
                            }}
                            onBlur={(e) => this.handleValidate(e, "bankName")}
                            placeholder="Enter Bank Name"
                            disabled={true}
                          />
                          {errors.bankName && (
                            <span className="errorMsg">
                              Please enter Bank Name
                            </span>
                          )}
                          {/* {!errors.bankName && errors.validBankNameError && (
                                                <span className="errorMsg">
                                                    Bank name should be in character.
                                                </span>
                                            )} */}
                        </div>

                        <div className="form-group col-md-4">
                          <label>
                            {" "}
                            <i className="text-danger">*</i> Account Number
                          </label>
                          <input
                            type="number"
                            pattern="\d*"
                            maxLength="16"
                            className="form-input"
                            name="accountNumber"
                            value={form.accountNumber}
                            onKeyPress={this.restrictAlphabets}
                            onChange={(e) => {
                              if (e.target.value.toString().length <= 16)
                                this.handleRadioChange(e, "accountNumber");
                            }}
                            onBlur={(e) =>
                              this.handleValidate(e, "accountNumber")
                            }
                            placeholder="Enter Account Number"
                            className={`form-input ${errors.accountNumber ? "error-input-border" : ""
                              } `}
                            disabled={true}
                          />
                          {errors.accountNumber && (
                            <span className="errorMsg">
                              Please Enter Account Number
                            </span>
                          )}
                          {!errors.accountNumber &&
                            errors.validAccountNumber && (
                              <span className="errorMsg">
                                Account number should be between 9 to 16.
                              </span>
                            )}
                        </div>

                        <div className="form-group col-md-4">
                          <label>
                            <i className="text-danger">*</i> IFSC Code
                          </label>
                          <input
                            type="text"
                            pattern="\d*"
                            maxLength="11"
                            className="form-input"
                            name="ifscCode"
                            value={form.ifscCode}
                            onChange={(e) => {
                              let regex = /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                              if (!regex.test(e.target.value))
                                this.handleRadioChange(e, "ifscCode");
                            }}
                            onBlur={(e) => this.handleValidate(e, "ifscCode")}
                            placeholder="Enter IFSC Code"
                            className={`form-input ${errors.ifscCode ? "error-input-border" : ""
                              } `}
                            disabled={true}
                          />
                          {errors.ifscCode && (
                            <span className="errorMsg">
                              Please enter IFSC Code
                            </span>
                          )}
                          {!errors.ifscCode && errors.isValidIfsc && (
                            <span className="errorMsg">
                              Please enter valid IFSC Code
                            </span>
                          )}
                        </div>
                        <div className="offset-md-4 offset-lg-4 col-md-4 col-lg-4 align-center-end mt-3">
                          <button
                            type="button"
                            disabled={true}
                            class="btn btn-primary btn-sm"
                            style={{ width: 100 }}
                            onClick={this.handleBankVerification}
                          >
                            {form.bankVerified ? "Verified" : "Verify"}
                          </button>
                        </div>
                      </div>
                    </section>
                    <section className="tab-body mt-3 mb-3">
                      <div className="row">
                        <h5 className="col-md-12 text-center mb-4">
                          Upload Document
                        </h5>
                        <div className="col-md-4">
                          <label>Upload Demand promissory note</label>
                          <ImageUploader
                            className="disable-div"
                            name="demandPromissoryNote"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Demand promissory note"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "demandPromissoryNote");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          {/* {errors.demandPromissoryNote && (
                                                <span className="errorMsg">Please Upload Demand promissory note</span>
                                            )} */}
                          <ul>
                            {form &&
                              form.goldAppraiserDocument &&
                              form.goldAppraiserDocument.length > 0 &&
                              form.goldAppraiserDocument.map((res) => {
                                if (res.description == "demandPromissoryNote") {
                                  let pathArray = res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span
                                        className="mr-2 text-link fw-500 cursor-pointer"
                                      >
                                         <Link onClick={() => this.handleViewDoc(res.documentPath)}>{res.documentPath}</Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <label>Upload Appraisers appointment agreement</label>
                          <ImageUploader
                            className="disable-div"
                            name="appraiserAppointment"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Appraisers appointment agreement"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "appraiserAppointment");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          <ul>
                            {form &&
                              form.goldAppraiserDocument &&
                              form.goldAppraiserDocument.length > 0 &&
                              form.goldAppraiserDocument.map((res) => {
                                if (res.description == "appraiserAppointment") {
                                  let pathArray = res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span
                                        className="mr-2 text-link fw-500 cursor-pointer"
                                      >
                                         <Link onClick={() => this.handleViewDoc(res.documentPath)}>{res.documentPath}</Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <label>Upload DPN - delivery letter</label>
                          <ImageUploader
                            className="disable-div"
                            name="dpnLetter"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="DPN - delivery letter"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "dpnLetter");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          <ul>
                            {form &&
                              form.goldAppraiserDocument &&
                              form.goldAppraiserDocument.length > 0 &&
                              form.goldAppraiserDocument.map((res) => {
                                if (res.description == "dpnLetter") {
                                  let pathArray = res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span
                                        className="mr-2 text-link fw-500 cursor-pointer"
                                      >
                                        <Link onClick={() => this.handleViewDoc(res.documentPath)}></Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-md-4 mt-5">
                          <label style={{ whiteSpace: "pre" }}>
                            Upload Cheque guarantee/ cancelled cheque
                          </label>
                          <ImageUploader
                            className="disable-div"
                            name="chequeGaurantee"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText=" Cheque guarantee/ cancelled cheque"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "chequeGaurantee");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          {/* {errors.chequeGaurantee && (
                                                <span className="errorMsg">Please Upload Cheque guarantee/ cancelled cheque</span>
                                            )} */}
                          <ul>
                            {form &&
                              form.goldAppraiserDocument &&
                              form.goldAppraiserDocument.length > 0 &&
                              form.goldAppraiserDocument.map((res) => {
                                if (res.description == "chequeGaurantee") {
                                  let pathArray = res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span
                                        className="mr-2 text-link fw-500 cursor-pointer"
                                      >
                                         <Link onClick={() => this.handleViewDoc(res.documentPath)}>{res.documentPath}</Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-md-4 mt-5">
                          <label>Upload Passport photo</label>
                          <ImageUploader
                            className="disable-div"
                            name="passportPhoto"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText=" Passport photo"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "passportPhoto");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          {/* {errors.passportPhoto && (
                                                <span className="errorMsg">Please Upload Passport photo</span>
                                            )} */}
                          <ul>
                            {form &&
                              form.goldAppraiserDocument &&
                              form.goldAppraiserDocument.length > 0 &&
                              form.goldAppraiserDocument.map((res) => {
                                if (res.description == "passportPhoto") {
                                  let pathArray = res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span
                                        className="mr-2 text-link fw-500 cursor-pointer"
                                      >
                                         <Link onClick={() => this.handleViewDoc(res.documentPath)}>{res.documentPath}</Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-md-4 mt-5">
                          <label>Upload Resume</label>
                          <ImageUploader
                            className="disable-div"
                            name="resume"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText=" Resume"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "resume");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          {/* {errors.resume && (
                                                <span className="errorMsg">Please Upload Resume</span>
                                            )} */}

                          <ul>
                            {form &&
                              form.goldAppraiserDocument &&
                              form.goldAppraiserDocument.length > 0 &&
                              form.goldAppraiserDocument.map((res) => {
                                if (res.description == "resume") {
                                  let pathArray = res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span
                                        className="mr-2 text-link fw-500 cursor-pointer"
                                      >
                                         <Link onClick={() => this.handleViewDoc(res.documentPath)}>{res.documentPath}</Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-md-5 mt-5">
                          <label style={{ whiteSpace: "pre" }}>
                            Upload Shop registration document / Appraiser
                            certificate
                          </label>
                          <ImageUploader
                            className="disable-div"
                            name="shopRegistration"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Shop registration document / Appraiser certificate"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "shopRegistration");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          {/* {errors.shopRegistration && (
                                                <span className="errorMsg">Please Upload Shop registration document / Appraiser certificate</span>
                                            )} */}
                          <ul>
                            {form &&
                              form.goldAppraiserDocument &&
                              form.goldAppraiserDocument.length > 0 &&
                              form.goldAppraiserDocument.map((res) => {
                                if (res.description == "shopRegistration") {
                                  let pathArray = res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span
                                        className="mr-2 text-link fw-500 cursor-pointer"
                                      >
                                         <Link onClick={() => this.handleViewDoc(res.documentPath)}>{res.documentPath}</Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-md-3 mt-5">
                          <label>Upload KYC document</label>
                          <ImageUploader
                            className="disable-div"
                            name="kycDocument"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="KYC document"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "kycDocument");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          {/* {errors.kycDocument && (
                                                <span className="errorMsg">Please Upload KYC document</span>
                                            )} */}
                          <ul>
                            {form &&
                              form.goldAppraiserDocument &&
                              form.goldAppraiserDocument.length > 0 &&
                              form.goldAppraiserDocument.map((res) => {
                                if (res.description == "kycDocument") {
                                  let pathArray = res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span
                                        className="mr-2 text-link fw-500 cursor-pointer"
                                      >
                                         <Link onClick={() => this.handleViewDoc(res.documentPath)}>{res.documentPath}</Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-md-4 mt-5">
                          <label>Upload Substitute appraiser document</label>
                          <ImageUploader
                            className="disable-div"
                            name="substituteAppraiser"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Substitute appraiser document"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "substituteAppraiser");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          {/* {errors.substituteAppraiser && (
                                                <span className="errorMsg">Please Upload Substitute appraiser document</span>
                                            )} */}
                          <ul>
                            {form &&
                              form.goldAppraiserDocument &&
                              form.goldAppraiserDocument.length > 0 &&
                              form.goldAppraiserDocument.map((res) => {
                                if (res.description == "substituteAppraiser") {
                                  let pathArray = res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span
                                        className="mr-2 text-link fw-500 cursor-pointer"
                                      >
                                         <Link onClick={() => this.handleViewDoc(res.documentPath)}>{res.documentPath}</Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-md-4 mt-5">
                          <label>Upload BM interview sheet</label>
                          <ImageUploader
                            className="disable-div"
                            name="bmInterviewSheet"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="BM interview sheet"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "bmInterviewSheet");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          {/* {errors.bmInterviewSheet && (
                                                <span className="errorMsg">Please Upload BM interview sheet</span>
                                            )} */}
                          <ul>
                            {form &&
                              form.goldAppraiserDocument &&
                              form.goldAppraiserDocument.length > 0 &&
                              form.goldAppraiserDocument.map((res) => {
                                if (res.description == "bmInterviewSheet") {
                                  let pathArray = res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span
                                        className="mr-2 text-link fw-500 cursor-pointer"
                                      >
                                         <Link onClick={() => this.handleViewDoc(res.documentPath)}>{res.documentPath}</Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-md-4 mt-5">
                          <label>Upload Form 60</label>
                          <ImageUploader
                            className="disable-div"
                            name="form60"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Form 60"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "form60");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          {/* {errors.form60 && (
                                                <span className="errorMsg">Please Upload Form 60</span>
                                            )} */}
                          <ul>
                            {form &&
                              form.goldAppraiserDocument &&
                              form.goldAppraiserDocument.length > 0 &&
                              form.goldAppraiserDocument.map((res) => {
                                if (res.description == "form60") {
                                  let pathArray = res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span
                                        className="mr-2 text-link fw-500 cursor-pointer"
                                      >
                                         <Link onClick={() => this.handleViewDoc(res.documentPath)}>{res.documentPath}</Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-md-4 mt-5">
                          <label>Upload Other document 1</label>
                          <ImageUploader
                            className="disable-div"
                            name="otherDocument1"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Other document 1"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "otherDocument1");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          <ul>
                            {form &&
                              form.goldAppraiserDocument &&
                              form.goldAppraiserDocument.length > 0 &&
                              form.goldAppraiserDocument.map((res) => {
                                if (res.description == "otherDocument1") {
                                  let pathArray = res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span
                                        className="mr-2 text-link fw-500 cursor-pointer"
                                      >
                                         <Link onClick={() => this.handleViewDoc(res.documentPath)}>{res.documentPath}</Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-md-4 mt-5">
                          <label>Upload Other document 2</label>
                          <ImageUploader
                            className="disable-div"
                            name="otherDocument2"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Other document 2"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "otherDocument2");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          <ul>
                            {form &&
                              form.goldAppraiserDocument &&
                              form.goldAppraiserDocument.length > 0 &&
                              form.goldAppraiserDocument.map((res) => {
                                if (res.description == "otherDocument2") {
                                  let pathArray = res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span
                                        className="mr-2 text-link fw-500 cursor-pointer"
                                      >
                                         <Link onClick={() => this.handleViewDoc(res.documentPath)}>{res.documentPath}</Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-md-4 mt-5">
                          <label>Upload Other document 3</label>
                          <ImageUploader
                            className="disable-div"
                            name="otherDocument3"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Other document 3"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "otherDocument3");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          <ul>
                            {form &&
                              form.goldAppraiserDocument &&
                              form.goldAppraiserDocument.length > 0 &&
                              form.goldAppraiserDocument.map((res) => {
                                if (res.description == "otherDocument3") {
                                  let pathArray = res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span
                                        className="mr-2 text-link fw-500 cursor-pointer"
                                      >
                                         <Link onClick={() => this.handleViewDoc(res.documentPath)}>{res.documentPath}</Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-md-4 mt-5">
                          <label>Upload Other document 4</label>
                          <ImageUploader
                            className="disable-div"
                            name="otherDocument4"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Other document 4"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "otherDocument4");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                            ]}
                            maxFileSize={5242880}
                            buttonClassName={"disabled"}
                          />
                          <ul>
                            {form &&
                              form.goldAppraiserDocument &&
                              form.goldAppraiserDocument.length > 0 &&
                              form.goldAppraiserDocument.map((res) => {
                                if (res.description == "otherDocument4") {
                                  let pathArray = res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span
                                        className="mr-2 text-link fw-500 cursor-pointer"
                                      >
                                         <Link onClick={() => this.handleViewDoc(res.documentPath)}>{res.documentPath}</Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <p className="mt-5" style={{ color: "black" }}>
                          <i className="text-danger">*</i>Upload documents in
                          jpg, jpeg, png, pdf, gif, docx, pptx and xlsx format
                          with Maximum size up to 5 MB
                        </p>
                      </div>
                    </section>
                    {previousStatus == "REJECTED" && (
                      <section className="tab-body mt-3 mb-3">
                        <div className="row">
                          <div className="form-group col-md-12 col-lg-12 col-sm-12">
                            <label>
                              {" "}
                              <i className="text-danger">*</i> Remark
                            </label>
                            <div class="inner-addon right-addon">
                              <textarea
                                type="text"
                                pattern="\d*"
                                maxLength="500"
                                className="form-input"
                                name="remark"
                                value={form && form.remark}
                                onChange={(e) => {
                                  this.handleRadioChange(e, "remark");
                                }}
                                onBlur={(e) => this.handleValidate(e, "remark")}
                                placeholder="Enter remark"
                                disabled={true}
                                className={`form-input ${errors.remark ? "error-input-border" : ""
                                  } `}
                                autocomplete="false"
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                    {previousStatus == "PENDING" && (
                      <section className="tab-body mt-3 mb-3">
                        <div className="row">
                          <div className=" row form-group col-md-6">
                            <div className=" form-group col-md-3">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={form && form.status == "APPROVED"}
                                      onChange={(e) =>
                                        this.handleApproveReject(e, "APPROVED")
                                      }
                                      name="status"
                                      color="primary"
                                    />
                                  }
                                  label="Approve"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={form && form.status == "REJECTED"}
                                      onChange={(e) =>
                                        this.handleApproveReject(e, "REJECTED")
                                      }
                                      name="status"
                                      color="primary"
                                    />
                                  }
                                  label="Reject"
                                />
                              </FormGroup>
                            </div>
                          </div>
                          {form && form.status && form.status == "REJECTED" && (
                            <div className="form-group col-md-6">
                              <label>
                                {" "}
                                <i className="text-danger">*</i> Remark
                              </label>
                              <div class="inner-addon right-addon">
                                <textarea
                                  type="text"
                                  pattern="\d*"
                                  maxLength="500"
                                  className="form-input"
                                  name="remark"
                                  value={form && form.remark}
                                  onChange={(e) => {
                                    this.handleRadioChange(e, "remark");
                                  }}
                                  onBlur={(e) =>
                                    this.handleValidate(e, "remark")
                                  }
                                  placeholder="Enter remark"
                                  disabled={false}
                                  className={`form-input ${errors.remark ? "error-input-border" : ""
                                    } `}
                                  autocomplete="false"
                                />

                                {errors.remark && (
                                  <span className="errorMsg">
                                    Please Enter Remark
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </section>
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {previousStatus == "PENDING" && (
                    <>
                      <Button className="btn-danger" onClick={this.close}>
                        Cancel
                      </Button>
                      <Button
                        className="btn-success"
                        disabled={disableSave}
                        onClick={this.handleSubmit}
                      >
                        {loading ? (
                          <React.Fragment>
                            <i class="fa fa-spinner fa-spin"></i>
                            Updating...
                          </React.Fragment>
                        ) : (
                            "Save"
                          )}
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </Modal>
              <CloseSection
                show={this.state.showInner}
                button2={this.close}
                button1={this.handleClose}
                title="Do you want to close ?"
              />
            </div>
          </>
        ) : (
            <>
              <h4 className="text-center mt-5">LOADING...</h4>
            </>
          )}
      </>
    );
  }
}

export default withRouter(GoldApprovalMasterForm);