// import React from 'react'
import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import { getAllSegment } from "../../../Utils/Services/segmentmaster";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddEditSegmentMaster from "./AddEditSegmentMaster";
toast.configure();

const SegmentMaster = (props) => {
  let [editSegment, setEditSegement] = useState([]);
  let [segment, setSegment] = useState([]);

  useEffect(() => {
    getSegmentMaster();
  }, []);

  //getSegmentMaster fucntion to fetch all data from api
  const getSegmentMaster = () => {
    getAllSegment().then((res) => {
      console.log("getAllSegment", res);
      if (res.error) {
        return;
      } else if (res.data && res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        if (res && res.data && res.data.data) {
          setSegment(res.data.data);
        }
      }
    });
  };

  const columns = [
    {
      Header: "SR. No",
      width: 70,
      Cell: (data) => {
        // console.log("data...", data)
        return data.index + 1;
      },
      /* Cell: ({ original }) => {
                return original.id && original.id ? original.id : null;
            } */
    },
    {
      Header: "Segment Code",
      width: 200,
      Cell: ({ original }) => {
        return original.segmentCode && original.segmentCode
          ? original.segmentCode
          : null;
      },
    },
    {
      Header: "Segment Description",
      Cell: ({ original }) => {
        return original.segmentDescription && original.segmentDescription
          ? original.segmentDescription
          : null;
      },
    },

    {
      Header: "Action",
      width: 125,
      disabled: false,
      accessor: "delete",
      accessor: "edit",

      Cell: ({ original }) => {
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              // onClick={() => handleEditRoi(original)}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box"> Segment Master</h5>
          <div className="d-flex align-items-center mt-5">
            {
              <button
                disabled={false}
                // onClick={handleAddROi}

                className="add-btn ml-auto btn btn-info btn-lg"
              >
                {" "}
                Add{" "}
              </button>
            }

            <AddEditSegmentMaster getSegmentMaster={getSegmentMaster} />
            {/* {showRoiModal && (
                            <AddEditProductFundingNorms
                                action={"add"}
                                getRoi={getRoi}
                                handleAddROi={handleAddROi}
                            />
                        )}
                        {showEditRoiModal && (
                            <AddEditProductFundingNorms
                                action={"edit"}
                                getRoi={getRoi}
                                editData={editData}
                                handleEditRoi={handleEditRoi}
                            />
                        )} */}
          </div>

          <div className={`mt-4 react-table`}>
            <ReactTable data={segment} columns={columns} defaultPageSize={10} />
          </div>
        </section>
      </main>
    </div>
  );
};

export default SegmentMaster;
