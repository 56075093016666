import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from "lodash";
import CloseModal from "../../../Components/CloseModal";
import {
  formatIndianCurrency,
  replaceComma,
  checkIfNotDecimal,
} from "../../../Utils/helperFunctions";
import {
  savepayoutmaster,
  updatepayoutmaster,
  getFranchiseeListbyCode,
} from "../../../Utils/Services/productfund";

toast.configure();

const initForm = {
  franchisee: "",
  sgShare: "",
  franchiseeShare: "",
  errors: {
    franchisee: null,
    sgShare: null,
    franchiseeShare: null,
  },
};

export class AddEditPayment extends Component {
  state = {
    showModal: false,
    isLoading: false,
    showInner: false,
    updatedropdown: [],
    form: cloneDeep(initForm),
  };

  componentDidMount = () => {
    let { form } = this.state;
    this.setState({ showModal: true });
    console.log("props.editData", this.props.editData);
    if (this.props.editData && this.props.action == "edit") {
      let { form } = this.state;
      let obj = { ...form, ...this.props.editData };
      this.setState({ form: obj });
      this.FranchiseeListbyCode();
    }
  };

  FranchiseeListbyCode = () => {
    let { editData } = this.props;
    getFranchiseeListbyCode(editData && editData.franchisee).then((res) => {
      if (res.error) {
        return;
      }
      if (res.data && res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        this.setState({
          updatedropdown: res.data && res.data.data,
        });
      }
    });
  };

  handleModalClose = () => {
    this.setState({ showInner: !this.state.showInner });
    // this.this.props.addRole();
  };

  innerModalClose = (modclose) => {
    if (!modclose) {
      this.handleModalClose();
    }
    this.setState({ showModal: false });
    if (this.props.action == "edit") {
      this.props.handleEditProductNorm();
    } else {
      this.props.handleAddProductNorm();
    }
  };

  handleSubmit = () => {
    let { form } = this.state;
    let obj = cloneDeep(form);
    this.setState({ isLoading: true });
    // updatepayoutmaster
    if (this.props.action == "edit") {
      delete obj.errors;
      updatepayoutmaster(obj).then((response) => {
        if (response.data && response.data.error) {
          toast.error(response.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
          this.setState({ isLoading: false });
          return;
        } else {
          this.props.getPayoutMasterDetails();
          if (this.props.editData && this.props.action == "edit") {
            this.props.handleEditProductNorm();
          } else {
            this.props.handleAddProductNorm();
          }
          this.setState({ showModal: false });
          toast.success(response.data.message, {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
          this.setState({ isLoading: false });
        }
      });
    } else {
      savepayoutmaster(obj).then((response) => {
        this.setState({ isLoading: false });
        if (response.data && response.data.error) {
          toast.error(response.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
          this.setState({ isLoading: false });
          return;
        } else {
          this.setState({ isLoading: false });
          this.props.getPayoutMasterDetails();
          if (this.props.editData && this.props.action == "edit") {
            this.props.handleEditProductNorm();
          } else {
            this.props.handleAddProductNorm();
          }
          this.setState({ showModal: false });
          toast.success(response.data.message, {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        }
      });
    }
  };

  handleInputChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("handleInputChange", e, name, e.target.value);
    form.franchiseeShare = 100 - e.target.value;
    form[name] = e.target.value;
    this.setState({ form });
  };

  handleProductSelectChange = (e, name) => {
    console.log("handleProductSelectChange", e, name);
    let { form } = this.state;
    let { productdescription } = e;
    form[name] = productdescription;
    this.setState({ form });
  };

  render() {
    let { form, showModal, showInner, isLoading, updatedropdown } = this.state;
    let { franchiseeDropdownValue } = this.props;
    let { errors } = form;
    let diableSave = false;
    let isRequiredFeildFilled = false;
    Object.keys(form).map((name) => {
      console.log("form", form[name]);
      if (form[name] == "" || form[name] == null || form[name] == undefined) {
        isRequiredFeildFilled = true;
        return true;
      }
    });

    Object.keys(errors).map((error) => {
      console.log("error", errors[error]);
      if (errors[error] == true || errors[error] != null) {
        diableSave = true;
        return true;
      }
    });
    console.log("form", form);
    let updatedvalue;
    if (updatedropdown && updatedropdown.length > 0) {
      updatedvalue = updatedropdown.filter(
        ({ productdescription }) => productdescription == form.franchisee
      )[0];
      console.log("value", updatedvalue);
    }

    return (
      <>
        <div>
          <Modal
            className="product-norm-container"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            size="lg"
          >
            <Modal.Header>
              <Modal.Title>
                {this.props.action == "edit" ? "Edit Share" : "Add Share"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
              {isLoading ? (
                <h4 className="text-center">LOADING...</h4>
              ) : (
                <div className="row ">
                  {this.props && this.props.action == "edit" ? (
                    <>
                      {updatedropdown && updatedropdown.length > 0 && (
                        <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                          <label>
                            <i className="text-danger">*</i> Franchisee
                          </label>

                          <Select
                            defaultValue={
                              updatedropdown.filter(
                                ({ productdescription }) =>
                                  productdescription == form.franchisee
                              )[0]
                            }
                            onChange={(e) => {
                              this.handleProductSelectChange(e, "franchisee");
                            }}
                            //   onBlur={(e) => this.onValidate(e, "franchisee")}
                            name="franchisee"
                            options={
                              this.props.action == "edit"
                                ? updatedropdown
                                : franchiseeDropdownValue
                            }
                            placeholder="Select Franchisee"
                            valueKey="productdescription"
                            labelKey="productdescription"
                            getOptionLabel={(option) =>
                              option.productdescription
                            }
                            getOptionValue={(option) =>
                              option.productdescription
                            }
                            className={`${
                              errors.product ? "error-input-border" : ""
                            } `}
                            isDisabled={this.props.action == "edit"}
                          />
                          {errors.product && (
                            <span className="errorMsg">
                              Please Select Franchisee
                            </span>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                      <label>
                        <i className="text-danger">*</i> Franchisee
                      </label>

                      <Select
                        defaultValue={
                          franchiseeDropdownValue.filter(
                            ({ productdescription }) =>
                              productdescription == form.franchisee
                          )[0]
                        }
                        onChange={(e) => {
                          this.handleProductSelectChange(e, "franchisee");
                        }}
                        //   onBlur={(e) => this.onValidate(e, "franchisee")}
                        name="franchisee"
                        options={franchiseeDropdownValue}
                        placeholder="Select Franchisee"
                        valueKey="productdescription"
                        labelKey="productdescription"
                        getOptionLabel={(option) => option.productdescription}
                        getOptionValue={(option) => option.productdescription}
                        className={`${
                          errors.product ? "error-input-border" : ""
                        } `}
                        isDisabled={this.props.action == "edit"}
                      />
                      {errors.product && (
                        <span className="errorMsg">
                          Please Select franchisee
                        </span>
                      )}
                    </div>
                  )}

                  <div className="form-group col-md-4 ">
                    <label>
                      <i className="text-danger">*</i> SG Share (%)
                    </label>
                    <div class="inner-addon right-addon">
                      <input
                        type="text"
                        // pattern="\d*"
                        maxLength="2"
                        name="sgShare"
                        value={form.sgShare}
                        onChange={(e) => {
                          if (
                            e.target.value == "" ||
                            (Math.ceil(e.target.value) <= 100 &&
                              parseInt(e.target.value) >= 0)
                          ) {
                            e.target.value = e.target.value
                              .toString()
                              .split(".")
                              .map((el, i) =>
                                i ? el.split("").slice(0, 2).join("") : el
                              )
                              .join(".");
                            this.handleInputChange(e, "sgShare");
                          }
                        }}
                        // onBlur={(e) => this.onValidate(e, "sgShare")}
                        placeholder="Enter SG Share (%)"
                        className={`form-input ${
                          errors.sgShare ? "error-input-border" : ""
                        } `}
                        disabled={false}
                      />
                      {errors.sgShare && (
                        <span className="errorMsg">
                          Please Enter SG Share (%)
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group col-md-4 ">
                    <label>
                      <i className="text-danger">*</i> Franchisee Share (%)
                    </label>
                    <div class="inner-addon right-addon">
                      <input
                        type="text"
                        // pattern="\d*"
                        maxLength="2"
                        name="franchiseeShare"
                        value={form.franchiseeShare}
                        onChange={(e) => {
                          if (
                            e.target.value == "" ||
                            (Math.ceil(e.target.value) <= 50 &&
                              parseInt(e.target.value) >= 0)
                          ) {
                            e.target.value = e.target.value
                              .toString()
                              .split(".")
                              .map((el, i) =>
                                i ? el.split("").slice(0, 2).join("") : el
                              )
                              .join(".");
                            this.handleInputChange(e, "franchiseeShare");
                          }
                        }}
                        // onBlur={(e) => this.onValidate(e, "franchiseeShare")}
                        placeholder="Enter Franchisee Share (%)"
                        className={`form-input ${
                          errors.franchiseeShare ? "error-input-border" : ""
                        } `}
                        disabled={true}
                      />
                      {errors.franchiseeShare && (
                        <span className="errorMsg">
                          Please Enter franchiseeShare (%)
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-danger" onClick={this.handleModalClose}>
                Cancel
              </Button>
              <Button
                className="btn-success"
                disabled={diableSave || isRequiredFeildFilled || isLoading}
                onClick={this.handleSubmit}
              >
                {this.props.action == "edit" ? "Update" : "Create"}
              </Button>
            </Modal.Footer>
          </Modal>
          <CloseModal
            show={showInner}
            button2={this.handleModalClose}
            button1={this.innerModalClose}
            title="Do you want to close ?"
          />
        </div>
      </>
    );
  }
}

export default AddEditPayment;
