import React, { useState, useEffect, Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from "lodash";
import DatePicker from "react-datepicker";
import CloseModal from "../../../Components/CloseModal";
import { saveorupdatebranchmaster,getunmappedspokes,getdetailsbypincode} from "../../../Utils/Services/BranchMaster";


toast.configure();

const initForm = {
    branchName:"",
    partnerId:null,
    pincode:"",
    tehsil:"",
    state:"",
    dateOfEstablishment:"",
    address:"",
    longitude:"",
    latitude:"",
    district:"",
    spokeEntitySet:[],
    status: "",
  errors: {
    branchName: null,
    partnerId: null,
    pincode: null,
    dateOfEstablishment:null,
    address:null,
    latitude:null,
    longitude:null,
    spokeEntitySet:null,
    tehsil: null,
    district: null,
    state: null,
    status: null,
  },
};
export class AddEditBranch extends Component {
  constructor(props) {
      
    super(props);
    this.state = {
      tehsilsdropdownvalue: [],
      unmappedspokes:[],  
      userTehsil: [],
      userspokes:[],
      pincodedetails:[],
      showModal: false,
      Loading: false,
      showInner: false,
      form: cloneDeep(initForm),
      stateDropdownValues: [],
      districtDropdownValues: [],
      selectedDate:"",
      mappedspokes:[]
    };
  }

  componentDidMount = () => {
   // let { form, selectedDate, mappedspokes, tehsilsdropdownvalue, districtDropdownValues, stateDropdownValues} = this.state;
    this.setState({ showModal: true });
    console.log("props.editData", this.props.editData);
    if (this.props.editData && this.props.action == "edit") {
     // this.alldetailsbypincode(this.props.editData.pincode);
      let { form, selectedDate,mappedspokes, tehsilsdropdownvalue, districtDropdownValues, stateDropdownValues } = this.state;
      selectedDate=this.props.editData.dateOfEstablishment;
      let obj = { ...form, ...this.props.editData };
      tehsilsdropdownvalue.push({ value: obj.tehsil, label: obj.tehsil});
      districtDropdownValues.push({ value: obj.district, label: obj.district});
      stateDropdownValues.push({ value: obj.state, label: obj.state});
      for(var i=0;i<this.props.editData&&this.props.editData.spokeDTOSet&&this.props.editData.spokeDTOSet.length;i++)
      {
        mappedspokes.push(this.props.editData&&this.props.editData.spokeDTOSet&&this.props.editData.spokeDTOSet[i])
      } 
      obj.spokeEntitySet = this.props.editData&&this.props.editData.spokeDTOSet;
      this.setState({ form: obj, selectedDate,mappedspokes, tehsilsdropdownvalue, districtDropdownValues, stateDropdownValues });
      console.log("mappedspokes are ", mappedspokes);
    }
    this.allspokesdropdown();
  };
 
  allspokesdropdown = () => {
    getunmappedspokes().then((res) => {
      console.log("spokes", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        return;
      } else {
        this.setState({ unmappedspokes: res.data });
        if(this.props.action=="edit")
        {
        let{form,unmappedspokes,mappedspokes}=this.state;
        for(var i=0;i<mappedspokes.length;i++)
        {
          unmappedspokes.push(mappedspokes[i]);
          //form.spokeEntitySet.push(mappedspokes[i]);
          console.log("formspokes",form.spokeEntitySet);
        }
        console.log("spokeMappingSet2",form);
        console.log("mappedspokes",unmappedspokes);
        this.setState({ form, unmappedspokes });
      }}
    });
  };
//tehsil district state by pinncode 
alldetailsbypincode = (pincode) => {
  let {form, stateDropdownValues ,districtDropdownValues,tehsilsdropdownvalue} = this.state;
  console.log("form is ", form);
  console.log("pincode is ", pincode);
  getdetailsbypincode(pincode).then((response) => {
    console.log("pincodeaddress", response);
    if (response.error) {
      return;
    }
    if (response.data.error) {
      // toast.error("This Pincode is Invalid", {
      //   type: toast.TYPE.ERROR,
      //   autoClose: 4000,
      // });
      return;
    } else {
      const stateSet = new Set();
      const districtSet = new Set();
      const tehsilSet = new Set();
      const tehsils=[]
      const states=[]
      const districts=[]

      for(var i = 0; i < response.data.length; i++) {
        stateSet.add(response.data[i].state);
      }
      for(var i of stateSet) {
        states.push({ value: i, label: i });
      }
      for(var j=0; j <response.data.length;j++)
      {
        districtSet.add(response.data[j].district)
      }
      for(var j of districtSet) {
        districts.push({ value: j, label: j });
      } 
      for(var k = 0; k<response.data.length; k++) 
      {
        tehsilSet.add(response.data[k].tehsil)
      }
      for(var k of tehsilSet)  
      {
        tehsils.push({ value: k, label: k});
      } 
      form.tehsil = tehsils && tehsils[0] && tehsils[0].value;
      form.district = districts && districts[0] && districts[0].value;
      form.state = states && states[0] && states[0].value;
      this.setState({form, stateDropdownValues:states, districtDropdownValues:districts, tehsilsdropdownvalue:tehsils});
    }
  
})
};
  handleModalClose = () => {
    this.setState({ showInner: !this.state.showInner });
    // this.this.props.addRole();
  };

  innerModalClose = (modclose) => {
    if (!modclose) {
      this.handleModalClose();
    }
    this.setState({ showModal: false });
    if (this.props.action == "edit") {
      this.props.handleEditBranchMaster();
    } else {
      this.props.handleAddBranch();
    }
  };
  
  //saveupdate api
  handleSubmit = (performActive) => {
    let { form,mappedspokes,Loading } = this.state;

    if(this.props.action === "edit" && performActive){ 
      form.status = form.status === true ? false : true;
    }

    let obj = form;
    this.setState({ Loading: true });
    saveorupdatebranchmaster(obj).then((response) => {
      this.setState({ Loading: false });
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        this.props.BranchMaster();
        if (this.props.editData && this.props.action == "edit") {
          this.setState({ showModal: false });
          toast.success("Data Updated Succsesfully", {
            type: toast.TYPE.SUCCESS,
            autoClose: 4000,
          });
          this.props.handleEditBranchMaster();
        } else {
          this.setState({ showModal: false });
          toast.success("Data Saved Succsesfully", {
            type: toast.TYPE.SUCCESS,
            autoClose: 4000,
          });
          this.props.handleAddBranch();
        }
        this.setState({ showModal: false });
      }
    });
    console.log("loading",Loading);
  };

  handleInputChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("handleInputChange", e, name, e.target.value);
    form[name] = e.target.value;
    this.setState({ form });
  };

//for handle partnerId
handlepartnerId = (e, name) => {
  // e.preventDefault();
  let { form } = this.state;
  console.log("handlepartnerId", e, name);
  form[name] = e.target.value;
  this.setState({ form });
};

//handle validation Partner Id
handleValidatepartnerId = (e, name) => {
  e.preventDefault();
  let { form, errors } = this.state;
  let value = form[name];
  // console.log("uuu", e, name, value.length);
  if(name=="partnerId" && value===null || e.target.value.length===0){
    return this.setState({form:{...form, errors:{...form.errors ,partnerId:null}}});
  }

  if (
    value === "" ||
    value === null ||
    value === undefined ||
    (name == "partnerId" && value.length<4 && value.length !== 6)
  ) {
    form.errors[name] = true;
    this.setState({ form });
  } else {
    form.errors[name] = null;
    this.setState({ form });
  }
};

  //pincode valdtn
  restrictAlphabets = (e) => {
    const regx = "^[0-9]*$";
    if (e.key.match(regx)) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  handleBranchTehsilDistrict = (e, name) =>  {
    let regx = /^[a-zA-Z][a-zA-Z0-9()\-\. ]*$/g;
      if (e.target.value.match(regx) || e.target.value === "") {
      let { form } = this.state;
      form[name] = e.target.value;
      this.setState({ form });
    }
  }
  handleLongitudeLatitude =(e,name)=>{
      let regx =  /[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)/;
      if (e.target.value.match(regx) || e.target.value === "") {
      let { form } = this.state;
      form[name] = e.target.value;
      this.setState({ form });
  }
}

 
  handleValidateBranchtehsildiststate = (e, name) => {
    e.preventDefault();
    let { form, errors } = this.state;
    console.log("uuu", e, name);
    let value = form[name];
    if (
      value === "" ||
      value === null ||
      value === undefined ||
      (name == "branchName" && value && e.target.value.trim().length < 3)
    ) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      form.errors[name] = null;
      this.setState({ form });
    }
  };
  handleSpoke = (spoke) => {
    let { form } = this.state;
    console.log("helloh",spoke);
    if (spoke === null ){
      form.spokeEntitySet=[];  
      this.setState({form});
    }if (spoke !== null){
      for(var j=0; j <spoke.length;j++)
       {
        spoke[j].dateOfEstablishment=null;
        }
         form.spokeEntitySet=spoke;
         this.setState({form});
         console.log("helloh",form);
  }
  };
  ValidateLongitudeLatitude = (e, name) => {
    e.preventDefault();
    let { form, errors } = this.state;
    console.log("uuu", e, name);
    let value = form[name];
    if (
      value === "" ||
      value === null ||
      value === undefined 
    ) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      form.errors[name] = null;
      this.setState({ form });
    }
  
  }
  handleStateSelectChange = (e, name) => {
    console.log("handleStateSelectChange", e, name);
    let { form } = this.state;
    let { value } = e;
    form[name] = value;
    this.setState({ form });
  };
 
  handlePincode = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("handlePincode", e, name, e.target.value);
    this.clearAll();
    form[name] = e.target.value;
    this.setState({ form });
    // if (name==="pincode"){
    //   form.tehsil="";
    //   form.district="";
    //   form.state="";    
    //   this.setState({form,stateDropdownValues:[],tehsilsdropdownvalue:[],districtDropdownValues:[]})
    // }
    if(name==="pincode" && e.target.value.length>5){
      this.alldetailsbypincode(e.target.value)
    }
  };
    clearAll = () => {
    let { form ,stateDropdownValues,tehsilsdropdownvalue,districtDropdownValues} = this.state;
    form.tehsil="";
    form.district="";
    form.state="";
    stateDropdownValues.splice(0,stateDropdownValues.length);
    districtDropdownValues.splice(0,districtDropdownValues.length);
    tehsilsdropdownvalue.splice(0,tehsilsdropdownvalue.length);

    this.setState({ form ,stateDropdownValues,tehsilsdropdownvalue,districtDropdownValues});
  };

  
handlevalidationDateOfEstablishment = (e, name)=>{
  e.preventDefault();
  let { form, errors } = this.state;
   let value = form[name];
  if (value === "" || value === null || value === undefined) {
    form.errors[name] = true;
    this.setState({ errors });
  } else {
    form.errors[name] = false;
    this.setState({ errors });
  }
}
  handleValidatePincode = (e, name) => {
    e.preventDefault();
    let { form, errors } = this.state;
    console.log("uuu", e, name);
    let value = form[name];
    if (
      value === "" ||
      value === null ||
      value === undefined ||
      (name == "pincode" && value && value.length !== 6)
    ) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      form.errors[name] = null;
      this.alldetailsbypincode(form.pincode);
      this.setState({ form });
    }
  };
  handleValidateAddressChange =(e,name) =>{
    e.preventDefault();
    let { form, errors, diableSave} = this.state;
    console.log("uuu", e, name);
    let value = form[name];
    if (
      value === "" ||
      value === null ||
      value === undefined || 
      value.length < 3
    ) {
      form.errors[name] = true;
      diableSave=true;
      this.setState({ form ,diableSave});
    
    } else {
      form.errors[name] = null;
      this.setState({ form });
    }
  };
  
  handleDateOfEstablishment = (date, e) => {
    console.log("handleDateOfEstablishment", date, e);
    let { form,selectedDate } = this.state;
var year = date.getFullYear();
var month = date.getMonth()+1;
var dt = date.getDate();

if (dt < 10) {
  dt = '0' + dt;
}
if (month < 10) {
  month = '0' + month;
}

console.log(year+'-' + month + '-'+dt);
    form.dateOfEstablishment = dt+'-' + month + '-'+year;
    form.dateOfEstablishment = dt+'-' + month + '-'+year;
    selectedDate=date;
    this.setState({ form,selectedDate});
  };
  handleAddressChange = (e, name) => {
    // console.log("handleAddressChange", e, name, outerName)
    let { form } = this.state;
    form[name] = e.target.value;
    this.setState({ form });
  };
  
  render() {
    let { form, showModal, showInner, Loading,tehsilsdropdownvalue,userspokes,unmappedspokes ,stateDropdownValues,districtDropdownValues,selectedDate,mappedspokes} = this.state;
    let { errors } = form;
    let diableSave = false;
    console.log("mappedspokes",mappedspokes);
    let isRequiredFeildFilled = false;
    Object.keys(form).map((name) => {
      console.log("form", form[name]);
      if((name=="dwaraid" && form[name].length==0)|| (name == "status")||(name=="partnerId" )){
        return; 
      }

      if (
        form[name] === "" ||
        form[name] === null ||
        form[name] === undefined
        ) {
          isRequiredFeildFilled = true;
          return true;
        }
      
      });
      
  let mappedBranchObj=[];
    unmappedspokes.map((res) => {
      userspokes &&
      userspokes.map((val) => {
        if (res.id == val.id) {
          mappedBranchObj.push(res);  
        }
        });  
    });
      // form.spokeEntitySet=mappedBranchObj;

      Object.keys(errors).map((error) => {
      console.log("error", errors[error]);
      if (errors[error] == true || errors[error] != null) {
    //  diableSave = true;
       //return true;
      }
    });
    
    console.log("error", diableSave);
    console.log("form", form);
    return (
      <React.Fragment>
        <Modal
          className="product-norm-container"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showModal}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>
              {this.props.action == "edit"
                ? "Edit Branch"
                : "Add Branch"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            {Loading ? (
              <h4 className="text-center">LOADING...</h4>
            ) : (
              <>
              <div className="row ">
                <div className="mb-4 col-md-4">
                  <label>
                    <i className="text-danger">*</i>Branch Name
                    </label>
                  <input
                    type="text"
                    pattern="\d*"
                 //   minLength="3"
                    maxLength="100"
                   // className="form-input"
                    name="branchName"
                    value={form.branchName}
                   onChange={(e) => {
                      this.handleBranchTehsilDistrict(e, "branchName");
                    }}
                    onBlur={(e) => this.handleValidateBranchtehsildiststate(e, "branchName")}
                    placeholder="Enter Branch"
                    className={`form-input ${
                      errors.branchName ? "error-input-border" : ""
                    } `}
                    />
                  {errors.branchName && (
                    <span className="errorMsg">
                      {errors.branchName == true
                        ? "Enter Valid Branch Name"
                        : errors.branchName}
                    </span>
                  )}
                </div>

                <div className="mb-4 col-md-4">
                 <label>
                    {/* <i className="text-danger">*</i> */}
                     Partner Id
                  </label>
                  <input
                    type="text"
                    pattern="\d*"
                    maxLength="6"
                 //   className="form-input"
                    name="partnerId"
                    value={form && form.partnerId}
                    onKeyPress={this.restrictAlphabets}
                    onChange={(e) => {
                      this.handlepartnerId(e, "partnerId");
                    }}
                    onBlur={(e) => this.handleValidatepartnerId(e, "partnerId")}
                    placeholder="Enter Partner Id"
                    className={`form-input ${
                      errors.partnerId ? "error-input-border" : ""
                    } `}
                    // id="pincode"
                  />
                  {errors.partnerId && (
                    <span className="errorMsg">
                      {errors.partnerId == true
                        ? "Please enter valid Partner Id"
                        : errors.partnerId}
                    </span>
                  )}
                  </div>

                

                <div className="mb-4 col-md-4 ">
                  <label>
                    <i className="text-danger">*</i> Pincode
                  </label>
                  <input
                    type="text"
                    pattern="\d*"      
                    //className="form-input"
                    name="pincode"
                    maxLength="6"
                    //id="pincode"
                    value={form && form.pincode}
                    onKeyPress={this.restrictAlphabets}
                    onChange={(e) => {
                      this.handlePincode(e, "pincode");
                    }}
                    onBlur={(e) => this.handleValidatePincode(e, "pincode")}
                    placeholder="Enter Pincode"
                    className={`form-input ${
                      errors.pincode ? "error-input-border" : ""
                    } `}
                    // id="pincode"
                  />
                
                  {errors.pincode && (
                    <span className="errorMsg">
                      {errors.pincode == true
                        ? "Please enter valid pincode"
                        : errors.pincode}
                    </span>
                  )}
                
                </div>
                </div>
                <div className="row">
                <div className="mb-4 col-md-4 ">
                <label>Tehsil<i className="text-danger">*</i>
                      </label>
                      <Select
                         value={ tehsilsdropdownvalue.filter(
                          (item) => item.value == form.tehsil
                        ) || ''}
                          onChange={(e) => {
                            this.handleStateSelectChange(e, "tehsil"); //the same logic can be used for this input also
                          }}
                          onBlur={(e) => this.handleValidateBranchtehsildiststate(e, "tehsil")}
                          name="tehsil"
                          options={tehsilsdropdownvalue}
                          placeholder="Select Tehsil"
                          valueKey="label"
                          labelKey="value"
                          getOptionLabel={(option) => option["label"]}
                          getOptionValue={(option) => option["value"]}
                          className={`${
                            errors.state ? "error-input-border" : ""
                          } `}
                          isDisabled={false}
                        />
                  {errors.tehsil && (
                    <span className="errorMsg">
                      {errors.tehsil == true
                        ? "Please enter tehsil"
                        : errors.tehsil}
                    </span>
                  )}
                </div>          
                  <div className="mb-4 col-md-4">
                 <label>
                    <i className="text-danger">*</i>District
                  </label>
                  <Select
                         value={
                          districtDropdownValues.filter(
                            ({ value }) => value == form.district
                          ) || ''}
                          onChange={(e) => {
                            this.handleStateSelectChange(e, "district"); //the same logic can be used for this input also
                          }}
                          onBlur={(e) => this.handleValidateBranchtehsildiststate(e, "district")}
                          name="tehsil"
                          options={districtDropdownValues}
                          placeholder="Select district"
                          valueKey="label"
                          labelKey="value"
                          getOptionLabel={(option) => option["label"]}
                          getOptionValue={(option) => option["value"]}
                          className={`${
                            errors.state ? "error-input-border" : ""
                          } `}
                          isDisabled={false}
                        />
                   {errors.district && (
                        <span className="errorMsg">
                          Please Select disrict
                        </span>
                      )}
                  
                  </div>
                  <div className="mb-4 col-md-4">
                 <label>
                    <i className="text-danger">*</i>State
                  </label>
                  <Select
                          value={
                            stateDropdownValues.filter(
                              ({ value }) => value == form.state
                            ) || ''
                          }
                          onChange={(e) => {
                            this.handleStateSelectChange(e, "state"); //the same logic can be used for this input also
                          }}
                          onBlur={(e) => this.handleValidateBranchtehsildiststate(e, "state")}
                          name="state"
                          options={stateDropdownValues}
                          placeholder="Select State"
                          valueKey="label"
                          labelKey="value"
                          getOptionLabel={(option) => option["label"]}
                          getOptionValue={(option) => option["value"]}
                          className={`${
                            errors.state ? "error-input-border" : ""
                          } `}
                          isDisabled={false}
                        />
                </div>
                  {/* <div className="mb-4 col-md-4">
                  <label>  <i className="text-danger">*</i>
                        Date of Establishment </label>
                      <div class="inner-addon right-addon">
                        <DatePicker
                          dateFormat="dd-MM-yyyy"
                          placeholderText="DD-MM-YYYY"
                          value={form.dateOfEstablishment}
                          onChange={this.handleDateOfEstablishment}
                          onBlur={(e) =>
                            this.handlevalidationDateOfEstablishment(e, "dateOfEstablishment")
                          }
                          className={`form-input ${
                            errors.dateOfEstablishment
                              ? "error-input-border"
                              : ""
                          } `}
                          disabled={false}
                          maxDate={new Date()}
                        />
                      </div>
                      {errors.dateOfEstablishment && (
                        <span className="errorMsg">
                          Please Select Date Of Establishment
                        </span>
                      )}
                  </div> */}
                  
                         
                </div>
                <div className="row">
                     <div className="mb-4 col-md-4">
                        <label>
                        <i className="text-danger">*</i>Address 
                        </label>
                          <textarea
                            type="text"
                            pattern="\d*"
                            maxLength="100"
                           // className="form-input"
                            name="address"
                            value={form.address}
                            onChange={(e) => {
                              this.handleAddressChange(e, "address");
                            }}
                            onBlur={(e) =>
                              this.handleValidateAddressChange(
                                e,
                                "address"
                              )
                            }
                            placeholder="Enter Address"
                            className={`form-input ${
                              errors.address
                                ? "error-input-border"
                                : ""
                            } `}
                            autocomplete="false"
                          />
                          {errors.address && (
                    <span className="errorMsg">
                      {errors.address == true
                        ? "Please enter District"
                        : errors.district}
                    </span>
                  )}
                      </div>

                  <div className="mb-4 col-md-4">
                 <label>
                    <i className="text-danger">*</i>Latitude
                  </label>
                  <input
                    type="text"
                   // className="form-input"
                    name="Latitude"
                    value={form.latitude}
                    onChange={(e) => {
                      this.handleLongitudeLatitude(e, "latitude"); //the same logic can be used for this input also
                    }}
                    onBlur={(e) => this.ValidateLongitudeLatitude(e, "latitude")}
                    placeholder="Enter Latitude"
                    className={`form-input ${
                      errors.latitude ? "error-input-border" : ""
                    } `}
                  />
                  {errors.latitude && (
                    <span className="errorMsg">
                      {errors.latitude == true
                        ? "Please enter latitude"
                        : errors.latitude}
                    </span>
                  )}
                  </div>
                  <div className="mb-4 col-md-4">
                 <label>
                    <i className="text-danger">*</i>Longitude
                  </label>
                  <input
                    type="text"
                    //className="form-input"
                    name="Latitude"
                    value={form.longitude}
                    onChange={(e) => {
                      this.handleLongitudeLatitude(e, "longitude"); //the same logic can be used for this input also
                    }}
                    onBlur={(e) => this.ValidateLongitudeLatitude(e, "longitude")}
                    placeholder="Enter longitude"
                    className={`form-input ${
                      errors.longitude ? "error-input-border" : ""
                    } `}
                  />
                  {errors.longitude && (
                    <span className="errorMsg">
                      {errors.longitude == true
                        ? "Please enter longitude"
                        : errors.longitude}
                    </span>
                  )}
                  </div>

                 </div>
                 
                 <div className="row">
                     
                  <div className="mb-4 col-md-4">
                      <>
                        <label>
                          <i className="text-danger">*</i> Spokes
                        </label>
                        <Select
                          value={form.spokeEntitySet}
                          onChange={this.handleSpoke}
                          // onBlur={(e) => this.onValidate(e, "region")}
                          name="spoke"
                          placeholder="Select spoke / Spokes"
                          valueKey="id"
                          labelKey="info"
                          options={unmappedspokes}
                          getOptionLabel={(option) => option.spokeName}
                          getOptionValue={(option) => option.id}
                          className={`${
                            errors.spokeEntitySet ? "error-input-border" : ""
                          } `}
                          isDisabled={false}
                          isMulti={true}
                        
                        />
                      </>
                    {errors.spoke && (
                      <span className="errorMsg">
                        Please Select spoke / Spoke
                      </span>
                    )}
                  </div>

                  <div className="mb-4 col-md-4">
                  <label>  <i className="text-danger">*</i>
                        Date of Establishment </label>
                      <div class="inner-addon right-addon">
                        <DatePicker
                          dateFormat="dd-MM-yyyy"
                          placeholderText="DD-MM-YYYY"
                          value={form.dateOfEstablishment}
                          onChange={this.handleDateOfEstablishment}
                          onBlur={(e) =>
                            this.handlevalidationDateOfEstablishment(e, "dateOfEstablishment")
                          }
                          className={`form-input ${
                            errors.dateOfEstablishment
                              ? "error-input-border"
                              : ""
                          } `}
                          disabled={false}
                          maxDate={new Date()}
                        />
                      </div>
                      {errors.dateOfEstablishment && (
                        <span className="errorMsg">
                          Please Select Date Of Establishment
                        </span>
                      )}
                  </div>

                 </div> 
            </>
            )}
          </Modal.Body>
          <Modal.Footer>
          {this.props.action === "edit" ? (
              <Button className="success" onClick={() => this.handleSubmit(true)}>
                {form.status === true ? "Inactive" : "Active"}
              </Button>
            ) : null}
            <Button className="btn-danger" onClick={this.handleModalClose}>
              Cancel
            </Button>
            <Button
              className="btn-success"
            disabled={ diableSave ||form&&form.spokeEntitySet&&form.spokeEntitySet.length==0 }
            onClick={() => this.handleSubmit(false)}    
            >
              {this.props.action == "edit" ? "Update" : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
        <CloseModal
          show={showInner}
          button2={this.handleModalClose}
          button1={this.innerModalClose}
          title="Do you want to close ?"
        />
      </React.Fragment>
    );
  }
}

export default AddEditBranch;
