import React, { useState, useEffect, Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from "lodash";

import CloseModal from "../../../Components/CloseModal";
import {
  formatIndianCurrency,
  replaceComma,
  checkIfNotDecimal,
} from "../../../Utils/helperFunctions";
import {
  updateFundingGrid,
  addFundingGrid,
  getLowerLimit,
} from "../../../Utils/Services/fundingmaster";
import "./style.css";
toast.configure();

let initForm = {
  expertScoreUpperLimit: "",
  expertScoreLowerLimit: "",
  highmarkScoreLowerLimit: "",
  highmarkScoreUpperLimit: "",
  fundingAmount: "",
  errors: {
    expertScoreUpperLimit: null,
    expertScoreLowerLimit: null,
    highmarkScoreLowerLimit: null,
    highmarkScoreUpperLimit: null,
    fundingAmount: null,
  },
};
class AddEditFundingMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      showInner: false,
      form: cloneDeep(initForm),
    };
  }

  componentDidMount = () => {
    let { form } = this.state;
    this.setState({ showModal: true });
    // console.log("props.editData", this.props.editData)
    if (this.props.editData && this.props.action == "edit") {
      let { form } = this.state;
      let obj = { ...form, ...this.props.editData };
      this.setState({ form: obj });
    }
  };

  handleModalClose = () => {
    this.setState({ showInner: !this.state.showInner });
    // this.this.props.addRole();
  };

  innerModalClose = (modclose) => {
    if (!modclose) {
      this.handleModalClose();
    }
    this.setState({ showModal: false });
    if (this.props.action == "edit") {
      this.props.handleEditProductFundingGrid();
    } else {
      this.props.handleAddProductFundingGrid();
    }
  };

  handleSubmit = () => {
    let { form } = this.state;
    let obj = { ...form };
    this.setState({ isLoading: true });
    delete obj.errors;
    if (this.props.editData && this.props.action == "edit") {
      updateFundingGrid(obj).then((response) => {
        this.setState({ isLoading: false });
        if (response.data && response.data.error) {
          toast.error(response.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
          return;
        } else {
          this.props.getProductFundingGrid();
          this.props.handleEditProductFundingGrid();
          this.setState({ showModal: false });
          if (response.data && response.data.message) {
            toast.success(response.data.message, {
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }
        }
      });
    } else {
      addFundingGrid(obj).then((response) => {
        this.setState({ isLoading: false });
        if (response.data && response.data.error) {
          toast.error(response.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
          return;
        } else {
          this.props.getProductFundingGrid();
          this.props.handleAddProductFundingGrid();
          this.setState({ showModal: false });
          toast.success(response.data.message, {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        }
      });
    }
  };

  handleInputChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("handleInputChange", e, name, e.target.value);
    form[name] = e.target.value;
    this.setState({ form });
  };

  onValidate = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("state*******", e, name, form);

    if (
      ((form.expertScoreLowerLimit != 0 || form.expertScoreUpperLimit != 0) &&
        (form.expertScoreLowerLimit != "" ||
          form.expertScoreUpperLimit != "")) ||
      ((form.highmarkScoreLowerLimit != 0 ||
        form.highmarkScoreUpperLimit != 0) &&
        form.highmarkScoreLowerLimit != "") ||
      form.highmarkScoreUpperLimit != ""
    ) {
      console.log(
        "inside",
        name == "expertScoreUpperLimit",
        parseInt(e.target.value),
        parseInt(form.expertScoreLowerLimit)
      );
      if (
        name == "expertScoreUpperLimit" &&
        parseInt(e.target.value) <= parseInt(form.expertScoreLowerLimit)
      ) {
        form.errors.expertScoreUpperLimit =
          "Upper limit can not be less than or equal to Lower limit.";
        form.errors.expertScoreLowerLimit = null;
      } else if (
        name == "expertScoreUpperLimit" &&
        parseInt(e.target.value) > parseInt(form.expertScoreLowerLimit)
      ) {
        form.errors.expertScoreUpperLimit = null;
        form.errors.expertScoreLowerLimit = null;
      } else if (
        name == "expertScoreLowerLimit" &&
        parseInt(e.target.value) >= parseInt(form.expertScoreUpperLimit)
      ) {
        form.errors.expertScoreLowerLimit =
          "Lower limit can not be less than or equal to Upper limit.";
        form.errors.expertScoreUpperLimit = null;
      } else if (
        name == "expertScoreLowerLimit" &&
        parseInt(e.target.value) < parseInt(form.expertScoreUpperLimit)
      ) {
        form.errors.expertScoreLowerLimit = null;
        form.errors.expertScoreUpperLimit = null;
      } else if (
        name == "highmarkScoreUpperLimit" &&
        parseInt(e.target.value) <= parseInt(form.highmarkScoreLowerLimit)
      ) {
        form.errors.highmarkScoreUpperLimit =
          "Upper limit can not be less than or equal to Lower limit.";
        form.errors.highmarkScoreLowerLimit = null;
      } else if (
        name == "highmarkScoreUpperLimit" &&
        parseInt(e.target.value) > parseInt(form.highmarkScoreLowerLimit)
      ) {
        form.errors.highmarkScoreUpperLimit = null;
        form.errors.highmarkScoreLowerLimit = null;
      } else if (
        name == "highmarkScoreLowerLimit" &&
        parseInt(e.target.value) >= parseInt(form.highmarkScoreUpperLimit)
      ) {
        form.errors.highmarkScoreLowerLimit =
          "Lower limit can not be less than or equal to Upper limit.";
        form.errors.highmarkScoreUpperLimit = null;
      } else if (
        name == "highmarkScoreLowerLimit" &&
        parseInt(e.target.value) < parseInt(form.highmarkScoreUpperLimit)
      ) {
        form.errors.highmarkScoreLowerLimit = null;
        form.errors.highmarkScoreUpperLimit = null;
      } else {
        if (isNaN(parseInt(e.target.value))) {
          form.errors[name] = true;
        } else {
          form.errors[name] = null;
        }
      }

      this.setState({ form });
    } else {
      if (form[name] == "" || form[name] == null || form[name] == undefined) {
        form.errors[name] = true;
        this.setState({ form });
      } else {
        form.errors[name] = null;
        this.setState({ form });
      }
    }
    // let { product, loantype, lowerloanamtnorms, baseamt, state, percentagevalue } = form;
  };

  render() {
    let { form, showModal, showInner, isLoading } = this.state;
    let { errors } = form;

    let diableSave = false;
    let isRequiredFeildFilled = false;
    Object.keys(form).map((name) => {
      console.log("form", form[name]);
      if (form[name] == "" || form[name] == null || form[name] == undefined) {
        isRequiredFeildFilled = true;
        return true;
      }
    });

        Object.keys(errors).map((error) => {
            console.log("error", errors[error])
            if (errors[error] == true || errors[error] != null) {
                diableSave = true;
                return true
            }
        })
        // console.log("form", this.props.action == "add" && form && form.length != 0)
        return (
            <>

                <div>
                    <Modal
                        className="product-norm-container"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={showModal}
                        size="lg"
                    >
                        <Modal.Header>
                            <Modal.Title>
                                {this.props.action == "edit" ? "Edit Product Funding Grid" : "Add Product Funding Grid"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-4">
                            {isLoading ? (
                                <h4 className="text-center">LOADING...</h4>
                            ) : (
                                    <div className="row ">
                                        <div className="form-group col-md-4 ">
                                            <label>
                                                <i className="text-danger">*</i> Expert Score (lower limit)
                                                </label>
                                            <div class="inner-addon right-addon">
                                                <input
                                                    type="text"
                                                    // pattern="\d*"
                                                    maxLength="9"
                                                    name="expertScoreLowerLimit"
                                                    value={form.expertScoreLowerLimit && formatIndianCurrency(form.expertScoreLowerLimit)}
                                                    onChange={e => {
                                                        e.target.value = replaceComma(e.target.value)
                                                        if (
                                                            e.target.value == "" ||
                                                            (Math.ceil(e.target.value) <= 999 && parseInt(e.target.value) > 0)
                                                        ) {
                                                            e.target.value = e.target.value
                                                                .toString()
                                                                .split(".")
                                                                .map((el, i) =>
                                                                    i ? el.split("").slice(0, 2).join("") : el
                                                                )
                                                                .join(".");
                                                            this.handleInputChange(e, "expertScoreLowerLimit")
                                                        }
                                                        /* if (checkIfNotDecimal(e.target.value)) {
                                                            this.handleInputChange(e, "expertScoreLowerLimit")
                                                        } */
                                                    }}
                                                    onBlur={(e) => {
                                                        e.target.value = replaceComma(e.target.value)
                                                        this.onValidate(e, "expertScoreLowerLimit")
                                                    }}
                                                    placeholder="Enter Expert Score (Lower Limit)"
                                                    className={`form-input ${errors.expertScoreLowerLimit ? "error-input-border"
                                                        : ""
                                                        } `}
                                                    disabled={false}
                                                />
                                                {errors.expertScoreLowerLimit && (
                                                    <span className="errorMsg">
                                                        {errors.expertScoreLowerLimit == true ? "Enter Expert Score (Lower Limit)" : errors.expertScoreLowerLimit}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group col-md-4 ">
                                            <label>
                                                <i className="text-danger">*</i> Expert Score (upper limit)
                                                </label>
                                            <div class="inner-addon right-addon">
                                                <input
                                                    type="text"
                                                    // pattern="\d*"
                                                    maxLength="9"
                                                    name="expertScoreUpperLimit"
                                                    value={form.expertScoreUpperLimit && formatIndianCurrency(form.expertScoreUpperLimit)}
                                                    onChange={e => {
                                                        e.target.value = replaceComma(e.target.value)
                                                        if (
                                                            e.target.value == "" ||
                                                            (Math.ceil(e.target.value) <= 999 && parseInt(e.target.value) > 0)
                                                        ) {
                                                            e.target.value = e.target.value
                                                                .toString()
                                                                .split(".")
                                                                .map((el, i) =>
                                                                    i ? el.split("").slice(0, 2).join("") : el
                                                                )
                                                                .join(".");
                                                            this.handleInputChange(e, "expertScoreUpperLimit")
                                                        }
                                                        /* if (checkIfNotDecimal(e.target.value)) {
                                                            this.handleInputChange(e, "expertScoreUpperLimit")
                                                        } */
                                                    }}
                                                    onBlur={(e) => {
                                                        e.target.value = replaceComma(e.target.value)
                                                        this.onValidate(e, "expertScoreUpperLimit")
                                                    }}
                                                    placeholder="Enter Expert Score (upper limit)"
                                                    className={`form-input ${errors.expertScoreUpperLimit ? "error-input-border"
                                                        : ""
                                                        } `}
                                                    disabled={false}
                                                />
                                                {errors.expertScoreUpperLimit && (
                                                    <span className="errorMsg">
                                                        {errors.expertScoreUpperLimit == true ? "Enter Expert Score (upper limit)" : errors.expertScoreUpperLimit}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group col-md-4 ">
                                            <label>
                                                <i className="text-danger">*</i> High Mark Score (lower limit)
                                                </label>
                                            <div class="inner-addon right-addon">
                                                <input
                                                    type="text"
                                                    // pattern="\d*"
                                                    maxLength="9"
                                                    name="highmarkScoreLowerLimit"
                                                    value={form.highmarkScoreLowerLimit && formatIndianCurrency(form.highmarkScoreLowerLimit)}
                                                    onChange={e => {
                                                        e.target.value = replaceComma(e.target.value)
                                                        if (
                                                            e.target.value == "" ||
                                                            (Math.ceil(e.target.value) <= 999 && parseInt(e.target.value) > 0)
                                                        ) {
                                                            e.target.value = e.target.value
                                                                .toString()
                                                                .split(".")
                                                                .map((el, i) =>
                                                                    i ? el.split("").slice(0, 2).join("") : el
                                                                )
                                                                .join(".");
                                                            this.handleInputChange(e, "highmarkScoreLowerLimit")
                                                        }
                                                        /* if (checkIfNotDecimal(e.target.value)) {
                                                            this.handleInputChange(e, "highmarkScoreLowerLimit")
                                                        } */
                                                    }}
                                                    onBlur={(e) => {
                                                        e.target.value = replaceComma(e.target.value)
                                                        this.onValidate(e, "highmarkScoreLowerLimit")
                                                    }}
                                                    placeholder="Enter High Mark Score (Lower Limit)"
                                                    className={`form-input ${errors.highmarkScoreLowerLimit ? "error-input-border"
                                                        : ""
                                                        } `}
                                                    disabled={false}
                                                />
                                                {errors.highmarkScoreLowerLimit && (
                                                    <span className="errorMsg">
                                                        {errors.highmarkScoreLowerLimit == true ? "Enter High Mark Score (Lower Limit)" : errors.highmarkScoreLowerLimit}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group col-md-4 ">
                                            <label>
                                                <i className="text-danger">*</i> High Mark Score (upper limit)
                                                </label>
                                            <div class="inner-addon right-addon">
                                                <input
                                                    type="text"
                                                    // pattern="\d*"
                                                    maxLength="9"
                                                    name="highmarkScoreUpperLimit"
                                                    value={form.highmarkScoreUpperLimit && formatIndianCurrency(form.highmarkScoreUpperLimit)}
                                                    onChange={e => {
                                                        e.target.value = replaceComma(e.target.value)
                                                        if (
                                                            e.target.value == "" ||
                                                            (Math.ceil(e.target.value) <= 999 && parseInt(e.target.value) > 0)
                                                        ) {
                                                            e.target.value = e.target.value
                                                                .toString()
                                                                .split(".")
                                                                .map((el, i) =>
                                                                    i ? el.split("").slice(0, 2).join("") : el
                                                                )
                                                                .join(".");
                                                            this.handleInputChange(e, "highmarkScoreUpperLimit")
                                                        }
                                                        /* if (checkIfNotDecimal(e.target.value)) {
                                                            this.handleInputChange(e, "highmarkScoreUpperLimit")
                                                        } */
                        }}
                        onBlur={(e) => {
                          e.target.value = replaceComma(e.target.value);
                          this.onValidate(e, "highmarkScoreUpperLimit");
                        }}
                        placeholder="Enter High Mark Score (upper limit)"
                        className={`form-input ${
                          errors.highmarkScoreUpperLimit
                            ? "error-input-border"
                            : ""
                        } `}
                        disabled={false}
                      />
                      {errors.highmarkScoreUpperLimit && (
                        <span className="errorMsg">
                          {errors.highmarkScoreUpperLimit == true
                            ? "Enter High Mark Score (upper limit)"
                            : errors.highmarkScoreUpperLimit}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group col-md-4 ">
                    <label>
                      <i className="text-danger">*</i> Funding Amount (In
                      Rupees)
                    </label>
                    <div class="inner-addon right-addon">
                      <input
                        type="text"
                        // pattern="\d*"
                        maxLength="9"
                        name="fundingAmount"
                        value={
                          form.fundingAmount &&
                          formatIndianCurrency(form.fundingAmount)
                        }
                        onChange={(e) => {
                          e.target.value = replaceComma(e.target.value);
                          if (checkIfNotDecimal(e.target.value)) {
                            this.handleInputChange(e, "fundingAmount");
                          }
                        }}
                        onBlur={(e) => this.onValidate(e, "fundingAmount")}
                        placeholder="Enter Base Amount"
                        className={`form-input ${
                          errors.fundingAmount ? "error-input-border" : ""
                        } `}
                        disabled={false}
                      />
                      {errors.fundingAmount && (
                        <span className="errorMsg">
                          Please Enter Base Amount
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-danger" onClick={this.handleModalClose}>
                Cancel
              </Button>
              <Button
                className="btn-success"
                disabled={diableSave || isRequiredFeildFilled || isLoading}
                onClick={this.handleSubmit}
              >
                {this.props.action == "edit" ? "Update" : "Save"}
              </Button>
            </Modal.Footer>
          </Modal>
          <CloseModal
            show={showInner}
            button2={this.handleModalClose}
            button1={this.innerModalClose}
            title="Do you want to close ?"
          />
        </div>
      </>
    );
  }
}

export default AddEditFundingMaster;
