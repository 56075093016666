import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
// import Select from "../../../Components/Input/Select";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

import CloseModal from "../../../Components/CloseModal";
import {
  formatIndianCurrency,
  replaceComma,
  checkIfNotDecimal,
} from "../../../Utils/helperFunctions";
import {
  addFrankingFee,
  updateFrankingFeeById,
} from "../../../Utils/Services/frankingFee";
import { LoanTypeOption } from "../../../Utils/constant";
import { getAllStates, getAllProducts } from "../../../Utils/Services/common";

import "./style.css";
import { cloneDeep } from "lodash";
toast.configure();

let initForm = {
  product: "",
  loantype: "",
  lowerloanamtnorms: "",
  upperloanamtnorms: "",
  baseamt: "",
  state: "",
  percentagevalue: "",
  errors: {
    product: null,
    loantype: null,
    baseamt: null,
    state: null,
    percentagevalue: null,
    lowerloanamtnorms: null,
    upperloanamtnorms: null,
  },
};
class AddEditFrankingFee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      showInner: false,
      stateDropdownValue: [],
      productDropdownValue: [],
      form: cloneDeep(initForm),
    };
  }

  componentDidMount = () => {
    console.log("this.props.editData", this.props);
    if (this.props.editData && this.props.action == "edit") {
      let { form } = this.state;
      let obj = { ...form, ...this.props.editData };
      this.setState({ form: obj });
    }
    this.setState({ showModal: true });
  };

  handleModalClose = () => {
    this.setState({ showInner: !this.state.showInner });
    // this.this.props.addRole();
  };

  innerModalClose = (modclose) => {
    if (!modclose) {
      this.handleModalClose();
    }
    this.setState({ showModal: false });
    if (this.props.action == "edit") {
      this.props.handleEditFrankingFee();
    } else {
      this.props.handleAddFrankingFee();
    }
  };

  handleStateSelectChange = (e, name) => {
    console.log("handleStateSelectChange", e, name);
    let { form } = this.state;
    let { stateName } = e;
    form[name] = stateName;
    this.setState({ form });
  };

  handleProductSelectChange = (e, name) => {
    console.log("handleProductSelectChange", e, name);
    let { form } = this.state;
    let { productdescription } = e;
    form[name] = productdescription;
    this.setState({ form });
  };

  handleLoanTypeSelectChange = (e, name) => {
    console.log("handleLoanTypeSelectChange", e, name);
    let { laonTypeLabel, laonTypeValue } = e;
    let { form } = this.state;
    form[name] = laonTypeValue;
    this.setState({ form });
  };

  handleInputChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log(
      "handleInputChange",
      e,
      name,
      e.target.value,
      parseInt(e.target.value) > parseInt(form.lowerloanamtnorms)
    );
    form[name] = e.target.value;
    this.setState({ form });
  };

  onValidate = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    // let { product, loantype, lowerloanamtnorms, baseamt, state, percentagevalue } = form;
    console.log(
      "onValidate",
      name,
      e.target.value,
      parseInt(e.target.value),
      parseInt(form.upperloanamtnorms)
    );
    if (
      (form[name] == "" || form[name] == null || form[name] == undefined) &&
      name != "lowerloanamtnorms" &&
      name != "upperloanamtnorms"
    ) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      if (
        name == "upperloanamtnorms" &&
        parseInt(e.target.value) <= parseInt(form.lowerloanamtnorms)
      ) {
        form.errors.upperloanamtnorms =
          "Upper limit can not be less than or equal to Lower limit.";
        form.errors.lowerloanamtnorms = null;
      } else if (
        name == "upperloanamtnorms" &&
        parseInt(e.target.value) > parseInt(form.lowerloanamtnorms)
      ) {
        form.errors.upperloanamtnorms = null;
        form.errors.lowerloanamtnorms = null;
      } else if (
        name == "lowerloanamtnorms" &&
        parseInt(e.target.value) >= parseInt(form.upperloanamtnorms)
      ) {
        form.errors.lowerloanamtnorms =
          "Lower limit can not be less than or equal to Upper limit.";
        form.errors.upperloanamtnorms = null;
      } else if (
        name == "lowerloanamtnorms" &&
        parseInt(e.target.value) < parseInt(form.upperloanamtnorms)
      ) {
        form.errors.lowerloanamtnorms = null;
        form.errors.upperloanamtnorms = null;
      } else {
        form.errors[name] = null;
      }
      this.setState({ form });
    }
  };

  handleSubmit = () => {
    let { form } = this.state;
    let obj = form;
    // delete obj.errors;
    this.setState({ isLoading: true });
    if (this.props.editData && this.props.action == "edit") {
      updateFrankingFeeById(obj).then((response) => {
        this.setState({ isLoading: false });
        if (response.error) {
          return;
        } else if (response.data && response.data.error) {
          toast.error(response.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
          return;
        } else {
          this.props.getFrankingFee();
          this.props.handleEditFrankingFee();
          this.setState({ showModal: false });
          toast.success(response.data.message, {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        }
      });
    } else {
      addFrankingFee(obj).then((response) => {
        this.setState({ isLoading: false });
        if (response.error) {
          return;
        } else if (response.data && response.data.error) {
          toast.error(response.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
          return;
        } else {
          this.props.getFrankingFee();
          this.props.handleAddFrankingFee();
          this.setState({ showModal: false });
          toast.success(response.data.message, {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        }
      });
    }
  };

  render() {
    let { form, showModal, showInner, isLoading } = this.state;
    let { stateDropdownValue, productDropdownValue } = this.props;
    let { errors } = form;
    let diableSave = false;
    let isRequiredFeildFilled = false;

    Object.keys(form).map((name) => {
      console.log("form", form[name]);
      if (
        (form[name] == "" || form[name] == null || form[name] == undefined) &&
        name != "lowerloanamtnorms" &&
        name != "upperloanamtnorms"
      ) {
        isRequiredFeildFilled = true;
        return true;
      }
    });

    if (form && form.percentagevalue == 0) {
      isRequiredFeildFilled = false;
    }
    Object.keys(errors).map((error) => {
      console.log("error", errors[error]);
      if (errors[error] == true || errors[error] != null) {
        diableSave = true;
        return true;
      }
    });
    console.log("form.state", form, diableSave, isRequiredFeildFilled);
    return (
      <>
        {stateDropdownValue &&
          stateDropdownValue.length > 0 &&
          stateDropdownValue &&
          productDropdownValue &&
          productDropdownValue.length > 0 && (
            <div>
              <Modal
                className="franking-fee-container"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showModal}
                size="lg"
              >
                <Modal.Header>
                  <Modal.Title>
                    {this.props.action == "edit"
                      ? "Edit Franking Fee"
                      : "Add Franking Fee"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                  {isLoading ? (
                    <h4 className="text-center">LOADING...</h4>
                  ) : (
                    <div className="row">
                      <div className="mb-4 col-md-4">
                        <label>
                          <i className="text-danger">*</i> State
                        </label>
                        <Select
                          defaultValue={
                            stateDropdownValue.filter(
                              ({ stateName }) => stateName == form.state
                            )[0]
                          }
                          onChange={(e) => {
                            this.handleStateSelectChange(e, "state");
                          }}
                          onBlur={(e) => this.onValidate(e, "state")}
                          name="state"
                          options={stateDropdownValue}
                          placeholder="Select State"
                          valueKey="stateName"
                          labelKey="stateName"
                          getOptionLabel={(option) => option["stateName"]}
                          getOptionValue={(option) => option["stateName"]}
                          className={`${
                            errors.state ? "error-input-border" : ""
                          } `}
                          isDisabled={false}
                        />
                        {errors.state && (
                          <span className="errorMsg">Please Select State</span>
                        )}
                      </div>
                      <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                        <label>
                          <i className="text-danger">*</i> Product
                        </label>
                        <Select
                          defaultValue={
                            productDropdownValue.filter(
                              ({ productdescription }) =>
                                productdescription == form.product
                            )[0]
                          }
                          onChange={(e) => {
                            this.handleProductSelectChange(e, "product");
                          }}
                          onBlur={(e) => this.onValidate(e, "product")}
                          name="product"
                          options={productDropdownValue}
                          placeholder="Select Product"
                          valueKey="productdescription"
                          labelKey="productdescription"
                          getOptionLabel={(option) => option.productdescription}
                          getOptionValue={(option) => option.productdescription}
                          className={`${
                            errors.product ? "error-input-border" : ""
                          } `}
                          isDisabled={false}
                        />
                        {errors.product && (
                          <span className="errorMsg">
                            Please Select Product
                          </span>
                        )}
                      </div>
                      <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                        <label>
                          <i className="text-danger">*</i> Loan Type
                        </label>
                        <Select
                          defaultValue={
                            LoanTypeOption.filter(
                              ({ laonTypeValue }) =>
                                laonTypeValue == form.loantype
                            )[0]
                          }
                          onChange={(e) => {
                            this.handleLoanTypeSelectChange(e, "loantype");
                          }}
                          onBlur={(e) => this.onValidate(e, "loantype")}
                          name="loantype"
                          options={LoanTypeOption}
                          placeholder="Select Loan Type"
                          valueKey="laonTypeValue"
                          labelKey="laonTypeLabel"
                          getOptionLabel={(option) => option.laonTypeLabel}
                          getOptionValue={(option) => option.laonTypeValue}
                          className={`${
                            errors.loantype ? "error-input-border" : ""
                          } `}
                          isDisabled={false}
                        />
                        {errors.loantype && (
                          <span className="errorMsg">
                            Please Select Loan Type
                          </span>
                        )}
                      </div>
                      <div className="form-group col-md-4 ">
                        <label>
                          {/* <i className="text-danger">*</i> */} Loan Amount
                          Norms (lower limit - In Rupees)
                        </label>
                        <div class="inner-addon right-addon">
                          <input
                            type="text"
                            // pattern="\d*"
                            maxLength="9"
                            name="lowerloanamtnorms"
                            value={
                              form.lowerloanamtnorms &&
                              formatIndianCurrency(form.lowerloanamtnorms)
                            }
                            onChange={(e) => {
                              e.target.value = replaceComma(e.target.value);
                              if (checkIfNotDecimal(e.target.value)) {
                                this.handleInputChange(e, "lowerloanamtnorms");
                              }
                            }}
                            onBlur={(e) => {
                              e.target.value = replaceComma(e.target.value);
                              this.onValidate(e, "lowerloanamtnorms");
                            }}
                            placeholder="Enter Loan Amount Norms (lower limit)"
                            className={`form-input ${
                              errors.lowerloanamtnorms
                                ? "error-input-border"
                                : ""
                            } `}
                            disabled={false}
                          />
                          {errors.lowerloanamtnorms && (
                            <span className="errorMsg">
                              {errors.lowerloanamtnorms}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-4 ">
                        <label>
                          {/* <i className="text-danger">*</i> */} Loan Amount
                          Norms (upper limit - In Rupees)
                        </label>
                        <div class="inner-addon right-addon">
                          <input
                            type="text"
                            // pattern="\d*"
                            maxLength="9"
                            value={
                              form.upperloanamtnorms &&
                              formatIndianCurrency(form.upperloanamtnorms)
                            }
                            onChange={(e) => {
                              e.target.value = replaceComma(e.target.value);
                              if (checkIfNotDecimal(e.target.value)) {
                                this.handleInputChange(e, "upperloanamtnorms");
                              }
                            }}
                            onBlur={(e) => {
                              e.target.value = replaceComma(e.target.value);
                              this.onValidate(e, "upperloanamtnorms");
                            }}
                            placeholder="Enter Loan Amount Norms (upper limit)"
                            className={`form-input ${
                              errors.upperloanamtnorms
                                ? "error-input-border"
                                : ""
                            } `}
                            disabled={false}
                          />
                          {errors.upperloanamtnorms && (
                            <span className="errorMsg">
                              {errors.upperloanamtnorms}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-4 ">
                        <label>
                          <i className="text-danger">*</i> Base Amount (In
                          Rupees)
                        </label>
                        <div class="inner-addon right-addon">
                          <input
                            type="text"
                            // pattern="\d*"
                            maxLength="9"
                            name="baseamt"
                            value={
                              form.baseamt && formatIndianCurrency(form.baseamt)
                            }
                            onChange={(e) => {
                              e.target.value = replaceComma(e.target.value);
                              if (checkIfNotDecimal(e.target.value)) {
                                this.handleInputChange(e, "baseamt");
                              }
                            }}
                            onBlur={(e) => this.onValidate(e, "baseamt")}
                            placeholder="Enter Base Amount"
                            className={`form-input ${
                              errors.baseamt ? "error-input-border" : ""
                            } `}
                            disabled={false}
                          />
                          {errors.baseamt && (
                            <span className="errorMsg">
                              Please Enter Base Amount
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-4 ">
                        <label>
                          <i className="text-danger">*</i> Percentage Value (%)
                        </label>
                        <div class="inner-addon right-addon">
                          <input
                            type="text"
                            // pattern="\d*"
                            maxLength="9"
                            name="percentagevalue"
                            value={form.percentagevalue}
                            onChange={(e) => {
                              if (
                                e.target.value == "" ||
                                (Math.ceil(e.target.value) <= 100 &&
                                  parseInt(e.target.value) >= 0)
                              ) {
                                e.target.value = e.target.value
                                  .toString()
                                  .split(".")
                                  .map((el, i) =>
                                    i ? el.split("").slice(0, 2).join("") : el
                                  )
                                  .join(".");
                                this.handleInputChange(e, "percentagevalue");
                              }
                            }}
                            onBlur={(e) =>
                              this.onValidate(e, "percentagevalue")
                            }
                            placeholder="Enter Percentage Value (%)"
                            className={`form-input ${
                              errors.percentagevalue ? "error-input-border" : ""
                            } `}
                            disabled={false}
                          />
                          {errors.percentagevalue && (
                            <span className="errorMsg">
                              Please Enter Percentage Value (%)
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="btn-danger"
                    onClick={this.handleModalClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-success"
                    disabled={diableSave || isRequiredFeildFilled || isLoading}
                    onClick={this.handleSubmit}
                  >
                    {this.props.action == "edit" ? "Update" : "Create"}
                  </Button>
                </Modal.Footer>
              </Modal>
              <CloseModal
                show={showInner}
                button2={this.handleModalClose}
                button1={this.innerModalClose}
                title="Do you want to close ?"
              />
            </div>
          )}
      </>
    );
  }
}

export default AddEditFrankingFee;
