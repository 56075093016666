import {
    GET_USER_BY_EMPLOYEEID,
    GET_USER_BY_EMPLOYEEID_SUCCESS,
    GET_USER_BY_EMPLOYEEID_FAILURE
} from './types'
const LOAD = 'redux-form-examples/account/LOAD'

export const getUserByEmplyeeId = (data) => ({
    type: GET_USER_BY_EMPLOYEEID,
    payload: data
})

export const getUserByEmplyeeIdSuccess = (data) => ({
    type: GET_USER_BY_EMPLOYEEID_SUCCESS,
    payload: data
})
export const getUserByEmplyeeIdFailure = (error) => ({
    type: GET_USER_BY_EMPLOYEEID_FAILURE,
    payload: error
})

export const load = data => ({ type: LOAD, data })
