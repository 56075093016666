import axios from "axios";
import { getServerValidation } from "./helperFunctions";
import { API_URL } from "../Utils/config";
import { ToastContainer, toast } from "react-toastify";
import { loginUrl } from "./constant";
// const serverURL = "https://cors-anywhere.herokuapp.com/";
// const Local = "http://localhost:3000/";
const serverURL = "http://182.71.191.91:5010/";
toast.configure();

const handleResponse = (response) => {
  return {
    data: response.data,
  };
};

const handleError = (error) => {
  const { response } = error;
  let parsedError = response && JSON.parse(JSON.stringify(response.data));
  // console.log("handleError", typeof JSON.parse(JSON.stringify(parsedError)), parsedError.statusCode, parsedError && parsedError.statusCode == 401)
  let errorMsg = "";
  errorMsg = response?.data?.message;
  // let errorMsg = "Sorry, something went wrong. Please try again.";
  console.log("error", error, response);
  //status null or message null
  if (response == undefined) {
    errorMsg = "Service Unavailable";
  }
  if (response && response.status == 402) {
    errorMsg = response.data.message;
  } else if (
    response &&
    response.status === 404 &&
    response.data &&
    response.data.message
  ) {
    errorMsg = response.data.message;
    toast.error(errorMsg, {
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    });
  } else if (response && response != undefined && response.status === 422) {
    // handle server validation
    errorMsg = "Sorry, something went wrong. Please try again.";
    if (response.data && response.data.errors)
      errorMsg = getServerValidation(response.data.errors) || errorMsg;
    else if (response.data.message) {
      errorMsg = response.data.message;
    }
  } else if (response.status == 401) {
    // Unauthorized
    localStorage.clear();
    window.location.href = loginUrl;
  } else if (
    response &&
    response != undefined &&
    JSON.parse(JSON.stringify(response.data)).toString().includes("401")
  ) {
    // Unauthorized
    // console.log("here............1111")
    let element = document.getElementById("unauthorized-box");
    if (element) {
      element.style.display = "block";
      errorMsg = null;
    }
    localStorage.removeItem("userData");
    // window.location.href = `${window.location.protocol}//${window.location.hostname}:5000/admin-logout`;
    window.location.href = `${loginUrl}`;
  } else if (errorMsg) {
    //errorMsg = "Sorry, something went wrong. Please try again.";
    // console.log("here............")
    toast.error(errorMsg, {
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    });
  }
  return {
    error: true,
    message: response?.data?.message
      ? response.data.message
      : response?.data?.errorMessage
      ? response.data.errorMessage
      : null,
    status: response && response != undefined ? response.status : null,
  };
  /*  return {
     message: response ? response.data.message : null,
     status: response ? response.status : null
   }; */
};

export const get = (
  url,
  isPrivate = true,
  responseType = null,
  customUrl = false,
  headers
) => {
  let apiUrl = API_URL + url;
  if (customUrl) {
    apiUrl = url;
  }
  // if (isPrivate && getToken()) {
  //   const isParam = apiUrl.includes("?");
  //   if (isParam) apiUrl = `${apiUrl}&&api_token=${getToken()}`;
  //   else apiUrl = `${apiUrl}?api_token=${getToken()}`;
  // }
  const axiosObj = {
    method: "get",
    url: apiUrl,
  };
  let userData = JSON.parse(localStorage.getItem("userData"));
  headers = {
    Authorization: `Bearer ${userData.adminAccessToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  if (headers) {
    axiosObj.headers = headers;
  }
  if (responseType) axiosObj.responseType = responseType;
  return axios(axiosObj)
    .then((response) => handleResponse(response))
    .catch((error) => handleError(error));
};

export const post = (
  url,
  bodyObj = {},
  isPrivate = true,
  mediaFile = false,
  uat = false
) => {
  const apiUrl = !uat ? API_URL + url : url;
  let header = {};
  if (mediaFile == true) {
    /* let formData = new FormData();
    Object.keys(bodyObj).map(key => {
      formData.append(key, bodyObj[key]);
    });
    bodyObj = formData; */
    let userData = JSON.parse(localStorage.getItem("userData"));
    header = {
      Authorization: `Bearer ${userData.adminAccessToken}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
    };
  } else {
    let userData = JSON.parse(localStorage.getItem("userData"));
    if (!url.includes("authenticate")) {
      header = {
        Authorization: `Bearer ${userData.adminAccessToken}`,
        "Access-Control-Allow-Origin": "*",
      };
    }
  }
  return axios
    .post(apiUrl, bodyObj, {
      headers: header,
    })
    .then((response) => {
      console.log("response...", response.data);
      return handleResponse(response);
    })
    .catch((error) => {
      console.log("error interceptor", error);
      return handleError(error);
    });
};

export const put = (
  url,
  bodyObj = {},
  isPrivate = true,
  mediaFile = false,
  uat = false
) => {
  const apiUrl = !uat ? API_URL + url : url;
  let header = {};
  if (mediaFile == true) {
    /* let formData = new FormData();
    Object.keys(bodyObj).map(key => {
      formData.append(key, bodyObj[key]);
    });
    bodyObj = formData; */
    let userData = JSON.parse(localStorage.getItem("userData"));
    header = {
      Authorization: `Bearer ${userData.adminAccessToken}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
    };
  } else {
    let userData = JSON.parse(localStorage.getItem("userData"));
    if (!url.includes("authenticate")) {
      header = {
        Authorization: `Bearer ${userData.adminAccessToken}`,
        "Access-Control-Allow-Origin": "*",
      };
    }
  }
  return axios
    .put(apiUrl, bodyObj, {
      headers: header,
    })
    .then((response) => {
      console.log("response...put request ****8*", response.data);
      return handleResponse(response);
    });
  // .catch((error) => {
  //   console.log("error interceptor", error);
  //   return handleError(error);
  // });
};

export const postdelete = (
  url,
  isPrivate = true,
  mediaFile = false,
  uat = false
) => {
  const apiUrl = !uat ? API_URL + url : url;
  let header = {};
  if (mediaFile == true) {
    /* let formData = new FormData();
    Object.keys(bodyObj).map(key => {
      formData.append(key, bodyObj[key]);
    });
    bodyObj = formData; */
    let userData = JSON.parse(localStorage.getItem("userData"));
    header = {
      Authorization: `Bearer ${userData.adminAccessToken}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
    };
  } else {
    let userData = JSON.parse(localStorage.getItem("userData"));
    if (!url.includes("authenticate")) {
      header = {
        Authorization: `Bearer ${userData.adminAccessToken}`,
        "Access-Control-Allow-Origin": "*",
      };
    }
  }
  return axios
    .delete(apiUrl, {
      headers: header,
    })
    .then((response) => {
      console.log("response...", response.data);
      return handleResponse(response);
    })
    .catch((error) => {
      console.log("error interceptor", error);
      return handleError(error);
    });
};

export const postFile = (apiURL, bodyObj = {}, contentType) => {
  return axios({
    method: "post",
    url: `${serverURL}${API_URL}${apiURL}`,
    data: bodyObj,
    config: { headers: { "Content-Type": "multipart/form-data" } },
  })
    .then((response) => handleResponse(response))
    .catch((error) => handleError(error));
};

export const awspost = (
  url,
  bodyObj = {},
  isPrivate = true,
  mediaFile = false,
  uat = false
) => {
  const apiUrl = !uat ? API_URL + url : url;

  if (mediaFile == true) {
    let formData = new FormData();
    Object.keys(bodyObj).map((key) => {
      formData.append(key, bodyObj[key]);
    });
    bodyObj = formData;
  }
  return axios
    .post(apiUrl, bodyObj, {
      headers: {
        "x-api-key": "dlQFSd7b2i3lnWLhMwmPx2Uv3wqU6zHGxwnxwFy9",
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => handleResponse(response))
    .catch((error) => handleError(error));
};

export const postexcel = (url, data) => {
  const api_url = API_URL + url;
  const leadData = localStorage.getItem("userData");
  let token = JSON.parse(leadData).adminAccessToken;
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + token,
  };

  return axios
    .post(api_url, data, { headers: headers })
    .then((res) => handleResponse(res))
    .catch((err) => {
      return err.response;
    });
};
