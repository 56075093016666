// import React from 'react'
import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import {
  getMFGEmiMultiplier,
  deleteMFGEmiMultiplier,
} from "../../../Utils/Services/MFGEmiMaster";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddEditMFGEmiMaster from "./AddEditMFGEmiMaster";
import { formatIndianCurrency } from "../../../Utils/helperFunctions";
toast.configure();

const MFGMaster = (props) => {
  let [MFG, setMFG] = useState([]);
  let [showAddMFGEmiMasterModal, setshowAddMFGEmiMasterModal] = useState(false);
  let [showEditMFGEmiMasterModal, setshowEditMFGEmiMasterModal] =
    useState(false);
  let [editData, setEditData] = useState({});

  useEffect(() => {
    getMFGEmiMaster();
  }, []);

  const getMFGEmiMaster = () => {
    getMFGEmiMultiplier().then((res) => {
      console.log("getMFGEmiMultiplier", res);
      if (res.error) {
        return;
      }
      // else if (res.data && res.data.error) {
      //   toast.error(res.data.message, {
      //     type: toast.TYPE.ERROR,
      //     autoClose: 2000,
      //   });
      //   return;
      // }
      else {
        if (res && res.data && res.data.data) {
          setMFG(res.data.data);
        }
      }
    });
  };

  const deleteMFGEmiMasterById = (id) => {
    let postData = {
      id: id,
    };
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes, Delete It!",
          onClick: () => {
            deleteMFGEmiMultiplier(postData).then((response) => {
              console.log("deleteMFGEmiMultiplier", response);
              if (response.error) {
                return;
              }
              if (response.data && response.data.error) {
                toast.error(response.data.message, {
                  type: toast.TYPE.ERROR,
                  autoClose: 2000,
                });
              } else {
                toast.success(response.data.message, {
                  type: toast.TYPE.SUCCESS,
                  autoClose: 2000,
                });
                getMFGEmiMaster();
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            // this.getAllMFG();
          },
        },
      ],
    });
  };

  const columns = [
    {
      Header: "SR. No",
      width: 70,
      Cell: (data) => {
        return data.index + 1;
      },
    },

    {
      Header: "Bureau Score",
      Cell: ({ original }) => {
        let text = "";
        if (
          original.bureauScoreUpperLimit == 700 &&
          original.bureauScoreLowerLimit < "0"
        ) {
          text = `>=${original.bureauScoreUpperLimit}`;
        } else if (
          original.bureauScoreUpperLimit != "" &&
          original.bureauScoreLowerLimit != "" &&
          original.bureauScoreUpperLimit != undefined &&
          original.bureauScoreLowerLimit != undefined &&
          original.bureauScoreUpperLimit != "0" &&
          original.bureauScoreLowerLimit != "0"
        ) {
          text = `${original.bureauScoreLowerLimit}-${original.bureauScoreUpperLimit}`;
        } else if (
          (original.bureauScoreUpperLimit != undefined &&
            original.bureauScoreLowerLimit != undefined) ||
          (original.bureauScoreUpperLimit == "0" &&
            original.bureauScoreLowerLimit == "0")
        ) {
          text = ``;
        }
        return text;
      },
    },

    {
      Header: "EMI Month (min)",
      Cell: ({ original }) => {
        return original.emiMonth && original.emiMonth
          ? original.emiMonth
          : null;
      },
    },

    {
      Header: "Loan Amount (In Rupees)",
      Cell: (data) => {
        let { original } = data;
        let text = "";

        if (
          original.loanAmountUpperLimit != "" &&
          (original.loanAmountLowerLimit == "" ||
            original.loanAmountLowerLimit == "0")
        ) {
          text = `<= ${formatIndianCurrency(original.loanAmountUpperLimit)}`;
        } else if (
          (original.loanAmountUpperLimit == "" ||
            original.loanAmountUpperLimit == "0") &&
          original.loanAmountLowerLimit != ""
        ) {
          text = `>= ${formatIndianCurrency(original.loanAmountLowerLimit)}`;
        }
        if (
          original.loanAmountUpperLimit != "" &&
          original.loanAmountLowerLimit != ""
        ) {
          text = `${formatIndianCurrency(
            original.loanAmountLowerLimit
          )}-${formatIndianCurrency(original.loanAmountUpperLimit)}`;
        } else if (
          (original.loanAmountUpperLimit == "" &&
            original.loanAmountLowerLimit == "") ||
          (original.loanAmountUpperLimit == "0" &&
            original.loanAmountLowerLimit == "0")
        ) {
          text = `NA`;
        }
        return text;
      },
    },
    {
      Header: "Tenure (months)",
      Cell: (data) => {
        let { original } = data;
        let text = "";
        if (
          original.tenureUpperLimit != "" &&
          original.tenureLowerLimit != "" &&
          original.tenureUpperLimit != undefined &&
          original.tenureLowerLimit != undefined &&
          original.tenureUpperLimit != "0" &&
          original.tenureLowerLimit != "0"
        ) {
          text = `${original.tenureLowerLimit}-${original.tenureUpperLimit}`;
        } else if (
          (original.tenureUpperLimit != undefined &&
            original.tenureLowerLimit != undefined) ||
          (original.tenureUpperLimit == "0" && original.tenureLowerLimit == "0")
        ) {
          text = ``;
        }
        return text;
      },
    },

    {
      Header: "Multiplier",
      Cell: ({ original }) => {
        return original.multiplier && original.multiplier
          ? original.multiplier
          : null;
      },
    },

    {
      Header: "Loan Type",
      Cell: ({ original }) => {
        console.log("org", original);
        return original.loanType && original.loanType
          ? original.loanType
          : null;
      },
    },

    {
      Header: "Condition Type",
      Cell: ({ original }) => {
        return original.conditionType && original.conditionType
          ? original.conditionType
          : null;
      },
    },

    {
      Header: "Action",
      width: 125,
      disabled: false,
      accessor: "delete",
      accessor: "edit",

      Cell: (data) => {
        let { original, index } = data;
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              onClick={() => deleteMFGEmiMasterById(original.id)}
              className="fa fa-trash"
              style={{ cursor: "pointer" }}
            />
            <i
              disabled={false}
              onClick={() => handleEditMFGEmiMaster(original, index)}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const handleAddMFGEmiMaster = (id) => {
    console.log("handleAddMFGEmiMaster");
    setshowAddMFGEmiMasterModal(!showAddMFGEmiMasterModal);
  };

  const handleEditMFGEmiMaster = (obj, index) => {
    setshowEditMFGEmiMasterModal(!showEditMFGEmiMasterModal);
    setEditData(obj);
  };

  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box">
            {" "}
            MFG EMI Multiplier
          </h5>
          <div className="d-flex align-items-center mt-5">
            <button
              disabled={false}
              onClick={handleAddMFGEmiMaster}
              className="add-btn ml-auto btn btn-info btn-lg"
            >
              {" "}
              Add{" "}
            </button>
            {showAddMFGEmiMasterModal && (
              <AddEditMFGEmiMaster
                action={"add"}
                getMFGEmiMaster={getMFGEmiMaster}
                handleAddMFGEmiMaster={handleAddMFGEmiMaster}
              />
            )}
            {showEditMFGEmiMasterModal && (
              <AddEditMFGEmiMaster
                action={"edit"}
                getMFGEmiMaster={getMFGEmiMaster}
                editData={editData}
                handleEditMFGEmiMaster={handleEditMFGEmiMaster}
              />
            )}
          </div>

          <div className={`mt-4 react-table`}>
            <ReactTable data={MFG} columns={columns} defaultPageSize={10} />
          </div>
        </section>
      </main>
    </div>
  );
};

export default MFGMaster;
