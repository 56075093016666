import { get, post } from "../httpInterceptor";

// API for delete  campaign name by id
export const deleteCampaignNameById = (campaignNameId) => {
  return post(
    `/masters/campaign/name/delete/${campaignNameId}`,
    null,
    false
  ).then((res) => {
    console.log("deletecampaignNamebyId", res);
    return res;
  });
};

// API for get  campaign name
export const getAllCampaignName = () => {
  return get(`/masters/campaign/name`).then((res) => {
    console.log("get campaign name", res);
    return res;
  });
};

// // API for save product campaign
// export const addProductCampaign = (obj) => {
// 	return post(`/productcampaign/saveorupdateproductcampaign`, obj, false).then((res) => {
// 		console.log("addFrankingFee", res);
// 		return res;
// 	});
// };
// API for save and update campaign name
export const saveUpdateCampaignName = (obj) => {
  return post(`/masters/campaign/name`, obj, false).then((res) => {
    console.log("save or update  campaign name", res);
    return res;
  });
};
