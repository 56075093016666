import React, { useState, useEffect, Component } from 'react';
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from 'lodash';

import CloseModal from "../../../Components/CloseModal";
import { formatIndianCurrency, replaceComma, checkIfNotDecimal } from "../../../Utils/helperFunctions";
import { addOrUpdateDfpMaster } from "../../../Utils/Services/dfpMater";
import { getAllProducts } from "../../../Utils/Services/common";
import { LoanTypeOption } from "../../../Utils/constant";

import "./style.css"
toast.configure();

let ActiveDropdownValue = [
    {
        value: "Y",
        name: "Y",
    },
    {
        value: "N",
        name: "N",
    }
]
let initForm = {
    roleName: "",
    loanType: "",
    product: "",
    pricingUpperLimit: "",
    pricingLowerLimit: "",
    id: null
}
class AddEditDfpMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            isLoading: false,
            showInner: false,
            form: cloneDeep(initForm),
            errors: {
                role: null,
                loanType: null,
                product: null,
                pricingUpperLimit: null,
                pricingLowerLimit: null,
                // active: null,
                id: null
            }
        }
    }


    componentDidMount = () => {
        let { form } = this.state;
        this.setState({ showModal: true });
        console.log("props.editData", this.props.editData)
        if (this.props.editData && this.props.action == "edit") {
            let { form } = this.state
            let obj = { ...form, ...this.props.editData }
            this.setState({ form: obj });
        }
    }


    handleModalClose = () => {
        this.setState({ showInner: !this.state.showInner });
        // this.this.props.addRole();
    };

    innerModalClose = modclose => {
        if (!modclose) {
            this.handleModalClose()
        };
        this.setState({ showModal: false })
        if (this.props.action == "edit") {
            this.props.handleEditDfpMaster()
        } else {
            this.props.handleAddDfpMaster()
        }
    };

    handleSubmit = () => {
        let { form } = this.state;
        let obj = form;
        this.setState({ isLoading: true })
        addOrUpdateDfpMaster(obj).then((response) => {
            this.setState({ isLoading: false })
            if (response.data && response.data.error) {
                toast.error(response.data.message, {
                    type: toast.TYPE.ERROR,
                    autoClose: 2000
                });
                return
            } else {
                this.props.getDfpMaster();
                if (this.props.editData && this.props.action == "edit") {
                    this.props.handleEditDfpMaster()
                } else {
                    this.props.handleAddDfpMaster()
                }
                toast.success(response.data.message, {
                    type: toast.TYPE.SUCCESS,
                    autoClose: 2000
                });
                this.setState({ showModal: false })
            }
        });
    }

    handleLoanTypeSelectChanges = (e, name) => {
        console.log("handleLoanTypeSelectChanges", e, name);
        let { form } = this.state;
        let { laonTypeValue } = e;
        form[name] = laonTypeValue;
        this.setState({ form });
    }

    handleProductSelectChange = (e, name) => {
        console.log("handleProductSelectChange", e, name)
        let { form } = this.state;
        let { productdescription } = e;
        form[name] = productdescription;
        this.setState({ form });
    }

    handleRoleSelectChanges = (e, name) => {
        console.log("handleRoleSelectChanges", e, name)
        let { form } = this.state;
        let { roleName } = e;
        form[name] = roleName;
        this.setState({ form });
    }

    handleInputChange = (e, name) => {
        e.preventDefault();
        let { form, errors } = this.state;
        console.log("handleInputChange", e, name, e.target.value)
        form[name] = e.target.value;
        if (e.target.value == null || e.target.value == undefined || e.target.value == "") {
            errors[name] = true
        } else {
            errors[name] = false
        }
        this.setState({ form });
    }

    onValidate = (e, name) => {
        console.log("state*******", e);
        e.preventDefault();
        let { form, errors } = this.state;

        if ((form.pricingLowerLimit != 0 || form.pricingUpperLimit != 0) && form.pricingLowerLimit != "" || form.pricingUpperLimit != "") {
            if (name == "pricingUpperLimit" && parseInt(e.target.value) <= parseInt(form.pricingLowerLimit)) {
                errors.pricingUpperLimit = "Upper limit can not be less than or equal to Lower limit."
                errors.pricingLowerLimit = null;
            } else if (name == "pricingUpperLimit" && parseInt(e.target.value) > parseInt(form.pricingLowerLimit)) {
                errors.pricingUpperLimit = null;
                errors.pricingLowerLimit = null;
            } else if (name == "pricingLowerLimit" && parseInt(e.target.value) >= parseInt(form.pricingUpperLimit)) {
                errors.pricingLowerLimit = "Lower limit can not be less than or equal to Upper limit."
                errors.pricingUpperLimit = null;
            } else if (name == "pricingLowerLimit" && parseInt(e.target.value) < parseInt(form.pricingUpperLimit)) {
                errors.pricingLowerLimit = null;
                errors.pricingUpperLimit = null;
            } else {
                if (form.pricingLowerLimit == null || form.pricingLowerLimit == undefined || form.pricingLowerLimit == "") {
                    errors[name] = true
                } else {
                    errors[name] = null
                }
            }

            this.setState({ form });
        } else {
            if ((form[name] == "" || form[name] == null || form[name] == undefined)) {
                errors[name] = true;
                this.setState({ form });
            } else {
                errors[name] = null;
                this.setState({ form });
            }
        }
        // let { product, loantype, lowerloanamtnorms, baseamt, state, percentagevalue } = form;

    }

    render() {
        let { form, showModal, showInner, isLoading, errors } = this.state;
        let { productDropdownValue, RoleDropdownValue } = this.props;
        let diableSave = false;
        let isRequiredFeildFilled = false;
        Object.keys(form).map((name) => {
            console.log("form", form[name])
            if ((form[name] == "" || form[name] == null || form[name] == undefined) && name != "id") {
                isRequiredFeildFilled = true;
                return true
            }
        })

        errors && Object.keys(errors).map((error) => {
            console.log("error", errors[error])
            if (errors[error] == true || errors[error] != null) {
                diableSave = true;
                return true
            }
        })
        console.log("form", isRequiredFeildFilled, diableSave)

        return (
            <>
                {productDropdownValue && productDropdownValue.length > 0 && RoleDropdownValue && RoleDropdownValue.length > 0 && <div>
                    <Modal
                        className="product-norm-container"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={showModal}
                        size="lg"
                    >
                        <Modal.Header>
                            <Modal.Title>
                                {this.props.action == "edit" ? "Edit DPF Master" : "Add DPF Master"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-4">
                            {isLoading ? (
                                <h4 className="text-center">LOADING...</h4>
                            ) : (
                                    <div className="row ">
                                        <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                                            <label>
                                                <i className="text-danger">*</i> Product
                                                </label>
                                            <Select
                                                defaultValue={productDropdownValue.filter(({ productdescription }) => productdescription == form.product)[0]}
                                                onChange={e => {
                                                    this.handleProductSelectChange(e, "product");
                                                }}
                                                onBlur={(e) => this.onValidate(e, "product")}
                                                name="product"
                                                options={productDropdownValue}
                                                placeholder="Select Product"
                                                valueKey="productdescription"
                                                labelKey="productdescription"
                                                getOptionLabel={option => option.productdescription}
                                                getOptionValue={option => option.productdescription}
                                                className={`${errors.product ? "error-input-border" : ""
                                                    } `}
                                                isDisabled={false}
                                            />
                                            {errors.product && (
                                                <span className="errorMsg">
                                                    Please Select Product
                                                </span>
                                            )}
                                        </div>
                                        <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                                            <label>
                                                <i className="text-danger">*</i> Type
                                                </label>
                                            <Select
                                                defaultValue={LoanTypeOption.filter(({ laonTypeValue }) => laonTypeValue == form.loanType)[0]}
                                                onChange={e => {
                                                    this.handleLoanTypeSelectChanges(e, "loanType");
                                                }}
                                                onBlur={(e) => this.onValidate(e, "loanType")}
                                                name="loanType"
                                                options={LoanTypeOption}
                                                placeholder="Select Type"
                                                valueKey="laonTypeValue"
                                                labelKey="laonTypeValue"
                                                getOptionLabel={option => option.laonTypeValue}
                                                getOptionValue={option => option.laonTypeValue}
                                                className={`${errors.loanType ? "error-input-border" : ""
                                                    } `}
                                                isDisabled={false}
                                            />
                                            {errors.loanType && (
                                                <span className="errorMsg">
                                                    Please Select Type
                                                </span>
                                            )}
                                        </div>
                                        <div className="mb-4 col-md-4 ">
                                            <label>
                                                <i className="text-danger">*</i> Role
                                                </label>
                                            <Select
                                                defaultValue={RoleDropdownValue.filter(({ roleName }) => roleName == form.roleName)[0]}
                                                onChange={e => {
                                                    this.handleRoleSelectChanges(e, "roleName");
                                                }}
                                                onBlur={(e) => this.onValidate(e, "roleName")}
                                                name="roleName"
                                                options={RoleDropdownValue}
                                                placeholder="Select role"
                                                valueKey="roleName"
                                                labelKey="roleName"
                                                getOptionLabel={option => option.roleName}
                                                getOptionValue={option => option.roleName}
                                                className={`${errors.roleName ? "error-input-border" : ""
                                                    } `}
                                                isDisabled={false}
                                            />
                                            {errors.roleName && (
                                                <span className="errorMsg">
                                                    Please Enter Role
                                                </span>
                                            )}
                                        </div>
                                        <div className="form-group col-md-4 ">
                                            <label>
                                                <i className="text-danger">*</i> Pricing (lower limit - In Rupees)
                                                </label>
                                            <div class="inner-addon right-addon">
                                                <input
                                                    type="text"
                                                    // pattern="\d*"
                                                    maxLength="9"
                                                    name="pricingLowerLimit"
                                                    value={form.pricingLowerLimit && formatIndianCurrency(form.pricingLowerLimit)}
                                                    onChange={e => {
                                                        e.target.value = replaceComma(e.target.value);
                                                        if (checkIfNotDecimal(e.target.value)) {
                                                            this.handleInputChange(e, "pricingLowerLimit")
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        e.target.value = replaceComma(e.target.value)
                                                        this.onValidate(e, "pricingLowerLimit")
                                                    }}
                                                    placeholder="Enter Pricing (Lower Limit)"
                                                    className={`form-input ${errors.pricingLowerLimit ? "error-input-border"
                                                        : ""
                                                        } `}
                                                    disabled={false}
                                                />
                                                {errors.pricingLowerLimit && (
                                                    <span className="errorMsg">
                                                        {errors.pricingLowerLimit == true ? "Enter Pricing (Lower Limit)" : errors.pricingLowerLimit}
                                                    </span>
                                                )}
                                                {/* {!errors.loanamtnorms && invalidPanNumber && (
                                                        <span className="errorMsg">Please enter valid PAN</span>
                                                    )} */}
                                            </div>
                                        </div>
                                        <div className="form-group col-md-4 ">
                                            <label>
                                                <i className="text-danger">*</i> Pricing (upper limit - In Rupees)
                                                </label>
                                            <div class="inner-addon right-addon">
                                                <input
                                                    type="text"
                                                    // pattern="\d*"
                                                    maxLength="9"
                                                    name="pricingUpperLimit"
                                                    value={form.pricingUpperLimit && formatIndianCurrency(form.pricingUpperLimit)}
                                                    onChange={e => {
                                                        e.target.value = replaceComma(e.target.value);
                                                        if (checkIfNotDecimal(e.target.value)) {
                                                            this.handleInputChange(e, "pricingUpperLimit")
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        e.target.value = replaceComma(e.target.value)
                                                        this.onValidate(e, "pricingUpperLimit")
                                                    }}
                                                    placeholder="Enter Pricing (upper limit)"
                                                    className={`form-input ${errors.pricingUpperLimit ? "error-input-border"
                                                        : ""
                                                        } `}
                                                    disabled={false}
                                                />
                                                {errors.pricingUpperLimit && (
                                                    <span className="errorMsg">
                                                        {errors.pricingUpperLimit == true ? "Enter Pricing (upper limit)" : errors.pricingUpperLimit}
                                                    </span>
                                                )}
                                                {/* {!errors.pricingUpperLimit && invalidPanNumber && (
                                                        <span className="errorMsg">Please enter valid PAN</span>
                                                    )} */}
                                            </div>
                                            {/* {this.props.action == "edit" && <div className="mb-4 col-md-4 ">
                                                <label>
                                                    <i className="text-danger">*</i> Active
                                                </label>
                                                <Select
                                                    defaultValue={ActiveDropdownValue.filter(({ name }) => name == form.active)[0]}
                                                    onChange={e => {
                                                        this.handleActiveSelectChanges(e, "active");
                                                    }}
                                                    onBlur={(e) => this.onValidate(e, "active")}
                                                    name="active"
                                                    options={ActiveDropdownValue}
                                                    placeholder="Select Active"
                                                    valueKey="value"
                                                    labelKey="name"
                                                    getOptionLabel={option => option.name}
                                                    getOptionValue={option => option.value}
                                                    className={`${errors.active ? "error-input-border" : ""
                                                        } `}
                                                    isDisabled={false}
                                                />
                                                {errors.active && (
                                                    <span className="errorMsg">
                                                        Please Enter Active
                                                    </span>
                                                )}
                                            </div>} */}
                                        </div>
                                    </div>
                                )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn-danger" onClick={this.handleModalClose}>
                                Cancel
                  </Button>
                            <Button className="btn-success" disabled={diableSave || isRequiredFeildFilled || isLoading} onClick={this.handleSubmit}>
                                {this.props.action == "edit" ? "Update" : "Create"}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <CloseModal
                        show={showInner}
                        button2={this.handleModalClose}
                        button1={this.innerModalClose}
                        title="Do you want to close ?"
                    />
                </div>}
            </>
        )
    }
}

export default AddEditDfpMaster;
