import React, { Component } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../Components/Header";
import LeftMenu from "../../Components/LeftMenu";
import Select from "react-select";
import {
  addrolebasedmoduleaccess,
  fetchingrolebasedmoduleaccessbyemployeeid,
  findAllRoles,
  getModuleList,
} from "../../Utils/Services/access";
toast.configure();

export class index extends Component {
  state = {
    role: null,
    roleOption: [],
    pageData: [],
    accessData: [],
    roleHide: true,
  };

  componentDidMount() {
    this.RoleList();
    this.Allpages();
  }

  RoleList = () => {
    findAllRoles().then((response) => {
      this.setState({
        roleOption:
          response.data && response.data.data ? response.data.data : [],
      });
    });
  };

  Allpages = () => {
    getModuleList().then((response) => {
      console.log("response of pages", response);
      this.setState({
        pageData: response.data && response.data.data ? response.data.data : [],
      });
    });
  };

  handleRole = (role) => {
    console.log("Role....", role && role.roleId);
    fetchingrolebasedmoduleaccessbyemployeeid(role && role.roleId).then(
      (response) => {
        this.setState({
          roleHide: false,
        });
        console.log("response of Get Access by Role ID", response);
        if (response.data && response.data.error == false) {
          let data = response.data.data;
          console.log("konsa Data", data);

          let newData = {};
          data.forEach((ele) => {
            newData[ele.module_id] = ele;
          });
          console.log("newData", newData);

          this.setState({
            accessData: newData,
          });
        }
        if (response.data && response.data.error == true) {
          this.setState({
            accessData: "",
          });
        }
      }
    );
    this.setState({ role });
  };

  onChanged = (e, original) => {
    const { role, accessData } = this.state;
    let uniqueId = Object.values(accessData).find((val) => {
      console.log("RoleModuleAccess -> onChanged -> val", val);
      if (val.module_id == original.module_id) {
        return val.id;
      }
    });
    Object.values(accessData).map(
      (res) =>
        res.module_id == [e.currentTarget.name] &&
        this.setState({
          accessData: {
            ...this.state.accessData,
            [e.currentTarget.name]: {
              access_id: parseInt(e.currentTarget.value),
              module_id: original.module_id,
              role_id: role && role.roleId,
              id: uniqueId && uniqueId.id ? uniqueId.id : null,
              // createdBy: localStorage.getItem("employeeId"),
            },
          },
        })
    );

    this.setState({
      accessData: {
        ...this.state.accessData,
        [e.currentTarget.name]: {
          access_id: parseInt(e.currentTarget.value),
          module_id: original.module_id,
          role_id: role && role.roleId,
          id: uniqueId && uniqueId.id ? uniqueId.id : null,
          // createdBy: localStorage.getItem("employeeId"),
        },
      },
    });
  };

  handleSubmit = () => {
    let { accessData, role } = this.state;
    console.log("accessData...", accessData);
    let moduleaccess = [...Object.values(accessData)];
    console.log("accessMappingList", moduleaccess);

    let obj = {
      moduleaccess,
    };
    addrolebasedmoduleaccess(obj).then((response) => {
      console.log("response of add access", response);
      if (response.data && response.data.error == false) {
        fetchingrolebasedmoduleaccessbyemployeeid(role && role.roleId).then(
          (response) => {
            console.log("response of roleId", response);
          }
        );
        toast.success("The Access Mapping is done successfully !!!", {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      }
      if (response.data && response.data.error == true) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      }
    });
  };

  render() {
    let { roleOption, pageData, accessData, roleHide } = this.state;

    const columns = [
      {
        Header: "Module Name",
        Cell: ({ original }) => {
          return original.module_name ? original.module_name : null;
        },
      },
      {
        Header: "Read",
        Cell: ({ original }) => (
          <input
            type="radio"
            name={original.module_id}
            value="1"
            checked={
              accessData &&
              accessData[original.module_id] &&
              accessData[original.module_id].access_id == 1
            }
            onChange={(e) => this.onChanged(e, original)}
          />
        ),
      },
      {
        Header: "Write",
        Cell: ({ original }) => {
          return (
            <input
              type="radio"
              name={original.module_id}
              value="2"
              checked={
                accessData &&
                accessData[original.module_id] &&
                accessData[original.module_id].access_id == 2
              }
              onChange={(e) => this.onChanged(e, original)}
            />
          );
        },
      },
      {
        Header: "Hide",
        // width: 250,
        Cell: ({ original }) => {
          return (
            <input
              type="radio"
              name={original.module_id}
              value="3"
              checked={
                accessData &&
                accessData[original.module_id] &&
                accessData[original.module_id].access_id == "3"
              }
              onChange={(e) => this.onChanged(e, original)}
            />
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <div className="dash_grid sweet-loading">
          <ToastContainer />
          <LeftMenu />
          <main className="bg-white">
            <Header {...this.props} />
            <section className="container-fluid mr-5">
              <h5 className="text-center mt-5 mx-auto user-box">
                Rolewise Module Access Management
              </h5>
              {/* new code */}
              <div className="row mt-4">
                <div className="col-md-4">
                  <Select
                    className="m-0"
                    // value={}
                    onChange={this.handleRole}
                    // onBlur={() => this.onValidate("role")}
                    options={roleOption}
                    valueKey="roleId"
                    labelKey="roleName"
                    getOptionLabel={(option) => option["roleName"]}
                    getOptionValue={(option) => option["roleId"]}
                    placeholder="Select Role"
                  />
                  {/* {errors.roleError && (
                    <span className="errorMsg">Please select role</span>
                  )} */}
                </div>
                <div className="d-flex col-md-8">
                  <button
                    disabled={roleHide}
                    onClick={this.handleSubmit}
                    className="add-btn btn btn-info btn-lg ml-auto"
                  >
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>

              <div className="mt-4">
                <ReactTable
                  data={pageData}
                  columns={columns}
                  defaultPageSize={10}
                />
              </div>
            </section>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default index;
