import React, { Component } from "react";
import { Link } from "react-router-dom";
// import Header from './Header';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      isLoading: false,
      invalidEmail: false,
      errors: {
        emailError: null,
      },
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  //validate if email  enetered is in correct format or not
  handleValidate = (e) => {
    const { errors } = this.state;
    let name = e.target.name;
    let value = e.target.value;
    if (value === "" || value === null || value === undefined) {
      this.setState({ errors: { ...errors, [name + "Error"]: true } });
    } else {
      this.setState({ errors: { ...errors, [name + "Error"]: false } });
    }
    if (name === "email") {
      let emailRegx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value && !value.match(emailRegx)) {
        this.setState({
          invalidEmail: true,
        });
      } else {
        this.setState({
          invalidEmail: false,
        });
      }
    }
  };

  handleSubmit = () => {
    const { errors, invalidEmail, email } = this.state;
    let userName = email;
    let isSuccess = true;
    for (var val in errors) {
      if (errors[val] === null || errors[val]) {
        errors[val] = true;
        isSuccess = false;
      }
    }
    if (invalidEmail) {
      isSuccess = false;
    }
    // let obj = { userName };
    // console.log(obj);

    if (isSuccess) {
      this.setState({ isLoading: true });
      // forgotPassword(userName).then(response => {
      //   console.log("gjfgd", response.data && response.data);
      //   this.setState({ isLoading: false });
      //   if (response.data && response.data.error === "false") {
      //     toast.success(response.data.message, {
      //       type: toast.TYPE.SUCCESS,
      //       autoClose: 2000
      //     });
      //   }

      //   if (response.data && response.data.error === "true") {
      //     toast.error(response.data.message, {
      //       type: toast.TYPE.ERROR,
      //       autoClose: 2000
      //     });
      //     return false;
      //   }
      // });
    }
    this.setState({
      email: "",
    });
    this.setState({ errors: { ...errors } });
  };

  render() {
    const { email, invalidEmail, errors } = this.state;
    return (
      <section className="login-section">
        <ToastContainer />

        <div className="page-container">
          <div style={{ width: "400px" }} className="content">
            <h3 className="text-center title-font mb-3">Forgot Password</h3>
            <p className="text-center w3-text-gray mb-5">
              Enter your email address below and we'll send you an email with
              instructions.
            </p>

            <div className="form-group">
              <input
                type="email"
                className="form-input"
                name="email"
                value={email}
                onChange={this.handleChange}
                onBlur={this.handleValidate}
                placeholder="Enter Email"
              />
              {errors.emailError && (
                <span className="errorMsg">Please enter email</span>
              )}
              {invalidEmail && (
                <span className="errorMsg">Please enter valid email</span>
              )}
            </div>
            <div className="form-group ">
              <button
                onClick={this.handleSubmit}
                className="form-submit btn btn-primary"
                disabled={this.state.isLoading}
              >
                Send Mail
                {this.state.isLoading ? (
                  <i
                    class="fa fa-spinner fa-spin"
                    style={{ fontSize: "24px" }}
                  ></i>
                ) : (
                  ""
                )}
              </button>
            </div>

            <p className="loginhere mt-0">
              <Link to="/" className="loginhere-link">
                <i className="fas fa-sign-in-alt mr-2"></i>Back to Login
              </Link>
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default ResetPassword;
