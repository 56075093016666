import { get, post } from "../httpInterceptor";

// API for get insurance deduction

export const getInsuranceDeductionAbove25 = () => {
  return get(
    `/sarvagram-credit/master/findallInsuranceDeductionAbove25New`
  ).then((res) => {
    console.log("RESPONSE :: getInsuranceDeductionAbove25 ::: ", res);
    return res;
  });
};

// API for save or update insurance deduction

export const addOrUpdateInsuranceDeductionAbove25 = (obj) => {
  return post(
    `/sarvagram-credit/master/saveorupdateInsuranceDeductionAbove25New`,
    obj,
    false
  ).then((res) => {
    console.log("addOrUpdateInsuranceDeductionAbove25", res);
    return res;
  });
};

// API for delete insurance deduction

export const deleteInsuranceDeductionByTenure = (tenure = null) => {
  return get(`/sarvagram-credit/master/deleteByTenure?tenure=${tenure}`).then(
    (res) => {
      console.log("RESPONSE :: deleteInsuranceDeductionByTenure ::: ", res);
      return res;
    }
  );
};

// API for get insurance deduction

export const getInsuranceDeductionBelow25 = () => {
  return get(
    `/sarvagram-credit/master/findallInsuranceDeductionBelow25New`
  ).then((res) => {
    console.log("RESPONSE :: getInsuranceDeductionAbove25 ::: ", res);
    return res;
  });
};

// API for save or update insurance deduction

export const addOrUpdateInsuranceDeductionBelow25 = (obj) => {
  return post(
    `/sarvagram-credit/master/saveorupdateInsuranceDeductionBelow25New`,
    obj,
    false
  ).then((res) => {
    console.log("addOrUpdateInsuranceDeductionBelow25", res);
    return res;
  });
};

// API for delete insurance deduction

export const deleteInsuranceDeductionByTenureBelow25 = (tenure = null) => {
  return get(
    `/sarvagram-credit/master/deleteByTenureBelow25?tenure=${tenure}`
  ).then((res) => {
    console.log(
      "RESPONSE :: deleteInsuranceDeductionByTenureBelow25 ::: ",
      res
    );
    return res;
  });
};
