import { get, post } from "../httpInterceptor";

// API for delete collateral

export const deleteCollateralById = (obj) => {
  return post(`/sarvagram-credit/master/deleteltvvaluebyid`, obj, false).then(
    (res) => {
      console.log("deleteRoiById", res);
      return res;
    }
  );
};

// API for get all collateral data

export const getAllCollateralData = () => {
  return get(`/sarvagram-credit/master/findAllltvvalue`).then((res) => {
    console.log("RESPONSE :: getAllCollateralData ::: ", res);
    return res;
  });
};

/* export const addProductFund = (obj) => {
    return post(`/sarvagram-credit/master/saveorupdateProductFunds`, obj,
        false
    ).then(res => {
        console.log("addProductFund", res);
        return res;
    });
} */

// API for save or update collateral data

export const addOrUpdateCollateral = (obj) => {
  return post(`/sarvagram-credit/master/saveOrUpdateltvtable`, obj, false).then(
    (res) => {
      console.log("addOrUpdateCollateral", res);
      return res;
    }
  );
};

// API for get all collateral data by ID

export const getCollateralById = (obj = {}) => {
  return post(`/sarvagram-credit/master/findltvbyid`, obj, false).then(
    (res) => {
      console.log("getCollateralById", res);
      return res;
    }
  );
};

// API for get all motion dropdown values

export const getAllMotionDropdown = () => {
  return get(`/sarvagram-credit/master/findallmotiontypedropdown`).then(
    (res) => {
      console.log("RESPONSE :: getAllMotionDropdown ::: ", res);
      return res;
    }
  );
};

// API for get all property dropdown value

export const getAllPropertyDropdown = () => {
  return get(`/sarvagram-credit/master/findallpropertytyperdropdown`).then(
    (res) => {
      console.log("RESPONSE :: getAllPropertyDropdown ::: ", res);
      return res;
    }
  );
};

// API for get all subtype dropdown value

export const getAllSubtypeDropdown = () => {
  return get(`/sarvagram-credit/master/findallpropertysubtyperdropdown`).then(
    (res) => {
      console.log("RESPONSE :: getAllSubtypeDropdown ::: ", res);
      return res;
    }
  );
};
