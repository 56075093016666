import { get, post } from "../httpInterceptor";

//list all Zones
export const getAllEvents = () => {
  return get(`/notification/findAllEventsForDropdown`).then((res) => {
    console.log("getAllEvents", res);
    return res;
  });
};

export const findAllNotificationTypeDropdown = () => {
  return get(`/notification/findAllNotificationTypeDropdown`).then((res) => {
    console.log("findAllNotificationTypeDropdown", res);
    return res;
  });
};

export const findAllNotificationListing = () => {
  return get(`/notification/findAllNotificationListing`).then((res) => {
    console.log("findAllNotificationListing", res);
    return res;
  });
};

export const saveorupdateDetails = (obj) => {
  return post(`/notification/saveOrUpdateNotification`, obj, false).then(
    (res) => {
      console.log("saveorupdateDetails", res);
      return res;
    }
  );
};

export const deleteNotificationById = (obj) => {
  return post(`/notification/deleteNotificationById`, obj, false).then(
    (res) => {
      console.log("deleteNotificationById", res);
      return res;
    }
  );
};

export const findNotificationById = (obj) => {
  return post(`/notification/findNotificationById`, obj, false).then((res) => {
    console.log("findNotificationById", res);
    return res;
  });
};

export const notificationImageAndVideoUpload = (obj) => {
  return post(`/notification/notificationImageAndVideoUpload`, obj, false).then(
    (res) => {
      console.log("notificationImageAndVideoUpload", res);
      return res;
    }
  );
};
