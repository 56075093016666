import { get, post } from "../httpInterceptor";

// API for get all MFG Multiplier

export const getMFGEmiMultiplier = () => {
  return get(`/sarvagram-credit/masternew/getmfgemimultiplier`).then((res) => {
    console.log("RESPONSE :: getmfgemimultiplier ::: ", res);
    return res;
  });
};

// API for save MFG multiplier

export const saveMFGEmiMultiplier = (obj) => {
  return post(
    `/sarvagram-credit/masternew/savemfgemimultiplier`,
    obj,
    false
  ).then((res) => {
    console.log("saveMFGEmiMultiplier", res);
    return res;
  });
};

// API for update MFG multiplier

export const updateMFGEmiMultiplier = (obj) => {
  return post(
    `/sarvagram-credit/masternew/updatemfgemimultiplier`,
    obj,
    false
  ).then((res) => {
    console.log("updateMFGEmiMultiplier", res);
    return res;
  });
};

// API for delete MFG multiplier

export const deleteMFGEmiMultiplier = (obj) => {
  return post(
    `/sarvagram-credit/masternew/deletefgemimultiplier`,
    obj,
    false
  ).then((res) => {
    console.log("deleteMFGEmiMultiplier", res);
    return res;
  });
};
