import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import "./styles.css";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddEditSpoke from "./AddEditSpoke.js";
import BulkUploadVillage from "./BulkUploadSpoke";
import FilterListIcon from "@mui/icons-material/FilterList";

import {
  findallspokes,
  searchSpokes,
} from "../../../Utils/Services/spokeMaster";
import MigrateSpoke from "./MigrateSpoke";
import {
  LocationSearching,
  SettingsOverscanOutlined,
} from "@mui/icons-material";
toast.configure();

const columns = [
  {
    title: "Spoke Id",
    field: "id",
  },
  { title: "Spoke Name", field: "spokeName" },
  { title: "Partner Id", field: "partnerId" },
  { title: "Pincode", field: "pincode" },
  { title: "Tehsil", field: "tehsil" },
  { title: "District", field: "district" },
  { title: "State", field: "state" },
  { title: "Date Of Establishment", field: "dateOfEstablishment" },
  {
    title: "Status",
    field: "status",
    render: (rowData) => {
      return rowData && rowData.status === true ? "ACTIVE" : "INACTIVE";
    },
  },
];

const SpokeMaster = (props) => {
  let [SpokeData, setSpokeData] = useState([]);
  let [productDropdownOptions, setproductDropdownOptions] = useState([]);
  let [showAddProductNormModal, setshowAddProductNormModal] = useState(false);
  let [showEditNormModal, setshowEditNormModal] = useState(false);
  let [editData, setEditData] = useState({});
  let [showBulUploadModal, setshowBulUploadModal] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [value, setValue] = useState("");
  const [searchPage, setSearchPage] = useState(0);
  const [showMigrateSpokeModal, setshowMigrateSpokeModal] = useState(false);
  const [reloadAfterSpokeChange, setReloadAfterSpokeChange] = useState(true);

  const columns = [
    {
      title: "Spoke Id",
      field: "id",
    },
    { title: "Spoke Name", field: "spokeName" },
    { title: "Partner Id", field: "partnerId" },
    { title: "Pincode", field: "pincode" },
    { title: "Tehsil", field: "tehsil" },
    { title: "District", field: "district" },
    { title: "State", field: "state" },
    { title: "Date Of Establishment", field: "dateOfEstablishment" },
    {
      title: "Status",
      field: "status",
      render: (row) => {
        return row && row.status === true ? "ACTIVE" : "INACTIVE";
      },
    },
  ];

  const search = useRef([]);

  useEffect(() => {
    spokeMaster(page);
    window.scrollTo(0, 0);
  }, []);

  //get all list village
  const spokeMaster = (page) => {
    if (page === undefined || page === null) {
      page = 0;
      findallspokes(page).then((response) => {
        if (response.error) {
          return;
        }
        if (response.data && response.data.error) {
          toast.error(response.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        } else {
          setSpokeData(response.data.content);
          setTotalPages(response.data.totalPages);
        }
      });
    }
    findallspokes(page).then((response) => {
      if (response.error) {
        return;
      }
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        setSpokeData(response.data.content);
        setTotalPages(response.data.totalPages);
      }
    });
  };

  const getData = (query) => {
    return new Promise((resolve, reject) => {
      let page = query.page;
      let keyword = query.search;
      searchSpokes(page, keyword)
        .then((result) => {
          console.log("****************************8", result);
          resolve({
            data: [...result.data.content],
            page: result.data.pageable.pageNumber,
            totalCount: result.data.totalElements,
          });
        }, [])
        .catch((err) => {
          reject({
            data: [],
            page: 0,
            totalCount: 0,
          });
        });
    });
  };

  const handleSearch = (e, keyword, page = 0) => {
    if (e) {
      search.current = e?.target?.value;
    } else {
      search.current = keyword;
    }

    searchSpokes(page, value).then((response) => {
      console.log("setsearch", response);
      setSearchPage(response.data.totalPages);

      // const x = document.getElementsByClassName("-pageJump");
      // console.log(x.firstElementChild);

      if (response.error) {
        return;
      }
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        setSpokeData(response.data.content);
        setTotalPages(response.data.totalPages);
      }
    });
  };

  const handleAddSpoke = (id) => {
    setshowAddProductNormModal(!showAddProductNormModal);
  };

  const handleEditSpokeMaster = (obj) => {
    setshowEditNormModal(!showEditNormModal);
    setEditData(obj);
  };

  const handleBulkUpload = () => {
    console.log("HAPPENED");
    setshowBulUploadModal(!showBulUploadModal);
  };

  const onPageChange = (index) => {
    console.log(index);
    setPage(index);
    if (value) {
      handleSearch(null, value, index);
    } else {
      spokeMaster(index);
    }
  };

  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box">
            Geographical Spoke Master
          </h5>
          <div className="d-flex align-items-center mt-5">
            {/* <div class="input-group" style={{ width: "25vw" }}>
              <input
                type="search"
                className="form-control"
                name="search"
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                placeholder="Search..."
                style={{ height: "2.5rem" }}
              />
              <span class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="submit"
                  onClick={() => handleSearch(page)}
                  style={{
                    border: "1px",
                    height: "2.5rem",
                    background: "#17a2b8",
                    color: "white",
                  }}
                >
                  <i class="fa fa-search"></i>
                </button>
              </span>
            </div> */}

            <button
              disabled={false}
              onClick={handleAddSpoke}
              className="add-btn ml-auto btn btn-info btn-lg"
            >
              {" "}
              Add{" "}
            </button>
            <button
              disabled={false}
              onClick={handleBulkUpload}
              className="bulk-upload-button btn btn-info btn-lg"
            >
              Bulk Upload Spoke
            </button>
            <button
              className="bulk-upload-button btn btn-info btn-lg"
              onClick={() => setshowMigrateSpokeModal(!showMigrateSpokeModal)}
            >
              Migrate Spoke
            </button>
            {showAddProductNormModal && (
              <AddEditSpoke
                action={"add"}
                SpokeMaster={getData}
                handleAddSpoke={handleAddSpoke}
                productDropdownOptions={productDropdownOptions}
                // page={page}
              />
            )}
            {showEditNormModal && (
              <AddEditSpoke
                action={"edit"}
                SpokeMaster={getData}
                editData={editData}
                handleEditSpokeMaster={handleEditSpokeMaster}
                productDropdownOptions={productDropdownOptions}
                // page={page}
              />
            )}
            {showMigrateSpokeModal && (
              <MigrateSpoke
                showMigrateSpokeModal={showMigrateSpokeModal}
                setshowMigrateSpokeModal={setshowMigrateSpokeModal}
                spokeMaster={spokeMaster}
              />
            )}
            {showBulUploadModal && (
              <BulkUploadVillage handleBulkUpload={handleBulkUpload} />
            )}
          </div>

          <div className={`mt-4 react-table hide-react-table-footer`}>
            <MaterialTable
              title=" "
              columns={columns}
              data={getData}
              options={{
                searchFieldAlignment: "left",
                actionsColumnIndex: -1,
                pageSize: 10,
                pageSizeOptions: [10],
                idSynonym: "id",

                headerStyle: {
                  fontWeight: "bold",
                  textAlign: "center",
                  backgroundColor: "#17a2b8",
                  color: "#FFF",

                  padding: "15px",
                },
                searchFieldStyle: {
                  width: 500,
                  height: 50,
                  marginBottom: 20,
                },
                tableLayout: "fixed",
              }}
              onRowClick={(event, row) => handleEditSpokeMaster(row)}
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default SpokeMaster;
