import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddProductCampaign from "./AddProductCampaign";
import { getAllProduct } from "./../../../Utils/Services/productmaster";
import { deleteProductCampaignById, getAllProductCampaign, saveUpdateProductCampaign } from "../../../Utils/Services/productCampaign";

toast.configure();

const SAMPLE_DATA = [
	{
		id: 1,
		campaign_desc: "Campaign A",
		campaign_id: 123,
		campaign_name: "Campaign A",
		end_dt: "2022-02-10",
		entry_dt: "2022-02-04",
		start_dt: "2022-02-04",
		update_dt: "2022-02-04",
		product: "Business Loans",
	},
	{
		id: 2,
		campaign_desc: "Campaign A",
		campaign_id: 123,
		campaign_name: "Campaign A",
		end_dt: "2022-02-10",
		entry_dt: "2022-02-04",
		start_dt: "2022-02-04",
		update_dt: "2022-02-04",
		product: "Business Loans",
	},
	{
		id: 3,
		campaign_desc: "Campaign A",
		campaign_id: 123,
		campaign_name: "Campaign A",
		end_dt: "2022-02-10",
		entry_dt: "2022-02-04",
		start_dt: "2022-02-04",
		update_dt: "2022-02-04",
		product: "Business Loans",
	},
];

const ProductCampaign = (props) => {
	let [showAddModal, setShowAddModal] = useState(false);
	let [showEditModal, setShowEditModal] = useState(false);
	let [productCampaignData, setProductCampaignData] = useState([]);
	let [editData, setEditData] = useState({});

	useEffect(() => {
		getProductCampaign();
	}, []);

	const getProductCampaign = () => {
		//product campaign api call will be here
		getAllProductCampaign().then((response) => {
			if (response.error) {
				return;
			}
			if (response.data && response.data.error) {
				toast.error(response.data.message, {
					type: toast.TYPE.ERROR,
					autoClose: 2000,
				});
			} else {
				setProductCampaignData(response.data.data);
			}
		});
		// setProductCampaignData(SAMPLE_DATA);
	};

	const deleteProductCampaign = (id) => {
		//delete api call will be here
		confirmAlert({
			title: "Confirm to delete",
			message: "Are you sure to do this.",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						console.log("yes");
						const obj = {
							id: id,
						};
						deleteProductCampaignById(obj)
							.then((res) => {
								if (res.error) {
									toast.error(res.error);
								} else {
									toast.success("Deleted Successfully");
									// setProductCampaignData(productCampaignData.filter((item) => item.id !== id));
									getProductCampaign();
								}
							})
							.catch((err) => {
								console.log("err", err);
							});
					},
				},
				{
					label: "No",
					onClick: () => {
						console.log("no");
					},
				},
			],
		});
	};

	const columns = [
		{
			Header: "SR. No",
			width: 70,
			Cell: (data) => {
				// console.log("dat/a...", data)
				return data.index + 1;
			},
		},
		// {
		// 	Header: "Campaign Id",
		// 	width: 170,
		// 	Cell: (data) => {
		// 		const { original } = data;
		// 		return original && original.campaign_id ? original.campaign_id : null;
		// 	},
		// },
		{
			Header: "Campaign Name",
			width: 250,
			Cell: (data) => {
				const { original } = data;
				return original && original.campaignName ? original.campaignName : null;
			},
		},
		{
			Header: "Product",
			width: 250,
			Cell: (data) => {
				const { original } = data;
				return original && original.productType ? original.productType : null;
			},
		},
		{
			Header: "Campaign Start Date",
			width: 250,
			Cell: (data) => {
				const { original } = data;
				return original && original.startDate ? original.startDate : null;
			},
		},
		{
			Header: "Campaign End Date",
			width: 250,
			Cell: (data) => {
				const { original } = data;
				return original && original.endDate ? original.endDate : null;
			},
		},
		{
			Header: "Action",
			width: 125,
			disabled: false,
			accessor: "delete",
			accessor: "edit",

			Cell: ({ original }) => {
				return (
					<div disabled={false} style={{ display: "flex", justifyContent: "space-around" }}>
						<i disabled={false} onClick={() => deleteProductCampaign(original.id)} className="fa fa-trash" style={{ cursor: "pointer" }} />
						<i
							disabled={false}
							onClick={() => handleEditCampaignModal(original)}
							className="fas fa-user-edit"
							style={{ cursor: "pointer" }}
						/>
					</div>
				);
			},
		},
	];

	const handleAddCampaignModal = (obj) => {
		// setProductCampaignData([...productCampaignData, obj]);
		setShowAddModal(!showAddModal);
	};

	const handleEditCampaignModal = (obj) => {
		setShowEditModal(!showEditModal);
		setEditData(obj);
	};

	const addProductCampaign = (obj) => {
		setShowAddModal(!showAddModal);
		let newCampaign = {
			...obj,
			startDate: format(obj.startDate, "yyyy-MM-dd"),
			endDate: format(obj.endDate, "yyyy-MM-dd"),
		};
		console.log("newCampaign...", newCampaign);
		saveUpdateProductCampaign(newCampaign)
			.then((res) => {
				if (res.error) {
					toast.error(res.data.message);
				} else {
					if (res.data.error) {
						toast.error(res.data.message);
					} else {
						toast.success(res.data.message);
						getProductCampaign();
					}
				}
			})
			.catch((err) => {
				console.log("err", err);
			});
	};

	const editProductCampaign = (obj) => {
		setShowEditModal(!showEditModal);
		let editedCampaign = {
			...obj,
			startDate: format(obj.startDate, "yyyy-MM-dd"),
			endDate: format(obj.endDate, "yyyy-MM-dd"),
		};
		console.log("editedCampaign...", editedCampaign);
		saveUpdateProductCampaign(editedCampaign)
			.then((res) => {
				if (res.error) {
					toast.error("Server Error");
				} else {
					if (res.data.error) {
						toast.error("Error Updating Campaign");
					} else {
						toast.success("Campaign Updated Successfully");
						getProductCampaign();
					}
				}
			})
			.catch((err) => {
				console.log("err", err);
			});
	};

	return (
		<div className="dash_grid">
			<ToastContainer />
			<LeftMenu />
			<main className="bg-white">
				<Header {...props} />
				<section className="container-fluid mb-5">
					<h5 className="text-center mt-5 mx-auto user-box">Product Campaign</h5>
					<div className="d-flex align-items-center mt-5">
						<button disabled={false} onClick={handleAddCampaignModal} className="add-btn ml-auto btn btn-info btn-lg">
							Add
						</button>
						{showAddModal && (
							<AddProductCampaign action={"add"} handleAddCampaignModal={handleAddCampaignModal} addProductCampaign={addProductCampaign} />
						)}
						{showEditModal && (
							<AddProductCampaign
								action={"edit"}
								editData={editData}
								handleEditCampaignModal={handleEditCampaignModal}
								editProductCampaign={editProductCampaign}
							/>
						)}
					</div>
					<div className={`mt-4 react-table`}>
						<ReactTable data={productCampaignData} columns={columns} defaultPageSize={10} />
					</div>
				</section>
			</main>
		</div>
	);
};

export default ProductCampaign;
