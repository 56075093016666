import React, { useState, useEffect, Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from "lodash";
import DatePicker from "react-datepicker";
import CloseModal from "../../../Components/CloseModal";
import {
  saveorupdatespokemaster,
  getdetailsbypincode,
} from "../../../Utils/Services/spokeMaster";

toast.configure();

const initForm = {
  spokeName: "",
  partnerId: null,
  pincode: "",
  tehsil: "",
  state: "",
  dateOfEstablishment: "",
  district: "",
  status: "",
  errors: {
    spokeName: null,
    partnerId: null,
    pincode: null,
    dateOfEstablishment: null,
    tehsil: null,
    district: null,
    state: null,
    status: null,
  },
};

export class AddEditSpoke extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tehsilsdropdownvalue: [],
      pincodedetails: [],
      showModal: false,
      Loading: false,
      showInner: false,
      form: cloneDeep(initForm),
      stateDropdownValues: [],
      districtDropdownValues: [],
      selectedDate: "",
    };
  }

  componentDidMount = () => {
    let { form, selectedDate } = this.state;
    this.setState({ showModal: true });
    console.log("props.editData", this.props.editData);
    if (this.props.editData && this.props.action == "edit") {
      //this.alldetailsbypincode(this.props.editData.pincode);
      let {
        form,
        selectedDate,
        tehsilsdropdownvalue,
        districtDropdownValues,
        stateDropdownValues,
      } = this.state;
      selectedDate = this.props.editData.dateOfEstablishment;
      let obj = { ...form, ...this.props.editData };
      tehsilsdropdownvalue.push({ value: obj.tehsil, label: obj.tehsil });
      districtDropdownValues.push({ value: obj.district, label: obj.district });
      stateDropdownValues.push({ value: obj.state, label: obj.state });
      this.setState({
        form: obj,
        selectedDate,
        tehsilsdropdownvalue,
        districtDropdownValues,
        stateDropdownValues,
      });
    }
    // if(this.props.action === "edit"){
    //   this.findVillage(this.props.editData.pincode)
    // }
  };

  //tehsil district state by pinncode
  alldetailsbypincode = (pincode) => {
    let {
      form,
      stateDropdownValues,
      districtDropdownValues,
      tehsilsdropdownvalue,
    } = this.state;
    console.log("form is ", form);
    console.log("pincode is ", pincode);
    getdetailsbypincode(pincode)
      .then((response) => {
        console.log("pincodeaddress", response);
        if (response.error) {
          return;
        }
        if (response.data.error) {
          // toast.error("This Pincode is Invalid", {
          //   type: toast.TYPE.ERROR,
          //   autoClose: 4000,
          // });
          return;
        } else {
          const stateSet = new Set();
          const districtSet = new Set();
          const tehsilSet = new Set();
          const tehsils = [];
          const states = [];
          const districts = [];

          for (var i = 0; i < response.data.length; i++) {
            stateSet.add(response.data[i].state);
          }
          for (var i of stateSet) {
            states.push({ value: i, label: i });
          }
          for (var j = 0; j < response.data.length; j++) {
            districtSet.add(response.data[j].district);
          }
          for (var j of districtSet) {
            districts.push({ value: j, label: j });
          }
          for (var k = 0; k < response.data.length; k++) {
            tehsilSet.add(response.data[k].tehsil);
          }
          for (var k of tehsilSet) {
            tehsils.push({ value: k, label: k });
          }
          form.tehsil = tehsils && tehsils[0] && tehsils[0].value;
          form.district = districts && districts[0] && districts[0].value;
          form.state = states && states[0] && states[0].value;
          this.setState({
            form,
            stateDropdownValues: states,
            districtDropdownValues: districts,
            tehsilsdropdownvalue: tehsils,
          });
        }
      })
      .catch((err) => {
        console.log("error is: ", err);
      });
  };
  handleModalClose = () => {
    this.setState({ showInner: !this.state.showInner });
    // this.this.props.addRole();
  };

  innerModalClose = (modclose) => {
    if (!modclose) {
      this.handleModalClose();
    }
    this.setState({ showModal: false });
    if (this.props.action == "edit") {
      this.props.handleEditSpokeMaster();
    } else {
      this.props.handleAddSpoke();
    }
  };
  //saveupdate api
  handleSubmit = (performActive) => {
    let { form, Loading } = this.state;
    console.log("status", form.status);
    if (this.props.action === "edit" && performActive) {
      form.status = form.status === false ? true : false;
    }
    form.partnerId = form.partnerId === "" ? null : form.partnerId;
    let obj = form;
    this.setState({ Loading: true });
    saveorupdatespokemaster(obj).then((response) => {
      this.setState({ Loading: false });
      if (response.error) {
        toast.error(response.message, {
          type: "error",
          autoClose: 2000,
        });
      } else {
        if (this.props.editData && this.props.action == "edit") {
          toast.success("Data Updated Succsesfully", {
            type: toast.TYPE.SUCCESS,
            autoClose: 4000,
          });
          this.props.handleEditSpokeMaster();
          window.location.reload();
        } else {
          toast.success("Data Saved Succsesfully", {
            type: toast.TYPE.SUCCESS,
            autoClose: 4000,
          });
          this.props.handleAddSpoke({});
          window.location.reload();
        }
      }
    });
    console.log("loading", Loading);
  };

  handleInputChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("handleInputChange", e, name, e.target.value);
    form[name] = e.target.value;
    this.setState({ form });
  };
  //for handle partnerId
  handlepartnerId = (e, name) => {
    // e.preventDefault();
    let { form } = this.state;
    console.log("handlepartnerId", e, name);
    form[name] = e.target.value;
    this.setState({ form });
  };
  //handle validation Partner Id
  handleValidatepartnerId = (e, name) => {
    e.preventDefault();
    let { form, errors } = this.state;
    let value = form[name];
    // console.log("uuu", e, name, value.length);
    if (
      (name == "partnerId" && value === null) ||
      e.target.value.length === 0
    ) {
      return this.setState({
        form: { ...form, errors: { ...form.errors, partnerId: null } },
      });
    }

    if (
      value === "" ||
      value === null ||
      value === undefined ||
      (name == "partnerId" && value.length < 4 && value.length !== 6)
    ) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      form.errors[name] = null;
      this.setState({ form });
    }
  };
  //pincode valdtn
  restrictAlphabets = (e) => {
    const regx = "^[0-9]*$";
    if (e.key.match(regx)) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  handleBranchTehsilDistrict = (e, name) => {
    let regx = /^[a-zA-Z][a-zA-Z0-9()\-\. ]*$/g;
    if (e.target.value.match(regx) || e.target.value === "") {
      let { form } = this.state;
      form[name] = e.target.value;
      this.setState({ form });
    }
  };

  handleValidateBranchtehsildiststate = (e, name) => {
    e.preventDefault();
    let { form, errors } = this.state;
    console.log("uuu", e, name);
    let value = form[name];
    if (
      value === "" ||
      value === null ||
      value === undefined ||
      (name == "spokeName" && value && e.target.value.trim().length < 3)
    ) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      form.errors[name] = null;
      this.setState({ form });
    }
  };

  handleStateSelectChange = (e, name) => {
    console.log("handleStateSelectChange", e, name);
    let { form } = this.state;
    let { value } = e;
    form[name] = value;
    this.setState({ form });
  };

  handlePincode = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("handlePincode", e, name, e.target.value);
    this.clearAll();
    form[name] = e.target.value;
    this.setState({ form });
    if (name === "pincode" && e.target.value.length > 5) {
      this.alldetailsbypincode(e.target.value);
    }
  };
  clearAll = () => {
    let {
      form,
      stateDropdownValues,
      tehsilsdropdownvalue,
      districtDropdownValues,
    } = this.state;
    form.tehsil = "";
    form.district = "";
    form.state = "";
    stateDropdownValues.splice(0, stateDropdownValues.length);
    districtDropdownValues.splice(0, districtDropdownValues.length);
    tehsilsdropdownvalue.splice(0, tehsilsdropdownvalue.length);

    this.setState({
      form,
      stateDropdownValues,
      tehsilsdropdownvalue,
      districtDropdownValues,
    });
  };

  handlevalidationDateOfEstablishment = (e, name) => {
    e.preventDefault();
    let { form, errors } = this.state;
    let value = form[name];
    if (value === "" || value === null || value === undefined) {
      form.errors[name] = true;
      this.setState({ errors });
    } else {
      form.errors[name] = null;
      this.setState({ errors });
    }
  };
  handleValidatePincode = (e, name) => {
    e.preventDefault();
    let { form, errors } = this.state;
    console.log("uuu", e, name);
    let value = form[name];
    if (
      value === "" ||
      value === null ||
      value === undefined ||
      (name == "pincode" && value && value.length !== 6)
    ) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      form.errors[name] = null;
      // this.alldetailsbypincode(form.pincode);
      this.setState({ form });
    }
  };

  handleDateOfEstablishment = (date, e) => {
    console.log("handleDateOfEstablishment", date, e);
    let { form, selectedDate } = this.state;
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    console.log(year + "-" + month + "-" + dt);
    form.dateOfEstablishment = dt + "-" + month + "-" + year;
    selectedDate = date;
    this.setState({ form, selectedDate });
  };

  render() {
    let {
      form,
      showModal,
      showInner,
      Loading,
      tehsilsdropdownvalue,
      stateDropdownValues,
      districtDropdownValues,
      selectedDate,
    } = this.state;
    let { errors } = form;
    let diableSave = false;
    

    console.log("STATE IS ", this.state.form);
    console.log("PROPS", this.props);

    let isRequiredFeildFilled = false;
    Object.keys(form).map((name) => {
      console.log("form", form[name]);
      if (
        name == "partnerId" ||
        name == "branchId" ||
        name == "status" ||
        name == "latitude" ||
        name == "longitude"
      ) {
        return;
      }

      if (
        form[name] === "" ||
        form[name] === null ||
        form[name] === undefined
      ) {
        console.log("error field,", name, form[name]);
        isRequiredFeildFilled = true;
      }
    });
    // form.spokeMapping=mappedBranchObj;

    Object.keys(errors).map((error) => {
      console.log("error", errors[error]);
      if (errors[error] == true || errors[error] != null) {
        diableSave = true;
        //return true;
      } else {
        diableSave = false;
      }
    });

    

    console.log("diableSave", diableSave);
    console.log("isRequiredFieldFillled", isRequiredFeildFilled);
    console.log("loading", Loading);

    console.log("form", form);
    return (
      <React.Fragment>
        <Modal
          className="product-norm-container"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showModal}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>
              {this.props.action == "edit"
                ? "Edit Spoke"
                : "Spoke Creation Form"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            {Loading ? (
              <h4 className="text-center">LOADING...</h4>
            ) : (
              <>
                <div className="row ">
                  <div className="mb-4 col-md-4">
                    <label>
                      <i className="text-danger">*</i>Spoke Name
                    </label>
                    {console.log("spoke details are: ", form)}
                    <input
                      disabled={this.props.action === "edit"}
                      type="text"
                      pattern="\d*"
                      //   minLength="3"
                      maxLength="100"
                      // className="form-input"
                      name="spokeName"
                      value={form && form.spokeName}
                      onChange={(e) => {
                        this.handleBranchTehsilDistrict(e, "spokeName");
                      }}
                      onBlur={(e) =>
                        this.handleValidateBranchtehsildiststate(e, "spokeName")
                      }
                      placeholder="Enter Spoke"
                      className={`form-input ${
                        errors.spokeName ? "error-input-border" : ""
                      } `}
                    />
                    {errors.spokeName && (
                      <span className="errorMsg">
                        {errors.spokeName === true
                          ? "Enter Valid Spoke Name"
                          : errors.spokeName}
                      </span>
                    )}
                  </div>
                  <div className="mb-4 col-md-4">
                    <label>
                      {/* <i className="text-danger">*</i> */}
                      Partner Id
                    </label>
                    <input
                      type="text"
                      pattern="\d*"
                      maxLength="6"
                      disabled={true}
                      //   className="form-input"
                      name="partnerId"
                      value={form && form.partnerId}
                      onKeyPress={this.restrictAlphabets}
                      onChange={(e) => {
                        this.handlepartnerId(e, "partnerId");
                      }}
                      onBlur={(e) =>
                        this.handleValidatepartnerId(e, "partnerId")
                      }
                      placeholder="Enter Partner Id"
                      className={`form-input ${
                        errors.partnerId ? "error-input-border" : ""
                      } `}
                      // id="pincode"
                    />
                    {errors.partnerId && (
                      <span className="errorMsg">
                        {errors.partnerId == true
                          ? "Please enter valid Partner Id"
                          : errors.partnerId}
                      </span>
                    )}
                  </div>
                  <div className="mb-4 col-md-4 ">
                    <label>
                      <i className="text-danger">*</i> Pincode
                    </label>
                    <input
                      type="text"
                      pattern="\d*"
                      //  className="form-input"
                      name="pincode"
                      maxLength="6"
                      //id="pincode"
                      value={form && form.pincode}
                      onKeyPress={this.restrictAlphabets}
                      onChange={(e) => {
                        this.handlePincode(e, "pincode");
                      }}
                      onBlur={(e) => this.handleValidatePincode(e, "pincode")}
                      placeholder="Enter Pincode"
                      className={`form-input ${
                        errors.pincode ? "error-input-border" : ""
                      } `}
                      // id="pincode"
                    />
                    {errors.pincode && !form.pincode && (
                      <span className="errorMsg">
                        {errors.pincode === true
                          ? "Please enter valid pincode"
                          : errors.pincode}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row ">
                  <div className="mb-4 col-md-4 ">
                    <label>
                      Tehsil<i className="text-danger">*</i>
                    </label>
                    <Select
                      value={
                        tehsilsdropdownvalue.filter(
                          (item) => item.value == form.tehsil
                        ) || ""
                      }
                      onChange={(e) => {
                        this.handleStateSelectChange(e, "tehsil"); //the same logic can be used for this input also
                      }}
                      onBlur={(e) =>
                        this.handleValidateBranchtehsildiststate(e, "tehsil")
                      }
                      name="tehsil"
                      options={tehsilsdropdownvalue}
                      placeholder="Select Tehsil"
                      valueKey="label"
                      labelKey="value"
                      getOptionLabel={(option) => option["label"]}
                      getOptionValue={(option) => option["value"]}
                      className={`${errors.state ? "error-input-border" : ""} `}
                      isDisabled={false}
                    />
                    {errors.tehsil && !form.tehsil && (
                      <span className="errorMsg">
                        {errors.tehsil === true
                          ? "Please enter tehsil"
                          : errors.tehsil}
                      </span>
                    )}
                  </div>

                  <div className="mb-4 col-md-4">
                    <label>
                      <i className="text-danger">*</i>District
                    </label>
                    <Select
                      value={
                        districtDropdownValues.filter(
                          ({ value }) => value == form.district
                        ) || ""
                      }
                      onChange={(e) => {
                        this.handleStateSelectChange(e, "district"); //the same logic can be used for this input also
                      }}
                      onBlur={(e) =>
                        this.handleValidateBranchtehsildiststate(e, "district")
                      }
                      name="district"
                      options={districtDropdownValues}
                      placeholder="Select district"
                      valueKey="label"
                      labelKey="value"
                      getOptionLabel={(option) => option["label"]}
                      getOptionValue={(option) => option["value"]}
                      className={`${errors.state ? "error-input-border" : ""} `}
                      isDisabled={false}
                    />
                    {errors.district && !form.district && (
                      <span className="errorMsg">
                        {errors.district === true
                          ? "Please select district"
                          : errors.district}
                      </span>
                    )}
                  </div>

                  <div className="mb-4 col-md-4">
                    <label>
                      <i className="text-danger">*</i>State
                    </label>
                    <Select
                      value={
                        stateDropdownValues.filter(
                          ({ value }) => value == form.state
                        ) || ""
                      }
                      onChange={(e) => {
                        this.handleStateSelectChange(e, "state"); //the same logic can be used for this input also
                      }}
                      onBlur={(e) =>
                        this.handleValidateBranchtehsildiststate(e, "state")
                      }
                      name="state"
                      options={stateDropdownValues}
                      placeholder="Select State"
                      valueKey="label"
                      labelKey="value"
                      getOptionLabel={(option) => option["label"]}
                      getOptionValue={(option) => option["value"]}
                      className={`${errors.state ? "error-input-border" : ""} `}
                      isDisabled={false}
                    />
                    {errors.state && !form.state && (
                      <span className="errorMsg">
                        {errors.state == true
                          ? "Please select state"
                          : errors.state}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="mb-4 col-md-4">
                    <label>
                      {" "}
                      <i className="text-danger">*</i>
                      Date of Establishment{" "}
                    </label>
                    <div class="inner-addon right-addon">
                      <DatePicker
                        dateFormat="dd-MM-yyyy"
                        placeholderText="DD-MM-YYYY"
                        value={form.dateOfEstablishment}
                        onChange={this.handleDateOfEstablishment}
                        onBlur={(e) =>
                          this.handlevalidationDateOfEstablishment(
                            e,
                            "dateOfEstablishment"
                          )
                        }
                        className={`form-input ${
                          errors.dateOfEstablishment ? "error-input-border" : ""
                        } `}
                        disabled={false}
                        maxDate={new Date()}
                      />
                    </div>
                    {errors.dateOfEstablishment && (
                      <span className="errorMsg">
                        Please Select Date Of Establishment
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {this.props.action === "edit" ? (
              <Button
                className="success"
                onClick={() => this.handleSubmit(true)}
              >
                {form.status === true ? "Inactive" : "Active"}
              </Button>
            ) : null}
            <Button className="btn-danger" onClick={this.handleModalClose}>
              Cancel
            </Button>
            <Button
              className="btn-success"
              disabled={diableSave || isRequiredFeildFilled || Loading}
              onClick={() => this.handleSubmit(false)}
            >
              {this.props.action == "edit" ? "Update" : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
        <CloseModal
          show={showInner}
          button2={this.handleModalClose}
          button1={this.innerModalClose}
          title="Do you want to close ?"
        />
      </React.Fragment>
    );
  }
}

export default AddEditSpoke;
