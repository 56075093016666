import React, { Component } from "react";
import LeftMenu from "../../../Components/LeftMenu";
import Header from "../../../Components/Header";
import FranchiseeOnboardingMasterForm from "./FranchiseeOnboardingMasterForm";

export class FranchiseeOnboardingMaster extends Component{
    state = {
        search: "",
        showModal: false,
        loading: false,
    };
    addFranchiseeOnboardingMaster = () => {
        this.setState({
            showModal: !this.state.showModal,
            
        });
    };
    
  
   
    render(){
        const { search, loading} = this.state;
        return(
         <div>
             <div className="dash_grid">
                <LeftMenu />
                <main className="bg-white">
                    <Header {...this.props} />
                    <section className="container-fluid">
                        <div className="d-flex align-items-center mt-5">
                        <FranchiseeOnboardingMasterForm addFranchiseeOnboardingMaster={this.addFranchiseeOnboardingMaster} />
                            {this.state.showModal && <FranchiseeOnboardingMasterForm addFranchiseeOnboardingMaster={this.addFranchiseeOnboardingMaster} />}
                        </div>
                    </section>
                </main>
            </div>
         </div>
        )
    }
}

export default FranchiseeOnboardingMaster