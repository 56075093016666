import React, { useState, useEffect } from "react";
import Password from "./AdminModule/ChangePassword/Password";
// import ChangePassword from "../src/Pages/ChangePassword";
import ForgetPassword from "../src/Pages/ForgetPassword";
import ResetPassword from "../src/Pages/ResetPassword";
import CreateCampaign from "./AdminModule/Notification/CreateCampaign";

import { Route, Switch, Redirect } from "react-router";
import { HashRouter as Router } from "react-router-dom";

import FrankingFee from "./AdminModule/Masters/FrankingFee/index.js";
import ProductFundingNorms from "./AdminModule/Masters/ProductFundingNorms";
import DashBoard from "./AdminModule/Dashboard/index.js";
import ProcessingFee from "./AdminModule/Masters/ProcessingFee";
import LegalValutionFee from "./AdminModule/Masters/LegalValuation";
import Roi from "./AdminModule/Masters/Roi";
import Collateral from "./AdminModule/Masters/Collateral";
import Loader from "../src/Components/Landing";
import DfpMaster from "./AdminModule/Masters/DfpMaster/index.js";
import InsuranceDeduction from "./AdminModule/Masters/InsuranceDedection.js/index.js";
import SegmentMaster from "./AdminModule/Masters/SegmentMaster/index";
import ProductMaster from "./AdminModule/Masters/ProductMaster/index";
import LevelMaster from "./AdminModule/Masters/LevelMaster/index";
import FundingMaster from "./AdminModule/Masters/FundingGridMaster/index";
import MFGMaster from "./AdminModule/Masters/MFGEmiMaster/index";
import GoldOnboardingMaster from "./AdminModule/OnboardingMaster/GoldOnboardingMaster";
import GoldApprovalMaster from "./AdminModule/OnboardingMaster/GoldOnboardingMaster/GoldApprovalMaster";
import FranchiseApprovalMaster from "./AdminModule/OnboardingMaster/FranchiseeOnboardingMaster/FranchiseApprovalMaster";
import GoldAppraiserMasterListing from "./AdminModule/OnboardingMaster/GoldOnboardingMaster/GoldAppraiserMasterListing";
import FranchiseAppraiserMasterListing from "./AdminModule/OnboardingMaster/FranchiseeOnboardingMaster/FranchiseAppraiserMasterListing";
import FranchiseeOnboardingMaster from "./AdminModule/OnboardingMaster/FranchiseeOnboardingMaster/index.js";
import IncomeMultiplier from "./AdminModule/Masters/IncomeMultiplier/index";
import Familymember from "./AdminModule/Masters/Familymember/index";
import RoleManagement from "./AdminModule/Masters/RoleManagement/index";
import UserManagement from "./AdminModule/UserManagement/index";
import AccessManagement from "./AdminModule/AccessManagement/index";
import CancelMaster from "./AdminModule/Masters/CancellationMaster/index";
import ServiceMaster from "./AdminModule/Masters/ServiceMaster/index";
import VillageMaster from "./AdminModule/Masters/VillageMaster/index";
import BranchMaster from "./AdminModule/GeographyMaster/BranchMaster/index";
import SpokeMaster from "./AdminModule/GeographyMaster/SpokeMaster/index";

import CancellationPerMaster from "./AdminModule/Masters/CancellationPerMaster/index";
import Payment from "./AdminModule/Masters/Payment";
import VillageSpoke from "./AdminModule/Masters/VillageSpoke/index";
import WebNotification from "./AdminModule/Masters/WebNotificationMaster/index";
import ProductCampaign from "./AdminModule/Masters/ProductCampaign/index";
import { getUserInfo } from "./Utils/Services/user";
import CampaignMediumMaster from "./AdminModule/MarketingMasters/CampaignMediumMaster/CampaignMediumIndex";
import CampaignNameMaster from "./AdminModule/MarketingMasters/CampaignNameMaster/CampaignNameIndex";
import GoldAuditMaster from "./AdminModule/Masters/GoldAMS/GoldAuditMaster";
import EmployeeCreation from "./AdminModule/EmployeeMaster/EmployeeCreation/index";

const onlyUserManagementAccess = ["BM", "ZBH", "ZCM", "RBH", "RCM"];
const onlyLeaderboardCampaignAccess = ["SH", "PM", "AM", "MISE"];
const onlyEmployeeMasterAccess = ["BM", "ZBH", "ZCM", "RBH", "RCM"];

const PrivateRouteUser = (props) => {
  console.log("Private Route props", props);
  const Component = props.component;
  const role = props.role;
  const userInfo = props.userInfo;

  if (
    onlyUserManagementAccess.includes(role) &&
    props.location.pathname !== "/user-management"
  ) {
    return <Redirect to="/user-management" />;
  }
  if (
    onlyEmployeeMasterAccess.includes(role) &&
    props.location.pathname !== "/employee-master"
  ) {
    return <Redirect to="/employee-master" />;
  }

  if (
    onlyLeaderboardCampaignAccess.includes(role) &&
    props.location.pathname !== "/CreateCampaign"
  ) {
    return <Redirect to="/CreateCampaign" />;
  }

  return (
    <Route
      render={(props) => {
        // console.log("sadsadsa",localStorage.getItem("userData"))
        return localStorage.getItem("userData") ? (
          <Component {...props} role={role} userInfo={userInfo} />
        ) : (
          <Redirect to={{ pathname: `/admin/${null}/${null}/${null}` }} />
        );
      }}
    />
  );
};

// logout from credit module path
export const AdminLogout = () => {
  localStorage.clear();
  window.location.href = `${window.location.protocol}//${window.location.hostname}:5000/log-in`;
  return <div></div>;
};

function App(props) {
  let { history } = props;
  // const [role, setRole] = useState("notFetched");
  const [userInfo, setUserInfo] = useState({});

  let role =
    typeof userInfo === "object"
      ? userInfo.role
        ? userInfo.role
        : "notFetched"
      : "notFetched";

  console.log("role information", role, userInfo);

  useEffect(() => {
    if (localStorage.getItem("userData")) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      let obj = {
        employeeId: userData.adminEmployeeId,
        // moduleId: 2,
      };
      // newGetUserByEmployeeId(obj)
      // 	.then((res) => {
      // 		setRole(res.data.data.user.role.roleCode && res.data.data.user.role.roleCode);
      // 	})
      // 	.catch((err) => {
      // 		console.log("err", err);
      // 	});
      getUserInfo(userData.adminEmployeeId)
        .then((res) => {
          console.log("new api", res.data);
          setUserInfo(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/">
          <Loader role={role} />
        </Route>
        <Route exact path="/admin/:adminAccessToken/:adminEmployeeId/:adminId">
          <Loader role={role} />
        </Route>
        <Route exact path="/forgetpassword" component={ForgetPassword} />
        <Route exact path="/resetpassword" component={ResetPassword} />
        <Route exact path="/admin-logout" component={AdminLogout} />
        <PrivateRouteUser
          exact
          role={role}
          path="/user-management"
          userInfo={userInfo}
          component={UserManagement}
        />
        {/*<PrivateRouteUser exact role={role} path="/employee-master" userInfo={userInfo} component={EmployeeMaster} />*/}
        <PrivateRouteUser
          exact
          role={role}
          path="/employee-creation"
          component={EmployeeCreation}
        />

        <PrivateRouteUser
          exact
          role={role}
          path="/dashboard"
          component={DashBoard}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/role-management"
          component={RoleManagement}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/access-management"
          component={AccessManagement}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/product-campaign"
          component={ProductCampaign}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/master-gold-audit"
          component={GoldAuditMaster}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/master-franking-fee"
          component={FrankingFee}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/master-product-funding-norms"
          component={ProductFundingNorms}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/master-processing-fee"
          component={ProcessingFee}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/master-legal-valuation-fee"
          component={LegalValutionFee}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/master-roi"
          component={Roi}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/master-collateral-ltv"
          component={Collateral}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/master-dfp-master"
          component={DfpMaster}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/master-segment"
          component={SegmentMaster}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/master-product"
          component={ProductMaster}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/master-level"
          component={LevelMaster}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/master-funding"
          component={FundingMaster}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/master-MFGEMI"
          component={MFGMaster}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/master-insurance-deduction"
          component={InsuranceDeduction}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/master-income-multiplier"
          component={IncomeMultiplier}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/gold-onboarding"
          component={GoldOnboardingMaster}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/franchisee-onboarding"
          component={FranchiseeOnboardingMaster}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/gold-approval-master"
          component={GoldApprovalMaster}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/Franchise-approval-master"
          component={FranchiseApprovalMaster}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/gold-appraiser-master-listing"
          component={GoldAppraiserMasterListing}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/Franchise-appraiser-master-listing"
          component={FranchiseAppraiserMasterListing}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/change-password"
          component={Password}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/master-family-member"
          component={Familymember}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/Cancel-master"
          component={CancelMaster}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/Service-master"
          component={ServiceMaster}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/Village-master"
          component={VillageMaster}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/Branch-master"
          component={BranchMaster}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/spoke-master"
          component={SpokeMaster}
        />

        <PrivateRouteUser
          exact
          role={role}
          path="/Cancellation-per-master"
          component={CancellationPerMaster}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/VillageSpoke-master"
          component={VillageSpoke}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/Web-Notification-master"
          component={WebNotification}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/Payment-master"
          component={Payment}
        />
        <PrivateRouteUser
          exact
          path="/campaign-medium"
          component={CampaignMediumMaster}
        />
        <PrivateRouteUser
          exact
          path="/campaign-name"
          component={CampaignNameMaster}
        />
        <PrivateRouteUser
          exact
          role={role}
          path="/CreateCampaign"
          component={CreateCampaign}
        />
      </Switch>
    </Router>
  );
}

export default App;
