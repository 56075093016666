import React, {Component}from "react";
import DatePicker from "react-datepicker";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { cloneDeep } from "lodash";
import "./style.css"; 
import CloseModal from "../../../Components/CloseModal";
import { getAllProductType } from "../../../Utils/Services/CampaignMedium";

import { toast } from "react-toastify";

toast.configure();

let initForm = {
	campaignMedium: "",
	campaignMediumDescription: "",
	startDate: "",
	endDate: "",
	productType: "",
	errors: {
		campaignMedium: null,
		campaignMediumDescription: null,
		startDate: null,
		endDate: null,
		productType: null,
	},
};
class AddCampaignMediumMaster extends Component{

    constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			isLoading: false,
			showInner: false,
			form: cloneDeep(initForm),
			productList: [],
		};
	}

    componentDidMount = () => {
		console.log("this.props.editData", this.props);
		if (this.props.editData && this.props.action == "edit") {
			let { form } = this.state;
			console.log("this.props.editData", this.props.editData);
			let obj = {
				...form,
				...this.props.editData,
				startDate: new Date(this.props.editData.startDate),
				endDate: new Date(this.props.editData.endDate),
			};
			this.setState({ form: obj });
		}
		this.setState({ showModal: true });
		getAllProductType().then((res) => {
			console.log("RESPONSE :: getAllProduct ::: ", res);
			let productList = res.data ? res.data : [];
			productList.push({id: 0, productcode: 'All', productdescription: 'All'});
			this.setState({ productList });
		});
	};

    handleModalClose = () => {
		this.setState({ showInner: !this.state.showInner });
	};

	innerModalClose = (modclose) => {
		if (!modclose) {
			this.handleModalClose();
		}
		this.setState({ showModal: false });
		if (this.props.action == "edit") {
			this.props.handleEditCampaignModal();
		} else {
			this.props.handleAddCampaignModal();
		}
	};
    handleInputChange = (e, name) => {
		e.preventDefault();
		let { form } = this.state;
		form[name] = e.target.value;
		this.setState({ form });
	};
    onValidate = (e, name) => {
		e.preventDefault();
		if (name == "campaignMedium") {
			e.target.value <= 0
				? this.setState({
						form: { ...this.state.form, errors: { ...this.state.form.errors, campaignMedium: "Campaign Medium must not be blank." } },
				  })
				: this.setState({ form: { ...this.state.form, errors: { ...this.state.form.errors, campaignMedium: null } } });
		}

        if (name == "campaignMediumDescription") {
			e.target.value.length <= 0
				? this.setState({
						form: { ...this.state.form, errors: { ...this.state.form.errors, campaignMediumDescription: "Campaign Medium Description must not be blank." } },
				  })
				: this.setState({ form: { ...this.state.form, errors: { ...this.state.form.errors, campaignMediumDescription: null } } });
		}
        if (name == "productType") {
			const { form } = this.state;
			console.log("productType", e);
			if (e.productdescription == "" || e.productdescription == null || e.productdescription == undefined) {
				this.setState({
					form: { ...form, errors: { ...form.errors, productType: true } },
				});
			}
			if (form.productType) {
				this.setState({
					form: { ...form, errors: { ...form.errors, productType: null } },
				});
			}
		}
	};
    handleStartDateChange = (date) => {
		console.log("handleStartDateChange", date);
		this.setState({
			form: { ...this.state.form, startDate: date },
		});
	};

	handleEndDateChange = (date) => {
		console.log("handleEndDateChange", date);
		this.setState({
			form: { ...this.state.form, endDate: date },
		});
	};
    validateStartDate = () => {
		console.log("HAPPENED");
		let { form } = this.state;
		if (form.startDate > form.endDate) {
			this.setState({
				form: { ...this.state.form, errors: { ...this.state.form.errors, startDate: "Start Date can not be greater than End Date." } },
			});
		} else {
			this.setState({
				form: { ...this.state.form, errors: { ...this.state.form.errors, startDate: null } },
			});
		}
	};
    validateDateInput = () => {
		console.log("HAPPENED");
		let { form } = this.state;
		if (form.endDate < form.startDate) {
			this.setState({
				form: { ...this.state.form, errors: { ...this.state.form.errors, endDate: "End Date can not be less than Start Date." } },
			});
		} else {
			this.setState({
				form: { ...this.state.form, errors: { ...this.state.form.errors, endDate: null } },
			});
		}
		if (form.startDate > form.endDate) {
			this.setState({
				form: { ...this.state.form, errors: { ...this.state.form.errors, startDate: "Start Date can not be greater than End Date." } },
			});
		} else {
			this.setState({
				form: { ...this.state.form, errors: { ...this.state.form.errors, startDate: null } },
			});
		}
	};
    handleSubmit = () => {
		if (this.props.action == "edit") {
			this.props.editCampaignMedium(this.state.form);
		} else {
			this.props.addCampaignMedium(this.state.form);
		}
	};
    handleProductChange = (e, name) => {
		console.log("handleStateSelectChange", e, name);
		let { form } = this.state;
		let { productdescription } = e;
		form[name] = productdescription;
		this.setState({ form });
	};
    render() {
		let { form, showModal, showInner, isLoading, productList } = this.state;
		let { errors } = form;
		let disableSave = false;

		console.log("form", form.productType);

		Object.keys(errors).map((error) => {
			if (errors[error] == true || errors[error] != null) {
				disableSave = true;
				return true;
			}
		});
		Object.keys(form).map((key) => {
			if (form[key] == null || form[key] == "") {
				disableSave = true;
				return true;
			}
		});
        return (
			<>
				<div>
					<Modal className="franking-fee-container" aria-labelledby="contained-modal-title-vcenter" centered show={showModal} size="lg">
						<Modal.Header>
							<Modal.Title>{this.props.action == "edit" ? "Edit Campaign Medium Master" : "Add Campaign Medium Master"}</Modal.Title>
						</Modal.Header>
						<Modal.Body className="p-4">
							{isLoading ? (
								<h4 className="text-center">LOADING...</h4>
							) : (
								<>
                                <div className="row">
                                        <div className="form-group col-md-4 ">
											<label>Campaign Medium {<i className="text-danger">*</i>}</label>
											<div className="inner-addon right-addon">
												<input
													type="text"
													maxLength="255"
													name="campaignMedium"
													value={form.campaignMedium && form.campaignMedium}
													onChange={(e) => {
														this.handleInputChange(e, "campaignMedium");
													}}
													onBlur={(e) => {
														this.onValidate(e, "campaignMedium");
													}}
													// placeholder="Enter Campaign Name"
													className={`form-input ${errors.campaignMedium ? "error-input-border" : ""} `}
													disabled={false}
												/>
												{errors.campaignMedium && <span className="errorMsg">{errors.campaignMedium}</span>}
											</div>
										</div>
                                        <div className="mb-4 col-md-4">
											<label>
												<i className="text-danger">*</i> Product Type
											</label>
											<Select
												value={productList.filter((item) => item.productdescription == form.productType)[0]}
												onChange={(e) => {
													this.handleProductChange(e, "productType");
												}}
												onBlur={(e) => this.onValidate(e, "productType")}
												name="productType"
												options={productList}
												placeholder="Select Product"
												valueKey="productdescription"
												labelKey="productdescription"
												getOptionLabel={(option) => option["productdescription"]}
												getOptionValue={(option) => option["productdescription"]}
												className={`${errors.productType ? "error-input-border" : ""} `}
												isDisabled={false}
											/>
											{errors.productType && <span className="errorMsg">Please Select Product</span>}
										</div>
									</div>
                                    <div className="row">
										<div className="form-group col-md-6">
											<label>Campaign Medium Description {<i className="text-danger">*</i>}</label>
											<div>
												<textarea
													style={{ resize: "none" }}
													rows="5"
													type="text"
													maxLength="255"
													name="campaignMediumDescription"
													value={form.campaignMediumDescription && form.campaignMediumDescription}
													onChange={(e) => {
														this.handleInputChange(e, "campaignMediumDescription");
													}}
													onBlur={(e) => {
														this.onValidate(e, "campaignMediumDescription");
													}}
													// placeholder="Enter Campaign Description"
													className={`form-input ${errors.campaignMediumDescription ? "error-input-border" : ""} `}
													disabled={false}
												/>
												{errors.campaignMediumDescription && <span className="errorMsg">{errors.campaignMediumDescription}</span>}
											</div>
										</div>
                                        <div className="form-group col-md-3 ">
											<label>Start Date {<i className="text-danger">*</i>}</label>
											<div>
												<DatePicker
													selected={form && form.startDate}
													onBlur={this.validateDateInput}
													onChange={this.handleStartDateChange}
													dateFormat="dd-MM-yyyy"
													placeholderText="DD-MM-YYYY"
													showYearDropdown
													showMonthDropdown
													minDate={new Date()}
													className={`form-input ${errors.startDate ? "error-input-border" : ""} `}
													disabled={false}
													// maxDate={new Date()}
												/>
											</div>
											{errors.startDate && <span className="errorMsg">{errors.startDate}</span>}
										</div>
                                        <div className="form-group col-md-3">
											<label>End Date {<i className="text-danger">*</i>}</label>
											<div class="inner-addon right-addon relative-position">
												<DatePicker
													selected={form && form.endDate}
													onBlur={this.validateDateInput}
													onChange={this.handleEndDateChange}
													dateFormat="dd-MM-yyyy"
													placeholderText="DD-MM-YYYY"
													showYearDropdown
													showMonthDropdown
													minDate={new Date()}
													className={`form-input ${errors.endDate ? "error-input-border" : ""} `}
													disabled={false}
													// maxDate={new Date()}
												/>
											</div>
											{errors.endDate && <span className="errorMsg">{errors.endDate}</span>}
										</div>
										<div className="dummy-div"></div>
									</div>
								</>
							)}
						</Modal.Body>
                        <Modal.Footer>
							<Button className="btn-danger" onClick={this.handleModalClose}>
								Cancel
							</Button>
							<Button className="btn-success" disabled={disableSave || isLoading} onClick={this.handleSubmit}>
								{this.props.action == "edit" ? "Update" : "Create"}
							</Button>
						</Modal.Footer>
					</Modal>
					<CloseModal show={showInner} button2={this.handleModalClose} button1={this.innerModalClose} title="Do you want to close ?" />
				</div>
			</>
		);
	}
}
export default AddCampaignMediumMaster;