import { get, post } from "../httpInterceptor";

//list all tehsil district and state by pincode
export const getdetailsbypincode = (pincode) => {
  return get(`/geography/pincode/${pincode}`, null, false).then((res) => {
    console.log("saveorupdatevillagemaster", res);
    return res;
  });
};
//list of branches
export const findallbranches = () => {
  return get(`/geography/branches`).then((res) => {
    console.log("RESPONSE :: findallbranches ::: ", res);
    return res;
  });
};
//list of spokes which are not mapped with branches
export const getunmappedspokes = () => {
  return get(`/geography/spokes/unmapped`).then((res) => {
    console.log("RESPONSE :: unmappedvillages ::: ", res);
    return res;
  });
};
//save or update village
export const saveorupdatebranchmaster = (obj) => {
  return post(`/geography/branches`, obj, false).then((res) => {
    console.log("saveorupdatebranchmaster", res);
    return res;
  });
};

//delete village by id
export const deletevillagemasterbyid = (obj) => {
  return post(`/villagemaster/deletevillagemasterbyid`, obj, false).then(
    (res) => {
      console.log("deletevillagemasterbyid", res);
      return res;
    }
  );
};
