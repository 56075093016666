import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { ReactTableDefaults } from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddGoldAudit from "./AddGoldAudit";
import { getAllProduct } from "./../../../Utils/Services/productmaster";
import {
  deleteProductCampaignById,
  getAllProductCampaign,
  saveUpdateProductCampaign,
} from "../../../Utils/Services/productCampaign";
import {
  changeAuditStatus,
  getAllGoldAudits,
  saveGoldAuditLot,
} from "../../../Utils/Services/goldAMS";

toast.configure();

const GoldAuditMaster = (props) => {
  let [showAddModal, setShowAddModal] = useState(false);
  let [showEditModal, setShowEditModal] = useState(false);
  let [goldAuditData, setgoldAuditData] = useState([]);
  let [editData, setEditData] = useState({});
  let [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getGoldAuditData();
  }, []);

  const getGoldAuditData = () => {
    getAllGoldAudits()
      .then((response) => {
        setgoldAuditData(response.data);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
    //setgoldAuditData(SAMPLE_DATA);
  };

  const changeGoldAuditStatus = (record, status) => {
    let title = `Mark the Audit as ${
      record.status === "Scheduled" ? "Cancelled" : "Scheduled"
    }?`;
    confirmAlert({
      title: title,
      // message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            changeAuditStatus(record.id, status);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const columns = [
    {
      Header: "SR. No",
      width: 70,
      Cell: (data) => {
        return data.index + 1;
      },
    },
    {
      Header: "Auditor Name",
      width: 250,
      Cell: (data) => {
        const { original } = data;
        return original &&
          original.goldAuditorDTO &&
          original.goldAuditorDTO.name
          ? original.goldAuditorDTO.name
          : null;
      },
    },
    {
      Header: "Branch",
      width: 250,
      Cell: (data) => {
        const { original } = data;
        return original && original.branch ? original.branch : null;
      },
    },
    {
      Header: "Audit Date & Time",
      width: 250,
      Cell: (data) => {
        const { original } = data;
        return original && original.auditStartDate
          ? original.auditStartDate.replace("T", " ")
          : null;
      },
    },
    {
      Header: "Status",
      width: 200,
      Cell: (data) => {
        const { original } = data;
        return original && original.auditStatus ? original.auditStatus : null;
      },
    },
    {
      Header: "Action",
      width: 150,
      disabled: false,
      accessor: "delete",
      accessor: "edit",
      Cell: ({ original }) => {
        return (
          <div
            disabled={false}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {(original.auditStatus === "SCHEDULED" ||
              original.auditStatus === "COMPLETED" ||
              original.auditStatus === "STARTED") && (
              <i
                disabled={false}
                onClick={() => handleEditAuditModal(original)}
                className="fas fa-user-edit"
                style={{ cursor: "pointer" }}
              />
            )}
          </div>
        );
      },
    },
  ];

  const handleAddAuditModal = (obj) => {
    // setgoldAmsData([...productCampaignData, obj]);
    setShowAddModal(!showAddModal);
  };

  const handleEditAuditModal = (obj) => {
    setShowEditModal(!showEditModal);
    setEditData(obj);
  };

  const addGoldAudit = (obj, doc) => {
    setShowAddModal(false);
    setShowEditModal(false);
    // const goldAuditLotsSet = [];
    // obj.goldAuditLotsSet = obj.goldAuditLotsSet.lot;
    // obj.goldAuditLotsSet.lots.forEach((item) => {
    // 	item.pouches.forEach((pouch) => {
    // 		goldAuditLotsSet.push({
    // 			lotId: item.lotId,
    // 			pouchId: pouch.pouchId,
    // 		});
    // 	});
    // });

    const lots = obj.goldAuditLotsSet.lots;
    obj = { ...obj, lots };
    delete obj.goldAuditLotsSet;

    let newAudit = {
      ...obj,
      auditStartDate: format(obj.auditStartDate, "yyyy-MM-dd HH:mm"),
    };

    let lotsArray = newAudit.lots;
    let lotsMap = {};
    lotsArray.map((item) => {
      let lotsAndPouches = item.pouches.map((pouch) => {
        return { ...pouch };
      });

      lotsMap[item.lotId] = [...lotsAndPouches];
    });
    newAudit = { ...newAudit, lotsMap };
    delete newAudit.lots;

    const formData = new FormData();
    formData.append("idProof", doc);
    formData.append(
      "goldAudit",
      new Blob([JSON.stringify(newAudit)], { type: "application/json" })
    );

    saveGoldAuditLot(formData)
      .then((response) => {
        getGoldAuditData();
        toast.success("Audit Saved successfully");
      })
      .catch((error) => {
        error.response.data.errorMessage
          ? toast.error(error.response.data.errorMessage)
          : toast.error("Error Saving Audit");
      });
  };

  const cancelAudit = (auditId) => {
    handleEditAuditModal(false);
    changeAuditStatus(auditId, "CANCELLED")
      .then(() => {
        getGoldAuditData();
        toast.success("Audit Cancelled successfully");
      })
      .catch((error) => {
        getGoldAuditData();
        toast.error("Error Cancelling Audit");
      });
  };

  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box">
            Gold Audit Master
          </h5>
          <div className="d-flex align-items-center mt-5">
            <button
              disabled={false}
              onClick={handleAddAuditModal}
              className="add-btn ml-auto btn btn-info btn-lg"
            >
              Add
            </button>
            {showAddModal && (
              <AddGoldAudit
                action={"add"}
                handleAddAuditModal={handleAddAuditModal}
                addGoldAudit={addGoldAudit}
                cancelAudit={cancelAudit}
              />
            )}
            {showEditModal && (
              <AddGoldAudit
                action={"edit"}
                editData={editData}
                handleEditAuditModal={handleEditAuditModal}
                addGoldAudit={addGoldAudit}
                cancelAudit={cancelAudit}
              />
            )}
          </div>
          <div className={`mt-4 react-table`}>
            <ReactTable
              data={goldAuditData}
              columns={columns}
              defaultPageSize={10}
              loading={isLoading}
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default GoldAuditMaster;
