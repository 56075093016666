import { get, post, del, postdelete, put } from "../httpInterceptor";

// http://182.71.191.91:8080/sarvagram-credit/master/findallfamilymemberexpense
// export const findallfamilymemberexpense = () => {
//   return get(`/sarvagram-credit/master/findallfamilymemberexpense`).then(
//     (res) => {
//       console.log("RESPONSE :: findallfamilymemberexpense ::: ", res);
//       return res;
//     }
//   );
// };

export const getFamilyMemberExpense = () => {
  return get(`/credit/master/family-expense`).then((res) => {
    console.log("*************************************************", res);
    return res;
  });
};

// http://182.71.191.91:8080/sarvagram-credit/master/saveorupdatefamilymemberexpense
// export const saveorupdatefamilymemberexpense = (obj) => {
//   return post(
//     `/sarvagram-credit/master/saveorupdatefamilymemberexpense`,
//     obj,
//     false
//   ).then((res) => {
//     console.log("saveorupdatefamilymemberexpense", res);
//     return res;
//   });
// };

export const saveAndupdatefamilymemberexpense = (obj) => {
  return post(`/credit/master/family-expense`, obj, false).then((res) => {
    console.log("saveorupdatefamilymemberexpense", res);
    return res;
  });
};

export const updatefamilymemberexpense = (obj, id) => {
  return post(`/credit/master/family-expense/${id}`, obj, false).then((res) => {
    console.log("upjdkjdckdccxncnxc******************", res);
    return res;
  });
};

//   http://localhost:8085/sarvagram-credit/master/deletefamilymemberexpensebyid
// export const deletefamilymemberexpensebyid = (obj) => {
//   return post(
//     `/sarvagram-credit/master/deletefamilymemberexpensebyid`,
//     obj,
//     false
//   ).then((res) => {
//     console.log("deletefamilymemberexpensebyid", res);
//     return res;
//   });
// };

export const deletefamilymemberexpense = (id) => {
  return postdelete(`/credit/master/family-expense/${id}`, false).then(
    (res) => {
      console.log("deletefamilymemberexpensebyid", res);
      return res;
    }
  );
};
