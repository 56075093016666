import {
    GET_USER_BY_EMPLOYEEID,
    GET_USER_BY_EMPLOYEEID_SUCCESS,
    GET_USER_BY_EMPLOYEEID_FAILURE,
} from '../Actions/types';
const LOAD = 'redux-form-examples/account/LOAD'

const INIT_STATE = {
    userDetails: {},
    loggedIn: localStorage.getItem('userData') ? true : false,
    loading: false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_USER_BY_EMPLOYEEID:
            return { ...state, loading: true }

        case GET_USER_BY_EMPLOYEEID_SUCCESS:
            return { ...state, loading: false, token: true, userDetails: action.payload.data }

        case GET_USER_BY_EMPLOYEEID_FAILURE:
            return { ...state, loading: false }

        case LOAD:
            return {
                data: action.data
            }

        default:
            return { ...state }
    }
}