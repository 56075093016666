import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { migrateSpoke } from "../../../Utils/Services/VillageMaster";

const MigrateSpoke = (props) => {
  const { showMigrateSpokeModal, setshowMigrateSpokeModal, villageData } =
    props;

  const [oldSpoke, setOldSpoke] = useState(null);
  const [newSpoke, setNewSpoke] = useState(null);

  const submitHandler = () => {
    if (oldSpoke.villageName === newSpoke.villageName) {
      toast.error("Old And New Village Name Cannot Be Same");
    } else {
      const obj = {
        newSpoke: newSpoke.villageName,
        newPincode: newSpoke.pincode,
        oldSpoke: oldSpoke.villageName,
        oldPincode: oldSpoke.pincode,
      };

      migrateSpoke(obj).then((res) => {
        if (res.data && res.data.error) {
          toast.error(res.data.message);
        } else {
          toast.success("Spoke Migrated Successfully!");
        }
        setshowMigrateSpokeModal(!showMigrateSpokeModal);
      });
    }
  };

  return (
    <Modal
      className="product-norm-container"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showMigrateSpokeModal}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>Migrate Spoke</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4 d-flex">
        <div className="w-100 p-4">
          <label>
            <i className="text-danger">*</i> Old Spoke
          </label>
          <Select
            placeholder="Select Old Spoke"
            getOptionLabel={(option) => option["villageName"]}
            options={villageData}
            value={oldSpoke}
            onChange={(e) => setOldSpoke(e)}
          />
        </div>
        <div className="w-100 p-4">
          <label>
            <i className="text-danger">*</i> New Spoke
          </label>
          <Select
            placeholder="Select New Spoke"
            getOptionLabel={(option) => option["villageName"]}
            getOptionValue={(option) => option["villageName"]}
            options={villageData}
            value={newSpoke}
            onChange={(e) => setNewSpoke(e)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-danger"
          onClick={() => setshowMigrateSpokeModal(!showMigrateSpokeModal)}
        >
          Cancel
        </Button>
        <Button
          disabled={!oldSpoke || !newSpoke}
          className="btn-success"
          onClick={submitHandler}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MigrateSpoke;
