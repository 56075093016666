import React, { useState, useEffect } from 'react';
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddEditProductFundingNorms from "./AddEditRoi";
import { formatIndianCurrency } from "../../../Utils/helperFunctions";
import { deleteRoiById, getAllRoi } from "../../../Utils/Services/roi";
import { getAllLevelsDropdown } from "../../../Utils/Services/common";

toast.configure();

let data = [
    {
        product: "BL",
        segment: "MSE",
        roi: "2.33",
        type: "Secured",
        id: 1
    }
]
const Roi = (props) => {
    let [search, setSearch] = useState("");
    let [roiData, setroiData] = useState([]);
    let [showRoiModal, setshowRoiModal] = useState(false);
    let [showEditRoiModal, setshowEditRoiModal] = useState(false);
    let [editData, setEditData] = useState({});
    let [levelDropdownOptions, setlevelDropdownOptions] = useState([]);
    let [page, setPage] = useState(0)


    useEffect(() => {
        getRoi();
        getLevelValue();
    }, []);

    const getRoi = () => {
        getAllRoi().then((res) => {
            console.log("getAllRoi", res)
            if (res.error) {
                return;
            } else {
                if (res.data && res.data.error) {
                    toast.error(res.data.message, {
                        type: toast.TYPE.ERROR,
                        autoClose: 2000
                    });
                } else {
                    /* toast.success(res.data.message, {
                        type: toast.TYPE.SUCCESS,
                        autoClose: 2000
                    }); */
                    setroiData(res.data.data)
                }
            }
        })
    }

    const getLevelValue = () => {
        getAllLevelsDropdown().then((result) => {
            if (result.error) {
                return;
            } else if (result.data && result.data.error) {
                toast.error(result.data.message, {
                    type: toast.TYPE.ERROR,
                    autoClose: 2000,
                });
                return;
            } else {
                if (result && result.data && result.data.data) {
                    setlevelDropdownOptions(result.data.data);
                }
            }
        });
    };

    const deleteRoi = id => {
        let postData = {
            id: id
        }
        confirmAlert({
            title: "Confirm to Delete",
            message: "Are you sure to do this?",
            buttons: [
                {
                    label: "Yes, Delete It!",
                    onClick: () => {
                        deleteRoiById(postData).then(response => {
                            console.log("deleteRoi", response, response.data && response.data.error == "true")
                            if (response.error) {
                                return;
                            }
                            if (response.data && response.data.error) {
                                toast.error(response.data.message, {
                                    type: toast.TYPE.ERROR,
                                    autoClose: 2000
                                });
                            } else {
                                toast.success(response.data.message, {
                                    type: toast.TYPE.SUCCESS,
                                    autoClose: 2000
                                });
                                getRoi();
                            }
                        });
                    }
                },
                {
                    label: "No",
                    onClick: () => {
                        // toast.error("Role Can Not be Deleted", {
                        //   type: toast.TYPE.ERROR,
                        //   autoClose: 2000

                        // });
                        // this.RoleList();
                    }
                }
            ]
        });
    };

    const columns = [
        {
            Header: "SR. No",
            width: 70,
            Cell: ((data) => {
                // console.log("data...", data)
                return data.index + 1
            })
            /* Cell: ({ original }) => {
                return original.id && original.id ? original.id : null;
            } */
        },
        {
            Header: "Segment",
            width: 200,
            Cell: ({ original }) => {
                return original.segment && original.segment ? original.segment : null;
            }
        },
        {
            Header: "type",
            Cell: ({ original }) => {
                return original.type && original.type ? original.type : null;
            }
        },
        {
            Header: "product",
            Cell: ({ original }) => {
                return original.product && original.product ? original.product : null;
            }
        },
        {
            Header: "Level",
            Cell: ({ original }) => {
                return original && original.level ? original.level : null;
            },
        },
        {
            Header: "ROI(%)",
            Cell: ({ original }) => {
                return original.roi ? `${original.roi}` : null;
            }
        },
        {
            Header: "Action",
            width: 125,
            disabled: false,
            accessor: "delete",
            accessor: "edit",

            Cell: ({ original }) => {
                return (
                    <div
                        disabled={false}
                        style={{ display: "flex", justifyContent: "space-around" }}
                    >
                        <i
                            disabled={false}
                            onClick={() => deleteRoi(original.id)}
                            className="fa fa-trash"
                            style={{ cursor: "pointer" }}
                        />
                        <i
                            disabled={false}
                            onClick={() => handleEditRoi(original)}
                            className="fas fa-user-edit"
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                );
            }
        }
    ];

    const handleAddROi = id => {
        setshowRoiModal(!showRoiModal);
    };

    const handleEditRoi = obj => {
        setshowEditRoiModal(!showEditRoiModal);
        setEditData(obj)
    };

    const handleSearch = e => {
        /* let { roleContainer } = this.state;
        let array = [];
        roleContainer.map(res => {
            let roleName = res.roleName;
            if (roleName)
                if (res.roleName.match(e.target.value.toUpperCase())) {
                    array.push(res);
                }
        });
        this.setState({
            search: e.target.value,
            roleData: e.target.value ? array : roleContainer
        }); */
    };

    const handlePageChanges = (page) => {
        setPage(page)
    }

    return (
        <div className="dash_grid">
            <ToastContainer />
            <LeftMenu />
            <main className="bg-white">
                <Header {...props} />
                <section className="container-fluid mb-5">
                    <h5 className="text-center mt-5 mx-auto user-box"> ROI</h5>
                    <div className="d-flex align-items-center mt-5">
                        <button
                            disabled={false}
                            onClick={handleAddROi}
                            className="add-btn ml-auto btn btn-info btn-lg"
                        >
                            {" "}
                    Add ROI{" "}
                        </button>
                        {showRoiModal && (
                            <AddEditProductFundingNorms
                                action={"add"}
                                getRoi={getRoi}
                                levelDropdownOptions={levelDropdownOptions}
                                handleAddROi={handleAddROi}
                                handlePageChanges={handlePageChanges}
                            />
                        )}
                        {showEditRoiModal && (
                            <AddEditProductFundingNorms
                                action={"edit"}
                                getRoi={getRoi}
                                editData={editData}
                                levelDropdownOptions={levelDropdownOptions}
                                handleEditRoi={handleEditRoi}
                                handlePageChanges={handlePageChanges}
                            />
                        )}
                    </div>

                    <div
                        className={`mt-4 react-table`}
                    >
                        <ReactTable
                            data={roiData}
                            columns={columns}
                            defaultPageSize={10}
                            page={page}
                            onPageChange={(page) => handlePageChanges(page)}
                        />
                    </div>
                </section>
            </main>
        </div>
    );
};

export default Roi;