import React, { useState, useEffect, Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from "lodash";

import CloseModal from "../../../Components/CloseModal";
import {
  formatIndianCurrency,
  replaceComma,
  checkIfNotDecimal,
} from "../../../Utils/helperFunctions";
import {
  findallcancellationfeesmaster,
  saveorupdatecancellationmaster,
  deletecancellationfeesbyid,
  findallproductdropdown,
} from "../../../Utils/Services/CancelMaster";

toast.configure();

const initForm = {
  product: "",
  remark: "",
  fees: "",
  status: "",
  errors: {
    product: null,
    remark: null,
    fees: null,
    status: null,
  },
};

const remarks = [
  {
    remarkLebel: "Cancelled By SarvaGram",
    remarkValue: "Cancelled By SarvaGram",
  },
  {
    remarkLebel: "Cancelled By Customer",
    remarkValue: "Cancelled By Customer",
  },
];

const Status = [
  { StatusLebel: "Margin money pending", StatusValue: "Margin money pending" },
  {
    StatusLebel: "Margin money received",
    StatusValue: "Margin money received",
  },
  { StatusLebel: "Initiate delivery", StatusValue: "Initiate delivery" },
  { StatusLebel: "Dispatched", StatusValue: "Dispatched" },
];

export class AddEditCancel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      showInner: false,
      form: cloneDeep(initForm),
      productDropdownOptions: [],
    };
  }

  componentDidMount = () => {
    let { form } = this.state;
    this.setState({ showModal: true });
    console.log("props.editData", this.props.editData);
    if (this.props.editData && this.props.action == "edit") {
      let { form } = this.state;
      let obj = { ...form, ...this.props.editData };
      this.setState({ form: obj });
    }
  };

  handleModalClose = () => {
    this.setState({ showInner: !this.state.showInner });
    // this.this.props.addRole();
  };

  innerModalClose = (modclose) => {
    if (!modclose) {
      this.handleModalClose();
    }
    this.setState({ showModal: false });
    if (this.props.action == "edit") {
      this.props.handleEditCancelMaster();
    } else {
      this.props.handleAddCancel();
    }
  };

  handleSubmit = () => {
    let { form } = this.state;
    let obj = form;
    this.setState({ isLoading: true });
    saveorupdatecancellationmaster(obj).then((response) => {
      this.setState({ isLoading: false });
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        this.props.cancelMaster();
        if (this.props.editData && this.props.action == "edit") {
          this.props.handleEditCancelMaster();
        } else {
          this.props.handleAddCancel();
        }
        this.setState({ showModal: false });
        toast.success(response.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      }
    });
  };

  handleProductSelectChanges = (e, name) => {
    console.log("handleProductSelectChanges", e, name);
    let { form } = this.state;
    let { productName } = e;
    form[name] = productName;
    this.setState({ form });
  };

  handleInputChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("handleInputChange", e, name, e.target.value);
    form[name] = e.target.value;
    this.setState({ form });
  };

  handleremarkSelectChanges = (e, name) => {
    console.log("handleremarkSelectChanges", e, name);
    let { form } = this.state;
    let { remarkValue } = e;
    form[name] = remarkValue;
    this.setState({ form });
  };

  handleStatusSelectChanges = (e, name) => {
    console.log("handleStatusSelectChanges", e, name);
    let { form } = this.state;
    let { StatusValue } = e;
    form[name] = StatusValue;
    this.setState({ form });
  };

  onValidate = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    if (form[name] === "" || form[name] === null || form[name] === undefined) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      form.errors[name] = null;
      this.setState({ form });
    }
  };

  render() {
    let { form, showModal, showInner, isLoading } = this.state;
    let { productDropdownOptions } = this.props;
    let { errors } = form;
    let diableSave = false;
    let isRequiredFeildFilled = false;
    console.log("re", remarks);
    let handleZero = false;
    if (form.remark == "Cancelled By SarvaGram") {
      form.fees = "0";
      handleZero = true;
    } else {
      handleZero = false;
    }

    Object.keys(form).map((name) => {
      console.log("form", form[name]);
      if (
        form[name] === "" ||
        form[name] === null ||
        form[name] === undefined
      ) {
        isRequiredFeildFilled = true;
        return true;
      }
    });

    Object.keys(errors).map((error) => {
      console.log("error", errors[error]);
      if (errors[error] === true || errors[error] != null) {
        diableSave = true;
        return true;
      }
    });
    console.log("error", diableSave);
    console.log("form", form);
    console.log("ir", isRequiredFeildFilled);
    return (
      <React.Fragment>
        <Modal
          className="product-norm-container"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showModal}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>
              {this.props.action == "edit"
                ? "Edit Cancel fees"
                : "Add Cancel fees"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            {isLoading ? (
              <h4 className="text-center">LOADING...</h4>
            ) : (
              <div className="row ">
                <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                  <label>
                    <i className="text-danger">*</i> Remarks
                  </label>
                  <Select
                    defaultValue={
                      remarks.filter(
                        ({ remarkValue }) => remarkValue == form.remark
                      )[0]
                    }
                    onChange={(e) => {
                      this.handleremarkSelectChanges(e, "remark");
                    }}
                    onBlur={(e) => this.onValidate(e, "remark")}
                    name="remark"
                    options={remarks}
                    placeholder="Select remark"
                    // value={form.remark}
                    valueKey="remarkValue"
                    labelKey="remarkLebel"
                    getOptionLabel={(option) => option.remarkLebel}
                    getOptionValue={(option) => option.remarkValue}
                    className={`${errors.remark ? "error-input-border" : ""} `}
                    isDisabled={false}
                  />
                  {errors.remark && (
                    <span className="errorMsg">Please Select Remarks</span>
                  )}
                </div>
                <div className="mb-4 col-md-4 ">
                  <label>
                    <i className="text-danger">*</i> Product
                  </label>
                  <Select
                    defaultValue={
                      productDropdownOptions.filter(
                        ({ productName }) => productName == form.product
                      )[0]
                    }
                    onChange={(e) => {
                      this.handleProductSelectChanges(e, "product");
                    }}
                    onBlur={(e) => this.onValidate(e, "product")}
                    name="product"
                    options={productDropdownOptions}
                    placeholder="Select Product"
                    valueKey="productName"
                    labelKey="productName"
                    getOptionLabel={(option) => option.productName}
                    getOptionValue={(option) => option.productName}
                    className={`${errors.product ? "error-input-border" : ""} `}
                    isDisabled={false}
                  />
                  {errors.product && (
                    <span className="errorMsg">Please Enter product</span>
                  )}
                </div>
                <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                  <label>
                    <i className="text-danger">*</i> Status
                  </label>
                  <Select
                    defaultValue={
                      Status.filter(
                        ({ StatusValue }) => StatusValue == form.status
                      )[0]
                    }
                    onChange={(e) => {
                      this.handleStatusSelectChanges(e, "status");
                    }}
                    onBlur={(e) => this.onValidate(e, "status")}
                    name="status"
                    options={Status}
                    placeholder="Select Status"
                    // value={form.status}
                    valueKey="StatusValue"
                    labelKey="StatusLebel"
                    getOptionLabel={(option) => option.StatusLebel}
                    getOptionValue={(option) => option.StatusValue}
                    className={`${errors.status ? "error-input-border" : ""} `}
                    isDisabled={false}
                  />
                  {errors.status && (
                    <span className="errorMsg">Please Select Status</span>
                  )}
                </div>
                <div className="form-group col-md-4 ">
                  <label>
                    <i className="text-danger">*</i> Fees (In Rupees)
                  </label>
                  <div class="inner-addon right-addon">
                    <input
                      type="text"
                      // pattern="\d*"
                      maxLength="9"
                      name="fees"
                      value={
                        form.fees && formatIndianCurrency(form.fees).toString()
                      }
                      onChange={(e) => {
                        e.target.value = replaceComma(e.target.value);
                        if (checkIfNotDecimal(e.target.value)) {
                          this.handleInputChange(e, "fees");
                        }
                      }}
                      onBlur={(e) => {
                        e.target.value = replaceComma(e.target.value);
                        this.onValidate(e, "fees");
                      }}
                      placeholder="Enter fees"
                      className={`form-input ${
                        errors.fees ? "error-input-border" : ""
                      } `}
                      disabled={handleZero}
                    />
                    {errors.fees && (
                      <span className="errorMsg">
                        {errors.fees == true ? "Enter fees" : errors.fees}
                      </span>
                    )}
                    {/* {!errors.loanamtnorms && invalidPanNumber && (
                                                        <span className="errorMsg">Please enter valid PAN</span>
                                                    )} */}
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-danger" onClick={this.handleModalClose}>
              Cancel
            </Button>
            <Button
              className="btn-success"
              disabled={diableSave || isRequiredFeildFilled || isLoading}
              onClick={this.handleSubmit}
            >
              {this.props.action == "edit" ? "Update" : "Create"}
            </Button>
          </Modal.Footer>
        </Modal>
        <CloseModal
          show={showInner}
          button2={this.handleModalClose}
          button1={this.innerModalClose}
          title="Do you want to close ?"
        />
      </React.Fragment>
    );
  }
}

export default AddEditCancel;
