import React, { Component } from 'react';
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from 'lodash';
import { getAllSegment } from '../../../Utils/Services/segmentmaster'
import CloseModal from "../../../Components/CloseModal";


let initForm = {
   segmentCode:"",
   segmentDescription :"",
   id:null
}

// const getSegmentMaster = () => {
//     getAllSegment().then((result) => {
//         if (result.error) {
//             return;
//         } else if (result.data && result.data.error) {
//             toast.error(result.data.message, {
//                 type: toast.TYPE.ERROR,
//                 autoClose: 2000
//             });
//             return;
//         } else {
//             if (result && result.data && result.data.data) {
//                 this.setState({ initForm: result.data.data })

//             }
//         }
//     })
// }
class AddEditSegmentMaster extends Component{
    constructor(props){
        super(props);
        this.state={
            showModal: false,
            isLoading: false,
            showInner: false,
            form:cloneDeep(initForm),
            errors:{
                segmentCode:null,
                segmentDescription :null,
                id:null,
            }
        }

    }
    render(){
        let { form, showModal, showInner, isLoading,  errors } = this.state;
        return(
         <>
         <Modal
                        className="product-norm-container"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={showModal}
                        size="lg"
                    ></Modal>

         </>
        )
    }
}

export default AddEditSegmentMaster;