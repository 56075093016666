import React, { useState, useEffect, Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from "lodash";

import CloseModal from "../../../Components/CloseModal";
import {
  formatIndianCurrency,
  replaceComma,
  checkIfNotDecimal,
} from "../../../Utils/helperFunctions";
import {
  updateMFGEmiMultiplier,
  saveMFGEmiMultiplier,
} from "../../../Utils/Services/MFGEmiMaster";

toast.configure();

let initForm = {
  bureauScoreUpperLimit: "",
  bureauScoreLowerLimit: "",
  loanAmountLowerLimit: "",
  loanAmountUpperLimit: "",
  emiMonth: "",
  tenureLowerLimit: "",
  tenureUpperLimit: "",
  multiplier: "",
  loanType: "",
  conditionType: "",
  errors: {
    bureauScoreUpperLimit: null,
    bureauScoreLowerLimit: null,
    loanAmountLowerLimit: null,
    loanAmountUpperLimit: null,
    emiMonth: null,
    tenureLowerLimit: null,
    tenureUpperLimit: null,
    multiplier: null,
    loanType: null,
    conditionType: null,
  },
};

const Loan = [
  {
    LoanLebel: "Secured",
    LoanValue: "Secured",
  },
  {
    LoanLebel: "Unsecured",
    LoanValue: "Unsecured",
  },
  {
    LoanLebel: "NA",
    LoanValue: "NA",
  },
];

const Condition = [
  { ConditionLebel: "Rule", ConditionValue: "Rule" },
  {
    ConditionLebel: "Deviation",
    ConditionValue: "Deviation",
  },
];

class AddEditMFGEmiMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      showInner: false,
      form: cloneDeep(initForm),
    };
  }

  componentDidMount = () => {
    let { form } = this.state;
    this.setState({ showModal: true });
    // console.log("props.editData", this.props.editData)
    if (this.props.editData && this.props.action == "edit") {
      let { form } = this.state;
      let obj = { ...form, ...this.props.editData };
      this.setState({ form: obj });
    }
  };

  handleModalClose = () => {
    this.setState({ showInner: !this.state.showInner });
    // this.this.props.addRole();
  };

  innerModalClose = (modclose) => {
    if (!modclose) {
      this.handleModalClose();
    }
    this.setState({ showModal: false });
    if (this.props.action == "edit") {
      this.props.handleEditMFGEmiMaster();
    } else {
      this.props.handleAddMFGEmiMaster();
    }
  };

  handleSubmit = () => {
    let { form } = this.state;
    let obj = { ...form };
    this.setState({ isLoading: true });
    delete obj.errors;
    if (this.props.editData && this.props.action == "edit") {
      updateMFGEmiMultiplier(obj).then((response) => {
        this.setState({ isLoading: false });
        if (response.data && response.data.error) {
          toast.error(response.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
          return;
        } else {
          this.props.getMFGEmiMaster();
          this.props.handleEditMFGEmiMaster();
          this.setState({ showModal: false });
          if (response.data && response.data.message) {
            toast.success(response.data.message, {
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }
        }
      });
    } else {
      saveMFGEmiMultiplier(obj).then((response) => {
        this.setState({ isLoading: false });
        if (response.data && response.data.error) {
          toast.error(response.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
          return;
        } else {
          this.props.getMFGEmiMaster();
          this.props.handleAddMFGEmiMaster();
          this.setState({ showModal: false });
          toast.success(response.data.message, {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        }
      });
    }
  };

  handleLoanSelectChanges = (e, name) => {
    console.log("handleLoanSelectChanges", e, name);
    let { form } = this.state;
    let { LoanValue } = e;
    form[name] = LoanValue;
    this.setState({ form });
  };

  handleConditionSelectChanges = (e, name) => {
    console.log("handleConditionSelectChanges", e, name);
    let { form } = this.state;
    let { ConditionValue } = e;
    form[name] = ConditionValue;
    this.setState({ form });
  };

  handleInputChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log(
      "handleInputChange",
      e,
      name,
      e.target.value,
      parseInt(e.target.value) > parseInt(form.loanAmountLowerLimit)
    );
    form[name] = e.target.value;
    this.setState({ form });
  };

  onLoanValidate = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    // let { product, loanType, loanAmountLowerLimit, baseamt, state, percentagevalue } = form;
    console.log(
      "onValidate",
      name,
      e.target.value,
      parseInt(e.target.value),
      parseInt(form.loanAmountUpperLimit)
    );
    if (
      (form[name] == "" || form[name] == null || form[name] == undefined) &&
      name != "loanAmountLowerLimit" &&
      name != "loanAmountUpperLimit"
    ) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      if (
        name == "loanAmountUpperLimit" &&
        parseInt(e.target.value) <= parseInt(form.loanAmountLowerLimit)
      ) {
        form.errors.loanAmountUpperLimit =
          "Upper limit can not be less than or equal to Lower limit.";
        form.errors.loanAmountLowerLimit = null;
      } else if (
        name == "loanAmountUpperLimit" &&
        parseInt(e.target.value) > parseInt(form.loanAmountLowerLimit)
      ) {
        form.errors.loanAmountUpperLimit = null;
        form.errors.loanAmountLowerLimit = null;
      } else if (
        name == "loanAmountLowerLimit" &&
        parseInt(e.target.value) >= parseInt(form.loanAmountUpperLimit)
      ) {
        form.errors.loanAmountLowerLimit =
          "Lower limit can not be less than or equal to Upper limit.";
        form.errors.loanAmountUpperLimit = null;
      } else if (
        name == "loanAmountLowerLimit" &&
        parseInt(e.target.value) < parseInt(form.loanAmountUpperLimit)
      ) {
        form.errors.loanAmountLowerLimit = null;
        form.errors.loanAmountUpperLimit = null;
      } else {
        if (isNaN(parseInt(e.target.value))) {
          form.errors[name] = true;
        } else {
          form.errors[name] = null;
        }
      }
      this.setState({ form });
    }
  };

  onValidate = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("state*******", e, name, form);

    if (
      ((form.bureauScoreLowerLimit != "0" ||
        form.bureauScoreUpperLimit != "0") &&
        (form.bureauScoreLowerLimit != "" ||
          form.bureauScoreUpperLimit != "")) ||
      (form.tenureUpperLimit != "0" &&
        (form.tenureLowerLimit != "" || form.tenureUpperLimit != ""))
    ) {
      console.log(
        "inside",
        name == "bureauScoreUpperLimit",
        parseInt(e.target.value),
        parseInt(form.bureauScoreLowerLimit)
      );

      if (
        name == "bureauScoreUpperLimit" &&
        parseInt(e.target.value) <= parseInt(form.bureauScoreLowerLimit)
      ) {
        form.errors.bureauScoreUpperLimit =
          "Upper limit can not be less than or equal to Lower limit.";
        form.errors.bureauScoreLowerLimit = null;
      } else if (
        name == "bureauScoreLowerLimit" &&
        parseInt(e.target.value) < 300
      ) {
        form.errors.bureauScoreUpperLimit = null;
        form.errors.bureauScoreLowerLimit =
          "Lower limit can not be less than 300";
      } else if (
        name == "bureauScoreUpperLimit" &&
        parseInt(e.target.value) > 900
      ) {
        form.errors.bureauScoreUpperLimit =
          "Enter value less then or equal to 900";
        form.errors.bureauScoreLowerLimit = null;
      } else if (
        name == "bureauScoreUpperLimit" &&
        parseInt(e.target.value) > parseInt(form.bureauScoreLowerLimit)
      ) {
        form.errors.bureauScoreUpperLimit = null;
        form.errors.bureauScoreLowerLimit = null;
      } else if (
        name == "bureauScoreLowerLimit" &&
        parseInt(e.target.value) >= parseInt(form.bureauScoreUpperLimit)
      ) {
        form.errors.bureauScoreLowerLimit =
          "Lower limit can not be less than or equal to Upper limit.";
        form.errors.bureauScoreUpperLimit = null;
      } else if (
        name == "bureauScoreLowerLimit" &&
        parseInt(e.target.value) < parseInt(form.bureauScoreUpperLimit)
      ) {
        form.errors.bureauScoreLowerLimit = null;
        form.errors.bureauScoreUpperLimit = null;
      } else if (
        name == "tenureUpperLimit" &&
        parseInt(e.target.value) <= parseInt(form.tenureLowerLimit)
      ) {
        form.errors.tenureUpperLimit =
          "Upper limit can not be less than or equal to Lower limit.";
        form.errors.tenureLowerLimit = null;
      } else if (
        name == "tenureUpperLimit" &&
        parseInt(e.target.value) > parseInt(form.tenureLowerLimit)
      ) {
        form.errors.tenureUpperLimit = null;
        form.errors.tenureLowerLimit = null;
      } else if (
        name == "tenureLowerLimit" &&
        parseInt(e.target.value) >= parseInt(form.tenureUpperLimit)
      ) {
        form.errors.tenureLowerLimit =
          "Lower limit can not be less than or equal to Upper limit.";
        form.errors.tenureUpperLimit = null;
      } else if (
        name == "tenureLowerLimit" &&
        parseInt(e.target.value) < parseInt(form.tenureUpperLimit)
      ) {
        form.errors.tenureLowerLimit = null;
        form.errors.tenureUpperLimit = null;
      } else {
        if (isNaN(parseInt(e.target.value))) {
          form.errors[name] = true;
        } else {
          form.errors[name] = null;
        }
      }

      this.setState({ form });
    } else {
      if (form[name] == "" || form[name] == null || form[name] == undefined) {
        form.errors[name] = true;
        this.setState({ form });
      } else {
        form.errors[name] = null;
        this.setState({ form });
      }
    }
  };

  onValidateDropdown = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    if (form[name] === "" || form[name] === null || form[name] === undefined) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      form.errors[name] = null;
      this.setState({ form });
    }
  };

  render() {
    let { form, showModal, showInner, isLoading } = this.state;
    let { errors } = form;

    let diableSave = false;
    let isRequiredFeildFilled = false;
    // Object.keys(form).map((name) => {
    //   console.log("form", form[name]);
    //   if (form[name] == "" || form[name] == null || form[name] == undefined) {
    //     isRequiredFeildFilled = true;
    //     return true;
    //   }
    // });

    Object.keys(form).map((name) => {
      console.log("form", form[name]);
      if (
        form[name] == "" &&
        name != "loanAmountLowerLimit" &&
        name != "loanAmountUpperLimit"
      ) {
        isRequiredFeildFilled = true;
        return true;
      }
    });

    Object.keys(errors).map((error) => {
      console.log("error", errors[error]);
      if (errors[error] == true || errors[error] != null) {
        diableSave = true;
        return true;
      }
    });
    // console.log("form", this.props.action == "add" && form && form.length != 0)
    return (
      <>
        <div>
          <Modal
            className="product-norm-container"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            size="lg"
          >
            <Modal.Header>
              <Modal.Title>
                {this.props.action == "edit"
                  ? "Edit MFG Emi Multiplier"
                  : "Add MFG Emi Multiplier"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
              {isLoading ? (
                <h4 className="text-center">LOADING...</h4>
              ) : (
                <div className="row ">
                  <div className="form-group col-md-4 ">
                    <label>
                      <i className="text-danger">*</i> Bureau Score (lower
                      limit)
                    </label>
                    <div class="inner-addon right-addon">
                      <input
                        type="text"
                        // pattern="\d*"
                        maxLength="9"
                        name="bureauScoreLowerLimit"
                        value={
                          form.bureauScoreLowerLimit &&
                          formatIndianCurrency(form.bureauScoreLowerLimit)
                        }
                        onChange={(e) => {
                          e.target.value = replaceComma(e.target.value);
                          if (
                            e.target.value == "" ||
                            (Math.ceil(e.target.value) <= 999 &&
                              parseInt(e.target.value) >= 0)
                          ) {
                            e.target.value = e.target.value
                              .toString()
                              .split(".")
                              .map((el, i) =>
                                i ? el.split("").slice(0, 2).join("") : el
                              )
                              .join(".");
                            this.handleInputChange(e, "bureauScoreLowerLimit");
                          }
                          /* if (checkIfNotDecimal(e.target.value)) {
                                                            this.handleInputChange(e, "bureauScoreLowerLimit")
                                                        } */
                        }}
                        onBlur={(e) => {
                          e.target.value = replaceComma(e.target.value);
                          this.onValidate(e, "bureauScoreLowerLimit");
                        }}
                        placeholder="Enter Bureau Score (Lower Limit)"
                        className={`form-input ${
                          errors.bureauScoreLowerLimit
                            ? "error-input-border"
                            : ""
                        } `}
                        disabled={false}
                      />
                      {errors.bureauScoreLowerLimit && (
                        <span className="errorMsg">
                          {errors.bureauScoreLowerLimit == true
                            ? "Enter Bureau Score (Lower Limit)"
                            : errors.bureauScoreLowerLimit}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group col-md-4 ">
                    <label>
                      <i className="text-danger">*</i> Bureau Score (upper
                      limit)
                    </label>
                    <div class="inner-addon right-addon">
                      <input
                        type="text"
                        // pattern="\d*"
                        maxLength="9"
                        name="bureauScoreUpperLimit"
                        value={
                          form.bureauScoreUpperLimit &&
                          formatIndianCurrency(form.bureauScoreUpperLimit)
                        }
                        onChange={(e) => {
                          e.target.value = replaceComma(e.target.value);
                          if (
                            e.target.value == "" ||
                            (Math.ceil(e.target.value) <= 999 &&
                              parseInt(e.target.value) >= 0)
                          ) {
                            e.target.value = e.target.value
                              .toString()
                              .split(".")
                              .map((el, i) =>
                                i ? el.split("").slice(0, 2).join("") : el
                              )
                              .join(".");
                            this.handleInputChange(e, "bureauScoreUpperLimit");
                          }
                          /* if (checkIfNotDecimal(e.target.value)) {
                                                            this.handleInputChange(e, "bureauScoreUpperLimit")
                                                        } */
                        }}
                        onBlur={(e) => {
                          e.target.value = replaceComma(e.target.value);
                          this.onValidate(e, "bureauScoreUpperLimit");
                        }}
                        placeholder="Enter Bureau Score (upper limit)"
                        className={`form-input ${
                          errors.bureauScoreUpperLimit
                            ? "error-input-border"
                            : ""
                        } `}
                        disabled={false}
                      />
                      {errors.bureauScoreUpperLimit && (
                        <span className="errorMsg">
                          {errors.bureauScoreUpperLimit == true
                            ? "Enter Bureau Score (upper limit)"
                            : errors.bureauScoreUpperLimit}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group col-md-4 ">
                    <label>
                      <i className="text-danger">*</i> EMI Month (Min)
                    </label>
                    <div class="inner-addon right-addon">
                      <input
                        type="text"
                        // pattern="\d*"
                        maxLength="9"
                        name="emiMonth"
                        value={form.emiMonth}
                        onChange={(e) => {
                          e.target.value = replaceComma(e.target.value);
                          if (checkIfNotDecimal(e.target.value)) {
                            this.handleInputChange(e, "emiMonth");
                          }
                        }}
                        onBlur={(e) => this.onValidate(e, "emiMonth")}
                        placeholder="Enter Base Amount"
                        className={`form-input ${
                          errors.emiMonth ? "error-input-border" : ""
                        } `}
                        disabled={false}
                      />
                      {errors.emiMonth && (
                        <span className="errorMsg">Please Enter Base Emi</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group col-md-4 ">
                    <label>
                      <i className="text-danger">*</i> Loan Amount (lower limit
                      - In Rupees)
                    </label>
                    <div class="inner-addon right-addon">
                      <input
                        type="text"
                        // pattern="\d*"
                        maxLength="9"
                        name="loanAmountLowerLimit"
                        value={
                          form.loanAmountLowerLimit &&
                          formatIndianCurrency(form.loanAmountLowerLimit)
                        }
                        onChange={(e) => {
                          e.target.value = replaceComma(e.target.value);
                          if (checkIfNotDecimal(e.target.value)) {
                            this.handleInputChange(e, "loanAmountLowerLimit");
                          }
                        }}
                        onBlur={(e) => {
                          e.target.value = replaceComma(e.target.value);
                          this.onLoanValidate(e, "loanAmountLowerLimit");
                        }}
                        placeholder="Enter Loan Amount (lower limit)"
                        className={`form-input ${
                          errors.loanAmountLowerLimit
                            ? "error-input-border"
                            : ""
                        } `}
                        disabled={false}
                      />
                      {errors.loanAmountLowerLimit && (
                        <span className="errorMsg">
                          {errors.loanAmountLowerLimit == true
                            ? "Enter Loan Amount (Lower Limit)"
                            : errors.loanAmountLowerLimit}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group col-md-4 ">
                    <label>
                      <i className="text-danger">*</i> Loan Amount (upper limit
                      - In Rupees)
                    </label>
                    <div class="inner-addon right-addon">
                      <input
                        type="text"
                        // pattern="\d*"
                        maxLength="9"
                        value={
                          form.loanAmountUpperLimit &&
                          formatIndianCurrency(form.loanAmountUpperLimit)
                        }
                        onChange={(e) => {
                          e.target.value = replaceComma(e.target.value);
                          if (checkIfNotDecimal(e.target.value)) {
                            this.handleInputChange(e, "loanAmountUpperLimit");
                          }
                        }}
                        onBlur={(e) => {
                          e.target.value = replaceComma(e.target.value);
                          this.onLoanValidate(e, "loanAmountUpperLimit");
                        }}
                        placeholder="Enter Loan Amount(upper limit)"
                        className={`form-input ${
                          errors.loanAmountUpperLimit
                            ? "error-input-border"
                            : ""
                        } `}
                        disabled={false}
                      />
                      {errors.loanAmountUpperLimit && (
                        <span className="errorMsg">
                          {errors.loanAmountUpperLimit == true
                            ? "Enter Loan Amount (Upper Limit)"
                            : errors.loanAmountUpperLimit}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group col-md-4 ">
                    <label>
                      <i className="text-danger">*</i> Tenure (lower limit)
                    </label>
                    <div class="inner-addon right-addon">
                      <input
                        type="text"
                        // pattern="\d*"
                        maxLength="9"
                        name="tenureLowerLimit"
                        value={
                          form.tenureLowerLimit &&
                          formatIndianCurrency(form.tenureLowerLimit)
                        }
                        onChange={(e) => {
                          e.target.value = replaceComma(e.target.value);
                          if (
                            e.target.value == "" ||
                            (Math.ceil(e.target.value) <= 99 &&
                              parseInt(e.target.value) > 0)
                          ) {
                            e.target.value = e.target.value
                              .toString()
                              .split(".")
                              .map((el, i) =>
                                i ? el.split("").slice(0, 2).join("") : el
                              )
                              .join(".");
                            this.handleInputChange(e, "tenureLowerLimit");
                          }
                        }}
                        onBlur={(e) => {
                          e.target.value = replaceComma(e.target.value);
                          this.onValidate(e, "tenureLowerLimit");
                        }}
                        placeholder="Enter Tenure (Lower Limit)"
                        className={`form-input ${
                          errors.tenureLowerLimit ? "error-input-border" : ""
                        } `}
                        disabled={false}
                      />
                      {errors.tenureLowerLimit && (
                        <span className="errorMsg">
                          {errors.tenureLowerLimit == true
                            ? "Enter Tenure (Lower Limit)"
                            : errors.tenureLowerLimitt}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group col-md-4 ">
                    <label>
                      <i className="text-danger">*</i> Tenure (upper limit)
                    </label>
                    <div class="inner-addon right-addon">
                      <input
                        type="text"
                        // pattern="\d*"
                        maxLength="9"
                        name="tenureUpperLimit"
                        value={
                          form.tenureUpperLimit &&
                          formatIndianCurrency(form.tenureUpperLimit)
                        }
                        onChange={(e) => {
                          e.target.value = replaceComma(e.target.value);
                          if (
                            e.target.value == "" ||
                            (Math.ceil(e.target.value) <= 99 &&
                              parseInt(e.target.value) > 0)
                          ) {
                            e.target.value = e.target.value
                              .toString()
                              .split(".")
                              .map((el, i) =>
                                i ? el.split("").slice(0, 2).join("") : el
                              )
                              .join(".");
                            this.handleInputChange(e, "tenureUpperLimit");
                          }
                        }}
                        onBlur={(e) => {
                          e.target.value = replaceComma(e.target.value);
                          this.onValidate(e, "tenureUpperLimit");
                        }}
                        placeholder="Enter Tenure (upper limit)"
                        className={`form-input ${
                          errors.tenureUpperLimit ? "error-input-border" : ""
                        } `}
                        disabled={false}
                      />
                      {errors.tenureUpperLimit && (
                        <span className="errorMsg">
                          {errors.tenureUpperLimit == true
                            ? "Enter Tenure (upper limit)"
                            : errors.tenureUpperLimit}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group col-md-4 ">
                    <label>
                      <i className="text-danger">*</i> Multiplier
                    </label>
                    <div class="inner-addon right-addon">
                      <input
                        type="text"
                        // pattern="\d*"
                        maxLength="9"
                        name="multiplier"
                        value={form.multiplier}
                        onChange={(e) => {
                          if (
                            e.target.value == "" ||
                            (Math.ceil(e.target.value) <= 100 &&
                              parseInt(e.target.value) >= 0)
                          ) {
                            e.target.value = e.target.value
                              .toString()
                              .split(".")
                              .map((el, i) =>
                                i ? el.split("").slice(0, 2).join("") : el
                              )
                              .join(".");
                            this.handleInputChange(e, "multiplier");
                          }
                        }}
                        onBlur={(e) => this.onValidate(e, "multiplier")}
                        placeholder="Enter Multiplier Value"
                        className={`form-input ${
                          errors.multiplier ? "error-input-border" : ""
                        } `}
                        disabled={false}
                      />
                      {errors.multiplier && (
                        <span className="errorMsg">
                          Please Enter Multiplier Value
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mb-4 col-md-4 ">
                    <label>
                      <i className="text-danger">*</i> Loan Type
                    </label>
                    <Select
                      defaultValue={
                        Loan.filter(
                          ({ LoanValue }) => LoanValue == form.loanType
                        )[0]
                      }
                      onChange={(e) => {
                        this.handleLoanSelectChanges(e, "loanType");
                      }}
                      onBlur={(e) => this.onValidateDropdown(e, "loanType")}
                      name="loanType"
                      options={Loan}
                      placeholder="Select Loan Type"
                      valueKey="LoanValue"
                      labelKey="LoanLebel"
                      getOptionLabel={(option) => option.LoanLebel}
                      getOptionValue={(option) => option.LoanValue}
                      className={`${
                        errors.loanType ? "error-input-border" : ""
                      } `}
                      isDisabled={false}
                    />
                    {errors.loanType && (
                      <span className="errorMsg">Please Select Loan Type</span>
                    )}
                  </div>
                  <div className="mb-4 col-md-4 ">
                    <label>
                      <i className="text-danger">*</i> Condition Type
                    </label>
                    <Select
                      defaultValue={
                        Condition.filter(
                          ({ ConditionValue }) =>
                            ConditionValue == form.conditionType
                        )[0]
                      }
                      onChange={(e) => {
                        this.handleConditionSelectChanges(e, "conditionType");
                      }}
                      onBlur={(e) =>
                        this.onValidateDropdown(e, "conditionType")
                      }
                      name="conditionType"
                      options={Condition}
                      placeholder="Select Condition Type"
                      valueKey="ConditionValue"
                      labelKey="ConditionLebel"
                      getOptionLabel={(option) => option.ConditionLebel}
                      getOptionValue={(option) => option.ConditionValue}
                      className={`${
                        errors.conditionType ? "error-input-border" : ""
                      } `}
                      isDisabled={false}
                    />
                    {errors.conditionType && (
                      <span className="errorMsg">
                        Please Select Condition Type
                      </span>
                    )}
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-danger" onClick={this.handleModalClose}>
                Cancel
              </Button>
              <Button
                className="btn-success"
                disabled={diableSave || isRequiredFeildFilled || isLoading}
                onClick={this.handleSubmit}
              >
                {this.props.action == "edit" ? "Update" : "Save"}
              </Button>
            </Modal.Footer>
          </Modal>
          <CloseModal
            show={showInner}
            button2={this.handleModalClose}
            button1={this.innerModalClose}
            title="Do you want to close ?"
          />
        </div>
      </>
    );
  }
}

export default AddEditMFGEmiMaster;
