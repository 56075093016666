import React, { Component } from 'react';
import { Button, Modal } from "react-bootstrap";
// import Select from "../../../Components/Input/Select";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

import CloseModal from "../../../Components/CloseModal";
import { formatIndianCurrency, replaceComma } from "../../../Utils/helperFunctions";
import { getAllMotionDropdown, getAllPropertyDropdown, getAllSubtypeDropdown, getCollateralById, addOrUpdateCollateral } from "../../../Utils/Services/collateral";

import "./style.css"
import { cloneDeep } from 'lodash';
toast.configure();

let initForm = {
    subtype: "",
    motiontype: "",
    propertytype: "",
    id: null,
    ltv: ""
}
class AddEditCollateral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            isLoading: false,
            showInner: false,
            movableDropdownValue: [],
            propertyDropdownValue: [],
            subtypeDropdownValue: [],
            form: cloneDeep(initForm),
            errors: {
                subtype: null,
                motiontype: null,
                propertytype: null,
                id: null,
                ltv: null
            }
        }
    }

    componentDidMount = () => {
        console.log("this.props.editData", this.props);
        this.getSubType();
        this.getProperty();
        this.getMotionType();
        if (this.props.editData && this.props.action == "edit") {
            let { form } = this.state
            let obj = { ...form, ...this.props.editData }
            this.setState({ form: obj });
        }
        this.setState({ showModal: true })
    }

    getSubType = () => {
        getAllSubtypeDropdown().then((response) => {
            if (response.error) {
                return;
            } else if (response.data && response.data.error) {
                toast.error(response.data.message, {
                    type: toast.TYPE.ERROR,
                    autoClose: 2000
                });
                return
            } else {
                this.setState({ subtypeDropdownValue: response.data.data })
            }
        });
    }

    getProperty = () => {
        getAllPropertyDropdown().then((response) => {
            if (response.error) {
                return;
            } else if (response.data && response.data.error) {
                toast.error(response.data.message, {
                    type: toast.TYPE.ERROR,
                    autoClose: 2000
                });
                return
            } else {
                this.setState({ propertyDropdownValue: response.data.data })
            }
        });
    }

    getMotionType = () => {
        getAllMotionDropdown().then((response) => {
            if (response.error) {
                return;
            } else if (response.data && response.data.error) {
                toast.error(response.data.message, {
                    type: toast.TYPE.ERROR,
                    autoClose: 2000
                });
                return
            } else {
                this.setState({ movableDropdownValue: response.data.data })
            }
        });
    }

    handleModalClose = () => {
        this.setState({ showInner: !this.state.showInner });
        // this.this.props.addRole();
    };

    innerModalClose = modclose => {
        if (!modclose) {
            this.handleModalClose()
        };
        this.setState({ showModal: false })
        if (this.props.action == "edit") {
            this.props.handleEditCollateral()
        } else {
            this.props.handleAddCollateral()
        }
    };

    handleMotionSelectChange = (e, name) => {
        console.log("handleMotionSelectChange", e, name);
        let { form } = this.state;
        let { motiontype } = e;
        form[name] = motiontype;
        this.setState({ form });
    }

    handlePropertySelectChange = (e, name) => {
        console.log("handlePropertySelectChange", e, name)
        let { form } = this.state;
        let { type } = e;
        form[name] = type;
        this.setState({ form });
    }

    handleSubTypeSelectChange = (e, name) => {
        console.log("handleSubTypeSelectChange", e, name);
        let { form } = this.state;
        let { type } = e;
        form[name] = type;
        this.setState({ form });
    }

    handleInputChange = (e, name) => {
        e.preventDefault();
        let { form } = this.state;
        console.log("handleInputChange", e, name)
        form[name] = e.target.value;
        this.setState({ form });
    }

    onValidate = (e, name) => {
        e.preventDefault();
        let { errors, form } = this.state;
        // let { product, loantype, lowerloanamtnorms, baseamt, state, percentagevalue } = form;
        console.log("onValidate", name, e.target.value)
        if ((form[name] == "" || form[name] == null || form[name] == undefined)) {
            errors[name] = true;
            this.setState({ form });
        } else {
            errors[name] = null;
            this.setState({ form });
        }
    }

    handleSubmit = () => {
        let { form } = this.state;
        let obj = form;
        // delete obj.errors;
        this.setState({ isLoading: true });
        addOrUpdateCollateral(obj).then((response) => {
            this.setState({ isLoading: false })
            if (response.error) {
                return;
            } else if (response.data && response.data.error) {
                toast.error(response.data.message, {
                    type: toast.TYPE.ERROR,
                    autoClose: 2000
                });
                return
            } else {
                if (this.props.editData && this.props.action == "edit") {
                    this.props.getCollateral();
                    this.props.handleEditCollateral();
                } else {
                    this.props.getCollateral();
                    this.props.handleAddCollateral();
                }
                toast.success(response.data.message, {
                    type: toast.TYPE.SUCCESS,
                    autoClose: 2000
                });
                this.setState({ showModal: false })
            }
        });
    }

    render() {
        let { form, showModal, showInner, isLoading, movableDropdownValue, subtypeDropdownValue, propertyDropdownValue, errors } = this.state;
        let diableSave = false;
        let isRequiredFeildFilled = false;

        Object.keys(form).map((name) => {
            console.log("form", form[name])
            if ((form[name] == "" || form[name] == null || form[name] == undefined) && name != "id") {
                isRequiredFeildFilled = true;
                return true
            }
        })

        errors && Object.keys(errors).map((error) => {
            console.log("error", errors[error])
            if (errors[error] == true || errors[error] != null) {
                diableSave = true;
                return true
            }
        })
        console.log("form.state", form, diableSave, isRequiredFeildFilled)
        return (
            <>
                {(movableDropdownValue && movableDropdownValue.length > 0) && (subtypeDropdownValue && subtypeDropdownValue.length > 0) && (propertyDropdownValue && propertyDropdownValue.length > 0) && <div>
                    <Modal
                        className="franking-fee-container"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={showModal}
                        size="lg"
                    >
                        <Modal.Header>
                            <Modal.Title>
                                {this.props.action == "edit" ? "Edit LTV %" : "Add LTV %"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-4">
                            {isLoading ? (
                                <h4 className="text-center">LOADING...</h4>
                            ) : (
                                    <div className="row">
                                        <div className="mb-4 col-md-4">
                                            <label>
                                                <i className="text-danger">*</i> Motion type
                                                        </label>
                                            <Select
                                                defaultValue={movableDropdownValue.filter(({ motiontype }) => motiontype == form.motiontype)[0]}
                                                onChange={e => {
                                                    this.handleMotionSelectChange(e, "motiontype");
                                                }}
                                                onBlur={(e) => this.onValidate(e, "motiontype")}
                                                name="motiontype"
                                                options={movableDropdownValue}
                                                placeholder="Select motion type"
                                                valueKey="motiontype"
                                                labelKey="motiontype"
                                                getOptionLabel={option => option["motiontype"]}
                                                getOptionValue={option => option["motiontype"]}
                                                className={`${errors.state ? "error-input-border" : ""
                                                    } `}
                                                isDisabled={false}
                                            />
                                            {errors.motiontype && (
                                                <span className="errorMsg">
                                                    Please Select Motion Type
                                                </span>
                                            )}
                                        </div>
                                        <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                                            <label>
                                                <i className="text-danger">*</i> Property type
                                                </label>
                                            <Select
                                                defaultValue={propertyDropdownValue.filter(({ type }) => type == form.propertytype)[0]}
                                                onChange={e => {
                                                    this.handlePropertySelectChange(e, "propertytype");
                                                }}
                                                onBlur={(e) => this.onValidate(e, "propertytype")}
                                                name="propertytype"
                                                options={propertyDropdownValue}
                                                placeholder="Select Property Type"
                                                valueKey="type"
                                                labelKey="type"
                                                getOptionLabel={option => option.type}
                                                getOptionValue={option => option.type}
                                                className={`${errors.propertytype ? "error-input-border" : ""
                                                    } `}
                                                isDisabled={false}
                                            />
                                            {errors.propertytype && (
                                                <span className="errorMsg">
                                                    Please Select Property Type
                                                </span>
                                            )}
                                        </div>
                                        <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                                            <label>
                                                <i className="text-danger">*</i> Sub Type
                                                </label>
                                            <Select
                                                defaultValue={subtypeDropdownValue.filter(({ type }) => type == form.subtype)[0]}
                                                onChange={e => {
                                                    this.handleSubTypeSelectChange(e, "subtype");
                                                }}
                                                onBlur={(e) => this.onValidate(e, "subtype")}
                                                name="subtype"
                                                options={subtypeDropdownValue}
                                                placeholder="Select Sub Type"
                                                valueKey="type"
                                                labelKey="type"
                                                getOptionLabel={option => option.type}
                                                getOptionValue={option => option.type}
                                                className={`${errors.subtypeDropdownValue ? "error-input-border" : ""
                                                    } `}
                                                isDisabled={false}
                                            />
                                            {errors.subtype && (
                                                <span className="errorMsg">
                                                    Please Select Sub Type
                                                </span>
                                            )}
                                        </div>
                                        <div className="form-group col-md-4 ">
                                            <label>
                                                <i className="text-danger">*</i> LTV (%)
                                                </label>
                                            <div class="inner-addon right-addon">
                                                <input
                                                    type="text"
                                                    // pattern="\d*"
                                                    maxLength="9"
                                                    name="ltv"
                                                    value={form.ltv}
                                                    onChange={e => {
                                                        if (
                                                            e.target.value == "" ||
                                                            (Math.ceil(e.target.value) <= 100 && parseInt(e.target.value) >= 0)
                                                        ) {
                                                            e.target.value = e.target.value
                                                                .toString()
                                                                .split(".")
                                                                .map((el, i) =>
                                                                    i ? el.split("").slice(0, 2).join("") : el
                                                                )
                                                                .join(".");
                                                            this.handleInputChange(e, "ltv")
                                                        }

                                                    }}
                                                    onBlur={(e) => this.onValidate(e, "ltv")}
                                                    placeholder="Enter ltv (%)"
                                                    className={`form-input ${errors.ltv ? "error-input-border"
                                                        : ""
                                                        } `}
                                                    disabled={false}
                                                />
                                                {errors.ltv && (
                                                    <span className="errorMsg">
                                                        Please Enter ltv (%)
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn-danger" onClick={this.handleModalClose}>
                                Cancel
                  </Button>
                            <Button className="btn-success" disabled={diableSave || isRequiredFeildFilled || isLoading} onClick={this.handleSubmit}>
                                {this.props.action == "edit" ? "Update" : "Create"}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <CloseModal
                        show={showInner}
                        button2={this.handleModalClose}
                        button1={this.innerModalClose}
                        title="Do you want to close ?"
                    />
                </div>}
            </>
        )
    }
}

export default AddEditCollateral;
