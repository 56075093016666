import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { allspokes, migrateSpoke } from "../../../Utils/Services/spokeMaster";
import ClipLoader from "react-spinners/ClipLoader";

const MigrateSpoke = (props) => {
  const { showMigrateSpokeModal, setshowMigrateSpokeModal, spokeMaster } =
    props;

  const [oldSpoke, setOldSpoke] = useState(null);
  const [newSpoke, setNewSpoke] = useState(null);
  const [spokeData, setSpokeData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    allspokes().then((res) => {
      let ans = res.data.map((item) => {
        let obj = { ...item, showSpoke: `${item.spokeName} - ${item.pincode}` };
        return obj;
      });

      setSpokeData(ans);
      setLoading(false);
    });
  }, []);

  const submitHandler = () => {
    if (oldSpoke.spokeName === newSpoke.spokeName) {
      toast.error("Old And New Spoke Name Cannot Be Same");
    } else {
      const obj = {
        newSpoke: newSpoke.spokeName,
        newPincode: newSpoke.pincode,
        oldSpoke: oldSpoke.spokeName,
        oldPincode: oldSpoke.pincode,
      };
      migrateSpoke(obj)
        .then((res) => {
          if (res.error) {
            console.log(res.error);
          } else if (res.data && res.data.error) {
            toast.error(res.data.message);
          } else {
            toast.success("Spoke Migrated Successfully!");
            spokeMaster(0);
          }
          setshowMigrateSpokeModal(!showMigrateSpokeModal);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Modal
      className="product-norm-container"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showMigrateSpokeModal}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>Migrate Spoke</Modal.Title>
      </Modal.Header>
      {loading ? (
        <div className="w-100 d-flex justify-content-center p-5">
          <ClipLoader color="#17a2b8" size={60} />
        </div>
      ) : (
        <Modal.Body className="p-4 d-flex">
          <div className="w-100 p-4">
            <label>
              <i className="text-danger">*</i> Old Spoke
            </label>
            <Select
              placeholder="Select Old Spoke"
              getOptionLabel={(option) => option["showSpoke"]}
              getOptionValue={(option) => option["spokeName"]}
              options={spokeData}
              value={oldSpoke}
              onChange={(e) => setOldSpoke(e)}
            />
          </div>
          <div className="w-100 p-4">
            <label>
              <i className="text-danger">*</i> New Spoke
            </label>
            <Select
              placeholder="Select New Spoke"
              getOptionLabel={(option) => option["showSpoke"]}
              getOptionValue={(option) => option["spokeName"]}
              options={spokeData}
              value={newSpoke}
              onChange={(e) => setNewSpoke(e)}
            />
          </div>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button
          className="btn-danger"
          onClick={() => setshowMigrateSpokeModal(!showMigrateSpokeModal)}
        >
          Cancel
        </Button>
        <Button
          disabled={!oldSpoke || !newSpoke}
          className="btn-success"
          onClick={submitHandler}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MigrateSpoke;
