import { get, post, awspost } from "../httpInterceptor";
const CORS_URL = "https://cors-anywhere.herokuapp.com/";

// API for upload document

export const uploadGoldAppraiserDoc = (obj) => {
  return post(`/goldappraiser/uploadDoc`, obj, false, true).then((res) => {
    console.log("uploadGoldAppraiserDoc", res);
    return res;
  });
};

/* export const uploadGoldAppraiserDoc = (data, zip) => {
    return postFile(
        `/goldappraiser/uploadDoc?goldappraiserDocs=${encodeURIComponent(
            JSON.stringify(data)
        )}
      `,
        zip,
        "multipart/form-data"
    ).then(res => {
        console.log("RESPONSE :: Upload Document ::: ", res);
        return res;
    });
}; */

// API for save or update gold details

export const saveOrUpdateGoldAppraiser = (obj) => {
  return post(`/goldappraiser/saveorupdategoldappraiser`, obj, false).then(
    (res) => {
      console.log("saveOrUpdateGoldAppraiser", res);
      return res;
    }
  );
};

// API for get all gold documents

export const getGoldAppraiserDocByAppraiserid = () => {
  return get(`/franchise/getDocument`).then((res) => {
    console.log("RESPONSE :: getGoldAppraiserDocByAppraiserid ::: ", res);
    return res;
  });
};

// API for get gold listing

export const getGoldAppraiserApprovalList = () => {
  return get(`/goldappraiser/getgoldappraiserapprovallist`).then((res) => {
    console.log("RESPONSE :: getGoldAppraiserApprovalList ::: ", res);
    return res;
  });
};

export const findAppraiserByApplication = (obj) => {
  return post(`/goldappraiser/getGoldappraiserByAppId`, obj, false).then(
    (res) => {
      console.log("findAppraiserByApplication", res);
      return res;
    }
  );
};

// API for delete document

export const deleteDocumentById = (obj) => {
  return post(`/goldappraiser/deleteDocument`, obj, false).then((res) => {
    console.log("deleteDocumentById", res);
    return res;
  });
};

export const getGoldAppraiserMasterList = (obj) => {
  return post(`/goldappraiser/getgoldappraiserlist`, obj, false).then((res) => {
    console.log("getGoldAppraiserMasterList", res);
    return res;
  });
};

// API for check the duplicate appraiser code

export const checkAppraiserDedupe = (obj) => {
  return post(`/goldappraiser/checkgoldappraiserispresent`, obj, false).then(
    (res) => {
      console.log("checkAppraiserDedupe", res);
      return res;
    }
  );
};
