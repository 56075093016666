import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import {
  findAllRoles,
  getRoleById,
  saveOrUpdateRole,
} from "../../../Utils/Services/role";
import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import { formatIndianCurrency } from "../../../Utils/helperFunctions";
import AddEditRole from "./AddEditRole";
toast.configure();

const RoleManagement = (props) => {
  let [search, setSearch] = useState("");
  let [roleData, setroleData] = useState([]);
  let [roleContainer, setroleContainer] = useState([]);
  let [showEditRoleModal, setshowEditRoleModal] = useState(false);
  let [editData, setEditData] = useState({});

  useEffect(() => {
    allRoles();
  }, []);

  const allRoles = () => {
    findAllRoles().then((res) => {
      console.log("Roles", res);
      if (res.error) {
        return;
      }
      if (res.data && res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        /*  toast.success(res.data.message, {
                type: toast.TYPE.SUCCESS,
                autoClose: 2000
            }); */
        setroleData(res.data.data);
        setroleContainer(res.data.data);
      }
    });
  };

  const columns = [
    {
      Header: "SR. No",
      width: 70,
      Cell: (data) => {
        // console.log("data...", data)
        return data.index + 1;
      },
      /* Cell: ({ original }) => {
                return original.id && original.id ? original.id : null;
            } */
    },
    {
      Header: "Role Name",
      width: 200,
      Cell: ({ original }) => {
        return original && original.roleName ? original.roleName : null;
      },
    },
    {
      Header: "Description",
      Cell: ({ original }) => {
        return original && original.description ? original.description : null;
      },
    },
    {
      Header: "Level",
      Cell: ({ original }) => {
        return original && original.levelling ? original.levelling : null;
      },
    },
    {
      Header: "Rank",
      Cell: ({ original }) => {
        return original && original.rank ? original.rank : null;
      },
    },
    {
      Header: "Geography",
      Cell: ({ original }) => {
        return original && original.geography ? original.geography : null;
      },
    },

    {
      Header: "Action",
      width: 125,
      disabled: false,
      accessor: "delete",
      accessor: "edit",

      Cell: ({ original }) => {
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              onClick={() => handleEditRole(original)}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const handleEditRole = (obj) => {
    setshowEditRoleModal(!showEditRoleModal);
    setEditData(obj);
  };

  const handleSearch = (e) => {
    // let { roleContainer } = this.state;
    let array = [];
    roleContainer.map((res) => {
      let roleName = res.roleName;
      if (roleName)
        if (res.roleName.toUpperCase().match(e.target.value.toUpperCase())) {
          array.push(res);
        }
    });

    setSearch(e.target.value);
    setroleData(e.target.value ? array : roleContainer);
  };

  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box">Role Management</h5>
          <div className="d-flex align-items-center mt-5">
            <div className="form-group m-0 search-input">
              <div className="inner-addon right-addon">
                <i className="glyphicon glyphicon-search mt-1 font-size-20"></i>
                <input
                  type="text"
                  className="form-input"
                  name="search"
                  value={search}
                  onChange={(e) => {
                    handleSearch(e);
                  }}
                  // onBlur={this.handleValidate}
                  placeholder="Search..."
                />
              </div>
            </div>
            {/*  <button
                        disabled={false}
                        onClick={handleAddProcessingFee}
                        className="add-btn ml-auto btn btn-info btn-lg"
                    >
                        {" "}
                Add {" "}
                    </button> */}
            {/* {showAddProcessingFeeModal && (
                        <AddEditProcessingFee
                            action={"add"}
                            getProcessingFee={getProcessingFee}
                            handleAddProcessingFee={handleAddProcessingFee}
                        />
                    )} */}
            {showEditRoleModal && (
              <AddEditRole
                action={"edit"}
                allRoles={allRoles}
                editData={editData}
                handleEditRole={handleEditRole}
              />
            )}
          </div>

          <div className={`mt-4 react-table`}>
            <ReactTable
              data={roleData}
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default RoleManagement;


