// import React from 'react'
import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import {
  getAllFunding,
  deleteFundingGrid,
} from "../../../Utils/Services/fundingmaster";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddEditFundingMaster from "./AddEditFundingMaster";
import { formatIndianCurrency } from "../../../Utils/helperFunctions";
toast.configure();

const FundingMaster = (props) => {
  let [funding, setFunding] = useState([]);
  let [
    showAddProductFundingGridModal,
    setshowAddProductFundingGridModal,
  ] = useState(false);
  let [
    showEditProductFindingGridModal,
    setshowEditProductFindingGridModal,
  ] = useState(false);
  let [editData, setEditData] = useState({});

  useEffect(() => {
    getProductFundingGrid();
  }, []);

  const getProductFundingGrid = () => {
    getAllFunding().then((res) => {
      console.log("getAllFunding", res);
      if (res.error) {
        return;
      } else if (res.data && res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        if (res && res.data && res.data.data) {
          setFunding(res.data.data);
        }
      }
    });
  };

  const deleteFundingGridById = (id) => {
    let postData = {
      id: id,
    };
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes, Delete It!",
          onClick: () => {
            deleteFundingGrid(postData).then((response) => {
              console.log("deleteFundingGrid", response);
              if (response.error) {
                return;
              }
              if (response.data && response.data.error) {
                toast.error(response.data.message, {
                  type: toast.TYPE.ERROR,
                  autoClose: 2000,
                });
              } else {
                toast.success(response.data.message, {
                  type: toast.TYPE.SUCCESS,
                  autoClose: 2000,
                });
                getProductFundingGrid();
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            // this.getAllFunding();
          },
        },
      ],
    });
  };

  const columns = [
    {
      Header: "SR. No",
      width: 70,
      Cell: (data) => {
        return data.index + 1;
      },
    },

    {
      Header: "Expert score",
      Cell: (data) => {
        let { original } = data;
        let text = "";
        if (
          original.expertScoreUpperLimit != "" &&
          original.expertScoreLowerLimit != "" &&
          original.expertScoreUpperLimit != undefined &&
          original.expertScoreLowerLimit != undefined &&
          original.expertScoreUpperLimit != 0 &&
          original.expertScoreLowerLimit != 0
        ) {
          text = `${
            original.expertScoreLowerLimit &&
            formatIndianCurrency(original.expertScoreLowerLimit)
          }-${
            original.expertScoreUpperLimit &&
            formatIndianCurrency(original.expertScoreUpperLimit)
          }`;
        } else if (
          (original.expertScoreUpperLimit != undefined &&
            original.expertScoreLowerLimit != undefined) ||
          (original.expertScoreUpperLimit == 0 &&
            original.expertScoreLowerLimit == 0)
        ) {
          text = ``;
        }
        return text;
      },
    },

    {
      Header: "High Mark score",
      Cell: (data) => {
        let { original } = data;
        let text = "";
        if (
          original.highmarkScoreUpperLimit != "" &&
          original.highmarkScoreLowerLimit != "" &&
          original.highmarkScoreUpperLimit != undefined &&
          original.highmarkScoreLowerLimit != undefined &&
          original.highmarkScoreUpperLimit != 0 &&
          original.highmarkScoreLowerLimit != 0
        ) {
          text = `${
            original.highmarkScoreLowerLimit &&
            formatIndianCurrency(original.highmarkScoreLowerLimit)
          }-${
            original.highmarkScoreUpperLimit &&
            formatIndianCurrency(original.highmarkScoreUpperLimit)
          }`;
        } else if (
          (original.highmarkScoreUpperLimit != undefined &&
            original.highmarkScoreLowerLimit != undefined) ||
          (original.highmarkScoreUpperLimit == 0 &&
            original.highmarkScoreLowerLimit == 0)
        ) {
          text = ``;
        }
        return text;
      },
    },

    {
      Header: "Funding Amount (In Rupees)",
      Cell: ({ original }) => {
        return original.fundingAmount && original.fundingAmount
          ? original.fundingAmount &&
              formatIndianCurrency(original.fundingAmount)
          : 0;
      },
    },

    {
      Header: "Action",
      width: 125,
      disabled: false,
      accessor: "delete",
      accessor: "edit",

      Cell: (data) => {
        let { original, index } = data;
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              onClick={() => deleteFundingGridById(original.id)}
              className="fa fa-trash"
              style={{ cursor: "pointer" }}
            />
            <i
              disabled={false}
              onClick={() => handleEditProductFundingGrid(original, index)}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const handleAddProductFundingGrid = (id) => {
    console.log("handleAddProductFundingGrid");
    setshowAddProductFundingGridModal(!showAddProductFundingGridModal);
  };

  const handleEditProductFundingGrid = (obj, index) => {
    setshowEditProductFindingGridModal(!showEditProductFindingGridModal);
    setEditData(obj);
  };

  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box">
            {" "}
            Product Funding Grid
          </h5>
          <div className="d-flex align-items-center mt-5">
            <button
              disabled={false}
              onClick={handleAddProductFundingGrid}
              className="add-btn ml-auto btn btn-info btn-lg"
            >
              {" "}
              Add{" "}
            </button>
            {showAddProductFundingGridModal && (
              <AddEditFundingMaster
                action={"add"}
                getProductFundingGrid={getProductFundingGrid}
                handleAddProductFundingGrid={handleAddProductFundingGrid}
              />
            )}
            {showEditProductFindingGridModal && (
              <AddEditFundingMaster
                action={"edit"}
                getProductFundingGrid={getProductFundingGrid}
                editData={editData}
                handleEditProductFundingGrid={handleEditProductFundingGrid}
              />
            )}
          </div>

          <div className={`mt-4 react-table`}>
            <ReactTable data={funding} columns={columns} defaultPageSize={10} />
          </div>
        </section>
      </main>
    </div>
  );
};

export default FundingMaster;
