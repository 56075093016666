import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import { getAllProduct } from "../../../Utils/Services/productmaster";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddEditProductMaster from "../ProductMaster/AddEditProductMaster";
toast.configure();

const ProductMaster = (props) => {
  let [editSegment, setEditSegement] = useState([]);
  let [product, setProduct] = useState([]);

  useEffect(() => {
    getProductMaster();
  }, []);

  //getProductMaster fucnction to fetch all the data from api call
  const getProductMaster = () => {
    getAllProduct().then((res) => {
      console.log("getAllProduct", res);
      if (res.error) {
        return;
      } else if (res.data && res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        if (res && res.data && res.data.data) {
          setProduct(res.data.data);
        }
      }
    });
  };

  const columns = [
    {
      Header: "SR. No",
      width: 70,
      Cell: (data) => {
        // console.log("data...", data)
        return data.index + 1;
      },
      /* Cell: ({ original }) => {
                return original.id && original.id ? original.id : null;
            } */
    },
    {
      Header: "Product Code",
      width: 200,
      Cell: ({ original }) => {
        return original.productCode && original.productCode
          ? original.productCode
          : null;
      },
    },
    {
      Header: "Product Description",
      Cell: ({ original }) => {
        return original.productDescription && original.productDescription
          ? original.productDescription
          : null;
      },
    },

    {
      Header: "Action",
      width: 125,
      disabled: false,
      accessor: "delete",
      accessor: "edit",

      Cell: ({ original }) => {
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              // onClick={() => handleEditRoi(original)}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box"> Product Master</h5>
          <div className="d-flex align-items-center mt-5">
            {/* <button
                        disabled={false}
                        // onClick={handleAddROi}
                        className="add-btn ml-auto btn btn-info btn-lg"
                    >
                        {" "}
                Add SEGMENT{" "}
                    </button> */}

            <AddEditProductMaster getProductMaster={getProductMaster} />

            {/* {showRoiModal && (
                        <AddEditProductFundingNorms
                            action={"add"}
                            getRoi={getRoi}
                            handleAddROi={handleAddROi}
                        />
                    )}
                    {showEditRoiModal && (
                        <AddEditProductFundingNorms
                            action={"edit"}
                            getRoi={getRoi}
                            editData={editData}
                            handleEditRoi={handleEditRoi}
                        />
                    )} */}
          </div>

          <div className={`mt-4 react-table`}>
            <ReactTable data={product} columns={columns} defaultPageSize={10} />
          </div>
        </section>
      </main>
    </div>
  );
};

export default ProductMaster;
