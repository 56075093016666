import React, { useState, useEffect, Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from "lodash";
import ImageUploader from "react-images-upload";
import CloseModal from "../../../Components/CloseModal";
import { FileUrl, FrontendURL } from "../../../Utils/config";
import JSZip from "jszip";
import {
  formatIndianCurrency,
  replaceComma,
  checkIfNotDecimal,
} from "../../../Utils/helperFunctions";
import {
  uploadInventoryDoc,
  saveImplInventoryMaster,
  getAllFranchiseLocation,
  getVillageListByFranchiseeCode,
  updateImplInventoryMaster,
  uploadInventoryVideo,
  inactiveImplement,
  getImplInventoryMasterBySerialNumber,
  getImplInventoryMasterBySerialNumberNew,
  deleteinventoryImage,
  deleteinventoryVideo,
  getAllinventoryList,
  deletecancellationfeesbyid,
  findallproductdropdown,
} from "../../../Utils/Services/InventoryMaster";

toast.configure();

const initForm = {
  serialNumber: "",
  service: "",
  implement: "",
  variableCost: "",
  frenchiseeShearPersentage: "",
  shortDescription: "",
  longDescription: "",
  branch: "",
  franchisee: "",
  village: "",
  status: "",
  unit: "",
  rate: "",
  brand: "",
  villageNameId: "",
  franchiseeCode: "",
  inUse: "",

  errors: {
    serialNumber: null,
    frenchiseeShearPersentage: null,
    service: null,
    implement: null,
    variableCost: null,
    branch: null,
    franchisee: null,
    village: null,
    unit: null,
    rate: null,
    brand: null,
    franchiseeCode: null,
  },
};

//unit field values
const unitValues = [
  {
    unitLabel: "Hour",
    unitValue: "Hour",
  },
  {
    unitLabel: "Acre",
    unitValue: "Acre",
  },
  {
    unitLabel: "Quintal",
    unitValue: "Quintal",
  },
  {
    unitLabel: "Trolley",
    unitValue: "Trolley",
  },
  {
    unitLabel: "Bag",
    unitValue: "Bag",
  },
  {
    unitLabel: "Bales",
    unitValue: "Bales",
  },
];

export class AddEditService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keys: Math.random(),
      selectedFile: null,
      showModal: false,
      isLoading: false,
      showInner: false,
      // inuse: "",

      villageArray: [],
      villageNameIdOne: "",
      // imageNumber:"",
      imageId: null,
      inuse: "",
      flagInuse: "",
      //  imageDropOne:false,

      form: cloneDeep(initForm),
      productDropdownOptions: [],
      franchiseArray: [],
      imageOne: [],
      imageTwo: [],
      imageThree: [],
      imageFour: [],
      imageFive: [],
      videoOne: [],
      DOCPath: "",
      DOCPathOne: "",
      DOCPathTwo: "",
      DOCPathThree: "",
      DOCPathFour: "",
      DOCPathVideo: "",
      videoLoading: false,
      imageLoading1: false,
      imageLoading2: false,
      imageLoading3: false,
      imageLoading4: false,
      imageLoading5: false,
      showInactive: false,
      uploadCount: 0,
      responseCount: 0,
      DisableFields: false,
      errors: {
        imageOne: null,
        imageTwo: null,
        imageThree: null,
        imageFour: null,
        imageFive: null,
        videoOne: null,
        // inuse: null,
      },
    };
  }

  componentDidMount = async () => {
    this.setState({
      showModal: true,
      serialNumber: this.props.serialNumberimp,
      keys: Math.random(),
    });
    this.fetchBySerialNumber(this.props.serialNumberimp);
    this.getVillageByfranchisee();
    // this.getFranchiseCodeByVillage();

    let obj = {
      serialNumber: this.props.serialNumberimp,
      franchisee: this.props.franchiseName,
      service: this.props.service,
    };

    //api call to check if implement is inuse or not
    inactiveImplement(obj).then((res) => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 2000,
        // });

        return;
      } else {
        this.setState({ flagInuse: res.data.data });
        // console.log("rr", res.data.data.inuse);
        // this.setState({ showModal: false });
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 2000,
        // });
      }
    });
  };

  //all media doc path
  handleDocumentPath = () => {
    let { form } = this.state;
    let docPath =
      form &&
      form.mediaDetail &&
      form.mediaDetail &&
      form.mediaDetail[0] &&
      form.mediaDetail[0].mediaPath;

    let docPathOne =
      form &&
      form.mediaDetail &&
      form.mediaDetail &&
      form.mediaDetail[1] &&
      form.mediaDetail[1].mediaPath;

    let docPathTwo =
      form &&
      form.mediaDetail &&
      form.mediaDetail &&
      form.mediaDetail[2] &&
      form.mediaDetail[2].mediaPath;

    let docPathThree =
      form &&
      form.mediaDetail &&
      form.mediaDetail &&
      form.mediaDetail[3] &&
      form.mediaDetail[3].mediaPath;

    let docPathFour =
      form &&
      form.mediaDetail &&
      form.mediaDetail &&
      form.mediaDetail[4] &&
      form.mediaDetail[4].mediaPath;

    let videoPath =
      form &&
      form.mediaDetail &&
      form.mediaDetail &&
      form.mediaDetail[5] &&
      form.mediaDetail[5].mediaPath;

    // let obj = { ...form, ...this.props.serialNumberimp };
    let obj = { ...form };
    obj.errors = {
      serialNumber: null,
      frenchiseeShearPersentage: null,
      service: null,
      implement: null,
      variableCost: null,
      branch: null,
      franchisee: null,
      village: null,
      unit: null,
      rate: null,
      brand: null,
      franchiseeCode: null,
    };
    this.setState(
      {
        form: obj,
        DOCPath: docPath,
        DOCPathOne: docPathOne,
        DOCPathTwo: docPathTwo,
        DOCPathThree: docPathThree,
        DOCPathFour: docPathFour,
        DOCPathVideo: videoPath,
      },
      () => {
        this.getVillageByfranchisee();
      }
    );
  };

  handleModalClose = () => {
    this.setState({ showInner: !this.state.showInner });
    // this.this.props.addRole();
  };

  innerModalClose = (modclose) => {
    if (!modclose) {
      this.handleModalClose();
    }
    this.setState({ showModal: false });
    if (this.props.action == "edit") {
      this.props.handleEditInventoryMaster();
    } else {
      this.props.handleAddCancel();
    }
  };

  //getall franchise-location

  getAllFranchiseList = () => {
    let { form, franchiseArray } = this.state;
    let { branch } = form;
    getAllFranchiseLocation(branch).then((res) => {
      if (res.error) {
        return;
      } else if (res.data && res.data.error) {
        toast.error(res && res.data && res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        if (res && res.data && res.data.data && res.data.data.length == 0) {
          toast.error("Franchisee does not exist in selected branch", {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        }
        this.setState({
          franchiseArray: res && res.data && res.data.data,
          form,
        });
      }
    });
  };

  //getVillageby franchise

  getVillageByfranchisee = () => {
    // let { villageNameId } = this.props;
    let { form } = this.state;

    form &&
      form.villageNameId &&
      getVillageListByFranchiseeCode(form.villageNameId).then((res) => {
        if (res.error) {
          return;
        }
        if (res.data.error) {
        } else {
          this.setState({
            villageArray: res && res.data && res.data.data,
          });
        }
      });
  };

  //save api
  handleSave = () => {
    let {
      form,
      imageOne,
      imageTwo,
      imageThree,
      imageFour,
      imageFive,
      videoOne,
      showModal,
    } = this.state;
    let obj = cloneDeep(form);
    obj.id = "";
    obj.inUse = "N";
    this.setState({ isLoading: true });
    saveImplInventoryMaster(obj).then((response) => {
      this.setState({ isLoading: false });
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        this.setState({ isLoading: false, showModal: false });
        return;
      } else {
        this.setState({ isLoading: false, showModal: false });
        this.props.getImplementInventory();
        /* this.props.getImplementInventory();
        if (this.props.serialNumberimp && this.props.action == "edit") {
          this.props.handleEditInventoryMaster();
        } else {
          this.props.handleAddCancel();
        }
        this.setState({ showModal: false });
        toast.success(response.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        }); */

        if (imageOne && imageOne[0] && imageOne[0].name !== "") {
          this.setState({ imageLoading1: true });
          this.handleUploadOne();
        }
        if (imageTwo && imageTwo[0] && imageTwo[0].name !== "") {
          this.setState({ imageLoading2: true });
          this.handleUploadTwo();
        }
        if (imageThree && imageThree[0] && imageThree[0].name !== "") {
          this.setState({ imageLoading3: true });
          this.handleUploadThree();
        }
        if (imageFour && imageFour[0] && imageFour[0].name !== "") {
          this.setState({ imageLoading4: true });
          this.handleUploadFour();
        }
        if (imageFive && imageFive[0] && imageFive[0].name !== "") {
          this.setState({ imageLoading5: true });
          this.handleUploadFive();
        }
        if (videoOne && videoOne[0] && videoOne[0].name !== "") {
          this.setState({
            videoLoading: true,
            showModal: true,
            isLoading: true,
          });
          this.handleUploadVideo();
        }
        toast.success(response.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        window.location.reload(true);
      }
    });
  };

  //update api
  handleUpdate = () => {
    let {
      form,
      imageOne,
      imageTwo,
      imageThree,
      imageFour,
      imageFive,
      videoOne,
      showModal,
    } = this.state;
    let obj = form;
    this.setState({ isLoading: true });
    updateImplInventoryMaster(obj).then((response) => {
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        this.setState({ isLoading: false, showModal: false });
        return;
      } else {
        this.setState({ isLoading: false, showModal: false });
        if (imageOne && imageOne[0] && imageOne[0].name !== "") {
          this.setState({ imageLoading1: true });
          this.handleUploadOne();
        }
        if (imageTwo && imageTwo[0] && imageTwo[0].name !== "") {
          this.setState({ imageLoading2: true });
          this.handleUploadTwo();
        }
        if (imageThree && imageThree[0] && imageThree[0].name !== "") {
          this.setState({ imageLoading3: true });
          this.handleUploadThree();
        }
        if (imageFour && imageFour[0] && imageFour[0].name !== "") {
          this.setState({ imageLoading4: true });
          this.handleUploadFour();
        }
        if (imageFive && imageFive[0] && imageFive[0].name !== "") {
          this.setState({ imageLoading5: true });
          this.handleUploadFive();
        }

        if (videoOne && videoOne[0] && videoOne[0].name !== "") {
          this.setState({
            videoLoading: true,
            showModal: true,
            isLoading: true,
          });
          this.handleUploadVideo();
        }
        toast.success(response.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        this.props.getImplementInventory();
        /* this.props.getImplementInventory();
        if (this.props.serialNumberimp && this.props.action == "edit") {
          this.props.handleEditInventoryMaster();
        } else {
          this.props.handleAddCancel();
        }
        toast.success(response.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        }); */
        window.location.reload(true);
      }
    });
  };

  handleInputChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("handleInputChange", e, name, e.target.value);
    form[name] = e.target.value;
    this.setState({ form });
  };

  //serial number
  handleSerialChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("handleSerialChange", e, name, e.target.value);
    form[name] = e.target.value;

    if (name == "serialNumber") {
      form[name] = e.target.value.toUpperCase();
    } else {
      form[name] = e.target.value;
    }
    this.setState({ form });
  };

  handlebranchSelectChanges = (e, name) => {
    let { branchDropdownValue } = this.props;
    console.log("handlebranchSelectChanges", e, name);
    let { form, franchiseArray, branch, franchisee } = this.state;
    let { branchName } = e;
    let { locationComName } = e;
    form[name] = branchName;
    this.setState({ form });
    this.getAllFranchiseList();
    this.clearAll();
  };

  //reset selected values
  clearAll = () => {
    let { form, villageArray, franchiseArray } = this.state;
    form.franchisee = null;
    franchiseArray = [];
    villageArray = [];
    this.setState({ form, villageArray, franchiseArray, keys: Math.random() });
  };

  //franchise dropdown
  handlefranchiseChanges = (e, name) => {
    console.log("handlefranchiseChanges", e, name);
    let { form, villageNameIdOne } = this.state;
    let { locationComName, villageNameId, id, franchiseeCode } = e;
    form[name] = locationComName;
    // form["villageNameId"] = villageNameId;
    form["villageNameId"] = id;
    form["franchiseeCode"] = franchiseeCode;
    console.log("cheking the code", franchiseeCode);
    villageNameIdOne = id;
    this.setState({ form, villageNameIdOne });
    this.getVillageByfranchisee();

    console.log("vill", villageNameId);
    console.log("ii", id);
  };

  handleUnitSelectChanges = (e, name) => {
    console.log("handleUnitSelectChanges", e, name);
    let { form } = this.state;
    let { unitValue } = e;
    form[name] = unitValue;
    this.setState({ form });
  };

  //error check if any
  onValidate = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    if (
      form &&
      (form[name] === "" || form[name] === null || form[name] === undefined)
    ) {
      if (form && form.errors) {
        form.errors[name] = true;
        this.setState({ form });
      }
    } else {
      if (form && form.errors) {
        form.errors[name] = null;
        this.setState({ form });
      }
    }
  };

  onValidateForSerialNumber = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    if (
      form &&
      (form[name] === "" || form[name] === null || form[name] === undefined)
    ) {
      if (form && form.errors) {
        form.errors[name] = true;
        this.setState({ form });
      }
    } else {
      if (form && form.errors) {
        form.errors[name] = null;
        this.setState({ form });
      }
    }
    this.fetchBySerialNumberOnClickTab();
  };

  //ondrop image upload

  onDrop = async (file, picture, name) => {
    let { errors, uploadCount } = this.state;
    let count = uploadCount;

    console.log("file", file);
    console.log("picture", picture.length);
    console.log("name", file, file && file.length > 0);

    if (picture.length == 1) {
      errors = { ...errors, [name]: false };
    } else {
      errors = { ...errors, [name]: true };
    }
    if (file && file.length > 0) {
      count = uploadCount + 1;
      console.log("name", count);

      this.setState({ uploadCount: count, [name]: file, errors: errors });
    } else {
      count = uploadCount - 1;
      this.setState({ uploadCount: count, [name]: file, errors: errors });
    }
  };

  //inactive api
  handleInactive = () => {
    let { form, inuse } = this.state;
    let { serialNumberimp } = this.props;
    var status = form && form.status;
    if (status === "Active") {
      status = "Inactive";
    } else {
      status = "Active";
    }
    let obj = {
      serialNumber: this.props.serialNumberimp,
      franchisee: this.props.franchiseName,
      service: this.props.service,
      status: status,
    };

    this.handleInactiveModalClose();
    inactiveImplement(obj).then((res) => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res && res.data && res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });

        // return serialNumberimp && serialNumberimp.inuse;
        return;
      } else {
        this.setState({ flagInuse: res.data.data.inuse });
        console.log("rr", res.data.data.inuse);
        this.setState({ showModal: false });
        toast.success(res && res.data && res.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        window.location.reload(true);
      }
    });
  };

  //getImplmasterBySerialNumber api

  fetchBySerialNumber = () => {
    // let { serialNumber } = this.props.serialNumberimp;
    let { form } = this.state;
    console.log("abcd", this.props.service);
    let obj = {
      serialNumber: this.props.serialNumberimp,
      franchisee: this.props.franchiseName,
      service: this.props.service,
    };
    getImplInventoryMasterBySerialNumber(obj).then((res) => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res && res.data && res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });

        return;
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 2000,
        // });
        this.setState({ form: res.data.data, keys: Math.random() }, () => {
          this.handleDocumentPath();
          this.getAllFranchiseList();
        });
      }
    });
  };

  // fetchBySerialNumberOnClickTab function is used for get the all data by serial number

  fetchBySerialNumberOnClickTab = () => {
    // let { serialNumber } = this.props.serialNumberimp;
    let {
      form,
      villageArray,
      franchiseArray,
      branchDropdownValue,
      DisableFields,
    } = this.state;
    let obj = {
      serialNumber: form.serialNumber,
    };
    getImplInventoryMasterBySerialNumberNew(obj).then((res) => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res && res.data && res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 2000,
        // });

        form.service = "";
        form.implement = "";
        form.branch = "";
        form.franchisee = "";
        franchiseArray = [];
        branchDropdownValue = [];
        villageArray = [];
        form.franchiseeCode = "";
        form.village = "";
        form.unit = "";
        form.variableCost = "";
        form.rate = "";
        form.brand = "";
        form.frenchiseeShearPersentage = "";
        form.shortDescription = "";
        form.longDescription = "";
        form.villageNameId = "";
        form.mediaDetail = [];
        DisableFields = "false";
        this.setState(
          {
            form,
            villageArray,
            franchiseArray,
            branchDropdownValue,
            keys: Math.random(),
            DisableFields,
          },
          () => {
            this.handleDocumentPath();
            //this.getAllFranchiseList();
          }
        );
        return;
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 2000,
        // });
        this.setState(
          {
            form: res.data.data,
            keys: Math.random(),
            DisableFields: res && res.data && res.data.status,
          },
          () => {
            this.handleDocumentPath();
            this.getAllFranchiseList();
          }
        );
      }
    });
  };

  //video api

  handleUploadVideo = () => {
    let { videoOne, form } = this.state;
    console.log("a", videoOne);
    this.setState({ loading: true });
    let Margin = videoOne && videoOne[0] && videoOne[0].name;
    let content = videoOne[0];
    console.log("c", content);
    // console.log(Margin);

    let _this = this;
    let blob = new Blob([content], { type: "video" });
    const formData = new FormData();
    formData.append("video", blob, Margin);
    //  formData.append("docs", JSON.stringify([{}]));
    this.setState({ isLoading: true, videoLoading: true });
    uploadInventoryVideo(
      form.serialNumber,
      form.franchisee,
      form.service,
      formData
    ).then((res) => {
      console.log("resssssss", res);

      if (res && res.error) {
        this.setState({
          isLoading: false,
          showModal: false,
          videoLoading: false,
        });
        return;
      }
      if (res.data.error) {
        // te(res.data.message)
        toast.error(res && res.data && res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        this.setState({
          videoOne: "",
          isLoading: false,
          showModal: false,
          videoLoading: false,
        });
        return;
      } else {
        this.setState({ videoLoading: false, isLoading: false }, () => {
          if (
            this.state.imageLoading1 == false &&
            this.state.imageLoading2 == false &&
            this.state.imageLoading3 == false &&
            this.state.imageLoading4 == false &&
            this.state.imageLoading5 == false &&
            this.state.videoLoading == false
          ) {
            this.setState({ showModal: false });
            this.props.getImplementInventory();
            if (this.props.serialNumberimp && this.props.action == "edit") {
              this.props.handleEditInventoryMaster();
            } else {
              this.props.handleAddCancel();
            }
            toast.success(res && res.data && res.data.message, {
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }
        });
      }
    });
  };

  handleInactiveModalOpen = () => {
    this.setState({ showInactive: true });
  };

  handleInactiveModalClose = () => {
    this.setState({ showInactive: false });
  };

  // delete img api

  handleDeleteModalclose = () => {
    this.setState({ showDeletemodal: false, showDeletemodal1: false });
  };

  handleDelete = () => {
    let { imageNumber, form } = this.state;
    let obj = [
      {
        serialNumber: form.serialNumber,
        imageNumber: imageNumber,
        franchisee: form.franchisee,
        serviceName: this.props.service,
      },
    ];
    deleteinventoryImage([
      {
        serialNumber: form.serialNumber,
        imageNumber: imageNumber,
        franchisee: form.franchisee,
        serviceName: this.props.service,
      },
    ]).then((response) => {
      setTimeout(() => {
        this.setState({ showDeletemodal1: false });
      }, 1000);
      if (response.error) {
        return;
      }
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        toast.success(response.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        this.fetchBySerialNumber(form.serialNumber);
      }
    });
  };

  //delete video api

  handleDeleteVideo = () => {
    let { form } = this.state;
    let obj = [
      {
        serialNumber: form.serialNumber,
        franchisee: form.franchisee,
        serviceName: this.props.service,
      },
    ];
    this.setState({ laoding: true });
    deleteinventoryVideo(obj).then((response) => {
      this.setState({ laoding: false });
      if (response.error) {
        return;
      }
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        this.setState({ [form.serialNumber]: "" });
      } else {
        toast.success(response.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        // this.getfranchiseAppraiserByAppicationId();
        this.fetchBySerialNumber(form.serialNumber);
        this.setState({ showDeletemodal: false });
      }
    });
  };

  handleDocDelete = (imageNumber) => {
    let { showDeletemodal1 } = this.state;
    this.setState({
      imageNumber: imageNumber,
      showDeletemodal1: !showDeletemodal1,
    });
  };

  handleDocDeleteVideo = (serialNumber) => {
    let { showDeletemodal } = this.state;
    this.setState({
      serialNumber: serialNumber,
      showDeletemodal: !showDeletemodal,
    });
  };

  //image api

  uploadDocuments = (formData, imageNumber) => {
    let {
      responseCount,
      imageLoading1,
      imageLoading2,
      imageLoading3,
      imageLoading4,
      imageLoading5,
      form,
    } = this.state;
    let count = responseCount;
    uploadInventoryDoc(formData).then((res) => {
      if (imageNumber == "imageOne") {
        this.setState({ imageLoading1: false }, () => {
          if (this.state.imageLoading1 == false) {
            this.fetchBySerialNumber(form.serialNumber);
            this.setState({ [imageNumber]: [] });
          }
          console.log(
            "herre...",
            "1",
            this.state.imageLoading1 == false &&
              this.state.imageLoading2 == false &&
              this.state.imageLoading3 == false &&
              this.state.imageLoading4 == false &&
              this.state.imageLoading5 == false &&
              this.state.videoLoading == false
          );
          if (
            this.state.imageLoading1 == false &&
            this.state.imageLoading2 == false &&
            this.state.imageLoading3 == false &&
            this.state.imageLoading4 == false &&
            this.state.imageLoading5 == false &&
            this.state.videoLoading == false
          ) {
            this.setState({ showModal: false });
            this.props.getImplementInventory();
            if (this.props.serialNumberimp && this.props.action == "edit") {
              this.props.handleEditInventoryMaster();
            } else {
              this.props.handleAddCancel();
            }
            toast.success(res && res.data && res.data.message, {
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }
        });
      } else if (imageNumber == "imageTwo") {
        this.setState({ imageLoading2: false }, () => {
          if (this.state.imageLoading2 == false) {
            this.fetchBySerialNumber(form.serialNumber);
            this.setState({ [imageNumber]: [] });
          }
          console.log(
            "herre...",
            "2",
            this.state.imageLoading1 == false &&
              this.state.imageLoading2 == false &&
              this.state.imageLoading3 == false &&
              this.state.imageLoading4 == false &&
              this.state.imageLoading5 == false &&
              this.state.videoLoading == false
          );
          if (
            this.state.imageLoading1 == false &&
            this.state.imageLoading2 == false &&
            this.state.imageLoading3 == false &&
            this.state.imageLoading4 == false &&
            this.state.imageLoading5 == false &&
            this.state.videoLoading == false
          ) {
            this.setState({ showModal: false });
            this.props.getImplementInventory();
            if (this.props.serialNumberimp && this.props.action == "edit") {
              this.props.handleEditInventoryMaster();
            } else {
              this.props.handleAddCancel();
            }
            toast.success(res && res.data && res.data.message, {
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }
        });
      } else if (imageNumber == "imageThree") {
        this.setState({ imageLoading3: false }, () => {
          if (this.state.imageLoading3 == false) {
            this.fetchBySerialNumber(form.serialNumber);
            this.setState({ [imageNumber]: [] });
          }
          console.log(
            "herre...",
            "3",
            this.state.imageLoading1 == false &&
              this.state.imageLoading2 == false &&
              this.state.imageLoading3 == false &&
              this.state.imageLoading4 == false &&
              this.state.imageLoading5 == false &&
              this.state.videoLoading == false
          );
          if (
            this.state.imageLoading1 == false &&
            this.state.imageLoading2 == false &&
            this.state.imageLoading3 == false &&
            this.state.imageLoading4 == false &&
            this.state.imageLoading5 == false &&
            this.state.videoLoading == false
          ) {
            this.setState({ showModal: false });
            this.props.getImplementInventory();
            if (this.props.serialNumberimp && this.props.action == "edit") {
              this.props.handleEditInventoryMaster();
            } else {
              this.props.handleAddCancel();
            }
            toast.success(res && res.data && res.data.message, {
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }
        });
      } else if (imageNumber == "imageFour") {
        this.setState({ imageLoading4: false }, () => {
          if (this.state.imageLoading4 == false) {
            this.fetchBySerialNumber(form.serialNumber);
            this.setState({ [imageNumber]: [] });
          }
          console.log(
            "herre...",
            "4",
            this.state.imageLoading1 == false &&
              this.state.imageLoading2 == false &&
              this.state.imageLoading3 == false &&
              this.state.imageLoading4 == false &&
              this.state.imageLoading5 == false &&
              this.state.videoLoading == false
          );
          if (
            this.state.imageLoading1 == false &&
            this.state.imageLoading2 == false &&
            this.state.imageLoading3 == false &&
            this.state.imageLoading4 == false &&
            this.state.imageLoading5 == false &&
            this.state.videoLoading == false
          ) {
            this.setState({ showModal: false });
            this.props.getImplementInventory();
            if (this.props.serialNumberimp && this.props.action == "edit") {
              this.props.handleEditInventoryMaster();
            } else {
              this.props.handleAddCancel();
            }
            toast.success(res && res.data && res.data.message, {
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }
        });
      } else if (imageNumber == "imageFive") {
        this.setState({ imageLoading5: false }, () => {
          if (this.state.imageLoading5 == false) {
            this.fetchBySerialNumber(form.serialNumber);
            this.setState({ [imageNumber]: [] });
          }
          console.log(
            "herre...",
            "5",
            this.state.imageLoading1 == false &&
              this.state.imageLoading2 == false &&
              this.state.imageLoading3 == false &&
              this.state.imageLoading4 == false &&
              this.state.imageLoading5 == false &&
              this.state.videoLoading == false
          );
          if (
            this.state.imageLoading1 == false &&
            this.state.imageLoading2 == false &&
            this.state.imageLoading3 == false &&
            this.state.imageLoading4 == false &&
            this.state.imageLoading5 == false &&
            this.state.videoLoading == false
          ) {
            this.setState({ showModal: false });
            this.props.getImplementInventory();
            if (this.props.serialNumberimp && this.props.action == "edit") {
              this.props.handleEditInventoryMaster();
            } else {
              this.props.handleAddCancel();
            }
            toast.success(res && res.data && res.data.message, {
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }
        });
      }
      if (res && res.error) {
        return;
      }
      if (res.data.error) {
        // te(res.data.message)
        toast.error(res && res.data && res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        this.setState({ [imageNumber]: "" });
        return;
      } else {
        /* toast.success(res.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        }); */
      }
    });
  };

  //upload first image
  handleUploadOne = () => {
    let { imageOne, form } = this.state;
    let Margin = imageOne && imageOne[0] && imageOne[0].name;
    let content = imageOne[0];

    let _this = this;
    let blob = new Blob([content], { type: "image" });
    const formData = new FormData();
    formData.append("image", blob, Margin);
    formData.append(
      "imageDtls",
      JSON.stringify([
        {
          serialNumber: form.serialNumber,
          imageNumber: 1,
          franchisee: form.franchisee,
          serviceName: form.service,
        },
      ])
    );
    this.uploadDocuments(formData, "imageOne");
  };

  //upload second image
  handleUploadTwo = () => {
    let { imageTwo, form } = this.state;
    let Margin = imageTwo && imageTwo[0] && imageTwo[0].name;
    let content = imageTwo[0];

    let _this = this;
    let blob = new Blob([content], { type: "image" });
    const formData = new FormData();
    formData.append("image", blob, Margin);
    formData.append(
      "imageDtls",
      JSON.stringify([
        {
          serialNumber: form.serialNumber,
          imageNumber: 2,
          franchisee: form.franchisee,
          serviceName: form.service,
        },
      ])
    );
    this.uploadDocuments(formData, "imageTwo");
  };

  //upload third image
  handleUploadThree = () => {
    let { imageThree, form } = this.state;
    let Margin = imageThree && imageThree[0] && imageThree[0].name;
    let content = imageThree[0];
    let _this = this;
    let blob = new Blob([content], { type: "image" });
    const formData = new FormData();
    formData.append("image", blob, Margin);
    formData.append(
      "imageDtls",
      JSON.stringify([
        {
          serialNumber: form.serialNumber,
          imageNumber: 3,
          franchisee: form.franchisee,
          serviceName: form.service,
        },
      ])
    );
    this.uploadDocuments(formData, "imageThree");
  };

  //upload fourth image
  handleUploadFour = () => {
    let { imageFour, form } = this.state;
    let Margin = imageFour && imageFour[0] && imageFour[0].name;
    let content = imageFour[0];
    let _this = this;
    let blob = new Blob([content], { type: "image" });
    const formData = new FormData();
    formData.append("image", blob, Margin);
    formData.append(
      "imageDtls",
      JSON.stringify([
        {
          serialNumber: form.serialNumber,
          imageNumber: 4,
          franchisee: form.franchisee,
          serviceName: form.service,
        },
      ])
    );
    this.uploadDocuments(formData, "imageFour");
  };

  //upload fifth image
  handleUploadFive = () => {
    let { imageFive, form } = this.state;
    let Margin = imageFive && imageFive[0] && imageFive[0].name;
    let content = imageFive[0];
    let _this = this;
    let blob = new Blob([content], { type: "image" });
    const formData = new FormData();
    formData.append("image", blob, Margin);
    formData.append(
      "imageDtls",
      JSON.stringify([
        {
          serialNumber: form.serialNumber,
          imageNumber: 5,
          franchisee: form.franchisee,
          serviceName: form.service,
        },
      ])
    );
    this.uploadDocuments(formData, "imageFive");
  };
  //image api end

  getDocName = (DocPath) => {
    if (DocPath != undefined && DocPath != "") {
      let str = DocPath;
      var res = str.split("/");
      return res[res.length - 1];
    } else {
      return "";
    }
  };

  // onKeyDownHandler function is used to fetch all the data by serail number on click of TAB key

  onKeyDownHandler = (e) => {
    if (e.keyCode === 9) {
      this.fetchBySerialNumberOnClickTab();
    }
  };
  render() {
    let {
      form,
      showModal,
      showInner,
      isLoading,
      franchisee,
      franchiseArray,

      villageArray,
      DOCPath,
      DOCPathOne,
      DOCPathTwo,
      DOCPathThree,
      DOCPathFour,
      imageOne,
      imageTwo,
      imageThree,
      imageFour,
      imageFive,
      DOCPathVideo,
      showDeletemodal,
      showDeletemodal1,
      showInactive,
      loading,
      imageNumber,
      serialNumber,
      keys,
      imageLoading1,
      imageLoading2,
      imageLoading3,
      imageLoading4,
      imageLoading5,
      videoLoading,
      DisableFields,
    } = this.state;
    let { productDropdownOptions, branchDropdownValue } = this.props;
    let { errors, branch } = form;
    let { serialNumberimp } = this.props;
    form.village = villageArray;

    let { villageNameId } = form;

    let diableSave = false;
    let isRequiredFeildFilled = false;

    //disable save & isreq
    if (
      form.serialNumber == "" ||
      form.service == "" ||
      form.implement == "" ||
      form.branch == "" ||
      form.franchisee == "" ||
      form.franchiseeCode == "" ||
      form.village == "" ||
      form.unit == "" ||
      form.rate == "" ||
      form.variableCost == "" ||
      form.frenchiseeShearPersentage == "" ||
      form.brand == "" ||
      form.serialNumber == null ||
      form.service == null ||
      form.implement == null ||
      form.branch == null ||
      form.franchisee == null ||
      form.village == null ||
      form.unit == null ||
      form.rate == null ||
      form.variableCost == null ||
      form.frenchiseeShearPersentage == null ||
      form.brand == null
    ) {
      isRequiredFeildFilled = true;
    }

    // Object.keys(form).map((name) => {
    //   if (
    //     form[name] === "" ||
    //     form[name] === null ||
    //     form[name] === undefined
    //   ) {
    //     isRequiredFeildFilled = true;
    //     return true;
    //   }
    // });

    errors &&
      Object.keys(errors).map((error) => {
        console.log("error", errors[error]);
        if (errors[error] === true || errors[error] != null) {
          diableSave = true;
          return true;
        }
      });

    console.log("dis", diableSave, DisableFields);

    console.log("isr", isRequiredFeildFilled);

    let image1 = false;
    let image2 = false;
    let image3 = false;
    let image4 = false;
    let image5 = false;
    let video = false;

    form &&
      form.mediaDetail &&
      form.mediaDetail.length > 0 &&
      form.mediaDetail.map((value, key) => {
        if (value.imageNumber == 1) {
          image1 = true;
        }
        if (value.imageNumber == 0) {
          video = true;
        }
        if (value.imageNumber == 2) {
          image2 = true;
        }
        if (value.imageNumber == 3) {
          image3 = true;
        }
        if (value.imageNumber == 4) {
          image4 = true;
        }
        if (value.imageNumber == 5) {
          image5 = true;
        }
      });

    console.log("franch", isLoading, videoLoading);
    return (
      <React.Fragment>
        <Modal
          className="product-norm-container"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showModal}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>
              {this.props.action == "edit"
                ? "Edit service & rate"
                : "Add service & rate"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            {isLoading ? (
              <>
                {videoLoading === true ? (
                  <h4 className="text-center">Uploading Video...</h4>
                ) : (
                  <h4 className="text-center">Loading...</h4>
                )}
              </>
            ) : (
              <div className="row ">
                {/* serial number */}
                <div className="mb-4 col-md-3">
                  <label>
                    <i className="text-danger">*</i> Serial Number
                  </label>
                  <input
                    type="text"
                    pattern="\d*"
                    maxLength="100"
                    className="form-input"
                    name="serialNumber"
                    value={form.serialNumber}
                    onKeyDown={this.onKeyDownHandler}
                    onChange={(e) => {
                      let regex =
                        /[\/\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                      if (!regex.test(e.target.value))
                        this.handleSerialChange(e, "serialNumber");
                    }}
                    // onChange={(e) => {
                    //   this.handleInputChange(e, "serialNumber");
                    // }}
                    onBlur={(e) =>
                      this.onValidateForSerialNumber(e, "serialNumber")
                    }
                    placeholder="Enter serial number"
                    // disabled={verifyingBank}
                    className={`form-input ${
                      errors && errors.serialNumber ? "error-input-border" : ""
                    } `}
                    disabled={this.props.action == "edit" ? true : false}
                  />
                  {errors && errors.serialNumber && (
                    <span className="errorMsg">
                      {errors && errors.serialNumber == true
                        ? "Enter serial Number"
                        : errors && errors.serialNumber}
                    </span>
                  )}
                </div>

                <div className="mb-4 col-md-3">
                  <label>
                    <i className="text-danger">*</i> Implement Type
                  </label>
                  <input
                    type="text"
                    pattern="\d*"
                    maxLength="100"
                    className="form-input"
                    name="service"
                    value={form.service}
                    onChange={(e) => {
                      this.handleInputChange(e, "service");
                    }}
                    onBlur={(e) => this.onValidate(e, "service")}
                    placeholder="Enter implement type"
                    // disabled={verifyingBank}
                    className={`form-input ${
                      errors && errors.service ? "error-input-border" : ""
                    } `}
                  />
                  {errors && errors.service && (
                    <span className="errorMsg">
                      {errors && errors.service == true
                        ? "Enter service type"
                        : errors && errors.service}
                    </span>
                  )}
                </div>
                <div className="mb-4 col-md-3 ">
                  <label>
                    <i className="text-danger">*</i> Service Name
                  </label>

                  <input
                    type="text"
                    pattern="\d*"
                    maxLength="100"
                    className="form-input"
                    name="implement"
                    value={form.implement}
                    onChange={(e) => {
                      this.handleInputChange(e, "implement");
                    }}
                    onBlur={(e) => this.onValidate(e, "implement")}
                    placeholder="Enter service name"
                    // disabled={verifyingBank}
                    className={`form-input ${
                      errors && errors.implement ? "error-input-border" : ""
                    } `}
                  />
                  {errors && errors.implement && (
                    <span className="errorMsg">
                      {errors && errors.implement == true
                        ? "Enter implement type"
                        : errors && errors.implement}
                    </span>
                  )}
                </div>
                {branchDropdownValue && branchDropdownValue.length > 0 && (
                  <div className="mb-4 col-sm-3 ">
                    <label>
                      <i className="text-danger">*</i> Branch
                    </label>
                    <Select
                      defaultValue={
                        form &&
                        form.branch &&
                        branchDropdownValue &&
                        branchDropdownValue.length > 0 &&
                        branchDropdownValue.filter(
                          ({ branchName }) => branchName == form.branch
                        )[0]
                      }
                      onChange={(e) => {
                        this.handlebranchSelectChanges(e, "branch");
                      }}
                      onBlur={(e) => this.onValidate(e, "branch")}
                      name="branch"
                      options={branchDropdownValue}
                      placeholder={`Select Branch`}
                      valueKey="branchName"
                      labelKey="branchName"
                      getOptionLabel={(option) => option["branchName"]}
                      getOptionValue={(option) => option["branchName"]}
                      className={`${
                        errors && errors.branch ? "error-input-border" : ""
                      } `}
                      isDisabled={
                        DisableFields === "true" ||
                        (this.props.action == "edit" &&
                          form.status != "Inactive")
                          ? true
                          : false
                      }
                      key={keys}
                    />
                    {errors && errors.branch && (
                      <span className="errorMsg">Please select Branch</span>
                    )}
                  </div>
                )}

                {/* franchisee */}

                {this.props.action == "edit" ? (
                  franchiseArray &&
                  franchiseArray.length > 0 && (
                    <div className="mb-4 col-sm-3 ">
                      <label>
                        <i className="text-danger">*</i> Franchisee
                      </label>

                      <Select
                        defaultValue={
                          franchiseArray &&
                          franchiseArray.filter(
                            ({ locationComName }) =>
                              locationComName == form.franchisee
                          )[0]
                        }
                        onChange={(e) => {
                          this.handlefranchiseChanges(e, "franchisee");
                        }}
                        onBlur={(e) => this.onValidate(e, "franchisee")}
                        name="franchisee"
                        // value={form.franchisee}
                        options={franchiseArray}
                        placeholder="Select franchisee"
                        valueKey="locationComName"
                        labelKey="locationComName"
                        getOptionLabel={(option) => option["locationComName"]}
                        getOptionValue={(option) => option["locationComName"]}
                        className={`${
                          errors && errors.franchisee
                            ? "error-input-border"
                            : ""
                        } `}
                        isDisabled={form.status == "Inactive" ? false : true}
                        key={keys}
                      />

                      {errors && errors.franchisee && (
                        <span className="errorMsg">
                          Please select franchisee
                        </span>
                      )}
                    </div>
                  )
                ) : DisableFields === "true" ? (
                  franchiseArray &&
                  franchiseArray.length > 0 && (
                    <div className="mb-4 col-sm-3 ">
                      <label>
                        <i className="text-danger">*</i> Franchisee
                      </label>

                      <Select
                        defaultValue={
                          franchiseArray &&
                          franchiseArray.filter(
                            ({ locationComName }) =>
                              locationComName == form.franchisee
                          )[0]
                        }
                        onChange={(e) => {
                          this.handlefranchiseChanges(e, "franchisee");
                        }}
                        onBlur={(e) => this.onValidate(e, "franchisee")}
                        name="franchisee"
                        // value={form.franchisee}
                        options={franchiseArray}
                        placeholder="Select franchisee"
                        valueKey="locationComName"
                        labelKey="locationComName"
                        getOptionLabel={(option) => option["locationComName"]}
                        getOptionValue={(option) => option["locationComName"]}
                        className={`${
                          errors && errors.franchisee
                            ? "error-input-border"
                            : ""
                        } `}
                        isDisabled={
                          DisableFields === "true" ||
                          this.props.action == "edit"
                            ? true
                            : false
                        }
                        key={keys}
                      />

                      {errors && errors.franchisee && (
                        <span className="errorMsg">
                          Please select franchisee
                        </span>
                      )}
                    </div>
                  )
                ) : (
                  <div className="mb-4 col-sm-3 ">
                    <label>
                      <i className="text-danger">*</i> Franchisee
                    </label>

                    <Select
                      defaultValue={
                        franchiseArray &&
                        franchiseArray.filter(
                          ({ locationComName }) =>
                            locationComName == form.franchisee
                        )[0]
                      }
                      onChange={(e) => {
                        this.handlefranchiseChanges(e, "franchisee");
                      }}
                      onBlur={(e) => this.onValidate(e, "franchisee")}
                      name="franchisee"
                      // value={form.franchisee}
                      options={franchiseArray}
                      placeholder="Select franchisee"
                      valueKey="locationComName"
                      labelKey="locationComName"
                      getOptionLabel={(option) => option["locationComName"]}
                      getOptionValue={(option) => option["locationComName"]}
                      className={`${
                        errors && errors.franchisee ? "error-input-border" : ""
                      } `}
                      isDisabled={
                        DisableFields === "true" || this.props.action == "edit"
                          ? true
                          : false
                      }
                      key={keys}
                    />

                    {errors && errors.franchisee && (
                      <span className="errorMsg">Please select franchisee</span>
                    )}
                  </div>
                )}

                <div className="mb-4 col-sm-3 ">
                  <label>
                    <i className="text-danger">*</i> Franchise Code
                  </label>
                  <textarea
                    type="text"
                    // pattern="\d*"
                    // maxLength="6"
                    name="franchiseeCode"
                    // value={form.village}
                    value={form.franchiseeCode}
                    // placeholder="Enter Rate"
                    onChange={(e) => {
                      this.handleInputChange(e, "franchiseeCode");
                    }}
                    className={`form-input ${
                      errors && errors.franchiseeCode
                        ? "error-input-border"
                        : ""
                    } `}
                    disabled={true}
                  />
                </div>

                <div className="mb-4 col-sm-3 ">
                  <label>
                    <i className="text-danger">*</i> Village
                  </label>
                  <textarea
                    type="text"
                    // pattern="\d*"
                    maxLength="6"
                    name="village"
                    // value={form.village}
                    value={form.village}
                    // placeholder="Enter Rate"
                    onChange={(e) => {
                      this.handleInputChange(e, "village");
                    }}
                    className={`form-input ${
                      errors && errors.village ? "error-input-border" : ""
                    } `}
                    disabled={true}
                  />
                </div>
                <div className="mb-4 col-sm-3 ">
                  <label>
                    <i className="text-danger">*</i> Unit
                  </label>
                  <Select
                    defaultValue={
                      unitValues.filter(
                        ({ unitValue }) => unitValue == form.unit
                      )[0]
                    }
                    onChange={(e) => {
                      this.handleUnitSelectChanges(e, "unit");
                    }}
                    onBlur={(e) => this.onValidate(e, "unit")}
                    name="unit"
                    options={unitValues}
                    placeholder="Select unit"
                    valueKey="unitValue"
                    labelKey="unitLabel"
                    getOptionLabel={(option) => option.unitLabel}
                    getOptionValue={(option) => option.unitValue}
                    className={`${
                      errors && errors.unit ? "error-input-border" : ""
                    } `}
                    isDisabled={false}
                    key={keys}
                  />
                  {errors && errors.unit && (
                    <span className="errorMsg">Please Enter Unit</span>
                  )}
                </div>
                <div className="form-group col-md-3 ">
                  <label>
                    <i className="text-danger">*</i> Rate
                  </label>
                  <div class="inner-addon right-addon">
                    <input
                      type="text"
                      // pattern="\d*"
                      name="rate"
                      maxLength="5"
                      value={form.rate}
                      onChange={(e) => {
                        if (
                          e.target.value == "" ||
                          (Math.ceil(e.target.value) &&
                            parseInt(e.target.value) >= 0)
                        ) {
                          e.target.value = replaceComma(e.target.value);
                          if (checkIfNotDecimal(e.target.value)) {
                            this.handleInputChange(e, "rate");
                          }
                        }
                      }}
                      onBlur={(e) => this.onValidate(e, "rate")}
                      placeholder="Please Enter rate"
                      className={`form-input ${
                        errors && errors && errors.rate
                          ? "error-input-border"
                          : ""
                      } `}
                      disabled={false}
                    />
                    {errors && errors.rate && (
                      <span className="errorMsg">
                        {errors && errors.rate == true
                          ? "Enter rate"
                          : errors && errors.rate}
                      </span>
                    )}
                  </div>
                </div>

                <div className="form-group col-md-3 ">
                  <label>
                    <i className="text-danger">*</i> Variable Cost
                  </label>
                  <div class="inner-addon right-addon">
                    <input
                      type="text"
                      // pattern="\d*"
                      name="variableCost"
                      maxLength="5"
                      value={form.variableCost}
                      onChange={(e) => {
                        if (
                          e.target.value == "" ||
                          (Math.ceil(e.target.value) &&
                            parseInt(e.target.value) >= 0)
                        ) {
                          e.target.value = replaceComma(e.target.value);
                          if (checkIfNotDecimal(e.target.value)) {
                            this.handleInputChange(e, "variableCost");
                          }
                        }
                      }}
                      onBlur={(e) => this.onValidate(e, "variableCost")}
                      placeholder="Please Enter variable cost"
                      className={`form-input ${
                        errors && errors && errors.variableCost
                          ? "error-input-border"
                          : ""
                      } `}
                      disabled={false}
                    />
                    {errors && errors.variableCost && (
                      <span className="errorMsg">
                        {errors && errors.variableCost == true
                          ? "Enter cost"
                          : errors && errors.variableCost}
                      </span>
                    )}
                  </div>
                </div>

                {/* franchis per */}

                <div className="form-group col-md-3 ">
                  <label>
                    <i className="text-danger">*</i> % Franchisee Share
                  </label>
                  <div class="inner-addon right-addon">
                    <input
                      type="text"
                      // pattern="\d*"
                      name="frenchiseeShearPersentage"
                      maxLength="5"
                      value={form.frenchiseeShearPersentage}
                      onChange={(e) => {
                        if (
                          e.target.value == "" ||
                          (Math.ceil(e.target.value) &&
                            parseInt(e.target.value) >= 0)
                        ) {
                          e.target.value = replaceComma(e.target.value);
                          if (checkIfNotDecimal(e.target.value)) {
                            this.handleInputChange(
                              e,
                              "frenchiseeShearPersentage"
                            );
                          }
                        }
                      }}
                      onBlur={(e) =>
                        this.onValidate(e, "frenchiseeShearPersentage")
                      }
                      placeholder="Please Enter franchisee %"
                      className={`form-input ${
                        errors && errors && errors.frenchiseeShearPersentage
                          ? "error-input-border"
                          : ""
                      } `}
                      disabled={false}
                    />
                    {errors && errors.frenchiseeShearPersentage && (
                      <span className="errorMsg">
                        {errors && errors.frenchiseeShearPersentage == true
                          ? "Enter franchisee share %"
                          : errors && errors.frenchiseeShearPersentage}
                      </span>
                    )}
                  </div>
                </div>

                {/* brand */}

                <div className="mb-4 col-md-3 ">
                  <label>
                    <i className="text-danger">*</i> Brand
                  </label>

                  <input
                    type="text"
                    pattern="\d*"
                    maxLength="100"
                    className="form-input"
                    name="brand"
                    value={form.brand}
                    onChange={(e) => {
                      this.handleInputChange(e, "brand");
                    }}
                    onBlur={(e) => this.onValidate(e, "brand")}
                    placeholder="Enter brand type"
                    // disabled={verifyingBank}
                    className={`form-input ${
                      errors && errors.brand ? "error-input-border" : ""
                    } `}
                  />
                  {errors && errors.brand && (
                    <span className="errorMsg">
                      {errors && errors.brand == true
                        ? "Enter brand type"
                        : errors && errors.brand}
                    </span>
                  )}
                </div>

                {/* short description */}

                <div className="mb-4 col-md-7 ">
                  <label>
                    {/* <i className="text-danger"></i> */}
                    Short Description
                  </label>
                  <textarea
                    type="text"
                    pattern="\d*"
                    maxLength="100"
                    className="form-input"
                    name="address"
                    value={form.shortDescription}
                    onChange={(e) => {
                      this.handleInputChange(e, "shortDescription");
                    }}
                    disabled={false}
                    className={`form-input ${
                      errors &&
                      errors.shortDescription &&
                      errors &&
                      errors.shortDescription
                        ? "error-input-border"
                        : ""
                    } `}
                    autocomplete="false"
                  />
                </div>

                {/* long description */}

                <div className="mb-4 col-md-7 ">
                  <label>
                    {/* <i className="text-danger"></i> */}
                    Long Description
                  </label>
                  <textarea
                    type="text"
                    pattern="\d*"
                    maxLength="500"
                    className="form-input"
                    name="address"
                    value={form.longDescription}
                    onChange={(e) => {
                      this.handleInputChange(e, "longDescription");
                    }}
                    disabled={false}
                    className={`form-input ${
                      errors &&
                      errors.longDescription &&
                      errors &&
                      errors.longDescription
                        ? "error-input-border"
                        : ""
                    } `}
                    autocomplete="false"
                  />
                </div>

                {/* upload images */}
                <section className="tab-body mt-3 mb-3">
                  <div className="row">
                    <h5 className="col-md-12 text-center mb-4">
                      Image Uploads
                    </h5>
                    <div className="col-md-4">
                      <label>Image 1</label>
                      <ImageUploader
                        name="imageOne"
                        buttonText={
                          imageLoading1 ? "uploding image" : "upload image"
                        }
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="upload image"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "imageOne");
                        }}
                        imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                        maxFileSize={20971520}
                        singleImage={true}
                        className={image1 || imageLoading1 ? "disable-div" : ""}
                      />

                      <ul>
                        {imageLoading1 ? (
                          <span>Uploading Image...</span>
                        ) : (
                          form &&
                          form.mediaDetail &&
                          form.mediaDetail.length > 0 &&
                          form.mediaDetail.map((res, key) => {
                            if (res && res.imageNumber == 1) {
                              let pathArray = res && res.mediaPath.split("/");
                              let path = "";
                              let mediaPath = res.mediaPath;
                              pathArray.map((section, index) => {
                                if (index > 3) {
                                  path += section;
                                  if (index != pathArray.length - 1)
                                    path += "/";
                                }
                              });
                              return (
                                <li className="p-1">
                                  <a
                                    href={`${FileUrl}${mediaPath}`}
                                    download
                                    target="_blank"
                                  >
                                    {this.getDocName(path)}
                                  </a>
                                  <span
                                    className="text-danger ml-3"
                                    onClick={() =>
                                      this.handleDocDelete(
                                        res && res.imageNumber
                                      )
                                    }
                                  >
                                    {DisableFields === "true" ? (
                                      <i
                                        class="fa fa-trash"
                                        style={{ cursor: "pointer" }}
                                        aria-hidden="true"
                                      ></i>
                                    ) : this.state.flagInuse == "N" ? (
                                      <i
                                        class="fa fa-trash"
                                        style={{ cursor: "pointer" }}
                                        aria-hidden="true"
                                      ></i>
                                    ) : null}
                                  </span>
                                </li>
                              );
                            }
                          })
                        )}
                      </ul>
                    </div>

                    <div className="col-md-4">
                      <label>Image 2</label>
                      <ImageUploader
                        name="imageTwo"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="upload image"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "imageTwo");
                        }}
                        imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                        maxFileSize={20971520}
                        singleImage={true}
                        className={image2 || imageLoading2 ? "disable-div" : ""}
                      />

                      <ul>
                        {imageLoading2 ? (
                          <span>Uploading Image...</span>
                        ) : (
                          form &&
                          form.mediaDetail &&
                          form.mediaDetail.length > 0 &&
                          form.mediaDetail.map((res, key) => {
                            if (res && res.imageNumber == 2) {
                              let pathArray = res && res.mediaPath.split("/");
                              let path = "";
                              let mediaPath = res.mediaPath;
                              pathArray.map((section, index) => {
                                if (index > 3) {
                                  path += section;
                                  if (index != pathArray.length - 1)
                                    path += "/";
                                }
                              });
                              return (
                                <li className="p-1">
                                  <a
                                    href={`${FileUrl}${mediaPath}`}
                                    download
                                    target="_blank"
                                  >
                                    {this.getDocName(path)}
                                  </a>
                                  <span
                                    className="text-danger ml-3"
                                    onClick={() =>
                                      this.handleDocDelete(
                                        res && res.imageNumber
                                      )
                                    }
                                  >
                                    {DisableFields === "true" ? (
                                      <i
                                        class="fa fa-trash"
                                        style={{ cursor: "pointer" }}
                                        aria-hidden="true"
                                      ></i>
                                    ) : this.state.flagInuse == "N" ? (
                                      <i
                                        class="fa fa-trash"
                                        style={{ cursor: "pointer" }}
                                        aria-hidden="true"
                                      ></i>
                                    ) : null}
                                  </span>
                                </li>
                              );
                            }
                          })
                        )}
                      </ul>
                    </div>

                    <div className="col-md-4">
                      <label>Image 3</label>
                      <ImageUploader
                        name="imageThree"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="upload image"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "imageThree");
                        }}
                        imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                        maxFileSize={20971520}
                        singleImage={true}
                        className={image3 || imageLoading3 ? "disable-div" : ""}
                      />

                      <ul>
                        {imageLoading3 ? (
                          <span>Uploading Image...</span>
                        ) : (
                          form &&
                          form.mediaDetail &&
                          form.mediaDetail.length > 0 &&
                          form.mediaDetail.map((res, key) => {
                            if (res && res.imageNumber == 3) {
                              let pathArray = res && res.mediaPath.split("/");
                              let path = "";
                              let mediaPath = res.mediaPath;
                              pathArray.map((section, index) => {
                                if (index > 3) {
                                  path += section;
                                  if (index != pathArray.length - 1)
                                    path += "/";
                                }
                              });
                              return (
                                <li className="p-1">
                                  <a
                                    href={`${FileUrl}${mediaPath}`}
                                    download
                                    target="_blank"
                                  >
                                    {this.getDocName(path)}
                                  </a>
                                  <span
                                    className="text-danger ml-3"
                                    onClick={() =>
                                      this.handleDocDelete(
                                        res && res.imageNumber
                                      )
                                    }
                                  >
                                    {DisableFields === "true" ? (
                                      <i
                                        class="fa fa-trash"
                                        style={{ cursor: "pointer" }}
                                        aria-hidden="true"
                                      ></i>
                                    ) : this.state.flagInuse == "N" ? (
                                      <i
                                        class="fa fa-trash"
                                        style={{ cursor: "pointer" }}
                                        aria-hidden="true"
                                      ></i>
                                    ) : null}
                                  </span>
                                </li>
                              );
                            }
                          })
                        )}
                      </ul>
                    </div>

                    <div className="col-md-4">
                      <label>Image 4</label>
                      <ImageUploader
                        name="imageFour"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="upload image"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "imageFour");
                        }}
                        imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                        maxFileSize={20971520}
                        singleImage={true}
                        className={image4 || imageLoading4 ? "disable-div" : ""}
                      />

                      <ul>
                        {imageLoading4 ? (
                          <span>Uploading Image...</span>
                        ) : (
                          form &&
                          form.mediaDetail &&
                          form.mediaDetail.length > 0 &&
                          form.mediaDetail.map((res, key) => {
                            if (res && res.imageNumber == 4) {
                              let pathArray = res && res.mediaPath.split("/");
                              let path = "";
                              let mediaPath = res.mediaPath;
                              pathArray.map((section, index) => {
                                if (index > 3) {
                                  path += section;
                                  if (index != pathArray.length - 1)
                                    path += "/";
                                }
                              });
                              return (
                                <li className="p-1">
                                  <a
                                    href={`${FileUrl}${mediaPath}`}
                                    download
                                    target="_blank"
                                  >
                                    {this.getDocName(path)}
                                  </a>
                                  <span
                                    className="text-danger ml-3"
                                    onClick={() =>
                                      this.handleDocDelete(
                                        res && res.imageNumber
                                      )
                                    }
                                  >
                                    {DisableFields === "true" ? (
                                      <i
                                        class="fa fa-trash"
                                        style={{ cursor: "pointer" }}
                                        aria-hidden="true"
                                      ></i>
                                    ) : this.state.flagInuse == "N" ? (
                                      <i
                                        class="fa fa-trash"
                                        style={{ cursor: "pointer" }}
                                        aria-hidden="true"
                                      ></i>
                                    ) : null}
                                  </span>
                                </li>
                              );
                            }
                          })
                        )}
                      </ul>
                    </div>

                    <div className="col-md-4">
                      <label>Image 5</label>
                      <ImageUploader
                        name="imageFive"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="upload image"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "imageFive");
                        }}
                        imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                        maxFileSize={20971520}
                        singleImage={true}
                        className={image5 || imageLoading5 ? "disable-div" : ""}
                      />
                      <ul>
                        {imageLoading5 ? (
                          <span>Uploading Image...</span>
                        ) : (
                          form &&
                          form.mediaDetail &&
                          form.mediaDetail.length > 0 &&
                          form.mediaDetail.map((res, key) => {
                            if (res && res.imageNumber == 5) {
                              let pathArray = res && res.mediaPath.split("/");
                              let path = "";
                              let mediaPath = res.mediaPath;
                              pathArray.map((section, index) => {
                                if (index > 3) {
                                  path += section;
                                  if (index != pathArray.length - 1)
                                    path += "/";
                                }
                              });
                              return (
                                <li className="p-1">
                                  <a
                                    href={`${FileUrl}${mediaPath}`}
                                    download
                                    target="_blank"
                                  >
                                    {this.getDocName(path)}
                                  </a>
                                  <span
                                    className="text-danger ml-3"
                                    onClick={() =>
                                      this.handleDocDelete(
                                        res && res.imageNumber
                                      )
                                    }
                                  >
                                    {DisableFields === "true" ? (
                                      <i
                                        class="fa fa-trash"
                                        style={{ cursor: "pointer" }}
                                        aria-hidden="true"
                                      ></i>
                                    ) : this.state.flagInuse == "N" ? (
                                      <i
                                        class="fa fa-trash"
                                        style={{ cursor: "pointer" }}
                                        aria-hidden="true"
                                      ></i>
                                    ) : null}
                                  </span>
                                </li>
                              );
                            }
                          })
                        )}
                      </ul>
                    </div>
                  </div>
                </section>

                {/* upload image end */}

                {/* videoupload */}

                <section className="tab-body mt-3 mb-3">
                  <div className="row">
                    <h5 className="col-md-12 text-center mb-4">
                      Video Uploads
                    </h5>

                    <div className="col-md-4">
                      <label>video</label>
                      <ImageUploader
                        name="videoOne"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="video/*"
                        buttonText="upload video"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "videoOne");
                        }}
                        imgExtension={[".mp4"]}
                        maxFileSize={52428800}
                        className={video || videoLoading ? "disable-div" : ""}
                        singleImage={true}
                      />

                      <ul>
                        {videoLoading ? (
                          <span>Uploading Video...</span>
                        ) : (
                          form &&
                          form.mediaDetail &&
                          form.mediaDetail.length >= 0 &&
                          form.mediaDetail.map((res, key) => {
                            if (res && res.imageNumber == "") {
                              let pathArray = res && res.mediaPath.split("/");
                              let path = "";
                              let mediaPath = res.mediaPath;
                              pathArray.map((section, index) => {
                                if (index > 3) {
                                  path += section;
                                  if (index != pathArray.length - 1)
                                    path += "/";
                                }
                              });
                              return (
                                <li className="p-1">
                                  <a
                                    href={`${FileUrl}${mediaPath}`}
                                    download
                                    target="_blank"
                                  >
                                    {this.getDocName(path)}
                                  </a>
                                  <span
                                    className="text-danger ml-3"
                                    onClick={() =>
                                      this.handleDocDeleteVideo(
                                        res && res.serialNumber
                                      )
                                    }
                                  >
                                    {DisableFields === "true" ? (
                                      <i
                                        class="fa fa-trash"
                                        style={{ cursor: "pointer" }}
                                        aria-hidden="true"
                                      ></i>
                                    ) : this.state.flagInuse == "N" ? (
                                      <i
                                        class="fa fa-trash"
                                        style={{ cursor: "pointer" }}
                                        aria-hidden="true"
                                      ></i>
                                    ) : null}
                                  </span>
                                </li>
                              );
                            }
                          })
                        )}
                      </ul>
                    </div>
                  </div>
                </section>

                {/* videoupload end */}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {this.props && this.props.action == "edit" ? (
              <Button
                className="btn-primary"
                onClick={this.handleInactiveModalOpen}
                disabled={
                  videoLoading ||
                  imageLoading1 ||
                  imageLoading2 ||
                  imageLoading3 ||
                  imageLoading4 ||
                  imageLoading5
                }
              >
                {form && form.status == "Active" ? "Inactive" : "Active"}
              </Button>
            ) : null}
            <Button
              className="btn-danger"
              onClick={this.handleModalClose}
              disabled={
                videoLoading ||
                imageLoading1 ||
                imageLoading2 ||
                imageLoading3 ||
                imageLoading4 ||
                imageLoading5
              }
            >
              Cancel
            </Button>
            <Button
              className="btn-success"
              disabled={
                diableSave ||
                isRequiredFeildFilled ||
                isLoading ||
                videoLoading ||
                imageLoading1 ||
                imageLoading2 ||
                imageLoading3 ||
                imageLoading4 ||
                imageLoading5
              }
              //disabled={form&&form.status=="Inactive" ? true : false}
              onClick={
                this.props.action == "edit"
                  ? this.handleUpdate
                  : this.handleSave
              }
            >
              {this.props.action == "edit" ? "Update" : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
        <CloseModal
          show={showInner}
          button2={this.handleModalClose}
          button1={this.innerModalClose}
          title="Do you want to close ?"
        />

        {/** pop for inactive */}

        <Modal
          className="delete-document"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showInactive}
          id="delete-inventory-doc"
          // onHide={() => {
          //   this.handleDeleteModalclose();
          // }}
        >
          <Modal.Header>
            <Modal.Title className="w-100">Change Implement Status</Modal.Title>
          </Modal.Header>
          <Modal.Body className={`p-4 `} style={{ background: "#f8f9fa" }}>
            <h5>Are you sure want to update implement status?</h5>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-danger"
              onClick={this.handleInactiveModalClose}
            >
              No
            </Button>
            <Button
              className="btn-success"
              disabled={loading}
              onClick={this.handleInactive}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* //delete image modal */}

        <Modal
          className="delete-document"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showDeletemodal1 && showDeletemodal1}
          id="delete-inventory-doc"
          onHide={() => {
            this.handleDeleteModalclose();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100">Confirm to Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body className={`p-4 `} style={{ background: "#f8f9fa" }}>
            <h5>Are you sure to do this?</h5>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-danger"
              onClick={this.handleDeleteModalclose}
            >
              No
            </Button>
            <Button
              className="btn-success"
              disabled={loading}
              onClick={this.handleDelete}
            >
              {loading ? (
                <>
                  <i class="fa fa-spinner fa-spin"></i> Deleting.....
                </>
              ) : (
                "Yes, Delete It!"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* delete video */}

        <Modal
          className="delete-document"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showDeletemodal && showDeletemodal}
          id="delete-inventory-doc"
          onHide={() => {
            this.handleDeleteModalclose();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100">Confirm to Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body className={`p-4 `} style={{ background: "#f8f9fa" }}>
            <h5>Are you sure to do this?</h5>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-danger"
              onClick={this.handleDeleteModalclose}
            >
              No
            </Button>
            <Button
              className="btn-success"
              disabled={loading}
              onClick={this.handleDeleteVideo}
            >
              {loading ? (
                <>
                  <i class="fa fa-spinner fa-spin"></i> Deleting.....
                </>
              ) : (
                "Yes, Delete It!"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default AddEditService;
