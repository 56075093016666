import { all } from 'redux-saga/effects';

// sagas
import userAuthSagas from './authAction';

export default function* rootSaga(getState) {
    yield all([
        userAuthSagas(),
        
    ]);
}