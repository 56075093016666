import React, { useState, useEffect } from "react";
import Select from "react-select";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import { Button } from "react-bootstrap";
import xorBy from "lodash/xorBy";

const LotView = (props) => {
	// const { lots, remainingPouches } = lotInfo;
	const [lots, setLotInfo] = useState(props.lotInfo.lots);
	const [remainingPouches, setRemainingPouches] = useState(props.lotInfo.remainingPouches);
	const handleChange = (e, index) => {

		let oldLots = [...lots];
		let oldRemainingPouches = [...remainingPouches];
		if (e == null) {
			oldRemainingPouches.push(oldLots[index].pouches[0]);
			oldLots[index].pouches = [];
			setLots(oldLots, oldRemainingPouches);
			props.handleEmptyLot(true);
			return;
		} else if (e < oldLots[index].pouches) {
			console.log("VALUE REMOVED");
			const difference = xorBy(oldLots[index].pouches, e, "lanId");
			oldLots[index].pouches = e;
			oldRemainingPouches.push(difference[0]);
			console.log("FINAL INFO IS", oldLots, oldRemainingPouches);
			setLots(oldLots, oldRemainingPouches);
		} else {
			console.log("VALUE ADDED");
			const difference = xorBy(e, oldLots[index].pouches, "lanId");
			console.log(difference);
			oldLots[index].pouches = e;
			const newRemaining = oldRemainingPouches.filter((item) => item.lanId !== difference[0].lanId);
			console.log("FINAL INFO IS", oldLots, difference, newRemaining);
			setLots(oldLots, newRemaining);
			let flag = false;
			lots.forEach((item) => {
				if (item.pouches.length === 0) {
					flag = true
				}
			});
			if (!flag) {
				props.handleEmptyLot(false);
			}
		}

	};

	const setLots = (lots, remainingPouches) => {
		let { lotInfo } = props;
		lotInfo.lots = lots;
		lotInfo.remainingPouches = remainingPouches;
		setLotInfo(lots);
		setRemainingPouches(remainingPouches);
		props.handleSaveLot(lotInfo);
	};

	return (
		<>
			<div className="row px-4">
				<h4 className="col-md-8">Generated Lots:</h4>
				{lots.map((item, index) => (
					<div className="col-md-8" key={item.lotId}>
						<label>
							Lot {index + 1} <i className="text-danger">*</i>
						</label>
						<Select
							options={remainingPouches}
							defaultValue={item.pouches}
							isMulti
							valueKey="lanId"
							labelKey="lanId"
							getOptionLabel={(option) => option["lanId"]}
							getOptionValue={(option) => option["lanId"]}
							onChange={(e) => handleChange(e, index)}
							isClearable={false}
						/>
						{item.pouches.length === 0 && <span className="errorMsg">Lot cannot be empty</span>}
					</div>
				))}
				<div className="col-md-4 d-flex align-items-center justify-content-around"></div>
			</div>
		</>
	);
};

export default LotView;
