import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import ImageUploader from "react-images-upload";
import "react-datepicker/dist/react-datepicker.css";

import Select from "react-select";
import { cloneDeep, stubFalse } from "lodash";
import JSZip from "jszip";

import { ToastContainer, toast } from "react-toastify";
import {
  SpinnerLoader,
  FullScreenLoader,
} from "../../../Components/Assets/Loader";
import { CloseSection } from "../../../Components/Assets/CloseSection";
import {
  uploadFranchiseAppraiserDoc,
  saveOrUpdatefranchise,
  checkFranchiseDedupe,
  checkemailidispresent,
  checkMobileNumberIsPresent,
  findallvillagesdropdown,
  checkVillageIsAlreadyMappedToFranchise,
} from "../../../Utils/Services/franchisemaster";

import moment from "moment";
import {
  getTypeCompany,
  verifyBankAccountDetails,
  getAddressByPincode,
  getAllBranch,
  verifyGst,
  verifyVoterID,
  verifyPassport,
  verifyDrivingLicense,
  pan,
} from "../../../Utils/Services/common";
import "./style.css";
toast.configure();

/* let branchList = [
    { branchCode: "MUB", branchCode: "MUMBAI" },
    { branchCode: "GUJ", branchCode: "GUJARAT" },
] */
let gstListDoc = [
  {
    emailId: "",
    applicationStatus: "",
    mobNum: "",
    registrationName: "",
    gstinRefId: "",
    regType: "",
    authStatus: "Inactive",
    tinNumber: "",
    pan: "AAACR5055K",
    gstinId: "03AAACR5055K1ZH",
  },
  {
    emailId: "",
    applicationStatus: "",
    mobNum: "",
    registrationName: "",
    gstinRefId: "",
    regType: "",
    authStatus: "Active",
    tinNumber: "",
    pan: "AAACR5055K",
    gstinId: "27AAACR5055K3Z5",
  },
  {
    emailId: "",
    applicationStatus: "",
    mobNum: "",
    registrationName: "",
    gstinRefId: "",
    regType: "",
    authStatus: "Active",
    tinNumber: "",
    pan: "AAACR5055K",
    gstinId: "05AAACR5055K1ZD",
  },
  {
    emailId: "",
    applicationStatus: "",
    mobNum: "",
    registrationName: "",
    gstinRefId: "",
    regType: "",
    authStatus: "Inactive",
    tinNumber: "",
    pan: "AAACR5055K",
    gstinId: "29AAACR5055K2Z2",
  },
];

let mandatoryField = [
  "typeOfCompany",
  "franchiseCode",
  "branch",
  "address",
  "bankAccountName",
  "bankName",
  "accountNumber",
  "ifscCode",
  "mobileNumber",
];

let mandatoryDoc = ["appraiserAppointment", "chequeGaurantee", "kycDocument"];

let initForm = {
  typeOfCompany: "",
  panNumber: "",
  dateOfEstablishment: "",
  bureauscore: "",
  name: "",
  franchiseCode: "",
  gstNumber: "",
  drivingLicenseNumber: "",
  voterIdNumber: "",
  passPortNumber: "",
  panNumber: "",
  drivingLicenceNumber: "",
  drivingLicenceDateOfBirth: "",
  passportDateOfBirth: "",
  passportFileNumber: "",
  Depositsecurity: "",
  epicNo: "",
  dateOfbirth: "",
  dlNo: "",
  dob: "",
  passportNo: "",
  fileNo: "",
  doi: "",
  location: "",
  registeredAddress: {
    state: "",
    city: "",
    branch: "",
    pinCode: "",
    contactName: "",
    mobileNumber: "",
    emailId: "",
    address: "",
  },
  communicationAddress: {
    state: "",
    city: "",
    branch: "",
    pinCode: "",
    contactName: "",
    mobileNumber: "",
    emailId: "",
    address: "",
  },
  isregisteraddsameascommddress: false,
  doYouHaveGST: false,
  bankAccountName: "",
  bankName: "",
  accountNumber: "",
  ifscCode: "",
  panNumberVerified: false,
  drivingLicenceNumberVerified: false,
  passportNumberVerified: false,
  bankVerified: false,
  voterIdVerified: false,
  status: "PENDING",
};

let documentObj = {
  documentName: "",
  documentPath: "",
  description: "",
  uploadedBy: "",
};
class FranchiseeOnboardingMasterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchList: [],

      isDis: true,

      typeofcompanyList: [],
      gstList: [],
      isDedupe: false,
      isEmailPresent: false,
      isMobileNumberPresent: false,
      verifyingBank: false,
      key: Math.random(),
      form: cloneDeep(initForm),
      show: false,
      showInner: false,
      loading: false,
      isVerifyingbureau: false,
      invalidPanNumber: false,
      isVerifyingPan: false,
      verifyingDriving: false,
      verifyingvoterIdNumber: false,
      isVerifyingVoterId: false,
      verifyingPassport: false,
      isVerifyingGst: false,
      invalidDrivingNumber: false,
      DrivingError: false,
      EpicNoError: false,
      PassportError: false,
      invalidpassportFileNumber: false,
      invalidvoterIdNumber: false,
      demandPromissoryNote: [],
      appraiserAppointment: [],
      aggrement: [],
      payoutdoc: [],
      dpnLetter: [],
      chequeGaurantee: [],
      passportPhoto: [],
      resume: [],
      shopRegistration: [],
      kycDocument: [],
      substituteAppraiser: [],
      bmInterviewSheet: [],
      form60: [],
      otherDocument1: [],
      otherDocument2: [],
      otherDocument3: [],
      otherDocument4: [],
      userZone: [],
      cities: [],
      comCities: [],
      selectedCity: null,
      selectedState: "",
      selectedBranch: "",
      zoneDropdownValue: [],
      villagePresent: false,
      errors: {
        //panAvailability: null,
        typeOfCompany: null,
        validbureauscore: null,
        msg: null,
        //panNumber: null,
        PanNumberError: null,
        dateOfEstablishment: null,
        //companyName: null,
        bureauscore: null,
        franchiseCode: null,
        gstNumber: null,
        otherBankAppraiserName: null,
        yearOfExperienceInGoldOrnaments: null,
        ownJewellerycompanyName: null,
        instituteNameOfDegree: null,
        accountNumber: null,
        bankAccountName: null,
        bankName: null,
        accountNumber: null,
        isValidIfsc: null,
        invalidDrivingNumber: null,
        DrivingError: null,
        EpicNoError: null,
        invalidvoterIdNumber: null,
        validAccountNumber: null,
        PassportError: null,
        invalidpassportFileNumber: null,
        location: null,
        registeredAddress: {
          state: null,
          city: null,
          branch: null,
          pinCode: null,
          contactName: null,
          mobileNumber: null,
          address: null,
          emailId: null,
          validPincode: null,
          validEmail: null,
          validMobile: null,
        },
        communicationAddress: {
          state: null,
          city: null,
          branch: null,
          pinCode: null,
          contactName: null,
          mobileNumber: null,
          emailId: null,
          address: null,
          validPincode: null,
          validEmail: null,
          validMobile: null,
        },
        demandPromissoryNote: null,
        appraiserAppointment: null,
        dpnLetter: null,
        chequeGaurantee: null,
        passportPhoto: null,
        resume: null,
        shopRegistration: null,
        kycDocument: null,
        substituteAppraiser: null,
        bmInterviewSheet: null,
        form60: null,
        aggrement: null,
        payoutdoc: null,
        otherDocument1: null,
        otherDocument2: null,
        otherDocument3: null,
        otherDocument4: null,
      },
    };
    this.handleCheck = this.handleCheck.bind(this);
  }

  componentDidMount = () => {
    this.handleShow();
    this.getBranchList();
    this.getcompanyList();
    this.allvillagesdropdown();
  };

  //Security deposit
  enforceMinMax = (el) => {
    if (el.value != "") {
      if (parseInt(el.value) < parseInt(el.min)) {
        el.value = el.min;
      }
      if (parseInt(el.value) > parseInt(el.max)) {
        el.value = el.max;
      }
    }
  };

  handleSequrity = (e) => {
    let { form } = this.state;

    form.securityDeposit = e.target.value;
    this.setState({
      form,
    });
  };

  allvillagesdropdown = () => {
    findallvillagesdropdown().then((res) => {
      console.log("village", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        return;
      } else {
        this.setState({ zoneDropdownValue: res.data.data });
      }
    });
  };

  handlePanNumber = () => {
    // this.handleVerifyVoterId();
    this.handleVerifyPan();
    // this.handleVerifyPassport();
    // this.handleVerifyDriving();
  };

  handleVoter = () => {
    this.handleVerifyVoterId();
  };

  handleCheck() {
    let { form } = this.state;
    form.doYouHaveGST = !form.doYouHaveGST;
    this.setState({ form });
  }

  //verify pan number
  handleVerifyPan = () => {
    let { form } = this.state;
    this.setState({ isVerifyingPan: true });
    let data = {
      consent: "Y",
      pan: form.panNumber,
    };

    pan(data).then((res) => {
      this.setState({ isVerifyingPan: false });
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        console.log("res.data", res.data.panHolderName);
        if (res.data && res.data.panHolderName !== "") {
          form.companyName = res.data.panHolderName;
          form.companyPanNumberVerified = true;
          this.setState({ form });
        }
      }
    });
  };

  checkVillagePresent = (zonearray) => {
    let { form, userZone, zoneDropdownValue } = this.state;
    console.log("abcd", zonearray[0]);
    let data = {
      villages: zonearray[0],
    };
    checkVillageIsAlreadyMappedToFranchise(data).then((res) => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
        return;
      } else {
        console.log(
          "res.data",
          res && res.data && res.data.data && res.data.data.message
        );
        if (res && res.data && res.data.data && res.data.data.message != "") {
          toast.error(
            res && res.data && res.data.data && res.data.data.message,
            {
              type: toast.TYPE.error,
              autoClose: 4000,
            }
          );
          this.setState({ villagePresent: true });
        }
        if (
          res &&
          res.data &&
          res.data.data &&
          res.data.data.message == undefined
        ) {
          this.setState({ villagePresent: false });
        }
      }
    });
  };

  //fetch all company list
  getcompanyList = () => {
    getTypeCompany().then((res) => {
      let companyTypeList = res && res.data && res.data.typeofcompanyList;

      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        return;
      } else {
        this.setState({ typeofcompanyList: companyTypeList });
      }
    });
  };

  //get all branch list
  getBranchList = () => {
    getAllBranch().then((res) => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        return;
      } else {
        if (res.data && res.data.data && res.data.data.length > 0) {
          this.setState({ branchList: res.data.data });
        }
      }
    });
  };

  //driving license validation
  handleDrivingVerification = () => {
    let { form } = this.state;
    this.setState({ verifyingDriving: true });
    console.log("date", form.drivingLicenceDateOfBirth);
    console.log(
      "date",
      moment(new Date(form.drivingLicenceDateOfBirth)).format("DD-MM-YYYY")
    );
    let data = {
      dlNo: form.drivingLicenceNumber,
      dob: moment(new Date(form.drivingLicenceDateOfBirth)).format(
        "DD-MM-YYYY"
      ),
    };

    verifyDrivingLicense(data).then((res) => {
      this.setState({ verifyingDriving: false });
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        console.log("res.data", res.data.data);
        form.drivingLicenceNumberVerified = true;
        this.setState({ form });
      }
    });
  };
  //Voter ID authentication
  handleVoterIdVerification = () => {
    let { form } = this.state;
    this.setState({ verifyingvoterIdNumber: true });

    let data = {
      epicNo: form.voterIdNumber,
    };

    verifyVoterID(data).then((res) => {
      this.setState({ verifyingvoterIdNumber: false });
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        console.log("res.data", res.data.data);
        form.voterIdVerified = true;
        this.setState({ form });
      }
    });
  };

  //verify passport

  handlePassportVerification = () => {
    let { form } = this.state;
    this.setState({ verifyingPassport: true });
    console.log("date", form.passportDateOfBirth);
    console.log(
      "date",
      moment(new Date(form.passportDateOfBirth)).format("DD/MM/YYYY")
    );
    let data = {
      fileNo: form.passportFileNumber,
      dob: moment(new Date(form.passportDateOfBirth)).format("DD/MM/YYYY"),
    };

    verifyPassport(data).then((res) => {
      this.setState({ verifyingPassport: false });
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        console.log("res.data", res.data.data);
        form.passportNumberVerified = true;
        this.setState({ form });
      }
    });
  };

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  handleClose = (close) => {
    this.props.history.push("/dashboard");
    if (!close) this.close();
    this.setState({ show: false });
    // this.props.addUser();
  };

  close = (close) => {
    this.setState({ showInner: !this.state.showInner });
  };

  handleChange = (e, name) => {
    let { form } = this.state;
    form[name] = e.target.value;
    this.setState({ form });
  };

  handlebranchSelectChange = (e, name) => {
    console.log("handlebranchSelectChange", e, name);
    let { form } = this.state;
    let { branchCode } = e;
    form[name].branch = branchCode;
    if (form.isregisteraddsameascommddress == true) {
      form.communicationAddress.branch = branchCode;
    }
    this.setState({ form, key: Math.random() });
  };

  handleCompanySelectChange = (e, name) => {
    console.log("handlebranchSelectChange", e, name);

    let { form, errors } = this.state;
    let { companytype } = e;
    form[name] = companytype;
    form.panNumber = "";
    form.companyPanNumberVerified = false;
    form.companyName = "";

    let value = companytype;
    if (value == "" || value == null || value == undefined) {
      errors[name] = true;
      this.setState({ errors });
    } else {
      errors[name] = false;
      this.setState({ errors });
    }

    this.setState({ form, key: Math.random() });
  };

  //location
  handleLocationSelectChange = (e, name) => {
    console.log("handleLocationSelectChange", e, name);

    let { form, errors } = this.state;
    let { villageName } = e;
    form[name] = villageName;
    form.villageName = "";

    let value = villageName;
    if (value == "" || value == null || value == undefined) {
      errors[name] = true;
      this.setState({ errors });
    } else {
      errors[name] = false;
      this.setState({ errors });
    }

    this.setState({ form, key: Math.random() });
  };

  onValidateCompany = (e, name) => {
    const { errors, form } = this.state;
    let value = form[name].typeOfCompany;
    if (value == "" || value == null || value == undefined) {
      errors[name] = true;
      this.setState({ errors });
    } else {
      errors[name] = false;
      this.setState({ errors });
    }
  };
  handleValidatebureauscore = (e, name) => {
    e.preventDefault();
    const { errors, form } = this.state;
    let value = form.bureauscore;
    this.setState({ errors });
    if (value == "") {
      errors[name] = "Enter Bureau Score In Range";
      form.isVerifyingbureau = false;
      this.setState({ errors });
    } else if (value.length !== 3 || value > 900 || value < 300) {
      errors[name] = true;
      this.setState({ errors });
      form.isVerifyingbureau = false;
    } else {
      form.isVerifyingbureau = true;
      errors[name] = false;
      this.setState({ errors });
    }
  };

  onValidateLocation = (e, name) => {
    const { errors, form } = this.state;
    let value = form.location;
    if (value == "" || value == null || value == undefined) {
      errors[name] = true;
      this.setState({ errors });
    } else {
      errors[name] = false;
      this.setState({ errors });
    }
  };

  onValidatebranch = (e, name) => {
    const { errors, form } = this.state;
    let value = form[name].branch;
    if (value === "" || value === null || value === undefined) {
      errors[name].branch = true;
      this.setState({ errors });
    } else {
      errors[name].branch = false;
      this.setState({ errors });
    }
  };

  handleRadioChange = (e, name) => {
    let { form, errors } = this.state;
    console.log("handleRadioChange", e.target, form[name]);
    if (name == "isregisteraddsameascommddress") {
      form[name] = !form[name];
      this.setState({ form }, () => {
        let { form } = this.state;
        if (form.isregisteraddsameascommddress == true) {
          let { registeredAddress } = form;
          let { communicationAddress } = errors;
          form.communicationAddress = cloneDeep(registeredAddress);
          errors.communicationAddress = cloneDeep({
            state: null,
            city: null,
            branch: null,
            pinCode: null,
            contactName: null,
            mobileNumber: null,
            emailId: null,
            address: null,
            validPincode: null,
            validEmail: null,
            validMobile: null,
          });
          this.setState({ form, errors, key: Math.random() });
        } else {
          console.log("handleRadioChange else.....");
          form.communicationAddress = cloneDeep({
            state: "",
            city: "",
            branch: "",
            pinCode: "",
            contactName: "",
            mobileNumber: "",
            emailId: "",
            address: "",
          });
          let { communicationAddress } = errors;
          errors.communicationAddress = cloneDeep({
            state: null,
            city: null,
            branch: null,
            pinCode: null,
            contactName: null,
            mobileNumber: null,
            emailId: null,
            address: null,
            validPincode: null,
            validEmail: null,
            validMobile: null,
          });
          this.setState({ form, errors, key: Math.random() });
        }
      });
    } else {
      if (
        name == "gstNumber" ||
        name == "franchiseCode" ||
        name == "ifscCode"
      ) {
        form[name] = e.target.value.toUpperCase();
      } else {
        form[name] = e.target.value;
      }
      this.setState({ form });
    }
  };

  handleDateOfEstablishment = (date, e) => {
    console.log("handleDateOfEstablishment", date, e);
    let { form } = this.state;
    form.dateOfEstablishment = date;
    this.setState({ form });
  };

  handledrivingLicenceDateOfBirth = (date, e) => {
    console.log("handledrivingLicenceDateOfBirth", date, e);
    let { form } = this.state;
    form.drivingLicenceDateOfBirth = date;
    this.setState({ form });
  };

  handlepassportDateOfBirth = (date, e) => {
    console.log("handlepassportDateOfBirth", date, e);
    let { form, errors } = this.state;
    form.passportDateOfBirth = date;
    this.setState({ form });
  };

  //handle driving

  handleDrive = (e) => {
    let { errors, form } = this.state;
    if (e.target.value.trim() !== "") {
      errors = { ...errors, DrivingError: false };
    } else {
      errors = { ...errors, DrivingError: true };
    }
    form.drivingLicenceNumber = e.target.value.toUpperCase();
    this.setState({
      form,
      errors,
    });
  };

  //handle voter ID
  handleEpicNo = (e) => {
    let { errors, form } = this.state;
    if (e.target.value.trim() !== "") {
      errors = { ...errors, EpicNoError: false };
    } else {
      errors = { ...errors, EpicNoError: true };
    }
    form.voterIdNumber = e.target.value.toUpperCase();
    this.setState({
      form,
      errors,
    });
  };

  //handle passport
  handlePassport = (e) => {
    let { errors, form } = this.state;
    if (e.target.value.trim() !== "") {
      errors = { ...errors, PassportError: false };
    } else {
      errors = { ...errors, PassportError: true };
    }
    form.passportFileNumber = e.target.value.toUpperCase();
    this.setState({
      form,
      errors,
    });
  };
  handleGstChange = (e, name) => {
    console.log("handleGstChange", e, name);
    let { form } = this.state;

    let { gstinId } = e;
    form.gstNumber = gstinId;
    this.setState({ form });
  };

  getGstList = () => {
    let { form } = this.state;

    this.setState({ isVerifyingGst: true });
    let data = {
      panNumber: form.panNumber,
    };

    verifyGst(data).then((res) => {
      console.log("resss", res);
      // let gstList = res.data.data
      this.setState({ isVerifyingGst: false });
      if (res.error) {
        toast.error(res.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        this.setState({ gstList: res.data.data, key: Math.random() });
      }
    });
  };

  handlePan = (e) => {
    let { errors, form } = this.state;
    if (e.target.value.trim() !== "") {
      errors = { ...errors, PanNumberError: false };
    } else {
      errors = { ...errors, PanNumberError: true };
    }
    form.panNumber = e.target.value.toUpperCase();
    this.setState({
      form,
      errors,
    });
  };

  restrictAlphabets = (e) => {
    const regx = "^[0-9]*$";
    if (e.key.match(regx)) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  handleAddressChange = (e, name, outerName) => {
    console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh", name);
    console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk", outerName);
    // console.log("handleAddressChange", e, name, outerName)
    let { form } = this.state;
    // const { pinCode } = e.target.value;
    form[outerName][name] = e.target.value;
    if (form.isregisteraddsameascommddress == true) {
      form.communicationAddress[name] = e.target.value;
    }

    console.log({ form });
    if (
      name === "pinCode" &&
      outerName === "registeredAddress" &&
      form["registeredAddress"]["pinCode"].length < 6
    ) {
      console.log("hrjejejej");
      form["registeredAddress"].city = "";
      form["registeredAddress"].state = "";
      form["registeredAddress"].branch = "";
      this.setState({ cities: [] });
    }

    if (
      name === "pinCode" &&
      outerName === "communicationAddress" &&
      form["communicationAddress"]["pinCode"].length < 6
    ) {
      form["communicationAddress"].city = "";
      form["communicationAddress"].state = "";
      form["communicationAddress"].branch = "";
      this.setState({ comCities: [] });
    }
    this.setState({ form });

    // form.registeredAddress.pinCode = val;
    // this.setState({ form });
  };

  fetchAddressByPincode = (sectionName) => {
    console.log("uuuuuuuuuuuuuuuuuuuuuuuuuu", sectionName);
    let { form, errors } = this.state;
    this.setState({ loading: true });
    getAddressByPincode({ pincode: form[sectionName].pinCode }).then((res) => {
      if (sectionName === "registeredAddress") {
        let cities =
          res &&
          res.data &&
          res.data.data &&
          res.data.data.map((item) => ({
            value: item.city_name,
            label: item.city_name,
            state: item.state_name,
            branch: item.branch_name,
          }));
        if (!cities) cities = [];
        this.setState({ cities });
      } else if (sectionName === "communicationAddress") {
        let comCities =
          res &&
          res.data &&
          res.data.data &&
          res.data.data.map((item) => ({
            value: item.city_name,
            label: item.city_name,
            state: item.state_name,
            branch: item.branch_name,
          }));
        if (!comCities) comCities = [];

        this.setState({ comCities });
      }
      this.setState({ loading: false });
      // if ((errors[sectionName].validPincode = true)) {
      //   cities = [];
      // }
      if (res.error) {
        return;
      }

      this.setState({ form });

      if (
        form.isregisteraddsameascommddress &&
        sectionName == "registeredAddress"
      ) {
        form.communicationAddress.city = "";
        form.communicationAddress.state = "";
        form.communicationAddress.branch = "";
        form.communicationAddress.pinCode = "";
      }
      this.setState({ errors, form });
      return;
    });
  };

  // fetchAddressByPincode = (sectionName) => {
  //   console.log("uuuuuuuuuuuuuuuuuuuuuuuuuu", sectionName);
  //   let { form, errors } = this.state;
  //   this.setState({ loading: true });
  //   getAddressByPincode({ pincode: form[sectionName].pinCode }).then((res) => {
  //     if (sectionName === "registeredAddress") {
  //       const cities =
  //         res &&
  //         res.data &&
  //         res.data.data &&
  //         res.data.data.map((item) => ({
  //           value: item.city_name,
  //           label: item.city_name,
  //           state: item.state_name,
  //           branch: item.branch_name,
  //         }));
  //       this.setState({ cities });
  //     } else if (sectionName === "communicationAddress") {
  //       const comCities =
  //         res &&
  //         res.data &&
  //         res.data.data &&
  //         res.data.data.map((item) => ({
  //           value: item.city_name,
  //           label: item.city_name,
  //           state: item.state_name,
  //           branch: item.branch_name,
  //         }));
  //       this.setState({ comCities });
  //     }
  //     this.setState({ loading: false });
  //     // if ((errors[sectionName].validPincode = true)) {
  //     //   cities = [];
  //     // }
  //     if (res.error) {
  //       return;
  //     }
  //     if (res.data.error) {
  //       /*  toast.error(res.data.message, {
  //                    type: toast.TYPE.ERROR,
  //                    autoClose: 4000
  //                }); */
  //       errors[sectionName].validPincode = true;
  //       form[sectionName].city = "";
  //       form[sectionName].state = "";
  //       form[sectionName].branch = "";
  //       if (
  //         form.isregisteraddsameascommddress &&
  //         sectionName == "registeredAddress"
  //       ) {
  //         form.communicationAddress.city = "";
  //         form.communicationAddress.state = "";
  //         form.communicationAddress.branch = "";
  //         form.communicationAddress.pinCode = "";
  //       }
  //       this.setState({ errors, form });
  //       return;
  //     }
  //   });
  // };

  handleCityChange = (selectedOption, name) => {
    const { value, state, branch } = selectedOption;
    const { form, errors, isregisteraddsameascommddress } = this.state;
    let some = form;

    if (name == "registeredAddress") {
      some.registeredAddress.city = selectedOption;
      some.registeredAddress.state = state;
      some.registeredAddress.branch = branch;
    } else if (
      isregisteraddsameascommddress !== true &&
      name === "registeredAddress"
    ) {
      some.registeredAddress.city = selectedOption;
      some.registeredAddress.state = state;
      some.registeredAddress.branch = branch;
    }
    // } else {
    //   some.registeredAddress.city = "";
    //   some.registeredAddress.state = "";
    //   some.registeredAddress.branch = "";
    // }
    this.setState({
      form: some,
    });

    if (name == "communicationAddress") {
      some.communicationAddress.city = selectedOption;
      some.communicationAddress.branch = branch;
      some.communicationAddress.state = state;
    }

    this.setState({
      form: some,
    });
  };

  handleValidateAddressChange = (e, name, outerName) => {
    const { errors, form } = this.state;
    console.log(
      "handleValidateAddressChange",
      e,
      name,
      outerName,
      name == "emailId"
    );
    let value = form[outerName][name];
    let mandatory = [
      "branch",
      "address",
      "contactName",
      "pinCode",
      "mobileNumber",
    ];

    if (
      (value === "" || value === null || value === undefined) &&
      mandatory.includes(name)
    ) {
      errors[outerName][name] = true;
      if (name == "pinCode") {
        form[outerName].city = "";
        form[outerName].state = "";
        form[outerName].branch = "";
        if (
          form.isregisteraddsameascommddress &&
          outerName == "registeredAddress"
        ) {
          form.communicationAddress.city = "";
          form.communicationAddress.state = "";
          form.communicationAddress.branch = "";
          form.communicationAddress.pinCode = "";
        }
      }
      this.setState({ errors, form });
    } else {
      errors[outerName][name] = false;
      this.setState({ errors });
    }

    if (name == "pinCode" && value && value.length !== 6) {
      errors[outerName].validPincode = true;
      this.setState({ errors });
    } else {
      errors[outerName].validPincode = false;
      this.setState({ errors });
    }

    if (name == "pinCode" && value && value.length == 6) {
      this.fetchAddressByPincode(outerName);
    }

    if (name == "emailId") {
      let emailRegx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // console.log("handleValidateAddressChange", value, value && !value.match(emailRegx))

      if (value && !value.match(emailRegx)) {
        errors[outerName].validEmail = true;
        this.setState({ errors });
      } else {
        errors[outerName].validEmail = false;
        this.setState({ errors });
      }
    }

    if (name == "emailId" && value != "") {
      this.handleEmailPresent(value);
    }

    if (name == "mobileNumber" && value != "") {
      this.handleMobilePresentforReg(
        form && form.registeredAddress && form.registeredAddress.mobileNumber,
        "registeredAddress"
      );
    }

    if (name == "mobileNumber" && value != "") {
      this.handleMobilePresentforComm(
        form &&
          form.communicationAddress &&
          form.communicationAddress.mobileNumber,
        "communicationAddress"
      );
    }

    if (name == "mobileNumber" && value != "") {
      let indianMobilenumber = /^[6789]\d{9}$/;
      if ((value && value.length < 10) || !value.match(indianMobilenumber)) {
        errors[outerName].validMobile = true;
        this.setState({ errors });
      } else {
        errors[outerName].validMobile = false;
        this.setState({ errors });
      }
    }
  };

  //verify franchise code duplication
  handleAppraiserDedupe = (franchiseCode) => {
    const { errors, form } = this.state;
    checkFranchiseDedupe({ franchiseCode: franchiseCode }).then((res) => {
      // console.log("checkFranchiseDedupe", res)
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        return;
      } else {
        let isDedupe = false;
        let { form } = this.state;
        if (
          res.data &&
          res.data.data &&
          res.data.data.franchiseCodeAvailable &&
          res.data.data.franchiseCodeAvailable == true
        ) {
          errors.franchiseCode = "Franchise code already exists.";
          isDedupe = true;
          console.log("abcd", errors.franchiseCode);
        } else {
          errors.franchiseCode = false;
          isDedupe = false;
        }
        this.setState({ errors, isDedupe });
        console.log("errorss", errors);
      }
    });
  };

  handleEmailPresent = (emailId) => {
    const { errors, form } = this.state;
    checkemailidispresent({
      emailId: emailId,
      franchiseCode: form && form.franchiseCode,
    }).then((res) => {
      // console.log("checkFranchiseDedupe", res)
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
        return;
      } else {
        let isEmailPresent = false;
        let { form } = this.state;
        let emid =
          res.data &&
          res.data.data &&
          res.data.data.emailIdAvailable &&
          res.data.data.emailIdAvailable == true;
        console.log(emid, "emid");
        if (emid) {
          console.log(emid, "emid1");
          errors.registeredAddress.emailId = "EMAIL ID already exist";
          if (
            form &&
            form.communicationAddress &&
            form.communicationAddress.emailId == ""
          ) {
            errors.communicationAddress.emailId = false;
          } else {
            errors.communicationAddress.emailId = "EMAIL ID already exist";
          }

          if (
            form.registeredAddress.emailId ===
              form.communicationAddress.emailId &&
            emid
          ) {
            errors.communicationAddress.emailId = false;
            errors.registeredAddress.emailId = "EMAIL ID already exist";
          }

          isEmailPresent = true;
        } else {
          errors.communicationAddress.emailId = false;
          isEmailPresent = false;
        }
        this.setState({ errors, isEmailPresent });
        console.log("errorss", errors);
      }
    });
  };

  handleMobilePresentforReg = (mobileNumber, outerName) => {
    const { errors, form } = this.state;

    checkMobileNumberIsPresent({
      mobileNumber: mobileNumber,
      franchiseCode: form && form.franchiseCode,
    }).then((res) => {
      // console.log("checkFranchiseDedupe", res)
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
        return;
      } else {
        let isMobileNumberPresent = false;
        let { form } = this.state;
        let emidForregMobile =
          res.data &&
          res.data.data &&
          res.data.data.mobileNumberAvailable &&
          outerName == "registeredAddress"
            ? true
            : false;
        let regMobileNumber =
          form && form.registeredAddress && form.registeredAddress.mobileNumber;

        if (
          regMobileNumber.length > 0 &&
          emidForregMobile &&
          outerName == "registeredAddress"
        ) {
          errors.registeredAddress.mobileNumber = "Mobile number already exist";
          isMobileNumberPresent = true;
        } else {
          errors.registeredAddress.mobileNumber = "";
          isMobileNumberPresent = false;
        }

        this.setState({ errors, isMobileNumberPresent });
        console.log("errorss", errors);
      }
    });
  };

  handleMobilePresentforComm = (mobileNumber, outerName) => {
    const { errors, form } = this.state;

    checkMobileNumberIsPresent({
      mobileNumber: mobileNumber,
      franchiseCode: form && form.franchiseCode,
    }).then((res) => {
      // console.log("checkFranchiseDedupe", res)
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
        return;
      } else {
        let isMobileNumberPresent = false;
        let { form } = this.state;
        let emidForcommMobile =
          res.data &&
          res.data.data &&
          res.data.data.mobileNumberAvailable &&
          outerName == "communicationAddress"
            ? true
            : false;

        let commMobileNumber =
          form &&
          form.communicationAddress &&
          form.communicationAddress.mobileNumber;

        if (
          commMobileNumber.length > 0 &&
          emidForcommMobile &&
          outerName == "communicationAddress"
        ) {
          errors.communicationAddress.mobileNumber =
            "Mobile number already exist";
          isMobileNumberPresent = true;
        } else {
          errors.communicationAddress.mobileNumber = "";
          isMobileNumberPresent = false;
        }

        this.setState({ errors, isMobileNumberPresent });
        console.log("errorss", errors);
      }
    });
  };

  handleValidate = (e, name) => {
    const { errors, form } = this.state;
    console.log("shhh", name, form[name], name == "otherBankAppraiserWorking");

    let value = form[name];
    let nonMandatory = [
      "companyName",
      "dateOfEstablishment",
      "drivingLicenceDateOfBirth",
      "passportDateOfBirth",
    ];
    if (
      (value === "" || value === null || value === undefined) &&
      !nonMandatory.includes(name)
    ) {
      errors[name] = true;
      this.setState({ errors });
    } else {
      errors[name] = false;
      this.setState({ errors });
    }

    if (name == "franchiseCode" && value != "") {
      this.handleAppraiserDedupe(value);
    }

    if (name === "ifscCode") {
      if (value.trim() == "") {
        this.setState({ errors: { ...errors, ifscCodeError: true } });
      } else {
        this.setState({ errors: { ...errors, ifscCodeError: false } });
      }
    }

    // if (name === "typeOfCompany") {
    //   let { errors } = this.state;
    //   if (form.typeOfCompany == "") {
    //     errors.typeOfCompany = true;
    //     this.setState({
    //       errors,
    //     });
    //   } else {
    //     if (form[name] === form.typeofcompanyList) {
    //       errors.typeOfCompany = false;
    //     }
    //   }
    // }

    // if (
    //   name === "typeOfCompany" &&
    //   form[name] ===
    //     this.state.typeofcompanyList.filter(
    //       (e) => this.state.typeofcompanyList[0].companydesc
    //     )
    // ) {
    //   let { errors } = this.state;
    //   if (form.panNumber == "") {
    //     errors.panNumber = false;
    //     this.setState({
    //       errors,
    //     });
    //   } else {
    //     errors.panNumber = false;
    //     form.panNumber = "";
    //     form.panNumberVerified = false;
    //     form.companyName = "";
    //     this.setState({
    //       invalidPanNumber: false,
    //       errors,
    //       form,
    //     });
    //   }
    // }

    if (name === "panNumber" && errors.panNumber == false) {
      var pan_value = value.toUpperCase();
      var reg =
        /([a-zA-Z]{3})([PCHFATBLJGE]{1})([a-zA-Z]{1})([0-9]{4})([a-zA-Z]{1})/;

      if (pan_value.match(reg)) {
        //this.getGstList();
        this.setState({
          invalidPanNumber: false,
        });
      } else {
        if (pan_value != "")
          this.setState({
            invalidPanNumber: true,
          });
      }
    }

    if (
      name === "drivingLicenceNumber" &&
      errors.drivingLicenceNumber == false
    ) {
      var pan_value = value.toUpperCase();
      var reg = /([a-zA-Z]{2})([0-9]{13})/;

      if (pan_value.match(reg)) {
        this.setState({
          invalidDrivingNumber: false,
        });
      } else {
        if (pan_value != "")
          this.setState({
            invalidDrivingNumber: true,
          });
      }
    }

    if (name === "passportFileNumber" && errors.passportFileNumber == false) {
      var pan_value = value.toUpperCase();
      var reg = /([a-zA-Z]{2})([0-9]{13})/;

      if (pan_value.match(reg)) {
        this.setState({
          invalidpassportFileNumber: false,
        });
      } else {
        if (pan_value != "")
          this.setState({
            invalidpassportFileNumber: true,
          });
      }
    }
    if (name === "voterIdNumber") {
      var pan_value = value.toUpperCase();
      var reg = /([a-zA-Z]{3})([0-9]{7})/;

      if (pan_value.match(reg)) {
        this.setState({
          invalidvoterIdNumber: false,
        });
      } else {
        if (pan_value != "")
          this.setState({
            invalidvoterIdNumber: true,
          });
      }
    }

    if (name === "permanantAddress") {
      if (value && value.length > 700) {
        this.setState({
          permanantAddressError: true,
        });
      }
    }

    if (name == "accountNumber") {
      if (
        (e.target.value.trim().length == 9 ||
          e.target.value.trim().length > 9) &&
        (e.target.value.trim().length == 16 ||
          e.target.value.trim().length < 16)
      ) {
        errors.validAccountNumber = false;
        this.setState({ errors });
      } else {
        errors.validAccountNumber = true;
        this.setState({ errors });
      }
    }

    if (name == "ifscCode") {
      let verifyIfsc = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/;
      if (verifyIfsc.test(value)) {
        errors.isValidIfsc = false;
        this.setState({ errors });
      } else {
        errors.isValidIfsc = true;
        this.setState({ errors });
      }
    }

    /* if (name == "panNumber" && form.panNumberVerified == true) {
            form.companyName = "";
            form.panNumberVerified = false;
            this.setState({ form });
        } */

    /*if (
      (name == "accountNumber" || name == "ifscCode") &&
      form.bankVerified == true
    ) {
      form.bankVerified = false;
      this.setState({ form });
    }*/
  };

  handleZone = (zone) => {
    let { form, zoneDropdownValue, userZone } = this.state;

    let zonearray = [];

    zone && zonearray.push(zone);
    // if (zonearray && zonearray.length != 0) {
    //   this.checkVillagePresent(zonearray);
    // }

    this.setState({ userZone: zonearray[0], key: Math.random() });
    this.clearAll();
  };

  clearAll = () => {
    let { form } = this.state;
    form.location = "";
    this.setState({ form });
  };

  onDrop = async (file, picture, name) => {
    let { errors } = this.state;
    console.log("file", file);
    console.log("picture", picture.length);
    console.log("name", name);
    if (mandatoryDoc.includes(name)) {
      if (picture.length > 0) {
        errors = { ...errors, [name]: false };
      } else {
        errors = { ...errors, [name]: true };
      }
    }
    this.setState({ [name]: file, errors: errors });
  };

  handleSubmit = () => {
    let { form, errors, userZone } = this.state;
    this.setState({ loading: true });
    let obj = cloneDeep(form);
    let userData = JSON.parse(localStorage.getItem("userData"));
    console.log("userdata", userData);
    let village = [];
    userZone &&
      userZone.map((val) => {
        village.push({
          employeeId: userData && userData.adminEmployeeId,
          villageCode: val.villageCode,
          id: val.id,
        });
      });

    obj.village = village;

    //save and update api franchise data
    console.log("isAdd", obj);
    obj.registeredAddress.city = obj?.registeredAddress?.city?.value;
    obj.communicationAddress.city = obj?.communicationAddress?.city?.value;
    saveOrUpdatefranchise(obj).then((res) => {
      if (res.error) {
        this.setState({ loading: false });

        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        console.log("res.data", res.data);
        this.ImageConverToZipAndUpload(res.data.data.applicationNumber);
      }
    });

    //get franchise list created
  };

  ImageConverToZipAndUpload = (uniqueId) => {
    let {
      demandPromissoryNote,
      appraiserAppointment,
      aggrement,
      payoutdoc,
      dpnLetter,
      chequeGaurantee,
      passportPhoto,
      resume,
      shopRegistration,
      kycDocument,
      substituteAppraiser,
      bmInterviewSheet,
      form60,
      otherDocument1,
      otherDocument2,
      otherDocument3,
      otherDocument4,
    } = this.state;

    var zip = new JSZip();
    let documentObj = {
      demandPromissoryNote: demandPromissoryNote,
      appraiserAppointment: appraiserAppointment,
      aggrement: aggrement,
      payoutdoc: payoutdoc,
      dpnLetter: dpnLetter,
      chequeGaurantee: chequeGaurantee,
      passportPhoto: passportPhoto,
      resume: resume,
      shopRegistration: shopRegistration,
      kycDocument: kycDocument,
      substituteAppraiser: substituteAppraiser,
      bmInterviewSheet: bmInterviewSheet,
      form60: form60,
      otherDocument1: otherDocument1,
      otherDocument2: otherDocument2,
      otherDocument3: otherDocument3,
      otherDocument4: otherDocument4,
    };

    let keys = Object.keys(documentObj);
    console.log("keys", keys);

    let userDoc = [];
    keys.map((key, index) => {
      console.log("key", key);
      documentObj[key].map((res, i) => {
        console.log("response", res, "i", i);

        userDoc.push({
          documentName: res.name,
          description: key,
          applicationNumber: uniqueId,
        });
        zip.file(res.name, res, { binary: true });
      });
    });
    let _this = this;
    zip.generateAsync({ type: "blob" }).then(function (content) {
      const formData = new FormData();
      formData.append("file", content, `${uniqueId}.zip`);
      formData.append("franchiseDocs", JSON.stringify(userDoc));
      uploadFranchiseAppraiserDoc(formData).then((response) => {
        _this.setState({ loading: false });
        if (response.error) {
          return;
        }
        if (response.data.error) {
          toast.error(response.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 4000,
          });
          return;
        } else {
          console.log("response.data", response.data);
          _this.setState({ show: false });
          _this.props.history.push("/dashboard");
          toast.success("Franchise On-boarding Added Successfully", {
            type: toast.TYPE.SUCCESS,
            autoClose: 4000,
          });
        }
      });
    });
  };

  handleVerifyPan = () => {
    let { form } = this.state;
    this.setState({ isVerifyingPan: true });
    let data = {
      consent: "Y",
      pan: form.panNumber,
    };
    pan(data).then((res) => {
      this.setState({ isVerifyingPan: false });
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        console.log("res.data", res.data.panHolderName);
        if (res.data && res.data.panHolderName !== "") {
          form.companyName = res.data.panHolderName;
          form.companyPanNumberVerified = true;
          this.setState({ form });
        }
      }
    });
  };
  handlebureauscore = (e, name) => {
    let { form } = this.state;
    form[name] = e.target.value;
    this.setState({ form });
  };
  handleBankVerification = () => {
    let { form } = this.state;
    this.setState({ verifyingBank: true });
    let data = {
      consent: "Y",
      ifsc: form.ifscCode,
      accountNumber: form.accountNumber,
    }

    verifyBankAccountDetails(data).then((res) => {
      this.setState({ verifyingBank: false });
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        console.log("res.data", res.data);

        if (res?.data?.bankTxnStatus) {
        form.bankVerified = true;
        this.setState({ form });
      }else {
        toast.error("Bank Account Not Verified, please check entered details once!")
      }
      }
    });
  };

  render() {
    // let { isDedupe, showInner, show, loading, errors, form, invalidPanNumber, isVerifyingPan, key, verifyingBank, branchList } = this.state;
    let {
      isDedupe,
      isEmailPresent,
      isMobileNumberPresent,
      showInner,
      show,
      loading,
      errors,
      isEnable,
      isActive,
      form,
      isDis,
      selectedOption,
      gstList,
      typeofcompanyList,
      invalidPanNumber,
      isVerifyingPan,
      verifyingDriving,
      verifyingvoterIdNumber,
      invalidDrivingNumber,
      invalidvoterIdNumber,
      isVerifyingVoterId,
      verifyingPassport,
      key,
      invalidpassportFileNumber,
      verifyingBank,
      securityDeposit,
      villagePresent,
      branchList,
      zoneDropdownValue,
      userZone,
      cities,
      comCities,
      selectedCity,
      selectedState,
      selectedBranch,
    } = this.state;
    console.log("zz", zoneDropdownValue);
    console.log("loc", form.location);
    console.log("zonee", userZone);

    let { registeredAddress, communicationAddress } = form;
    let registeredAddressMandatoryField = false;

    registeredAddress &&
      Object.keys(registeredAddress).map((name, index) => {
        if (
          (registeredAddress[name] == "" ||
            registeredAddress[name] == null ||
            registeredAddress[name] == undefined) &&
          (name == "address" ||
            name == "branch" ||
            // name == "emailId" ||
            name == "mobileNumber" ||
            name == "contactName" ||
            name == "pinCode")
        ) {
          registeredAddressMandatoryField = true;
        }
      });

    let zoneobj = [];
    zoneDropdownValue.map((res) => {
      userZone &&
        userZone.map((val) => {
          if (res.villageCode === val.villageCode && res.id === val.id) {
            zoneobj.push(res);
          }
        });
    });

    console.log("ab", zoneobj);
    let disabledSave = false;
    form &&
      Object.keys(form).map((eachKey, index) => {
        if (typeof errors[eachKey] == "object") {
          // console.log("sadsada", form[eachKey],)
          form[eachKey] &&
            Object.keys(form[eachKey]).map((name, index) => {
              if (
                (form[eachKey][name] == "" ||
                  form[eachKey][name] == null ||
                  form[eachKey][name] == undefined) &&
                (name == "address" ||
                  name == "branch" ||
                  // name == "emailId" ||
                  name == "mobileNumber" ||
                  name == "contactName" ||
                  name == "pinCode" ||
                  name == "location")
              ) {
                disabledSave = true;
              }
            });
        } else {
          if (
            (form[eachKey] == "" ||
              form[eachKey] == null ||
              form[eachKey] == undefined) &&
            mandatoryField.includes(eachKey)
          ) {
            // console.log("disabledSave 2")
            disabledSave = true;
          }
        }
      });

    mandatoryDoc.map((docname, index) => {
      console.log(
        "this.state[docname]",
        docname,
        this.state[docname] && this.state[docname].length < 1
      );
      if (this.state[docname] && this.state[docname].length < 1) {
        // console.log("disabledSave 3")
        disabledSave = true;
      }
    });
    if (form.bureauscore == "" && form.isVerifyingbureau == false) {
      disabledSave = true;
    }
    // if (form && form.bankVerified == false) {
    //   // console.log("disabledSave 4")
    //   disabledSave = true;
    // }

    if (
      form &&
      form.panNumber != "" &&
      form &&
      form.companyPanNumberVerified == false
    ) {
      disabledSave = true;
    }

    if (
      form &&
      form.drivingLicenceNumber != "" &&
      form &&
      form.drivingLicenceNumberVerified == false
    ) {
      disabledSave = true;
    }

    if (
      form &&
      form.passportFileNumber != "" &&
      form &&
      form.passportNumberVerified == false
    ) {
      disabledSave = true;
    }

    if (
      form &&
      form.voterIdNumber != "" &&
      form &&
      form.voterIdVerified == false
    ) {
      disabledSave = true;
    }

    // if (
    //   form &&
    //   form.accountNumber != "" &&
    //   form &&
    //   form.bankVerified == false
    // ) {
    //   disabledSave = true;
    // }
    console.log("disabledSave", disabledSave);

    if (
      errors.registeredAddress &&
      errors.registeredAddress.emailId == false &&
      errors.communicationAddress &&
      errors.communicationAddress.emailId == false
    ) {
      isEmailPresent = false;
    }

    if (
      errors.registeredAddress &&
      errors.registeredAddress.mobileNumber == false &&
      errors.communicationAddress &&
      errors.communicationAddress.mobileNumber == false
    ) {
      isMobileNumberPresent = false;
    }

    // if (userZone.length != zoneobj) {
    //   form.location = "";
    // }

    return (
      <>
        <div className="disable-div">
          <div className="disable-div"></div>
          <FullScreenLoader show={false} />
          <ToastContainer />
          <Modal
            className="add-user"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show && show}
            id="gold-appraiser"
            onHide={() => {
              this.close();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Franchise appraiser master form</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`p-4 `} style={{ background: "#f8f9fa" }}>
              <div className="row">
                <section className="tab-body mt-3 mb-3">
                  <div className="row">
                    <h5 className="col-md-12 text-center mb-4">
                      Company Details
                    </h5>
                    <div className="col-md-4">
                      <label>
                        Type Of Franchise {<i className="text-danger">*</i>}
                      </label>
                      <Select
                        defaultValue={
                          typeofcompanyList &&
                          typeofcompanyList.length > 0 &&
                          typeofcompanyList.filter(
                            ({ companytype }) =>
                              companytype == form.typeOfCompany
                          )[0]
                        }
                        onChange={(e, name, value) => {
                          this.handleCompanySelectChange(e, "typeOfCompany");
                        }}
                        onBlur={(e) =>
                          this.onValidateCompany(e, "typeOfCompany")
                        }
                        options={typeofcompanyList}
                        name="typeOfCompany"
                        placeholder="Please Select Type of Franchise"
                        valueKey="companytype"
                        labelKey="companydesc"
                        getOptionLabel={(option) => option["companydesc"]}
                        getOptionValue={(option) => option["companytype"]}
                        className={`${
                          errors.typeOfCompany ? "error-input-border" : ""
                        } `}
                        key={key}
                        isDisabled={isVerifyingPan}
                      />
                      {errors.typeOfCompany && (
                        <span className="errorMsg">
                          Please Select Type of Company
                        </span>
                      )}
                    </div>
                    <div className="form-group col-md-4 ">
                      <label>Franchise PAN Number</label>
                      <div className="d-flex">
                        <div class="inner-addon right-addon flex-grow-1">
                          <input
                            type="text"
                            pattern="\d*"
                            maxLength="10"
                            name="panNumber"
                            value={form.panNumber}
                            onChange={(e) => {
                              let regex =
                                /[\!\\/@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                              if (e.target.value.match(regex) == null)
                                this.handlePan(e);
                            }}
                            onBlur={(e) => this.handleValidate(e, "panNumber")}
                            placeholder="Franchise PAN Number"
                            className={`form-input ${
                              errors.PanNumberError || invalidPanNumber
                                ? "error-input-border"
                                : ""
                            } `}
                            disabled={
                              (form.companyPanNumberVerified &&
                                form.companyPanNumberVerified == true) ||
                              form.typeOfCompany == "" ||
                              isVerifyingPan
                            }
                          />

                          {!errors.PanNumberError && invalidPanNumber && (
                            <span className="errorMsg">
                              Please enter valid PAN
                            </span>
                          )}
                        </div>
                        <div className="pl-2 ">
                          <button
                            type="button"
                            disabled={
                              form.panAvailability == "" ||
                              form.panNumber == "" ||
                              isVerifyingPan ||
                              (!errors.PanNumberError && invalidPanNumber) ||
                              form.companyPanNumberVerified
                            }
                            class="btn btn-primary btn-sm"
                            style={{ width: 100 }}
                            onClick={this.handleVerifyPan}
                          >
                            {isVerifyingPan ? (
                              <React.Fragment>
                                <i class="fa fa-spinner fa-spin"></i>
                                Verifying...
                              </React.Fragment>
                            ) : form && form.companyPanNumberVerified ? (
                              "Verified"
                            ) : (
                              "Verify"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group overflow-visible col-md-4">
                      <label> {"Date Of Establishment"}</label>
                      <div class="inner-addon right-addon">
                        <DatePicker
                          selected={form && form.dateOfEstablishment}
                          onBlur={(e) =>
                            this.handleValidate(e, "dateOfEstablishment")
                          }
                          onChange={this.handleDateOfEstablishment}
                          dateFormat="dd-MM-yyyy"
                          placeholderText="DD-MM-YYYY"
                          showYearDropdown
                          showMonthDropdown
                          className={`form-input ${
                            errors.dateOfEstablishment
                              ? "error-input-border"
                              : ""
                          } `}
                          disabled={false}
                          maxDate={new Date()}
                        />
                      </div>
                      {errors.dateOfEstablishment && (
                        <span className="errorMsg">
                          Please Select Date Of Establishment
                        </span>
                      )}
                    </div>
                    <div className="form-group col-md-4 ">
                      <label>Franchise Name</label>
                      <div class="inner-addon right-addon">
                        <textarea
                          type="text"
                          pattern="\d*"
                          maxLength="200"
                          className="form-input"
                          name="companyName"
                          value={form.companyName}
                          onChange={(e) => {
                            this.handleChange(e, "companyName");
                          }}
                          onBlur={(e) => this.handleValidate(e, "companyName")}
                          placeholder="Enter Franchise / Shop name"
                          disabled={false}
                          className={`form-input ${
                            errors.companyName ? "error-input-border" : ""
                          } `}
                          autocomplete="false"
                        />

                        {errors.companyName && (
                          <span className="errorMsg">
                            Please Enter Company/Shop name
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="form-group col-md-4 ">
                      <label>
                        DSA Code/Franchise Code/Vendor{" "}
                        <i className="text-danger">*</i>
                      </label>
                      <div class="inner-addon right-addon">
                        <input
                          type="text"
                          pattern="\d*"
                          name="franchiseCode"
                          value={form.franchiseCode}
                          maxLength="20"
                          onChange={(e) => {
                            let regex =
                              /[\/\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\,\?\_\-]/g;
                            if (!regex.test(e.target.value))
                              this.handleRadioChange(e, "franchiseCode");
                          }}
                          onBlur={(e) =>
                            this.handleValidate(e, "franchiseCode")
                          }
                          placeholder="Franchise code"
                          className={`form-input ${
                            errors.franchiseCode ? "error-input-border" : ""
                          } `}
                          disabled={false}
                        />
                        {errors.franchiseCode && (
                          <span className="errorMsg">
                            {errors.franchiseCode == true
                              ? "Please Enter Franchise code"
                              : errors.franchiseCode}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="form-group col-md-4  ">
                      <label className="">
                        Bureau Score<i className="text-danger">*</i>
                      </label>
                      <div class="inner-addon right-addon">
                        {/* {mobileVerifyLoading && (
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    )} */}
                        <input
                          type="text"
                          pattern="\d*"
                          maxLength="3"
                          className="form-input"
                          name="bureauscore"
                          value={form.bureauscore}
                          onKeyPress={this.restrictAlphabets}
                          onChange={(e) => {
                            this.handlebureauscore(e, "bureauscore");
                          }}
                          onBlur={(e) =>
                            this.handleValidatebureauscore(e, "bureauscore")
                          }
                          placeholder="Enter Bureau"
                          className={`form-input ${
                            errors.bureauscore ? "error-input-border" : ""
                          } `}
                          disabled={false}
                        />
                        {errors.bureauscore && (
                          <span className="errorMsg">
                            {errors.bureauscore == true
                              ? "Please Enter Bureau Code In Range"
                              : errors.bureauscore}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4 ">
                      <div class="inner-addon right-addon">
                        <label>
                          <input
                            type="checkbox"
                            value={form.doYouHaveGST}
                            checked={form.doYouHaveGST}
                            onChange={(event) => this.handleCheck(event)}
                          />
                          Do you have GST number
                        </label>
                        {form && form.doYouHaveGST == true ? (
                          <Select
                            defaultValue={
                              form &&
                              gstList &&
                              gstList.length > 0 &&
                              form.gstNumber &&
                              gstList.filter(
                                ({ gstinId }) => gstinId == form.gstNumber
                              )[0]
                            }
                            onChange={(e) => {
                              this.handleGstChange(e, "gstNumber");
                            }}
                            onBlur={(e) => this.handleValidate(e, "gstNumber")}
                            // isMulti
                            options={gstList}
                            name="gstNumber"
                            placeholder="Enter Gst Number"
                            valueKey="gstinId"
                            labelKey="gstinId"
                            getOptionLabel={(option) => option["gstinId"]}
                            getOptionValue={(option) => option["gstinId"]}
                            className={`${
                              errors.gstNumber ? "error-input-border" : ""
                            } `}
                            key={key}
                          />
                        ) : null}
                      </div>
                    </div>
                    {/* new */}
                    <div className=" col-md-4 ">
                      <label>
                        Village <i className="text-danger">*</i>
                      </label>
                      <Select
                        value={zoneobj}
                        onChange={this.handleZone}
                        // onBlur={(e) => this.onValidate(e, "zone")}
                        name="zoneobj"
                        options={zoneDropdownValue}
                        placeholder="Select village"
                        valueKey="villageCode"
                        labelKey="villageName"
                        getOptionLabel={(option) => option.villageName}
                        getOptionValue={(option) => option.villageCode}
                        isClearable={false}
                        // className={"error-input-border"}
                        isDisabled={false}
                        isMulti={true}
                      />
                      {/* {errors.zone && (
                      <span className="errorMsg">Please Select Zone</span>
                    )} */}
                    </div>
                    {/* end */}

                    {/* location start */}
                    <div className=" col-md-4 ">
                      <label>
                        Location<i className="text-danger">*</i>
                      </label>
                      <Select
                        defaultValue={
                          userZone &&
                          userZone.length > 0 &&
                          userZone.filter(
                            ({ villageName }) => villageName == form.location
                          )[0]
                        }
                        onChange={(e, name, value) => {
                          this.handleLocationSelectChange(e, "location");
                        }}
                        onBlur={(e) => this.onValidateLocation(e, "location")}
                        options={userZone}
                        name="location"
                        placeholder="Please Select Location"
                        valueKey="villageName"
                        labelKey="villageCode"
                        getOptionLabel={(option) => option["villageCode"]}
                        getOptionValue={(option) => option["villageName"]}
                        className={`${
                          errors.location ? "error-input-border" : ""
                        } `}
                        // key={key}
                        isDisabled={false}
                      />
                      {errors.location && (
                        <span className="errorMsg">Please Select Location</span>
                      )}
                    </div>
                    {/* location end */}
                    <div className="form-group overflow-visible col-md-4">
                      <label> {"Date Of Birth"}</label>
                      <div class="inner-addon right-addon">
                        <DatePicker
                          selected={form.drivingLicenceDateOfBirth}
                          onBlur={(e) =>
                            this.handleValidate(e, "drivingLicenceDateOfBirth")
                          }
                          onChange={this.handledrivingLicenceDateOfBirth}
                          dateFormat="dd-MM-yyyy"
                          placeholderText="DD-MM-YYYYY (Driving)"
                          showYearDropdown
                          showMonthDropdown
                          className={`form-input ${
                            errors.drivingLicenceDateOfBirth
                              ? "error-input-border"
                              : ""
                          } `}
                          disabled={
                            (form.drivingLicenceNumberVerified &&
                              form.drivingLicenceNumberVerified == true) ||
                            verifyingDriving
                          }
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-4 ">
                      <label>Driving Licence Number</label>
                      <div className="d-flex">
                        <div class="inner-addon right-addon flex-grow-1">
                          <input
                            type="text"
                            // pattern="\d*"
                            maxLength="16"
                            name="drivingLicenceNumber"
                            value={form.drivingLicenceNumber}
                            onChange={(e) => {
                              let regex =
                                /[\!\\/@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                              if (e.target.value.match(regex) == null)
                                this.handleDrive(e);
                            }}
                            onBlur={(e) =>
                              this.handleValidate(e, "drivingLicenceNumber")
                            }
                            placeholder="Driving Licence Number"
                            className={`form-input ${
                              errors.DrivingError || invalidDrivingNumber
                                ? "error-input-border"
                                : ""
                            } `}
                            disabled={
                              (form.drivingLicenceNumberVerified &&
                                form.drivingLicenceNumberVerified == true) ||
                              verifyingDriving
                            }
                          />

                          {!errors.DrivingError && invalidDrivingNumber && (
                            <span className="errorMsg">
                              Please enter valid Driving Licence Number
                            </span>
                          )}
                        </div>
                        <div className="pl-2 ">
                          <button
                            type="button"
                            disabled={
                              (form && form.drivingLicenceDateOfBirth == "") ||
                              form.drivingLicenceDateOfBirth == null ||
                              form.drivingLicenceNumber == "" ||
                              form.drivingLicenceNumber == null ||
                              verifyingDriving ||
                              form.drivingLicenceNumberVerified ||
                              invalidDrivingNumber
                            }
                            class="btn btn-primary btn-sm"
                            style={{ width: 100 }}
                            onClick={this.handleDrivingVerification}
                          >
                            {verifyingDriving ? (
                              <React.Fragment>
                                <i class="fa fa-spinner fa-spin"></i>
                                Verifying...
                              </React.Fragment>
                            ) : form.drivingLicenceNumberVerified ? (
                              "Verified"
                            ) : (
                              "Verify"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-md-4 ">
                      <label>Voter ID Number</label>
                      <div className="d-flex">
                        <div class="inner-addon right-addon flex-grow-1">
                          <input
                            type="text"
                            // pattern="\d*"
                            maxLength="10"
                            name="voterIdNumber"
                            value={form.voterIdNumber}
                            onChange={(e) => {
                              let regex =
                                /[\!\\/@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                              if (e.target.value.match(regex) == null)
                                this.handleEpicNo(e);
                            }}
                            onBlur={(e) =>
                              this.handleValidate(e, "voterIdNumber")
                            }
                            placeholder="Voter ID Number"
                            className={`form-input ${
                              errors.EpicNoError || invalidvoterIdNumber
                                ? "error-input-border"
                                : ""
                            } `}
                            disabled={
                              (form.voterIdVerified &&
                                form.voterIdVerified == true) ||
                              verifyingvoterIdNumber
                            }
                          />

                          {!errors.EpicNoError && invalidvoterIdNumber && (
                            <span className="errorMsg">
                              Please enter Valid Voter ID Number
                            </span>
                          )}
                        </div>
                        <div className="pl-2 ">
                          <button
                            type="button"
                            disabled={
                              (form && form.voterIdNumber == "") ||
                              verifyingvoterIdNumber ||
                              (form && form.voterIdVerified) ||
                              (!errors.EpicNoError && invalidvoterIdNumber)
                            }
                            class="btn btn-primary btn-sm"
                            style={{ width: 100 }}
                            onClick={this.handleVoterIdVerification}
                          >
                            {verifyingvoterIdNumber ? (
                              <React.Fragment>
                                <i class="fa fa-spinner fa-spin"></i>
                                Verifying...
                              </React.Fragment>
                            ) : form.voterIdVerified ? (
                              "Verified"
                            ) : (
                              "Verify"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group overflow-visible col-md-4">
                      <label> {"Date Of Birth"}</label>
                      <div class="inner-addon right-addon">
                        <DatePicker
                          selected={form.passportDateOfBirth}
                          onBlur={(e) =>
                            this.handleValidate(e, "passportDateOfBirth")
                          }
                          onChange={this.handlepassportDateOfBirth}
                          dateFormat="dd-MM-yyyy"
                          placeholderText="DD-MM-YYYY (Passport)"
                          showYearDropdown
                          showMonthDropdown
                          className={`form-input ${
                            errors.passportDateOfBirth
                              ? "error-input-border"
                              : ""
                          } `}
                          disabled={
                            (form.passportNumberVerified &&
                              form.passportNumberVerified == true) ||
                            verifyingPassport
                          }
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-4 ">
                      <label>Passport File Number</label>
                      <div className="d-flex">
                        <div class="inner-addon right-addon flex-grow-1">
                          <input
                            type="text"
                            // pattern="\d*"
                            maxLength="15"
                            name="passportFileNumber"
                            value={form.passportFileNumber}
                            onChange={(e) => {
                              let regex =
                                /[\!\\/@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                              if (e.target.value.match(regex) == null)
                                this.handlePassport(e);
                            }}
                            onBlur={(e) =>
                              this.handleValidate(e, "passportFileNumber")
                            }
                            placeholder="Passport File Number"
                            className={`form-input ${
                              errors.PassportError || invalidpassportFileNumber
                                ? "error-input-border"
                                : ""
                            } `}
                            disabled={
                              (form.passportNumberVerified &&
                                form.passportNumberVerified == true) ||
                              verifyingPassport
                            }
                          />

                          {!errors.PassportError &&
                            invalidpassportFileNumber && (
                              <span className="errorMsg">
                                Please enter valid Passport File Number
                              </span>
                            )}
                        </div>
                        <div className="pl-2 ">
                          <button
                            type="button"
                            disabled={
                              (form && form.passportDateOfBirth == "") ||
                              form.passportDateOfBirth == null ||
                              form.passportFileNumber == "" ||
                              form.passportFileNumber == null ||
                              verifyingPassport ||
                              form.passportNumberVerified ||
                              invalidpassportFileNumber
                            }
                            class="btn btn-primary btn-sm"
                            style={{ width: 100 }}
                            onClick={this.handlePassportVerification}
                          >
                            {verifyingPassport ? (
                              <React.Fragment>
                                <i class="fa fa-spinner fa-spin"></i>
                                Verifying...
                              </React.Fragment>
                            ) : form.passportNumberVerified ? (
                              "Verified"
                            ) : (
                              "Verify"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-md-4 ">
                      <label>
                        Security Deposit
                        <i className="text-danger">*</i>
                      </label>
                      <div className="d-flex">
                        <div class="inner-addon right-addon flex-grow-1">
                          <input
                            type="text"
                            pattern="\d*"
                            minLength="4"
                            maxLength="7"
                            onKeyPress={this.restrictAlphabets}
                            name="securityDeposit"
                            value={form.securityDeposit}
                            onChange={(e) => {
                              let regex =
                                /[\!\\/@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                              if (e.target.value.match(regex) == null)
                                this.handleSequrity(e);
                            }}
                            placeholder="Security Deposit Number"
                            className="form-input"
                            disabled={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* address section */}

                <section className="tab-body mt-3 mb-3">
                  <div className="row">
                    <h5 className="col-md-12 text-center mb-4">
                      Address Details
                    </h5>
                  </div>
                  <section className="tab-body mt-3 mb-3">
                    <div className="row">
                      <h5 className="col-md-12 text-center mb-4">
                        Registered Address
                      </h5>
                      <div className="form-group col-md-4 ">
                        <label>
                          Pin Code<i className="text-danger">*</i>
                        </label>
                        <input
                          type="text"
                          pattern="\d*"
                          maxLength="6"
                          className="form-input"
                          name="pinCode"
                          id="pincode"
                          value={
                            form &&
                            form.registeredAddress &&
                            form.registeredAddress.pinCode
                          }
                          onKeyPress={this.restrictAlphabets}
                          onChange={(e) => {
                            this.handleAddressChange(
                              e,
                              "pinCode",
                              "registeredAddress"
                            );
                          }}
                          onBlur={(e) =>
                            this.handleValidateAddressChange(
                              e,
                              "pinCode",
                              "registeredAddress"
                            )
                          }
                          placeholder="Enter Pin Code"
                          className={`form-input ${
                            errors.registeredAddress.pinCode
                              ? "error-input-border"
                              : ""
                          } `}
                          id="pincode"
                        />
                        {errors.registeredAddress.pinCode && (
                          <span className="errorMsg">Please Enter Pincode</span>
                        )}
                        {!errors.registeredAddress.pinCode &&
                          errors.registeredAddress.validPincode && (
                            <span className="errorMsg">
                              Please Enter Valid Pincode
                            </span>
                          )}
                      </div>
                      {/* <div className="form-group col-md-4">
                        <label>City</label>
                        <input
                          type="text"
                          pattern="\d*"
                          className="form-input"
                          name="city"
                          value={
                            form &&
                            form.registeredAddress &&
                            form.registeredAddress.city
                          }
                          placeholder="Enter City"
                          id="city"
                          disabled={true}
                        />
                      </div> */}
                      <div className=" col-md-4 ">
                        <label>
                          Select City <i className="text-danger">*</i>
                        </label>
                        <Select
                          id="city"
                          options={cities}
                          value={
                            form &&
                            form.registeredAddress &&
                            form.registeredAddress.pinCode < 6
                              ? ""
                              : form &&
                                form.registeredAddress &&
                                form.registeredAddress.city
                          }
                          onChange={(e) =>
                            this.handleCityChange(e, "registeredAddress")
                          }
                          placeholder="Select a city"
                          isDisabled={form.registeredAddress.pinCode < 6}
                        />
                        {/* {errors.zone && (
                      <span className="errorMsg">Please Select Zone</span>
                    )} */}
                      </div>
                      <div className="form-group col-md-4">
                        <label>State</label>
                        <input
                          type="text"
                          pattern="\d*"
                          className="form-input"
                          name="state"
                          value={
                            form &&
                            form.registeredAddress &&
                            form.registeredAddress.pinCode < 6
                              ? ""
                              : form &&
                                form.registeredAddress &&
                                form.registeredAddress.state
                          }
                          placeholder="Enter State"
                          className={`form-input ${
                            errors.registeredAddress.state
                              ? "error-input-border"
                              : ""
                          } `}
                          id="state"
                          disabled={true}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label>Branch</label>
                        <input
                          type="text"
                          pattern="\d*"
                          className="form-input"
                          name="branch"
                          value={
                            form &&
                            form.registeredAddress &&
                            form.registeredAddress.pinCode < 6
                              ? ""
                              : form &&
                                form.registeredAddress &&
                                form.registeredAddress.branch
                          }
                          placeholder="Enter Branch"
                          className={`form-input ${
                            errors.registeredAddress.branch
                              ? "error-input-border"
                              : ""
                          } `}
                          id="branch"
                          disabled={true}
                        />
                      </div>
                      {/*
                      <div className=" col-md-4">
                        <label>
                          Branch <i className="text-danger">*</i>
                        </label>
                        <Select
                          defaultValue={
                            form &&
                            form.registeredAddress &&
                            form.registeredAddress.branch &&
                            branchList &&
                            branchList.length > 0 &&
                            branchList.filter(
                              ({ branchCode }) =>
                                branchCode == form.registeredAddress.branch
                            )[0]
                          }
                          onChange={(e) => {
                            this.handlebranchSelectChange(
                              e,
                              "registeredAddress"
                            );
                          }}
                          onBlur={(e) =>
                            this.onValidatebranch(e, "registeredAddress")
                          }
                          // isMulti
                          options={branchList}
                          name="branch"
                          placeholder="Branch"
                          valueKey="branchCode"
                          labelKey="branchCode"
                          getOptionLabel={(option) => option["branchCode"]}
                          getOptionValue={(option) => option["branchCode"]}
                          className={`${
                            errors.registeredAddress.branch
                              ? "error-input-border"
                              : ""
                          } `}
                        />
                        {errors.registeredAddress.branch && (
                          <span className="errorMsg">Please select Branch</span>
                        )}
                        </div>*/}
                      <div className="form-group col-md-4 ">
                        <label>
                          Contact Name <i className="text-danger">*</i>
                        </label>
                        <input
                          name="contactName"
                          value={
                            form &&
                            form.registeredAddress &&
                            form.registeredAddress.contactName
                          }
                          type="text"
                          pattern="\d*"
                          maxLength="100"
                          className="form-input"
                          onChange={(e) => {
                            this.handleAddressChange(
                              e,
                              "contactName",
                              "registeredAddress"
                            );
                          }}
                          onBlur={(e) =>
                            this.handleValidateAddressChange(
                              e,
                              "contactName",
                              "registeredAddress"
                            )
                          }
                          placeholder="Enter Contact Name"
                          className={`form-input ${
                            errors.registeredAddress.contactName
                              ? "error-input-border"
                              : ""
                          } `}
                        />
                        {errors.registeredAddress.contactName && (
                          <span className="errorMsg">
                            Please enter Contact Name
                          </span>
                        )}
                      </div>

                      <div className="form-group col-md-4  ">
                        <label className="">
                          Mobile Number <i className="text-danger">*</i>
                        </label>
                        <div class="inner-addon right-addon">
                          {/* {mobileVerifyLoading && (
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    )} */}
                          <input
                            type="text"
                            pattern="\d*"
                            maxLength="10"
                            className="form-input"
                            name="mobileNumber"
                            value={
                              form &&
                              form.registeredAddress &&
                              form.registeredAddress.mobileNumber
                            }
                            onKeyPress={this.restrictAlphabets}
                            onChange={(e) => {
                              this.handleAddressChange(
                                e,
                                "mobileNumber",
                                "registeredAddress"
                              );
                            }}
                            onBlur={(e) =>
                              this.handleValidateAddressChange(
                                e,
                                "mobileNumber",
                                "registeredAddress"
                              )
                            }
                            placeholder="Enter Mobile Number"
                            className={`form-input ${
                              errors.registeredAddress.mobileNumber
                                ? "error-input-border"
                                : ""
                            } `}
                          />
                        </div>

                        {errors.registeredAddress.mobileNumber && (
                          <span className="errorMsg">
                            {errors.registeredAddress.mobileNumber == true
                              ? "Please enter Mobile Number"
                              : errors.registeredAddress.mobileNumber}
                          </span>
                        )}

                        {!errors.registeredAddress.mobileNumber &&
                          errors.registeredAddress.validMobile && (
                            <span className="errorMsg">
                              Please enter valid Mobile Number
                            </span>
                          )}
                      </div>
                      <div className="form-group col-md-4 ">
                        <label>Email ID</label>
                        <div class="inner-addon right-addon">
                          {/* {emailVerifyLoading && (
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    )} */}
                          <input
                            type="text"
                            pattern="\d*"
                            maxLength="50"
                            className="form-input"
                            name="emailId"
                            value={
                              form &&
                              form.registeredAddress &&
                              form.registeredAddress.emailId
                            }
                            onChange={(e) => {
                              this.handleAddressChange(
                                e,
                                "emailId",
                                "registeredAddress"
                              );
                            }}
                            onBlur={(e) =>
                              this.handleValidateAddressChange(
                                e,
                                "emailId",
                                "registeredAddress"
                              )
                            }
                            placeholder="Enter Email Address"
                            className={`form-input ${
                              errors.registeredAddress.emailId ||
                              errors.registeredAddress.validEmail
                                ? "error-input-border"
                                : ""
                            } `}
                          />
                        </div>
                        {errors.registeredAddress.emailId && (
                          <span className="errorMsg">
                            {errors.registeredAddress.emailId}
                          </span>
                        )}

                        {/* {errors.registeredAddress.emailId && (
                          <span className="errorMsg">
                            {errors.registeredAddress.emailId == true
                              ? "Please enter Email Address"
                              : errors.registeredAddress.emailId}
                          </span>
                        )} */}
                        {errors.registeredAddress.validEmail && (
                          <span className="errorMsg">
                            Please enter valid Email Address
                          </span>
                        )}
                      </div>
                      <div className="form-group col-md-4">
                        <label>
                          Address <i className="text-danger">*</i>
                        </label>
                        <div class="inner-addon right-addon">
                          <textarea
                            type="text"
                            pattern="\d*"
                            maxLength="200"
                            className="form-input"
                            name="address"
                            value={
                              form &&
                              form.registeredAddress &&
                              form.registeredAddress.address
                            }
                            onChange={(e) => {
                              this.handleAddressChange(
                                e,
                                "address",
                                "registeredAddress"
                              );
                            }}
                            onBlur={(e) =>
                              this.handleValidateAddressChange(
                                e,
                                "address",
                                "registeredAddress"
                              )
                            }
                            placeholder="Enter Address"
                            disabled={false}
                            className={`form-input ${
                              errors.registeredAddress &&
                              errors.registeredAddress.address
                                ? "error-input-border"
                                : ""
                            } `}
                            autocomplete="false"
                          />

                          {errors.registeredAddress &&
                            errors.registeredAddress.address && (
                              <span className="errorMsg">
                                Please Enter Address
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                  </section>
                  {/* communication address */}
                  <section className="tab-body mt-3 mb-3">
                    <div className="row">
                      <h5 className="col-md-12 text-center mb-4">
                        Communication Address
                      </h5>
                      <div className="form-group col-md-4 ">
                        <label>
                          Pin Code <i className="text-danger">*</i>
                        </label>
                        <input
                          type="text"
                          pattern="\d*"
                          maxLength="6"
                          className="form-input"
                          name="pinCode"
                          value={
                            form.isregisteraddsameascommddress === true
                              ? form &&
                                form.registeredAddress &&
                                form.registeredAddress.pinCode
                              : form &&
                                communicationAddress &&
                                communicationAddress.pinCode
                          }
                          onKeyPress={this.restrictAlphabets}
                          onChange={(e) => {
                            this.handleAddressChange(
                              e,
                              "pinCode",
                              "communicationAddress"
                            );
                          }}
                          onBlur={(e) =>
                            this.handleValidateAddressChange(
                              e,
                              "pinCode",
                              "communicationAddress"
                            )
                          }
                          placeholder="Enter Pin Code"
                          className={`form-input ${
                            errors.communicationAddress.pinCode
                              ? "error-input-border"
                              : ""
                          } `}
                          id="pincode"
                          disabled={form.isregisteraddsameascommddress}
                        />
                        {errors.communicationAddress.pinCode && (
                          <span className="errorMsg">Please Enter Pincode</span>
                        )}
                        {!errors.communicationAddress.pinCode &&
                          errors.communicationAddress.validPincode && (
                            <span className="errorMsg">
                              Please Enter Valid Pincode
                            </span>
                          )}
                      </div>
                      {/* <div className="form-group col-md-4">
                        <label>City</label>
                        <input
                          type="text"
                          pattern="\d*"
                          className="form-input"
                          name="city"
                          value={
                            form &&
                            communicationAddress &&
                            communicationAddress.city
                          }
                          placeholder="Enter City"
                          id="city"
                          disabled={true || form.isregisteraddsameascommddress}
                        />
                      </div> */}

                      <div className=" col-md-4 ">
                        <label>
                          Select City <i className="text-danger">*</i>
                        </label>
                        <Select
                          id="city"
                          options={comCities}
                          value={
                            form.isregisteraddsameascommddress === true
                              ? form &&
                                form.registeredAddress &&
                                form.registeredAddress.city
                              : form &&
                                form.communicationAddress &&
                                form.communicationAddress.city
                          }
                          onChange={(e) =>
                            this.handleCityChange(e, "communicationAddress")
                          }
                          placeholder="Select a city"
                          isDisabled={
                            form.communicationAddress.pinCode < 6 ||
                            form.isregisteraddsameascommddress
                          }
                        />

                        {/* {errors.zone && (
                      <span className="errorMsg">Please Select Zone</span>
                    )} */}
                      </div>

                      <div className="form-group col-md-4">
                        <label>State</label>
                        <input
                          type="text"
                          pattern="\d*"
                          className="form-input"
                          name="state"
                          value={
                            form.isregisteraddsameascommddress === true
                              ? form &&
                                form.registeredAddress &&
                                form.registeredAddress.state
                              : form &&
                                form.communicationAddress &&
                                form.communicationAddress.state
                          }
                          placeholder="Enter State"
                          className={`form-input ${
                            errors.communicationAddress.state
                              ? "error-input-border"
                              : ""
                          } `}
                          id="state"
                          disabled={true || form.isregisteraddsameascommddress}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label>Branch</label>
                        <input
                          type="text"
                          pattern="\d*"
                          className="form-input"
                          name="branch"
                          value={
                            form.isregisteraddsameascommddress === true
                              ? form &&
                                form.registeredAddress &&
                                form.registeredAddress.branch
                              : form &&
                                form.communicationAddress &&
                                form.communicationAddress.branch
                          }
                          placeholder="Enter Branch"
                          className={`form-input ${
                            errors.communicationAddress.branch
                              ? "error-input-border"
                              : ""
                          } `}
                          id="branch"
                          disabled={true || form.isregisteraddsameascommddress}
                        />
                      </div>
                      {/*  <div className=" col-md-4">
                        <label>
                          Branch <i className="text-danger">*</i>
                        </label>
                        <Select
                          defaultValue={
                            form &&
                            communicationAddress &&
                            communicationAddress.branch &&
                            branchList &&
                            branchList.length > 0 &&
                            branchList.filter(
                              ({ branchCode }) =>
                                branchCode == communicationAddress.branch
                            )[0]
                          }
                          onChange={(e) => {
                            this.handlebranchSelectChange(
                              e,
                              "communicationAddress"
                            );
                          }}
                          onBlur={(e) =>
                            this.onValidatebranch(e, "communicationAddress")
                          }
                          // isMulti
                          options={branchList}
                          name="branch"
                          placeholder="Branch"
                          valueKey="branchCode"
                          labelKey="branchCode"
                          getOptionLabel={(option) => option["branchCode"]}
                          getOptionValue={(option) => option["branchCode"]}
                          className={`${
                            errors.communicationAddress.branch
                              ? "error-input-border"
                              : ""
                          } `}
                          key={key}
                          isDisabled={form.isregisteraddsameascommddress}
                        />
                        {errors.communicationAddress.branch && (
                          <span className="errorMsg">Please select Branch</span>
                        )}
                        </div>*/}
                      <div className="form-group col-md-4 ">
                        <label>
                          Contact Name <i className="text-danger">*</i>
                        </label>
                        <input
                          type="text"
                          pattern="\d*"
                          maxLength="100"
                          className="form-input"
                          name="contactName"
                          value={
                            form &&
                            communicationAddress &&
                            communicationAddress.contactName
                          }
                          onChange={(e) => {
                            this.handleAddressChange(
                              e,
                              "contactName",
                              "communicationAddress"
                            );
                          }}
                          onBlur={(e) =>
                            this.handleValidateAddressChange(
                              e,
                              "contactName",
                              "communicationAddress"
                            )
                          }
                          placeholder="Enter Contact Name"
                          className={`form-input ${
                            errors.communicationAddress.contactName
                              ? "error-input-border"
                              : ""
                          } `}
                          disabled={form.isregisteraddsameascommddress}
                        />
                        {errors.communicationAddress.contactName && (
                          <span className="errorMsg">
                            Please enter Contact Name
                          </span>
                        )}
                      </div>

                      <div className="form-group col-md-4  ">
                        <label className="">
                          Mobile Number <i className="text-danger">*</i>
                        </label>
                        <div class="inner-addon right-addon">
                          {/* {mobileVerifyLoading && (
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    )} */}
                          <input
                            type="text"
                            pattern="\d*"
                            maxLength="10"
                            className="form-input"
                            name="mobileNumber"
                            value={
                              form &&
                              communicationAddress &&
                              communicationAddress.mobileNumber
                            }
                            onKeyPress={this.restrictAlphabets}
                            onChange={(e) => {
                              this.handleAddressChange(
                                e,
                                "mobileNumber",
                                "communicationAddress"
                              );
                            }}
                            onBlur={(e) =>
                              this.handleValidateAddressChange(
                                e,
                                "mobileNumber",
                                "communicationAddress"
                              )
                            }
                            placeholder="Enter Mobile Number"
                            className={`form-input ${
                              errors.communicationAddress.mobileNumber
                                ? "error-input-border"
                                : ""
                            } `}
                            disabled={form.isregisteraddsameascommddress}
                          />
                        </div>

                        {errors.communicationAddress.mobileNumber && (
                          <span className="errorMsg">
                            {errors.communicationAddress.mobileNumber == true
                              ? "Please enter Mobile Number"
                              : errors.communicationAddress.mobileNumber}
                          </span>
                        )}
                        {!errors.communicationAddress.mobileNumber &&
                          errors.communicationAddress.validMobile && (
                            <span className="errorMsg">
                              Please enter valid Mobile Number
                            </span>
                          )}
                      </div>
                      <div className="form-group col-md-4 ">
                        <label>Email ID</label>
                        <div class="inner-addon right-addon">
                          {/* {emailVerifyLoading && (
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    )} */}
                          <input
                            type="text"
                            pattern="\d*"
                            maxLength="50"
                            className="form-input"
                            name="emailId"
                            value={
                              form &&
                              communicationAddress &&
                              communicationAddress.emailId
                            }
                            onChange={(e) => {
                              this.handleAddressChange(
                                e,
                                "emailId",
                                "communicationAddress"
                              );
                            }}
                            onBlur={(e) =>
                              this.handleValidateAddressChange(
                                e,
                                "emailId",
                                "communicationAddress"
                              )
                            }
                            placeholder="Enter Email Address"
                            className={`form-input ${
                              errors.communicationAddress.emailId ||
                              errors.communicationAddress.validEmail
                                ? "error-input-border"
                                : ""
                            } `}
                            disabled={form.isregisteraddsameascommddress}
                          />
                        </div>
                        {/* {errors.communicationAddress.emailId && (
                          <span className="errorMsg">
                            Please enter Email Address
                          </span>
                        )} */}
                        {errors.communicationAddress.emailId && (
                          <span className="errorMsg">
                            {errors.communicationAddress.emailId}
                          </span>
                        )}
                        {/* {errors.communicationAddress.emailId && (
                          <span className="errorMsg">
                            {errors.communicationAddress.emailId == true
                              ? "Please enter Email Address"
                              : errors.communicationAddress.emailId}
                          </span>
                        )} */}
                        {errors.communicationAddress.validEmail && (
                          <span className="errorMsg">
                            Please enter valid Email Address
                          </span>
                        )}
                      </div>
                      <div className="form-group col-md-4">
                        <label>
                          Address <i className="text-danger">*</i>
                        </label>
                        <div class="inner-addon right-addon">
                          <textarea
                            type="text"
                            pattern="\d*"
                            maxLength="200"
                            className="form-input"
                            name="address"
                            value={
                              form &&
                              form.communicationAddress &&
                              form.communicationAddress.address
                            }
                            onChange={(e) => {
                              this.handleAddressChange(
                                e,
                                "address",
                                "communicationAddress"
                              );
                            }}
                            onBlur={(e) =>
                              this.handleValidateAddressChange(
                                e,
                                "address",
                                "communicationAddress"
                              )
                            }
                            placeholder="Enter Address"
                            disabled={form.isregisteraddsameascommddress}
                            className={`form-input ${
                              errors.communicationAddress &&
                              errors.communicationAddress.address
                                ? "error-input-border"
                                : ""
                            } `}
                            autocomplete="false"
                          />

                          {errors.communicationAddress &&
                            errors.communicationAddress.address && (
                              <span className="errorMsg">
                                Please Enter Address
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="form-group row col-md-4 mt-4">
                        <div className="col-2 align-center">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={form.isregisteraddsameascommddress}
                            checked={form.isregisteraddsameascommddress}
                            id="isregisteraddsameascommddress"
                            onChange={(e) => {
                              this.handleRadioChange(
                                e,
                                "isregisteraddsameascommddress"
                              );
                            }}
                            disabled={
                              registeredAddressMandatoryField ||
                              (form &&
                                form.registeredAddress &&
                                form.registeredAddress.address == "") ||
                              (form &&
                                form.registeredAddress &&
                                form.registeredAddress.branch == "") ||
                              // (form &&
                              //   form.registeredAddress &&
                              //   form.registeredAddress.emailId == "") ||
                              (form &&
                                form.registeredAddress &&
                                form.registeredAddress.mobileNumber == "") ||
                              (form &&
                                form.registeredAddress &&
                                form.registeredAddress.contactName == "") ||
                              (errors &&
                                errors.registeredAddress &&
                                errors.registeredAddress.emailId) ||
                              (errors &&
                                errors.registeredAddress &&
                                errors.registeredAddress.mobileNumber) ||
                              (errors &&
                                errors.communicationAddress &&
                                errors.communicationAddress.mobileNumber)
                            }
                          />
                        </div>
                        <div className="col-10 align-center">
                          <label
                            className="form-check-label"
                            for="isregisteraddsameascommddress"
                          >
                            Same as registered address
                          </label>
                        </div>
                      </div>
                    </div>
                  </section>
                </section>
                {/* bank section */}
                <section className="tab-body mt-3 mb-3">
                  <div className="row">
                    <h5 className="col-md-12 text-center mb-4">Bank Details</h5>
                    <div className="form-group col-md-4">
                      <label>
                        Bank Account Name <i className="text-danger">*</i>
                      </label>
                      <input
                        type="text"
                        pattern="\d*"
                        maxLength="100"
                        className="form-input"
                        name="bankAccountName"
                        value={form.bankAccountName}
                        onChange={(e) => {
                          this.handleRadioChange(e, "bankAccountName");
                        }}
                        onBlur={(e) =>
                          this.handleValidate(e, "bankAccountName")
                        }
                        placeholder="Enter Bank account name"
                        disabled={verifyingBank}
                      />
                      {errors.bankAccountName && (
                        <span className="errorMsg">
                          Please Enter Bank account name
                        </span>
                      )}
                    </div>

                    <div className="form-group col-md-4">
                      <label>
                        Bank Name <i className="text-danger">*</i>
                      </label>
                      <input
                        type="text"
                        pattern="\d*"
                        maxLength="100"
                        className="form-input"
                        name="bankName"
                        value={form.bankName}
                        onChange={(e) => {
                          this.handleRadioChange(e, "bankName");
                        }}
                        onBlur={(e) => this.handleValidate(e, "bankName")}
                        placeholder="Enter Bank Name"
                        disabled={verifyingBank}
                        // className={`form-input ${
                        //   errors.bankName ? "error-input-border" : ""
                        // } `}
                      />
                      {errors.bankName && (
                        <span className="errorMsg">Please enter Bank Name</span>
                      )}
                      {/* {!errors.bankName && errors.validBankNameError && (
                                                <span className="errorMsg">
                                                    Bank name should be in character.
                                                </span>
                                            )} */}
                    </div>

                    <div className="form-group col-md-4">
                      <label>
                        {" "}
                        Account Number <i className="text-danger">*</i>
                      </label>
                      <input
                        type="number"
                        pattern="\d*"
                        maxLength="16"
                        className="form-input"
                        name="accountNumber"
                        value={form.accountNumber}
                        onKeyPress={this.restrictAlphabets}
                        onChange={(e) => {
                          if (e.target.value.toString().length <= 16)
                            this.handleRadioChange(e, "accountNumber");
                        }}
                        onBlur={(e) => this.handleValidate(e, "accountNumber")}
                        placeholder="Enter Account Number"
                        className={`form-input ${
                          errors.accountNumber ? "error-input-border" : ""
                        } `}
                        disabled={verifyingBank}
                      />
                      {errors.accountNumber && (
                        <span className="errorMsg">
                          Please Enter Account Number
                        </span>
                      )}
                      {!errors.accountNumber && errors.validAccountNumber && (
                        <span className="errorMsg">
                          Account number should be between 9 to 16.
                        </span>
                      )}
                    </div>

                    <div className="form-group col-md-4">
                      <label>
                        IFSC Code <i className="text-danger">*</i>
                      </label>
                      <input
                        type="text"
                        pattern="\d*"
                        maxLength="11"
                        className="form-input"
                        name="ifscCode"
                        value={form.ifscCode}
                        onChange={(e) => {
                          let regex =
                            /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                          if (!regex.test(e.target.value))
                            this.handleRadioChange(e, "ifscCode");
                        }}
                        onBlur={(e) => this.handleValidate(e, "ifscCode")}
                        placeholder="Enter IFSC Code"
                        className={`form-input ${
                          errors.ifscCode ? "error-input-border" : ""
                        } `}
                        disabled={verifyingBank}
                      />
                      {errors.ifscCode && (
                        <span className="errorMsg">Please enter IFSC Code</span>
                      )}
                      {!errors.ifscCode && errors.isValidIfsc && (
                        <span className="errorMsg">
                          Please enter valid IFSC Code
                        </span>
                      )}
                    </div>
                    {
                      <div className="offset-md-4 offset-lg-4 col-md-4 col-lg-4 align-center-end mt-3">
                        <button
                          type="button"
                          disabled={
                            form.bankAccountName == "" ||
                            form.bankName == "" ||
                            form.accountNumber == "" ||
                            form.ifscCode == "" ||
                            verifyingBank ||
                            (!errors.ifscCode && errors.isValidIfsc) ||
                            (!errors.accountNumber &&
                              errors.validAccountNumber) ||
                            form.bankVerified
                          }
                          class="btn btn-primary btn-sm"
                          style={{ width: 100 }}
                          onClick={this.handleBankVerification}
                        >
                          {verifyingBank ? (
                            <React.Fragment>
                              <i class="fa fa-spinner fa-spin"></i>
                              Verifying...
                            </React.Fragment>
                          ) : form.bankVerified ? (
                            "Verified"
                          ) : (
                            "Verify"
                          )}
                        </button>
                      </div>
                    }
                  </div>
                </section>
                <section className="tab-body mt-3 mb-3">
                  <div className="row">
                    <h5 className="col-md-12 text-center mb-4">
                      Upload Document
                    </h5>
                    {/* kycdoc */}

                    <div className="col-md-4">
                      <label>
                        Upload KYC document <i className="text-danger">*</i>{" "}
                      </label>
                      <ImageUploader
                        name="kycDocument"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="KYC document"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "kycDocument");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          ".xls",
                          ".doc",
                          ".ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                      {errors.kycDocument && (
                        <span className="errorMsg">
                          Please Upload KYC document
                        </span>
                      )}
                    </div>

                    {/* upload agrrement */}

                    <div className="col-md-4">
                      <label className="">
                        Upload Master Franchise Agreement
                        <i className="text-danger">*</i>
                      </label>
                      <ImageUploader
                        name="appraiserAppointment"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="Master Franchise Agreement"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "appraiserAppointment");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          ".xls",
                          ".doc",
                          ".ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                      {errors.appraiserAppointment && (
                        <span className="errorMsg">
                          Please Upload Agreement
                        </span>
                      )}
                    </div>

                    {/* payout doc */}

                    <div className="col-md-4">
                      <label className="">
                        Upload Equipment Lease Agreement
                        <i className="text-danger">*</i>
                      </label>
                      <ImageUploader
                        name="chequeGaurantee"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="Equipment Lease Agreement"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "chequeGaurantee");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          ".xls",
                          ".doc",
                          ".ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                      {errors.chequeGaurantee && (
                        <span className="errorMsg">
                          Please Upload Payout document
                        </span>
                      )}
                    </div>

                    <div className="col-md-4 mt-5">
                      <label>Upload CIBIL Report</label>
                      <ImageUploader
                        name="demandPromissoryNote"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        // accept="image/*"
                        buttonText="CIBIL Report"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "demandPromissoryNote");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          ".xls",
                          ".doc",
                          ".ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                    </div>
                    <div className="col-md-4 mt-5">
                      <label className="">Upload Franchise Detail Form</label>
                      <ImageUploader
                        name="aggrement"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="Franchise Detail Form"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "aggrement");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          ".xls",
                          ".doc",
                          ".ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                    </div>

                    <div className="col-md-4 mt-5">
                      <label>Upload Franchise Survey</label>
                      <ImageUploader
                        name="dpnLetter"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="Franchise Survey"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "dpnLetter");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          ".xls",
                          ".doc",
                          ".ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                    </div>
                    <div className="col-md-4 mt-5">
                      <label style={{ whiteSpace: "pre" }}>
                        Upload Cheque guarantee/ cancelled cheque
                      </label>
                      <ImageUploader
                        name="payoutdoc"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText=" Cheque guarantee/ cancelled cheque"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "payoutdoc");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          ".xls",
                          ".doc",
                          ".ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                    </div>
                    <div className="col-md-4 mt-5">
                      <label>Upload Passport photo</label>
                      <ImageUploader
                        name="passportPhoto"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText=" Passport photo"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "passportPhoto");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          ".xls",
                          ".doc",
                          ".ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                      {errors.passportPhoto && (
                        <span className="errorMsg">
                          Please Upload Passport photo
                        </span>
                      )}
                    </div>
                    <div className="col-md-4 mt-5">
                      <label>Upload Franchise/Implement Economics</label>
                      <ImageUploader
                        name="resume"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText=" Franchise/Implement Economics"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "resume");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          ".xls",
                          ".doc",
                          ".ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                    </div>
                    <div className="col-md-5 mt-5">
                      <label style={{ whiteSpace: "pre" }}>
                        Upload Shop registration document / Appraiser
                        certificate
                      </label>
                      <ImageUploader
                        name="shopRegistration"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="Shop registration document / Appraiser certificate"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "shopRegistration");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          ".xls",
                          ".doc",
                          ".ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                      {errors.shopRegistration && (
                        <span className="errorMsg">
                          Please Upload Shop registration document / Appraiser
                          certificate
                        </span>
                      )}
                    </div>

                    <div className="col-md-4 mt-5">
                      <label>Upload Substitute appraiser document </label>
                      <ImageUploader
                        name="substituteAppraiser"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="Substitute appraiser document"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "substituteAppraiser");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          ".xls",
                          ".doc",
                          ".ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                      {errors.substituteAppraiser && (
                        <span className="errorMsg">
                          Please Upload Substitute appraiser document
                        </span>
                      )}
                    </div>
                    <div className="col-md-3 mt-5">
                      <label>Upload Franchise Qualitative Score Card </label>
                      <ImageUploader
                        name="bmInterviewSheet"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="Franchise Qualitative Score Card"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "bmInterviewSheet");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          ".xls",
                          ".doc",
                          ".ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                      {errors.bmInterviewSheet && (
                        <span className="errorMsg">
                          Please Upload BM interview sheet
                        </span>
                      )}
                    </div>
                    <div className="col-md-4 mt-5">
                      <label>Upload Security Deposit Slip</label>
                      <ImageUploader
                        name="form60"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="Security Deposit Slip"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "form60");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          ".xls",
                          ".doc",
                          ".ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                      {errors.form60 && (
                        <span className="errorMsg">Please Upload Form 60</span>
                      )}
                    </div>

                    {/* <div className="col-md-4 mt-5">
                      <label className="">
                        Upload Agreement
                        <i className="text-danger">*</i>
                      </label>
                      <ImageUploader
                        name="appraiserAppointment"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="Agreement"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "appraiserAppointment");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          ".xls",
                          ".doc",
                          ".ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                      {errors.appraiserAppointment && (
                        <span className="errorMsg">
                          Please Upload Agreement
                        </span>
                      )}
                    </div> */}
                    {/* <div className="col-md-4 mt-5">
                      <label className="">
                        Upload Payout document
                        <i className="text-danger">*</i>
                      </label>
                      <ImageUploader
                        name="chequeGaurantee"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="Payout document"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "chequeGaurantee");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          ".xls",
                          ".doc",
                          ".ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                      {errors.chequeGaurantee && (
                        <span className="errorMsg">
                          Please Upload Payout document
                        </span>
                      )}
                    </div> */}
                    <div className="col-md-4 mt-5">
                      <label>Upload Implement Quotation</label>
                      <ImageUploader
                        name="otherDocument1"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="Implement Quotation"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "otherDocument1");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          ".xls",
                          ".doc",
                          ".ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                    </div>
                    <div className="col-md-4 mt-5">
                      <label>Upload Implement Invoice</label>
                      <ImageUploader
                        name="otherDocument2"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="Implement Invoice"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "otherDocument2");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          ".xls",
                          ".doc",
                          ".ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                    </div>
                    <div className="col-md-4 mt-5">
                      <label>Upload Other document 1</label>
                      <ImageUploader
                        name="otherDocument3"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="Other document 1"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "otherDocument3");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          "xls",
                          "doc",
                          "ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                    </div>
                    <div className="col-md-4 mt-5">
                      <label>Upload Other document 2</label>
                      <ImageUploader
                        name="otherDocument4"
                        withIcon={true}
                        withPreview={true}
                        withLabel={true}
                        accept="image/*"
                        buttonText="Other document 2"
                        onChange={(file, base64) => {
                          this.onDrop(file, base64, "otherDocument4");
                        }}
                        imgExtension={[
                          ".jpg",
                          ".gif",
                          ".png",
                          ".jpeg",
                          ".pdf",
                          ".docx",
                          ".pptx",
                          ".xlsx",
                          "xls",
                          "doc",
                          "ppt",
                        ]}
                        maxFileSize={15728640}
                      />
                    </div>
                    <p className="mt-5" style={{ color: "black" }}>
                      <i className="text-danger">*</i>Upload documents in jpg,
                      jpeg, png, pdf , gif, docx, pptx and xlsx format with
                      Maximum size up to 5 MB
                    </p>
                  </div>
                </section>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-danger" onClick={this.close}>
                Cancel
              </Button>
              <Button
                className="btn-success"
                onClick={this.handleSubmit}
                disabled={
                  disabledSave ||
                  loading ||
                  (errors &&
                    errors.registeredAddress &&
                    errors.registeredAddress.validMobile) ||
                  (errors &&
                    errors.communicationAddress &&
                    errors.communicationAddress.validMobile) ||
                  (errors &&
                    errors.communicationAddress &&
                    errors.communicationAddress.validEmail) ||
                  (errors &&
                    errors.registeredAddress &&
                    errors.registeredAddress.validEmail) ||
                  isDedupe ||
                  isEmailPresent ||
                  isMobileNumberPresent ||
                  villagePresent ||
                  form.franchiseCode == "" ||
                  (userZone && userZone.length == 0) ||
                  form.location == ""
                }
              >
                {loading ? (
                  <React.Fragment>
                    <i class="fa fa-spinner fa-spin"></i>
                    Creating...
                  </React.Fragment>
                ) : (
                  "Create"
                )}
              </Button>
            </Modal.Footer>
          </Modal>
          <CloseSection
            show={this.state.showInner}
            button2={this.close}
            button1={this.handleClose}
            title="Do you want to close ?"
          />
        </div>
      </>
    );
  }
}

export default withRouter(FranchiseeOnboardingMasterForm);
