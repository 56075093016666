import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { te, ts } from '../Utils/ReduxToaster';

import {
    GET_USER_BY_EMPLOYEEID,
} from '../Actions/types';

import {
    getUserByEmplyeeIdSuccess,
    getUserByEmplyeeIdFailure,
} from '../Actions';

import { getUserByEmployeeId } from "../Utils/Services/user";

/************************Sign up******************************************* */
/* const signUpRequest = (data) => {
    return axios.post(`/users/signup`,
        {
            email: data.email,
            password: data.password,
            name: data.name,
            username: data.username
        }
    ).then(result => result)
        .catch(error => error)
} */

function* getUserByEmployeeIdFunction({ payload }) {
    const { history } = payload;
    console.log("payload....", payload);
    try {

        const signupResponse = yield call(getUserByEmployeeId, payload.employeeId)
        console.log('signupResponse.data.userData.role', signupResponse)
        /* if (signupResponse.data.error) {
            NotificationManager.error(signupResponse.data.title)
            yield put(signupFailure(signupResponse.data))
        } else {
            localStorage.setItem('loggedIn', true)
            localStorage.setItem('token', signupResponse.data.token)
            localStorage.setItem('role', signupResponse.data.userdata.role)
            NotificationManager.success(signupResponse.data.title)
            yield put(signupSuccess(signupResponse.data))
            history.push(`/user`)
        } */
    } catch (error) {
        console.log('signupResponse.data.userData.role error', error)
        // yield put(signupFailure(error))
    }
}
export function* getUserByEmployeeIdFun() {
    yield takeEvery(GET_USER_BY_EMPLOYEEID, getUserByEmployeeIdFunction)
}

/******************************************************************* */
export default function* rootSaga() {
    yield all([
        fork(getUserByEmployeeIdFun),
    ]);
}
