import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import AddEditfamily from "./AddEditfamily";
import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import {
  deletefamilymemberexpense,
  getFamilyMemberExpense,
} from "../../../Utils/Services/family";
import { getAllLevelsDropdown } from "../../../Utils/Services/common";
import { setISODay } from "date-fns";
toast.configure();

const Familymember = (props) => {
  let [search, setSearch] = useState("");
  let [memberData, setmemberData] = useState([]);
  let [levelDropdownOptions, setlevelDropdownOptions] = useState([]);
  let [showAddProductNormModal, setshowAddProductNormModal] = useState(false);
  let [showEditNormModal, setshowEditNormModal] = useState(false);
  let [editData, setEditData] = useState({});

  useEffect(() => {
    getLevelValue();
    familymemberexpense();
  }, []);

  const familymemberexpense = () => {
    getFamilyMemberExpense().then((response) => {
      console.log("latest response", response.data);
      if (response.error) {
        return;
      }
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        setmemberData(response.data);
      }
    });
  };

  const getLevelValue = () => {
    getAllLevelsDropdown().then((result) => {
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        toast.error(result.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        if (result && result.data && result.data.data) {
          setlevelDropdownOptions(result.data.data);
        }
      }
    });
  };

  const deletefamilymemberById = (id) => {
    // let postData = {
    //   id: id,
    // };
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes, Delete It!",
          onClick: () => {
            deletefamilymemberexpense(id).then((response) => {
              console.log("deleteProductNormById", response);
              if (response.error) {
                return;
              }
              if (response && response.error) {
                toast.error("Error in deleting data", {
                  type: toast.TYPE.ERROR,
                  autoClose: 2000,
                });
              } else {
                toast.success(" Data deleted succesfully", {
                  type: toast.TYPE.SUCCESS,
                  autoClose: 2000,
                });
                familymemberexpense();
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            // toast.error("Role Can Not be Deleted", {
            //   type: toast.TYPE.ERROR,
            //   autoClose: 2000
            // });
            // this.RoleList();
          },
        },
      ],
    });
  };

  const columns = [
    {
      Header: "HH member",
      // width: 70,
      Cell: ({ original }) => {
        // console.log("data...", data)
        return original && original.hhMembers ? original.hhMembers : null;
      },
      /* Cell: ({ original }) => {
                return original.id && original.id ? original.id : null;
            } */
    },

    {
      Header: "NFCF",
      columns: [
        {
          Header: "Upto 30K",
          Cell: ({ original }) => {
            return original && original.zeroToThirtyExpense
              ? original.zeroToThirtyExpense
              : null;
          },
        },
        {
          Header: "30K to 50K",
          Cell: ({ original }) => {
            return original && original.thirtyToFiftyExpense
              ? original.thirtyToFiftyExpense
              : null;
          },
        },
        {
          Header: ">50K to 80K",
          // width: 200,
          Cell: ({ original }) => {
            return original && original.fiftyToEightyExpense
              ? original.fiftyToEightyExpense
              : null;
          },
        },
        {
          Header: ">80K to 1L",
          // width: 200,
          Cell: ({ original }) => {
            return original && original.eightyToHundredExpense
              ? original.eightyToHundredExpense
              : null;
          },
        },
        {
          Header: ">1L",
          // width: 200,
          Cell: ({ original }) => {
            return original && original.moreThanHundredExpense
              ? original.moreThanHundredExpense
              : null;
          },
        },

        {
          Header: "Action",
          width: 125,
          disabled: false,
          accessor: "delete",
          accessor: "edit",

          Cell: ({ original }) => {
            return (
              <div
                disabled={false}
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <i
                  disabled={false}
                  onClick={() => deletefamilymemberById(original.id)}
                  className="fa fa-trash"
                  style={{ cursor: "pointer" }}
                />
                <i
                  disabled={false}
                  onClick={() => handleEditfamilymember(original)}
                  className="fas fa-user-edit"
                  style={{ cursor: "pointer" }}
                />
              </div>
            );
          },
        },
      ],
    },
  ];

  const handleAddfamilymember = (id) => {
    setshowAddProductNormModal(!showAddProductNormModal);
  };

  const handleEditfamilymember = (obj) => {
    setshowEditNormModal(!showEditNormModal);
    setEditData(obj);
  };

  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box">
            {" "}
            Family Member Expense
          </h5>
          <div className="d-flex align-items-center mt-5">
            <button
              disabled={false}
              onClick={handleAddfamilymember}
              className="add-btn ml-auto btn btn-info btn-lg"
            >
              {" "}
              Add{" "}
            </button>
            {showAddProductNormModal && (
              <AddEditfamily
                action={"add"}
                familymemberexpense={familymemberexpense}
                handleAddfamilymember={handleAddfamilymember}
                levelDropdownOptions={levelDropdownOptions}
                memberData={memberData}
              />
            )}
            {showEditNormModal && (
              <AddEditfamily
                action={"edit"}
                familymemberexpense={familymemberexpense}
                editData={editData}
                handleEditfamilymember={handleEditfamilymember}
                levelDropdownOptions={levelDropdownOptions}
                memberData={memberData}
              />
            )}
          </div>

          <div className={`mt-4 react-table`}>
            <ReactTable
              data={memberData}
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default Familymember;
