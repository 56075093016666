import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import "./style.css";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddEditVillage from "../VillageMaster/AddEditVillage";
import {
  findallvillagesmaster,
  deletevillagemasterbyid,
} from "../../../Utils/Services/VillageMaster";
import BulkUploadVillage from "./BulkUploadVillage";
import MigrateSpoke from "./MigrateSpoke";
toast.configure();

const VillageMaster = (props) => {
  let [villageData, setvillageData] = useState([]);

  let [productDropdownOptions, setproductDropdownOptions] = useState([]);
  let [showAddProductNormModal, setshowAddProductNormModal] = useState(false);
  let [showEditNormModal, setshowEditNormModal] = useState(false);
  let [editData, setEditData] = useState({});
  let [showBulUploadModal, setshowBulUploadModal] = useState(false);
  const [showMigrateSpokeModal, setshowMigrateSpokeModal] = useState(false);

  useEffect(() => {
    villageMaster();
    window.scrollTo(0, 0);
  }, []);

  //get all list village
  const villageMaster = () => {
    findallvillagesmaster().then((response) => {
      if (response.error) {
        return;
      }
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        setvillageData(response.data.data);
      }
    });
  };

  //delete village api
  const deleteVillageById = (id) => {
    let postData = {
      id: id,
    };
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes, Delete It!",
          onClick: () => {
            deletevillagemasterbyid(postData).then((response) => {
              console.log("deletevillagemasterbyid", response);
              if (response.error) {
                return;
              }
              if (response.data && response.data.error) {
                toast.error(response.data.message, {
                  type: toast.TYPE.ERROR,
                  autoClose: 2000,
                });
              } else {
                toast.success(response.data.message, {
                  type: toast.TYPE.SUCCESS,
                  autoClose: 2000,
                });
                villageMaster();
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            // toast.error("Role Can Not be Deleted", {
            //   type: toast.TYPE.ERROR,
            //   autoClose: 2000
            // });
            // this.RoleList();
          },
        },
      ],
    });
  };

  const columns = [
    {
      Header: "SR. No",
      // width: 60,
      Cell: (data) => {
        return data.index + 1;
      },
    },

    {
      Header: "Village",
      // width: 100,
      Cell: ({ original }) => {
        return original && original.villageName ? original.villageName : null;
      },
    },

    {
      Header: "Pincode",
      // width: 100,
      Cell: ({ original }) => {
        return original && original.pincode ? original.pincode : null;
      },
    },
    {
      Header: "Dwara Id",
      // width: 100,
      Cell: ({ original }) => {
        return original && original.dwaraid ? original.dwaraid : null;
      },
    },
    {
      Header: "Tehsil",
      // width: 100,
      Cell: ({ original }) => {
        return original && original.tehsil ? original.tehsil : null;
      },
    },
    {
      Header: "District",
      // width: 100,
      Cell: ({ original }) => {
        return original && original.district ? original.district : null;
      },
    },
    {
      Header: "State",
      // width: 100,
      Cell: ({ original }) => {
        return original && original.state ? original.state : null;
      },
    },
    {
      Header: "Action",
      // width: 80,
      disabled: false,
      accessor: "delete",
      accessor: "edit",

      Cell: ({ original }) => {
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              onClick={() => deleteVillageById(original.id)}
              className="fa fa-trash"
              style={{ cursor: "pointer" }}
            />
            <i
              disabled={false}
              onClick={() => handleEditVillageMaster(original)}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const handleAddVillage = (id) => {
    setshowAddProductNormModal(!showAddProductNormModal);
  };

  const handleEditVillageMaster = (obj) => {
    setshowEditNormModal(!showEditNormModal);
    setEditData(obj);
  };

  const handleBulkUpload = () => {
    console.log("HAPPENED");
    setshowBulUploadModal(!showBulUploadModal);
  };

  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box">
            Village-Pincode Master
          </h5>
          <div className="d-flex flex-row-reverse align-items-center mt-5">
            <div className="w-full d-flex gap-2">
              <button
                className="btn btn-info btn-lg p-5 ml-auto"
                onClick={() => setshowMigrateSpokeModal(!showMigrateSpokeModal)}
              >
                Migrate Spoke
              </button>{" "}
              <button
                disabled={false}
                onClick={handleAddVillage}
                className="add-btn ml-4 btn btn-info btn-lg"
              >
                {" "}
                Add{" "}
              </button>
            </div>
            {/* <button
              disabled={false}
              onClick={handleBulkUpload}
              className="bulk-upload-button btn btn-info btn-lg"
            >
              {" "}
              Bulk Upload Villages{" "}
            </button> */}
            {showAddProductNormModal && (
              <AddEditVillage
                action={"add"}
                villageMaster={villageMaster}
                handleAddVillage={handleAddVillage}
                productDropdownOptions={productDropdownOptions}
              />
            )}
            {showEditNormModal && (
              <AddEditVillage
                action={"edit"}
                villageMaster={villageMaster}
                editData={editData}
                handleEditVillageMaster={handleEditVillageMaster}
                productDropdownOptions={productDropdownOptions}
              />
            )}
            {showMigrateSpokeModal && (
              <MigrateSpoke
                showMigrateSpokeModal={showMigrateSpokeModal}
                setshowMigrateSpokeModal={setshowMigrateSpokeModal}
                villageData={villageData}
              />
            )}
            {showBulUploadModal && (
              <BulkUploadVillage handleBulkUpload={handleBulkUpload} />
            )}
          </div>

          <div className={`mt-4 react-table`}>
            <ReactTable
              data={villageData}
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default VillageMaster;
