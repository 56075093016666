import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import Addedituser from "../UserManagement/Addedituser";
import AddCampaign from "../Notification/AddCampaign";
import Header from "../../Components/Header";
import LeftMenu from "../../Components/LeftMenu";
import { formatIndianCurrency } from "../../Utils/helperFunctions";
import {
  getnotification,
  SendNotification
} from "../../Utils/Services/Campaign";
toast.configure();

const UserManagement = (props) => {
  let [search, setSearch] = useState("");
  let [usereData, setuserData] = useState([]);
  let [userContainer, setuserContainer] = useState([]);
  let [showEditUserModal, setshowEditUserModal] = useState(false);
  let [editData, setEditData] = useState({});
  let [showAddUserModal, setShowAddUserModal] = useState(false);
  let [roleDropdownValue, setroleDropdownValue] = useState([]);

  useEffect(() => {
    allusers();
    getCampaigns();
  }, []);

  const getCampaigns = () => {
    getnotification().then((result) => {
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        toast.error(result.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        // } else {
        //   if (result && result.data && result.data.data) {
        //     setroleDropdownValue(result.data.data);
        //   }
      }
      this.setState({result})
    }).catch((err) => {
      console.log("err", err);
    });   
  };

  // const deleteProductCampaign = (id) => {
  //   //delete api call will be here
  //   confirmAlert({
  //     title: "Confirm to delete",
  //     message: "Are you sure to do this.",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () => {
  //           console.log("yes");
  //           const obj = {
  //             id: id,
  //           };
  //           deleteProductCampaignById(obj)
  //             .then((res) => {
  //               if (res.error) {
  //                 toast.error(res.error);
  //               } else {
  //                 toast.success("Deleted Successfully");
  //                 // setProductCampaignData(productCampaignData.filter((item) => item.id !== id));
  //                 getProductCampaign();
  //               }
  //             })
  //             .catch((err) => {
  //               console.log("err", err);
  //             });
  //         },
  //       },
  //       {
  //         label: "No",
  //         onClick: () => {
  //           console.log("no");
  //         },
  //       },
  //     ],
  //   });
  // };

  const allusers = () => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    getnotification(userData.id).then((res) => {
      console.log("Roles", res);
      if (res.error) {
        return;
      }
      if (res.data && res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
          toast.success(res.data.message, {
                type: toast.TYPE.SUCCESS,
                autoClose: 2000
            }); 
            if(res.data.data.length===0){
              setuserData([])
            }else{
              setuserData(res.data.data)
            }
        setuserContainer(res.data.data);
      }
    });
  };

  const sendPushNotification = (id) => {
    
		confirmAlert({
			title: "Confirm to send",
			message: "Are you sure to do this.",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						console.log("yes");
						const obj = {
							id: id,
              // token: token,
              
              
						};
						SendNotification(obj)
							.then((res) => {
								if (res.error) {
									toast.error(res.error);
								} else {
									toast.success("Sent Successfully");
									// setProductCampaignData(productCampaignData.filter((item) => item.id !== id));
								}
							})
							.catch((err) => {
								console.log("err", err);
							});
					},
				},
				{
					label: "No",
					onClick: () => {
						console.log("no");
					},
				},
			],
		});
	};

  const deletePushNotification = (id) => {
    // const token = "ftqx3ttqRY69DYp3vS7f4U:APA91bF9XpzNwweyrLewMOqZjZZoGVp8DQvkfJCwVeayD-ck0TSyf9Xh4eEe4lXPYUNVdriH1FlzF1EBQpDAq8dw6ZiYAW0kNDoWmps6khVV8kFrnIJRn4oqh9Bjy7TjX6OQHnNC-0Qt"
  // const token = "fRw1-rKVQgS5JAg9T5xkAb:APA91bFrQCO8nKA5pRWUya75Yp_4edKW9ye3jQGvgRAOj2Xf_NVD1KBPUcsOPX-H-RVhemb2MvtCgSV1mNb9beMI1ea2W45FZiyR9-kC4a620Elugj6wRMSutz_d4E9bp0cWAa8dESkU"
		//delete api call will be here
		confirmAlert({
			title: "Confirm to send",
			message: "Are you sure to do this.",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						console.log("yes");
						const obj = {
							id: id,
              // token: token,
              
              
						};
						SendNotification(obj)
							.then((res) => {
								if (res.error) {
									toast.error(res.error);
								} else {
									toast.success("Sent Successfully");
									// setProductCampaignData(productCampaignData.filter((item) => item.id !== id));
								}
							})
							.catch((err) => {
								console.log("err", err);
							});
					},
				},
				{
					label: "No",
					onClick: () => {
						console.log("no");
					},
				},
			],
		});
	};

  const columns = [
    {
      Header: "ID",
      width: 200,
      Cell: ({ original }) => {
        return original && original.id ? original.id : null;
      },
    },
    {
      Header: "Banner Title",
      width: 200,
      Cell: ({ original }) => {
        return original && original.bannerTitle ? original.bannerTitle : null;
      },
    },
    {
      Header: "Banner Image",
      width: 200,
      Cell: ({ original }) => {
        return original && original.banner ? original.banner : null;
      },
    },
    {
      Header: "Start Date",
      Cell: ({ original }) => {
        return original && original.startDate ? original.startDate : null;
      },
    },
    {
      Header: "End Date",
      Cell: ({ original }) => {
        return original && original.endDate ? original.endDate : null;
      },
    },
    {
      Header: "Category",
      Cell: ({ original }) => {
        return original && original.category ? original.category : null;
      },
    },
    {
      Header: "Title",
      Cell: ({ original }) => {
        return original && original.notificationTitle
          ? original.notificationTitle
          : null;
      },
    },
    // {
    //   Header: "Sub-Title",
    //   Cell: ({ original }) => {
    //     return original && original.notificationSubTitle
    //       ? original.notificationSubTitle
    //       : null;
    //   },
    // },
    // {
    //   Header: "Description",
    //   Cell: ({ original }) => {
    //     return original && original.notificationDesc
    //       ? original.notificationDesc
    //       : null;
    //   },
    
    //   {
    //     Header: "Delete",
    //     Cell: ({ original }) => {
    //       return original && original.status ? original.status : null;
    //     },
    //   },
    {
      Header: "Action",
      width: 125,
      disabled: false,
      accessor: "delete",
      accessor: "edit",

      Cell: ({ original }) => {
        console.log("theoriginaldata",original);
        let resData = usereData.filter((item)=>{return item.id==original.id})
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              onClick={() => handleEditUser({originalId:original.id,data:resData})}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },

    // {
    //   Header: "Delete",
    //   width: 125,
    //   disabled: false,
    //   accessor: "delete",

    //   Cell: ({ original }) => {
    //     return (
    //       <div
    //         disabled={false}
    //         style={{ display: "flex", justifyContent: "space-around" }}
    //       >
    //         <i
    //           disabled={false}
    //           onClick={() => handleEditUser(original.id)}
    //           className="fa fa-trash"
    //           style={{ cursor: "pointer" }}
    //         />
    //       </div>
    //     );
    //   },
    // },

    // {
    //   Header: "Send",
    //   width: 125,
    //   disabled: false,
    //   accessor: "send",

    //   Cell: ({ original }) => {
    //     return (
    //       <div
    //         disabled={false}
    //         style={{ display: "flex", justifyContent: "space-around" }}
    //       >
    //         <i
    //           disabled={false}
    //           onClick={() => sendPushNotification(original.id)}
    //           className="fa fa-paper-plane"
    //           style={{ cursor: "pointer" }}
    //         />
    //       </div>
    //     );
    //   },
    

    // {
    //   Header: "Delete",
    //   width: 125,
    //   disabled: false,
    //   accessor: "delete",

    //   Cell: ({ original }) => {
    //     return (
    //       <div
    //         disabled={false}
    //         style={{ display: "flex", justifyContent: "space-around" }}
    //       >
    //         <i
    //           disabled={false}
    //           onClick={() => handleDeleteUser(original)}
    //           className="fa fa-trash"
    //           aria-hidden="true"
    //           style={{ cursor: "pointer" }}
    //         />
    //       </div>
    //     );
    //   },
    // },

    // {
    //   Header: "Send",
    //   width: 125,
    //   disabled: false,
    //   accessor: "delete",

    //   Cell: ({ original }) => {
    //     return (
    //       <div
    //         disabled={false}
    //         style={{ display: "flex", justifyContent: "space-around" }}
    //       >
    //         <i
    //           disabled={false}
    //           onClick={() => handleDeleteUser(original)}
    //           className="fa fa-paper-plane"
    //           aria-hidden="true"
    //           style={{ cursor: "pointer" }}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  const handleAddUser = (id) => {
    setShowAddUserModal(!showAddUserModal);
  };

  const handleEditUser = (obj) => {
    console.log("originalid",obj);
    setshowEditUserModal(!showEditUserModal);
    setEditData(obj);
  };

  // const handleDeleteUser = (id) => {
  //   setuserData((prevData) =>{
  //     return prevData.filter(data => data.id != id);
  //   } )
  // }

  const handleSearch = (e) => {
    let array = [];
    userContainer.map((res) => {
      let name = res.name;
      if (
        res.id &&
        res.bannerTitle &&
        res.banner &&
        res.startDate &&
        res.endDate &&
        res.category &&
        res.notificationTitle
      )
        if (
          // res.id.toLowerCase().match(e.target.value.toLowerCase()) ||
          res.bannerTitle.toLowerCase().match(e.target.value.toLowerCase()) ||
          res.banner.toLowerCase().match(e.target.value.toLowerCase()) ||
          // res.startDate.toLowerCase().match(e.target.value.toLowerCase()) ||
          // res.endDate.toString().match(e.target.value.toString()) || 
          res.category.toString().match(e.target.value.toString()) ||
          res.notificationTitle.toString().match(e.target.value.toString())


        ) {
          array.push(res);
        }
    });
    // this.setState({
    //   search: e.target.value,
    //   userData: e.target.value ? array : userContainer
    // });
    setSearch(e.target.value);
    setuserData(e.target.value ? array : userContainer);
  };

  return (
    <>
      <div className="dash_grid">
        <ToastContainer />
        <LeftMenu role={props.role} />
        <main className="bg-white">
          <Header {...props} />
          <section className="container-fluid mb-5">
            <h5 className="text-center mt-5 mx-auto user-box">
              {" "}
              Leaderboard Campaigns
            </h5>
            <div className="d-flex align-items-center mt-5">
              {/* <div className="form-group m-0 search-input">
                <div className="inner-addon right-addon">
                  <i className="glyphicon glyphicon-search mt-1 font-size-20"></i>
                  <input
                    type="text"
                    className="form-input"
                    name="search"
                    value={search}
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                    // onBlur={this.handleValidate}
                    placeholder="Search..."
                  />
                </div>
              </div> */}
              <button
                disabled={false}
                onClick={handleAddUser}
                className="add-btn ml-auto btn btn-info btn-lg"
              >
                {" "}
                Add{" "}
              </button>
              {showAddUserModal && (
                <AddCampaign
                  action={"add"}
                  handleAddUser={handleAddUser}
                  roleDropdownValue={roleDropdownValue}
                  getCampaigns={getCampaigns}

                  // allusers={allusers}
                />
              )}
              {showEditUserModal && (
                <AddCampaign
                  action={"edit"}
                  editData={editData}
                  handleEditUser={handleEditUser}
                  getCampaigns={getCampaigns}
                  // roleDropdownValue={roleDropdownValue}
                  // allusers={allusers}
                />
              )}
              {/* <button
                disabled={false}
                onClick={handleAddUser}
                className="add-btn ml-auto btn btn-info btn-lg"
              >
                {" "}
                Send{" "}
              </button>
              {showAddUserModal && (
                <SendNotification
                  action={"add"}
                  handleAddUser={handleAddUser}
                  roleDropdownValue={roleDropdownValue}
                  // allusers={allusers}
                />
              )}
              {showEditUserModal && (
                <SendNotification
                  action={"edit"}
                  editData={editData}
                  handleEditUser={handleEditUser}
                  roleDropdownValue={roleDropdownValue}
                  // allusers={allusers}
                />
              )} */}

              {/* <button
                disabled={false}
                onClick={handleAddUser}
                className="add-btn ml-auto btn btn-info btn-lg"
              > Send
                </button> */}
            </div>

            <div className={`mt-4 react-table`}>
              <ReactTable
                data={usereData}
                columns={columns}
                defaultPageSize={10}
              />
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default UserManagement;

// import React, { useState } from "react";
// import { updatePassword } from "../../Utils/Services/authentication";
// import { getUserByEmployeeId } from "../../Utils/Services/user";
// import Header from "../../Components/Header";
// import LeftMenu from "../../Components/LeftMenu";
// import { generatePassword } from "../../Utils/helperFunctions";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// toast.configure();

// //change password scrn
// function CreateCampaign () {

//     const [bannerTitle, setBannerTitle] = useState('')
//     const [startDate, setStartDate] = useState('')
//     const [endDate, setEndDate] = useState('')
//     const [category, setCategory] = useState('')
//     const [title, setTitle] = useState('')
//     const [subTitle, setSubTitle] = useState('')
//     const [description, setDescription] = useState('')
//     const[image, setImage] = useState('')

//   // componentWillMount() {
//   //   let data = JSON.parse(sessionStorage.getItem("userName"));
//   //   console.log("data", data && data.userName);
//   //   this.setState({
//   //     userName: data && data.userName
//   //   });

//   //   let readData = JSON.parse(sessionStorage.getItem("ReadPages"));
//   //   console.log("readData", readData);
//   //   readData &&
//   //     readData.map(res => {
//   //       if (res == 13) {
//   //         this.setState({
//   //           userHide: true
//   //         });
//   //       }
//   //     });
//   //   }

//     return (
//       <div className="dash_grid">
//         <ToastContainer />
//         <LeftMenu />
//         <main className="bg-white">
//           <Header  />
//           <section className="tab-body">
//             <h5 className="text-center mt-3 mb-5 mx-auto user-box">
//               Create Campaign
//             </h5>
//             <div className={`row { "disable-tab"}`}>
//               <div style={{ width: "400px" }} className="content col-md-5">
//                 <div className="form-group">
//                   <input
//                     type="text"
//                     pattern="\d*"
//                     maxLength="50"
//                     className="form-input"
//                     name="Banner Title"
//                     value={bannerTitle.bannerTitle}
//                     onChange={text => setBannerTitle(text)}
//                     // onBlur={this.handleValidate}
//                     placeholder="Banner Title"
//                   />
//                   {/* {errors.oldpasswordError && (
//                     <span className="errorMsg">Please enter old password</span>
//                   )} */}
//                 </div>
//                 <div className="form-group">
//                   <input
//                     type="date"
//                     pattern="\d*"
//                     maxLength="50"
//                     className="form-input"
//                     name="Start Date"
//                     value={startDate}
//                     onChange={text => setStartDate(text)}
//                     // onBlur={this.handleValidate}
//                     placeholder="Start Date"
//                   />
//                   {/* {errors.newpasswordError && (
//                     <span className="errorMsg">Please enter new password</span>
//                   )}
//                   {invalidNewPassword && (
//                     <span className="errorMsg">
//                       Old Password And New password Can Not be Same.
//                     </span>
//                   )}
//                   <br />
//                   {invalidPassword && (
//                     <span className="errorMsg">
//                       Password must contain at least 8 characters, including
//                       uppercase, lowercase and numbers
//                     </span>
//                   )} */}
//                 </div>
//                 <div className="form-group">
//                   <input
//                     type="date"
//                     pattern="\d*"
//                     maxLength="50"
//                     className="form-input"
//                     name="End Date"
//                     value={endDate}
//                     onChange={text => setEndDate(text)}
//                     // onBlur={this.handleValidate}
//                     placeholder="End Date"
//                   />
//                   {/* {errors.confpasswordError && (
//                     <span className="errorMsg">
//                       Please enter confirm password
//                     </span>
//                   )}
//                   {invalidconfpassword && (
//                     <span className="errorMsg">
//                       Confirm password does not match
//                     </span>
//                   )} */}
//                 </div>

//                 <div className="form-group">
//                   <input
//                     type="text"
//                     pattern="\d*"
//                     maxLength="50"
//                     className="form-input"
//                     name="Category"
//                     value={category}
//                     onChange={text => setCategory(text)}
//                     // onBlur={this.handleValidate}
//                     placeholder="Category"
//                   />
//                   </div>

//                   <div className="form-group">
//                   <input
//                     type="text"
//                     pattern="\d*"
//                     maxLength="50"
//                     className="form-input"
//                     name="Title"
//                     value={title}
//                     onChange={text => setTitle(text)}
//                     // onBlur={this.handleValidate}
//                     placeholder="Title"
//                   />
//                   </div>

//                   <div className="form-group">
//                   <input
//                     type="text"
//                     pattern="\d*"
//                     maxLength="50"
//                     className="form-input"
//                     name="Sub-Title"
//                     value={subTitle}
//                     onChange={text => setSubTitle(text)}
//                     // onBlur={this.handleValidate}
//                     placeholder="Sub-Title"
//                   />
//                   </div>

//                   <div class="form-group">
//   <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//     Dropdown
//   </button>
//   <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
//     <button class="dropdown-item" type="button">Director's Club</button>
//     <button class="dropdown-item" type="button">Other Announcement</button>
//   </div>
// </div>

//                   <div className="form-group">
//                   <input
//                     type="text"
//                     pattern="\d*"
//                     maxLength="50"
//                     className="form-input"
//                     name="Description"
//                     value={description}
//                     onChange={text => setDescription(text)}
//                     // onBlur={this.handleValidate}
//                     placeholder="Description"
//                   />
//                   </div>

//                   <div className="form-group">
//                   <input
//                     type="file"
//                     pattern="\d*"
//                     maxLength="50"
//                     className="form-input"
//                     name="Upload Image/Video"
//                     value={image.image}
//                     onChange={file => setImage(file)}
//                     // onBlur={this.handleValidate}
//                     placeholder="Upload Image/Video"
//                   />
//                   </div>

//                   {/* <div className="form-group">
//                   <input
//                     type="password"
//                     pattern="\d*"
//                     maxLength="50"
//                     className="form-input"
//                     name="confpassword"
//                     value={confirmPassword}
//                     onChange={text => setConfirmPassword(text)}
//                     // onBlur={this.handleValidate}
//                     placeholder="Confirm password"
//                   />
//                   </div>

//                   <div className="form-group">
//                   <input
//                     type="password"
//                     pattern="\d*"
//                     maxLength="50"
//                     className="form-input"
//                     name="confpassword"
//                     value={confirmPassword}
//                     onChange={text => setConfirmPassword(text)}
//                     // onBlur={this.handleValidate}
//                     placeholder="Confirm password"
//                   />
//                   </div>

//                   <div className="form-group">
//                   <input
//                     type="password"
//                     pattern="\d*"
//                     maxLength="50"
//                     className="form-input"
//                     name="confpassword"
//                     value={confirmPassword}
//                     onChange={text => setConfirmPassword(text)}
//                     // onBlur={this.handleValidate}
//                     placeholder="Confirm password"
//                   />
//                   </div>

//                   <div className="form-group">
//                   <input
//                     type="password"
//                     pattern="\d*"
//                     maxLength="50"
//                     className="form-input"
//                     name="confpassword"
//                     value={confirmPassword}
//                     onChange={text => setConfirmPassword(text)}
//                     // onBlur={this.handleValidate}
//                     placeholder="Confirm password"
//                   />
//                   </div> */}

//                 <div className="form-group">
//                   <button
//                     // onClick={this.handleSubmit}
//                     // disabled={
//                     //   invalidconfpassword ||
//                     //   invalidNewPassword ||
//                     //   invalidPassword ||
//                     //   userHide
//                     // }
//                     className="form-submit"
//                     // disabled={this.state.isLoading}
//                   >
//                     Send
//                     {/* {this.state.isLoading ? (
//                       <i
//                         class="fa fa-spinner fa-spin"
//                         style={{ fontSize: "24px" }}
//                       ></i>
//                     ) : (
//                       ""
//                     )} */}
//                   </button>
//                 </div>
//               </div>
//               {/* <div className="col-md-7">
//                 <img
//                   className="px-5"
//                   src="./assets/images/update-password.jpg"
//                 />
//               </div> */}
//             </div>
//           </section>
//         </main>
//       </div>
//     );
//   }

// export default CreateCampaign;
