import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import CloseModal from "../../../Components/CloseModal";
import "./style.css";
import { cloneDeep } from "lodash";
import Select from "react-select";
import { getAllBranches, getGoldAuditLotByBranch, getRemainingPouches, viewDocument } from "../../../Utils/Services/goldAMS";
import { format } from "date-fns";
import ImageUploader from "react-images-upload";
import LotView from "./LotView";
import ClipLoader from "react-spinners/ClipLoader";

toast.configure();

let initForm = {
	id: "",
	auditorName: "",
	emailId: "",
	contactNumber: "",
	idProofDoc: "",
	idProofDocPath: "",
	idProofNumber: "",
	branch: "",
	auditStartDate: "",
	goldAuditLotsSet: {},
	errors: {
		auditorName: null,
		emailId: null,
		contactNumber: null,
		idProofDoc: null,
		idProofNumber: null,
		branch: null,
		auditStartDate: null,
		idProof: true,
	},
};

class AddGoldAudit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			isLoading: false,
			showInner: false,
			form: cloneDeep(initForm),
			branchList: [],
			goldAuditLotsSet: [],
			idProof: null,
			lotState: "no lot",
			imageUpload: false,
			uploadImageName: "",
			isLots: true,
			isLoadingLots: false,
		};
	}

	componentDidMount = () => {
		if (this.props.editData && this.props.action == "edit") {
			let { form } = this.state;
			console.log("this.props.editData", this.props.editData);
			getRemainingPouches(this.props.editData.id)
				.then((res) => {
					console.log(res.data);
					const newGoldAuditSet = {
						lots: [...res.data.lots],
						remainingPouches: res.data.remainingPouches,
					};
					console.log(newGoldAuditSet);
					let obj = {
						...form,
						...res.data.goldAuditorDTO,
						auditStartDate: new Date(res.data.auditStartDate),
						auditorName: res.data.goldAuditorDTO.name,
						auditCertificateDocPath: res.data.auditCertificateDocPath,
						timeTrackerDocPath: res.data.timeTrackerDocPath,
						branch: res.data.branch,
						goldAuditLotsSet: newGoldAuditSet,
						id: res.data.id,
					};
					obj.errors.idProof = null;
					console.log(obj);
					this.setState({
						form: obj,
						lotState: "Lot Generated",
						isLots: false,
					});
				})
				.catch((err) => {
					console.log({ err });
				});
		}
		this.setState({ showModal: true });
		this.getBranch();
	};

	handleModalClose = () => {
		this.setState({ showInner: !this.state.showInner });
	};

	getBranch = () => {
		getAllBranches().then((result) => {
			if (result.error) {
				return;
			} else if (result.data && result.data.error) {
				toast.error(result.data.message, {
					type: toast.TYPE.ERROR,
					autoClose: 2000,
				});
				return;
			} else {
				if (result && result.data && result.data.data) {
					this.setState({
						branchList: result.data.data,
					});
				}
			}
		});
	};

	getLot = (branch, auditId = null) => {
		console.log("branch check", branch, auditId);
		this.setState({ lotState: "no lot", isLoadingLots: true });
		getGoldAuditLotByBranch(branch, auditId).then((result) => {
			this.setState({ isLoadingLots: false })
			if (result.error && result.message) {
				toast.error(result.message, {
					type: toast.TYPE.ERROR,
					message: result.message,
					autoClose: 2000,
				});
				return;
			} else if (result.data && result.data.error) {
				toast.error(result.data.message, {
					type: toast.TYPE.ERROR,
					message: result.data.message,
					autoClose: 2000,
				});
				return;
			} else {
				if (result && result.data) {
					let { form } = this.state;
					form.goldAuditLotsSet = result.data;
					this.setState({
						lotState: "lot generated",
						form,
						isLots: false
					});
					console.log("check", result.data);
				}
			}
		});
	};

	innerModalClose = (modclose) => {
		if (!modclose) {
			this.handleModalClose();
		}
		this.setState({ showModal: false });
		if (this.props.action == "edit") {
			this.props.handleEditAuditModal();
		} else {
			this.props.handleAddAuditModal();
		}
	};

	handleInputChange = (e, name) => {
		let { form } = this.state;
		form[name] = e.target.value;
		this.setState({ form });
	};

	handleDateChange = (date) => {
		console.log("date", date);
		// console.log("formatted date", format(date, "yyyy-MM-dd hh:mm"));
		let { form } = this.state;
		form.auditStartDate = date;
		this.setState({ form });
	};

	onDrop = (file, base64, name) => {
		const { form } = this.state;
		const { errors } = form;
		errors.idProof = true;
		if (file.length <= 0) {
			this.setState({ idProof: file[0], form });
		}
		console.log("file", file);
		console.log("base64", base64);
		console.log("name", name);
		errors.idProof = null;
		this.setState({ idProof: file[0], form });
		this.setState({ imageUpload: true });

		if (file && file[0] && file[0].name)
			this.setState({ uploadImageName: file[0].name })
	};

	onValidate = (e, name) => {
		const { form } = this.state;
		const { errors } = form;
		const requiredName = form[name].trim();
		if (name === "emailId") {
			if (!requiredName.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && requiredName !== "") {
				errors[name] = "Invalid Email";
			} else {
				errors[name] = null;
			}
			this.setState({ form });
			return;
		}

		if (name === "contactNumber") {
			if (!requiredName.match(/^[6789]\d{9}$/)) {
				errors[name] = "Invalid Contact Number";
			} else {
				errors[name] = null;
			}
			this.setState({ form });
			return;
		}

		if (name === "auditorName") {
			if (!requiredName.match(/^[a-zA-Z0-9 ]{1,50}$/)) {
				errors[name] = "Invalid " + name;
			} else {
				errors[name] = null;
			}
			this.setState({ form });
			return;
		}

		if (name === "idProofDoc") {
			if (!requiredName.match(/^[a-zA-Z ]{1,20}$/)) {
				errors[name] = "Invalid " + name;
			} else {
				errors[name] = null;
			}
			this.setState({ form });
			return;
		}

		if (name === "idProofNumber") {
			//check 4 to 20 characters special characters - . / _ allowed
			if (!requiredName.match(/^[a-zA-Z0-9-_./]{1,20}$/)) {
				errors[name] = "Invalid Id Proof Number";
			} else {
				errors[name] = null;
			}
			this.setState({ form });
			return;
		}

		let value = requiredName;
		if (value === "" || value === null || value === undefined) {
			errors[name] = true;
			this.setState({ form });
		} else {
			errors[name] = null;
			this.setState({ form });
		}
	};

	handleSubmit = () => {
		this.props.addGoldAudit(this.state.form, this.state.idProof);
	};

	handleBranchChange = (e) => {
		console.log("branch changed", e);
		let { form } = this.state;
		let { branchName } = e;
		form.branch = branchName;
		this.setState({ form, lotState: "no lot" });
	};

	handleValidateDate = (e, name) => {
		let { form } = this.state;
		let { auditStartDate } = form;
		if (auditStartDate < new Date()) {
			this.setState({
				form: { ...form, errors: { ...form.errors, auditStartDate: "Please Enter Valid Date" } },
			});
			return;
		}
		if (form.auditStartDate) {
			this.setState({
				form: { ...form, errors: { ...form.errors, auditStartDate: null } },
			});
		}
	};

	generateLot = () => {
		this.getLot(this.state.form.branch);
	};

	handleSaveLot = (lots, remaining) => {
		let { form } = this.state;
		form.goldAuditLotsSet = lots;
		this.setState({ form });
	};

	cancelAudit = () => {
		this.handleModalClose();
		this.props.cancelAudit(this.props.editData.id);
	};

	render() {
		let { form, showModal, showInner, isLoading, branchList, lotState, idProof, imageUpload, uploadImageName } = this.state;
		let { errors } = form;
		let diableSave = false;

		console.log("form", form);

		Object.keys(errors).map((error) => {
			if (error != "emailId" && (errors[error] == true || errors[error] != null)) {
				console.log("ERROR IN", error, errors[error]);
				diableSave = true;
				return true;
			}
		});

		Object.keys(form).map((key) => {
			if (
				key === "auditId" ||
				key === "id" ||
				key === "idProofDocPath" ||
				key === "createdDate" ||
				key === "updatedDate" ||
				key === "idProof" ||
				key === "emailId" ||
				key === "auditCertificateDocPath" ||
				key === "timeTrackerDocPath"
			) {
				return;
			}
			if (form[key] == null || form[key] == "") {
				console.log("EMPTY IN", key, form[key]);
				diableSave = true;
				return true;
			}
		});

		const handleEmptyLot = (flag) => {
			this.setState({ isLots: flag });
		}

		let disableDueToStatus = false;
		let cancelAuditFlag = false;

		if(this?.props?.editData?.auditStatus === "COMPLETED" || this?.props?.editData?.auditStatus === "STARTED"){
		    disableDueToStatus = true;
			cancelAuditFlag = true;
		}

		if(this?.props?.editData?.auditStatus === "SCHEDULED"){
			let timeRemainingForAudit = (new Date(this.props.editData.auditStartDate) - new Date()) / 36e5;
            if(timeRemainingForAudit < 2)
			    disableDueToStatus = true;
		}

		return (
			<>
				<div>
					<Modal onHide={() => { this.handleModalClose() }} className="franking-fee-container" aria-labelledby="contained-modal-title-vcenter" centered show={showModal} size="lg">
						<Modal.Header closeButton>
							<Modal.Title>{this.props.action == "edit" ? "Edit Gold Audit" : "Add Gold Audit"}</Modal.Title>
						</Modal.Header>
						<Modal.Body className="p-4">
							{isLoading ? (
								<h4 className="text-center">LOADING...</h4>
							) : (
								<>
									<div className="row">
										<div className="form-group col-md-4 ">
											<label>Auditor's Name {<i className="text-danger">*</i>}</label>
											<div className="inner-addon right-addon">
												<input
													type="text"
													name="auditorName"
													value={form.auditorName && form.auditorName}
													onChange={(e) => {
														this.handleInputChange(e, "auditorName");
													}}
													onBlur={(e) => {
														this.onValidate(e, "auditorName");
													}}
													placeholder="Enter Auditor's Name"
													className={`form-input ${errors.auditorName ? "error-input-border" : ""} `}
													disabled={this.props.action === "edit" && disableDueToStatus}
												/>
												{errors.auditorName && <span className="errorMsg">Enter a Valid Name</span>}
											</div>
										</div>
										<div className="form-group col-md-4 ">
											<label>Auditor's Email {<i className="text-danger"></i>}</label>
											<div className="inner-addon right-addon">
												<input
													type="email"
													maxLength="255"
													name="emailId"
													value={form.emailId && form.emailId}
													onChange={(e) => {
														this.handleInputChange(e, "emailId");
													}}
													onBlur={(e) => {
														this.onValidate(e, "emailId");
													}}
													placeholder="Enter Auditor's Email"
													className={`form-input ${errors.emailId ? "error-input-border" : ""} `}
													disabled={this.props.action === "edit" && disableDueToStatus}
												/>
												{errors.emailId && <span className="errorMsg">Enter a valid Email</span>}
											</div>
										</div>
										<div className="form-group col-md-4 ">
											<label>Auditor's Contact No. {<i className="text-danger">*</i>}</label>
											<div className="inner-addon right-addon">
												<input
													type="number"
													onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
													name="contactNumber"
													value={form.contactNumber && form.contactNumber}
													onChange={(e) => {
														this.handleInputChange(e, "contactNumber");
													}}
													onBlur={(e) => {
														this.onValidate(e, "contactNumber");
													}}
													placeholder="Enter Auditor's Mobile No."
													className={`form-input ${errors.contactNumber ? "error-input-border" : ""} `}
													disabled={this.props.action === "edit" && disableDueToStatus}
												/>
												{errors.contactNumber && <span className="errorMsg">Enter a Valid Mobile Number</span>}
											</div>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-4 ">
											<label>ID Proof Document Type {<i className="text-danger">*</i>}</label>
											<div className="inner-addon right-addon">
												<input
													type="text"
													name="idProofDoc"
													value={form.idProofDoc && form.idProofDoc}
													onChange={(e) => {
														this.handleInputChange(e, "idProofDoc");
													}}
													onBlur={(e) => {
														this.onValidate(e, "idProofDoc");
													}}
													placeholder="Enter Document Type"
													className={`form-input ${errors.idProofDoc ? "error-input-border" : ""} `}
													disabled={this.props.action === "edit" && disableDueToStatus}
												/>
												{errors.idProofDoc && <span className="errorMsg">Enter Correct Document Type</span>}
											</div>
										</div>
										<div className="form-group col-md-4 ">
											<label>ID Proof Document Number {<i className="text-danger">*</i>}</label>
											<div className="inner-addon right-addon">
												<input
													type="text"
													maxLength="255"
													name="idProofNumber"
													value={form.idProofNumber && form.idProofNumber}
													onChange={(e) => {
														this.handleInputChange(e, "idProofNumber");
													}}
													onBlur={(e) => {
														this.onValidate(e, "idProofNumber");
													}}
													placeholder="Enter Document Number"
													className={`form-input ${errors.idProofNumber ? "error-input-border" : ""} `}
													disabled={this.props.action === "edit" && disableDueToStatus}
												/>
												{errors.idProofNumber && <span className="errorMsg">Enter Correct Document Number</span>}
											</div>
										</div>
										{/* <div className="form-group col-md-4">
											<label>Upload ID Proof Document{<i className="text-danger">*</i>}</label>
											<div className="inner-addon right-addon">
												<ImageUploader
													name="idProof"
													withIcon={true}
													withPreview={true}
													withLabel={true}
													accept="*"
													buttonText="ID Proof Document"
													onChange={(file, base64) => {
														this.onDrop(file, base64, "idProof");
													}}
													imgExtension={[".jpg", ".gif", ".png", ".jpeg", ".pdf", ".docx", ".pptx", ".xlsx"]}
													maxFileSize={5242880}
													singleImage
												/>
											</div>
										</div>
										{this.props.action === "edit" && (
											<>
												<div className="form-group col-md-4"></div>
												<div className="form-group col-md-4"></div>
												<div className="form-group col-md-4">
													<button className="btn btn-primary" onClick={() => viewDocument(this.props.editData.idProofDocPath)}>
														View Document
													</button>
												</div>
											</>
										)} */}
										<div className="col-md-4">
											<label>Audit Start Date {<i className="text-danger">*</i>}</label>
											<div>
												<DatePicker
													popperProps={{
														stratergy: "fixed",
													}}
													selected={form && form.auditStartDate}
													onBlur={this.handleValidateDate}
													onChange={this.handleDateChange}
													dateFormat="dd/MM/yyyy HH:mm"
													timeFormat="HH:mm"
													placeholderText="Select Date and Time"
													showYearDropdown
													showMonthDropdown
													showTimeSelect
													className={`form-input ${errors.startDate ? "error-input-border" : ""} `}
													disabled={this.props.action === "edit" && disableDueToStatus}
													minDate={new Date()}
													maxDate={new Date(new Date().getTime() + (24 * 60 * 60 * 1000 * 30))}
												/>
											</div>
											{errors.auditStartDate && <span className="errorMsg">Audit Time should be later</span>}
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-4">
											<label>Upload ID Proof Document{<i className="text-danger">*</i>}</label>
											<div className="right-addon" style={this.props.action === "edit" && disableDueToStatus ? { "pointer-events": "none", opacity: 0.7 } : {}}>
												<ImageUploader
													name="idProof"
													withIcon={false}
													withPreview={false}
													withLabel={true}
													accept="*"
													buttonText="ID Proof Document"
													onChange={(file, base64) => {
														this.onDrop(file, base64, "idProof");
													}}
													imgExtension={[".jpg", ".gif", ".png", ".jpeg", ".pdf", ".docx", ".pptx", ".xlsx"]}
													maxFileSize={5242880}
													singleImage
												/>
											</div>
											{
												imageUpload ? <div>*{uploadImageName}</div> : null
											}
											{this.props.action === "edit" && (
												<div style={{ "margin-top": "10px" }}>
													<button style={{ width: "100%" }} className="btn btn-primary" onClick={() => viewDocument(form.idProofDocPath)}>
														View Document
													</button>
												</div>

											)}
										</div>


										<div className="form-group col-md-4">
											<label>
												Select Branch <i className="text-danger">*</i>
											</label>
											<Select
												menuPortalTarget={document.body}
												styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
												value={branchList.filter((item) => item.branchName == form.branch)[0]}
												onChange={(e) => {
													this.handleBranchChange(e, "branch");
												}}
												onBlur={(e) => this.onValidate(e, "branch")}
												name="branch"
												options={branchList}
												placeholder="Select Branch"
												valueKey="branch"
												labelKey="branch"
												getOptionLabel={(option) => option["branchName"]}
												getOptionValue={(option) => option["branchName"]}
												className={`${errors.branch ? "error-input-border" : ""} `}
												isDisabled={this.props.action === "edit" && disableDueToStatus}
											/>
											{errors.branch && <span className="errorMsg">Please Select Branch</span>}


										</div>
										<div className="form-group col-md-4">
											<label>Audit Lot</label>
											<div className="inner-addon right-addon">
												<Button className="btn-success" disabled={form.branch == "" || this.props.action === "edit" && disableDueToStatus} style={{ width: "100%" }} onClick={this.generateLot}>
													Generate Lot
												</Button>
											</div>
										</div>
										{/* <div className="col-md-4">
											<label>Audit Start Date {<i className="text-danger">*</i>}</label>
											<div>
												<DatePicker
													popperProps={{
														stratergy: "fixed",
													}}
													selected={form && form.auditStartDate}
													onBlur={this.handleValidateDate}
													onChange={this.handleDateChange}
													dateFormat="dd/MM/yyyy HH:mm"
													timeFormat="HH:mm"
													placeholderText="Select Date and Time"
													showYearDropdown
													showMonthDropdown
													showTimeSelect
													className={`form-input ${errors.startDate ? "error-input-border" : ""} `}
													disabled={false}
													minDate={new Date()}
												/>
											</div>
											{errors.auditStartDate && <span className="errorMsg">{errors.auditStartDate}</span>}
										</div> */}
										{/* <div className="form-group">
											<label>Audit Lot</label>
											<div className="inner-addon right-addon">
												<Button className="btn-success" disabled={form.branch == ""} onClick={this.generateLot}>
													Generate Lot
												</Button>
											</div>
										</div> */}
										{/* <div className="dummy-div"></div> */}
									</div>
									<div className="row" style={this.props.action === "edit" && disableDueToStatus ? { "pointer-events": "none", opacity: 0.7 } : {}}>
										{this.state.isLoadingLots ?
											<div className="col-12" style={{ display: "flex", "justifyContent": "center" }}>
												<div><ClipLoader color="#17a2b8" size={60} /></div>
											</div>
											:
											lotState !== "no lot" && form.goldAuditLotsSet.lots && form.goldAuditLotsSet.lots.length > 0 && (
												<LotView handleEmptyLot={(flag) => handleEmptyLot(flag)} lotInfo={form.goldAuditLotsSet} branch={form.branch} handleSaveLot={this.handleSaveLot} />
											)}
									</div>
								</>
							)}
						</Modal.Body>
						<Modal.Footer style={{ display: "inline-block" }}>
							{this.props.action === "edit" && this.props.editData.auditStatus === "COMPLETED" &&
								<div style={{ float: "left" }}>
									<Button className="btn-success" onClick={() => viewDocument(form.auditCertificateDocPath)}>Audit Certificate</Button>
									<Button className="btn-info" onClick={() => viewDocument(form.timeTrackerDocPath)}>Time Tracker Sheet</Button>
								</div>}
							<div>
								{this.props.action === "edit" && (
									<Button className="btn-danger" onClick={this.cancelAudit} disabled={this.props.action === "edit" && cancelAuditFlag}>
										Cancel Audit
									</Button>
								)}
								<Button className="btn-success" disabled={diableSave || isLoading || this.state.isLots || this.props.action === "edit" && disableDueToStatus} onClick={this.handleSubmit}>
									{this.props.action == "edit" ? "Update" : "Create"}
								</Button>
							</div>
						</Modal.Footer>
					</Modal>
					<CloseModal show={showInner} button2={this.handleModalClose} button1={this.innerModalClose} title="Do you want to close ?" />
				</div>
			</>
		);
	}
}

export default AddGoldAudit;