import { get, post } from "../httpInterceptor";

// API for delete DFP master

export const deleteDfpMasterById = (obj) => {
  return post(`/sarvagram-credit/master/deleteDfpbyid`, obj, false).then(
    (res) => {
      console.log("deleteDfpMasterById", res);
      return res;
    }
  );
};

// API for get DFP master

export const getAllDfpMasterData = () => {
  return get(`/sarvagram-credit/master/findAllDfp`).then((res) => {
    console.log("RESPONSE :: getAllDfpMasterData ::: ", res);
    return res;
  });
};

/* export const addProductFund = (obj) => {
    return post(`/sarvagram-credit/master/saveorupdateProductFunds`, obj,
        false
    ).then(res => {
        console.log("addProductFund", res);
        return res;
    });
} */

// API for save or update DFP master

export const addOrUpdateDfpMaster = (obj) => {
  return post(`/sarvagram-credit/master/saveOrUpdateDfp`, obj, false).then(
    (res) => {
      console.log("addOrUpdateDfpMaster", res);
      return res;
    }
  );
};

// API for get DFP master

export const getDfpMasterById = (obj = {}) => {
  return post(`/sarvagram-credit/master/findDfpbyid`, obj, false).then(
    (res) => {
      console.log("getDfpMasterById", res);
      return res;
    }
  );
};

// API for find all role dropdown

export const findallroledropdown = () => {
  return get(`/findAllRolesForDfp/`).then((res) => {
    console.log("RESPONSE :: findallroledropdown ::: ", res);
    return res;
  });
};

/* export const getAllPropertyDropdown = () => {
    return get(`/sarvagram-credit/master/findallpropertytyperdropdown`).then(res => {
        console.log("RESPONSE :: getAllPropertyDropdown ::: ", res);
        return res;
    });
};

export const getAllSubtypeDropdown = () => {
    return get(`/sarvagram-credit/master/findallpropertysubtyperdropdown`).then(res => {
        console.log("RESPONSE :: getAllSubtypeDropdown ::: ", res);
        return res;
    });
}; */
