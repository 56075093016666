import React, { Component } from "react";
import axios from "axios";
import { Button, Modal, Form } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import ImageUploader from "react-images-upload";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import JSZip from "jszip";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { FileUrl } from "../../../../Utils/config";
import { viewDocument } from "../../../../Utils/Services/documents";
import {
  SpinnerLoader,
  FullScreenLoader,
} from "../../../../Components/Assets/Loader";
import { CloseSection } from "../../../../Components/Assets/CloseSection";
import {
  saveOrUpdatefranchise,
  uploadFranchiseAppraiserDoc,
  checkFranchiseDedupe,
  findallvillagesdropdown,
} from "../../../../Utils/Services/franchisemaster";
import { findfranchiseByApplication } from "../../../../Utils/Services/FranchiseAppraiser";
import {
  getTypeCompany,
  getAddressByPincode,
  getAllBranch,
  verifyPanNumber,
  verifyDrivingLicense,
  verifyVoterID,
  verifyPassport,
  verifyBankAccountDetails,
  verifyGst,
} from "../../../../Utils/Services/common";
import { cloneDeep } from "lodash";
import "../style.css";

toast.configure();

let gstListDoc = [
  {
    emailId: "",
    applicationStatus: "",
    mobNum: "",
    registrationName: "",
    gstinRefId: "",
    regType: "",
    authStatus: "Inactive",
    tinNumber: "",
    pan: "AAACR5055K",
    gstinId: "03AAACR5055K1ZH",
  },
  {
    emailId: "",
    applicationStatus: "",
    mobNum: "",
    registrationName: "",
    gstinRefId: "",
    regType: "",
    authStatus: "Active",
    tinNumber: "",
    pan: "AAACR5055K",
    gstinId: "27AAACR5055K3Z5",
  },
  {
    emailId: "",
    applicationStatus: "",
    mobNum: "",
    registrationName: "",
    gstinRefId: "",
    regType: "",
    authStatus: "Active",
    tinNumber: "",
    pan: "AAACR5055K",
    gstinId: "05AAACR5055K1ZD",
  },
  {
    emailId: "",
    applicationStatus: "",
    mobNum: "",
    registrationName: "",
    gstinRefId: "",
    regType: "",
    authStatus: "Inactive",
    tinNumber: "",
    pan: "AAACR5055K",
    gstinId: "29AAACR5055K2Z2",
  },
];

//upload doc
let mandatoryDoc = ["kycDocument", "Agreement", "payoutDocument"];

let initForm = {
  typeOfCompany: "",
  bureauuscore: "",
  panNumber: "",
  dateOfEstablishment: "",
  name: "",
  franchiseCode: "",
  gstNumber: "",
  drivingLicenseNumber: "",
  voterIdNumber: "",
  passPortNumber: "",
  panNumber: "",
  drivingLicenceNumber: "",
  drivingLicenceDateOfBirth: "",
  passportDateOfBirth: "",
  passportFileNumber: "",
  epicNo: "",
  dateOfbirth: "",
  securityDeposit: "",
  dlNo: "",
  dob: "",
  passportNo: "",
  fileNo: "",
  doi: "",
  location: "",
  registeredAddress: {
    state: "",
    city: "",
    branch: "",
    pinCode: "",
    contactName: "",
    mobileNumber: "",
    emailId: "",
    address: "",
  },
  communicationAddress: {
    state: "",
    city: "",
    branch: "",
    pinCode: "",
    contactName: "",
    mobileNumber: "",
    emailId: "",
    address: "",
  },
  isregisteraddsameascommddress: false,
  doYouHaveGST: false,
  bankAccountName: "",
  bankName: "",
  accountNumber: "",
  ifscCode: "",
  panNumberVerified: false,
  drivingLicenceNumberVerified: false,
  passportNumberVerified: false,
  bankVerified: false,
  voterIdVerified: false,
  remark: "",
};

let documentObj = {
  documentName: "",
  documentPath: "",
  description: "",
  uploadedBy: "",
};

// let documentObj = { documentName: "", documentPath: "", description: "", uploadedBy: "" }
class FranchiseApprovalMasterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchList: [],
      typeofcompanyList: [],
      gstList: [],
      isDis: true,

      isDedupe: false,
      previousAppraiserCode: "",
      showActivateDeactivatemodal: false,
      docId: null,
      showDeletemodal: false,
      previousStatus: "",
      verifyingBank: false,
      key: Math.random(),
      form: cloneDeep(initForm),
      show: false,
      showInner: false,
      loading: false,
      invalidPanNumber: false,
      invalidDrivingNumber: false,
      invalidPassport: false,
      invalidpassportFileNumber: false,
      isVerifyingPan: false,
      invalidvoterIdNumber: false,
      verifyingDriving: false,
      verifyingPassport: false,
      verifyingvoterIdNumber: false,
      demandPromissoryNote: [],
      appraiserAppointment: [],
      aggrement: [],
      payoutdoc: [],
      dpnLetter: [],
      chequeGaurantee: [],
      passportPhoto: [],
      resume: [],
      shopRegistration: [],
      kycDocument: [],
      substituteAppraiser: [],
      bmInterviewSheet: [],
      form60: [],
      otherDocument1: [],
      otherDocument2: [],
      otherDocument3: [],
      otherDocument4: [],
      userZone: [],
      zoneDropdownValue: [],
      testvalue: "",
      errors: {
        panAvailability: null,
        panNumber: null,
        passportFileNumber: null,
        passportFileNumber: null,
        PanNumberError: null,
        DrivingError: null,
        PassportError: null,
        EpicNoError: null,
        passportDateOfBirth: null,
        drivingLicenceDateOfBirth: null,
        dateOfEstablishment: null,
        companyName: null,
        franchiseCode: null,
        gstNumber: null,
        otherBankAppraiserName: null,
        yearOfExperienceInGoldOrnaments: null,
        ownJewellerycompanyName: null,
        instituteNameOfDegree: null,
        accountNumber: null,
        bankAccountName: null,
        bankName: null,
        accountNumber: null,
        isValidIfsc: null,
        validAccountNumber: null,
        location: null,
        registeredAddress: {
          state: null,
          city: null,
          branch: null,
          pinCode: null,
          contactName: null,
          mobileNumber: null,
          address: null,
          emailId: null,
          validPincode: null,
          validEmail: null,
          validMobile: null,
        },
        communicationAddress: {
          state: null,
          city: null,
          branch: null,
          pinCode: null,
          contactName: null,
          mobileNumber: null,
          emailId: null,
          address: null,
          validPincode: null,
          validEmail: null,
          validMobile: null,
        },
        demandPromissoryNote: null,
        appraiserAppointment: null,
        dpnLetter: null,
        aggrement: null,
        payoutdoc: null,
        chequeGaurantee: null,
        passportPhoto: null,
        resume: null,
        shopRegistration: null,
        kycDocument: null,
        substituteAppraiser: null,
        bmInterviewSheet: null,
        form60: null,
        aggrement: null,
        payoutdoc: null,
        otherDocument1: null,
        otherDocument2: null,
        otherDocument3: null,
        otherDocument4: null,
      },
    };
    // this.handleCheck = this.handleCheck.bind(this);
  }

  //third party api

  componentDidMount = () => {
    this.handleShow();
    this.getcompanyList();
    this.getBranchList();
    this.getfranchiseAppraiserByAppicationId();
    this.allvillagesdropdown();
  };

  handleViewDoc = (path) => {
    viewDocument(path);
  };

  //fetch all villages
  allvillagesdropdown = () => {
    findallvillagesdropdown().then((res) => {
      console.log("village", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        return;
      } else {
        this.setState({ zoneDropdownValue: res.data.data });
      }
    });
  };

  handlePanNumber = () => {
    // this.handleVerifyVoterId();
    this.handleVerifyPan();
    // this.handleVerifyPassport();
    // this.handleVerifyDriving();
  };

  handleCheck() {
    let { form } = this.state;
    form.doYouHaveGST = !form.doYouHaveGST;
    if (form.doYouHaveGST == true) {
      this.setState({ form });
    }
  }

  //verify pan number

  getcompanyList = () => {
    getTypeCompany().then((res) => {
      let companyTypeList = res.data.typeofcompanyList;
      // console.log(companyTypeList)
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        return;
      } else {
        this.setState({ typeofcompanyList: companyTypeList });
      }
    });
  };

  //fetch all branch list
  getBranchList = () => {
    getAllBranch().then((res) => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        return;
      } else {
        if (res.data && res.data.data && res.data.data.length > 0) {
          this.setState({ branchList: res.data.data });
        }
      }
    });
  };

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  handleClose = (close) => {
    this.props.history.push("/Franchise-approval-master");
    if (!close) this.close();
    this.setState({ show: false });
    // this.props.addUser();
  };

  close = (close) => {
    this.setState({ showInner: !this.state.showInner });
  };

  handleChange = (e, name) => {
    console.log("handleChange", e, name);
    let { form } = this.state;
    let { id } = e;
    form[name] = id;
    this.setState({ form });
  };

  handleCompanySelectChange = (e, name, value) => {
    console.log("handlebranchSelectChange", e, name, value);

    let { form } = this.state;
    let { companytype } = e;
    form[name] = companytype;

    this.setState({ form, key: Math.random() });
  };

  //location

  // handleLocationSelectChange = (e, name) => {
  //   console.log("handleLocationSelectChange", e, name);

  //   let { form, errors } = this.state;
  //   let { villageName } = e;
  //   form[name] = villageName;

  //   this.setState({ form, key: Math.random() });
  // };

  onValidateCompany = (e, name) => {
    const { errors, form } = this.state;
    let value = form[name].typeOfCompany;
    if (value === "" || value === null || value === undefined) {
      errors[name] = true;
      this.setState({ errors });
    } else {
      errors[name] = false;
      this.setState({ errors });
    }
  };

  //location
  // onValidateLocation = (e, name) => {
  //   const { errors, form } = this.state;
  //   let value = form[name].location;
  //   if (value == "" || value == null || value == undefined) {
  //     errors[name] = true;
  //     this.setState({ errors });
  //   } else {
  //     errors[name] = false;
  //     this.setState({ errors });
  //   }
  // };

  onValidatebranch = (e, name) => {
    const { errors, form } = this.state;
    let value = form[name].branch;
    if (value === "" || value === null || value === undefined) {
      errors[name].branch = true;
      this.setState({ errors });
    } else {
      errors[name].branch = false;
      this.setState({ errors });
    }
  };

  handleRadioChange = (e, name) => {
    let { form, errors } = this.state;
    console.log("handleRadioChange", e.target, form[name]);
    if (name == "isregisteraddsameascommddress") {
      form[name] = !form[name];
      this.setState({ form }, () => {
        let { form } = this.state;
        if (form.isregisteraddsameascommddress == true) {
          let { registeredAddress } = form;
          let { communicationAddress } = errors;
          form.communicationAddress = cloneDeep(registeredAddress);
          errors.communicationAddress = cloneDeep({
            state: null,
            city: null,
            branch: null,
            pinCode: null,
            contactName: null,
            mobileNumber: null,
            emailId: null,
            address: null,
            validPincode: null,
            validEmail: null,
            validMobile: null,
          });
          this.setState({ form, errors, key: Math.random() });
        } else {
          console.log("handleRadioChange else.....");
          form.communicationAddress = cloneDeep({
            state: "",
            city: "",
            branch: "",
            pinCode: "",
            contactName: "",
            mobileNumber: "",
            emailId: "",
            address: "",
          });
          let { communicationAddress } = errors;
          errors.communicationAddress = cloneDeep({
            state: null,
            city: null,
            branch: null,
            pinCode: null,
            contactName: null,
            mobileNumber: null,
            emailId: null,
            address: null,
            validPincode: null,
            validEmail: null,
            validMobile: null,
          });
          this.setState({ form, errors, key: Math.random() });
        }
      });
    } else {
      if (
        name == "gstNumber" ||
        name == "franchiseCode" ||
        name == "ifscCode"
      ) {
        form[name] = e.target.value.toUpperCase();
      } else {
        form[name] = e.target.value;
      }
      this.setState({ form });
    }
  };

  handleDateOfEstablishment = (date, e) => {
    let { form } = this.state;
    form.dateOfEstablishment = date;
    this.setState({ form });
  };

  handleValidatebureauscore = (e, name) => {
    e.preventDefault();
    const { errors, form } = this.state;
    let value = form.bureauscore;
    //this.setState({ errors });
    if (value == "0") {
      form.bureauuscore = null;
      this.setState({ form });
    } else if (value == null) {
      errors[name] = "Enter Bureau Score In Range";
      form.isVerifyingbureau = false;
      this.setState({ errors });
    } else if (value > 900 || value < 300 || value.length !== 3) {
      errors[name] = true;
      this.setState({ errors });
      form.isVerifyingbureau = false;
    } else {
      form.isVerifyingbureau = true;
      errors[name] = false;
      this.setState({ errors });
    }
  };

  handledrivingLicenceDateOfBirth = (date, e) => {
    let { form } = this.state;
    form.drivingLicenceDateOfBirth = date;

    // date =
    //   form.drivingLicenceDateOfBirth.getDate() +
    //   "-" +
    //   parseInt(form.drivingLicenceDateOfBirth.getMonth() + 1) +
    //   "-" +
    //   form.drivingLicenceDateOfBirth.getFullYear();
    console.log("handledrivingLicenceDateOfBirth", date, e);

    this.setState({ form });
  };

  handlepassportDateOfBirth = (date, e) => {
    let { form } = this.state;
    form.passportDateOfBirth = date;

    // date =
    //   form.drivingLicenceDateOfBirth.getDate() +
    //   "-" +
    //   parseInt(form.drivingLicenceDateOfBirth.getMonth() + 1) +
    //   "-" +
    //   form.drivingLicenceDateOfBirth.getFullYear();
    console.log("handlepassportDateOfBirth", date, e);

    this.setState({ form });
  };

  handleGstChange = (e, name) => {
    console.log("handleGstChange", e, name);
    let { form } = this.state;

    let { gstinId } = e;
    form.gstNumber = gstinId;
    this.setState({ form });
  };

  getGstList = () => {
    let { form } = this.state;

    this.setState({ isVerifyingGst: true });
    let data = {
      panNumber: form.panNumber,
    };

    verifyGst(data).then((res) => {
      // let gstList = res.data.data
      this.setState({ isVerifyingGst: false });
      if (res.error) {
        return;
      }
      if (res.data.error) {
        /* toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false }); */
        return;
      } else {
        this.setState({ gstList: res.data.data, key: Math.random() });
      }
    });
  };

  handlePan = (e) => {
    let { errors, form } = this.state;
    if (e.target.value.trim() !== "") {
      errors = { ...errors, PanNumberError: false };
    } else {
      errors = { ...errors, PanNumberError: true };
    }
    form.panNumber = e.target.value.toUpperCase();
    this.setState({
      form,
      errors,
    });
  };
  handlebureauscore = (e, name) => {
    let { form } = this.state;
    form[name] = e.target.value;
    this.setState({ form });
  };
  handleDrive = (e) => {
    let { errors, form } = this.state;
    if (e.target.value.trim() !== "") {
      errors = { ...errors, DrivingError: false };
    } else {
      errors = { ...errors, DrivingError: true };
    }
    form.drivingLicenceNumber = e.target.value.toUpperCase();
    this.setState({
      form,
      errors,
    });
  };

  handleEpicNo = (e) => {
    let { errors, form } = this.state;
    if (e.target.value.trim() !== "") {
      errors = { ...errors, EpicNoError: false };
    } else {
      errors = { ...errors, EpicNoError: true };
    }
    form.voterIdNumber = e.target.value.toUpperCase();
    this.setState({
      form,
      errors,
    });
  };

  handlePassport = (e) => {
    let { errors, form } = this.state;
    if (e.target.value.trim() !== "") {
      errors = { ...errors, PassportError: false };
    } else {
      errors = { ...errors, PassportError: true };
    }
    form.passportFileNumber = e.target.value.toUpperCase();
    this.setState({
      form,
      errors,
    });
  };

  restrictAlphabets = (e) => {
    const regx = "^[0-9]*$";
    if (e.key.match(regx)) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  handleAppraiserDedupe = (appraiserCode) => {
    const { errors, form } = this.state;
    checkFranchiseDedupe({ franchiseCode: appraiserCode }).then((res) => {
      // console.log("checkFranchiseDedupe", res)
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        return;
      } else {
        let isDedupe = false;
        if (
          res.data &&
          res.data.data &&
          res.data.data.appraiserCodeAvailable &&
          res.data.data.appraiserCodeAvailable == true
        ) {
          errors.franchiseCode = "Franchise code already exists.";
          isDedupe = true;
        } else {
          errors.franchiseCode = false;
          isDedupe = false;
        }
        this.setState({ errors, isDedupe });
      }
    });
  };

  handleValidate = (e, name) => {
    const { errors, form } = this.state;
    console.log("shhh", name, form[name], name == "otherBankAppraiserWorking");

    let value = form[name];
    let nonMandatory = [
      "companyName",
      "dateOfEstablishment",
      "drivingLicenceDateOfBirth",
      "passportDateOfBirth",
    ];
    if (
      (value === "" || value === null || value === undefined) &&
      !nonMandatory.includes(name)
    ) {
      errors[name] = true;
      this.setState({ errors });
    } else {
      errors[name] = false;
      this.setState({ errors });
    }

    if (name == "franchiseCode" && value != "") {
      this.handleAppraiserDedupe(value);
    }

    if (name === "ifscCode") {
      if (value.trim() == "") {
        this.setState({ errors: { ...errors, ifscCodeError: true } });
      } else {
        this.setState({ errors: { ...errors, ifscCodeError: false } });
      }
    }

    if (name === "typeOfCompany") {
      let { errors } = this.state;
      if (form.typeOfCompany == "") {
        errors.typeOfCompany = true;
        this.setState({
          errors,
        });
      } else {
        if (form[name] === form.typeofcompanyList) {
          errors.typeOfCompany = false;
        }
      }
    }

    if (name === "panNumber" && errors.panNumber == false) {
      var pan_value = value.toUpperCase();
      var reg =
        /([a-zA-Z]{3})([PCHFATBLJGE]{1})([a-zA-Z]{1})([0-9]{4})([a-zA-Z]{1})/;

      if (pan_value.match(reg)) {
        //this.getGstList();
        this.setState({
          invalidPanNumber: false,
        });
      } else {
        if (pan_value != "")
          this.setState({
            invalidPanNumber: true,
          });
      }
    }

    if (
      name === "drivingLicenceNumber" &&
      errors.drivingLicenceNumber == false
    ) {
      var pan_value = value.toUpperCase();
      var reg = /([a-zA-Z]{2})([0-9]{13})/;

      if (pan_value.match(reg)) {
        this.setState({
          invalidDrivingNumber: false,
        });
      } else {
        if (pan_value != "")
          this.setState({
            invalidDrivingNumber: true,
          });
      }
    }

    if (name === "passportFileNumber" && errors.passportFileNumber == false) {
      var pan_value = value.toUpperCase();
      var reg = /([a-zA-Z]{2})([0-9]{13})/;

      if (pan_value.match(reg)) {
        this.setState({
          invalidpassportFileNumber: false,
        });
      } else {
        if (pan_value != "")
          this.setState({
            invalidpassportFileNumber: true,
          });
      }
    }
    if (name === "voterIdNumber") {
      var pan_value = value.toUpperCase();
      var reg = /([a-zA-Z]{3})([0-9]{7})/;

      if (pan_value.match(reg)) {
        this.setState({
          invalidvoterIdNumber: false,
        });
      } else {
        if (pan_value != "")
          this.setState({
            invalidvoterIdNumber: true,
          });
      }
    }

    if (name === "permanantAddress") {
      if (value && value.length > 700) {
        this.setState({
          permanantAddressError: true,
        });
      }
    }

    if (name == "accountNumber") {
      if (
        (e.target.value.trim().length == 9 ||
          e.target.value.trim().length > 9) &&
        (e.target.value.trim().length == 16 ||
          e.target.value.trim().length < 16)
      ) {
        errors.validAccountNumber = false;
        this.setState({ errors });
      } else {
        errors.validAccountNumber = true;
        this.setState({ errors });
      }
    }

    if (name == "ifscCode") {
      let verifyIfsc = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/;
      if (verifyIfsc.test(value)) {
        errors.isValidIfsc = false;
        this.setState({ errors });
      } else {
        errors.isValidIfsc = true;
        this.setState({ errors });
      }
    }

    /* if (name == "panNumber" && form.companyPanNumberVerified == true) {
            form.companyName = "";
            form.companyPanNumberVerified = false;
            this.setState({ form });
        } */

    if (
      (name == "accountNumber" || name == "ifscCode") &&
      form.bankVerified == true
    ) {
      form.bankVerified = false;
      this.setState({ form });
    }
  };

  onDrop = async (file, picture, name) => {
    let { errors } = this.state;
    console.log("file", file);
    console.log("picture", picture.length);
    console.log("name", name);
    if (mandatoryDoc.includes(name)) {
      if (picture.length > 0) {
        errors = { ...errors, [name]: false };
      } else {
        errors = { ...errors, [name]: true };
      }
    }
    this.setState({ [name]: file, errors: errors });
  };

  handleBankVerification = () => {
    let { form } = this.state;
    this.setState({ verifyingBank: true });

    let data = {
      consent: "Y",
      ifsc: form.ifscCode,
      accountNumber: form.accountNumber,
    }
     
    verifyBankAccountDetails(data).then((res) => {
      this.setState({ verifyingBank: false });
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        console.log("res.data", res.data);
        if (res?.data?.bankTxnStatus) {
          form.bankVerified = true;
          this.setState({ form });
        }else {
          toast.error("Bank Account Not Verified, please check entered details once!")
        }
      }
    });
  };

  getfranchiseAppraiserByAppicationId = () => {
    let { verifyObj } = this.props;
    console.log(verifyObj);
    this.setState({ loading: true });
    findfranchiseByApplication({ applicationNumber: verifyObj }).then((res) => {
      this.setState({ loading: false });
      if (res.error) {
        this.setState({ loading: false });

        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        // console.log("res.data/.///", res.data);
        if (
          res.data &&
          res.data.data &&
          Object.keys(res.data.data).length > 0
        ) {
          this.setState(
            {
              form: res.data.data,
              key: Math.random(),
              previousStatus: res.data.data.status,
              userZone: res.data && res.data.data && res.data.data.village,
              testvalue: res.data && res.data.data && res.data.data.location,
            }
            /*() => {
              this.getGstList();
            }*/
          );
        }
      }
    });
  };

  handleApproveReject = (e, status) => {
    e.preventDefault();
    let { form, errors } = this.state;
    form.status = status;
    if (status == "APPROVED") {
      errors.remark = false;
      form.remark = "";
    }
    if (status == "REJECTED") {
      errors.remark = true;
    }
    this.setState({ form, errors });
  };

  handleSubmit = () => {
    let { form, errors } = this.state;
    let obj = form;
    if (form.status == "APPROVED") {
      obj.active = true;
    } else {
      obj.active = false;
    }
    this.setState({ loading: true });

    //save and update api franchise data
    // console.log("Save", form);
    saveOrUpdatefranchise(obj).then((res) => {
      if (res.error) {
        this.setState({ loading: false });

        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        console.log("res.data", res.data && res.data.data);
        // this.ImageConverToZipAndUpload(res.data.data.applicationNumber)
        let msg =
          res.data &&
          res.data.data &&
          res.data.data.status &&
          res.data.data.status == "REJECTED"
            ? "Franchise appraiser rejected successfully"
            : res.data &&
              res.data.data &&
              res.data.data.status &&
              res.data.data.status == "APPROVED"
            ? (form &&
                form.communicationAddress &&
                form.communicationAddress.emailId == "") ||
              (form &&
                form.communicationAddress &&
                form.communicationAddress.emailId == null)
              ? "Franchise appraiser approved successfully"
              : "Franchise appraiser approved successfully and Mail has been sent to the Franchisee along with reset password link and OTP"
            : "";
        toast.success(msg, {
          type: toast.TYPE.SUCCESS,
          autoClose: 4000,
        });
        this.setState({ loading: false, show: false });
        this.props.fetchAppraiser();
      }
    });

    //get franchise list created
    // getfranchiselist(form).then((res) => {
    //     if (res.error) {
    //         this.setState({ loading: false });

    //         return;
    //     }
    //     if (res.data.error) {
    //         toast.error(res.data.message, {
    //             type: toast.TYPE.ERROR,
    //             autoClose: 4000
    //         });
    //         this.setState({ loading: false });
    //         return;
    //     } else {
    //         console.log("res.data", res.data);

    //     }
    // })
  };

  handleDrivingVerification = () => {
    let { form } = this.state;
    this.setState({ verifyingDriving: true });
    console.log("date", form.drivingLicenceDateOfBirth);
    console.log(
      "date",
      moment(new Date(form.drivingLicenceDateOfBirth))
        .format("DD-MM-YYYY")
        .toLocaleString()
    );
    let data = {
      dlNo: form.drivingLicenceNumber,
      dob: moment(new Date(form.drivingLicenceDateOfBirth))
        .format("DD-MM-YYYY")
        .toLocaleString(),
    };

    verifyDrivingLicense(data).then((res) => {
      this.setState({ verifyingDriving: false });
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        console.log("res.data", res.data.data);
        form.drivingLicenceNumberVerified = true;
        this.setState({ form });
      }
    });
  };
  //Voter ID authentication
  handleVoterIdVerification = () => {
    let { form } = this.state;
    this.setState({ verifyingvoterIdNumber: true });

    let data = {
      epicNo: form.voterIdNumber,
    };

    verifyVoterID(data).then((res) => {
      this.setState({ verifyingvoterIdNumber: false });
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        console.log("res.data", res.data.data);
        form.voterIdVerified = true;
        this.setState({ form });
      }
    });
  };

  //security deposit

  handleSequrity = (e) => {
    let { form } = this.state;

    form.securityDeposit = e.target.value;
    this.setState({
      form,
    });
  };

  //verify passport

  handlePassportVerification = () => {
    let { form } = this.state;
    this.setState({ verifyingPassport: true });
    console.log("date", form.passportDateOfBirth);
    console.log(
      "date",
      moment(new Date(form.passportDateOfBirth)).format("DD/MM/YYYY")
    );
    let data = {
      fileNo: form.passportFileNumber,
      dob: moment(new Date(form.passportDateOfBirth)).format("DD/MM/YYYY"),
    };

    verifyPassport(data).then((res) => {
      this.setState({ verifyingPassport: false });
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        console.log("res.data", res.data.data);
        form.passportNumberVerified = true;
        this.setState({ form });
      }
    });
  };

  render() {
    let {
      isDedupe,
      showActivateDeactivatemodal,
      showDeletemodal,
      typeofcompanyList,
      gstList,
      isDis,
      showInner,
      show,
      loading,
      errors,
      form,
      invalidPanNumber,
      invalidDrivingNumber,
      invalidPassport,
      invalidpassportFileNumber,
      invalidvoterIdNumber,
      verifyingvoterIdNumber,
      verifyingPassport,
      isVerifyingPan,
      verifyingDriving,
      key,
      previousStatus,
      verifyingBank,
      branchList,
      zoneDropdownValue,
      userZone,
      testvalue,
    } = this.state;
    console.log("tst", testvalue);
    console.log("user", userZone);
    console.log("ff", form);

    let { registeredAddress, communicationAddress } = form;

    let disableSave = false;
    if (
      (form &&
        form.status == "REJECTED" &&
        (form.remark == "" ||
          form.remark == null ||
          form.remark == undefined)) ||
      (form && form.status == "PENDING")
    ) {
      disableSave = true;
    }

    let zoneobj = [];
    zoneDropdownValue.map((res) => {
      userZone &&
        userZone.map((val) => {
          if (res.villageCode == val.villageCode) {
            zoneobj.push(res);
          }
          console.log("zon", zoneobj);
        });
    });

    let comList =
      typeofcompanyList &&
      typeofcompanyList.length > 0 &&
      typeofcompanyList.filter(
        ({ companytype }) => companytype == form.typeOfCompany
      )[0];

    console.log(
      "formdata",
      form && form.communicationAddress && form.communicationAddress.emailId
    );
    return (
      <>
        {branchList && branchList.length > 0 ? (
          <>
            <div className="disable-div">
              <div className="disable-div"></div>
              <FullScreenLoader show={false} />
              <ToastContainer />
              <Modal
                className="add-user"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show && show}
                id="gold-appraiser"
                onHide={() => {
                  this.close();
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="w-100">
                    Franchise appraiser master form
                    <span className="right"> {`${previousStatus}`} </span>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  className={`p-4 `}
                  style={{ background: "#f8f9fa" }}
                >
                  <div className="row">
                    <section className="tab-body mt-3 mb-3">
                      <div className="row">
                        <h5 className="col-md-12 text-center mb-4">
                          Company Details
                        </h5>
                        <div className="col-md-4">
                          <label>
                            Type Of Franchise {<i className="text-danger">*</i>}
                          </label>
                          <Select
                            defaultValue={comList}
                            onChange={(e, name, value) => {
                              this.handleCompanySelectChange(
                                e,
                                "typeOfCompany"
                              );
                            }}
                            onBlur={(e) =>
                              this.handleValidate(e, "typeOfCompany")
                            }
                            options={typeofcompanyList}
                            name="typeOfCompany"
                            placeholder="Please Select Type of Franchise"
                            valueKey="companytype"
                            labelKey="companydesc"
                            getOptionLabel={(option) => option["companydesc"]}
                            getOptionValue={(option) => option["companytype"]}
                            className={`${
                              errors.typeOfCompany ? "error-input-border" : ""
                            } `}
                            key={key}
                            isDisabled={true}
                          />
                          {errors.typeOfCompany && (
                            <span className="errorMsg">
                              Please Select Type of Company
                            </span>
                          )}
                        </div>
                        <div className="form-group col-md-4 ">
                          <label>Franchise PAN Number</label>
                          <div className="d-flex">
                            <div class="inner-addon right-addon flex-grow-1">
                              <input
                                type="text"
                                pattern="\d*"
                                maxLength="10"
                                name="panNumber"
                                value={form.panNumber}
                                onChange={(e) => {
                                  let regex =
                                    /[\!\\/@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                                  if (e.target.value.match(regex) == null)
                                    this.handlePan(e);
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(e, "panNumber")
                                }
                                placeholder="Franchise PAN Number"
                                className={`form-input ${
                                  errors.PanNumberError || invalidPanNumber
                                    ? "error-input-border"
                                    : ""
                                } `}
                                // disabled={
                                //   (form.companyPanNumberVerified &&
                                //     form.companyPanNumberVerified == true) ||
                                //   form.panAvailability == "NO" ||
                                //   form.panAvailability == "" ||
                                //   isVerifyingPan
                                // }
                                disabled={true}
                              />
                              {errors.panNumber && (
                                <span className="errorMsg">
                                  Please Enter PAN Number
                                </span>
                              )}
                              {!errors.PanNumberError && invalidPanNumber && (
                                <span className="errorMsg">
                                  Please enter valid PAN
                                </span>
                              )}
                            </div>
                            <div className="pl-2 ">
                              <button
                                type="button"
                                disabled={true}
                                class="btn btn-primary btn-sm"
                                style={{ width: 100 }}
                                onClick={this.handleVerifyPan}
                              >
                                {isVerifyingPan ? (
                                  <React.Fragment>
                                    <i class="fa fa-spinner fa-spin"></i>
                                    Verifying...
                                  </React.Fragment>
                                ) : form && form.companyPanNumberVerified ? (
                                  "Verified"
                                ) : (
                                  "Verify"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group overflow-visible col-md-4">
                          <label> {"Date Of Establishment"}</label>
                          <div class="inner-addon right-addon">
                            <DatePicker
                              selected={
                                form && form.dateOfEstablishment
                                  ? new Date(form.dateOfEstablishment)
                                  : ""
                              }
                              onBlur={(e) =>
                                this.handleValidate(e, "dateOfEstablishment")
                              }
                              onChange={this.handleDateOfEstablishment}
                              dateFormat="dd-MM-yyyy"
                              placeholderText="DD-MM-YYYY"
                              showYearDropdown
                              showMonthDropdown
                              className={`form-input ${
                                errors.dateOfEstablishment
                                  ? "error-input-border"
                                  : ""
                              } `}
                              disabled={true}
                              maxDate={new Date()}
                            />
                          </div>
                          {errors.dateOfEstablishment && (
                            <span className="errorMsg">
                              Please Select Date Of Establishment
                            </span>
                          )}
                        </div>
                        <div className="form-group col-md-4 ">
                          <label>Franchise name</label>
                          <div class="inner-addon right-addon">
                            <textarea
                              type="text"
                              pattern="\d*"
                              maxLength="200"
                              className="form-input"
                              name="companyName"
                              value={form.companyName}
                              onChange={(e) => {
                                this.handleChange(e, "companyName");
                              }}
                              onBlur={(e) =>
                                this.handleValidate(e, "companyName")
                              }
                              placeholder="Enter Franchise / Shop name"
                              disabled={true}
                              className={`form-input ${
                                errors.companyName ? "error-input-border" : ""
                              } `}
                              autocomplete="false"
                            />
                            {/* <input
                                                    type="text"
                                                    pattern="\d*"
                                                    name="companyName"
                                                    value={form.companyName}
                                                    onChange={e => {
                                                        this.handleChange(e, "companyName")
                                                    }}
                                                    onBlur={(e) => this.handleValidate(e, "companyName")}
                                                    placeholder="Company / Shop name"
                                                    className={`form-input ${errors.companyName
                                                        ? "error-input-border"
                                                        : ""
                                                        } `}
                                                    disabled={false}
                                                /> */}
                            {errors.companyName && (
                              <span className="errorMsg">
                                Please Enter Company/Shop name
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="form-group col-md-4 ">
                          <label>
                            DSA Code/Franchise Code/Vendor{" "}
                            <i className="text-danger">*</i>
                          </label>
                          <div class="inner-addon right-addon">
                            <input
                              type="text"
                              pattern="\d*"
                              name="franchiseCode"
                              value={form.franchiseCode}
                              maxLength="20"
                              onChange={(e) => {
                                let regex =
                                  /[\/\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                                if (!regex.test(e.target.value))
                                  this.handleRadioChange(e, "franchiseCode");
                              }}
                              onBlur={(e) =>
                                this.handleValidate(e, "franchiseCode")
                              }
                              placeholder="Franchise code"
                              className={`form-input ${
                                errors.franchiseCode ? "error-input-border" : ""
                              } `}
                              disabled={true}
                            />
                            {errors.franchiseCode && (
                              <span className="errorMsg">
                                {errors.franchiseCode == true
                                  ? "Please Enter Franchise code"
                                  : errors.franchiseCode}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="form-group col-md-4  ">
                          <label className="">
                            Bureau Score<i className="text-danger">*</i>
                          </label>
                          <div class="inner-addon right-addon">
                            {/* {mobileVerifyLoading && (
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    )} */}
                            <input
                              type="text"
                              pattern="\d*"
                              maxLength="3"
                              className="form-input"
                              name="bureauscore"
                              value={form.bureauscore}
                              onKeyPress={this.restrictAlphabets}
                              onChange={(e) => {
                                this.handlebureauscore(e, "bureauscore");
                              }}
                              onBlur={(e) =>
                                this.handleValidatebureauscore(e, "bureauscore")
                              }
                              placeholder="Enter Bureau"
                              className={`form-input ${
                                errors.bureauscore ? "error-input-border" : ""
                              } `}
                              disabled={true}
                            />
                            {errors.bureauscore && (
                              <span className="errorMsg">
                                {errors.bureauscore == true
                                  ? "Please Enter Bureau Code In Range"
                                  : errors.bureauscore}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-4 ">
                          <div class="inner-addon right-addon">
                            <label>
                              <input
                                type="checkbox"
                                value={form.doYouHaveGST}
                                checked={form.doYouHaveGST}
                                onChange={(event) => this.handleCheck(event)}
                                disabled={true}
                              />
                              Do you have GST number
                            </label>
                            {form && form.doYouHaveGST == true ? (
                              <Select
                                defaultValue={
                                  form &&
                                  gstList &&
                                  gstList.length > 0 &&
                                  form.gstNumber &&
                                  gstList.filter(
                                    ({ gstinId }) => gstinId == form.gstNumber
                                  )[0]
                                }
                                onChange={(e) => {
                                  this.handleGstChange(e, "gstNumber");
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(e, "gstNumber")
                                }
                                // isMulti
                                options={gstList}
                                name="gstNumber"
                                placeholder="Enter Gst Number"
                                valueKey="gstinId"
                                labelKey="gstinId"
                                getOptionLabel={(option) => option["gstinId"]}
                                getOptionValue={(option) => option["gstinId"]}
                                disabled={true}
                                className={`${
                                  errors.gstNumber ? "error-input-border" : ""
                                } `}
                                key={key}
                                isDisabled={true}

                                //   isDisabled={
                                //     gstList && gstList.length == 0 ? true : false
                                //   }
                              />
                            ) : null}
                          </div>
                        </div>
                        {/* new */}
                        <div className=" col-md-4 ">
                          <label>
                            Village <i className="text-danger">*</i>
                          </label>
                          <Select
                            value={zoneobj}
                            onChange={this.handleZone}
                            // onBlur={(e) => this.onValidate(e, "zone")}
                            name="zoneobj"
                            options={zoneDropdownValue}
                            placeholder="Select village"
                            valueKey="villageCode"
                            labelKey="villageName"
                            getOptionLabel={(option) => option.villageName}
                            getOptionValue={(option) => option.villageCode}
                            // className={"error-input-border"}
                            isDisabled={true}
                            isMulti={true}
                          />
                          {/* {errors.zone && (
                      <span className="errorMsg">Please Select Zone</span>
                    )} */}
                        </div>
                        {/* end */}

                        {/* location start */}
                        <div className="form-group col-md-4 ">
                          <label>
                            Location <i className="text-danger">*</i>
                          </label>
                          <div class="inner-addon right-addon">
                            <input
                              name="location"
                              value={testvalue}
                              placeholder="location"
                              className={`form-input ${
                                errors.franchiseCode ? "error-input-border" : ""
                              } `}
                              disabled={true}
                            />
                          </div>
                        </div>

                        {/* location end */}

                        <div className="form-group overflow-visible col-md-4">
                          <label> {"Date Of Birth"}</label>
                          <div class="inner-addon right-addon">
                            <DatePicker
                              selected={
                                form && form.drivingLicenceDateOfBirth
                                  ? new Date(form.drivingLicenceDateOfBirth)
                                  : ""
                              }
                              onBlur={(e) =>
                                this.handleValidate(
                                  e,
                                  "drivingLicenceDateOfBirth"
                                )
                              }
                              onChange={this.handledrivingLicenceDateOfBirth}
                              dateFormat="dd-MM-yyyy"
                              placeholderText="DD-MM-YYYYY (Driving)"
                              showYearDropdown
                              showMonthDropdown
                              className={`form-input ${
                                errors.drivingLicenceDateOfBirth
                                  ? "error-input-border"
                                  : ""
                              } `}
                              disabled={true}
                              maxDate={new Date()}
                            />
                          </div>
                          {/* {errors.drivingLicenceDateOfBirth && (
                        <span className="errorMsg">
                          Please Select Date Of Birth
                        </span>
                      )} */}
                        </div>
                        <div className="form-group col-md-4 ">
                          <label>Driving Licence Number</label>
                          <div className="d-flex">
                            <div class="inner-addon right-addon flex-grow-1">
                              <input
                                type="text"
                                // pattern="\d*"
                                maxLength="16"
                                name="drivingLicenceNumber"
                                value={form.drivingLicenceNumber}
                                onChange={(e) => {
                                  let regex =
                                    /[\!\\/@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                                  if (e.target.value.match(regex) == null)
                                    this.handleDrive(e);
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(e, "drivingLicenceNumber")
                                }
                                placeholder="Driving Licence Number"
                                className={`form-input ${
                                  errors.DrivingError || invalidDrivingNumber
                                    ? "error-input-border"
                                    : ""
                                } `}
                                disabled={true}
                              />
                              {/* {errors.drivingLicenceNumber && (
                            <span className="errorMsg">
                              Please Enter Driving Licence Number
                            </span>
                          )} */}
                              {!errors.DrivingError && invalidDrivingNumber && (
                                <span className="errorMsg">
                                  Please enter valid Driving Licence Number
                                </span>
                              )}
                            </div>
                            <div className="pl-2 ">
                              <button
                                type="button"
                                disabled={true}
                                class="btn btn-primary btn-sm"
                                style={{ width: 100 }}
                                onClick={this.handleDrivingVerification}
                              >
                                {verifyingDriving ? (
                                  <React.Fragment>
                                    <i class="fa fa-spinner fa-spin"></i>
                                    Verifying...
                                  </React.Fragment>
                                ) : form.drivingLicenceNumberVerified ? (
                                  "Verified"
                                ) : (
                                  "Verify"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="form-group col-md-4 ">
                          <label>Voter ID Number</label>
                          <div className="d-flex">
                            <div class="inner-addon right-addon flex-grow-1">
                              <input
                                type="text"
                                // pattern="\d*"
                                maxLength="10"
                                name="voterIdNumber"
                                value={form.voterIdNumber}
                                onChange={(e) => {
                                  let regex =
                                    /[\!\\/@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                                  if (e.target.value.match(regex) == null)
                                    this.handleEpicNo(e);
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(e, "voterIdNumber")
                                }
                                placeholder="Voter ID Number"
                                className={`form-input ${
                                  errors.EpicNoError || invalidvoterIdNumber
                                    ? "error-input-border"
                                    : ""
                                } `}
                                disabled={true}
                              />

                              {!errors.EpicNoError && invalidvoterIdNumber && (
                                <span className="errorMsg">
                                  Please enter Valid Voter ID Number
                                </span>
                              )}
                            </div>
                            <div className="pl-2 ">
                              <button
                                type="button"
                                disabled={true}
                                class="btn btn-primary btn-sm"
                                style={{ width: 100 }}
                                onClick={this.handleVoterIdVerification}
                              >
                                {verifyingvoterIdNumber ? (
                                  <React.Fragment>
                                    <i class="fa fa-spinner fa-spin"></i>
                                    Verifying...
                                  </React.Fragment>
                                ) : form.voterIdVerified ? (
                                  "Verified"
                                ) : (
                                  "Verify"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group overflow-visible col-md-4">
                          <label> {"Date Of Birth"}</label>
                          <div class="inner-addon right-addon">
                            <DatePicker
                              selected={
                                form && form.passportDateOfBirth
                                  ? new Date(form.passportDateOfBirth)
                                  : ""
                              }
                              onBlur={(e) =>
                                this.handleValidate(e, "passportDateOfBirth")
                              }
                              onChange={this.handlepassportDateOfBirth}
                              dateFormat="dd-MM-yyyy"
                              placeholderText="DD-MM-YYYY (Passport)"
                              showYearDropdown
                              showMonthDropdown
                              className={`form-input ${
                                errors.passportDateOfBirth
                                  ? "error-input-border"
                                  : ""
                              } `}
                              disabled={true}
                              maxDate={new Date()}
                            />
                          </div>
                        </div>
                        <div className="form-group col-md-4 ">
                          <label>Passport File Number</label>
                          <div className="d-flex">
                            <div class="inner-addon right-addon flex-grow-1">
                              <input
                                type="text"
                                // pattern="\d*"
                                maxLength="15"
                                name="passportFileNumber"
                                value={form.passportFileNumber}
                                onChange={(e) => {
                                  let regex =
                                    /[\!\\/@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                                  if (e.target.value.match(regex) == null)
                                    this.handlePassport(e);
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(e, "passportFileNumber")
                                }
                                placeholder="Passport File Number"
                                className={`form-input ${
                                  errors.PassportError ||
                                  invalidpassportFileNumber
                                    ? "error-input-border"
                                    : ""
                                } `}
                                disabled={true}
                              />
                              {/* {errors.passportFileNumber && (
                            <span className="errorMsg">
                              Please Enter Passport Number
                            </span>
                          )} */}
                              {!errors.PassportError &&
                                invalidpassportFileNumber && (
                                  <span className="errorMsg">
                                    Please enter valid Passport File Number
                                  </span>
                                )}
                            </div>
                            <div className="pl-2 ">
                              <button
                                type="button"
                                disabled={true}
                                class="btn btn-primary btn-sm"
                                style={{ width: 100 }}
                                onClick={this.handlePassportVerification}
                              >
                                {verifyingPassport ? (
                                  <React.Fragment>
                                    <i class="fa fa-spinner fa-spin"></i>
                                    Verifying...
                                  </React.Fragment>
                                ) : form.passportNumberVerified ? (
                                  "Verified"
                                ) : (
                                  "Verify"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group col-md-4 ">
                          <label>
                            Security Deposit
                            <i className="text-danger">*</i>
                          </label>
                          <div className="d-flex">
                            <div class="inner-addon right-addon flex-grow-1">
                              <input
                                type="text"
                                pattern="\d*"
                                minLength="4"
                                maxLength="7"
                                onKeyPress={this.restrictAlphabets}
                                name="securityDeposit"
                                value={form.securityDeposit}
                                onChange={(e) => {
                                  let regex =
                                    /[\!\\/@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                                  if (e.target.value.match(regex) == null)
                                    this.handleSequrity(e);
                                }}
                                placeholder="Security Deposit Number"
                                className="form-input"
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    {/* address section */}

                    <section className="tab-body mt-3 mb-3">
                      <div className="row">
                        <h5 className="col-md-12 text-center mb-4">
                          Address Details
                        </h5>
                      </div>
                      <section className="tab-body mt-3 mb-3">
                        <div className="row">
                          <h5 className="col-md-12 text-center mb-4">
                            Registered Address
                          </h5>
                          <div className="form-group col-md-4 ">
                            <label>
                              Pin Code <i className="text-danger">*</i>
                            </label>
                            <input
                              type="text"
                              pattern="\d*"
                              maxLength="6"
                              className="form-input"
                              name="pinCode"
                              value={
                                form &&
                                form.registeredAddress &&
                                form.registeredAddress.pinCode
                              }
                              onKeyPress={this.restrictAlphabets}
                              onChange={(e) => {
                                this.handleAddressChange(
                                  e,
                                  "pinCode",
                                  "registeredAddress"
                                );
                              }}
                              onBlur={(e) =>
                                this.handleValidateAddressChange(
                                  e,
                                  "pinCode",
                                  "registeredAddress"
                                )
                              }
                              placeholder="Enter Pin Code"
                              className={`form-input ${
                                errors.registeredAddress.pinCode
                                  ? "error-input-border"
                                  : ""
                              } `}
                              id="pincode"
                              disabled={true}
                            />
                            {errors.registeredAddress.pinCode && (
                              <span className="errorMsg">
                                Please Enter Pincode
                              </span>
                            )}
                            {!errors.registeredAddress.pinCode &&
                              errors.registeredAddress.validPincode && (
                                <span className="errorMsg">
                                  Please Enter Valid Pincode
                                </span>
                              )}
                          </div>
                          <div className="form-group col-md-4">
                            <label>City</label>
                            <input
                              type="text"
                              pattern="\d*"
                              className="form-input"
                              name="city"
                              value={
                                form &&
                                form.registeredAddress &&
                                form.registeredAddress.city
                              }
                              placeholder="Enter City"
                              id="city"
                              disabled={true}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label>State</label>
                            <input
                              type="text"
                              pattern="\d*"
                              className="form-input"
                              name="state"
                              value={
                                form &&
                                form.registeredAddress &&
                                form.registeredAddress.state
                              }
                              placeholder="Enter State"
                              className={`form-input ${
                                errors.registeredAddress.state
                                  ? "error-input-border"
                                  : ""
                              } `}
                              id="state"
                              disabled={true}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label>Branch</label>
                            <input
                              type="text"
                              pattern="\d*"
                              className="form-input"
                              name="branch"
                              value={
                                form &&
                                form.registeredAddress &&
                                form.registeredAddress.branch
                              }
                              placeholder="Enter Branch"
                              className={`form-input ${
                                errors.registeredAddress.branch
                                  ? "error-input-border"
                                  : ""
                              } `}
                              id="branch"
                              disabled={true}
                            />
                          </div>
                          {/*  <div className=" col-md-4">
                            <label>
                              Branch <i className="text-danger">*</i>
                            </label>
                            <Select
                              defaultValue={
                                form &&
                                form.registeredAddress &&
                                form.registeredAddress.branch &&
                                branchList &&
                                branchList.length > 0 &&
                                branchList.filter(
                                  ({ branchCode }) =>
                                    branchCode == form.registeredAddress.branch
                                )[0]
                              }
                              onChange={(e) => {
                                this.handlebranchSelectChange(
                                  e,
                                  "registeredAddress"
                                );
                              }}
                              key={key}
                              onBlur={(e) =>
                                this.onValidatebranch(e, "registeredAddress")
                              }
                              // isMulti
                              options={branchList}
                              name="branch"
                              placeholder="Branch"
                              valueKey="branchCode"
                              labelKey="branchCode"
                              getOptionLabel={(option) => option["branchCode"]}
                              getOptionValue={(option) => option["branchCode"]}
                              className={`${
                                errors.registeredAddress.branch
                                  ? "error-input-border"
                                  : ""
                              } `}
                              isDisabled={true}
                            />
                            {errors.registeredAddress.branch && (
                              <span className="errorMsg">
                                Please select Branch
                              </span>
                            )}
                            </div>*/}
                          <div className="form-group col-md-4 ">
                            <label>
                              {" "}
                              Contact Name <i className="text-danger">*</i>
                            </label>
                            <input
                              type="text"
                              pattern="\d*"
                              maxLength="100"
                              className="form-input"
                              name="contactName"
                              value={
                                form &&
                                form.registeredAddress &&
                                form.registeredAddress.contactName
                              }
                              onChange={(e) => {
                                this.handleAddressChange(
                                  e,
                                  "contactName",
                                  "registeredAddress"
                                );
                              }}
                              onBlur={(e) =>
                                this.handleValidateAddressChange(
                                  e,
                                  "contactName",
                                  "registeredAddress"
                                )
                              }
                              placeholder="Enter Contact Name"
                              className={`form-input ${
                                errors.registeredAddress.contactName
                                  ? "error-input-border"
                                  : ""
                              } `}
                              disabled={true}
                            />
                            {errors.registeredAddress.contactName && (
                              <span className="errorMsg">
                                Please enter Contact Name
                              </span>
                            )}
                          </div>

                          <div className="form-group col-md-4  ">
                            <label className="">
                              Mobile Number <i className="text-danger">*</i>
                            </label>
                            <div class="inner-addon right-addon">
                              {/* {mobileVerifyLoading && (
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    )} */}
                              <input
                                type="text"
                                pattern="\d*"
                                maxLength="10"
                                className="form-input"
                                name="mobileNumber"
                                value={
                                  form &&
                                  form.registeredAddress &&
                                  form.registeredAddress.mobileNumber
                                }
                                onKeyPress={this.restrictAlphabets}
                                onChange={(e) => {
                                  this.handleAddressChange(
                                    e,
                                    "mobileNumber",
                                    "registeredAddress"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidateAddressChange(
                                    e,
                                    "mobileNumber",
                                    "registeredAddress"
                                  )
                                }
                                placeholder="Enter Mobile Number"
                                className={`form-input ${
                                  errors.registeredAddress.mobileNumber
                                    ? "error-input-border"
                                    : ""
                                } `}
                                disabled={true}
                              />
                            </div>

                            {errors.registeredAddress.mobileNumber && (
                              <span className="errorMsg">
                                Please enter Mobile Number
                              </span>
                            )}
                            {!errors.registeredAddress.mobileNumber &&
                              errors.registeredAddress.validMobile && (
                                <span className="errorMsg">
                                  Please enter valid Mobile Number
                                </span>
                              )}
                          </div>
                          <div className="form-group col-md-4 ">
                            <label>Email ID</label>
                            <div class="inner-addon right-addon">
                              {/* {emailVerifyLoading && (
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    )} */}
                              <input
                                type="text"
                                pattern="\d*"
                                maxLength="50"
                                className="form-input"
                                name="emailId"
                                value={
                                  form &&
                                  form.registeredAddress &&
                                  form.registeredAddress.emailId
                                }
                                onChange={(e) => {
                                  this.handleAddressChange(
                                    e,
                                    "emailId",
                                    "registeredAddress"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidateAddressChange(
                                    e,
                                    "emailId",
                                    "registeredAddress"
                                  )
                                }
                                placeholder="Enter Email Address"
                                className={`form-input ${
                                  errors.registeredAddress.emailId ||
                                  errors.registeredAddress.validEmail
                                    ? "error-input-border"
                                    : ""
                                } `}
                                disabled={true}
                              />
                            </div>
                            {errors.registeredAddress.emailId && (
                              <span className="errorMsg">
                                Please enter Email Address
                              </span>
                            )}
                            {errors.registeredAddress.validEmail && (
                              <span className="errorMsg">
                                Please enter valid Email Address
                              </span>
                            )}
                          </div>
                          <div className="form-group col-md-4">
                            <label>
                              Address <i className="text-danger">*</i>
                            </label>
                            <div class="inner-addon right-addon">
                              <textarea
                                type="text"
                                pattern="\d*"
                                maxLength="700"
                                className="form-input"
                                name="address"
                                value={
                                  form &&
                                  form.communicationAddress &&
                                  form.communicationAddress.address
                                }
                                onChange={(e) => {
                                  this.handleAddressChange(
                                    e,
                                    "address",
                                    "communicationAddress"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidateAddressChange(
                                    e,
                                    "address",
                                    "communicationAddress"
                                  )
                                }
                                placeholder="Enter Address"
                                disabled={true}
                                className={`form-input ${
                                  errors.address ? "error-input-border" : ""
                                } `}
                                autocomplete="false"
                              />

                              {errors.address && (
                                <span className="errorMsg">
                                  Please Enter Address
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </section>
                      {/* communication address */}
                      <section className="tab-body mt-3 mb-3">
                        <div className="row">
                          <h5 className="col-md-12 text-center mb-4">
                            Communication Address
                          </h5>
                          <div className="form-group col-md-4 ">
                            <label>
                              Pin Code <i className="text-danger">*</i>
                            </label>
                            <input
                              type="text"
                              pattern="\d*"
                              maxLength="6"
                              className="form-input"
                              name="pinCode"
                              value={
                                form &&
                                communicationAddress &&
                                communicationAddress.pinCode
                              }
                              onKeyPress={this.restrictAlphabets}
                              onChange={(e) => {
                                this.handleAddressChange(
                                  e,
                                  "pinCode",
                                  "communicationAddress"
                                );
                              }}
                              onBlur={(e) =>
                                this.handleValidateAddressChange(
                                  e,
                                  "pinCode",
                                  "communicationAddress"
                                )
                              }
                              placeholder="Enter Pin Code"
                              className={`form-input ${
                                errors.communicationAddress.pinCode
                                  ? "error-input-border"
                                  : ""
                              } `}
                              id="pincode"
                              disabled={true}
                            />
                            {errors.communicationAddress.pinCode && (
                              <span className="errorMsg">
                                Please Enter Pincode
                              </span>
                            )}
                            {!errors.communicationAddress.pinCode &&
                              errors.communicationAddress.validPincode && (
                                <span className="errorMsg">
                                  Please Enter Valid Pincode
                                </span>
                              )}
                          </div>
                          <div className="form-group col-md-4">
                            <label>City</label>
                            <input
                              type="text"
                              pattern="\d*"
                              className="form-input"
                              name="city"
                              value={
                                form &&
                                communicationAddress &&
                                communicationAddress.city
                              }
                              placeholder="Enter City"
                              id="city"
                              disabled={true}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label>State</label>
                            <input
                              type="text"
                              pattern="\d*"
                              className="form-input"
                              name="state"
                              value={
                                form &&
                                communicationAddress &&
                                communicationAddress.state
                              }
                              placeholder="Enter State"
                              className={`form-input ${
                                errors.communicationAddress.state
                                  ? "error-input-border"
                                  : ""
                              } `}
                              id="state"
                              disabled={true}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label>Branch</label>
                            <input
                              type="text"
                              pattern="\d*"
                              className="form-input"
                              name="branch"
                              value={
                                form &&
                                communicationAddress &&
                                communicationAddress.branch
                              }
                              placeholder="Enter Branch"
                              id="branch"
                              disabled={true}
                            />
                          </div>
                          {/* <div className=" col-md-4">
                            <label>
                              Branch <i className="text-danger">*</i>
                            </label>
                            <Select
                              defaultValue={
                                form &&
                                communicationAddress &&
                                communicationAddress.branch &&
                                branchList &&
                                branchList.length > 0 &&
                                branchList.filter(
                                  ({ branchCode }) =>
                                    branchCode == communicationAddress.branch
                                )[0]
                              }
                              onChange={(e) => {
                                this.handlebranchSelectChange(
                                  e,
                                  "communicationAddress"
                                );
                              }}
                              onBlur={(e) =>
                                this.onValidatebranch(e, "communicationAddress")
                              }
                              // isMulti
                              options={branchList}
                              name="branch"
                              placeholder="Branch"
                              valueKey="branchCode"
                              labelKey="branchCode"
                              getOptionLabel={(option) => option["branchCode"]}
                              getOptionValue={(option) => option["branchCode"]}
                              className={`${
                                errors.communicationAddress.branch
                                  ? "error-input-border"
                                  : ""
                              } `}
                              isDisabled={true}
                              key={key}
                            />
                            {errors.communicationAddress.branch && (
                              <span className="errorMsg">
                                Please select Branch
                              </span>
                            )}
                            </div>*/}
                          <div className="form-group col-md-4 ">
                            <label>
                              {" "}
                              Contact Name <i className="text-danger">*</i>
                            </label>
                            <input
                              type="text"
                              pattern="\d*"
                              maxLength="100"
                              className="form-input"
                              name="contactName"
                              value={
                                form &&
                                communicationAddress &&
                                communicationAddress.contactName
                              }
                              onChange={(e) => {
                                this.handleAddressChange(
                                  e,
                                  "contactName",
                                  "communicationAddress"
                                );
                              }}
                              onBlur={(e) =>
                                this.handleValidateAddressChange(
                                  e,
                                  "contactName",
                                  "communicationAddress"
                                )
                              }
                              placeholder="Enter Contact Name"
                              className={`form-input ${
                                errors.communicationAddress.contactName
                                  ? "error-input-border"
                                  : ""
                              } `}
                              disabled={true}
                            />
                            {errors.communicationAddress.contactName && (
                              <span className="errorMsg">
                                Please enter Contact Name
                              </span>
                            )}
                          </div>

                          <div className="form-group col-md-4  ">
                            <label className="">
                              Mobile Number <i className="text-danger">*</i>
                            </label>
                            <div class="inner-addon right-addon">
                              {/* {mobileVerifyLoading && (
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    )} */}
                              <input
                                type="text"
                                pattern="\d*"
                                maxLength="10"
                                className="form-input"
                                name="mobileNumber"
                                value={
                                  form &&
                                  communicationAddress &&
                                  communicationAddress.mobileNumber
                                }
                                onKeyPress={this.restrictAlphabets}
                                onChange={(e) => {
                                  this.handleAddressChange(
                                    e,
                                    "mobileNumber",
                                    "communicationAddress"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidateAddressChange(
                                    e,
                                    "mobileNumber",
                                    "communicationAddress"
                                  )
                                }
                                placeholder="Enter Mobile Number"
                                className={`form-input ${
                                  errors.communicationAddress.mobileNumber
                                    ? "error-input-border"
                                    : ""
                                } `}
                                disabled={true}
                              />
                            </div>

                            {errors.communicationAddress.mobileNumber && (
                              <span className="errorMsg">
                                Please enter Mobile Number
                              </span>
                            )}
                            {!errors.communicationAddress.mobileNumber &&
                              errors.communicationAddress.validMobile && (
                                <span className="errorMsg">
                                  Please enter valid Mobile Number
                                </span>
                              )}
                          </div>
                          <div className="form-group col-md-4 ">
                            <label>Email ID</label>
                            <div class="inner-addon right-addon">
                              {/* {emailVerifyLoading && (
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    )} */}
                              <input
                                type="text"
                                pattern="\d*"
                                maxLength="50"
                                className="form-input"
                                name="emailId"
                                value={
                                  form &&
                                  communicationAddress &&
                                  communicationAddress.emailId
                                }
                                onChange={(e) => {
                                  this.handleAddressChange(
                                    e,
                                    "emailId",
                                    "communicationAddress"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidateAddressChange(
                                    e,
                                    "emailId",
                                    "communicationAddress"
                                  )
                                }
                                placeholder="Enter Email Address"
                                className={`form-input ${
                                  errors.communicationAddress.emailId ||
                                  errors.communicationAddress.validEmail
                                    ? "error-input-border"
                                    : ""
                                } `}
                                disabled={true}
                              />
                            </div>
                            {errors.communicationAddress.emailId && (
                              <span className="errorMsg">
                                Please enter Email Address
                              </span>
                            )}
                            {errors.communicationAddress.validEmail && (
                              <span className="errorMsg">
                                Please enter valid Email Address
                              </span>
                            )}
                          </div>
                          <div className="form-group col-md-4">
                            <label>
                              Address <i className="text-danger">*</i>
                            </label>
                            <div class="inner-addon right-addon">
                              <textarea
                                type="text"
                                pattern="\d*"
                                maxLength="700"
                                className="form-input"
                                name="address"
                                value={
                                  form &&
                                  form.communicationAddress &&
                                  form.communicationAddress.address
                                }
                                onChange={(e) => {
                                  this.handleAddressChange(
                                    e,
                                    "address",
                                    "communicationAddress"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidateAddressChange(
                                    e,
                                    "address",
                                    "communicationAddress"
                                  )
                                }
                                placeholder="Enter Address"
                                disabled={true}
                                className={`form-input ${
                                  errors.address ? "error-input-border" : ""
                                } `}
                                autocomplete="false"
                              />

                              {errors.address && (
                                <span className="errorMsg">
                                  Please Enter Address
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group row col-md-4 mt-4">
                            <div className="col-2 align-center">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={form.isregisteraddsameascommddress}
                                checked={form.isregisteraddsameascommddress}
                                id="isregisteraddsameascommddress"
                                onChange={(e) => {
                                  this.handleRadioChange(
                                    e,
                                    "isregisteraddsameascommddress"
                                  );
                                }}
                                disabled={true}
                              />
                            </div>
                            <div className="col-10 align-center">
                              <label
                                className="form-check-label"
                                for="isregisteraddsameascommddress"
                              >
                                Same as registered address
                              </label>
                            </div>
                          </div>
                        </div>
                      </section>
                    </section>
                    {/* bank section */}
                    <section className="tab-body mt-3 mb-3">
                      <div className="row">
                        <h5 className="col-md-12 text-center mb-4">
                          Bank Details
                        </h5>
                        <div className="form-group col-md-4">
                          <label>
                            Bank account name <i className="text-danger">*</i>
                          </label>
                          <input
                            type="text"
                            pattern="\d*"
                            maxLength="100"
                            className="form-input"
                            name="bankAccountName"
                            value={form.bankAccountName}
                            onChange={(e) => {
                              this.handleRadioChange(e, "bankAccountName");
                            }}
                            onBlur={(e) =>
                              this.handleValidate(e, "bankAccountName")
                            }
                            placeholder="Enter Bank account name"
                            disabled={true}
                          />
                          {errors.bankAccountName && (
                            <span className="errorMsg">
                              Please Enter Bank account name
                            </span>
                          )}
                        </div>

                        <div className="form-group col-md-4">
                          <label>
                            Bank Name <i className="text-danger">*</i>
                          </label>
                          <input
                            type="text"
                            pattern="\d*"
                            maxLength="100"
                            className="form-input"
                            name="bankName"
                            value={form.bankName}
                            onChange={(e) => {
                              this.handleRadioChange(e, "bankName");
                            }}
                            onBlur={(e) => this.handleValidate(e, "bankName")}
                            placeholder="Enter Bank Name"
                            disabled={true}
                          />
                          {errors.bankName && (
                            <span className="errorMsg">
                              Please enter Bank Name
                            </span>
                          )}
                          {/* {!errors.bankName && errors.validBankNameError && (
                                                <span className="errorMsg">
                                                    Bank name should be in character.
                                                </span>
                                            )} */}
                        </div>

                        <div className="form-group col-md-4">
                          <label>
                            {" "}
                            Account Number <i className="text-danger">*</i>
                          </label>
                          <input
                            type="number"
                            pattern="\d*"
                            maxLength="16"
                            className="form-input"
                            name="accountNumber"
                            value={form.accountNumber}
                            onKeyPress={this.restrictAlphabets}
                            onChange={(e) => {
                              if (e.target.value.toString().length <= 16)
                                this.handleRadioChange(e, "accountNumber");
                            }}
                            onBlur={(e) =>
                              this.handleValidate(e, "accountNumber")
                            }
                            placeholder="Enter Account Number"
                            className={`form-input ${
                              errors.accountNumber ? "error-input-border" : ""
                            } `}
                            disabled={true}
                          />
                          {errors.accountNumber && (
                            <span className="errorMsg">
                              Please Enter Account Number
                            </span>
                          )}
                          {!errors.accountNumber &&
                            errors.validAccountNumber && (
                              <span className="errorMsg">
                                Account number should be between 9 to 16.
                              </span>
                            )}
                        </div>

                        <div className="form-group col-md-4">
                          <label>
                            IFSC Code <i className="text-danger">*</i>
                          </label>
                          <input
                            type="text"
                            pattern="\d*"
                            maxLength="11"
                            className="form-input"
                            name="ifscCode"
                            value={form.ifscCode}
                            onChange={(e) => {
                              let regex =
                                /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                              if (!regex.test(e.target.value))
                                this.handleRadioChange(e, "ifscCode");
                            }}
                            onBlur={(e) => this.handleValidate(e, "ifscCode")}
                            placeholder="Enter IFSC Code"
                            className={`form-input ${
                              errors.ifscCode ? "error-input-border" : ""
                            } `}
                            disabled={true}
                          />
                          {errors.ifscCode && (
                            <span className="errorMsg">
                              Please enter IFSC Code
                            </span>
                          )}
                          {!errors.ifscCode && errors.isValidIfsc && (
                            <span className="errorMsg">
                              Please enter valid IFSC Code
                            </span>
                          )}
                        </div>
                        <div className="offset-md-4 offset-lg-4 col-md-4 col-lg-4 align-center-end mt-3">
                          <button
                            type="button"
                            disabled={true}
                            class="btn btn-primary btn-sm"
                            style={{ width: 100 }}
                            onClick={this.handleBankVerification}
                          >
                            {form.bankVerified ? "Verified" : "Verify"}
                          </button>
                        </div>
                      </div>
                    </section>
                    {/* documents upload section */}

                    <section className="tab-body mt-3 mb-3">
                      <div className="row">
                        <h5 className="col-md-12 text-center mb-4">
                          Upload Document
                        </h5>

                        {/* kyc doc */}

                        <div className="col-md-4">
                          <label className="align-center">
                            Upload KYC document <i className="text-danger">*</i>{" "}
                          </label>
                          <ImageUploader
                            className="disable-div"
                            name="kycDocument"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="KYC document"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "kycDocument");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          {/* {errors.kycDocument && (
                                                <span className="errorMsg">Please Upload KYC document</span>
                                            )} */}

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (res && res.description == "kycDocument") {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        {/* upload agreement */}

                        <div className="col-md-4">
                          <label className="align-center">
                            Upload Master Franchise Agreement{" "}
                            <i className="text-danger">*</i>{" "}
                          </label>
                          <ImageUploader
                            className="disable-div"
                            name="appraiserAppointment"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Master Franchise Agreement "
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "appraiserAppointment");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "appraiserAppointment"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        {/* payout doc */}

                        <div className="col-md-4">
                          <label className="align-center">
                            Upload Equipment Lease Agreement{" "}
                            <i className="text-danger">*</i>{" "}
                          </label>
                          <ImageUploader
                            // name="uploadPayout"
                            className="disable-div"
                            name="chequeGaurantee"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Equipment Lease Agreement"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "chequeGaurantee");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "chequeGaurantee"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        <div className="col-md-4">
                          <label className="align-center">
                            Upload CIBIL Report
                          </label>
                          <ImageUploader
                            className="disable-div"
                            name="demandPromissoryNote"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="CIBIL Report"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "demandPromissoryNote");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "demandPromissoryNote"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        <div className="col-md-4">
                          <label className="align-center">
                            Upload Franchise Detail Form
                          </label>
                          <ImageUploader
                            className="disable-div"
                            name="aggrement"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Franchise Detail Form"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "aggrement");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (res && res.description == "aggrement") {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        <div className="col-md-4">
                          <label className="align-center">
                            Upload Franchise Survey
                          </label>
                          <ImageUploader
                            className="disable-div"
                            name="dpnLetter"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Franchisee Survey"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "dpnLetter");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                console.log("dd", res);
                                if (res && res.description == "dpnLetter") {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        <div className="col-md-4 mt-5">
                          <label style={{ whiteSpace: "pre" }}>
                            Upload Cheque guarantee/ cancelled cheque
                          </label>
                          <ImageUploader
                            className="disable-div"
                            name="payoutdoc"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText=" Cheque guarantee/ cancelled cheque"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "payoutdoc");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (res && res.description == "payoutdoc") {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        <div className="col-md-4 mt-5">
                          <label className="align-center">
                            Upload Passport photo{" "}
                          </label>
                          <ImageUploader
                            className="disable-div"
                            name="passportPhoto"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText=" Passport photo"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "passportPhoto");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          {/* {errors.passportPhoto && (
                                                <span className="errorMsg">Please Upload Passport photo</span>
                                            )}  */}

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (res && res.description == "passportPhoto") {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        <div className="col-md-4 mt-5">
                          <label className="align-center">
                            Upload Franchise/Implement Economics{" "}
                          </label>
                          <ImageUploader
                            className="disable-div"
                            name="resume"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText=" Franchise/Implement Economics"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "resume");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          {/* {errors.resume && (
                                                <span className="errorMsg">Please Upload Resume</span>
                
                                            )} */}

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (res && res.description == "resume") {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        <div className="col-md-5 mt-5">
                          <label style={{ whiteSpace: "pre" }}>
                            Upload Shop registration document / Appraiser
                            certificate{" "}
                          </label>
                          <ImageUploader
                            className="disable-div"
                            name="shopRegistration"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Shop registration document / Appraiser certificate"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "shopRegistration");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          {/* {errors.shopRegistration && (
                                                <span className="errorMsg">Please Upload Shop registration document / Appraiser certificate</span>
                                            )} */}

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "shopRegistration"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        <div className="col-md-4 mt-5">
                          <label className="align-center">
                            Upload Substitute appraiser document
                          </label>
                          <ImageUploader
                            className="disable-div"
                            name="substituteAppraiser"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Substitute appraiser document"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "substituteAppraiser");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          {/* {errors.substituteAppraiser && (
                                                <span className="errorMsg">Please Upload Substitute appraiser document</span>
                                            )} */}

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "substituteAppraiser"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        <div className="col-md-3 mt-5">
                          <label className="align-center">
                            Upload Franchise Qualitative Score Card{" "}
                          </label>
                          <ImageUploader
                            className="disable-div"
                            name="bmInterviewSheet"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Franchise Qualitative Score Card"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "bmInterviewSheet");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          {/* {errors.bmInterviewSheet && (
                                                <span className="errorMsg">Please Upload BM interview sheet</span>
                                            )} */}
                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "bmInterviewSheet"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        <div className="col-md-4 mt-5">
                          <label className="align-center">
                            Upload Security Deposit Slip{" "}
                          </label>
                          <ImageUploader
                            className="disable-div"
                            name="form60"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Security Deposit Slip"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "form60");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />
                          {/* {errors.form60 && (
                                                <span className="errorMsg">Please Upload Form 60</span>
                                            )} */}
                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (res && res.description == "form60") {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        {/* any other 4 documents */}

                        <div className="col-md-4 mt-5">
                          <label className="align-center">
                            Upload Implement Quotation
                          </label>
                          <ImageUploader
                            className="disable-div"
                            name="otherDocument1"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Implement Quotation"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "otherDocument1");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "otherDocument1"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        <div className="col-md-4 mt-5">
                          <label className="align-center">
                            Upload Implement Invoice
                          </label>
                          <ImageUploader
                            className="disable-div"
                            name="otherDocument2"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Implement Invoice"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "otherDocument2");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "otherDocument2"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        <div className="col-md-4 mt-5">
                          <label className="align-center">
                            Upload Other document 1
                          </label>
                          <ImageUploader
                            className="disable-div"
                            name="otherDocument3"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Other document 1"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "otherDocument3");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            disabled={true}
                          />

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "otherDocument3"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        <div className="col-md-4 mt-5">
                          <label className="align-center">
                            Upload Other document 2
                          </label>
                          <ImageUploader
                            className="disable-div"
                            name="otherDocument4"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Other document 2"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "otherDocument4");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            buttonClassName={"disabled"}
                          />
                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "otherDocument4"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li>
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <p className="mt-5" style={{ color: "black" }}>
                          <i className="text-danger">*</i>Upload documents in
                          jpg, jpeg, png, pdf , gif, docx, pptx and xlsx format
                          with Maximum size up to 5 MB
                        </p>
                      </div>
                    </section>
                    {/* new section */}
                    {previousStatus == "REJECTED" && (
                      <section className="tab-body mt-3 mb-3">
                        <div className="row">
                          <div className="form-group col-md-12 col-lg-12 col-sm-12">
                            <label>
                              {" "}
                              <i className="text-danger">*</i> Remark
                            </label>
                            <div class="inner-addon right-addon">
                              <textarea
                                type="text"
                                pattern="\d*"
                                maxLength="500"
                                className="form-input"
                                name="remark"
                                value={form && form.remark}
                                onChange={(e) => {
                                  this.handleRadioChange(e, "remark");
                                }}
                                onBlur={(e) => this.handleValidate(e, "remark")}
                                placeholder="Enter remark"
                                disabled={true}
                                className={`form-input ${
                                  errors.remark ? "error-input-border" : ""
                                } `}
                                autocomplete="false"
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                    {previousStatus == "PENDING" && (
                      <section className="tab-body mt-3 mb-3">
                        <div className="row">
                          <div className=" row form-group col-md-6">
                            <div className=" form-group col-md-3">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        form && form.status == "APPROVED"
                                      }
                                      onChange={(e) =>
                                        this.handleApproveReject(e, "APPROVED")
                                      }
                                      name="status"
                                      color="primary"
                                    />
                                  }
                                  label="Approve"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        form && form.status == "REJECTED"
                                      }
                                      onChange={(e) =>
                                        this.handleApproveReject(e, "REJECTED")
                                      }
                                      name="status"
                                      color="primary"
                                    />
                                  }
                                  label="Reject"
                                />
                              </FormGroup>
                            </div>
                          </div>
                          {form && form.status && form.status == "REJECTED" && (
                            <div className="form-group col-md-6">
                              <label>
                                {" "}
                                <i className="text-danger">*</i> Remark
                              </label>
                              <div class="inner-addon right-addon">
                                <textarea
                                  type="text"
                                  pattern="\d*"
                                  maxLength="500"
                                  className="form-input"
                                  name="remark"
                                  value={form && form.remark}
                                  onChange={(e) => {
                                    this.handleRadioChange(e, "remark");
                                  }}
                                  onBlur={(e) =>
                                    this.handleValidate(e, "remark")
                                  }
                                  placeholder="Enter remark"
                                  disabled={false}
                                  className={`form-input ${
                                    errors.remark ? "error-input-border" : ""
                                  } `}
                                  autocomplete="false"
                                />

                                {errors.remark && (
                                  <span className="errorMsg">
                                    Please Enter Remark
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </section>
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {previousStatus == "PENDING" && (
                    <>
                      <Button className="btn-danger" onClick={this.close}>
                        Cancel
                      </Button>
                      <Button
                        className="btn-success"
                        disabled={disableSave}
                        onClick={this.handleSubmit}
                      >
                        {loading ? (
                          <React.Fragment>
                            <i class="fa fa-spinner fa-spin"></i>
                            Updating...
                          </React.Fragment>
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </Modal>
              <CloseSection
                show={this.state.showInner}
                button2={this.close}
                button1={this.handleClose}
                title="Do you want to close ?"
              />
            </div>
          </>
        ) : (
          <>
            <h4 className="text-center mt-5">LOADING...</h4>
          </>
        )}
      </>
    );
  }
}

export default withRouter(FranchiseApprovalMasterForm);
