import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import ImageUploader from "react-images-upload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { FileUrl } from "../../../../Utils/config";
import JSZip from "jszip";
import { viewDocument } from "../../../../Utils/Services/documents";

import {
  SpinnerLoader,
  FullScreenLoader,
} from "../../../../Components/Assets/Loader";
import { CloseSection } from "../../../../Components/Assets/CloseSection";
import { cloneDeep } from "lodash";
import {
  deleteDocumentById,
  uploadFranchiseAppraiserDoc,
  saveOrUpdatefranchise,
  checkFranchiseDedupe,
  checkVillageIsAlreadyMappedToFranchiseOnUpdate,
} from "../../../../Utils/Services/FranchiseAppraiser";
import { findfranchiseByApplication } from "../../../../Utils/Services/FranchiseAppraiser";
import {
  findallvillagesdropdown,
  checkemailidispresent,
  checkMobileNumberIsPresent,
  checkVillageIsAlreadyMappedToFranchise,
} from "../../../../Utils/Services/franchisemaster";

import {
  pan,
  verifyBankAccountDetails,
  getAddressByPincode,
  getTypeCompany,
  getAllBranch,
  verifyDrivingLicense,
  verifyVoterID,
  verifyPassport,
  verifyGst,
} from "../../../../Utils/Services/common";

toast.configure();

let gstListDoc = [
  {
    emailId: "",
    applicationStatus: "",
    mobNum: "",
    registrationName: "",
    gstinRefId: "",
    regType: "",
    authStatus: "Inactive",
    tinNumber: "",
    pan: "AAACR5055K",
    gstinId: "03AAACR5055K1ZH",
  },
  {
    emailId: "",
    applicationStatus: "",
    mobNum: "",
    registrationName: "",
    gstinRefId: "",
    regType: "",
    authStatus: "Active",
    tinNumber: "",
    pan: "AAACR5055K",
    gstinId: "27AAACR5055K3Z5",
  },
  {
    emailId: "",
    applicationStatus: "",
    mobNum: "",
    registrationName: "",
    gstinRefId: "",
    regType: "",
    authStatus: "Active",
    tinNumber: "",
    pan: "AAACR5055K",
    gstinId: "05AAACR5055K1ZD",
  },
  {
    emailId: "",
    applicationStatus: "",
    mobNum: "",
    registrationName: "",
    gstinRefId: "",
    regType: "",
    authStatus: "Inactive",
    tinNumber: "",
    pan: "AAACR5055K",
    gstinId: "29AAACR5055K2Z2",
  },
];

//upload doc
let mandatoryDoc = ["appraiserAppointment", "chequeGaurantee", "kycDocument"];

// mandary field
let mandatoryField = [
  "typeOfCompany",
  "franchiseCode",
  "branch",
  "address",
  "bankAccountName",
  "bankName",
  "accountNumber",
  "ifscCode",
  "mobileNumber",
];

let initForm = {
  typeOfCompany: "",
  panNumber: "",
  drivingLicenceNumber: "",
  dateOfEstablishment: "",
  drivingLicenceDateOfBirth: "",
  passportDateOfBirth: "",
  name: "",
  bureauscore: "",
  franchiseCode: "",
  gstNumber: "",
  voterIdNumber: "",
  panNumber: "",
  drivingLicenceNumber: "",
  voterIdNumber: "",
  passportNo: "",
  passportFileNumber: "",
  doi: "",
  applicationNumber: "",
  location: "",

  registeredAddress: {
    state: "",
    city: "",
    branch: "",
    pinCode: "",
    contactName: "",
    mobileNumber: "",
    emailId: "",
    address: "",
  },
  communicationAddress: {
    state: "",
    city: "",
    branch: "",
    pinCode: "",
    contactName: "",
    mobileNumber: "",
    emailId: "",
    address: "",
  },

  isregisteraddsameascommddress: false,
  doYouHaveGST: false,
  drivingLincenceChecked: false,
  passportNumberChecked: false,
  checkAddress: false,
  bankAccountName: "",
  bankName: "",
  accountNumber: "",
  ifscCode: "",
  companyPanNumberVerified: false,
  drivingLicenceNumberVerified: false,
  passportNumberVerified: false,
  voterIdVerified: false,
  remark: "",
  active: false,
};

class FranchiseAppraiser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchList: [],
      typeofcompanyList: [],
      gstList: [],
      isDis: true,

      isDedupe: false,
      isEmailPresent: false,
      isMobileNumberPresent: false,
      previousAppraiserCode: "",
      showActivateDeactivatemodal: false,
      docId: null,
      showDeletemodal: false,
      previousStatus: "",
      newBranch: "",
      newComp: "",
      mail: "",
      verifyingBank: false,
      key: Math.random(),
      form: cloneDeep(initForm),
      show: false,
      showInner: false,
      loading: false,
      invalidPanNumber: false,
      invalidDrivingNumber: false,
      invalidPassport: false,
      invalidpassportFileNumber: false,
      isVerifyingPan: false,
      invalidvoterIdNumber: false,
      verifyingDriving: false,
      verifyingPassport: false,
      verifyingvoterIdNumber: false,
      demandPromissoryNote: [],
      appraiserAppointment: [],
      aggrement: [],
      payoutdoc: [],
      dpnLetter: [],
      chequeGaurantee: [],
      passportPhoto: [],
      resume: [],
      shopRegistration: [],
      kycDocument: [],
      substituteAppraiser: [],
      bmInterviewSheet: [],
      form60: [],
      otherDocument1: [],
      otherDocument2: [],
      otherDocument3: [],
      otherDocument4: [],
      userZone: [],
      zoneDropdownValue: [],
      villagePresent: false,
      errors: {
        panAvailability: null,
        panNumber: null,
        passportFileNumber: null,
        passportFileNumber: null,
        PanNumberError: null,
        DrivingError: null,
        PassportError: null,
        EpicNoError: null,
        passportDateOfBirth: null,
        drivingLicenceDateOfBirth: null,
        dateOfEstablishment: null,
        companyName: null,
        franchiseCode: null,
        gstNumber: null,
        otherBankAppraiserName: null,
        yearOfExperienceInGoldOrnaments: null,
        ownJewellerycompanyName: null,
        instituteNameOfDegree: null,
        accountNumber: null,
        bankAccountName: null,
        bankName: null,
        accountNumber: null,
        isValidIfsc: null,
        validAccountNumber: null,
        location: null,
        registeredAddress: {
          state: null,
          city: null,
          branch: null,
          pinCode: null,
          contactName: null,
          mobileNumber: null,
          address: null,
          emailId: null,
          validPincode: null,
          validEmail: null,
          validMobile: null,
        },
        communicationAddress: {
          state: null,
          city: null,
          branch: null,
          pinCode: null,
          contactName: null,
          mobileNumber: null,
          emailId: null,
          address: null,
          validPincode: null,
          validEmail: null,
          validMobile: null,
        },
        demandPromissoryNote: null,
        appraiserAppointment: null,
        dpnLetter: null,
        aggrement: null,
        payoutdoc: null,
        chequeGaurantee: null,
        passportPhoto: null,
        resume: null,
        shopRegistration: null,
        kycDocument: null,
        substituteAppraiser: null,
        bmInterviewSheet: null,
        form60: null,
        aggrement: null,
        payoutdoc: null,
        otherDocument1: null,
        otherDocument2: null,
        otherDocument3: null,
        otherDocument4: null,
      },
    };
    // this.handleCheck = this.handleCheck.bind(this);
  }

  componentDidMount = () => {
    this.handleShow();
    this.getcompanyList();
    this.getfranchiseAppraiserByAppicationId();
    this.getBranchList();
    this.allvillagesdropdown();
  };

  handleViewDoc = (path) => {
    viewDocument(path);
  };

  clearAll = () => {
    let { form } = this.state;
    form.location = "";
    this.setState({ form });
  };

  allvillagesdropdown = () => {
    findallvillagesdropdown().then((res) => {
      console.log("village", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        return;
      } else {
        this.setState({ zoneDropdownValue: res.data.data });
      }
    });
  };

  checkVillagePresent = (zonearray) => {
    let { form, userZone, zoneDropdownValue } = this.state;
    console.log("abcd", zonearray[0]);
    let data = {
      franchiseCode: form.franchiseCode,
      villages: zonearray[0],
    };
    checkVillageIsAlreadyMappedToFranchiseOnUpdate(data).then((res) => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
        return;
      } else {
        console.log(
          "res.data",
          res && res.data && res.data.data && res.data.data.message
        );
        if (res && res.data && res.data.data && res.data.data.message != "") {
          toast.error(
            res && res.data && res.data.data && res.data.data.message,
            {
              type: toast.TYPE.error,
              autoClose: 4000,
            }
          );
          this.setState({ villagePresent: true });
        }
        if (
          res &&
          res.data &&
          res.data.data &&
          res.data.data.message == undefined
        ) {
          this.setState({ villagePresent: false });
        }
      }
    });
  };

  getfranchiseAppraiserByAppicationId = () => {
    let { verifyObj } = this.props;
    this.setState({ loading: true });
    findfranchiseByApplication({ applicationNumber: verifyObj }).then((res) => {
      if (res.error) {
        this.setState({ loading: false });
        return;
      }
      if (res.data.error) {
        this.setState({ loading: false });
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        if (
          res.data &&
          res.data.data &&
          Object.keys(res.data.data).length > 0
        ) {
          this.setState(
            {
              form: res.data.data,
              key: Math.random(),
              previousStatus: res.data.data.status,
              previousAppraiserCode: res.data.data.franchiseCode,
              userZone: res.data && res.data.data && res.data.data.village,
              newBranch:
                res.data &&
                res.data.data &&
                res.data.data.registeredAddress &&
                res.data.data.registeredAddress.branch,
              newComp: res.data && res.data.data && res.data.data.typeOfCompany,
              mail:
                res.data &&
                res.data.data &&
                res.data.data.communicationAddress &&
                res.data.data.communicationAddress.emailId,
            },
            () => {
              this.setState({ loading: false });
              //this.getGstList();
            }
          );
        }
      }
    });
  };

  handleZone = (zone) => {
    let zonearray = [];
    zone && zonearray.push(zone);
    // if (zonearray && zonearray.length != 0) {
    //   this.checkVillagePresent(zonearray);
    // }
    this.setState({ userZone: zonearray[0], key: Math.random() });
    this.clearAll();
  };

  handlePanNumber = () => {
    // this.handleVerifyVoterId();
    this.handleVerifyPan();
    // this.handleVerifyPassport();
    // this.handleVerifyDriving();
  };

  //security deposit

  handleSequrity = (e) => {
    let { form } = this.state;

    form.securityDeposit = e.target.value;
    this.setState({
      form,
    });
  };

  handleCheck() {
    let { form } = this.state;
    form.doYouHaveGST = !form.doYouHaveGST;
    this.setState({ form });
  }

  handleVerifyPan = () => {
    let { form } = this.state;
    this.setState({ isVerifyingPan: true });
    let data = {
      consent: "Y",
      pan: form.panNumber,
    };
    pan(data).then((res) => {
      this.setState({ isVerifyingPan: false });
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        console.log("res.data", res.data.panHolderName);
        if (res.data && res.data.panHolderName !== "") {
          form.companyName = res.data.panHolderName;
          form.companyPanNumberVerified = true;
          this.setState({ form });
        }
      }
    });
  };

  //verify pan number

  getcompanyList = () => {
    getTypeCompany().then((res) => {
      let companyTypeList = res.data.typeofcompanyList;

      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        return;
      } else {
        this.setState({ typeofcompanyList: companyTypeList });
      }
    });
  };

  getBranchList = () => {
    getAllBranch().then((res) => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        return;
      } else {
        if (res.data && res.data.data && res.data.data.length > 0) {
          this.setState({ branchList: res.data.data });
        }
      }
    });
  };
  getcompanyList = () => {
    getTypeCompany().then((res) => {
      let companyTypeList = res.data.typeofcompanyList;

      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        return;
      } else {
        this.setState({ typeofcompanyList: companyTypeList });
      }
    });
  };

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  handleClose = (close) => {
    if (!close) this.close();
    this.setState({ show: false }, () => {
      this.props.handleModalAction();
    });
  };

  close = (close) => {
    this.setState({ showInner: !this.state.showInner });
  };

  handleChange = (e, name) => {
    let { form } = this.state;
    form[name] = e.target.value;
    this.setState({ form });
  };

  handlebranchSelectChange = (e, name) => {
    let { form } = this.state;
    let { branchCode } = e;
    form[name].branch = branchCode;
    if (form.isregisteraddsameascommddress == true) {
      form.communicationAddress.branch = branchCode;
    }
    this.setState({ form, key: Math.random() });
  };

  handleCompanySelectChange = (e, name) => {
    let { form, errors } = this.state;
    let { companytype } = e;
    form[name] = companytype;
    form.panNumber = "";
    form.companyPanNumberVerified = false;
    form.companyName = "";

    let value = companytype;
    if (value == "" || value == null || value == undefined) {
      errors[name] = true;
      this.setState({ errors });
    } else {
      errors[name] = false;
      this.setState({ errors });
    }

    this.setState({ form, key: Math.random() });
  };

  //location
  handleLocationSelectChange = (e, name) => {
    console.log("handleLocationSelectChange", e, name);

    let { form, errors } = this.state;
    let { villageName } = e;
    form[name] = villageName;
    form.villageName = "";

    let value = villageName;
    if (value == "" || value == null || value == undefined) {
      errors[name] = true;
      this.setState({ errors });
    } else {
      errors[name] = false;
      this.setState({ errors });
    }

    this.setState({ form, key: Math.random() });
  };

  onValidateCompany = (e, name) => {
    const { errors, form } = this.state;
    let value = form[name].typeOfCompany;
    if (value === "" || value === null || value === undefined) {
      errors[name] = true;
      this.setState({ errors });
    } else {
      errors[name] = false;
      this.setState({ errors });
    }
  };

  //location
  onValidateLocation = (e, name) => {
    const { errors, form } = this.state;
    let value = form[name].location;
    if (value == "" || value == null || value == undefined) {
      errors[name] = true;
      this.setState({ errors });
    } else {
      errors[name] = false;
      this.setState({ errors });
    }
  };

  onValidatebranch = (e, name) => {
    const { errors, form } = this.state;
    let value = form[name].branch;
    if (value === "" || value === null || value === undefined) {
      errors[name].branch = true;
      this.setState({ errors });
    } else {
      errors[name].branch = false;
      this.setState({ errors });
    }
  };

  handleRadioChange = (e, name) => {
    let { form, errors } = this.state;

    if (name == "isregisteraddsameascommddress") {
      form[name] = !form[name];
      this.setState({ form }, () => {
        let { form } = this.state;
        if (form.isregisteraddsameascommddress == true) {
          let { registeredAddress } = form;
          let { communicationAddress } = errors;
          form.communicationAddress = cloneDeep(registeredAddress);
          errors.communicationAddress = cloneDeep({
            state: null,
            city: null,
            branch: null,
            pinCode: null,
            contactName: null,
            mobileNumber: null,
            emailId: null,
            address: null,
            validPincode: null,
            validEmail: null,
            validMobile: null,
          });
          this.setState({ form, errors, key: Math.random() });
        } else {
          form.communicationAddress = cloneDeep({
            state: "",
            city: "",
            branch: "",
            pinCode: "",
            contactName: "",
            mobileNumber: "",
            emailId: "",
            address: "",
          });
          let { communicationAddress } = errors;
          errors.communicationAddress = cloneDeep({
            state: null,
            city: null,
            branch: null,
            pinCode: null,
            contactName: null,
            mobileNumber: null,
            emailId: null,
            address: null,
            validPincode: null,
            validEmail: null,
            validMobile: null,
          });
          this.setState({ form, errors, key: Math.random() });
        }
      });
    } else {
      if (name == "franchiseCode" || name == "ifscCode") {
        form[name] = e.target.value.toUpperCase();
      } else {
        form[name] = e.target.value;
      }
      this.setState({ form });
    }
  };

  handleDateOfEstablishment = (date, e) => {
    let { form } = this.state;
    form.dateOfEstablishment = date;
    this.setState({ form });
  };

  handledrivingLicenceDateOfBirth = (date, e) => {
    let { form } = this.state;
    form.drivingLicenceDateOfBirth = date;

    this.setState({ form });
  };

  handlepassportDateOfBirth = (date, e) => {
    let { form } = this.state;
    form.passportDateOfBirth = date;

    this.setState({ form });
  };

  handleGstChange = (e, name) => {
    console.log("handleGstChange", e, name);
    let { form } = this.state;

    let { gstinId } = e;
    form.gstNumber = gstinId;
    this.setState({ form });
  };

  getGstList = () => {
    let { form } = this.state;

    this.setState({ isVerifyingGst: true });
    let data = {
      panNumber: form.panNumber,
    };

    verifyGst(data).then((res) => {
      // let gstList = res.data.data
      this.setState({ isVerifyingGst: false });
      if (res.error) {
        return;
      } else {
        this.setState({ gstList: res.data.data, key: Math.random() });
      }
    });
  };

  handlePan = (e) => {
    let { errors, form } = this.state;
    if (e.target.value.trim() !== "") {
      errors = { ...errors, PanNumberError: false };
    } else {
      errors = { ...errors, PanNumberError: true };
    }
    form.panNumber = e.target.value.toUpperCase();
    this.setState({
      form,
      errors,
    });
  };

  handleDrive = (e) => {
    let { errors, form } = this.state;
    if (e.target.value.trim() !== "") {
      errors = { ...errors, DrivingError: false };
    } else {
      errors = { ...errors, DrivingError: true };
    }
    form.drivingLicenceNumber = e.target.value.toUpperCase();
    this.setState({
      form,
      errors,
    });
  };

  handleEpicNo = (e) => {
    let { errors, form } = this.state;
    if (e.target.value.trim() !== "") {
      errors = { ...errors, EpicNoError: false };
    } else {
      errors = { ...errors, EpicNoError: true };
    }
    form.voterIdNumber = e.target.value.toUpperCase();
    this.setState({
      form,
      errors,
    });
  };

  handlePassport = (e) => {
    let { errors, form } = this.state;
    if (e.target.value.trim() !== "") {
      errors = { ...errors, PassportError: false };
    } else {
      errors = { ...errors, PassportError: true };
    }
    form.passportFileNumber = e.target.value.toUpperCase();
    this.setState({
      form,
      errors,
    });
  };

  restrictAlphabets = (e) => {
    const regx = "^[0-9]*$";
    if (e.key.match(regx)) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  handleAddressChange = (e, name, outerName) => {
    let { form } = this.state;
    let value = form[outerName][name];
    form[outerName][name] = e.target.value;
    if (form.isregisteraddsameascommddress == true) {
      form.communicationAddress[name] = e.target.value;
    }
    this.setState({ form });
    this.setState({ form });
    if (
      (form &&
        form.registeredAddress &&
        form.registeredAddress.mobileNumber &&
        form.registeredAddress.mobileNumber.length == 10) ||
      (form &&
        form.communicationAddress &&
        form.communicationAddress.mobileNumber &&
        form.communicationAddress.mobileNumber.length == 10)
    ) {
      if (name == "mobileNumber" && value != "") {
        this.handleMobilePresentforReg(
          form && form.registeredAddress && form.registeredAddress.mobileNumber,
          "registeredAddress"
        );
      }

      if (name == "mobileNumber" && value != "") {
        this.handleMobilePresentforComm(
          form &&
            form.communicationAddress &&
            form.communicationAddress.mobileNumber,
          "communicationAddress"
        );
      }
    }
  };

  fetchAddressByPincode = (sectionName) => {
    let { form, errors } = this.state;
    this.setState({ loading: true });
    getAddressByPincode({ pincode: form[sectionName].pinCode }).then((res) => {
      this.setState({ loading: false });
      if (res.error) {
        return;
      }
      if (res.data.error) {
        /*  toast.error(res.data.message, {
                     type: toast.TYPE.ERROR,
                     autoClose: 4000
                 }); */
        errors[sectionName].validPincode = true;
        form[sectionName].city = "";
        form[sectionName].state = "";
        form[sectionName].branch = "";

        if (
          form.isregisteraddsameascommddress &&
          sectionName == "registeredAddress"
        ) {
          form.communicationAddress.city = "";
          form.communicationAddress.state = "";
          form.communicationAddress.branch = "";
          form.communicationAddress.pinCode = "";
        }
        this.setState({ errors, form });
        return;
      } else {
        if (res.data && res.data.data && res.data.data.length > 0) {
          form[sectionName].city = res.data.data[0].city_name;
          form[sectionName].state = res.data.data[0].state_name;
          form[sectionName].branch = res.data.data[0].branch_name;
          if (
            form.isregisteraddsameascommddress &&
            sectionName == "registeredAddress"
          ) {
            form.communicationAddress.city = res.data.data[0].city_name;
            form.communicationAddress.state = res.data.data[0].state_name;
            form.communicationAddress.branch = res.data.data[0].branch_name;
            form.communicationAddress.pinCode = form[sectionName].pinCode;
          }
          this.setState({ form });
        } else {
          errors[sectionName].validPincode = true;
          form[sectionName].city = "";
          form[sectionName].state = "";
          form[sectionName].branch = "";
          if (
            form.isregisteraddsameascommddress &&
            sectionName == "registeredAddress"
          ) {
            form.communicationAddress.city = "";
            form.communicationAddress.state = "";
            form.communicationAddress.pinCode = "";
            form.communicationAddress.branch = "";
          }
          this.setState({ errors, form });
        }
      }
    });
  };

  handleValidateAddressChange = (e, name, outerName) => {
    const { errors, form } = this.state;

    let value = form[outerName][name];
    let mandatory = [
      "branch",
      "address",
      "mobileNumber",
      "contactName",
      "pinCode",
    ];

    if (
      (value === "" || value === null || value === undefined) &&
      mandatory.includes(name)
    ) {
      errors[outerName][name] = true;
      if (name == "pinCode") {
        form[outerName].city = "";
        form[outerName].state = "";
        form[outerName].branch = "";
        if (
          form.isregisteraddsameascommddress &&
          outerName == "registeredAddress"
        ) {
          form.communicationAddress.city = "";
          form.communicationAddress.state = "";
          form.communicationAddress.branch = "";
          form.communicationAddress.pinCode = "";
        }
      }
      this.setState({ errors, form });
    } else {
      errors[outerName][name] = false;
      this.setState({ errors });
    }

    if (name == "pinCode" && value && value.length !== 6) {
      errors[outerName].validPincode = true;
      this.setState({ errors });
    } else {
      errors[outerName].validPincode = false;
      this.setState({ errors });
    }

    if (name == "pinCode" && value && value.length == 6) {
      this.fetchAddressByPincode(outerName);
    }

    if (name == "emailId") {
      let emailRegx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (value && !value.match(emailRegx)) {
        errors[outerName].validEmail = true;
        this.setState({ errors });
      } else {
        errors[outerName].validEmail = false;
        this.setState({ errors });
      }
    }

    if (name == "emailId" && value != "") {
      this.handleEmailPresent(value);
    }

    if (name == "mobileNumber" && value != "") {
      this.handleMobilePresentforReg(
        form && form.registeredAddress && form.registeredAddress.mobileNumber,
        "registeredAddress"
      );
    }

    if (name == "mobileNumber" && value != "") {
      this.handleMobilePresentforComm(
        form &&
          form.communicationAddress &&
          form.communicationAddress.mobileNumber,
        "communicationAddress"
      );
    }

    if (name == "mobileNumber" && value !== "") {
      let indianMobilenumber = /^[6789]\d{9}$/;

      if (
        (value && value.length < 10) ||
        (value && !new RegExp(/^[6789]\d{9}$/).test(value))
      ) {
        errors[outerName].validMobile = true;
        this.setState({ errors });
      } else {
        errors[outerName].validMobile = false;
        this.setState({ errors });
      }
    }
  };

  //verify franchise code duplication
  handleAppraiserDedupe = (franchiseCode) => {
    const { errors, form } = this.state;
    checkFranchiseDedupe({ franchiseCode: franchiseCode }).then((res) => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        return;
      } else {
        let isDedupe = false;
        if (
          res.data &&
          res.data.data &&
          res.data.data.franchiseCodeAvailable &&
          res.data.data.franchiseCodeAvailable == true
        ) {
          errors.franchiseCode = "Franchise code already exists.";
          isDedupe = true;
        } else {
          errors.franchiseCode = false;
          isDedupe = false;
        }
        this.setState({ errors, isDedupe });
      }
    });
  };

  handleEmailPresent = (emailId) => {
    const { errors, form } = this.state;

    checkemailidispresent({
      emailId: emailId,
      franchiseCode: form && form.franchiseCode,
    }).then((res) => {
      // console.log("checkFranchiseDedupe", res)
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
        return;
      } else {
        // if (this.state.mail != form.communicationAddress.emailId) {
        console.log("mail", this.state.mail);
        let isEmailPresent = false;
        let { form } = this.state;
        let emid =
          res.data && res.data.data && res.data.data.emailIdAvailable == true;
        console.log(emid, "emid");
        if (emid) {
          console.log(emid, "emid1");
          errors.registeredAddress.emailId = "EMAIL ID already exist";
          if (
            form &&
            form.communicationAddress &&
            form.communicationAddress.emailId == ""
          ) {
            errors.communicationAddress.emailId = false;
          } else {
            errors.communicationAddress.emailId = "EMAIL ID already exist";
          }

          if (
            form.registeredAddress.emailId != form.communicationAddress.emailId
          ) {
            errors.registeredAddress.emailId = "";
          }

          if (form.registeredAddress.emailId == "") {
            errors.registeredAddress.emailId = "";
          }

          if (
            form.registeredAddress.emailId === form.communicationAddress.emailId
          ) {
            errors.communicationAddress.emailId = false;
          }

          isEmailPresent = true;
        } else {
          errors.communicationAddress.emailId = false;
          isEmailPresent = false;
        }
        this.setState({ errors, isEmailPresent });
        console.log("errorss", errors);
      }
    });
  };

  handleMobilePresentforReg = (mobileNumber, outerName) => {
    const { errors, form } = this.state;

    checkMobileNumberIsPresent({
      mobileNumber: mobileNumber,
      franchiseCode: form && form.franchiseCode,
    }).then((res) => {
      // console.log("checkFranchiseDedupe", res)
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
        return;
      } else {
        let isMobileNumberPresent = false;
        let { form } = this.state;
        let emidForregMobile =
          res.data &&
          res.data.data &&
          res.data.data.mobileNumberAvailable &&
          outerName == "registeredAddress"
            ? true
            : false;
        let regMobileNumber =
          form && form.registeredAddress && form.registeredAddress.mobileNumber;

        if (
          regMobileNumber.length > 0 &&
          emidForregMobile &&
          outerName == "registeredAddress"
        ) {
          errors.registeredAddress.mobileNumber = "Mobile number already exist";
          isMobileNumberPresent = true;
        } else {
          errors.registeredAddress.mobileNumber = "";
          isMobileNumberPresent = false;
        }

        this.setState({ errors, isMobileNumberPresent });
        console.log("errorss", errors);
      }
    });
  };

  handleMobilePresentforComm = (mobileNumber, outerName) => {
    const { errors, form } = this.state;

    checkMobileNumberIsPresent({
      mobileNumber: mobileNumber,
      franchiseCode: form && form.franchiseCode,
    }).then((res) => {
      // console.log("checkFranchiseDedupe", res)
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
        return;
      } else {
        let isMobileNumberPresent = false;
        let { form } = this.state;
        let emidForcommMobile =
          res.data &&
          res.data.data &&
          res.data.data.mobileNumberAvailable &&
          outerName == "communicationAddress"
            ? true
            : false;

        let commMobileNumber =
          form &&
          form.communicationAddress &&
          form.communicationAddress.mobileNumber;

        if (
          commMobileNumber.length > 0 &&
          emidForcommMobile &&
          outerName == "communicationAddress"
        ) {
          errors.communicationAddress.mobileNumber =
            "Mobile number already exist";
          isMobileNumberPresent = true;
        } else {
          errors.communicationAddress.mobileNumber = "";
          isMobileNumberPresent = false;
        }

        this.setState({ errors, isMobileNumberPresent });
        console.log("errorss", errors);
      }
    });
  };
  handleValidate = (e, name) => {
    const { errors, form, previousAppraiserCode } = this.state;

    let value = form[name];
    let nonMandatory = [
      "companyName",
      "dateOfEstablishment",
      "drivingLicenceDateOfBirth",
      "passportDateOfBirth",
    ];
    if (
      (value === "" || value === null || value === undefined) &&
      !nonMandatory.includes(name)
    ) {
      errors[name] = true;
      this.setState({ errors });
    } else {
      errors[name] = false;
      this.setState({ errors });
    }

    if (name == "franchiseCode" && value != "") {
      if (value != previousAppraiserCode) this.handleAppraiserDedupe(value);
    }

    if (name == "otherBankAppraiserWorking") {
      if (value == "YES" && form.otherBankAppraiserName == "") {
        errors.otherBankAppraiserName = true;
        this.setState({ errors });
      } else {
        errors.otherBankAppraiserName = false;
        form.otherBankAppraiserName = "";
        this.setState({ errors });
      }
    }

    if (name === "ifscCode") {
      if (value.trim() == "") {
        this.setState({ errors: { ...errors, ifscCodeError: true } });
      } else {
        this.setState({ errors: { ...errors, ifscCodeError: false } });
      }
    }

    if (name === "typeOfCompany") {
      let { errors } = this.state;
      if (form.typeOfCompany == "") {
        errors.typeOfCompany = true;
        this.setState({
          errors,
        });
      } else {
        if (form[name] === form.typeofcompanyList) {
          errors.typeOfCompany = false;
        }
      }
    }

    if (name === "panNumber" && errors.panNumber == false) {
      var pan_value = value.toUpperCase();
      var reg =
        /([a-zA-Z]{3})([PCHFATBLJGE]{1})([a-zA-Z]{1})([0-9]{4})([a-zA-Z]{1})/;

      if (pan_value.match(reg)) {
        //this.getGstList();
        this.setState({
          invalidPanNumber: false,
        });
      } else {
        if (pan_value != "")
          this.setState({
            invalidPanNumber: true,
          });
      }
    }

    if (
      name === "drivingLicenceNumber" &&
      errors.drivingLicenceNumber == false
    ) {
      var pan_value = value.toUpperCase();
      var reg = /([a-zA-Z]{2})([0-9]{13})/;

      if (pan_value.match(reg)) {
        this.setState({
          invalidDrivingNumber: false,
        });
      } else {
        if (pan_value != "")
          this.setState({
            invalidDrivingNumber: true,
          });
      }
    }

    if (name === "passportFileNumber" && errors.passportFileNumber == false) {
      var pan_value = value.toUpperCase();
      var reg = /([a-zA-Z]{2})([0-9]{13})/;

      if (pan_value.match(reg)) {
        this.setState({
          invalidpassportFileNumber: false,
        });
      } else {
        if (pan_value != "")
          this.setState({
            invalidpassportFileNumber: true,
          });
      }
    }
    if (name === "voterIdNumber") {
      var pan_value = value.toUpperCase();
      var reg = /([a-zA-Z]{3})([0-9]{7})/;

      if (pan_value.match(reg)) {
        this.setState({
          invalidvoterIdNumber: false,
        });
      } else {
        if (pan_value != "")
          this.setState({
            invalidvoterIdNumber: true,
          });
      }
    }
    if (name === "permanantAddress") {
      if (value && value.length > 700) {
        this.setState({
          permanantAddressError: true,
        });
      }
    }

    if (name == "accountNumber") {
      if (
        (e.target.value.trim().length == 9 ||
          e.target.value.trim().length > 9) &&
        (e.target.value.trim().length == 16 ||
          e.target.value.trim().length < 16)
      ) {
        errors.validAccountNumber = false;
        this.setState({ errors });
      } else {
        errors.validAccountNumber = true;
        this.setState({ errors });
      }
    }

    if (name == "ifscCode") {
      let verifyIfsc = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/;
      if (verifyIfsc.test(value)) {
        errors.isValidIfsc = false;
        this.setState({ errors });
      } else {
        errors.isValidIfsc = true;
        this.setState({ errors });
      }
    }

    /* if (name == "panNumber" && form.companyPanNumberVerified == true) {
            form.companyName = "";
            form.companyPanNumberVerified = false;
            this.setState({ form });
        } */

    if (
      (name == "accountNumber" || name == "ifscCode") &&
      form.bankVerified == true
    ) {
      form.bankVerified = false;
      this.setState({ form });
    }
  };

  onDrop = async (file, picture, name) => {
    let { errors } = this.state;

    if (mandatoryDoc.includes(name)) {
      if (picture.length > 0) {
        errors = { ...errors, [name]: false };
      } else {
        errors = { ...errors, [name]: true };
      }
    }
    this.setState({ [name]: file, errors: errors });
  };

  handleSubmit = () => {
    let { form, errors, userZone, mail } = this.state;
    let userData = JSON.parse(localStorage.getItem("userData"));
    console.log("userdata", userData);
    let village = [];
    userZone &&
      userZone.map((val) => {
        village.push({
          employeeId: userData && userData.adminEmployeeId,
          villageCode: val.villageCode,
          id: val.id,
        });
      });

    let obj = { ...form };
    obj.village = village;
    delete obj.franchiseDocument;
    this.setState({ loading: true });
    saveOrUpdatefranchise(obj).then((res) => {
      if (res.error) {
        this.setState({ loading: false });

        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        this.ImageConverToZipAndUpload(res.data.data.applicationNumber);
      }
    });
  };

  ImageConverToZipAndUpload = (uniqueId) => {
    let { form, mail } = this.state;
    let {
      demandPromissoryNote,
      appraiserAppointment,
      dpnLetter,
      aggrement,
      payoutdoc,
      chequeGaurantee,
      passportPhoto,
      resume,
      shopRegistration,
      kycDocument,
      substituteAppraiser,
      bmInterviewSheet,
      form60,
      otherDocument1,
      otherDocument2,
      otherDocument3,
      otherDocument4,
    } = this.state;

    var zip = new JSZip();
    let documentObj = {
      demandPromissoryNote: demandPromissoryNote,
      appraiserAppointment: appraiserAppointment,
      aggrement: aggrement,
      payoutdoc: payoutdoc,
      dpnLetter: dpnLetter,
      chequeGaurantee: chequeGaurantee,
      passportPhoto: passportPhoto,
      resume: resume,
      shopRegistration: shopRegistration,
      kycDocument: kycDocument,
      substituteAppraiser: substituteAppraiser,
      bmInterviewSheet: bmInterviewSheet,
      form60: form60,
      otherDocument1: otherDocument1,
      otherDocument2: otherDocument2,
      otherDocument3: otherDocument3,
      otherDocument4: otherDocument4,
    };

    let keys = Object.keys(documentObj);

    let userDoc = [];
    keys.map((key, index) => {
      documentObj[key].map((res, i) => {
        userDoc.push({
          documentName: res.name,
          description: key,
          applicationNumber: uniqueId,
        });
        zip.file(res.name, res, { binary: true });
      });
    });
    let _this = this;

    zip.generateAsync({ type: "blob" }).then(function (content) {
      const formData = new FormData();
      formData.append("file", content, `${uniqueId}.zip`);
      formData.append("franchiseDocs", JSON.stringify(userDoc));
      uploadFranchiseAppraiserDoc(formData).then((response) => {
        _this.setState({ loading: false });
        if (response.error) {
          return;
        }
        if (response.data.error) {
          toast.error(response.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 4000,
          });
          return;
        } else {
          _this.setState({ show: false });
          _this.props.fetchAppraiser();

          if (mail != form.communicationAddress.emailId) {
            toast.success(
              "Email ID changed and Mail has been sent to the Franchisee along with reset password link",
              {
                type: toast.TYPE.SUCCESS,
                autoClose: 4000,
              }
            );
          } else {
            toast.success("Data Updated successfully", {
              type: toast.TYPE.SUCCESS,
              autoClose: 4000,
            });
          }
        }
      });
    });
  };

  handleDeleteModalclose = () => {
    this.setState({ showDeletemodal: false });
  };

  handleDelete = () => {
    let { docId } = this.state;
    let postData = {
      documentId: docId,
    };
    this.setState({ laoding: true });
    deleteDocumentById(postData).then((response) => {
      this.setState({ laoding: false });
      if (response.error) {
        return;
      }
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        toast.success(response.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        this.getfranchiseAppraiserByAppicationId();
        this.setState({ showDeletemodal: false });
      }
    });
  };

  handleDocDelete = (documentId) => {
    let { showDeletemodal } = this.state;
    this.setState({ docId: documentId, showDeletemodal: !showDeletemodal });
  };

  openActivateDeactiveModal = () => {
    let { showActivateDeactivatemodal } = this.state;
    this.setState({
      showActivateDeactivatemodal: !showActivateDeactivatemodal,
    });
  };

  handleActivateDeactivateModalclose = () => {
    let { showActivateDeactivatemodal } = this.state;
    this.setState({ showActivateDeactivatemodal: false });
  };

  handleActivateDeactivate = () => {
    let { form } = this.state;
    let obj = { ...form };
    obj.active = !form.active;
    delete obj.franchiseDocument;
    this.setState({ loading: true });
    saveOrUpdatefranchise(obj).then((res) => {
      if (res.error) {
        this.setState({ loading: false });

        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        this.getfranchiseAppraiserByAppicationId();
        let msg = obj.active
          ? "Franchise appraiser activated successfully"
          : "Franchise appraiser inactivated successfully";
        setTimeout(() => {
          toast.success(msg, {
            type: toast.TYPE.SUCCESS,
            autoClose: 4000,
          });
          this.setState({ showActivateDeactivatemodal: false });
        });
      }
    });
  };

  handleBankVerification = () => {
    let { form } = this.state;
    this.setState({ verifyingBank: true });
    let data = {
      consent: "Y",
      ifsc: form.ifscCode,
      accountNumber: form.accountNumber,
    }

    verifyBankAccountDetails(data).then((res) => {
      this.setState({ verifyingBank: false });
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        if (res?.data?.bankTxnStatus) {
          form.bankVerified = true;
          this.setState({ form });
        }else {
          toast.error("Bank Account Not Verified, please check entered details once!")
        }
      }
    });
  };

  handleDrivingVerification = () => {
    let { form } = this.state;
    this.setState({ verifyingDriving: true });

    let data = {
      dlNo: form.drivingLicenceNumber,
      dob: moment(new Date(form.drivingLicenceDateOfBirth)).format(
        "DD-MM-YYYY"
      ),
    };

    verifyDrivingLicense(data).then((res) => {
      this.setState({ verifyingDriving: false });
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        form.drivingLicenceNumberVerified = true;
        this.setState({ form });
      }
    });
  };

  handlePassportVerification = () => {
    let { form } = this.state;
    this.setState({ verifyingPassport: true });

    let data = {
      fileNo: form.passportFileNumber,
      dob: moment(new Date(form.passportDateOfBirth)).format("DD/MM/YYYY"),
    };

    verifyPassport(data).then((res) => {
      this.setState({ verifyingPassport: false });
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        form.passportNumberVerified = true;
        this.setState({ form });
      }
    });
  };

  handleVoterIdVerification = () => {
    let { form } = this.state;
    this.setState({ verifyingvoterIdNumber: true });

    let data = {
      epicNo: form.voterIdNumber,
    };

    verifyVoterID(data).then((res) => {
      this.setState({ verifyingvoterIdNumber: false });
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        form.voterIdVerified = true;
        this.setState({ form });
      }
    });
  };

  handleDriveCheck = () => {
    let { form } = this.state;
    form.drivingLincenceChecked = true;
    form.drivingLicenceDateOfBirth = true;
  };

  render() {
    let {
      isDedupe,
      isEmailPresent,
      isMobileNumberPresent,
      showActivateDeactivatemodal,
      showDeletemodal,
      typeofcompanyList,
      gstList,
      showInner,
      show,
      loading,
      errors,
      form,
      invalidPanNumber,
      invalidDrivingNumber,
      invalidPassport,
      invalidpassportFileNumber,
      invalidvoterIdNumber,
      verifyingvoterIdNumber,
      verifyingPassport,
      isVerifyingPan,
      verifyingDriving,
      key,
      mail,
      newBranch,
      newComp,
      previousStatus,
      verifyingBank,
      branchList,
      zoneDropdownValue,
      userZone,
      villagePresent,
    } = this.state;
    console.log("newBranch", newBranch);
    console.log("newComp", newComp);
    let { registeredAddress, communicationAddress } = form;
    let registeredAddressMandatoryField = false;
    console.log("mail", mail);

    registeredAddress &&
      Object.keys(registeredAddress).map((name, index) => {
        if (
          (registeredAddress[name] == "" ||
            registeredAddress[name] == null ||
            registeredAddress[name] == undefined) &&
          (name == "branch" ||
            name == "address" ||
            // name == "emailId" ||
            name == "mobileNumber" ||
            name == "contactName" ||
            name == "pinCode")
        ) {
          registeredAddressMandatoryField = true;
        }
      });

    let zoneobj = [];
    zoneDropdownValue.map((res) => {
      userZone &&
        userZone.map((val) => {
          if (res.villageCode == val.villageCode) {
            zoneobj.push(res);
          }
        });
    });

    let disableSave = false;

    form &&
      Object.keys(form).map((eachKey, index) => {
        if (typeof errors[eachKey] == "object") {
          form[eachKey] &&
            Object.keys(form[eachKey]).map((name, index) => {
              if (
                (form[eachKey][name] == "" ||
                  form[eachKey][name] == null ||
                  form[eachKey][name] == undefined) &&
                (name == "address" ||
                  name == "branch" ||
                  // name == "emailId" ||
                  name == "mobileNumber" ||
                  name == "contactName" ||
                  name == "pinCode")
              ) {
                disableSave = true;
              }
            });
        } else {
          if (
            (form[eachKey] == "" ||
              form[eachKey] == null ||
              form[eachKey] == undefined) &&
            mandatoryField.includes(eachKey)
          ) {
            disableSave = true;
          }
        }
      });

    if (
      form &&
      form.panNumber != "" &&
      form &&
      form.companyPanNumberVerified == false
    ) {
      disableSave = true;
    }

    if (
      form &&
      form.drivingLicenceNumber != "" &&
      form &&
      form.drivingLicenceNumberVerified == false
    ) {
      disableSave = true;
    }

    if (
      form &&
      form.passportFileNumber != "" &&
      form &&
      form.passportNumberVerified == false
    ) {
      disableSave = true;
    }

    if (
      form &&
      form.voterIdNumber != "" &&
      form &&
      form.voterIdVerified == false
    ) {
      disableSave = true;
    }

    mandatoryDoc.map((docname, index) => {
      let docIndex =
        form &&
        form.franchiseDocument &&
        form.franchiseDocument.findIndex(
          (eachData) => eachData.description == docname
        );

      if (
        this.state[docname] &&
        this.state[docname].length < 1 &&
        docIndex < 0
      ) {
        disableSave = true;
      }
    });

    let comp =
      typeofcompanyList &&
      typeofcompanyList.length > 0 &&
      typeofcompanyList.filter(
        ({ companytype }) => companytype == form.typeOfCompany
      )[0];

    if (
      errors.registeredAddress &&
      errors.registeredAddress.emailId == false &&
      errors.communicationAddress &&
      errors.communicationAddress.emailId == false
    ) {
      isEmailPresent = false;
    }

    if (
      errors.registeredAddress &&
      errors.registeredAddress.mobileNumber == false &&
      errors.communicationAddress &&
      errors.communicationAddress.mobileNumber == false
    ) {
      isMobileNumberPresent = false;
    }

    return (
      <>
        {branchList && branchList.length > 0 ? (
          <>
            <div className="disable-div">
              <div className="disable-div"></div>
              <FullScreenLoader show={false} />
              <ToastContainer />
              <Modal
                className="add-user"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show && show}
                id="gold-appraiser"
                onHide={() => {
                  this.close();
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="w-100">
                    Franchise appraiser master form
                    <span className="right"> {`${previousStatus}`} </span>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  className={`p-4 `}
                  style={{ background: "#f8f9fa" }}
                >
                  <div className="row">
                    <section className="tab-body mt-3 mb-3">
                      <div className="row">
                        <h5 className="col-md-12 text-center mb-4">
                          Company Details
                        </h5>
                        {typeofcompanyList && typeofcompanyList.length > 0 && (
                          <div className="col-md-4">
                            <label>
                              Type of Franchise{" "}
                              {<i className="text-danger">*</i>}
                            </label>
                            <Select
                              defaultValue={comp || newComp}
                              onChange={(e, name, value) => {
                                this.handleCompanySelectChange(
                                  e,
                                  "typeOfCompany"
                                );
                              }}
                              onBlur={(e) =>
                                this.handleValidate(e, "typeOfCompany")
                              }
                              options={typeofcompanyList}
                              name="typeOfCompany"
                              placeholder="Please Select Type of Franchise"
                              valueKey="companytype"
                              labelKey="companydesc"
                              getOptionLabel={(option) => option["companydesc"]}
                              getOptionValue={(option) => option["companytype"]}
                              className={`${
                                errors.typeOfCompany ? "error-input-border" : ""
                              } `}
                              key={key}
                            />
                            {errors.typeOfCompany && (
                              <span className="errorMsg">
                                Please Select Type of Company
                              </span>
                            )}
                          </div>
                        )}
                        <div className="form-group col-md-4 ">
                          <label>Franchise PAN Number</label>
                          <div className="d-flex">
                            <div class="inner-addon right-addon flex-grow-1">
                              {form.companyPanNumberVerified &&
                                form.companyPanNumberVerified == "Active" && (
                                  <i class="glyphicon glyphicon-ok"></i>
                                )}
                              <input
                                type="text"
                                pattern="\d*"
                                maxLength="10"
                                name="panNumber"
                                value={form.panNumber}
                                onChange={(e) => {
                                  let regex =
                                    /[\!\\/@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                                  if (e.target.value.match(regex) == null)
                                    this.handlePan(e);
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(e, "panNumber")
                                }
                                placeholder="Franchise PAN Number"
                                className={`form-input ${
                                  errors.PanNumberError || invalidPanNumber
                                    ? "error-input-border"
                                    : ""
                                } `}
                                disabled={
                                  (form.companyPanNumberVerified &&
                                    form.companyPanNumberVerified == true) ||
                                  isVerifyingPan
                                }
                              />

                              {!errors.PanNumberError && invalidPanNumber && (
                                <span className="errorMsg">
                                  Please enter valid PAN
                                </span>
                              )}
                            </div>
                            <div className="pl-2 ">
                              <button
                                type="button"
                                disabled={
                                  form.panNumber == "" ||
                                  isVerifyingPan ||
                                  (!errors.PanNumberError &&
                                    invalidPanNumber) ||
                                  (form.companyPanNumberVerified &&
                                    form.companyPanNumberVerified == true)
                                }
                                class="btn btn-primary btn-sm"
                                style={{ width: 100 }}
                                onClick={this.handleVerifyPan}
                              >
                                {isVerifyingPan ? (
                                  <React.Fragment>
                                    <i class="fa fa-spinner fa-spin"></i>
                                    Verifying...
                                  </React.Fragment>
                                ) : form && form.companyPanNumberVerified ? (
                                  "Verified"
                                ) : (
                                  "Verify"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group overflow-visible col-md-4">
                          <label> {"Date Of Establishment"}</label>
                          <div class="inner-addon right-addon">
                            <DatePicker
                              selected={
                                form && form.dateOfEstablishment
                                  ? new Date(form.dateOfEstablishment)
                                  : ""
                              }
                              onBlur={(e) =>
                                this.handleValidate(e, "dateOfEstablishment")
                              }
                              onChange={this.handleDateOfEstabtelishment}
                              dateFormat="dd-MM-yyyy"
                              placeholderText="DD-MM-YYYY"
                              showYearDropdown
                              showMonthDropdown
                              className={`form-input ${
                                errors.dateOfEstablishment
                                  ? "error-input-border"
                                  : ""
                              } `}
                              disabled={false}
                              maxDate={new Date()}
                            />
                          </div>
                          {errors.dateOfEstablishment && (
                            <span className="errorMsg">
                              Please Select Date Of Establishment
                            </span>
                          )}
                        </div>
                        <div className="form-group col-md-4 ">
                          <label>Franchise name</label>
                          <div class="inner-addon right-addon">
                            <textarea
                              type="text"
                              pattern="\d*"
                              maxLength="200"
                              className="form-input"
                              name="companyName"
                              value={form.companyName}
                              onChange={(e) => {
                                this.handleChange(e, "companyName");
                              }}
                              onBlur={(e) =>
                                this.handleValidate(e, "companyName")
                              }
                              placeholder="Enter Franchise / Shop name"
                              disabled={false}
                              className={`form-input ${
                                errors.companyName ? "error-input-border" : ""
                              } `}
                              autocomplete="false"
                            />

                            {errors.companyName && (
                              <span className="errorMsg">
                                Please Enter Company/Shop name
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="form-group col-md-4 ">
                          <label>
                            DSA Code/Franchise Code/Vendor
                            <i className="text-danger">*</i>
                          </label>
                          <div class="inner-addon right-addon">
                            <input
                              type="text"
                              pattern="\d*"
                              name="franchiseCode"
                              value={form.franchiseCode}
                              maxLength="20"
                              onChange={(e) => {
                                let regex =
                                  /[\/\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                                if (!regex.test(e.target.value))
                                  this.handleRadioChange(e, "franchiseCode");
                              }}
                              onBlur={(e) =>
                                this.handleValidate(e, "franchiseCode")
                              }
                              placeholder="Franchise code"
                              className={`form-input ${
                                errors.franchiseCode ? "error-input-border" : ""
                              } `}
                              disabled={true}
                            />
                            {errors.franchiseCode && (
                              <span className="errorMsg">
                                {errors.franchiseCode == true
                                  ? "Please Enter Franchise code"
                                  : errors.franchiseCode}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="form-group col-md-4 ">
                          <label>
                            BureauScore
                            <i className="text-danger">*</i>
                          </label>
                          <div class="inner-addon right-addon">
                            <input
                              type="text"
                              pattern="\d*"
                              name="bureauscore"
                              value={form.bureauscore}
                              maxLength="3"
                              onChange={(e) => {
                                let regex =
                                  /[\/\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                                if (!regex.test(e.target.value))
                                  this.handleRadioChange(e, "bureauscore");
                              }}
                              onBlur={(e) =>
                                this.handleValidate(e, "bureauscore")
                              }
                              placeholder="Enter Bureau Score"
                              className={`form-input ${
                                errors.bureauscore ? "error-input-border" : ""
                              } `}
                              disabled={true}
                            />
                            {errors.bureauscore && (
                              <span className="errorMsg">
                                {errors.bureauscore == true
                                  ? "Please Enter Enter"
                                  : errors.bureauscore}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-4 ">
                          <div class="inner-addon right-addon">
                            <label>
                              <input
                                type="checkbox"
                                value={form.doYouHaveGST}
                                checked={form.doYouHaveGST}
                                onChange={(event) => this.handleCheck(event)}
                              />
                              Do you have GST number
                            </label>
                            {form && form.doYouHaveGST == true ? (
                              <Select
                                defaultValue={
                                  form &&
                                  gstList &&
                                  gstList.length > 0 &&
                                  form.gstNumber &&
                                  gstList.filter(
                                    ({ gstinId }) => gstinId == form.gstNumber
                                  )[0]
                                }
                                onChange={(e) => {
                                  this.handleGstChange(e, "gstNumber");
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(e, "gstNumber")
                                }
                                // isMulti
                                options={gstList}
                                name="gstNumber"
                                placeholder="Enter Gst Number"
                                valueKey="gstinId"
                                labelKey="gstinId"
                                getOptionLabel={(option) => option["gstinId"]}
                                getOptionValue={(option) => option["gstinId"]}
                                className={`${
                                  errors.gstNumber ? "error-input-border" : ""
                                } `}
                                key={key}
                                disabled={false}
                              />
                            ) : null}
                          </div>
                        </div>
                        {/* new */}
                        <div className=" col-md-4 ">
                          <label>
                            Village <i className="text-danger">*</i>
                          </label>
                          <Select
                            value={zoneobj}
                            onChange={this.handleZone}
                            // onBlur={(e) => this.onValidate(e, "zone")}
                            name="zoneobj"
                            options={zoneDropdownValue}
                            placeholder="Select village"
                            valueKey="villageCode"
                            labelKey="villageName"
                            getOptionLabel={(option) => option.villageName}
                            getOptionValue={(option) => option.villageCode}
                            isClearable={false}
                            // className={"error-input-border"}
                            isDisabled={false}
                            isMulti={true}
                          />
                          {/* {errors.zone && (
                      <span className="errorMsg">Please Select Zone</span>
                    )} */}
                        </div>
                        {/* end */}

                        {/* location start */}
                        {zoneobj && zoneobj.length > 0 && (
                          <div className=" col-md-4 ">
                            <label>
                              Location <i className="text-danger">*</i>
                            </label>
                            <Select
                              defaultValue={
                                zoneobj &&
                                zoneobj.length > 0 &&
                                zoneobj.filter(
                                  ({ villageName }) =>
                                    villageName == form.location
                                )[0]
                              }
                              onChange={(e, name, value) => {
                                this.handleLocationSelectChange(e, "location");
                              }}
                              onBlur={(e) =>
                                this.onValidateLocation(e, "location")
                              }
                              options={zoneobj}
                              name="location"
                              placeholder="Please Select Location"
                              valueKey="villageName"
                              labelKey="villageCode"
                              getOptionLabel={(option) => option["villageCode"]}
                              getOptionValue={(option) => option["villageName"]}
                              className={`${
                                errors.location ? "error-input-border" : ""
                              } `}
                              key={key}
                              isDisabled={false}
                            />
                            {errors.location && (
                              <span className="errorMsg">
                                Please Select Location
                              </span>
                            )}
                          </div>
                        )}
                        {/* location end */}
                        <div className="form-group overflow-visible col-md-4">
                          <label> {"Date Of Birth"}</label>
                          <div class="inner-addon right-addon">
                            <DatePicker
                              selected={
                                form && form.drivingLicenceDateOfBirth
                                  ? new Date(form.drivingLicenceDateOfBirth)
                                  : ""
                              }
                              onBlur={(e) =>
                                this.handleValidate(
                                  e,
                                  "drivingLicenceDateOfBirth"
                                )
                              }
                              onChange={this.handledrivingLicenceDateOfBirth}
                              dateFormat="dd-MM-yyyy"
                              placeholderText="DD-MM-YYYY (Driving Licence)"
                              showYearDropdown
                              showMonthDropdown
                              className={`form-input ${
                                errors.drivingLicenceDateOfBirth
                                  ? "error-input-border"
                                  : ""
                              } `}
                              disabled={
                                (form.drivingLicenceNumberVerified &&
                                  form.drivingLicenceNumberVerified == true) ||
                                verifyingDriving
                              }
                              maxDate={new Date()}
                            />
                          </div>
                        </div>
                        <div className="form-group col-md-4 ">
                          <label>Driving Licence Number</label>
                          <div className="d-flex">
                            <div class="inner-addon right-addon flex-grow-1">
                              <input
                                type="text"
                                // pattern="\d*"
                                maxLength="16"
                                name="drivingLicenceNumber"
                                value={form.drivingLicenceNumber}
                                onChange={(e) => {
                                  let regex =
                                    /[\!\\/@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                                  if (e.target.value.match(regex) == null)
                                    this.handleDrive(e);
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(e, "drivingLicenceNumber")
                                }
                                placeholder="Driving Licence Number"
                                className={`form-input ${
                                  errors.drivingLicenceNumber ||
                                  invalidDrivingNumber
                                    ? "error-input-border"
                                    : ""
                                } `}
                                disabled={
                                  (form.drivingLicenceNumberVerified &&
                                    form.drivingLicenceNumberVerified ==
                                      true) ||
                                  verifyingDriving
                                }
                              />

                              {!errors.DrivingError && invalidDrivingNumber && (
                                <span className="errorMsg">
                                  Please enter valid Driving Licence Number
                                </span>
                              )}
                            </div>
                            <div className="pl-2 ">
                              <button
                                type="button"
                                disabled={
                                  (form &&
                                    form.drivingLicenceDateOfBirth == "") ||
                                  form.drivingLicenceDateOfBirth == null ||
                                  form.drivingLicenceNumber == "" ||
                                  form.drivingLicenceNumber == null ||
                                  verifyingDriving ||
                                  form.drivingLicenceNumberVerified ||
                                  invalidDrivingNumber
                                }
                                class="btn btn-primary btn-sm"
                                style={{ width: 100 }}
                                onClick={this.handleDrivingVerification}
                              >
                                {verifyingDriving ? (
                                  <React.Fragment>
                                    <i class="fa fa-spinner fa-spin"></i>
                                    Verifying...
                                  </React.Fragment>
                                ) : form.drivingLicenceNumberVerified ? (
                                  "Verified"
                                ) : (
                                  "Verify"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="form-group col-md-4 ">
                          <label>Voter ID Number</label>
                          <div className="d-flex">
                            <div class="inner-addon right-addon flex-grow-1">
                              <input
                                type="text"
                                // pattern="\d*"
                                maxLength="10"
                                name="voterIdNumber"
                                value={form.voterIdNumber}
                                onChange={(e) => {
                                  let regex =
                                    /[\!\\/@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                                  if (e.target.value.match(regex) == null)
                                    this.handleEpicNo(e);
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(e, "voterIdNumber")
                                }
                                placeholder="Voter ID Number"
                                className={`form-input ${
                                  errors.EpicNoError || invalidvoterIdNumber
                                    ? "error-input-border"
                                    : ""
                                } `}
                                disabled={
                                  (form.voterIdVerified &&
                                    form.voterIdVerified == true) ||
                                  verifyingvoterIdNumber
                                }
                              />

                              {!errors.EpicNoError && invalidvoterIdNumber && (
                                <span className="errorMsg">
                                  Please enter valid Voter Id Number
                                </span>
                              )}
                            </div>
                            <div className="pl-2 ">
                              <button
                                type="button"
                                disabled={
                                  form.voterIdNumber == "" ||
                                  verifyingvoterIdNumber ||
                                  form.voterIdVerified ||
                                  invalidvoterIdNumber
                                }
                                class="btn btn-primary btn-sm"
                                style={{ width: 100 }}
                                onClick={this.handleVoterIdVerification}
                              >
                                {verifyingvoterIdNumber ? (
                                  <React.Fragment>
                                    <i class="fa fa-spinner fa-spin"></i>
                                    Verifying...
                                  </React.Fragment>
                                ) : form.voterIdVerified ? (
                                  "Verified"
                                ) : (
                                  "Verify"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group overflow-visible col-md-4">
                          <label> {"Date Of Birth"}</label>
                          <div class="inner-addon right-addon">
                            <DatePicker
                              selected={
                                form && form.passportDateOfBirth
                                  ? new Date(form.passportDateOfBirth)
                                  : ""
                              }
                              onBlur={(e) =>
                                this.handleValidate(e, "passportDateOfBirth")
                              }
                              onChange={this.handlepassportDateOfBirth}
                              dateFormat="dd-MM-yyyy"
                              placeholderText="DD-MM-YYYY (Passport)"
                              showYearDropdown
                              showMonthDropdown
                              className={`form-input ${
                                errors.passportDateOfBirth
                                  ? "error-input-border"
                                  : ""
                              } `}
                              disabled={
                                (form.passportNumberVerified &&
                                  form.passportNumberVerified == true) ||
                                verifyingPassport
                              }
                              maxDate={new Date()}
                            />
                          </div>
                        </div>
                        <div className="form-group col-md-4 ">
                          <label>Passport File Number</label>
                          <div className="d-flex">
                            <div class="inner-addon right-addon flex-grow-1">
                              <input
                                type="text"
                                // pattern="\d*"
                                maxLength="15"
                                name="passportFileNumber"
                                value={form.passportFileNumber}
                                onChange={(e) => {
                                  let regex =
                                    /[\!\\/@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                                  if (e.target.value.match(regex) == null)
                                    this.handlePassport(e);
                                }}
                                onBlur={(e) =>
                                  this.handleValidate(e, "passportFileNumber")
                                }
                                placeholder="Passport File Number"
                                className={`form-input ${
                                  errors.PassportError ||
                                  invalidpassportFileNumber
                                    ? "error-input-border"
                                    : ""
                                } `}
                                disabled={
                                  (form.passportNumberVerified &&
                                    form.passportNumberVerified == true) ||
                                  verifyingPassport
                                }
                              />

                              {!errors.PassportError &&
                                invalidpassportFileNumber && (
                                  <span className="errorMsg">
                                    Please enter valid Passport File Number
                                  </span>
                                )}
                            </div>
                            <div className="pl-2 ">
                              <button
                                type="button"
                                disabled={
                                  (form && form.passportDateOfBirth == "") ||
                                  form.passportDateOfBirth == null ||
                                  form.passportFileNumber == "" ||
                                  form.passportFileNumber == null ||
                                  verifyingPassport ||
                                  form.passportNumberVerified ||
                                  invalidpassportFileNumber
                                }
                                class="btn btn-primary btn-sm"
                                style={{ width: 100 }}
                                onClick={this.handlePassportVerification}
                              >
                                {verifyingPassport ? (
                                  <React.Fragment>
                                    <i class="fa fa-spinner fa-spin"></i>
                                    Verifying...
                                  </React.Fragment>
                                ) : form.passportNumberVerified ? (
                                  "Verified"
                                ) : (
                                  "Verify"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="form-group col-md-4 ">
                          <label>
                            Security Deposit
                            <i className="text-danger">*</i>
                          </label>
                          <div className="d-flex">
                            <div class="inner-addon right-addon flex-grow-1">
                              <input
                                type="text"
                                pattern="\d*"
                                minLength="4"
                                maxLength="7"
                                onKeyPress={this.restrictAlphabets}
                                name="securityDeposit"
                                value={form.securityDeposit}
                                onChange={(e) => {
                                  let regex =
                                    /[\!\\/@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                                  if (e.target.value.match(regex) == null)
                                    this.handleSequrity(e);
                                }}
                                placeholder="Security Deposit Number"
                                className="form-input"
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    {/* address section */}

                    <section className="tab-body mt-3 mb-3">
                      <div className="row">
                        <h5 className="col-md-12 text-center mb-4">
                          Address Details
                        </h5>
                      </div>
                      <section className="tab-body mt-3 mb-3">
                        <div className="row">
                          <h5 className="col-md-12 text-center mb-4">
                            Registered Address
                          </h5>
                          <div className="form-group col-md-4 ">
                            <label>
                              Pin Code <i className="text-danger">*</i>
                            </label>
                            <input
                              type="text"
                              pattern="\d*"
                              maxLength="6"
                              className="form-input"
                              name="pinCode"
                              value={
                                form &&
                                form.registeredAddress &&
                                form.registeredAddress.pinCode
                              }
                              onKeyPress={this.restrictAlphabets}
                              onChange={(e) => {
                                this.handleAddressChange(
                                  e,
                                  "pinCode",
                                  "registeredAddress"
                                );
                              }}
                              onBlur={(e) =>
                                this.handleValidateAddressChange(
                                  e,
                                  "pinCode",
                                  "registeredAddress"
                                )
                              }
                              placeholder="Enter Pin Code"
                              className={`form-input ${
                                errors.registeredAddress.pinCode
                                  ? "error-input-border"
                                  : ""
                              } `}
                              id="pincode"
                              disabled={false}
                            />
                            {errors.registeredAddress.pinCode && (
                              <span className="errorMsg">
                                Please Enter Pincode
                              </span>
                            )}
                            {!errors.registeredAddress.pinCode &&
                              errors.registeredAddress.validPincode && (
                                <span className="errorMsg">
                                  Please Enter Valid Pincode
                                </span>
                              )}
                          </div>
                          <div className="form-group col-md-4">
                            <label>City</label>
                            <input
                              type="text"
                              pattern="\d*"
                              className="form-input"
                              name="city"
                              value={
                                form &&
                                form.registeredAddress &&
                                form.registeredAddress.city
                              }
                              placeholder="Enter City"
                              id="city"
                              disabled={true}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label>State</label>
                            <input
                              type="text"
                              pattern="\d*"
                              className="form-input"
                              name="state"
                              value={
                                form &&
                                form.registeredAddress &&
                                form.registeredAddress.state
                              }
                              placeholder="Enter State"
                              className={`form-input ${
                                errors.registeredAddress.state
                                  ? "error-input-border"
                                  : ""
                              } `}
                              id="state"
                              disabled={true}
                            />
                          </div>
                          {/*<div className=" col-md-4">
                            <label>
                              Branch <i className="text-danger">*</i>
                            </label>
                            <Select
                              defaultValue={
                                (form &&
                                  form.registeredAddress &&
                                  form.registeredAddress.branch &&
                                  branchList.filter(
                                    ({ branchCode }) =>
                                      branchCode ==
                                      form.registeredAddress.branch
                                  )[0]) ||
                                newBranch
                              }
                              onChange={(e) => {
                                this.handlebranchSelectChange(
                                  e,
                                  "registeredAddress"
                                );
                              }}
                              onBlur={(e) =>
                                this.onValidatebranch(e, "registeredAddress")
                              }
                              // isMulti
                              options={branchList}
                              name="branch"
                              placeholder="Branch"
                              valueKey="branchCode"
                              labelKey="branchCode"
                              getOptionLabel={(option) => option["branchCode"]}
                              getOptionValue={(option) => option["branchCode"]}
                              className={`${
                                errors.registeredAddress.branch
                                  ? "error-input-border"
                                  : ""
                              } `}
                              key={key}
                            />
                            {errors.registeredAddress.branch && (
                              <span className="errorMsg">
                                Please select Branch
                              </span>
                            )}
                            </div>*/}
                          <div className="form-group col-md-4">
                            <label>Branch</label>
                            <input
                              type="text"
                              pattern="\d*"
                              className="form-input"
                              name="branch"
                              value={
                                form &&
                                form.registeredAddress &&
                                form.registeredAddress.branch
                              }
                              placeholder="Enter Branch"
                              id="branch"
                              disabled={true}
                            />
                          </div>
                          <div className="form-group col-md-4 ">
                            <label>
                              Contact Name <i className="text-danger">*</i>
                            </label>
                            <input
                              type="text"
                              pattern="\d*"
                              maxLength="100"
                              className="form-input"
                              name="contactName"
                              value={
                                form &&
                                form.registeredAddress &&
                                form.registeredAddress.contactName
                              }
                              onChange={(e) => {
                                this.handleAddressChange(
                                  e,
                                  "contactName",
                                  "registeredAddress"
                                );
                              }}
                              onBlur={(e) =>
                                this.handleValidateAddressChange(
                                  e,
                                  "contactName",
                                  "registeredAddress"
                                )
                              }
                              placeholder="Enter Contact Name"
                              className={`form-input ${
                                errors.registeredAddress.contactName
                                  ? "error-input-border"
                                  : ""
                              } `}
                              disabled={false}
                            />
                            {errors.registeredAddress.contactName && (
                              <span className="errorMsg">
                                Please enter Contact Name
                              </span>
                            )}
                          </div>

                          <div className="form-group col-md-4  ">
                            <label className="">
                              Mobile Number <i className="text-danger">*</i>
                            </label>
                            <div class="inner-addon right-addon">
                              {/* {mobileVerifyLoading && (
                                                    <i class="fa fa-spinner fa-spin"></i>
                                                )} */}
                              <input
                                type="text"
                                pattern="\d*"
                                maxLength="10"
                                className="form-input"
                                name="mobileNumber"
                                value={
                                  form &&
                                  form.registeredAddress &&
                                  form.registeredAddress.mobileNumber
                                }
                                onKeyPress={this.restrictAlphabets}
                                onChange={(e) => {
                                  this.handleAddressChange(
                                    e,
                                    "mobileNumber",
                                    "registeredAddress"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidateAddressChange(
                                    e,
                                    "mobileNumber",
                                    "registeredAddress"
                                  )
                                }
                                placeholder="Enter Mobile Number"
                                className={`form-input ${
                                  errors.registeredAddress.mobileNumber
                                    ? "error-input-border"
                                    : ""
                                } `}
                                disabled={false}
                              />
                            </div>

                            {errors.registeredAddress.mobileNumber && (
                              <span className="errorMsg">
                                {errors.registeredAddress.mobileNumber == true
                                  ? "Please enter Mobile Number"
                                  : errors.registeredAddress.mobileNumber}
                              </span>
                            )}

                            {!errors.registeredAddress.mobileNumber &&
                              errors.registeredAddress.validMobile && (
                                <span className="errorMsg">
                                  Please enter valid Mobile Number
                                </span>
                              )}
                          </div>
                          <div className="form-group col-md-4 ">
                            <label>Email ID</label>
                            <div class="inner-addon right-addon">
                              {/* {emailVerifyLoading && (
                                                    <i class="fa fa-spinner fa-spin"></i>
                                                )} */}
                              <input
                                type="text"
                                pattern="\d*"
                                maxLength="50"
                                className="form-input"
                                name="emailId"
                                value={
                                  form &&
                                  form.registeredAddress &&
                                  form.registeredAddress.emailId
                                }
                                onChange={(e) => {
                                  this.handleAddressChange(
                                    e,
                                    "emailId",
                                    "registeredAddress"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidateAddressChange(
                                    e,
                                    "emailId",
                                    "registeredAddress"
                                  )
                                }
                                placeholder="Enter Email Address"
                                className={`form-input ${
                                  errors.registeredAddress.emailId ||
                                  errors.registeredAddress.validEmail
                                    ? "error-input-border"
                                    : ""
                                } `}
                                disabled={false}
                              />
                            </div>
                            {errors.registeredAddress.emailId && (
                              <span className="errorMsg">
                                {errors.registeredAddress.emailId}
                              </span>
                            )}

                            {/* {errors.registeredAddress.emailId && (
                              <span className="errorMsg">
                                {errors.registeredAddress.emailId == true
                                  ? "Please enter Email Address"
                                  : errors.registeredAddress.emailId}
                              </span>
                            )} */}
                            {errors.registeredAddress.validEmail && (
                              <span className="errorMsg">
                                Please enter valid Email Address
                              </span>
                            )}
                          </div>

                          {/* address text box */}

                          <div className="form-group col-md-4">
                            <label>
                              Address <i className="text-danger">*</i>
                            </label>
                            <div class="inner-addon right-addon">
                              <textarea
                                type="text"
                                pattern="\d*"
                                maxLength="700"
                                className="form-input"
                                name="address"
                                value={
                                  form &&
                                  form.registeredAddress &&
                                  form.registeredAddress.address
                                }
                                onChange={(e) => {
                                  this.handleAddressChange(
                                    e,
                                    "address",
                                    "registeredAddress"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidateAddressChange(
                                    e,
                                    "address",
                                    "registeredAddress"
                                  )
                                }
                                placeholder="Enter Address"
                                disabled={false}
                                className={`form-input ${
                                  errors.address ? "error-input-border" : ""
                                } `}
                                autocomplete="false"
                              />

                              {errors.registeredAddress.address && (
                                <span className="errorMsg">
                                  Please Enter Address
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </section>
                      {/* communication address */}
                      <section className="tab-body mt-3 mb-3">
                        <div className="row">
                          <h5 className="col-md-12 text-center mb-4">
                            Communication Address
                          </h5>
                          <div className="form-group col-md-4 ">
                            <label>
                              Pin Code <i className="text-danger">*</i>
                            </label>
                            <input
                              type="text"
                              pattern="\d*"
                              maxLength="6"
                              className="form-input"
                              name="pinCode"
                              value={
                                form &&
                                communicationAddress &&
                                communicationAddress.pinCode
                              }
                              onKeyPress={this.restrictAlphabets}
                              onChange={(e) => {
                                this.handleAddressChange(
                                  e,
                                  "pinCode",
                                  "communicationAddress"
                                );
                              }}
                              onBlur={(e) =>
                                this.handleValidateAddressChange(
                                  e,
                                  "pinCode",
                                  "communicationAddress"
                                )
                              }
                              placeholder="Enter Pin Code"
                              className={`form-input ${
                                errors.communicationAddress.pinCode
                                  ? "error-input-border"
                                  : ""
                              } `}
                              id="pincode"
                              disabled={form.isregisteraddsameascommddress}
                            />
                            {errors.communicationAddress.pinCode && (
                              <span className="errorMsg">
                                Please Enter Pincode
                              </span>
                            )}
                            {!errors.communicationAddress.pinCode &&
                              errors.communicationAddress.validPincode && (
                                <span className="errorMsg">
                                  Please Enter Valid Pincode
                                </span>
                              )}
                          </div>
                          <div className="form-group col-md-4">
                            <label>City</label>
                            <input
                              type="text"
                              pattern="\d*"
                              className="form-input"
                              name="city"
                              value={
                                form &&
                                communicationAddress &&
                                communicationAddress.city
                              }
                              placeholder="Enter City"
                              id="city"
                              disabled={true}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label>State</label>
                            <input
                              type="text"
                              pattern="\d*"
                              className="form-input"
                              name="state"
                              value={
                                form &&
                                communicationAddress &&
                                communicationAddress.state
                              }
                              placeholder="Enter State"
                              className={`form-input ${
                                errors.communicationAddress.state
                                  ? "error-input-border"
                                  : ""
                              } `}
                              id="state"
                              disabled={true}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label>Branch</label>
                            <input
                              type="text"
                              pattern="\d*"
                              className="form-input"
                              name="branch"
                              value={
                                form &&
                                communicationAddress &&
                                communicationAddress.branch
                              }
                              placeholder="Enter Branch"
                              id="branch"
                              disabled={true}
                            />
                          </div>
                          {/*  <div className=" col-md-4">
                            <label>
                              Branch <i className="text-danger">*</i>
                            </label>
                            <Select
                              defaultValue={
                                form &&
                                communicationAddress &&
                                communicationAddress.branch &&
                                branchList &&
                                branchList.length > 0 &&
                                branchList.filter(
                                  ({ branchCode }) =>
                                    branchCode == communicationAddress.branch
                                )[0]
                              }
                              onChange={(e) => {
                                this.handlebranchSelectChange(
                                  e,
                                  "communicationAddress"
                                );
                              }}
                              onBlur={(e) =>
                                this.onValidatebranch(e, "communicationAddress")
                              }
                              // isMulti
                              options={branchList}
                              name="branch"
                              placeholder="Branch"
                              valueKey="branchCode"
                              labelKey="branchCode"
                              getOptionLabel={(option) => option["branchCode"]}
                              getOptionValue={(option) => option["branchCode"]}
                              className={`${
                                errors.communicationAddress.branch
                                  ? "error-input-border"
                                  : ""
                              } `}
                              isDisabled={form.isregisteraddsameascommddress}
                              key={key}
                            />
                            {errors.communicationAddress.branch && (
                              <span className="errorMsg">
                                Please select Branch
                              </span>
                            )}
                            </div>*/}
                          <div className="form-group col-md-4 ">
                            <label>
                              Contact Name <i className="text-danger">*</i>
                            </label>
                            <input
                              type="text"
                              pattern="\d*"
                              maxLength="100"
                              className="form-input"
                              name="contactName"
                              value={
                                form &&
                                communicationAddress &&
                                communicationAddress.contactName
                              }
                              onChange={(e) => {
                                this.handleAddressChange(
                                  e,
                                  "contactName",
                                  "communicationAddress"
                                );
                              }}
                              onBlur={(e) =>
                                this.handleValidateAddressChange(
                                  e,
                                  "contactName",
                                  "communicationAddress"
                                )
                              }
                              placeholder="Enter Contact Name"
                              className={`form-input ${
                                errors.communicationAddress.contactName
                                  ? "error-input-border"
                                  : ""
                              } `}
                              disabled={form.isregisteraddsameascommddress}
                            />
                            {errors.communicationAddress.contactName && (
                              <span className="errorMsg">
                                Please enter Contact Name
                              </span>
                            )}
                          </div>

                          <div className="form-group col-md-4  ">
                            <label className="">
                              Mobile Number <i className="text-danger">*</i>
                            </label>
                            <div class="inner-addon right-addon">
                              {/* {mobileVerifyLoading && (
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    )} */}
                              <input
                                type="text"
                                pattern="\d*"
                                maxLength="10"
                                className="form-input"
                                name="mobileNumber"
                                value={
                                  form &&
                                  communicationAddress &&
                                  communicationAddress.mobileNumber
                                }
                                onKeyPress={this.restrictAlphabets}
                                onChange={(e) => {
                                  this.handleAddressChange(
                                    e,
                                    "mobileNumber",
                                    "communicationAddress"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidateAddressChange(
                                    e,
                                    "mobileNumber",
                                    "communicationAddress"
                                  )
                                }
                                placeholder="Enter Mobile Number"
                                className={`form-input ${
                                  errors.communicationAddress.mobileNumber
                                    ? "error-input-border"
                                    : ""
                                } `}
                                disabled={form.isregisteraddsameascommddress}
                              />
                            </div>

                            {errors.communicationAddress.mobileNumber && (
                              <span className="errorMsg">
                                {errors.communicationAddress.mobileNumber ==
                                true
                                  ? "Please enter Mobile Number"
                                  : errors.communicationAddress.mobileNumber}
                              </span>
                            )}
                            {!errors.communicationAddress.mobileNumber &&
                              errors.communicationAddress.validMobile && (
                                <span className="errorMsg">
                                  Please enter valid Mobile Number
                                </span>
                              )}
                          </div>
                          <div className="form-group col-md-4 ">
                            <label>Email ID</label>
                            <div class="inner-addon right-addon">
                              {/* {emailVerifyLoading && (
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    )} */}
                              <input
                                type="text"
                                pattern="\d*"
                                maxLength="50"
                                className="form-input"
                                name="emailId"
                                value={
                                  form &&
                                  communicationAddress &&
                                  communicationAddress.emailId
                                }
                                onChange={(e) => {
                                  this.handleAddressChange(
                                    e,
                                    "emailId",
                                    "communicationAddress"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidateAddressChange(
                                    e,
                                    "emailId",
                                    "communicationAddress"
                                  )
                                }
                                placeholder="Enter Email Address"
                                className={`form-input ${
                                  errors.communicationAddress.emailId ||
                                  errors.communicationAddress.validEmail
                                    ? "error-input-border"
                                    : ""
                                } `}
                                disabled={form.isregisteraddsameascommddress}
                              />
                            </div>
                            {/* {errors.communicationAddress.emailId && (
                              <span className="errorMsg">
                                Please enter Email Address
                              </span>
                            )} */}

                            {errors.communicationAddress.emailId && (
                              <span className="errorMsg">
                                {errors.communicationAddress.emailId}
                              </span>
                            )}

                            {/* {errors.communicationAddress.emailId && (
                              <span className="errorMsg">
                                {errors.communicationAddress.emailId == true
                                  ? "Please enter Email Address"
                                  : errors.communicationAddress.emailId}
                              </span>
                            )} */}
                            {errors.communicationAddress.validEmail && (
                              <span className="errorMsg">
                                Please enter valid Email Address
                              </span>
                            )}
                          </div>
                          <div className="form-group col-md-4">
                            <label>
                              Address <i className="text-danger">*</i>
                            </label>
                            <div class="inner-addon right-addon">
                              <textarea
                                type="text"
                                pattern="\d*"
                                maxLength="200"
                                className="form-input"
                                name="address"
                                value={
                                  form &&
                                  form.communicationAddress &&
                                  form.communicationAddress.address
                                }
                                onChange={(e) => {
                                  this.handleAddressChange(
                                    e,
                                    "address",
                                    "communicationAddress"
                                  );
                                }}
                                onBlur={(e) =>
                                  this.handleValidateAddressChange(
                                    e,
                                    "address",
                                    "communicationAddress"
                                  )
                                }
                                placeholder="Enter Address"
                                disabled={form.isregisteraddsameascommddress}
                                className={`form-input ${
                                  errors.communicationAddress &&
                                  errors.communicationAddress.address
                                    ? "error-input-border"
                                    : ""
                                } `}
                                autocomplete="false"
                              />

                              {errors.communicationAddress &&
                                errors.communicationAddress.address && (
                                  <span className="errorMsg">
                                    Please Enter Address
                                  </span>
                                )}
                            </div>
                          </div>
                          <div className="form-group row col-md-4 mt-4">
                            <div className="col-2 align-center">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={form.isregisteraddsameascommddress}
                                checked={form.isregisteraddsameascommddress}
                                id="isregisteraddsameascommddress"
                                onChange={(e) => {
                                  this.handleRadioChange(
                                    e,
                                    "isregisteraddsameascommddress"
                                  );
                                }}
                                disabled={
                                  registeredAddressMandatoryField ||
                                  (form &&
                                    form.registeredAddress &&
                                    form.registeredAddress.address == "") ||
                                  (form &&
                                    form.registeredAddress &&
                                    form.registeredAddress.branch == "") ||
                                  // (form &&
                                  //   form.registeredAddress &&
                                  //   form.registeredAddress.emailId == "") ||
                                  (form &&
                                    form.registeredAddress &&
                                    form.registeredAddress.mobileNumber ==
                                      "") ||
                                  (form &&
                                    form.registeredAddress &&
                                    form.registeredAddress.contactName == "") ||
                                  (errors &&
                                    errors.registeredAddress &&
                                    errors.registeredAddress.emailId) ||
                                  (errors &&
                                    errors.registeredAddress &&
                                    errors.registeredAddress.mobileNumber) ||
                                  (errors &&
                                    errors.communicationAddress &&
                                    errors.communicationAddress.mobileNumber)
                                }
                              />
                            </div>
                            <div className="col-10 align-center">
                              <label
                                className="form-check-label"
                                for="isregisteraddsameascommddress"
                              >
                                Same as registered address
                              </label>
                            </div>
                          </div>
                        </div>
                      </section>
                    </section>
                    {/* bank section */}
                    <section className="tab-body mt-3 mb-3">
                      <div className="row">
                        <h5 className="col-md-12 text-center mb-4">
                          Bank Details
                        </h5>
                        <div className="form-group col-md-4">
                          <label>
                            Bank account name <i className="text-danger">*</i>
                          </label>
                          <input
                            type="text"
                            pattern="\d*"
                            maxLength="100"
                            className="form-input"
                            name="bankAccountName"
                            value={form.bankAccountName}
                            onChange={(e) => {
                              this.handleRadioChange(e, "bankAccountName");
                            }}
                            onBlur={(e) =>
                              this.handleValidate(e, "bankAccountName")
                            }
                            placeholder="Enter Bank account name"
                            disabled={false}
                          />
                          {errors.bankAccountName && (
                            <span className="errorMsg">
                              Please Enter Bank account name
                            </span>
                          )}
                        </div>

                        <div className="form-group col-md-4">
                          <label>
                            Bank Name <i className="text-danger">*</i>
                          </label>
                          <input
                            type="text"
                            pattern="\d*"
                            maxLength="100"
                            className="form-input"
                            name="bankName"
                            value={form.bankName}
                            onChange={(e) => {
                              this.handleRadioChange(e, "bankName");
                            }}
                            onBlur={(e) => this.handleValidate(e, "bankName")}
                            placeholder="Enter Bank Name"
                            disabled={false}
                            // className={`form-input ${
                            //   errors.bankName ? "error-input-border" : ""
                            // } `}
                          />
                          {errors.bankName && (
                            <span className="errorMsg">
                              Please enter Bank Name
                            </span>
                          )}
                          {/* {!errors.bankName && errors.validBankNameError && (
                                                <span className="errorMsg">
                                                    Bank name should be in character.
                                                </span>
                                            )} */}
                        </div>

                        <div className="form-group col-md-4">
                          <label>
                            {" "}
                            Account Number <i className="text-danger">*</i>
                          </label>
                          <input
                            type="number"
                            pattern="\d*"
                            maxLength="16"
                            className="form-input"
                            name="accountNumber"
                            value={form.accountNumber}
                            onKeyPress={this.restrictAlphabets}
                            onChange={(e) => {
                              if (e.target.value.toString().length <= 16)
                                this.handleRadioChange(e, "accountNumber");
                            }}
                            onBlur={(e) =>
                              this.handleValidate(e, "accountNumber")
                            }
                            placeholder="Enter Account Number"
                            disabled={false}
                            className={`form-input ${
                              errors.accountNumber ? "error-input-border" : ""
                            } `}
                          />
                          {errors.accountNumber && (
                            <span className="errorMsg">
                              Please Enter Account Number
                            </span>
                          )}
                          {!errors.accountNumber &&
                            errors.validAccountNumber && (
                              <span className="errorMsg">
                                Account number should be between 9 to 16.
                              </span>
                            )}
                        </div>

                        <div className="form-group col-md-4">
                          <label>
                            IFSC Code <i className="text-danger">*</i>
                          </label>
                          <input
                            type="text"
                            pattern="\d*"
                            maxLength="11"
                            className="form-input"
                            name="ifscCode"
                            value={form.ifscCode}
                            onChange={(e) => {
                              let regex =
                                /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
                              if (!regex.test(e.target.value))
                                this.handleRadioChange(e, "ifscCode");
                            }}
                            onBlur={(e) => this.handleValidate(e, "ifscCode")}
                            placeholder="Enter IFSC Code"
                            disabled={false}
                            className={`form-input ${
                              errors.ifscCode ? "error-input-border" : ""
                            } `}
                          />
                          {errors.ifscCode && (
                            <span className="errorMsg">
                              Please enter IFSC Code
                            </span>
                          )}
                          {!errors.ifscCode && errors.isValidIfsc && (
                            <span className="errorMsg">
                              Please enter valid IFSC Code
                            </span>
                          )}
                        </div>
                        <div className="offset-md-4 offset-lg-4 col-md-4 col-lg-4 align-center-end mt-3">
                          <button
                            type="button"
                            disabled={
                              form.bankAccountName == "" ||
                              form.bankName == "" ||
                              form.accountNumber == "" ||
                              form.ifscCode == "" ||
                              verifyingBank ||
                              (!errors.ifscCode && errors.isValidIfsc) ||
                              (!errors.accountNumber &&
                                errors.validAccountNumber) ||
                              form.bankVerified
                            }
                            class="btn btn-primary btn-sm"
                            style={{ width: 100 }}
                            onClick={this.handleBankVerification}
                          >
                            {verifyingBank ? (
                              <React.Fragment>
                                <i class="fa fa-spinner fa-spin"></i>
                                Verifying...
                              </React.Fragment>
                            ) : form.bankVerified ? (
                              "Verified"
                            ) : (
                              "Verify"
                            )}
                          </button>
                        </div>
                      </div>
                    </section>

                    <section className="tab-body mt-3 mb-3">
                      <div className="row">
                        <h5 className="col-md-12 text-center mb-4">
                          Upload Document
                        </h5>
                        {/* kyc doc */}

                        <div className="col-md-4">
                          <label>
                            Upload KYC document<i className="text-danger">*</i>
                          </label>
                          <ImageUploader
                            name="kycDocument"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="KYC document"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "kycDocument");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (res && res.description == "kycDocument") {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                          {errors.kycDocument && (
                            <span className="errorMsg">
                              Please Upload KYC document
                            </span>
                          )}
                        </div>

                        {/* upload agrrement */}

                        <div className="col-md-4">
                          <label className="align-center">
                            Upload Master Franchise Agreement{" "}
                            <i className="text-danger">*</i>{" "}
                          </label>
                          <ImageUploader
                            name="appraiserAppointment"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            // buttonText="Upload Agreement"
                            buttonText=" Master Franchise Agreement"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "appraiserAppointment");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />
                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "appraiserAppointment"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        {/* payout doc */}

                        <div className="col-md-4">
                          <label className="align-center">
                            Upload Equipment Lease Agreement{" "}
                            <i className="text-danger">*</i>{" "}
                          </label>
                          <ImageUploader
                            name="chequeGaurantee"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            // buttonText="Upload Payout"
                            buttonText="Equipment Lease Agreement"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "chequeGaurantee");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />
                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "chequeGaurantee"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        <div className="col-md-4">
                          <label>Upload CIBIL Report</label>
                          <ImageUploader
                            name="demandPromissoryNote"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="CIBIL Report"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "demandPromissoryNote");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />
                          {/* {errors.demandPromissoryNote && (
                                                <span className="errorMsg">Please Upload Demand promissory note</span>
                                            )} */}
                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "demandPromissoryNote"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <label>Upload Franchise Detail Form</label>
                          <ImageUploader
                            name="aggrement"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Franchise Detail Form"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "aggrement");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />
                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (res && res.description == "aggrement") {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                          {errors.appraiserAppointment && (
                            <span className="errorMsg">
                              Please Upload Appraisers appointment agreement
                            </span>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label>Upload Franchise Survey</label>
                          <ImageUploader
                            name="dpnLetter"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Franchise Survey"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "dpnLetter");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />
                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (res && res.description == "dpnLetter") {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-md-4 mt-5">
                          <label style={{ whiteSpace: "pre" }}>
                            Upload Cheque guarantee/ cancelled cheque
                          </label>
                          <ImageUploader
                            name="payoutdoc"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText=" Cheque guarantee/ cancelled cheque"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "payoutdoc");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />
                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (res && res.description == "payoutdoc") {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                          {errors.chequeGaurantee && (
                            <span className="errorMsg">
                              Please Upload Cheque guarantee/ cancelled cheque
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 mt-5">
                          <label>Upload Passport photo</label>
                          <ImageUploader
                            name="passportPhoto"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText=" Passport photo"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "passportPhoto");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (res && res.description == "passportPhoto") {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                          {errors.passportPhoto && (
                            <span className="errorMsg">
                              Please Upload Passport photo
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 mt-5">
                          <label>Upload Franchise/Implement Economics</label>
                          <ImageUploader
                            name="resume"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText=" Franchise/Implement Economics"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "resume");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (res && res.description == "resume") {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                          {errors.resume && (
                            <span className="errorMsg">
                              Please Upload Resume
                            </span>
                          )}
                        </div>
                        <div className="col-md-5 mt-5">
                          <label style={{ whiteSpace: "pre" }}>
                            Upload Shop registration document / Appraiser
                            certificate
                          </label>
                          <ImageUploader
                            name="shopRegistration"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Shop registration document / Appraiser certificate"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "shopRegistration");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "shopRegistration"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                          {errors.shopRegistration && (
                            <span className="errorMsg">
                              Please Upload Shop registration document /
                              Appraiser certificate
                            </span>
                          )}
                        </div>
                        {/* <div className="col-md-3 mt-5">
                          <label>
                            Upload KYC document<i className="text-danger">*</i>
                          </label>
                          <ImageUploader
                            name="kycDocument"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="KYC document"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "kycDocument");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (res && res.description == "kycDocument") {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <a
                                        href={`${FileUrl}${path}`}
                                        download
                                        target="_blank"
                                      >
                                        {res && res.documentName}
                                      </a>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                          {errors.kycDocument && (
                            <span className="errorMsg">
                              Please Upload KYC document
                            </span>
                          )}
                        </div> */}
                        <div className="col-md-4 mt-5">
                          <label>Upload Substitute appraiser document</label>
                          <ImageUploader
                            name="substituteAppraiser"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Substitute appraiser document"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "substituteAppraiser");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "substituteAppraiser"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                          {errors.substituteAppraiser && (
                            <span className="errorMsg">
                              Please Upload Substitute appraiser document
                            </span>
                          )}
                        </div>
                        <div className="col-md-3 mt-5">
                          <label>Upload Franchise Qualitative Score Card</label>
                          <ImageUploader
                            name="bmInterviewSheet"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Franchise Qualitative Score Card"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "bmInterviewSheet");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "bmInterviewSheet"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                          {errors.bmInterviewSheet && (
                            <span className="errorMsg">
                              Please Upload BM interview sheet
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 mt-5">
                          <label>Upload Security Deposit Slip</label>
                          <ImageUploader
                            name="form60"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Security Deposit Slip"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "form60");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />

                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (res && res.description == "form60") {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                          {errors.form60 && (
                            <span className="errorMsg">
                              Please Upload Form 60
                            </span>
                          )}
                        </div>
                        {/* <div className="col-md-4 mt-5">
                          <label className="align-center">
                            Upload Agreement <i className="text-danger">*</i>{" "}
                          </label>
                          <ImageUploader
                            name="appraiserAppointment"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Upload Agreement"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "appraiserAppointment");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />
                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "appraiserAppointment"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <a
                                        href={`${FileUrl}${path}`}
                                        download
                                        target="_blank"
                                      >
                                        {res && res.documentName}
                                      </a>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div> */}
                        {/* <div className="col-md-4 mt-5">
                          <label className="align-center">
                            Upload Payout <i className="text-danger">*</i>{" "}
                          </label>
                          <ImageUploader
                            name="chequeGaurantee"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Upload Payout"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "chequeGaurantee");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />
                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "chequeGaurantee"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <a
                                        href={`${FileUrl}${path}`}
                                        download
                                        target="_blank"
                                      >
                                        {res && res.documentName}
                                      </a>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div> */}
                        <div className="col-md-4 mt-5">
                          <label>Upload Implement Quotation</label>
                          <ImageUploader
                            name="otherDocument1"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Implement Quotation"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "otherDocument1");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />
                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "otherDocument1"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>

                        <div className="col-md-4 mt-5">
                          <label>Upload Implement Invoice</label>
                          <ImageUploader
                            name="otherDocument2"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Implement Invoice"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "otherDocument2");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />
                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "otherDocument2"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-md-4 mt-5">
                          <label>Upload Other document 1</label>
                          <ImageUploader
                            name="otherDocument3"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Other document 1"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "otherDocument3");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            disabled={false}
                          />
                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "otherDocument3"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div className="col-md-4 mt-5">
                          <label>Upload Other document 2</label>
                          <ImageUploader
                            name="otherDocument4"
                            withIcon={true}
                            withPreview={true}
                            withLabel={true}
                            accept="image/*"
                            buttonText="Other document 2"
                            onChange={(file, base64) => {
                              this.onDrop(file, base64, "otherDocument4");
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".jpeg",
                              ".pdf",
                              ".docx",
                              ".pptx",
                              ".xlsx",
                              ".xls",
                              ".doc",
                              ".ppt",
                            ]}
                            maxFileSize={5242880}
                            singleImage={true}
                            buttonClassName={"disabled"}
                          />
                          <ul>
                            {form &&
                              form.franchiseDocument &&
                              form.franchiseDocument.length > 0 &&
                              form.franchiseDocument.map((res) => {
                                if (
                                  res &&
                                  res.description == "otherDocument4"
                                ) {
                                  let pathArray =
                                    res && res.documentPath.split("/");
                                  let path = "";
                                  pathArray.map((section, index) => {
                                    if (index > 3) {
                                      path += section;
                                      if (index != pathArray.length - 1)
                                        path += "/";
                                    }
                                  });
                                  return (
                                    <li className="p-1">
                                      <span className="mr-2 text-link fw-500 cursor-pointer">
                                        <Link
                                          onClick={() =>
                                            this.handleViewDoc(res.documentPath)
                                          }
                                        >
                                          {res.documentPath}
                                        </Link>
                                      </span>
                                      <span
                                        className="text-danger ml-3"
                                        onClick={() =>
                                          this.handleDocDelete(
                                            res && res.documentId
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <p className="mt-5" style={{ color: "black" }}>
                          <i className="text-danger">*</i>Upload documents in
                          jpg, jpeg, png, pdf, gif, docx, pptx and xlsx format
                          with Maximum size up to 5 MB
                        </p>
                      </div>
                      <div className="row">
                        <div className="pl-0 p-3 col-md-6">
                          <FormControlLabel
                            control={
                              <Switch
                                checked={form.active}
                                onChange={this.openActivateDeactiveModal}
                                name="active"
                                color="primary"
                              />
                            }
                            label={form.active ? "Active" : "In-Active"}
                            className="activateDeactivate"
                          />
                        </div>
                      </div>
                    </section>
                    {/* {previousStatus == "PENDING" && <section className="tab-body mt-3 mb-3">
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<Checkbox checked={form && form.status == "APPROVED"} onChange={(e) => this.handleApproveReject(e, "APPROVED")} name="status" color="primary" />}
                                                    label="Approve"
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox checked={form && form.status == "REJECTED"} onChange={(e) => this.handleApproveReject(e, "REJECTED")} name="status" color="primary" />}
                                                    label="Reject"
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </section>} */}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button className="btn-danger" onClick={this.close}>
                    Cancel
                  </Button>
                  <Button
                    className="btn-success"
                    disabled={
                      disableSave ||
                      loading ||
                      (errors &&
                        errors.registeredAddress &&
                        errors.registeredAddress.validMobile) ||
                      (errors &&
                        errors.communicationAddress &&
                        errors.communicationAddress.validMobile) ||
                      (errors &&
                        errors.communicationAddress &&
                        errors.communicationAddress.validEmail) ||
                      (errors &&
                        errors.registeredAddress &&
                        errors.registeredAddress.validEmail) ||
                      (errors &&
                        errors.communicationAddress &&
                        errors.communicationAddress.mobileNumber ==
                          "Mobile number already exist") ||
                      (errors &&
                        errors.registeredAddress &&
                        errors.registeredAddress.mobileNumber ==
                          "Mobile number already exist") ||
                      loading ||
                      isDedupe ||
                      isEmailPresent ||
                      isMobileNumberPresent ||
                      villagePresent ||
                      (userZone && userZone.length == 0) ||
                      form.location == ""
                    }
                    onClick={this.handleSubmit}
                  >
                    {loading ? (
                      <React.Fragment>
                        <i class="fa fa-spinner fa-spin"></i>
                        Updating...
                      </React.Fragment>
                    ) : (
                      "Save"
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>
              <CloseSection
                show={this.state.showInner}
                button2={this.close}
                button1={this.handleClose}
                title="Do you want to close ?"
              />
            </div>
            <Modal
              className="delete-document"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showDeletemodal && showDeletemodal}
              id="delete-gold-appraiser-doc"
              onHide={() => {
                this.handleDeleteModalclose();
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title className="w-100">Confirm to Delete</Modal.Title>
              </Modal.Header>
              <Modal.Body className={`p-4 `} style={{ background: "#f8f9fa" }}>
                <h5>Are you sure to do this?</h5>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn-danger"
                  onClick={this.handleDeleteModalclose}
                >
                  No
                </Button>
                <Button
                  className="btn-success"
                  disabled={loading}
                  onClick={this.handleDelete}
                >
                  {loading ? (
                    <>
                      <i class="fa fa-spinner fa-spin"></i> Deleting.....
                    </>
                  ) : (
                    "Yes, Delete It!"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              className="activate-deactivate"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showActivateDeactivatemodal && showActivateDeactivatemodal}
              id="activate-deactivate-gold-appraiser"
              onHide={() => {
                this.handleActivateDeactivateModalclose();
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title className="w-100">
                  Confirm to {form.active == true ? "In-Active" : "Active"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className={`p-4 `} style={{ background: "#f8f9fa" }}>
                <h5>Are you sure to do this?</h5>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn-danger"
                  onClick={this.handleActivateDeactivateModalclose}
                >
                  No
                </Button>
                <Button
                  className="btn-success"
                  disabled={
                    loading ||
                    disableSave ||
                    isDedupe ||
                    isEmailPresent ||
                    isMobileNumberPresent ||
                    villagePresent
                  }
                  onClick={this.handleActivateDeactivate}
                >
                  {loading ? (
                    <>
                      <i class="fa fa-spinner fa-spin"></i>
                      {form.active == true ? "In-Activating" : "Activating"}
                      .....
                    </>
                  ) : (
                    "Yes"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        ) : (
          <h4 className="text-center mt-5">LOADING...</h4>
        )}
      </>
    );
  }
}

export default withRouter(FranchiseAppraiser);
