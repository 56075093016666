import { get, post } from "../httpInterceptor";

// localhost:8080/user/getallusersByEmpId?empId=BCM11
export const getalluserByEmpId = (empId) => {
  return get(`/geography/getUsersByEmpId?empId=${empId}`).then((res) => {
    console.log("RESPONSE :: getalluserByEmpId ::: ", res);
    return res;
  });
};
export const getallusers = () => {
  return get(`/user/users`).then((res) => {
    console.log("RESPONSE :: getalluserByEmpId ::: ", res);
    return res;
  });
};

export const getuserbyid = (employeeIdfk) => {
  return get(`/user/users/${employeeIdfk}`).then((res) => {
    console.log("RESPONSE :: getuserbyid ::: ", res);
    return res;
  });
};

//find All roles
export const findAllRoles = () => {
  return get(`/findAllRoles`).then((res) => {
    console.log("RESPONSE :: findAllRoles ::: ", res);
    return res;
  });
};

// Get All Zones
export const getallzones = () => {
  return get(`/geography/zones`).then((res) => {
    console.log("RESPONSE :: getallzones ::: ", res);
    return res;
  });
};

// Get All States
export const getallstates = (zoneId) => {
  return get(`/geography/state/${zoneId}`).then((res) => {
    console.log("RESPONSE :: getallstates ::: ", res);
    return res;
  });
};

//Get All Region
export const getallregion = (stateId) => {
  return get(`/geography/region/${stateId}`).then((res) => {
    console.log("RESPONSE :: getallregions ::: ", res);
    return res;
  });
};

// Get All Branch
export const getallbranch = (regionId) => {
  return get(`/geography/branch/${regionId}`).then((res) => {
    console.log("RESPONSE :: getallbranches ::: ", res);
    return res;
  });
};

// Get All Additional Branch https://uat-api.sarvagram.net/admin/v1.0/geography/branch/state/Maharashtra
export const getallAdditionalbranch = (regionId) => {
  return get(`/geography/branch/state/${regionId}`).then((res) => {
    console.log("RESPONSE :: getallbranches ::: ", res);
    return res;
  });
};

export const findallspokes = (branchId) => {
  //   return post(`/geography/spokes`, obj, false).then(
  return get(`/geography/spokes/${branchId}`).then((res) => {
    console.log("RESPONSE :: findallvillagesmaster ::: ", res);
    return res;
  });
};

export const addusers = (obj) => {
  return post(`/user/users`, obj, false).then((res) => {
    console.log("addusers", res);
    return res;
  });
};

export const addUpdate = (obj) => {
  return post(`/user/users/update`, obj, false).then((res) => {
    console.log("addusers", res);
    return res;
  });
};
export const addCreate = (obj) => {
  return post(`/user/users/create`, obj, false).then((res) => {
    console.log("addusers", res);
    return res;
  });
};
