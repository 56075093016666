import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import AddEditPerCancel from "./AddEditPerCancel";
import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import { findallcancellationPermaster } from "../../../Utils/Services/CancelPerMaster";
toast.configure();

const CancelMaster = (props) => {
  let [cancelData, setcancelData] = useState([]);
  let [showEditModal, setshowEditModal] = useState(false);
  let [editData, setEditData] = useState({});

  useEffect(() => {
    FetchCancelPerMaster();
  }, []);

  const FetchCancelPerMaster = () => {
    findallcancellationPermaster().then((response) => {
      if (response.error) {
        return;
      }
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        if (response && response.data) {
          setcancelData([response.data]);
        }
      }
    });
  };

  const columns = [
    {
      Header: "SR. No",
      Cell: (data) => {
        return data.index + 1;
      },
    },
    {
      Header: "Allowed % value for cancellation",
      Cell: ({ original }) => {
        return original && original.percentage ? original.percentage : "0";
      },
    },

    {
      Header: "Action",
      disabled: false,
      accessor: "edit",

      Cell: ({ original }) => {
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              onClick={() => handleEditCancelMaster(original)}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const handleEditCancelMaster = (obj) => {
    setshowEditModal(!showEditModal);
    setEditData(obj);
  };

  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box">
            {" "}
            Cancellation Percentage Master
          </h5>
          <div className="d-flex align-items-center mt-5">
            {showEditModal && (
              <AddEditPerCancel
                action={"edit"}
                cancelMaster={FetchCancelPerMaster}
                editData={editData}
                handleEditCancelMaster={handleEditCancelMaster}
              />
            )}
          </div>

          <div className={`mt-4 react-table`}>
            <ReactTable
              data={cancelData}
              columns={columns}
              defaultPageSize={5}
              showPagination={false}
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default CancelMaster;
