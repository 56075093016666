import { get, post } from "../httpInterceptor";

// API for get all funding details

export const getAllFunding = () => {
  return get(`/sarvagram-credit/masternew/getfundinggridmaster`).then((res) => {
    console.log("RESPONSE :: getAllFunding ::: ", res);
    return res;
  });
};

// API for save funding details

export const addFundingGrid = (obj) => {
  return post(
    `/sarvagram-credit/masternew/savefundinggridmaster`,
    obj,
    false
  ).then((res) => {
    console.log("addFundingGrid", res);
    return res;
  });
};

// API for update funding details

export const updateFundingGrid = (obj) => {
  return post(
    `/sarvagram-credit/masternew/updatefundinggridtmaster`,
    obj,
    false
  ).then((res) => {
    console.log("updateFundingGrid", res);
    return res;
  });
};

/* export const deleteFundingGrid = () => {
    return get(`/sarvagram-credit/masternew/deletefundinggridmaster`).then(res => {
        console.log("RESPONSE :: deleteFundingGrid ::: ", res);
        return res;
    });
}; */

// API for delete funding details

export const deleteFundingGrid = (obj) => {
  return post(
    `/sarvagram-credit/masternew/deletefundinggridmaster`,
    obj,
    false
  ).then((res) => {
    console.log("deleteFundingGrid", res);
    return res;
  });
};

// API for get lower limit

export const getLowerLimit = () => {
  return get(`/sarvagram-credit/masternew/gethigherlimitmaster`).then((res) => {
    console.log("RESPONSE :: getLowerLimit ::: ", res);
    return res;
  });
};
