import React, { useState, useEffect, Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from "lodash";

import CloseModal from "../../../Components/CloseModal";
import {
  formatIndianCurrency,
  replaceComma,
  checkIfNotDecimal,
} from "../../../Utils/helperFunctions";
import {
  findallfamilymemberexpense,
  saveorupdatefamilymemberexpense,
  deletefamilymemberexpensebyid,
  saveAndupdatefamilymemberexpense,
  updatefamilymemberexpense,
} from "../../../Utils/Services/family";
import { getAllLevelsDropdown } from "../../../Utils/Services/common";

toast.configure();

const initForm = {
  hhMembers: "",
  zeroToThirtyExpense: "",
  thirtyToFiftyExpense: "",
  fiftyToEightyExpense: "",
  eightyToHundredExpense: "",
  moreThanHundredExpense: "",
  errors: {
    hhMembers: null,
    zeroToThirtyExpense: null,
    thirtyToFiftyExpense: null,
    fiftyToEightyExpense: null,
    eightyToHundredExpense: null,
    moreThanHundredExpense: null,
  },
};

const memberType = [
  { memberLebel: "Adult", memberValue: "Adult" },
  { memberLebel: "Child", memberValue: "Child" },
];

export class AddEditfamily extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      showInner: false,
      form: cloneDeep(initForm),
      levelDropdownOptions: [],
    };
  }

  componentDidMount = () => {
    let { form } = this.state;
    this.setState({ showModal: true });
    console.log("props.editData", this.props.editData);
    if (this.props.editData && this.props.action == "edit") {
      let { form } = this.state;
      let obj = { ...form, ...this.props.editData };
      this.setState({ form: obj });
    }
  };

  handleModalClose = () => {
    this.setState({ showInner: !this.state.showInner });
    // this.this.props.addRole();
  };

  innerModalClose = (modclose) => {
    if (!modclose) {
      this.handleModalClose();
    }
    this.setState({ showModal: false });
    if (this.props.action == "edit") {
      this.props.handleEditfamilymember();
    } else {
      this.props.handleAddfamilymember();
    }
  };

  handleSubmit = () => {
    let { form } = this.state;
    let obj = form;
    this.setState({ isLoading: true });
    saveAndupdatefamilymemberexpense(obj).then((response) => {
      this.setState({ isLoading: false });
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        this.props.familymemberexpense();

        this.props.handleAddfamilymember();

        this.setState({ showModal: false });
        toast.success("Data added successfylly", {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      }
    });
  };

  handleUpdate = () => {
    let { form } = this.state;
    let obj = form;
    let id = form.id;
    console.log("ytytytytyt", id);

    this.setState({ isLoading: true });
    updatefamilymemberexpense(obj, id).then((response) => {
      this.setState({ isLoading: false });
      if (response.data && response.data.error) {
        toast.error(response.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        this.props.familymemberexpense();
        if (this.props.editData && this.props.action == "edit") {
          this.props.handleEditfamilymember();
        } else {
          this.props.handleAddfamilymember();
        }
        this.setState({ showModal: false });
        toast.success("Data updated successfully", {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      }
    });
  };

  handleLevelSelectChanges = (e, name) => {
    console.log("handleLevelSelectChanges", e, name);
    console.log("nm", name);
    console.log("event", e);
    let { form } = this.state;
    let { levelcode } = e;
    console.log(levelcode);
    form[name] = levelcode;
    this.setState({ form });
  };

  handleInputChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("handleInputChange", e, name, e.target.value);
    form[name] = e.target.value;
    this.setState({ form });
  };

  handleSelectChanges = (e, name) => {
    console.log("handleSegmentSelectChanges", e, name);
    let { form } = this.state;
    let { memberValue } = e;
    form[name] = memberValue;
    this.setState({ form });
  };

  onValidate = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    if (form[name] === "" || form[name] === null || form[name] === undefined) {
      form.errors[name] = true;
      this.setState({ form });
    } else {
      form.errors[name] = null;
      this.setState({ form });
    }
  };

  render() {
    let { form, showModal, showInner, isLoading } = this.state;
    let { levelDropdownOptions } = this.props;
    let { errors } = form;
    let diableSave = false;
    let isRequiredFeildFilled = false;

    Object.keys(form).map((name) => {
      console.log("form", form[name]);
      if (
        form[name] === "" ||
        form[name] === null ||
        form[name] === undefined
      ) {
        isRequiredFeildFilled = true;
        return true;
      }
    });

    Object.keys(errors).map((error) => {
      console.log("error", errors[error]);
      if (errors[error] === true || errors[error] != null) {
        diableSave = true;
        return true;
      }
    });
    console.log("error", diableSave);
    console.log("form", form);
    return (
      <React.Fragment>
        <Modal
          className="product-norm-container"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showModal}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>
              {this.props.action == "edit"
                ? "Edit Family Member expense"
                : "Add Family Member expense"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            {isLoading ? (
              <h4 className="text-center">LOADING...</h4>
            ) : (
              <div className="row ">
                <div className="mb-4 col-md-4 ">
                  <label>
                    <i className="text-danger">*</i> HH Members
                  </label>
                  <div class="inner-addon right-addon">
                    <input
                      type="text"
                      // pattern="\d*"
                      maxLength="9"
                      name="hhMembers"
                      value={form.hhMembers}
                      onChange={(e) => {
                        e.target.value = replaceComma(e.target.value);
                        if (checkIfNotDecimal(e.target.value)) {
                          this.handleInputChange(e, "hhMembers");
                        }
                      }}
                      onBlur={(e) => {
                        e.target.value = replaceComma(e.target.value);
                        this.onValidate(e, "hhMembers");
                      }}
                      placeholder="Enter HH Members"
                      className={`form-input ${
                        errors.hhMembers ? "error-input-border" : ""
                      } `}
                      disabled={false}
                    />
                    {errors.expense && (
                      <span className="errorMsg">
                        {errors.hhMembers == true
                          ? "Enter HH Members"
                          : errors.hhMembers}
                      </span>
                    )}
                    {/* {!errors.loanamtnorms && invalidPanNumber && (
                                                        <span className="errorMsg">Please enter valid PAN</span>
                                                    )} */}
                  </div>
                  {/* <label>
                    <i className="text-danger">*</i> Level
                  </label>
                  <Select
                    defaultValue={
                      levelDropdownOptions.filter(
                        ({ levelcode }) => levelcode == form.level
                      )[0]
                    }
                    onChange={(e) => {
                      this.handleLevelSelectChanges(e, "level");
                    }}
                    onBlur={(e) => this.onValidate(e, "level")}
                    name="level"
                    options={levelDropdownOptions}
                    placeholder="Select level"
                    valueKey="levelcode"
                    labelKey="levelcode"
                    getOptionLabel={(option) => option.levelcode}
                    getOptionValue={(option) => option.levelcode}
                    className={`${errors.level ? "error-input-border" : ""} `}
                    isDisabled={false}
                  />
                  {errors.level && (
                    <span className="errorMsg">Please Enter Level</span>
                  )} */}
                </div>
                <div className="mb-4 col-md-4 col-lg-4 col-sm-12">
                  <label>
                    <i className="text-danger">*</i> Upto 30K
                  </label>
                  <div class="inner-addon right-addon">
                    <input
                      type="text"
                      // pattern="\d*"

                      name="zeroToThirtyExpense"
                      value={
                        form.zeroToThirtyExpense &&
                        formatIndianCurrency(
                          form.zeroToThirtyExpense
                        ).toString()
                      }
                      onChange={(e) => {
                        e.target.value = replaceComma(e.target.value);
                        if (checkIfNotDecimal(e.target.value)) {
                          this.handleInputChange(e, "zeroToThirtyExpense");
                        }
                      }}
                      onBlur={(e) => {
                        e.target.value = replaceComma(e.target.value);
                        this.onValidate(e, "zeroToThirtyExpense");
                      }}
                      placeholder="Enter Value"
                      className={`form-input ${
                        errors.zeroToThirtyExpense ? "error-input-border" : ""
                      } `}
                      disabled={false}
                    />
                    {errors.zeroToThirtyExpense && (
                      <span className="errorMsg">
                        {errors.zeroToThirtyExpense == true
                          ? "Enter value"
                          : errors.zeroToThirtyExpense}
                      </span>
                    )}
                    {/* {!errors.loanamtnorms && invalidPanNumber && (
                                                        <span className="errorMsg">Please enter valid PAN</span>
                                                    )} */}
                  </div>
                  {/* <label>
                    <i className="text-danger">*</i> Type
                  </label>
                  <Select
                    defaultValue={
                      memberType.filter(
                        ({ memberValue }) => memberValue == form.type
                      )[0]
                    }
                    onChange={(e) => {
                      this.handleSelectChanges(e, "type");
                    }}
                    onBlur={(e) => this.onValidate(e, "type")}
                    name="type"
                    options={memberType}
                    placeholder="Select Type"
                    // value={form.type}
                    valueKey="memberValue"
                    labelKey="memberLebel"
                    getOptionLabel={(option) => option.memberLebel}
                    getOptionValue={(option) => option.memberValue}
                    className={`${errors.type ? "error-input-border" : ""} `}
                    isDisabled={false}
                  />
                  {errors.product && (
                    <span className="errorMsg">Please Select Product</span>
                  )} */}
                </div>
                <div className="form-group col-md-4 ">
                  <label>
                    <i className="text-danger">*</i> 30K to 50K
                  </label>
                  <div class="inner-addon right-addon">
                    <input
                      type="text"
                      // pattern="\d*"

                      name="thirtyToFiftyExpense"
                      value={
                        form.thirtyToFiftyExpense &&
                        formatIndianCurrency(
                          form.thirtyToFiftyExpense
                        ).toString()
                      }
                      onChange={(e) => {
                        e.target.value = replaceComma(e.target.value);
                        if (checkIfNotDecimal(e.target.value)) {
                          this.handleInputChange(e, "thirtyToFiftyExpense");
                        }
                      }}
                      onBlur={(e) => {
                        e.target.value = replaceComma(e.target.value);
                        this.onValidate(e, "thirtyToFiftyExpense");
                      }}
                      placeholder="Enter Value"
                      className={`form-input ${
                        errors.thirtyToFiftyExpense ? "error-input-border" : ""
                      } `}
                      disabled={false}
                    />
                    {errors.thirtyToFiftyExpense && (
                      <span className="errorMsg">
                        {errors.thirtyToFiftyExpense == true
                          ? "Field is Empty"
                          : errors.thirtyToFiftyExpense}
                      </span>
                    )}
                    {/* {!errors.loanamtnorms && invalidPanNumber && (
                                                        <span className="errorMsg">Please enter valid PAN</span>
                                                    )} */}
                  </div>
                </div>
                <div className="mb-4 col-md-4 ">
                  <label>
                    <i className="text-danger">*</i> {"> 50K to 80K"}
                  </label>
                  <div class="inner-addon right-addon">
                    <input
                      type="text"
                      // pattern="\d*"
                      maxLength="9"
                      name="fiftyToEightyExpense"
                      value={
                        form.fiftyToEightyExpense &&
                        formatIndianCurrency(
                          form.fiftyToEightyExpense
                        ).toString()
                      }
                      onChange={(e) => {
                        e.target.value = replaceComma(e.target.value);
                        if (checkIfNotDecimal(e.target.value)) {
                          this.handleInputChange(e, "fiftyToEightyExpense");
                        }
                      }}
                      onBlur={(e) => {
                        e.target.value = replaceComma(e.target.value);
                        this.onValidate(e, "fiftyToEightyExpense");
                      }}
                      placeholder="Enter Expense"
                      className={`form-input ${
                        errors.fiftyToEightyExpense ? "error-input-border" : ""
                      } `}
                      disabled={false}
                    />
                    {errors.fiftyToEightyExpense && (
                      <span className="errorMsg">
                        {errors.fiftyToEightyExpense == true
                          ? "Field is Empty"
                          : errors.fiftyToEightyExpense}
                      </span>
                    )}
                    {/* {!errors.loanamtnorms && invalidPanNumber && (
                                                        <span className="errorMsg">Please enter valid PAN</span>
                                                    )} */}
                  </div>
                  {/* <label>
                    <i className="text-danger">*</i> Level
                  </label>
                  <Select
                    defaultValue={
                      levelDropdownOptions.filter(
                        ({ levelcode }) => levelcode == form.level
                      )[0]
                    }
                    onChange={(e) => {
                      this.handleLevelSelectChanges(e, "level");
                    }}
                    onBlur={(e) => this.onValidate(e, "level")}
                    name="level"
                    options={levelDropdownOptions}
                    placeholder="Select level"
                    valueKey="levelcode"
                    labelKey="levelcode"
                    getOptionLabel={(option) => option.levelcode}
                    getOptionValue={(option) => option.levelcode}
                    className={`${errors.level ? "error-input-border" : ""} `}
                    isDisabled={false}
                  />
                  {errors.level && (
                    <span className="errorMsg">Please Enter Level</span>
                  )} */}
                </div>
                <div className="mb-4 col-md-4 ">
                  <label>
                    <i className="text-danger">*</i> {"> 80K to 1L"}
                  </label>
                  <div class="inner-addon right-addon">
                    <input
                      type="text"
                      // pattern="\d*"
                      maxLength="9"
                      name="eightyToHundredExpense"
                      value={
                        form.eightyToHundredExpense &&
                        formatIndianCurrency(
                          form.eightyToHundredExpense
                        ).toString()
                      }
                      onChange={(e) => {
                        e.target.value = replaceComma(e.target.value);
                        if (checkIfNotDecimal(e.target.value)) {
                          this.handleInputChange(e, "eightyToHundredExpense");
                        }
                      }}
                      onBlur={(e) => {
                        e.target.value = replaceComma(e.target.value);
                        this.onValidate(e, "eightyToHundredExpense");
                      }}
                      placeholder="Enter Expense"
                      className={`form-input ${
                        errors.eightyToHundredExpense
                          ? "error-input-border"
                          : ""
                      } `}
                      disabled={false}
                    />
                    {errors.eightyToHundredExpense && (
                      <span className="errorMsg">
                        {errors.eightyToHundredExpense == true
                          ? "Field is Empty"
                          : errors.eightyToHundredExpense}
                      </span>
                    )}
                    {/* {!errors.loanamtnorms && invalidPanNumber && (
                                                        <span className="errorMsg">Please enter valid PAN</span>
                                                    )} */}
                  </div>
                  {/* <label>
                    <i className="text-danger">*</i> Level
                  </label>
                  <Select
                    defaultValue={
                      levelDropdownOptions.filter(
                        ({ levelcode }) => levelcode == form.level
                      )[0]
                    }
                    onChange={(e) => {
                      this.handleLevelSelectChanges(e, "level");
                    }}
                    onBlur={(e) => this.onValidate(e, "level")}
                    name="level"
                    options={levelDropdownOptions}
                    placeholder="Select level"
                    valueKey="levelcode"
                    labelKey="levelcode"
                    getOptionLabel={(option) => option.levelcode}
                    getOptionValue={(option) => option.levelcode}
                    className={`${errors.level ? "error-input-border" : ""} `}
                    isDisabled={false}
                  />
                  {errors.level && (
                    <span className="errorMsg">Please Enter Level</span>
                  )} */}
                </div>
                <div className="mb-4 col-md-4 ">
                  <label>
                    <i className="text-danger">*</i> {"> 1L"}
                  </label>
                  <div class="inner-addon right-addon">
                    <input
                      type="text"
                      // pattern="\d*"

                      name="moreThanHundredExpense"
                      value={
                        form.moreThanHundredExpense &&
                        formatIndianCurrency(
                          form.moreThanHundredExpense
                        ).toString()
                      }
                      onChange={(e) => {
                        e.target.value = replaceComma(e.target.value);
                        if (checkIfNotDecimal(e.target.value)) {
                          this.handleInputChange(e, "moreThanHundredExpense");
                        }
                      }}
                      onBlur={(e) => {
                        e.target.value = replaceComma(e.target.value);
                        this.onValidate(e, "moreThanHundredExpense");
                      }}
                      placeholder="Enter Value"
                      className={`form-input ${
                        errors.moreThanHundredExpense
                          ? "error-input-border"
                          : ""
                      } `}
                      disabled={false}
                    />
                    {errors.moreThanHundredExpense && (
                      <span className="errorMsg">
                        {errors.moreThanHundredExpense == true
                          ? "Field is Empty"
                          : errors.moreThanHundredExpense}
                      </span>
                    )}
                    {/* {!errors.loanamtnorms && invalidPanNumber && (
                                                        <span className="errorMsg">Please enter valid PAN</span>
                                                    )} */}
                  </div>
                  {/* <label>
                    <i className="text-danger">*</i> Level
                  </label>
                  <Select
                    defaultValue={
                      levelDropdownOptions.filter(
                        ({ levelcode }) => levelcode == form.level
                      )[0]
                    }
                    onChange={(e) => {
                      this.handleLevelSelectChanges(e, "level");
                    }}
                    onBlur={(e) => this.onValidate(e, "level")}
                    name="level"
                    options={levelDropdownOptions}
                    placeholder="Select level"
                    valueKey="levelcode"
                    labelKey="levelcode"
                    getOptionLabel={(option) => option.levelcode}
                    getOptionValue={(option) => option.levelcode}
                    className={`${errors.level ? "error-input-border" : ""} `}
                    isDisabled={false}
                  />
                  {errors.level && (
                    <span className="errorMsg">Please Enter Level</span>
                  )} */}
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-danger" onClick={this.handleModalClose}>
              Cancel
            </Button>
            {this.props.action === "edit" && (
              <Button
                className="btn-success"
                disabled={diableSave || isRequiredFeildFilled || isLoading}
                onClick={this.handleUpdate}
              >
                Update
              </Button>
            )}
            {this.props.action !== "edit" && (
              <Button
                className="btn-success"
                disabled={diableSave || isRequiredFeildFilled || isLoading}
                onClick={this.handleSubmit}
              >
                Create
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        <CloseModal
          show={showInner}
          button2={this.handleModalClose}
          button1={this.innerModalClose}
          title="Do you want to close ?"
        />
      </React.Fragment>
    );
  }
}

export default AddEditfamily;
