import React, { useState, useEffect, Component, useRef } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from "lodash";

import CloseModal from "../../../Components/CloseModal";
import {
  formatIndianCurrency,
  replaceComma,
  checkIfNotDecimal,
} from "../../../Utils/helperFunctions";
import { uploadbulkspoke } from "../../../Utils/Services/spokeMaster";

toast.configure();

const BulkUploadVillage = (props) => {
  const [showModal, setshowModal] = useState(true);
  const [loading, setLoading] = useState("selecting");
  const [showInner, setshowInner] = useState(false);
  const [file, setFile] = useState(null);

  const BulkUploader = useRef(null);

  const handleModalClose = () => {
    setshowInner(!showInner);
  };

  const innerModalClose = (modclose) => {
    if (!modclose) {
      handleModalClose();
    }
    setshowModal(false);
    props.handleBulkUpload();
  };

  const handleInputClick = () => {
    if (loading === "selecting") {
      BulkUploader.current.click();
    }
  };
  const handleSelectFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    setLoading("uploading");

    const fileType = file.name.slice(file.name.lastIndexOf(".") + 1);
    if (
      fileType === "xls" ||
      fileType === "xlsx" ||
      fileType === "xlsm" ||
      fileType === "xlsb"
    ) {
      let blob = new Blob([file], { type: "xlsx" });
      const formData = new FormData();
      formData.append("file", blob, "excel.xls");
      uploadbulkspoke(formData).then((res) => {
        console.log(res);
        if (!res.status && res.message) {
          toast.success(res.data.message);
        } else if (res.data.errors) {
          toast.error(res.data.errors.toString(), {
            autoClose: 10000,
          });
        } else if (res.data.message) {
          toast.error(res.data.message);
        } else {
          toast.error("Excel file Error: Empty fields are not allowed");
        }
      });
      props.handleBulkUpload();
    } else {
      toast.error(`${fileType} extension is invalid`);
      props.handleBulkUpload();
    }
  };

  return (
    <React.Fragment>
      <Modal
        className="bulk-upload-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Bulk Upload Villages</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="bulk-upload-input-div" onClick={handleInputClick}>
            {loading === "selecting" && (
              <>
                <input
                  type="file"
                  name="BulkleadUpload"
                  id="file"
                  ref={BulkUploader}
                  onChange={handleSelectFile}
                  style={{ display: "none" }}
                />
                <i
                  class="fas fa-upload fa-4x"
                  name="BulkleadUpload"
                  style={{
                    color: "#1e1f63",
                    cursor: "pointer",
                    marginBottom: "20px",
                  }}
                ></i>
                <p>{file ? file.name : "Select File"}</p>
              </>
            )}
            {loading === "uploading" && (
              <>
                <i class="fa fa-spinner fa-spin fa-4x"></i>
                Uploading...
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-danger" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button
            className="btn-success"
            disabled={!file}
            onClick={handleUpload}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
      <CloseModal
        show={showInner}
        button2={handleModalClose}
        button1={innerModalClose}
        title="Do you want to close ?"
      />
    </React.Fragment>
  );
};

export default BulkUploadVillage;
