import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import AddEditFrankingFee from "./AddEditFrankingFee";
import { formatIndianCurrency } from "../../../Utils/helperFunctions";
import {
  deleteFrankingFeeById,
  getAllFrankingFee,
} from "../../../Utils/Services/frankingFee";
import { LoanTypeOption } from "../../../Utils/constant";
import {
  getAllProducts,
  getAllSegmentList,
  getAllStates,
} from "../../../Utils/Services/common";

toast.configure();

const FrankingFee = (props) => {
  let [search, setSearch] = useState("");
  let [frankingFeeData, setFrankingFeeData] = useState([]);
  let [productDropdownValue, setproductDropdownValue] = useState([]);
  let [stateDropdownValue, setstateDropdownValue] = useState([]);
  let [showAddFrankingFeeModal, setShowAddFrankingFeeModal] = useState(false);
  let [showEditFrankingFeeModal, setShowEditFrankingFeeModal] = useState(false);
  let [editData, setEditData] = useState({});

  useEffect(() => {
    getFrankingFee();
    getStates();
    getProducts();
  }, []);

  const getFrankingFee = () => {
    getAllFrankingFee().then((res) => {
      console.log("getAllFrankingFee", res);
      if (res.error) {
        return;
      }
      if (res.data && res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        /* toast.success(res.data.message, {
                    type: toast.TYPE.SUCCESS,
                    autoClose: 2000
                }); */
        setFrankingFeeData(res.data.data);
      }
    });
  };

  const getStates = () => {
    getAllStates().then((result) => {
      console.log("result", result);
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        toast.error(result.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return;
      } else {
        if (result && result.data && result.data.data) {
          setstateDropdownValue(result.data.data);
        }
      }
    });
  };

  const getProducts = () => {
    getAllProducts().then((result) => {
      if (result.error) {
        return;
      } else if (result.data && result.data.error) {
        toast.error(result.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        if (result && result.data && result.data.data) {
          setproductDropdownValue(result.data.data);
        }
      }
    });
  };

  const deleteFrankingData = (id) => {
    let postData = {
      id: id,
    };
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes, Delete It!",
          onClick: () => {
            deleteFrankingFeeById(postData).then((response) => {
              console.log(
                "deleteFrankingData",
                response,
                response.data && response.data.error == "true"
              );
              if (response.error) {
                return;
              }
              if (response.data && response.data.error) {
                toast.error(response.data.message, {
                  type: toast.TYPE.ERROR,
                  autoClose: 2000,
                });
              } else {
                toast.success(response.data.message, {
                  type: toast.TYPE.SUCCESS,
                  autoClose: 2000,
                });
                getFrankingFee();
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            // toast.error("Role Can Not be Deleted", {
            //   type: toast.TYPE.ERROR,
            //   autoClose: 2000
            // });
            // this.RoleList();
          },
        },
      ],
    });
  };

  const columns = [
    {
      Header: "SR. No",
      width: 70,
      Cell: (data) => {
        // console.log("dat/a...", data)
        return data.index + 1;
      },
      /* Cell: ({ original }) => {
                return original.id && original.id ? original.id : null;
            } */
    },
    {
      Header: "State",
      width: 200,
      Cell: ({ original }) => {
        return original && original.state ? original.state : null;
      },
    },
    {
      Header: "Product",
      Cell: ({ original }) => {
        return original && original.product ? original.product : null;
      },
    },
    {
      Header: "Loan Type",
      Cell: ({ original }) => {
        return original && original.loantype ? original.loantype : null;
      },
    },
    {
      Header: "Loan Amount Norms (In Rupees)",
      Cell: (data) => {
        let { original } = data;
        let text = "";
        // console.log("asdasdsa", original);
        if (
          original.upperloanamtnorms != "" &&
          (original.lowerloanamtnorms == "" ||
            original.lowerloanamtnorms == "0")
        ) {
          text = `<= ${formatIndianCurrency(original.upperloanamtnorms)}`;
        } else if (
          (original.upperloanamtnorms == "" ||
            original.upperloanamtnorms == "0") &&
          original.lowerloanamtnorms != ""
        ) {
          text = `>= ${formatIndianCurrency(original.lowerloanamtnorms)}`;
        }
        if (
          original.upperloanamtnorms != "" &&
          original.lowerloanamtnorms != ""
        ) {
          text = `${formatIndianCurrency(
            original.lowerloanamtnorms
          )}-${formatIndianCurrency(original.upperloanamtnorms)}`;
        } else if (
          (original.upperloanamtnorms == "" &&
            original.lowerloanamtnorms == "") ||
          (original.upperloanamtnorms == "0" &&
            original.lowerloanamtnorms == "0")
        ) {
          text = `NA`;
        }
        return text;
      },
    },
    {
      Header: "Base Amount (In Rupees)",
      Cell: ({ original }) => {
        return original.baseamt ? formatIndianCurrency(original.baseamt) : null;
      },
    },
    {
      Header: "Percentage(%) Value",
      Cell: ({ original }) => {
        return original.percentagevalue && original.percentagevalue
          ? original.percentagevalue
          : null;
      },
    },
    {
      Header: "Action",
      width: 125,
      disabled: false,
      accessor: "delete",
      accessor: "edit",

      Cell: ({ original }) => {
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              onClick={() => deleteFrankingData(original.id)}
              className="fa fa-trash"
              style={{ cursor: "pointer" }}
            />
            <i
              disabled={false}
              onClick={() => handleEditFrankingFee(original)}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const handleAddFrankingFee = (id) => {
    setShowAddFrankingFeeModal(!showAddFrankingFeeModal);
  };

  const handleEditFrankingFee = (obj) => {
    setShowEditFrankingFeeModal(!showEditFrankingFeeModal);
    setEditData(obj);
  };

  const handleSearch = (e) => {
    /* let { roleContainer } = this.state;
        let array = [];
        roleContainer.map(res => {
            let roleName = res.roleName;
            if (roleName)
                if (res.roleName.match(e.target.value.toUpperCase())) {
                    array.push(res);
                }
        });
        this.setState({
            search: e.target.value,
            roleData: e.target.value ? array : roleContainer
        }); */
  };

  return (
    <div className="dash_grid">
      <ToastContainer />
      <LeftMenu />
      <main className="bg-white">
        <Header {...props} />
        <section className="container-fluid mb-5">
          <h5 className="text-center mt-5 mx-auto user-box"> Franking Fee</h5>
          <div className="d-flex align-items-center mt-5">
            <button
              disabled={false}
              onClick={handleAddFrankingFee}
              className="add-btn ml-auto btn btn-info btn-lg"
            >
              {" "}
              Add{" "}
            </button>
            {showAddFrankingFeeModal && (
              <AddEditFrankingFee
                action={"add"}
                getFrankingFee={getFrankingFee}
                handleAddFrankingFee={handleAddFrankingFee}
                productDropdownValue={productDropdownValue}
                stateDropdownValue={stateDropdownValue}
              />
            )}
            {showEditFrankingFeeModal && (
              <AddEditFrankingFee
                action={"edit"}
                getFrankingFee={getFrankingFee}
                editData={editData}
                handleEditFrankingFee={handleEditFrankingFee}
                productDropdownValue={productDropdownValue}
                stateDropdownValue={stateDropdownValue}
              />
            )}
          </div>

          <div className={`mt-4 react-table`}>
            <ReactTable
              data={frankingFeeData}
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default FrankingFee;
