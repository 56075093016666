import { get, post } from "../httpInterceptor";

// API for delete ROI

export const deleteRoiById = (obj) => {
  return post(`/sarvagram-credit/master/deleteroibyid`, obj, false).then(
    (res) => {
      console.log("deleteRoiById", res);
      return res;
    }
  );
};

// API for get ROI

export const getAllRoi = () => {
  return get(`/sarvagram-credit/master/findallroi`).then((res) => {
    console.log("RESPONSE :: getAllRoi ::: ", res);
    return res;
  });
};

/* export const addProductFund = (obj) => {
    return post(`/sarvagram-credit/master/saveorupdateProductFunds`, obj,
        false
    ).then(res => {
        console.log("addProductFund", res);
        return res;
    });
} */

// API for add or update ROI

export const addOrUpdateRot = (obj) => {
  return post(`/sarvagram-credit/master/saveorupdateroi`, obj, false).then(
    (res) => {
      console.log("addOrUpdateRot", res);
      return res;
    }
  );
};

// API for get segment list

export const getAllSegmentList = () => {
  return get(`/sarvagram-credit/master/findallsegmentdropdown`).then((res) => {
    console.log("RESPONSE :: getAllSegmentList ::: ", res);
    return res;
  });
};

// API for get ROI

export const getRoiById = (obj = {}) => {
  return post(`/sarvagram-credit/master/findroibyid`, obj, false).then(
    (res) => {
      console.log("getRoiById", res);
      return res;
    }
  );
};
