import CryptoJS from "crypto-js";

//Get the indian currency format

export const formatIndianCurrency = (val) => {
  console.log("val------", val);
  let isNegative =
    val != "" && val != undefined && val != null
      ? val.toString().includes("-")
      : false;
  if (isNegative) {
    val = val.replace(/-/gi, "");
  }
  let temp = val;
  temp = temp.toString();
  let afterPoint = "";
  if (temp.indexOf(".") > 0)
    afterPoint = temp.substring(temp.indexOf("."), temp.length);
  if (temp !== "-") {
    temp = isNaN(parseInt(temp)) ? "" : parseInt(temp);
  }
  temp = temp.toString();
  let lastThree = temp.substring(temp.length - 3);
  let otherNumbers = temp.substring(0, temp.length - 3);
  if (otherNumbers !== "") lastThree = "," + lastThree;
  let res =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
  if (isNegative) {
    res = "-" + res;
  }
  return res;
};

//Replace the comma from input
export const replaceComma = (value) => {
  return value.replace(/,/g, "");
};

// get server validation in string format
export const getServerValidation = (errorObj) => {
  let messages = [];
  Object.keys(errorObj).map((val) => {
    errorObj[val].map((arr_val) => messages.push(arr_val));
  });
  return messages.join(",");
};

export const checkIfNotDecimal = (value) => {
  if (value.indexOf(".") === -1) {
    return true;
  } else {
    return false;
  }
};

//Allow the numbers greater than zero
export const allowNumberGreaterThanZero = (value) => {
  let regex = /^(?=.*[0-9])\d*(?:\.\d{1,2})?$/;
  return regex.test(value);
};

export const AesUtil = function (keySize, iterationCount) {
  this.keySize = keySize / 32;
  this.iterationCount = iterationCount;
};

AesUtil.prototype.generateKey = function (salt, passPhrase) {
  var key = CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt), {
    keySize: this.keySize,
    iterations: this.iterationCount,
  });
  return key;
};

AesUtil.prototype.encrypt = function (salt, iv, passPhrase, plainText) {
  var key = this.generateKey(salt, passPhrase);
  var encrypted = CryptoJS.AES.encrypt(plainText, key, {
    iv: CryptoJS.enc.Hex.parse(iv),
  });
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
};

AesUtil.prototype.decrypt = function (salt, iv, passPhrase, cipherText) {
  var key = this.generateKey(salt, passPhrase);
  var cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: CryptoJS.enc.Base64.parse(cipherText),
  });
  var decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
    iv: CryptoJS.enc.Hex.parse(iv),
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const generatePassword = (password) => {
  var iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
  var salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);

  var aesUtil = new AesUtil(128, 1000);
  var ciphertext = aesUtil.encrypt(salt, iv, "sarvagram", password);

  var aesPassword = iv + "::" + salt + "::" + ciphertext;
  var hashedpassword = Buffer.from(aesPassword).toString("base64"); //btoa(aesPassword);
  // console.log("hashedpassword", hashedpassword);
  return hashedpassword;
};
