import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import Addedituser from "./Addedituser";
import Header from "../../../Components/Header";

import LeftMenu from "../../../Components/LeftMenu";
import { formatIndianCurrency } from "../../../Utils/helperFunctions";
// import {
//   getallusers,
//   //getallzones,
//   //getallregions,
//  // getallbranches,
//  // getallroles,
//   //getalluserByEmpId,
// } from "../../../Utils/Services/role";
import { getallzones,getallregion,getallstates,getallbranches,findAllRoles,getalluserByEmpId,getallusers,getuserbyid} from "../../../Utils/Services/employeeMaster";
toast.configure();

const UserManagement = (props) => {
  let [search, setSearch] = useState("");
  let [usereData, setuserData] = useState([]);
  let [userContainer, setuserContainer] = useState([]);
  let [showEditUserModal, setshowEditUserModal] = useState(false);
  let [editData, setEditData] = useState({});
  let [showAddUserModal, setShowAddUserModal] = useState(false);
  let [roleDropdownValue, setroleDropdownValue] = useState([]);

  useEffect(() => {
    allusers();
    getrole();
    
	}, [props]);

	console.log("props of add user", props);

	const getrole = () => {
		if (props.role === "ZBH") {
			setroleDropdownValue(zbhRoles);
			console.log("zbhRoles", zbhRoles);
		} else if (props.role === "ZCM") {
			setroleDropdownValue(zcmRoles);
			console.log("zcmRoles", zcmRoles);
		} else if (props.role === "RBH") {
			setroleDropdownValue(rbhRoles);
			console.log("rbhRoles", rbhRoles);
		} else if (props.role === "RCM") {
			setroleDropdownValue(rcmRoles);
			console.log("rcmRoles", rcmRoles);
		} else if (props.role === "BM") {
			setroleDropdownValue(bmRoles);
			console.log("bmRoles", bmRoles);
		} else {
			console.log("else");
		//	getallroles().then((result) => {
      findAllRoles().then((result) => {
				if (result.error) {
					return;
				} else if (result.data && result.data.error) {
					toast.error(result.data.message, {
						type: toast.TYPE.ERROR,
						autoClose: 2000,
					});
				} else {
					if (result && result.data && result.data.data) {
						setroleDropdownValue(result.data.data);
					}
				}
			});
		}
	};

	console.log("roleDropdownValue", roleDropdownValue);

  // const allusers = () => {
  //   let userData = JSON.parse(localStorage.getItem("userData"));
  //   getalluserByEmpId(userData.adminEmployeeId).then((res) => {
  //     console.log("Roles", res);
  //     if (res.error) {
  //       return;
  //     }
  //     if (res.data && res.data.error) {
  //       toast.error(res.data.message, {
  //         type: toast.TYPE.ERROR,
  //         autoClose: 2000,
  //       });
  //     } else {
  //       /*  toast.success(res.data.message, {
  //               type: toast.TYPE.SUCCESS,
  //               autoClose: 2000
  //           }); */
  //       setuserData(res.data.data);
  //       setuserContainer(res.data.data);
  //     }
  //   });
  // };

   const allusers = () => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    getallusers().then((res) => {
      console.log("Roles", res);
      if (res.error) {
        return;
      }
      if (res && res.error) {
        toast.error(res.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        /*  toast.success(res.data.message, {
                type: toast.TYPE.SUCCESS,
                autoClose: 2000
            }); */
        setuserData(res.data);
        setuserContainer(res.data);
      }
    });
  };

  const getuser = (obj) => {
    getuserbyid(obj).then((res) => {
      
      console.log("*****", res);
      if (res.error) {
        return;
      }
      if (res && res.error) {
        toast.error(res.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        /*  toast.success(res.data.message, {
                type: toast.TYPE.SUCCESS,
                autoClose: 2000
            }); */
     //   setEditData(res.data);
        // setuserContainer(res.data);
      }
    });
  };

  

  const columns = [
    {
      Header: "User ID",
      width: 200,
      Cell: ({ original }) => {
        return original && original.employeeId ? original.employeeId : null;
      },
    },
    {
      Header: "Employee name",
      width: 200,
      Cell: ({ original }) => {
        return original && original.employeeName ? original.employeeName : null;
      },
    },
    {
      Header: "Mobile NO",
      Cell: ({ original }) => {
        return original && original.mobileNo ? original.mobileNo : null;
      },
    },
    {
      Header: "Role",
      Cell: ({ original }) => {
        return original && original.role ? original.role : null;
      },
    },
    {
      Header: "Status",
      Cell: ({ original }) => {
        return original && original.status ? original.status : null;
      },
    },
    {
      Header: "Action",
      width: 125,
      disabled: false,
      accessor: "delete",
      accessor: "edit",

      Cell: ({ original }) => {
        return (
          <div
            disabled={false}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <i
              disabled={false}
              onClick={() => handleEditUser(original)}
              className="fas fa-user-edit"
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  const handleAddUser = (id) => {
    setShowAddUserModal(!showAddUserModal);
  };

  const handleEditUser = (obj) => {
    setshowEditUserModal(!showEditUserModal);
    setEditData(obj);
    //getuser(obj);
  };

  const handleSearch = (e) => {
    let array = [];
    userContainer.map((res) => {
      let name = res.name;
      if (
        res.employeeName &&
        res.role &&
        res.employeeId &&
        res.status &&
        res.mobileNo
      )
        if (
          res.employeeName.toLowerCase().match(e.target.value.toLowerCase()) ||
          res.role.toLowerCase().match(e.target.value.toLowerCase()) ||
          res.employeeId.toLowerCase().match(e.target.value.toLowerCase()) ||
          res.status.toLowerCase().match(e.target.value.toLowerCase()) ||
          res.mobileNo.toString().match(e.target.value.toString())
        ) {
          array.push(res);
        }
    });
    // this.setState({
    //   search: e.target.value,
    //   userData: e.target.value ? array : userContainer
    // });
    setSearch(e.target.value);
    setuserData(e.target.value ? array : userContainer);
  };

  return (
    <>
      <div className="dash_grid">
        <ToastContainer />
        <LeftMenu role={props.role} />
        <main className="bg-white">
          <Header {...props} />
          <section className="container-fluid mb-5">
            <h5 className="text-center mt-5 mx-auto user-box">
              {" "}
              Employee Creation
            </h5>
          

            <div className="d-flex align-items-center mt-5">
              <div className="form-group m-0 search-input">
                <div className="inner-addon right-addon">
                  <i className="glyphicon glyphicon-search mt-1 font-size-20"></i>
                  <input
                    type="text"
                    className="form-input"
                    name="search"
                    value={search}
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                    // onBlur={this.handleValidate}
                    placeholder="Search..."
                  />
                </div>
              </div>
              <button
                disabled={false}
                onClick={handleAddUser}
                className="add-btn ml-auto btn btn-info btn-lg"
              >
                {" "}
                Add{" "}
              </button>
              {showAddUserModal && (
                <Addedituser
                  action={"add"}
                  handleAddUser={handleAddUser}
                  roleDropdownValue={roleDropdownValue}
                  allusers={allusers}
                  role={props.role}
									userInfo={props.userInfo}
                />
              )}
              {showEditUserModal && (
                <Addedituser
                  action={"edit"}
                  editData={editData}
                  handleEditUser={handleEditUser}
                  roleDropdownValue={roleDropdownValue}
                  allusers={allusers}
                  role={props.role}
									userInfo={props.userInfo}
                />
              )}
            </div>

            <div className={`mt-4 react-table`}>
              <ReactTable
                data={usereData}
                columns={columns}
                defaultPageSize={10}
              />
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default UserManagement;

const zbhRoles = [
  {
		roleCode: "RBH",
		roleName: "RBH",
		id: 12,
	},
	{
		roleCode: "BM",
		roleName: "BM",
		id: 13,
	},
	{
		roleCode: "BRO",
		roleName: "BRO",
		id: 21,
	},
	{
		roleCode: "GRO",
		roleName: "GRO",
		id: 256,
	},
];

const zcmRoles = [
	{
		roleCode: "RCM",
		roleName: "RCM",
		id: 4,
	},
	{
		roleCode: "BCO",
		roleName: "BCO",
		id: 18,
	},
	{
		roleCode: "BCM1",
		roleName: "BCM 1",
		id: 2,
	},
];

const rbhRoles = [
	{
		roleCode: "BM",
		roleName: "BM",
		id: 13,
	},
	{
		roleCode: "BRO",
		roleName: "BRO",
		id: 21,
	},
	{
		roleCode: "GRO",
		roleName: "GRO",
		id: 256,
	},
];

const rcmRoles = [
	{
		roleCode: "BCO",
		roleName: "BCO",
		id: 18,
	},
	{
		roleCode: "BCM1",
		roleName: "BCM 1",
		id: 2,
	},
];

const bmRoles = [
	{
		roleCode: "BRO",
		roleName: "BRO",
		id: 21,
	},
	{
		roleCode: "GRO",
		roleName: "GRO",
		id: 256,
	},
];

