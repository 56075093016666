import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { getUserByEmplyeeId } from "../../Actions/AuthAction";
import { loginUrl } from "../../Utils/constant";
import "./style.css";

const Loader = (props) => {
  let userData = {};
  userData.adminAccessToken = props.match.params.adminAccessToken;
  userData.adminEmployeeId = props.match.params.adminEmployeeId;
  userData.adminId = props.match.params.adminId;
  userData.employeeName = "ABC";
  if (
    userData.adminAccessToken != "null" &&
    userData.adminAccessToken != undefined &&
    userData.adminId != "null" &&
    userData.adminId != undefined &&
    userData.adminEmployeeId != null &&
    userData.adminEmployeeId != undefined
  ) {
    localStorage.setItem("userData", JSON.stringify(userData));
    props.history.push("/dashboard");
  } else if (localStorage.getItem("userData")) {
    props.history.push("/dashboard");
  } else {
    localStorage.removeItem("userData");
    window.location.href = `${loginUrl}`;
  }

  return (
    <section className="w-100 px-2 px-md-5 pt-4 pb-5 dashboard_div bg_l-secondary">
      <div className="col-sm-12 text-center">
        <div className="loader1">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      {/* <div className="col-lg-12" style={{ height: 250 }}>
                <h3 className="font-weight-bold text-primary ml-3 mb-3 text-center">
                    <i className="fa fa-spinner fa-spin mr-2" />
                Loading...
              </h3>
            </div> */}
    </section>
  );
};

const mapStateToProps = ({ authActionsReducers }) => {
  const { userDetails } = authActionsReducers;
  console.log("userDetails......", userDetails);
  return { userDetails };
};

export default connect(mapStateToProps, { getUserByEmplyeeId })(
  withRouter(Loader)
);
