import { get, post } from "../httpInterceptor";

// API for delete Product funding

export const deleteProductFundById = (obj) => {
  return post(
    `/sarvagram-credit/master/deleteproductfundbyid`,
    obj,
    false
  ).then((res) => {
    console.log("deleteProductFundById", res);
    return res;
  });
};

// API for get Product funding

export const getAllProductFund = () => {
  return get(`/sarvagram-credit/master/findAllproductfund`).then((res) => {
    console.log("RESPONSE :: getAllProductFund ::: ", res);
    return res;
  });
};

/* export const addProductFund = (obj) => {
    return post(`/sarvagram-credit/master/saveorupdateProductFunds`, obj,
        false
    ).then(res => {
        console.log("addProductFund", res);
        return res;
    });
} */

// API for update Product funding

export const updateProductFundById = (obj) => {
  return post(
    `/sarvagram-credit/master/saveOrUpdateproductfund`,
    obj,
    false
  ).then((res) => {
    console.log("updateProductFundById", res);
    return res;
  });
};

// API for get franchise list

export const getFranchiseeList = () => {
  return get(`/payoutmaster/getFranchiseeList`).then((res) => {
    console.log("RESPONSE :: getFranchiseeList ::: ", res);
    return res;
  });
};

// API for get all payout master details

export const getAllPayoutMasterDetails = () => {
  return get(`/payoutmaster/getAllPayoutMasterDetails`).then((res) => {
    console.log("RESPONSE :: getAllPayoutMasterDetails ::: ", res);
    return res;
  });
};

// API for save payout master details
export const savepayoutmaster = (obj) => {
  return post(`/payoutmaster/save`, obj, false).then((res) => {
    console.log("savepayoutmaster", res);
    return res;
  });
};

// API for update payout master details

export const updatepayoutmaster = (obj) => {
  return post(`/payoutmaster/update`, obj, false).then((res) => {
    console.log("updatepayoutmaster", res);
    return res;
  });
};

// API for get all francise list by franchise code

export const getFranchiseeListbyCode = (franchiseeCode) => {
  return get(
    `/payoutmaster/getFranchiseeList?franchiseeCode=${franchiseeCode}`
  ).then((res) => {
    console.log("RESPONSE :: getFranchiseeListbyCode ::: ", res);
    return res;
  });
};

// API for delete payout master details

export const deletepayoutmasterId = (obj) => {
  return post(`/payoutmaster/delete`, obj, false).then((res) => {
    console.log("deletepayoutmasterId", res);
    return res;
  });
};
