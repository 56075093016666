import { get, post } from "../httpInterceptor";

// findAllRoles
// export const findAllRoles = () => {
//   return get(`/findAllRoles`).then((res) => {
//     console.log("RESPONSE :: findAllRoles ::: ", res);
//     return res;
//   });
// };

export const findAllRoles = () => {
  return get(`/findAllRoles`).then((res) => {
    console.log("RESPONSE :: findAllRoles ::: ", res);
    return res;
  });
};

export const findAllVillage = (obj) => {
  return post(`/user/getallvillages`, obj, false).then((res) => {
    console.log("RESPONSE :: findallvillagesmaster ::: ", res);
    return res;
  });
};

//localhost:8080/getRoleById?roleId=2
export const getRoleById = (roleId = null) => {
  return get(`/getRoleById?roleId=${roleId}`).then((res) => {
    console.log("RESPONSE :: getRoleById ::: ", res);
    return res;
  });
};

// localhost:8080/saveOrUpdateRole
export const saveOrUpdateRole = (obj) => {
  return post(`/saveOrUpdateRole`, obj, false).then((res) => {
    console.log("saveOrUpdateRole", res);
    return res;
  });
};

// http://localhost:8080/user/getallusers
export const getallusers = () => {
  return get(`/user/getallusers`).then((res) => {
    console.log("RESPONSE :: getallusers ::: ", res);
    return res;
  });
};

// localhost:8080/user/getallusersByEmpId?empId=BCM11
export const getallusersByEmpId = (empId) => {
  return get(`/user/getallusersByEmpId?empId=${empId}`).then((res) => {
    console.log("RESPONSE :: getallusersByEmpId ::: ", res);
    return res;
  });
};

// http://localhost:8080/user/getallroles
export const getallroles = () => {
  return get(`/user/getallroles`).then((res) => {
    console.log("RESPONSE :: getallroles ::: ", res);
    return res;
  });
};

// http://localhost:8080/user/getallbranches
export const getallbranches = (obj) => {
  return post(`/user/getallbranches`, obj, false).then((res) => {
    console.log("RESPONSE :: getallbranches ::: ", res);
    return res;
  });
};

// http://localhost:8080/user/getallregions
export const getallregions = (obj) => {
  return post(`/user/getallregions`, obj, false).then((res) => {
    console.log("RESPONSE :: getallregions ::: ", res);
    return res;
  });
};

// http://182.71.191.91:8080/user/getallzones
export const getallzones = () => {
  return get(`/user/getallzones`).then((res) => {
    console.log("RESPONSE :: getallzones ::: ", res);
    return res;
  });
};

// http://localhost:8080/user/adduser
export const adduser = (obj) => {
  return post(`/user/adduser`, obj, false).then((res) => {
    console.log("adduser", res);
    return res;
  });
};
