import React, { useState, useEffect } from 'react';
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import { formatIndianCurrency } from "../../../Utils/helperFunctions";
import { deleteCollateralById, getAllCollateralData } from "../../../Utils/Services/collateral";
import AddEditCollateral from './AddEditCollateral';

toast.configure();

let data = [
    {
        subtype: "",
        motiontype: "",
        propertytype: "",
        id: 1,
        ltv: ""
    }
]
const Collateral = (props) => {
    let [search, setSearch] = useState("");
    let [collateralData, setcollateralData] = useState([]);
    let [showCollateralModal, setshowCollateralModal] = useState(false);
    let [showEditCollateralModal, setshowEditCollateralModal] = useState(false);
    let [editData, setEditData] = useState({});

    useEffect(() => {
        getCollateral();
    }, []);

    const getCollateral = () => {
        getAllCollateralData().then((res) => {
            console.log("getAllCollateralData", res)
            if (res.error) {
                return;
            } else {
                if (res.data && res.data.error) {
                    toast.error(res.data.message, {
                        type: toast.TYPE.ERROR,
                        autoClose: 2000
                    });
                } else {
                    /* toast.success(res.data.message, {
                        type: toast.TYPE.SUCCESS,
                        autoClose: 2000
                    }); */
                    setcollateralData(res.data.data)
                }
            }
        })
    }

    const deleteCollateral = id => {
        let postData = {
            id: id
        }
        confirmAlert({
            title: "Confirm to Delete",
            message: "Are you sure to do this?",
            buttons: [
                {
                    label: "Yes, Delete It!",
                    onClick: () => {
                        deleteCollateralById(postData).then(response => {
                            console.log("deleteCollateral", response, response.data && response.data.error == "true")
                            if (response.error) {
                                return;
                            }
                            if (response.data && response.data.error) {
                                toast.error(response.data.message, {
                                    type: toast.TYPE.ERROR,
                                    autoClose: 2000
                                });
                            } else {
                                toast.success(response.data.message, {
                                    type: toast.TYPE.SUCCESS,
                                    autoClose: 2000
                                });
                                getCollateral();
                            }
                        });
                    }
                },
                {
                    label: "No",
                    onClick: () => {
                        // toast.error("Role Can Not be Deleted", {
                        //   type: toast.TYPE.ERROR,
                        //   autoClose: 2000

                        // });
                        // this.RoleList();
                    }
                }
            ]
        });
    };

    const columns = [
        {
            Header: "SR. No",
            width: 70,
            Cell: ((data) => {
                // console.log("data...", data)
                return data.index + 1
            })
            /* Cell: ({ original }) => {
                return original.id && original.id ? original.id : null;
            } */
        },
        {
            Header: "Movable/non movable",
            width: 200,
            Cell: ({ original }) => {
                return original.motiontype && original.motiontype ? original.motiontype : null;
            }
        },
        {
            Header: "Property type",
            Cell: ({ original }) => {
                return original.propertytype && original.propertytype ? original.propertytype : null;
            }
        },
        {
            Header: "Sub type",
            Cell: ({ original }) => {
                return original.subtype && original.subtype ? original.subtype : null;
            }
        },
        {
            Header: "LTV (%)",
            Cell: ({ original }) => {
                return original.ltv ? `${original.ltv}` : null;
            }
        },
        {
            Header: "Action",
            width: 125,
            disabled: false,
            accessor: "delete",
            accessor: "edit",

            Cell: ({ original }) => {
                return (
                    <div
                        disabled={false}
                        style={{ display: "flex", justifyContent: "space-around" }}
                    >
                        <i
                            disabled={false}
                            onClick={() => deleteCollateral(original.id)}
                            className="fa fa-trash"
                            style={{ cursor: "pointer" }}
                        />
                        <i
                            disabled={false}
                            onClick={() => handleEditCollateral(original)}
                            className="fas fa-user-edit"
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                );
            }
        }
    ];

    const handleAddCollateral = id => {
        setshowCollateralModal(!showCollateralModal);
    };

    const handleEditCollateral = obj => {
        setshowEditCollateralModal(!showEditCollateralModal);
        setEditData(obj)
    };

    const handleSearch = e => {
        /* let { roleContainer } = this.state;
        let array = [];
        roleContainer.map(res => {
            let roleName = res.roleName;
            if (roleName)
                if (res.roleName.match(e.target.value.toUpperCase())) {
                    array.push(res);
                }
        });
        this.setState({
            search: e.target.value,
            roleData: e.target.value ? array : roleContainer
        }); */
    };

    return (
        <div className="dash_grid">
            <ToastContainer />
            <LeftMenu />
            <main className="bg-white">
                <Header {...props} />
                <section className="container-fluid mb-5">
                    <h5 className="text-center mt-5 mx-auto user-box"> Collateral Table</h5>
                    <div className="d-flex align-items-center mt-5">
                        <button
                            disabled={false}
                            onClick={handleAddCollateral}
                            className="add-btn ml-auto btn btn-info btn-lg"
                        >
                            {" "}
                    Add {" "}
                        </button>
                        {showCollateralModal && (
                            <AddEditCollateral
                                action={"add"}
                                getCollateral={getCollateral}
                                handleAddCollateral={handleAddCollateral}
                            />
                        )}
                        {showEditCollateralModal && (
                            <AddEditCollateral
                                action={"edit"}
                                getCollateral={getCollateral}
                                editData={editData}
                                handleEditCollateral={handleEditCollateral}
                            />
                        )}
                    </div>

                    <div
                        className={`mt-4 react-table`}
                    >
                        <ReactTable
                            data={collateralData}
                            columns={columns}
                            defaultPageSize={10}
                        />
                    </div>
                </section>
            </main>
        </div>
    );
};

export default Collateral;