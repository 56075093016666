import React, { Component } from 'react';
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import { cloneDeep } from "lodash";
import { Button, Modal } from "react-bootstrap";

import Header from "../../../Components/Header";
import LeftMenu from "../../../Components/LeftMenu";
import InsuranceTable from "./InsuranceTable";


class InsuranceDeduction extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    /* handleChnages = (e, outerIndex, name, extremeIndex) => {
        let { form } = this.state;
        console.log("wwww", e, outerIndex, name, extremeIndex)
        form[outerIndex][name][extremeIndex] = e.target.value;

        this.setState({ form });
    }

    addTenure = () => {
        let { form } = this.state;
        let output = form.map((outerData, outerIndex) => {
            Object.keys(outerData).map((each, index) => {
                if (each == "Age | Tenure") {
                    let ten = outerData[each][outerData[each].length - 1] && outerData[each][outerData[each].length - 1].tenure ? outerData[each][outerData[each].length - 1].tenure + 1 : 2;
                    outerData[each].push({ tenure: ten });
                    return outerData[each]
                } else {
                    outerData[each].push({ age: "" });
                    return outerData[each]
                }
            })
        })
        this.setState({ form });
    }

    deleteTenure = () => {
        let { form } = this.state;
        let output = form.map((outerData, outerIndex) => {
            Object.keys(outerData).map((each, index) => {
                if (each == "Age | Tenure") {
                    outerData[each].splice(-1, outerData[each].length);
                    return outerData[each]
                } else {
                    outerData[each].splice(-1, outerData[each].length);
                    return outerData[each]
                }
            })
        })
        this.setState({ form });
    }

    handleEditableStatus = () => {
        this.setState({ isNotEditable: false });
    }

    handleClose = () => {
        this.setState({ isNotEditable: true });
    }

    handleSubmit = () => {
        toast.success("form submitted Sucessfullty", {
            type: toast.TYPE.ERROR,
            autoClose: 2000
        });
        this.setState({ isNotEditable: true });
    } */

    render() {
        // let { form, isNotEditable } = this.state;
        return (
            <>
                <div className="dash_grid">
                    <ToastContainer />
                    <LeftMenu />
                    <main className="bg-white">
                        <Header {...this.props} />
                        <section className="container-fluid-insurance mb-5">
                            <h5 className="text-center mt-2 mx-auto user-box"> Insurance Deduction</h5>
                            <InsuranceTable title={"Insurance deduction (ROI > 25% To 30%)"} action={"above25"} />
                            <hr className="bg_lightblue border-0 h-1px mt-4" />
                            <InsuranceTable title={"Insurance deduction (ROI Upto 25%)"} action={"below25"}/>
                        </section>
                    </main>
                </div>
            </>
        );
    }
}

export default InsuranceDeduction;