import { get, post, postexcel } from "../httpInterceptor";

//save or update spokes
export const saveorupdatespokemaster = (obj) => {
  console.log("saving, ", obj);

  return post(`/geography/spokes`, obj);
  // .then((res) => {
  //   console.log("saveorupdatespokemaster", res);
  //   return res;
  // });
};

//list all tehsil district and state by pincode
export const getdetailsbypincode = (pincode) => {
  return get(`/geography/pincode/${pincode}`, null, false).then((res) => {
    console.log("saveorupdatevillagemaster", res);
    return res;
  });
};

//list of branches
export const findallspokes = (page) => {
  return get(`/geography/spokes/page/${page}`).then((res) => {
    console.log("RESPONSE :: findallspokes ::: ", res);
    return res;
  });
};

export const searchSpokes = (page, keyword) => {
  return get(`/geography/spokes/page/${page}?keyword=${keyword}`).then(
    (res) => {
      console.log("search by spoke name", res);
      return res;
    }
  );
};

export const uploadbulkspoke = (formData) => {
  return postexcel(`/geography/spokes/bulk/upload`, formData).then((res) => {
    return res;
  });
};

export const allspokes = () => {
  return get(`/geography/spokes`).then((res) => {
    return res;
  });
};

export const migrateSpoke = (obj) => {
  return post(`/geography/spoke/migration`, obj, false).then((res) => {
    return res;
  });
};
